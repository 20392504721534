import { Component, OnInit, Input } from '@angular/core';
import { FinancesModel } from 'src/app/shared/models/finances.model';
import { FamilyTreeService } from '../../family-tree.service';
import { TreeResponse, FamilyTreeExistResponse, BudgetResponse, XplanChoicesResponse, InsuranceResponse, RetirementResponse, ProvisionResponse, WillResponse, FinancesResponse, RetirementAddResponse, DependantSupportAddResponse, SpouseDeathOrTPDResponse, BenefitPeriodResponse, OtherProvisionsResponse, ImmediateNeedsResponse, ProgressiveNeedsResponse, WillExecutorsResponse, DocumentsResponse, ResidualBequestsResponse, ResiduaryBequestUserResponse, ResiduaryBequestNoteResponse, CalamityBeneficiaryResponse, CalamityBeneficiaryAddResponse, DeleteOtherProvisionResponse, OtherEntityItemResponse, OtherEntityNoteResponse, AddFinancialEPAResponse, LifetimeArrangementsNoteResponse, ProposedInsuranceResponse, ProposedInsuranceAddResponse } from '../../../shared/models/response.model';
import { HttpErrorResponse } from '@angular/common/http';
import { SharedDataService } from 'src/app/shared/service/shared-data.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-finances',
  templateUrl: './finances.component.html',
  styleUrls: ['../rightside.component.css']
})
export class FinancesComponent implements OnInit {
  preloaderService: any;
  router: any;

  constructor(
    private familyTreeService :FamilyTreeService,
    private sharedDataService: SharedDataService,
    private toastr: ToastrService,
  ) { 
    this.sharedDataService.clientFirstname.subscribe(clientFirstname => this.clientFirstname = clientFirstname);
    this.sharedDataService.partnerFirstname.subscribe(partnerFirstname => this.partnerFirstname = partnerFirstname);
  }

  isLoaded7: boolean;
  financesInfo:FinancesModel;
  @Input() client_id: string;
  @Input() xeppoCode: string;
  @Input() client_code: string;
  partnerClientCode: string;

  insurableIncomeClient: number = 0;
  insurableIncomePartner: number = 0;
  annualBenefitClient: number = 0;
  annualBenefitPartner: number = 0;
  totalDebt: number = 0;
  valueOfAllInvestmentsIfSoldClient: number = 0;
  valueOfAllInvestmentsIfSoldPartner: number = 0;
  valueOfAllInvestmentsIfSoldJoint: number = 0;
  deceasedPersonsSuperClient: number = 0;
  deceasedPersonsSuperPartner: number = 0;
  investmentPropertiesClient: number = 0;
  investmentPropertiesPartner: number = 0;
  totalValueOfInvestmentsIfSold: number = 0;
  message = 'Hello!';
  clientFirstname:string = "";
  partnerFirstname:string = "";
  mainUserFinanceTotal: number = 0;
  partnerUserFinanceTotal: number = 0;
  jointUserFinanceTotal: number = 0;
  incomePartner: number = 0;
  income: number = 0;
  salaryOwner: number = 0;
  salaryPartner: number = 0;
  provisionInfoDependent:any=[];
  superBalance: number = 0;
  superBalancePartner: number = 0;
  needtoload=false;
  

  ngOnInit(): void {
    this.getFinancesInfo();

    this.message = 'Hello thilina!';
    this.familyTreeService.currentMessage.subscribe(message => this.message = message);
    this.sharedDataService.financesInfo.subscribe(financesInfo => this.financesInfo = financesInfo);
    
    this.sharedDataService.insurableIncomeClient.subscribe(insurableIncomeClient => this.insurableIncomeClient = insurableIncomeClient);
    this.sharedDataService.insurableIncomePartner.subscribe(insurableIncomePartner => this.insurableIncomePartner = insurableIncomePartner);
    this.sharedDataService.annualBenefitClient.subscribe(annualBenefitClient => this.annualBenefitClient = annualBenefitClient);
    this.sharedDataService.annualBenefitPartner.subscribe(annualBenefitPartner => this.annualBenefitPartner = annualBenefitPartner);
    this.sharedDataService.totalDebt.subscribe(totalDebt => this.totalDebt = totalDebt);
    this.sharedDataService.deceasedPersonsSuperClient.subscribe(deceasedPersonsSuperClient => this.deceasedPersonsSuperClient = deceasedPersonsSuperClient);
    this.sharedDataService.deceasedPersonsSuperPartner.subscribe(deceasedPersonsSuperPartner => this.deceasedPersonsSuperPartner = deceasedPersonsSuperPartner);
    this.sharedDataService.valueOfAllInvestmentsIfSoldClient.subscribe(valueOfAllInvestmentsIfSoldClient => this.valueOfAllInvestmentsIfSoldClient = valueOfAllInvestmentsIfSoldClient);
    this.sharedDataService.valueOfAllInvestmentsIfSoldPartner.subscribe(valueOfAllInvestmentsIfSoldPartner => this.valueOfAllInvestmentsIfSoldPartner = valueOfAllInvestmentsIfSoldPartner);
    this.sharedDataService.valueOfAllInvestmentsIfSoldJoint.subscribe(valueOfAllInvestmentsIfSoldJoint => this.valueOfAllInvestmentsIfSoldJoint = valueOfAllInvestmentsIfSoldJoint);
    
    this.sharedDataService.totalValueOfInvestmentsIfSold.subscribe(totalValueOfInvestmentsIfSold => this.totalValueOfInvestmentsIfSold = totalValueOfInvestmentsIfSold);
    this.sharedDataService.investmentPropertiesClient.subscribe(investmentPropertiesClient => this.investmentPropertiesClient = investmentPropertiesClient);
    this.sharedDataService.investmentPropertiesPartner.subscribe(investmentPropertiesPartner => this.investmentPropertiesPartner = investmentPropertiesPartner);

    this.sharedDataService.mainUserFinanceTotal.subscribe(mainUserFinanceTotal => this.mainUserFinanceTotal = mainUserFinanceTotal);
    this.sharedDataService.partnerUserFinanceTotal.subscribe(partnerUserFinanceTotal => this.partnerUserFinanceTotal = partnerUserFinanceTotal);
    this.sharedDataService.jointUserFinanceTotal.subscribe(jointUserFinanceTotal => this.jointUserFinanceTotal = jointUserFinanceTotal);
    this.sharedDataService.income.subscribe(income => this.income = income);
    this.sharedDataService.incomePartner.subscribe(incomePartner => this.incomePartner = incomePartner);
    this.sharedDataService.salaryOwner.subscribe(salaryOwner => this.salaryOwner = salaryOwner);
    this.sharedDataService.salaryPartner.subscribe(salaryPartner => this.salaryPartner = salaryPartner);

    this.sharedDataService.client_code.subscribe(client_code => this.client_code = client_code);
    this.sharedDataService.partnerClientCode.subscribe(partnerClientCode => this.partnerClientCode = partnerClientCode);
    this.sharedDataService.provisionInfoDependent.subscribe(provisionInfoDependent => this.provisionInfoDependent = provisionInfoDependent);
    
    this.sharedDataService.superBalance.subscribe(superBalance => this.superBalance = superBalance);
    this.sharedDataService.superBalancePartner.subscribe(superBalancePartner => this.superBalancePartner = superBalancePartner);
    
  }

  ngOnChanges() {
    this.financesInfo = this.financesInfo
    this.insurableIncomeClient = this.insurableIncomeClient
    this.insurableIncomePartner = this.insurableIncomePartner
    this.annualBenefitClient = this.annualBenefitClient
    this.annualBenefitPartner = this.annualBenefitPartner
    this.totalDebt = this.totalDebt
    this.deceasedPersonsSuperClient = this.deceasedPersonsSuperClient
    this.deceasedPersonsSuperPartner = this.deceasedPersonsSuperPartner
    this.valueOfAllInvestmentsIfSoldClient = this.valueOfAllInvestmentsIfSoldClient
    this.valueOfAllInvestmentsIfSoldPartner = this.valueOfAllInvestmentsIfSoldPartner
    this.valueOfAllInvestmentsIfSoldJoint = this.valueOfAllInvestmentsIfSoldJoint
    this.totalValueOfInvestmentsIfSold = this.totalValueOfInvestmentsIfSold
    this.investmentPropertiesClient = this.investmentPropertiesClient
    this.investmentPropertiesPartner = this.investmentPropertiesPartner
    this.mainUserFinanceTotal = this.mainUserFinanceTotal
    this.partnerUserFinanceTotal = this.partnerUserFinanceTotal
    this.jointUserFinanceTotal = this.jointUserFinanceTotal

    this.income = this.income;
    this.incomePartner = this.incomePartner;

    this.salaryOwner = this.salaryOwner
    this.salaryPartner = this.salaryPartner
    this.superBalance = this.superBalance
    this.superBalancePartner = this.superBalancePartner

    this.sharedDataService.changFinancesInfo(this.financesInfo);
    this.sharedDataService.changInsurableIncomeClient(this.insurableIncomeClient);
    this.sharedDataService.changInsurableIncomePartner(this.insurableIncomePartner);
    this.sharedDataService.changAnnualBenefitClient(this.annualBenefitClient);
    this.sharedDataService.changAnnualBenefitPartner(this.annualBenefitPartner);
    this.sharedDataService.changTotalDebt(this.totalDebt);
    this.sharedDataService.changDeceasedPersonsSuperClient(this.deceasedPersonsSuperClient);
    this.sharedDataService.changDeceasedPersonsSuperPartner(this.deceasedPersonsSuperPartner);
    this.sharedDataService.changValueOfAllInvestmentsIfSoldClient(this.valueOfAllInvestmentsIfSoldClient);
    this.sharedDataService.changValueOfAllInvestmentsIfSoldPartner(this.valueOfAllInvestmentsIfSoldPartner);
    this.sharedDataService.changValueOfAllInvestmentsIfSoldJoint(this.valueOfAllInvestmentsIfSoldJoint);
    this.sharedDataService.changTotalValueOfInvestmentsIfSold(this.totalValueOfInvestmentsIfSold);
    this.sharedDataService.changInvestmentPropertiesClient(this.investmentPropertiesClient);
    this.sharedDataService.changInvestmentPropertiesPartner(this.investmentPropertiesPartner);
    this.sharedDataService.changMainUserFinanceTotal(this.mainUserFinanceTotal);
    // this.sharedDataService.changPartnerUserFinanceTotal(this.partnerUserFinanceTotal);
    // this.sharedDataService.changJointUserFinanceTotal(this.jointUserFinanceTotal);
  
    this.sharedDataService.changIncome(this.income);
    this.sharedDataService.changIncomePartner(this.incomePartner);

    this.sharedDataService.changSalaryOwner(this.salaryOwner);
    this.sharedDataService.changSalaryPartner(this.salaryPartner);
    this.sharedDataService.changSuperBalance(this.superBalance);
    this.sharedDataService.changSuperBalancePartner(this.superBalancePartner);
  }
  

  getFinancesInfo(){
    // this.ngxService.start(); 
    var financedata=JSON.parse(localStorage.getItem("financesInfo"));
   
    if(financedata!=null){
      console.log("checkhere")
      console.log(financedata);
     
      this.financesInfo = financedata;
      this.isLoaded7=true;
          
      this.needtoload=true;
      loaddata();
      
    }else{
   
      this.familyTreeService.getFinances(this.client_id, this.xeppoCode).subscribe((financesData:FinancesResponse)=>{
        if(financesData.success){
        
          
          localStorage.setItem('financesInfo', JSON.stringify(financesData.response));
          this.financesInfo = financesData.response;
   
          
this.isLoaded7=true;
this.needtoload=true;
          loaddata();
        
        }

      },
      (err: HttpErrorResponse)=>{
        console.log("Error"+ err.message);
      });
    }
    
   
    function loaddata(){
      if(this.needtoload){
  
  
          this.familyTreeService.changeMessage("Hello from Second Component");
            console.log("this is other call");
            this.sharedDataService.changFinancesInfo(this.financesInfo);
          
           
             if(this.financesInfo.income.length>0 && this.financesInfo.superContributionRate.length>0) {
          
               //// ---- According to Ishare confirmation, change this "superContributionRate =0 to superContributionRate =9.5" (2021/04/22) --- Start ---
               if(this.financesInfo.superContributionRate[0].value == 0) {
                 this.financesInfo.superContributionRate[0].value = 10;  // 9.5  is previous value
               }
          
               if(this.financesInfo.superContributionRate[1].value == 0) {
                 this.financesInfo.superContributionRate[1].value = 10; // 9.5  is previous value
               }
          
               //// now ifact all the calculation (if 0, multiply 9.5)
               //// ---- End ---
          
               if(this.financesInfo.income[0].ownerType == 1 && this.financesInfo.superContributionRate[0].value > 0) {
                 this.insurableIncomeClient = this.financesInfo.income[0].value + (this.financesInfo.income[0].value * this.financesInfo.superContributionRate[0].value/100);
                 this.sharedDataService.changInsurableIncomeClient(this.insurableIncomeClient);
               }
               else if(this.financesInfo.income[0].ownerType == 1 && this.financesInfo.superContributionRate[0].value == 0) {
                 this.insurableIncomeClient = this.financesInfo.income[0].value;
                 this.sharedDataService.changInsurableIncomeClient(this.insurableIncomeClient);
               }
          
               if(this.financesInfo.income[1].ownerType == 2 && this.financesInfo.superContributionRate[1].value > 0) {
                 this.insurableIncomePartner = this.financesInfo.income[1].value + (this.financesInfo.income[1].value * this.financesInfo.superContributionRate[1].value/100);
                 this.sharedDataService.changInsurableIncomePartner(this.insurableIncomePartner);
               }
               else if(this.financesInfo.income[1].ownerType == 2 && this.financesInfo.superContributionRate[1].value == 0) {
                 this.insurableIncomePartner = this.financesInfo.income[1].value;
                 this.sharedDataService.changInsurableIncomePartner(this.insurableIncomePartner);
               }
          
               console.log(this.insurableIncomePartner , '=======this.insurableIncomeClient====');
               if(this.insurableIncomeClient>0){
                 this.annualBenefitClient = this.insurableIncomeClient * 75/100;
                 this.sharedDataService.changAnnualBenefitClient(this.annualBenefitClient);
               }
          
               if(this.insurableIncomePartner>0){
                 this.annualBenefitPartner = this.insurableIncomePartner * 75/100;
                 this.sharedDataService.changAnnualBenefitPartner(this.annualBenefitPartner);
               }
          
             }
          
             if(this.financesInfo.income.length>0) {
               this.income = this.financesInfo.income[0].value;
               this.incomePartner = this.financesInfo.income[1].value;
             }
          
             this.totalDebt = 0;
             if(this.financesInfo.deductibleDebt.length>0 && this.financesInfo.nonDeductibleDebt.length>0) {
               for(let j=0;j<this.financesInfo.deductibleDebt.length;j++){  
                 this.totalDebt += this.financesInfo.deductibleDebt[j].value;  
               } 
               
               for(let k=0;k<this.financesInfo.nonDeductibleDebt.length;k++){  
                 this.totalDebt += this.financesInfo.nonDeductibleDebt[k].value;  
               } 
             }
             
             this.valueOfAllInvestmentsIfSoldClient = 0;
             this.valueOfAllInvestmentsIfSoldPartner = 0;
             this.valueOfAllInvestmentsIfSoldJoint = 0;
          
             this.mainUserFinanceTotal = 0;
             this.partnerUserFinanceTotal = 0;
             this.jointUserFinanceTotal = 0;
          
             if(this.financesInfo.superBalance.length>0) {
               this.deceasedPersonsSuperClient = this.financesInfo.superBalance[0].value;
               this.deceasedPersonsSuperPartner = this.financesInfo.superBalance[1].value;
          
               this.mainUserFinanceTotal += this.financesInfo.superBalance[0].value;
               this.partnerUserFinanceTotal += this.financesInfo.superBalance[1].value;
               // this.jointUserFinanceTotal += this.financesInfo.superBalance[2].value;
          
               this.superBalance = this.financesInfo.superBalance[0].value;
               this.superBalancePartner = this.financesInfo.superBalance[1].value
               this.sharedDataService.changSuperBalance(this.superBalance);
               this.sharedDataService.changSuperBalancePartner(this.superBalancePartner);
             }
          
             if(this.financesInfo.primaryPlaceofResidence.length>0) {
               this.mainUserFinanceTotal += this.financesInfo.primaryPlaceofResidence[0].value;
               this.partnerUserFinanceTotal += this.financesInfo.primaryPlaceofResidence[1].value;
               this.jointUserFinanceTotal += this.financesInfo.primaryPlaceofResidence[2].value;
             }
          
             if(this.financesInfo.investmentProperties.length>0) {
               this.valueOfAllInvestmentsIfSoldClient += this.financesInfo.investmentProperties[0].value;
               this.valueOfAllInvestmentsIfSoldPartner += this.financesInfo.investmentProperties[1].value;
               this.valueOfAllInvestmentsIfSoldJoint += this.financesInfo.investmentProperties[2].value;
          
               this.investmentPropertiesClient = this.financesInfo.investmentProperties[0].value;
               this.investmentPropertiesPartner = this.financesInfo.investmentProperties[1].value;
          
               this.mainUserFinanceTotal += this.financesInfo.investmentProperties[0].value;
               this.partnerUserFinanceTotal += this.financesInfo.investmentProperties[1].value;
               this.jointUserFinanceTotal += this.financesInfo.investmentProperties[2].value;
             }
          
             this.totalValueOfInvestmentsIfSold = 0;
          
             if(this.financesInfo.cash.length>0) {
               this.valueOfAllInvestmentsIfSoldClient += this.financesInfo.cash[0].value;
               this.valueOfAllInvestmentsIfSoldPartner += this.financesInfo.cash[1].value;
               this.valueOfAllInvestmentsIfSoldJoint += this.financesInfo.cash[2].value;
          
               this.mainUserFinanceTotal += this.financesInfo.cash[0].value;
               this.partnerUserFinanceTotal += this.financesInfo.cash[1].value;
               this.jointUserFinanceTotal += this.financesInfo.cash[2].value;
          
               for(let j=0;j<this.financesInfo.cash.length;j++){  
                 this.totalValueOfInvestmentsIfSold += this.financesInfo.cash[j].value;  
               } 
             }
          
             // if(this.financesInfo.managedFunds.length>0) {
             //   this.valueOfAllInvestmentsIfSoldClient += this.financesInfo.managedFunds[0].value;
             //   this.valueOfAllInvestmentsIfSoldPartner += this.financesInfo.managedFunds[1].value;
             //   this.valueOfAllInvestmentsIfSoldJoint += this.financesInfo.managedFunds[2].value;
          
             //   this.mainUserFinanceTotal += this.financesInfo.managedFunds[0].value;
             //   this.partnerUserFinanceTotal += this.financesInfo.managedFunds[1].value;
             //   this.jointUserFinanceTotal += this.financesInfo.managedFunds[2].value;
               
             //   for(let k=0;k<this.financesInfo.managedFunds.length;k++){  
             //     this.totalValueOfInvestmentsIfSold += this.financesInfo.managedFunds[k].value;  
             //   } 
             // }
          
             // if(this.financesInfo.shares.length>0) {
             //   this.valueOfAllInvestmentsIfSoldClient += this.financesInfo.shares[0].value;
             //   this.valueOfAllInvestmentsIfSoldPartner += this.financesInfo.shares[1].value;
             //   this.valueOfAllInvestmentsIfSoldJoint += this.financesInfo.shares[2].value;
          
             //   this.mainUserFinanceTotal += this.financesInfo.shares[0].value;
             //   this.partnerUserFinanceTotal += this.financesInfo.shares[1].value;
             //   this.jointUserFinanceTotal += this.financesInfo.shares[2].value;
          
             //   for(let l=0;l<this.financesInfo.shares.length;l++){  
             //     this.totalValueOfInvestmentsIfSold += this.financesInfo.shares[l].value;  
             //   } 
             // }
          
             if(this.financesInfo.investment.length>0) {
               this.valueOfAllInvestmentsIfSoldClient += this.financesInfo.investment[0].value;
               this.valueOfAllInvestmentsIfSoldPartner += this.financesInfo.investment[1].value;
               this.valueOfAllInvestmentsIfSoldJoint += this.financesInfo.investment[2].value;
          
               this.mainUserFinanceTotal += this.financesInfo.investment[0].value;
               this.partnerUserFinanceTotal += this.financesInfo.investment[1].value;
               this.jointUserFinanceTotal += this.financesInfo.investment[2].value;
               
               for(let k=0;k<this.financesInfo.investment.length;k++){  
                 this.totalValueOfInvestmentsIfSold += this.financesInfo.investment[k].value;  
               } 
             }
          
             if(this.financesInfo.salary.length>0) {
               this.salaryOwner =  this.financesInfo.salary[0].value;
               this.salaryPartner =  this.financesInfo.salary[1].value;
          
               this.sharedDataService.changSalaryOwner(this.salaryOwner);
               this.sharedDataService.changSalaryPartner(this.salaryPartner);
             }
             
          
             this.sharedDataService.changTotalDebt(this.totalDebt);
             this.sharedDataService.changDeceasedPersonsSuperClient(this.deceasedPersonsSuperClient);
             this.sharedDataService.changDeceasedPersonsSuperPartner(this.deceasedPersonsSuperPartner);
             this.sharedDataService.changValueOfAllInvestmentsIfSoldClient(this.valueOfAllInvestmentsIfSoldClient);
             this.sharedDataService.changValueOfAllInvestmentsIfSoldPartner(this.valueOfAllInvestmentsIfSoldPartner);
             this.sharedDataService.changValueOfAllInvestmentsIfSoldJoint(this.valueOfAllInvestmentsIfSoldJoint);
             this.sharedDataService.changTotalValueOfInvestmentsIfSold(this.totalValueOfInvestmentsIfSold);
             this.sharedDataService.changInvestmentPropertiesClient(this.investmentPropertiesClient);
             this.sharedDataService.changInvestmentPropertiesPartner(this.investmentPropertiesPartner);
          
             this.sharedDataService.changMainUserFinanceTotal(this.mainUserFinanceTotal);
             this.sharedDataService.changPartnerUserFinanceTotal(this.partnerUserFinanceTotal);
             this.sharedDataService.changJointUserFinanceTotal(this.jointUserFinanceTotal);
          
             this.sharedDataService.changIncome(this.income);
             this.sharedDataService.changIncomePartner(this.incomePartner);
          
             
             this.isLoaded7=true
          
       
      }
    }


    
    
  

     
 
    

     
       
    
      // this.ngxService.stop(); 
      
  }
  ngOnDestroy() {
    console.log("I am Destroied");
  }
  noteSave(treeListFromServer2, event: any){
    // console.log(retirement);

    localStorage.removeItem("financesInfo");
    let value = event.target.value;
    let inputId = event.target.id;
    let errorVal = true;
    // console.log(treeListFromServer2);
    // console.log(value);

    if(treeListFromServer2==null){
      this.toastr.error('Error on updating note.');  
    }
    else {
      let familyTreeId  = treeListFromServer2.familyTreeId;

      // if(inputId == 'retirementAge' || inputId == 'retirementAgePartner') {
        // this.retirementAdd.retirementAge = value;
      // }
       
      if(errorVal){
        this.familyTreeService.addFinancesNote(familyTreeId , value).subscribe((data:FinancesResponse)=>{
          if(data.error && !data.success){      
            this.toastr.error('Error on updating note.');      
          }
          else{
            this.toastr.success('Note updated successfully.');
          }
    
          // this.getRetirementInfo();
        },err=>{   
          console.log(err);
          this.toastr.error('Error on updating note.');
        });
      }
    }
  }

  sCRChange(userType, event, financesInfo) {
    let familyTreeId  = financesInfo.familyTreeId;
    let clientCode = ''

    if(userType == 1){
      clientCode = this.client_code;
      this.financesInfo.superContributionRate[0].value == event.target.value;
      this.insurableIncomeClient = this.financesInfo.income[0].value + (this.financesInfo.income[0].value * this.financesInfo.superContributionRate[0].value/100);
      this.sharedDataService.changInsurableIncomeClient(this.insurableIncomeClient);
    }
    if(userType == 2){
      clientCode = this.partnerClientCode;
      this.financesInfo.superContributionRate[1].value == event.target.value;
      this.insurableIncomePartner = this.financesInfo.income[1].value + (this.financesInfo.income[1].value * this.financesInfo.superContributionRate[1].value/100);
      this.sharedDataService.changInsurableIncomePartner(this.insurableIncomePartner);
    }

    let value = event.target.value;
    value = value.replace(/\%/g, "");
    console.log('kkkkk=',value)
    if(financesInfo==null || value==''){
      this.toastr.error('Error on updating note.');  
    }
    else {
       
      
        this.familyTreeService.superContributionRateChange(familyTreeId , clientCode, value).subscribe((data:FinancesResponse)=>{
          if(data.error && !data.success){      
            this.toastr.error('Error on updating note.');      
          }
          else{
            this.toastr.success('Note updated successfully.');
          }
    
          // this.getRetirementInfo();
        },err=>{   
          console.log(err);
          this.toastr.error('Error on updating note.');
        });
    }

  }
}
