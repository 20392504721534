<div id="calResult" class="pt-5">
    <div class="row m-0 tree-heading">
        <div class="bgcolor"></div>
        <div class="right-1 float-right textr pr-5">
            <h5>FAMILY TREE</h5>
            <h4>{{name}}</h4>
            <h6>GENERATED DATE {{today | date: 'dd/MM/yyyy'}}</h6>
        </div>
    </div>

    <div class="row A_Sum m_lr justify-content-center">
        <img class="family-tree" src="{{imageURL}}">
    </div>
</div>