import { Component, OnInit } from '@angular/core';
import { FamilyTreeService } from '../../family-tree.service';
import { WillResponse } from 'src/app/shared/models/response.model';
import { WillModel,  ExecutorsList } from 'src/app/shared/models/will.model';
import { HttpErrorResponse } from '@angular/common/http';
import { SharedDataService } from 'src/app/shared/service/shared-data.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-will',
  templateUrl: './will.component.html',
  styleUrls: ['../rightside.component.css']
})
export class WillComponent implements OnInit {

  willInfo:WillModel;
  showCBUsers: boolean = false;
  isLoaded6: boolean;
  family_tree_id: string = "";
  willExecutorsNoteAdd:ExecutorsList;
  client_code: string;
  partnerClientCode:string;
  partnerHas:boolean = false;
  clientFirstname:string;
  partnerFirstname:string;
  
  constructor(
    private familyTreeService :FamilyTreeService,
    private sharedDataService: SharedDataService,
  ) {
    this.sharedDataService.familyTreeId.subscribe(family_tree_id => this.family_tree_id = family_tree_id);
    this.sharedDataService.client_code.subscribe(client_code => this.client_code = client_code);
    this.sharedDataService.partnerClientCode.subscribe(partnerClientCode => this.partnerClientCode = partnerClientCode);
    this.sharedDataService.partnerHas.subscribe(partnerHas => this.partnerHas = partnerHas);
    this.sharedDataService.clientFirstname.subscribe(clientFirstname => this.clientFirstname = clientFirstname);
    this.sharedDataService.partnerFirstname.subscribe(partnerFirstname => this.partnerFirstname = partnerFirstname);
   }

  ngOnInit(): void {
    this.getWillInfo();
  }


  getWillInfo(){
    // this.ngxService.start();
    this.familyTreeService.getWill(this.family_tree_id).subscribe((willData:WillResponse)=>{
      if(willData.success){
        this.willInfo = willData.response;
        //console.log(this.willInfo);
        if(this.willInfo.calamityBeneficiaries.type == 2) {
          this.showCBUsers = true;
        }
        else {
          this.showCBUsers = false;
        }
        // let executorNmae = this.willInfo.executors.clientExecutors.name,
        // executorType = this.willInfo.executors.clientExecutors.combinedRetirementIncome,
        // precentageIfPartnerDeceased = this.willInfo.executors.partnerExecutors.precentageIfPartnerDeceased,
        // precentageIfPartnerDeceased = this.willInfo.executors.partnerExecutors.precentageIfPartnerDeceased;
          
        this.isLoaded6 = true;
      } 
      // this.ngxService.stop(); 
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });
  }


}
