<ng-container *ngIf="isLoaded">
    <form [formGroup]="addImmediateNeedsForm" novalidate class="form " >
        <table class="table tablecollect">
            <thead>
            <tr>
                <th class="tc2 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs">Immediate Needs</th>
                <th class="tc1 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs pdAlignTableHead val1">{{clientFirstname}}</th>
                <ng-container *ngIf="partnerHas">
                <th class="tc1 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs val2">{{partnerFirstname}}</th>
                </ng-container>
            </tr>
            </thead>
            <tbody> 
            <tr>
            <td class="tablefist tableb">Alternative home purchase </td>
            <td class="tableleftbedit edit-border">
                <input type="text" formControlName="homePurchase" id="homePurchase" (change)="immediateNeedsSave(provisionInfo.immediateNeeds.clientImmediateNeeds, $event)" mask="separator.0" thousandSeparator="," class="form-control neweditinput do" placeholder="">
            </td>
            <ng-container *ngIf="partnerHas">
                <td class="tableleftbedit edit-border">
                <input type="text" formControlName="homePurchasePartner" id="homePurchasePartner" (change)="immediateNeedsSave(provisionInfo.immediateNeeds.partnerImmediateNeeds, $event)" mask="separator.0" thousandSeparator="," class="form-control neweditinput do" placeholder="">
                </td>
            </ng-container>
            </tr>
            <tr>
            <td class="tablefist tableb">Modifications to the home </td>
            <td class="tableleftbedit edit-border">
                <input type="text" formControlName="modificationHome" mask="separator.0" thousandSeparator="," id="modificationHome" (change)="immediateNeedsSave(provisionInfo.immediateNeeds.clientImmediateNeeds, $event)" class="form-control neweditinput do" placeholder="">
            </td>
            <ng-container *ngIf="partnerHas">
                <td class="tableleftbedit edit-border">
                <input type="text" formControlName="modificationHomePartner" mask="separator.0" thousandSeparator="," id="modificationHomePartner" (change)="immediateNeedsSave(provisionInfo.immediateNeeds.partnerImmediateNeeds, $event)" class="form-control neweditinput do" placeholder="">
                </td>
            </ng-container>
            
            </tr>
            <tr>
            <td class="tablefist tableb">Medical treatment </td>
            <td class="tableleftbedit edit-border">
                <input type="text" formControlName="medicalTreatment" id="medicalTreatment" (change)="immediateNeedsSave(provisionInfo.immediateNeeds.clientImmediateNeeds, $event)" mask="separator.0" thousandSeparator="," class="form-control neweditinput do" placeholder="">
            </td>
            <ng-container *ngIf="partnerHas">
                <td class="tableleftbedit edit-border">
                <input type="text" formControlName="medicalTreatmentPartner" id="medicalTreatmentPartner" (change)="immediateNeedsSave(provisionInfo.immediateNeeds.partnerImmediateNeeds, $event)" mask="separator.0" thousandSeparator="," class="form-control neweditinput do" placeholder="">
                </td>
            </ng-container>
            </tr>
            <tr>
            <td class="tablefist tableb">Funeral & Legal Expenses </td>
            <td class="tableleftbedit edit-border">
                <input type="text" formControlName="funeralLegalExpenses" id="funeralLegalExpenses" (change)="immediateNeedsSave(provisionInfo.immediateNeeds.clientImmediateNeeds, $event)" mask="separator.0" thousandSeparator="," class="form-control neweditinput do" placeholder="">
            </td>
            <ng-container *ngIf="partnerHas">
                <td class="tableleftbedit edit-border">
                    <input type="text" formControlName="funeralLegalExpensesPartner" id="funeralLegalExpensesPartner" (change)="immediateNeedsSave(provisionInfo.immediateNeeds.partnerImmediateNeeds, $event)" mask="separator.0" thousandSeparator="," class="form-control neweditinput do" placeholder="">
                </td>
            </ng-container>
            </tr>
            <tr>
            <td class="tablefist tableb">Upfront Capital Needs (Trauma) </td>
            <td class="tableleftbedit edit-border">
                <input type="text" formControlName="upfrontCapitalNeeds" id="upfrontCapitalNeeds" (change)="immediateNeedsSave(provisionInfo.immediateNeeds.clientImmediateNeeds, $event)" mask="separator.0" thousandSeparator="," class="form-control neweditinput do" placeholder="">
            </td>
            <ng-container *ngIf="partnerHas">
                <td class="tableleftbedit edit-border">
                    <input type="text" formControlName="upfrontCapitalNeedsPartner" id="upfrontCapitalNeedsPartner" (change)="immediateNeedsSave(provisionInfo.immediateNeeds.partnerImmediateNeeds, $event)" mask="separator.0" thousandSeparator="," class="form-control neweditinput do" placeholder="">
                </td>
            </ng-container>
            </tr>                
            </tbody>
        </table>
    </form>
</ng-container>
