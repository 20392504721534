import { Component, OnInit, Input } from '@angular/core';
import { WillModel, OtherEntities, OtherEntitie } from 'src/app/shared/models/will.model';
import { WillResponse, OtherEntityNoteResponse, OtherEntityItemResponse } from 'src/app/shared/models/response.model';
import { HttpErrorResponse } from '@angular/common/http';
import { FamilyTreeService } from 'src/app/family-tree/family-tree.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-other-entities',
  templateUrl: './other-entities.component.html',
  styleUrls: ['../../rightside.component.css']
})
export class OtherEntitiesComponent implements OnInit {

  @Input() family_tree_id:string;
  @Input() client_code:string;
  @Input() partnerClientCode:string;
  @Input() clientFirstname:string;
  @Input() partnerFirstname:string;
  @Input() partnerHas:boolean;
  @Input() willInfo:WillModel;
  isLoaded6:boolean;
  otherEntitie: any=[];
  otherEntities: any=[];

  constructor(
    private familyTreeService :FamilyTreeService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
  }

  getWillInfo(){
    // this.ngxService.start();
    this.familyTreeService.getWill(this.family_tree_id).subscribe((willData:WillResponse)=>{
      if(willData.success){
        this.willInfo = willData.response;
        
        // let executorNmae = this.willInfo.executors.clientExecutors.name,
        // executorType = this.willInfo.executors.clientExecutors.combinedRetirementIncome,
        // precentageIfPartnerDeceased = this.willInfo.executors.partnerExecutors.precentageIfPartnerDeceased,
        // precentageIfPartnerDeceased = this.willInfo.executors.partnerExecutors.precentageIfPartnerDeceased;
          
        this.isLoaded6 = true;
      } 
      // this.ngxService.stop(); 
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });
  }

  otherEntityItemSave(otherEntitie, event: any){
    // console.log(otherEntitie);
    let value = event.target.value;
    let inputId = event.target.id;
    // console.log(value);
    // console.log(inputId);

    this.otherEntitie = new OtherEntitie;
    
    this.otherEntitie.clientCode = otherEntitie.clientCode;
    this.otherEntitie.details = value;
    
    
    // console.log(this.willExecutorsNoteAdd);
    this.familyTreeService.editOtherEntityItem(otherEntitie.id, this.otherEntitie).subscribe((data:OtherEntityItemResponse)=>{
      if(data.error && !data.success){      
        this.toastr.error('Error on updating details.');      
      }
      else{
        this.toastr.success('Details updated successfully.');
      }

      this.getWillInfo();
    },err=>{   
      console.log(err);
      this.toastr.error('Error on updating details.');
    });
  }

  otherEntityNoteSave(otherEntities, event: any){
    // console.log(otherEntities);
    let value = event.target.value;
    let inputId = event.target.id;
    // console.log(value);
    // console.log(inputId);

    this.otherEntities = new OtherEntities;
    
    this.otherEntities.note = value;
    
    
    // console.log(this.willExecutorsNoteAdd);
    this.familyTreeService.editOtherEntityNote(otherEntities.id, this.otherEntities).subscribe((data:OtherEntityNoteResponse)=>{
      if(data.error && !data.success){      
        this.toastr.error('Error on updating note.');      
      }
      else{
        this.toastr.success('Note updated successfully.');
      }

      this.getWillInfo();
    },err=>{   
      console.log(err);
      this.toastr.error('Error on updating note.');
    });
  }


}
