<ng-container *ngIf="isLoaded">
    <form [formGroup]="addProgressiveNeedsForm" novalidate class="form " >
        <table class="table tablecollect">
            <thead>
            <tr>
                <th class="tc2 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs">Progressive Needs</th>
                <th class="tc1 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs pdAlignTableHead val1">{{clientFirstname}}</th>
                <ng-container *ngIf="partnerHas">
                <th class="tc1 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs val2">{{partnerFirstname}}</th>
                </ng-container>
            </tr>
            </thead>
            <tbody> 
            <tr>
            <td class="tablefist tableb">Provision for care if you were totally disabled </td>
            <td class="tableleftbedit edit-border">
                <input type="text" formControlName="provisionForTotallyDisabled" id="provisionForTotallyDisabled" (change)="progressiveNeedsSave(provisionInfo.progressiveNeeds.clientProgressiveNeeds, $event)" mask="separator.0" thousandSeparator="," class="form-control neweditinput do" placeholder="">
            </td>
            <ng-container *ngIf="partnerHas">
                <td class="tableleftbedit edit-border">
                    <input type="text" formControlName="provisionForTotallyDisabledPartner" id="provisionForTotallyDisabledPartner" (change)="progressiveNeedsSave(provisionInfo.progressiveNeeds.partnerProgressiveNeeds, $event)" mask="separator.0" thousandSeparator="," class="form-control neweditinput do" placeholder="">
                </td>
            </ng-container>
            </tr>
            <tr>
            <td class="tablefist tableb">Passive income for spouse if you die </td>
            <td class="tableleftbedit edit-border">
                <input type="text" formControlName="passiveIncomeForSpouse" id="passiveIncomeForSpouse" (change)="progressiveNeedsSave(provisionInfo.progressiveNeeds.clientProgressiveNeeds, $event)" mask="separator.0" thousandSeparator="," class="form-control neweditinput do" placeholder="">
            </td>
            <ng-container *ngIf="partnerHas">
                <td class="tableleftbedit edit-border">
                    <input type="text" formControlName="passiveIncomeForSpousePartner" id="passiveIncomeForSpousePartner" (change)="progressiveNeedsSave(provisionInfo.progressiveNeeds.partnerProgressiveNeeds, $event)" mask="separator.0" thousandSeparator="," class="form-control neweditinput do" placeholder="">
                </td>
            </ng-container>
            </tr>
            <tr>
            <td class="tablefist tableb">Passive income continued to be paid to you in event of TPD or Trauma? </td>
            <td class="tableleftbedit edit-border">
                <input type="text" formControlName="passiveIncomeContinuedPaid" id="passiveIncomeContinuedPaid" (change)="progressiveNeedsSave(provisionInfo.progressiveNeeds.clientProgressiveNeeds, $event)" mask="separator.0" thousandSeparator="," class="form-control neweditinput do" placeholder="">
            </td>
            <ng-container *ngIf="partnerHas">
                <td class="tableleftbedit edit-border">
                    <input type="text" formControlName="passiveIncomeContinuedPaidPartner" id="passiveIncomeContinuedPaidPartner" (change)="progressiveNeedsSave(provisionInfo.progressiveNeeds.partnerProgressiveNeeds, $event)" mask="separator.0" thousandSeparator="," class="form-control neweditinput do" placeholder="">
                </td>
            </ng-container>
            </tr>
            <tr>
            <td class="tablefist tableb">Provision for ongoing medical costs </td>
            <td class="tableleftbedit edit-border">
                <input type="text" formControlName="provisionForOngoingMedicalCosts" id="provisionForOngoingMedicalCosts" (change)="progressiveNeedsSave(provisionInfo.progressiveNeeds.clientProgressiveNeeds, $event)" mask="separator.0" thousandSeparator="," class="form-control neweditinput do" placeholder="">
            </td>
            <ng-container *ngIf="partnerHas">
                <td class="tableleftbedit edit-border">
                    <input type="text" formControlName="provisionForOngoingMedicalCostsPartner" id="provisionForOngoingMedicalCostsPartner" (change)="progressiveNeedsSave(provisionInfo.progressiveNeeds.partnerProgressiveNeeds, $event)" mask="separator.0" thousandSeparator="," class="form-control neweditinput do" placeholder="">
                </td>
            </ng-container>
            </tr>
            <tr>
            <td class="tablefist tableb">How much income would you like to leave per child? </td>
            <td class="tableleftbedit edit-border">
                <input type="text" formControlName="incomeLeavePerChild" id="incomeLeavePerChild" (change)="progressiveNeedsSave(provisionInfo.progressiveNeeds.clientProgressiveNeeds, $event)" mask="separator.0" thousandSeparator="," class="form-control neweditinput do" placeholder="">
            </td>
            <ng-container *ngIf="partnerHas">
                <td class="tableleftbedit edit-border">
                    <input type="text" formControlName="incomeLeavePerChildPartner" id="incomeLeavePerChildPartner" (change)="progressiveNeedsSave(provisionInfo.progressiveNeeds.partnerProgressiveNeeds, $event)" mask="separator.0" thousandSeparator="," class="form-control neweditinput do" placeholder="">
                </td>
            </ng-container>
            </tr>                
            </tbody>
        </table>
    </form>
</ng-container>