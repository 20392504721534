import { Component, OnInit, Input } from '@angular/core';
import { ProvisionsComponent } from '../../provisions/provisions.component';
import { SharedDataService } from 'src/app/shared/service/shared-data.service';
import { InsurancesList } from 'src/app/shared/models/insurance.model';
import { ToastrService } from 'ngx-toastr';
import { FamilyTreeService } from 'src/app/family-tree/family-tree.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ProposedInsuranceResponse, ProposedInsuranceAddResponse, DeleteOtherProvisionResponse } from 'src/app/shared/models/response.model';
import { RightsidePipe } from 'src/app/shared/custom-pipes/rightside.pipe';
import { ProgressiveNeedsTPDModel } from './tpd.mode';

@Component({
  selector: 'app-tpd',
  templateUrl: './tpd.component.html',
  styleUrls: ['../../rightside.component.css'],
  providers: [ RightsidePipe ]
})
export class TpdComponent implements OnInit {

  @Input() family_tree_id:string;
  @Input() isLoaded3:boolean;
  @Input() isLoaded8:boolean;
  @Input() clientFirstname:string;
  @Input() partnerFirstname:string;
  @Input() provisionInfo:any;
  @Input() insuranceInfo:any;
  @Input() proposedInsuranceInfo:any;
  @Input() client_code:string;
  @Input() clientID:string;
  @Input() partnerID:string;
  totalDebt:number;
  housePurchaseClient:number;
  housePurchasePartner:number;
  financesInfo:any;
  investmentPropertiesClient:number;
  investmentPropertiesPartner:number;

  totalValueOfInvestmentsIfSold:number;
  homeModificationClient:number;
  homeModificationPartner:number;
  medicalTreatmentClient:number;
  medicalTreatmentPartner:number;
  immediateNeedsTotalClient:number;
  immediateNeedsTotalPartner:number;
  immediateNonSuperIncomingAssetsTotalClient:number;
  immediateNonSuperIncomingAssetsTotalPartner:number;
  cashedOutOfSuperImmediatelyClient:number;
  cashedOutOfSuperImmediatelyPartner:number;
  grossUpForTaxClient:number;
  grossUpForTaxPartner:number;
  totalNetImmediateNeedTPDClient:number;
  totalNetImmediateNeedTPDPartner:number;

  provisionsCompOneObj: ProvisionsComponent;
  addProposedInsuranceData: any=[];

  incomePartner:number = 0;
  yearsNeedOf:any;
  yearsNeedOfPartner:any;
  salaryOwner: number = 0;
  salaryPartner: number = 0;
  provisionForTotallyDisabled: number = 0;
  passiveIncomeContinuedPaid: number = 0;
  passiveIncomeContinuedPaidPartner: number = 0;
  combinedRetirementIncome: number = 0;
  provisionForOngoingMedicalCosts: number = 0;
  mainNodeAge:number =0;
  mainNodeGender:any =0;
  incomeSupportFromRetirementToLongestLifeExpectancy:number =0;
  incomeSupportFromRetirementToLongestLifeExpectancyPartner:number =0;
  partnerAge:number;
  income:number = 0;
  benefitPeriodInYear:any;
  benefitPeriodInYearPartner:any;
  incomeSupportTillRetirementOfNonInjuredSpouse:number=0;
  incomeSupportTillRetirementOfNonInjuredSpousePartner:number=0;
  retirementAge:any = 0;
  incomeProtectionBenefitsPaid:number=0;
  incomeProtectionBenefitsPaidPartner:number=0;
  totalChildCare:number=0;
  superBalance:number=0;
  superBalancePartner:number=0;
  totalNetProgressiveNeed:number=0;
  totalNetProgressiveNeedPartner:number=0;
  totalRequired:number=0;
  totalRequiredPartner:number=0;
  immediateNonSuperIncomingAssetsTotal:number=0;
  immediateNeedsTotal:number=0;
  grossUpForTax:number=0;
  cashedOutofSuperImmediately:number=0;
  cashedOutofSuperImmediatelyPartner:number=0;
  hasTPDClient:boolean =false;
  hasTPDPartner:boolean =false;
  totalRequiredInput:number=0;
  totalRequiredPartnerInput:number=0;
  benefitAvailableClient:number=0;
  benefitAvailablePartner:number=0;

  isLoaded:boolean;
  isLoaded7:boolean;

  progressiveNeedsTPDModelData = new ProgressiveNeedsTPDModel();

  constructor(
    private sharedDataService: SharedDataService,
    private toastr: ToastrService,
    private familyTreeService :FamilyTreeService,
    private rightsideCalculation: RightsidePipe,
    ) {
    this.sharedDataService.totalDebt.subscribe(totalDebt => this.totalDebt = totalDebt);
    this.sharedDataService.housePurchaseClient.subscribe(housePurchaseClient => this.housePurchaseClient = housePurchaseClient);
    this.sharedDataService.housePurchasePartner.subscribe(housePurchasePartner => this.housePurchasePartner = housePurchasePartner);
    this.sharedDataService.financesInfo.subscribe(financesInfo => this.financesInfo = financesInfo);

    this.sharedDataService.totalValueOfInvestmentsIfSold.subscribe(totalValueOfInvestmentsIfSold => this.totalValueOfInvestmentsIfSold = totalValueOfInvestmentsIfSold);
    this.sharedDataService.homeModificationClient.subscribe(homeModificationClient => this.homeModificationClient = homeModificationClient);
    this.sharedDataService.homeModificationPartner.subscribe(homeModificationPartner => this.homeModificationPartner = homeModificationPartner);
    this.sharedDataService.medicalTreatmentClient.subscribe(medicalTreatmentClient => this.medicalTreatmentClient = medicalTreatmentClient);
    this.sharedDataService.medicalTreatmentPartner.subscribe(medicalTreatmentPartner => this.medicalTreatmentPartner = medicalTreatmentPartner);
    this.sharedDataService.immediateNeedsTotalClient.subscribe(immediateNeedsTotalClient => this.immediateNeedsTotalClient = immediateNeedsTotalClient);
    this.sharedDataService.immediateNeedsTotalPartner.subscribe(immediateNeedsTotalPartner => this.immediateNeedsTotalPartner = immediateNeedsTotalPartner);
    this.sharedDataService.immediateNonSuperIncomingAssetsTotalClient.subscribe(immediateNonSuperIncomingAssetsTotalClient => this.immediateNonSuperIncomingAssetsTotalClient = immediateNonSuperIncomingAssetsTotalClient);
    this.sharedDataService.immediateNonSuperIncomingAssetsTotalPartner.subscribe(immediateNonSuperIncomingAssetsTotalPartner => this.immediateNonSuperIncomingAssetsTotalPartner = immediateNonSuperIncomingAssetsTotalPartner);
    this.sharedDataService.cashedOutOfSuperImmediatelyClient.subscribe(cashedOutOfSuperImmediatelyClient => this.cashedOutOfSuperImmediatelyClient = cashedOutOfSuperImmediatelyClient);
    this.sharedDataService.cashedOutOfSuperImmediatelyPartner.subscribe(cashedOutOfSuperImmediatelyPartner => this.cashedOutOfSuperImmediatelyPartner = cashedOutOfSuperImmediatelyPartner);
    this.sharedDataService.grossUpForTaxClient.subscribe(grossUpForTaxClient => this.grossUpForTaxClient = grossUpForTaxClient);
    this.sharedDataService.grossUpForTaxPartner.subscribe(grossUpForTaxPartner => this.grossUpForTaxPartner = grossUpForTaxPartner);
    this.sharedDataService.totalNetImmediateNeedTPDClient.subscribe(totalNetImmediateNeedTPDClient => this.totalNetImmediateNeedTPDClient = totalNetImmediateNeedTPDClient);
    this.sharedDataService.totalNetImmediateNeedTPDPartner.subscribe(totalNetImmediateNeedTPDPartner => this.totalNetImmediateNeedTPDPartner = totalNetImmediateNeedTPDPartner);
    this.sharedDataService.investmentPropertiesClient.subscribe(investmentPropertiesClient => this.investmentPropertiesClient = investmentPropertiesClient);
    this.sharedDataService.investmentPropertiesPartner.subscribe(investmentPropertiesPartner => this.investmentPropertiesPartner = investmentPropertiesPartner);
    this.sharedDataService.incomePartner.subscribe(incomePartner => this.incomePartner = incomePartner);
    this.sharedDataService.yearsNeedOf.subscribe(yearsNeedOf => this.yearsNeedOf = yearsNeedOf);
    this.sharedDataService.yearsNeedOfPartner.subscribe(yearsNeedOfPartner => this.yearsNeedOfPartner = yearsNeedOfPartner);
    this.sharedDataService.salaryOwner.subscribe(salaryOwner => this.salaryOwner = salaryOwner);
    this.sharedDataService.salaryPartner.subscribe(salaryPartner => this.salaryPartner = salaryPartner);
    this.sharedDataService.provisionForTotallyDisabled.subscribe(provisionForTotallyDisabled => this.provisionForTotallyDisabled = provisionForTotallyDisabled);
    this.sharedDataService.passiveIncomeContinuedPaid.subscribe(passiveIncomeContinuedPaid => this.passiveIncomeContinuedPaid = passiveIncomeContinuedPaid);
    this.sharedDataService.passiveIncomeContinuedPaidPartner.subscribe(passiveIncomeContinuedPaidPartner => this.passiveIncomeContinuedPaidPartner = passiveIncomeContinuedPaidPartner);
    this.sharedDataService.combinedRetirementIncome.subscribe(combinedRetirementIncome => this.combinedRetirementIncome = combinedRetirementIncome);
    this.sharedDataService.provisionForOngoingMedicalCosts.subscribe(provisionForOngoingMedicalCosts => this.provisionForOngoingMedicalCosts = provisionForOngoingMedicalCosts);
    this.sharedDataService.mainNodeAge.subscribe(mainNodeAge => this.mainNodeAge = mainNodeAge);
    this.sharedDataService.mainNodeGender.subscribe(mainNodeGender => this.mainNodeGender = mainNodeGender);
    this.sharedDataService.partnerAge.subscribe(partnerAge => this.partnerAge = partnerAge);
    this.sharedDataService.income.subscribe(income => this.income = income);
    this.sharedDataService.benefitPeriodInYear.subscribe(benefitPeriodInYear => this.benefitPeriodInYear = benefitPeriodInYear);
    this.sharedDataService.benefitPeriodInYearPartner.subscribe(benefitPeriodInYearPartner => this.benefitPeriodInYearPartner = benefitPeriodInYearPartner);
    this.sharedDataService.retirementAge.subscribe(retirementAge => this.retirementAge = retirementAge);
    this.sharedDataService.totalChildCare.subscribe(totalChildCare => this.totalChildCare = totalChildCare);
    this.sharedDataService.superBalance.subscribe(superBalance => this.superBalance = superBalance);
    this.sharedDataService.superBalancePartner.subscribe(superBalancePartner => this.superBalancePartner = superBalancePartner);
    this.sharedDataService.benefitAvailableClient.subscribe(benefitAvailableClient => this.benefitAvailableClient = benefitAvailableClient);
      this.sharedDataService.benefitAvailablePartner.subscribe(benefitAvailablePartner => this.benefitAvailablePartner = benefitAvailablePartner);


  }

  ngOnInit(): void {
    // this.provisionsCompOneObj.getProvisionInfo();
    this.isLoaded= true;

    this.sharedDataService.totalRequired.subscribe(totalRequired => this.totalRequired = totalRequired);
    this.sharedDataService.totalRequiredPartner.subscribe(totalRequiredPartner => this.totalRequiredPartner = totalRequiredPartner);
  }

  ngOnChanges () : void {
    this.clientID = this.clientID
    this.provisionForTotallyDisabled = this.provisionForTotallyDisabled;
    this.passiveIncomeContinuedPaid = this.passiveIncomeContinuedPaid;
    this.passiveIncomeContinuedPaidPartner = this.passiveIncomeContinuedPaidPartner;
    this.mainNodeAge = this.mainNodeAge;
    this.benefitPeriodInYear = this.benefitPeriodInYear;
    this.benefitPeriodInYearPartner = this.benefitPeriodInYearPartner;
    this.totalDebt = this.totalDebt;
    this.homeModificationPartner = this.homeModificationPartner;
    this.medicalTreatmentPartner = this.medicalTreatmentPartner;
    this.housePurchasePartner = this.housePurchasePartner;
    this.salaryOwner = this.salaryOwner;
    this.yearsNeedOf = this.yearsNeedOf;
    this.investmentPropertiesPartner =this.investmentPropertiesPartner;
    this.investmentPropertiesClient = this.investmentPropertiesClient;
    this.totalValueOfInvestmentsIfSold = this.totalValueOfInvestmentsIfSold;

    this.immediateNonSuperIncomingAssetsTotal = this.investmentPropertiesClient + this.investmentPropertiesPartner + this.totalValueOfInvestmentsIfSold;
    this.immediateNeedsTotal = this.totalDebt + this.homeModificationClient + this.medicalTreatmentClient + this.housePurchaseClient + this.incomePartner * (70/100)* this.yearsNeedOfPartner;
    this.immediateNeedsTotalPartner = this.totalDebt + this.homeModificationPartner + this.medicalTreatmentPartner + this.housePurchasePartner + this.income * (70/100)* this.yearsNeedOf;
    this.cashedOutofSuperImmediately = this.totalDebt + this.homeModificationClient + this.medicalTreatmentClient + this.housePurchaseClient + this.incomePartner * (70/100)* this.yearsNeedOfPartner -(this.investmentPropertiesClient + this.investmentPropertiesPartner + this.totalValueOfInvestmentsIfSold);
    this.cashedOutofSuperImmediatelyPartner = this.totalDebt + this.homeModificationPartner + this.medicalTreatmentPartner + this.housePurchasePartner + this.income * (70/100)* this.yearsNeedOf -(this.investmentPropertiesPartner + this.investmentPropertiesClient + this.totalValueOfInvestmentsIfSold);

    this.totalNetImmediateNeedTPDClient = this.grossUpForTax + this.cashedOutofSuperImmediately;
    this.totalNetImmediateNeedTPDPartner = this.grossUpForTaxPartner + this.cashedOutofSuperImmediatelyPartner;

    this.incomeSupportFromRetirementToLongestLifeExpectancyCall();
    this.incomeSupportTillRetirementOfNonInjuredSpouseCall();
    this.incomeProtectionBenefitsPaidCall();
    this.totalNetProgressiveNeedCall();
    this.injuredPersonsSuperCall();

    if(this.clientID) {
      this.checkIfInsuranceTypeHas();
    }

    // -- PROGRESSIVE NEEDS Data -- //
    this.setProgressiveNeedsData()
  }


  addProposedInsurance(proposedInsuranceInfo, tabType, saveType) {
    this.addProposedInsuranceData = new InsurancesList;

    this.addProposedInsuranceData.familyTreeId = proposedInsuranceInfo.familyTreeId;
    this.addProposedInsuranceData.clientCode = this.client_code;

    if(tabType == 'tpd' && saveType == 'addNew') {
      this.addProposedInsuranceData.insuranceCoverType = 2;
      this.addProposedInsuranceData.lifeInsured = ((document.getElementById("lifeInsuredAddTPD") as HTMLInputElement).value);
      this.addProposedInsuranceData.underwriter = ((document.getElementById("insurerAddTPD") as HTMLInputElement).value);
      this.addProposedInsuranceData.lifeSum = ((document.getElementById("lifeSumAddTPD") as HTMLInputElement).value);
      this.addProposedInsuranceData.tpdSum = ((document.getElementById("tpdSumAddTPD") as HTMLInputElement).value);
      this.addProposedInsuranceData.monthlyPremium = ((document.getElementById("monthlyPremiumAddTPD") as HTMLInputElement).value);
      this.addProposedInsuranceData.structure = ((document.getElementById("structureAddTPD") as HTMLInputElement).value);
      this.addProposedInsuranceData.fundingMechanism = ((document.getElementById("fundingMechanismAddTPD") as HTMLInputElement).value);
    } else if(tabType == 'tpd' && saveType == 'defaultClient') {
      this.addProposedInsuranceData.insuranceCoverType = 2;
      this.addProposedInsuranceData.lifeInsured = ((document.getElementById("lifeInsuredAddTPDClient") as HTMLInputElement).value);
      this.addProposedInsuranceData.underwriter = ((document.getElementById("insurerAddTPDClient") as HTMLInputElement).value);
      this.addProposedInsuranceData.lifeSum = ((document.getElementById("lifeSumAddTPDClient") as HTMLInputElement).value);
      this.addProposedInsuranceData.tpdSum = ((document.getElementById("tpdSumAddTPDClient") as HTMLInputElement).value);
      this.addProposedInsuranceData.monthlyPremium = ((document.getElementById("monthlyPremiumAddTPDClient") as HTMLInputElement).value);
      this.addProposedInsuranceData.structure = ((document.getElementById("structureAddTPDClient") as HTMLInputElement).value);
      this.addProposedInsuranceData.fundingMechanism = ((document.getElementById("fundingMechanismAddTPDClient") as HTMLInputElement).value);
    } else if(tabType == 'tpd' && saveType == 'defaultPartner') {
      this.addProposedInsuranceData.insuranceCoverType = 2;
      this.addProposedInsuranceData.lifeInsured = ((document.getElementById("lifeInsuredAddTPDPartner") as HTMLInputElement).value);
      this.addProposedInsuranceData.underwriter = ((document.getElementById("insurerAddTPDPartner") as HTMLInputElement).value);
      this.addProposedInsuranceData.lifeSum = ((document.getElementById("lifeSumAddTPDPartner") as HTMLInputElement).value);
      this.addProposedInsuranceData.tpdSum = ((document.getElementById("tpdSumAddTPDPartner") as HTMLInputElement).value);
      this.addProposedInsuranceData.monthlyPremium = ((document.getElementById("monthlyPremiumAddTPDPartner") as HTMLInputElement).value);
      this.addProposedInsuranceData.structure = ((document.getElementById("structureAddTPDPartner") as HTMLInputElement).value);
      this.addProposedInsuranceData.fundingMechanism = ((document.getElementById("fundingMechanismAddTPDPartner") as HTMLInputElement).value);
    }


    // this.addProposedInsuranceData.insuranceCoverType = ((document.getElementById("insuranceCoverTypeAdd") as HTMLInputElement).value);
    let tabTypeUpper = tabType.toUpperCase();
    if(tabType == 'income') {
      if(this.addProposedInsuranceData.policyOwner == '') {
        this.toastr.error('Error on adding proposed '+tabTypeUpper+'.'); // 'Error on policy owner.'
      } else {
        this.proposedInsuranceAdd(this.addProposedInsuranceData, tabType);
      }
    }

    if(tabType == 'life' || tabType == 'tpd' || tabType == 'trauma') {
      if(this.addProposedInsuranceData.lifeInsured == '') {
        this.toastr.error('Error on adding proposed '+tabTypeUpper+'.'); // 'Error on life insured.'
      } else {
        this.proposedInsuranceAdd(this.addProposedInsuranceData, tabType);
      }
    }
  }


  removeComma(value) {
    if(value == 'null' || value =='') return; // locale returns string of NaN if fail
    return value = value.replace(/,/g, ""); // remove commas
  }


  proposedInsuranceAdd(addProposedInsuranceData, tabType) {
    let tabTypeUpper = tabType.toUpperCase();
    this.familyTreeService.addProposedInsurance(this.addProposedInsuranceData.clientCode, this.addProposedInsuranceData.familyTreeId, addProposedInsuranceData).subscribe((data:ProposedInsuranceAddResponse)=>{
      if(data.error && !data.success){
        this.toastr.error('Error on adding proposed '+tabTypeUpper+'.');
      }
      else{
        this.toastr.success('Proposed '+tabTypeUpper+' added successfully.');

        if(tabType == 'tpd') {
          ((document.getElementById("lifeInsuredAddTPD") as HTMLInputElement).value='');
          ((document.getElementById("insurerAddTPD") as HTMLInputElement).value='');
          ((document.getElementById("lifeSumAddTPD") as HTMLInputElement).value='');
          ((document.getElementById("tpdSumAddTPD") as HTMLInputElement).value='');
          ((document.getElementById("monthlyPremiumAddTPD") as HTMLInputElement).value='');
          ((document.getElementById("structureAddTPD") as HTMLInputElement).value='');
          ((document.getElementById("fundingMechanismAddTPD") as HTMLInputElement).value='');
        }

      }

      this.getProposedInsuranceInfo();
    },err=>{
      console.log(err);
      this.toastr.error('Error on adding proposed '+tabTypeUpper+'.');
    });
  }

  getProposedInsuranceInfo() {
    this.familyTreeService.getProposedInsurance(this.family_tree_id).subscribe((proposedInsuranceData:ProposedInsuranceResponse)=>{
      if(proposedInsuranceData.success){
        this.proposedInsuranceInfo = proposedInsuranceData.response;

        this.checkIfInsuranceTypeHas();
        this.isLoaded8 = true;
      }
      // this.ngxService.stop();
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
    });
  }

  deleteProposedInsurance(proposedInsurance) {
    let id = proposedInsurance.id;

    this.familyTreeService.deleteProposedInsurance(id).subscribe((data:DeleteOtherProvisionResponse)=>{
      if(data.error && !data.success){
        this.toastr.error("Error on deleting proposed insurance.");
      }
      else{
        this.getProposedInsuranceInfo();
        this.toastr.success("Proposed insurance deleted successfully.");
      }
    },err=>{
      console.log(err);
      this.toastr.error("Error on deleting proposed insurance.");
    });
  }

  proposedInsuranceSave(proposedInsurance, event: any){
    // console.log(spouseDeathOrTPDS);
    let value = event.target.value;
    let inputId = event.target.id;
    // console.log(value);
    // console.log(inputId);

    this.addProposedInsuranceData = new InsurancesList;
    this.addProposedInsuranceData.insuranceCoverType = proposedInsurance.insuranceCoverType;
    this.addProposedInsuranceData.lifeInsured = proposedInsurance.lifeInsured;
    this.addProposedInsuranceData.underwriter = proposedInsurance.underwriter;
    this.addProposedInsuranceData.lifeSum = proposedInsurance.lifeSum;
    this.addProposedInsuranceData.tpdSum = proposedInsurance.tpdSum;
    this.addProposedInsuranceData.monthlyPremium = proposedInsurance.monthlyPremium;
    this.addProposedInsuranceData.structure = proposedInsurance.structure;
    this.addProposedInsuranceData.fundingMechanism = proposedInsurance.fundingMechanism;

    // this.willExecutorsAdd.clientCode = (userType==1)?this.client_code:this.facIdPartner;
    //this.willExecutorsAdd.clientCode = proposedInsurance.clientCode;  // this.client_code

    if(inputId == 'insuranceCoverType') {
      this.addProposedInsuranceData.insuranceCoverType = value;
    }
    if(inputId == 'lifeInsured') {
      this.addProposedInsuranceData.lifeInsured = value;
    }
    if(inputId == 'underwriter') {
      this.addProposedInsuranceData.underwriter = value;
    }
    if(inputId == 'lifeSum') {
      this.addProposedInsuranceData.lifeSum = value;
    }
    if(inputId == 'tpdSum') {
      this.addProposedInsuranceData.tpdSum = value;
    }
    if(inputId == 'monthlyPremium') {
      this.addProposedInsuranceData.monthlyPremium = value;
    }
    if(inputId == 'structure') {
      this.addProposedInsuranceData.structure = value;
    }
    if(inputId == 'fundingMechanism') {
      this.addProposedInsuranceData.fundingMechanism = value;
    }

    // console.log(this.addProposedInsuranceData);
    this.familyTreeService.editProposedInsurance(proposedInsurance.id, this.addProposedInsuranceData).subscribe((data:ProposedInsuranceAddResponse)=>{
      if(data.error && !data.success){
        this.toastr.error('Error on updating proposed insurance.');
      }
      else{
        this.toastr.success('Proposed insurance updated successfully.');
      }

      this.getProposedInsuranceInfo();
    },err=>{
      console.log(err);
      this.toastr.error('Error on updating proposed insurance.');
    });
  }

  checkIfInsuranceTypeHas(){
    var clientID = this.clientID;
    var partnerID = this.partnerID;

    if(this.proposedInsuranceInfo){
      var isPresent1 = false;
      var isPresent2 = false;

      if(this.proposedInsuranceInfo.clientInsurances.length > 0) {
        var isCoverType = this.proposedInsuranceInfo.clientInsurances.some(function(el){ return el.insuranceCoverType === 2 });
        var target =  this.proposedInsuranceInfo.clientInsurances.filter(function(el) { return el.insuranceCoverType == 2;});
        if (isCoverType) {

          var isClient = target.some(function(el){ return el.lifeInsured === clientID });
          var isPresent = target.some(function(el){ return el.lifeInsured === partnerID });
          if (isClient) {
            isPresent1 = true;
          } else {
            isPresent1 = false;
          }
          if (isPresent) {
            isPresent2 = true;
          } else {
            isPresent2 = false;
          }
        } else {
          isPresent1 = false;
          isPresent2 = false;
        }
      }

      if(this.proposedInsuranceInfo.partnerInsurances.length > 0) {
        var isCoverType = this.proposedInsuranceInfo.partnerInsurances.some(function(el){ return el.insuranceCoverType === 2 });
        var target =  this.proposedInsuranceInfo.partnerInsurances.filter(function(el) { return el.insuranceCoverType == 2;});

        if (isCoverType) {
          var isClient = target.some(function(el){ return el.lifeInsured === clientID });
          var isPresent = target.some(function(el){ return el.lifeInsured === partnerID });
          if (isClient) {
            isPresent1 = true;
          } else {
            isPresent1 = false;
          }
          if (isPresent) {
            isPresent2 = true;
          } else {
            isPresent2 = false;
          }
        } else {
          isPresent1 = false;
          isPresent2 = false;
        }
      }

      if(isPresent1) {
        this.hasTPDClient = true;
      } else {
        this.hasTPDClient = false;
      }

      if(isPresent2) {
        this.hasTPDPartner = true;
      } else {
        this.hasTPDPartner = false;
      }
    }
}

  incomeSupportFromRetirementToLongestLifeExpectancyCall(){
    // // E9 = IF(E6=0,0,IF(E7="Male", VLOOKUP(D6, Assumptions!B16:D116,2),VLOOKUP(E6,Assumptions!B16:D116,3)))
    // // D9 = IF(D7="Male",VLOOKUP(D6,Assumptions!B16:D116,2),VLOOKUP(D6,Assumptions!B16:D116,3))
    let E9 = this.rightsideCalculation.getLifeExpetancy(this.mainNodeAge+1,this.mainNodeGender);
    let D9 = this.rightsideCalculation.getLifeExpetancy(this.mainNodeAge+1,this.mainNodeGender);

    let E5 = 'Client 2'
    let R18 = 'Lifetime' // Lifetime, Age 65
    let O23 =  this.provisionForTotallyDisabled// What provision for care if you were totally disabled ( until assitance from spouse available)
    let AA85 = O23
    //console.log('O18=0', this.passiveIncomeContinuedPaid = 10000) // from 10000 to 0
    let O18 = this.passiveIncomeContinuedPaid // What Passive income would continue to be paid to you in event of your TPD or Trauma?
    let O15 = this.combinedRetirementIncome // What is your combined income need at retirement?
    let P15 = this.combinedRetirementIncome // What is your combined income need at retirement?
    let O22 = this.provisionForOngoingMedicalCosts // What provision for ongoing medical costs ( Lifetime)
    let D13 = 1.5/100 //1.5%
    let Y39 = D13
    let D6 = this.mainNodeAge+1 // Age Next Birthday // Client
    let E6 = this.partnerAge+1  // Age Next Birthday // D6
    let Z25 = E6
    let D8 = this.retirementAge // Desired Retirement Age 60
    let O14 = D8
    let O13 = D6
    let E8 = D8
    let P14 = E8
    let P13 = E6
    let Y27 = O14-O13
    let Z27

    //IF(Z25=0,Y27,P14-P13)
    if(Z25==0) {
      Z27 = Y27
    } else {
      Z27 = P14-P13
    }

    let AA83 = Z27
    let AD108 = Y39
    let AA86 = O22
    let AA84

    //IF(E5="client 2",O15,P15)
    if(E5=="client 2") {
      AA84 = O15
    } else {
      AA84 = P15
    }

    let a
    let b

    // IF(E5="client 2",Risk!AA85,0)
    if(E5=="client 2") {
      a = AA85
    } else {
      a = 0
    }

    // IF(R18="lifetime",O18,0)
    if(R18=="lifetime") {
      b = O18
    } else {
      b = 0
    }

    let D12 = 6.8/100 // 6.8%
    let Y38 = D12
    let AD107 = Y38
    let D10 = 5 // Life expectacy + yr  // Client
    let E10 = D10
    let Z26 = E9+E10
    let Y26

    // IF(E5="client 2",0,D9+D10)
    if(E5=="client 2") {
      Y26 = 0
    } else {
      Y26 = D9+D10
    }

    let AD109 = Math.max(Y26, Z26)-Z27  // MAX(Y26:Z26)-Z27
    let AG111 = 1+AD107
    let AF111 = 1+AD108
    let AH111 = AF111/AG111
    let AI111 = Math.pow(AH111, AD109)
    let AJ111 = 1-AI111
    let AD111 = AJ111

    let c = (1+AD108)
    let d = Math.pow(c, AA83)

    let AD106 = (a+(AA84+AA86-b)*d) //(IF(E5="client 2",Risk!AA85,0)+(AA84+AA86-IF(R18="lifetime",O18,0))*(1+AD108)^AA83)
    let AD110 = AD106/(AD107-AD108)
    let AD112 = AD110*AD111
    let AD113 = AD112*(1+AD107)
    let i = Math.pow(AG111, AA83)
    let AD114 = AD113/i // Math.pow((1+AD107), AA83)
    let O102 = AD114
    this.incomeSupportFromRetirementToLongestLifeExpectancy = O102

    let P18 = this.passiveIncomeContinuedPaidPartner // What Passive income would continue to be paid to you in event of your TPD or Trauma?
    let AD143 = Y39
    let Z83 = Y27
    let S18 = 'Lifetime' // Lifetime, Age 65
    let P22 = O22
    let Z86

    // IF(P13=0,AA86,P22)
    if(P13==0) {
      Z86 = AA86
    } else {
      Z86 = P22
    }

    let AF146 = 1+AD143
    let e
    let f = Math.pow(AF146, Z83)// (1+AD143)^Z83
    // IF(S18="lifetime",P18,0)
    if(S18=="lifetime") {
      e = P18
    } else {
      e = 0
    }
    let AD142 = Y38
    let AD144 = Math.max(Y26, Z26)-Y27
    let AG146 = 1+AD142
    let AH146 = AF146/AG146
    let AI146 = Math.pow(AH146, AD144) // AH146^AD144
    let AJ146 = 1-AI146
    let AD146 = AJ146

    let Z84 = P15
    let AD141 = (Z84+Z86-e)*f
    let AD145 = AD141/(AD142-AD143)
    let AD147 = AD145*AD146
    let AD148 = AD147*(1+AD142)
    let g = (1+AD142)
    let h = Math.pow(g, Z83) // (1+AD142)^Z83
    let AD149 = AD148/h

    let P102
    // IF(E5="client 2",0,AD149)
    if(E5=="client 2") {
      P102 = 0
    } else {
      P102 = AD149
    }

    this.incomeSupportFromRetirementToLongestLifeExpectancyPartner = P102
  }

  incomeSupportTillRetirementOfNonInjuredSpouseCall() {
    let E5 = 'Client 2'
    let E6 = this.partnerAge+1  // Age Next Birthday
    let P13 = E6
    let O9 = this.income
    let O12 = 75/100 // 75%
    let P9 = this.incomePartner
    let P12 = 75/100 // 75%
    let O18 = this.passiveIncomeContinuedPaid // What Passive income would continue to be paid to you in event of your TPD or Trauma?
    let AA89 = O18
    let O15 = this.combinedRetirementIncome // What is your combined income need at retirement?
    let P15 = this.combinedRetirementIncome // What is your combined income need at retirement?
    let O23 =  this.provisionForTotallyDisabled// What provision for care if you were totally disabled ( until assitance from spouse available)
    let AA85 = O23
    let O22 = this.provisionForOngoingMedicalCosts // What provision for ongoing medical costs ( Lifetime)
    let AA86 = O22
    let D12 = 6.8/100 // 6.8%
    let D13 = 1.5/100 //1.5%
    let Y39 = D13
    let Y38 = D12
    let Z25 = E6
    let D8 = this.retirementAge // Desired Retirement Age 60
    let D6 = this.mainNodeAge+1 // Age Next Birthday // Client
    let O14 = D8
    let O13 = D6
    let E8 = D8
    let P14 = E8
    let Y27 = O14-O13

    let Z27
    //IF(Z25=0,Y27,P14-P13)
    if(Z25==0) {
      Z27 = Y27
    } else {
      Z27 = P14-P13
    }

    let O11 = this.yearsNeedOf // If your spouse were to pass away/become TPD many years off work would you want?
    let Y52 = O11
    let AA103 = Y52
    let AD96 = Y39
    let AA84
    // IF(E5="client 2",O15,P15)
    if (E5=="client 2") {
      AA84 = O15
    } else {
      AA84 = P15
    }

    let Z87
    // IF(E5="client 2",0,O9*O12*0.7)
    if(E5=="client 2") {
      Z87 = 0
    } else {
      Z87 = O9*O12*0.7
    }

    let AA87
    // IF(P13=0,Z87,P9*P12*0.7)
    if(P13==0) {
      AA87 = Z87
    } else {
      AA87 = P9*P12*0.7
    }

    let P11 = this.yearsNeedOfPartner;
    let Z52 = P11
    let Z103 = Z52
    let AD95 = Y38
    let Z47 = Z27
    let AD97 = Z47-Z52
    let AG99 = 1+AD95
    let AF99 = 1+AD96
    let AH99 = AF99/AG99
    let AI99 = Math.pow(AH99, AD97) // AH99^AD97
    let AJ99 = 1-AI99
    let AD99 = AJ99
    let AA90 = AA87+AA89-AA84-AA85-AA86
    let a = Math.pow(AF99, Z103)
    let AD94 = -AA90*a // -AA90*(1+AD96)^Z103
    let AD98 = AD94/(AD95-AD96)
    let AD100 = AD98*AD99
    let AD101 = AD100*(1+AD95)
    let b = Math.pow(AG99, AA103)
    let AD102 = AD101/b // (1+AD95)^AA103
    let O103 = AD102
    this.incomeSupportTillRetirementOfNonInjuredSpouse = O103

    let P18 = this.passiveIncomeContinuedPaidPartner // What Passive income would continue to be paid to you in event of your TPD or Trauma? // Data base vall = 100000
    let Z84 = P15
    let Z89 = P18
    let P23 = O23
    let P22 = O22
    let Z85
    // IF(P13=0,AA85,P23)
    if(P13==0) {
      Z85 = AA85
    } else {
      Z85 = P23
    }

    let Z86
    // IF(P13=0,AA86,P22)
    if(P13==0) {
      Z86 = AA86
    } else {
      Z86 = P22
    }

    let AD131 = Y39
    let AD130 = Y38
    let AF134 = 1+AD131
    let AG134 = 1+AD130
    let Z109 = Y27
    let AD132 = Z109-Z103
    let AH134 = AF134/AG134
    let AI134 = Math.pow(AH134, AD132) // AH134^AD132
    let AJ134 = 1-AI134
    let AD134 = AJ134
    let Z90 = Z87+Z89-Z84-Z85-Z86
    let c = Math.pow(AF134, AA103)
    let AD129 = -Z90*c // (1+AD131)^AA103
    let AD133 = AD129/(AD130-AD131)
    // let AD153 = AD133*AD134
    let AD135 = AD133*AD134
    let AD136 = AD135*(1+AD130)
    let d = Math.pow(AG134, Z103)
    let AD137 = AD136/d // (1+AD130)^Z103
    let P103
    // IF(E5="client 2",0,AD137)
    if (E5=="client 2") {
      P103 = 0
    } else {
      P103 = AD137
    }

    this.incomeSupportTillRetirementOfNonInjuredSpousePartner = P103
  }

  incomeProtectionBenefitsPaidCall() {
    let E5 = 'Client 2'
    let D35 = this.benefitAvailableClient // 0 // D29+D30+D31
    let O80 = D35
    let AA113 = O80*12*0.7
    let D13 = 1.5/100 //1.5%
    let Y39 = D13
    let AD85 = Y39
    let O11 = this.yearsNeedOf // If your spouse were to pass away/become TPD many years off work would you want?
    let Y52 = O11
    let AA103 = Y52
    let a = (1+AD85)
    let b = Math.pow(a, AA103)
    let AD83 = AA113*b // AA113*(1+AD85)^AA103
    let D12 = 6.8/100 // 6.8%
    let Y38 = D12
    let AD84 = Y38
    let AD87 = AD83/(AD84-AD85)
    let AF88 = 1+AD85
    let AG88 = 1+AD84
    let AH88 = AF88/AG88
    let D8 = this.retirementAge // Desired Retirement Age 60
    let E8 = D8
    let P14 = E8
    let E6 = this.partnerAge+1   // Age Next Birthday
    let Z25 = E6
    let O14 = D8
    let D6 = this.mainNodeAge+1 // Age Next Birthday // Client
    let O13 = D6
    let Y27 = O14-O13
    let P13 = E6

    let Z27
    // IF(Z25=0,Y27,P14-P13)
    if(Z25==0) {
      Z27 = Y27
    } else {
      Z27 = P14-P13
    }

    let AA83 = Z27

    let Z39 = Y39
    let Z38 = Y38
    let AE84 = Z38 // Y38
    let AE85 = Z39 // Y39
    let l = Math.pow((1+AE85), AA83)
    let AE83 = AA113*l // AA113*(1+AE85)^AA83  // (1+AD85)^AA103
    let AE87 = AE83/(AE84-AE85) // AE83/(AE84-AE85)
    let M77 = this.benefitPeriodInYear

    let O77
    //IF(M77-O13<=0,M77,M77-O13)
    if(M77-O13<=0) {
      O77 = M77
    } else {
      O77 = M77-O13
    }

    let AA96 = O77
    let AA95 = Z27
    let c = Math.min(AA95, AA96) // MIN(AA95:AA96)
    let AD86 = c-AA103 // MIN(AA95:AA96)-AA103
    let AI88 = Math.pow(AH88, AD86) // AH88^AD86
    let AJ88 = 1-AI88
    let AF89 = 1+AE85
    let AG89 = 1+AE84
    let AH89 = AF89/AG89
    let j = AA96-AA95

    let AA98
    // IF(AA96-AA95>0,AA96-AA95,0)
    if(j>0) {
      AA98 = j
    } else {
      AA98 = 0
    }

    let AE86 = AA98
    let AI89 = Math.pow(AH89, AE86) // AH89^AE86
    let AJ89 = 1-AI89
    let AE88 = AJ89 // AJ88
    let AE89 = AE87*AE88
    let AE90 = (AE89*(1+AE84))
    let d = 1+AE84
    let e = Math.pow(d, AA83)
    let AE91 = -AE90/e // -AE90/(1+AE84)^AA83
    let AD88 = AJ88
    let AD89 = AD87*AD88
    let AD90 = AD89*(1+AD84)
    let f = 1+AD84
    let g = Math.pow(f, AA103)
    let AD91 = -AD90/g // -AD90/(1+AD84)^AA103
    let O104 = AD91+AE91
    this.incomeProtectionBenefitsPaid = O104



    let D36 = this.benefitAvailablePartner // 0 // D33+D32
    let P80 = D36
    let Z113 = P80*12*0.7
    let AD119 = Y39
    let P11 = this.yearsNeedOfPartner;
    let Z52 = P11
    let Z103 = Z52
    let AD118 = Y38
    let AF122 = 1+AD119
    let AG122 = 1+AD118
    let Z95 = Y27
    let h
    let N77 = this.benefitPeriodInYearPartner

    let P77
    // IF(N77-IF(E5="client 2",N77,P13)<=0,N77,N77-IF(E5="client 2",N77,P13))
    if(E5=="client 2") {
      h = N77
    } else {
      h = P13
    }
    let i = N77 - h
    if(i<=0) {
      P77 = N77
    } else {
      P77 = i
    }

    let Z96
    // IF(P13=0,AA96,P77)
    if(P13==0) {
      Z96 = AA96
    } else {
      Z96 = P77
    }

    let AD120
    // IF(P13=0,AD86,MIN(Z95:Z96)-Z103)
    if(P13==0) {
      AD120 = AD86
    } else {
      let k = Math.min(Z95, Z96) // MIN(Z95:Z96)
      AD120 = k-Z103
    }

    let AE119 = Z39
    let Z83 = Y27

    let AE118 = Z38
    let AF123 = 1+AE119
    let AG123 = 1+AE118

    let k = Z96-Z95

    let Z98
    // IF(Z96-Z95>0,Z96-Z95,0)
    if(k>0) {
      Z98 = k
    } else {
      Z98 = 0
    }

    let AE120
    // IF(P13=0,AE86,Z98)
    if(P13==0) {
      AE120 = AE86
    } else {
      AE120 = Z98
    }

    let AH123 = AF123/AG123
    let AI123 = Math.pow(AH123, AE120) // AH123^AE120
    let AJ123 = 1-AI123
    let AE122 = AJ123
    let n = 1+AE119
    let o = Math.pow(n, Z83)
    let AE117 = Z113*o // (1+AE119)^Z83
    let AE121 = AE117/(AE118-AE119)
    let AE123 = AE121*AE122
    let AE124 = AE123*(1+AE118)
    let AH122 = AF122/AG122
    let AI122 = Math.pow(AH122, AD120) // AH122^AD120
    let AJ122 = 1-AI122
    let AD122 = AJ122
    let p = 1+AD119
    let q = Math.pow(p, Z103)
    let AD117 = Z113*q // (1+AD119)^Z103
    let AD121 = AD117/(AD118-AD119)
    let AD123 = AD121*AD122
    let AD124 = (AD123*(1+AD118))
    let r = 1+AE118
    let s = Math.pow(r, Z83)
    let AE125 = -AE124/s // (1+AE118)^Z83
    let t = 1+AD118
    let u = Math.pow(t, Z103)
    let AD125 = -AD124/u // (1+AD118)^Z103
    let P104 = AD125+AE125
    this.incomeProtectionBenefitsPaidPartner = P104
  }

  injuredPersonsSuperCall() {

    // Cashed out of super immediately  --- Start ---
    let O95 = 1404454 // this.immediateNonSuperIncomingAssetsTotal // = O5 // Immediate Non Super Incoming Assets Total
    let O92 = this.immediateNeedsTotal // = SUM(O87:O91) // Immediate Needs Total
    let O97 = O92-O95     // Cashed out of super immediately = Immediate Needs Total - Immediate Non Super Incoming Assets Total
    // Cashed out of super immediately  --- End ---

    // Gross up for tax   --- Start --- // Client
    let D6 = this.mainNodeAge+1 // Age Next Birthday // Client
    let D4 = 2021 // Current year
    let AF154 = D4-D6+20
    let AF156 = AF154-20+65
    let AF155 = D4+0.5
    let AF161 = (AF156-AF155)/(AF156-AF154)
    let AF160 = 1-AF161
    let AF162 = O97
    let AF163 = AF160*AF162
    let AF153 = D6
    let AH150 = 60  // send berth year and get value  // INDEX(AM153:AM158,MATCH(AH151,AN153:AN158,1))
    let AJ153 = AH150
    let AO161 =  200000 // Taxable Taxfree
    let AM160 = 2018
    let AO162 = D4
    let AO163 = AO162-AM160
    let b = AO161*1.03
    let AO164 = Math.pow(b, AO163) // AO161*1.03^AO163
    let AO165 = Math.round((AO164)/5000)*5000; // MROUND(AO164,5000)

    let AF165
    // IF(AF153>AJ153,AO165,0)
    if(AF153>AJ153) {
      AF165 = AO165
    } else {
      AF165 = 0
    }

    let AF166 = AF163-AF165
    let a
    // IF(AF166<0,0,AF166)*0.215
    if(AF166<0) {
      a = 0
    } else {
      a = AF166
    }

    let AF167 = a*0.215
    let AH153 = AH150-0.5
    let AH156 = AF156
    let AH154 = AF154
    let AH155 = AH154-20+AH153
    let AH161 = (AH156-AH155)/(AH156-AH154)
    let AH160 = 1-AH161
    let AH162 = AF162
    let AH163 = AH160*AH162
    let AH167
    // IF(AF153>AH153,0,AH163*0.215)
    if(AF153>AH153) {
      AH167 = 0
    } else {
      AH167 = AH163*0.215
    }
    let AF168 = Math.max(AF167, AH167) // MAX(AF167,AH167)

    let O98
    // IF(O97>0,AF168,0)
    if(O97>0) {
      O98 = AF168
    } else {
      O98 = 0
    }

    this.grossUpForTax = O98
    // Gross up for tax   --- End ---

    // Gross up for tax   --- Start --- // Partner
    let P92 = this.immediateNeedsTotalPartner  // Immediate Needs Total // Partner
    let P5 = this.immediateNonSuperIncomingAssetsTotal  // Immediate Non Super Incoming Assets Total // this.investmentPropertiesPartner+this.investmentPropertiesClient+ this.totalValueOfInvestmentsIfSold
    let P95 = P5
    let P97 = P92-P95 // Cashed Out of Super Immediately ----/Partner
    let E6 = this.partnerAge+1   // Age Next Birthday
    let AG154 = D4-E6+20
    let AG156 = AG154-20+65
    let AG155 = D4+0.5
    let AG161 = (AG156-AG155)/(AG156-AG154)
    let AG160 = 1-AG161
    let AG162 = P97
    let AG163 = AG160*AG162
    let AG153 = E6
    let AI150 = 60  // this.preservationAge(year) // send berth year and get value  // INDEX(AM153:AM158,MATCH(AH151,AN153:AN158,1))
    let AK153 = AI150
    let AG165
    // IF(AG153>AK153,$AO$165,0)
    if(AG153>AK153) {
      AG165 = Math.round((AO164)/5000)*5000; // MROUND(AO164,5000)
    } else {
      AG165 = 0
    }
    let AG166 = AG163-AG165
    let AG167
    // IF(AG166<0,0,AG166)*0.215
    if(AG166<0) {
      AG167 = 0
    } else {
      AG167 = AG166*0.215
    }

    let AI153 = AI150-0.5
    let AI154 = AG154
    let AI155 = AI154-20+AI153
    let AI156 = AG156
    let AI161 = (AI156-AI155)/(AI156-AI154)
    let AI160 = 1-AI161
    let AI162 = AG162
    let AI163 = AI160*AI162
    let AI167
    // IF(AG153>AI153,0,AI163*0.215)
    if(AG153>AI153) {
      AI167 = 0
    } else {
      AI167 = AI163*0.215
    }
    let AG168 = Math.max(AG167, AI167) // MAX(AG167,AI167)
    let P98
    // IF(P97>0,AG168,0)
    if(P97>0) {
      P98 = AG168
    } else {
      P98 = 0
    }

    this.grossUpForTaxPartner = P98
    // Gross up for tax   --- End ---
  }

  totalNetProgressiveNeedCall() {
    // Injured Person's Super
    let O6 = this.superBalance // What is the current value of your superannuation?  // Client
    let O106 = -O6
    let P6 = this.superBalancePartner // What is the current value of your superannuation?  // Partner
    let P106 = -P6

    // Non-injured Spouse's Super
    let O107 = P106  // Client
    let P107 = O106  // IF(E5="client 2",0,O106) // Partner

    let totalClient = this.incomeSupportFromRetirementToLongestLifeExpectancy + this.incomeSupportTillRetirementOfNonInjuredSpouse + this.incomeProtectionBenefitsPaid + this.totalChildCare + O106 + O107
    let totalPartner = this.incomeSupportFromRetirementToLongestLifeExpectancyPartner + this.incomeSupportTillRetirementOfNonInjuredSpousePartner + this.incomeProtectionBenefitsPaidPartner + this.totalChildCare + P106 + P107

    let O109
    // IF(SUM(O102:O105)+O106+O107<0,O106,SUM(O102:O105)+O106+O107)
    if(totalClient<0) {
      O109 = -O6
    } else {
      O109 = totalClient
    }

    let P109
    // IF(SUM(P102:P105)+P106+P107<0,P106,SUM(P102:P105)+P106+P107)
    if(totalPartner<0) {
      P109 = -P6
    } else {
      P109 = totalPartner
    }

    this.totalNetProgressiveNeed = O109
    this.totalNetProgressiveNeedPartner = P109


    let O111
    let a
    // ROUND(IF(O109<0,0,O109)+O99,-4)  // Client
    if(O109<0) {
      a = 0
    } else {
      a = O109
    }

    let O99 = this.totalNetImmediateNeedTPDClient
    let b = a+O99
    O111 = Math.round((b)/10000)*10000;
    this.totalRequired = O111

    let c
    let P111
    // ROUND(IF(P109<0,0,P109)+P99,-4)
    if(P109<0) {
      c = 0
    } else {
      c = P109
    }

    let P99 = this.totalNetImmediateNeedTPDPartner
    let d = c+P99
    P111 = Math.round((d)/10000)*10000;
    this.totalRequiredPartner = P111

    if(this.totalRequired>0) {this.totalRequiredInput = this.totalRequired
    } else {this.totalRequiredInput = 0}

    if(this.totalRequiredPartner>0) {this.totalRequiredPartnerInput = this.totalRequiredPartner
    } else {this.totalRequiredPartnerInput = 0}

    this.sharedDataService.changTotalRequired(this.totalRequired)
    this.sharedDataService.changTotalRequiredPartner(this.totalRequiredPartner)
  }

  // preservationAge(year){
  //   (before-year? 1960) 55
  //   (before-year? 1961) 56
  //   (before-year? 1962) 57
  //   (before-year? 1963) 58
  //   (before-year? 1964) 59
  //   :else 60)))
  // }

  // -- PROGRESSIVE NEEDS Data -- //
  private setProgressiveNeedsData() {
    this.progressiveNeedsTPDModelData.setIncomeSupportFromRetirement(
      Math.round(this.incomeSupportFromRetirementToLongestLifeExpectancy),
      Math.round(this.incomeSupportFromRetirementToLongestLifeExpectancyPartner)
    )
    this.progressiveNeedsTPDModelData.setIncomeSupportTillRetirement(
      Math.round(this.incomeSupportTillRetirementOfNonInjuredSpouse),
      Math.round(this.incomeSupportTillRetirementOfNonInjuredSpousePartner)
    )
    this.progressiveNeedsTPDModelData.setIncomeProtectionBenefitsPaid(
      Math.round(this.incomeProtectionBenefitsPaid),
      Math.round(this.incomeProtectionBenefitsPaidPartner)
    )
    this.progressiveNeedsTPDModelData.setChildcare(
      Math.round(this.totalChildCare),
      Math.round(this.totalChildCare)
    )
    this.progressiveNeedsTPDModelData.setInjuredPersonsSuper(
      Math.round(this.superBalance),
      Math.round(this.superBalancePartner)
    )
    this.progressiveNeedsTPDModelData.setNonInjuredSpouseSuper(
      Math.round(this.superBalancePartner),
      Math.round(this.superBalance)
    )
    this.progressiveNeedsTPDModelData.setTotalNetProgressiveNeed(
      Math.round(this.totalNetProgressiveNeed),
      Math.round(this.totalNetProgressiveNeedPartner)
    )
    this.progressiveNeedsTPDModelData.setTotalRequired(
      Math.round(this.totalRequired),
      Math.round(this.totalRequiredPartner)
    )
    this.progressiveNeedsTPDModelData.setCashedOutOfSuperImmediately(
      Math.round(this.cashedOutOfSuperImmediatelyClient),
      Math.round(this.cashedOutOfSuperImmediatelyPartner)
    )
    this.progressiveNeedsTPDModelData.setGrossUpForTaxClient(
      Math.round(this.grossUpForTaxClient),
      Math.round(this.grossUpForTaxPartner)
    )

    this.progressiveNeedsTPDModelData.setImmediateNeedsTotal(
      Math.round(this.immediateNeedsTotalClient),
      Math.round(this.immediateNeedsTotalPartner)
    )
    this.progressiveNeedsTPDModelData.setIncomeReplacementForSpouseTimeOffWork(
      Math.round(this.incomePartner * (70/100)* this.yearsNeedOfPartner),
      Math.round(this.income * (70/100)* this.yearsNeedOf)
    )

    this.sharedDataService.setProgressiveNeedsTPDdata(this.progressiveNeedsTPDModelData);
  }

}
