<form class="form" [formGroup]="memberForm"> 
    <div class="boxcolor mainboxbg mt-2">

        <div class="d-flex justify-content-end align-items-center mb-1">
            <label *ngIf="isMain" class="tc2 mr-auto mb-0">Main</label>
            <span *ngIf="isUpdating" class="reset-btn p-1 mr-auto" (click)="reset()">[ Reset ]</span>
            <span *ngIf="isUpdating && isValid" class="save-btn p-1 mr-3" (click)="saveMember()">Save Now</span>
            <span *ngIf="!isMain && !isDisabled" (click)="openDeleteDialog(deleteMemberRef)">
                <i class="fa fa-close close_section"></i>
            </span>
        </div>

        <div class="form-row subgraphs">
            <div class="form-group col-md-4 edit-border">
                <input formControlName="firstName" placeholder="First Name" class="form-control">
                <div *ngIf="submitted && mf.firstName.errors" class="invalid-feedback">
                    <span *ngIf="mf.firstName.errors.required">First name is required</span>
                </div>
            </div>
            <div class="form-group col-md-4 edit-border">
                <input formControlName="middleName" placeholder="Middle Name" class="form-control">
            </div>
            <div class="form-group col-md-4 edit-border">
                <input formControlName="lastName" placeholder="Last Name" class="form-control">
                <div *ngIf="submitted && mf.lastName.errors" class="invalid-feedback">
                    <span *ngIf="mf.lastName.errors.required">Last name is required</span>
                </div>
            </div>
        </div>
        
        <div class="form-row subgraphs">
            <div class="form-group col-md-4 edit-border"> 
                <select formControlName="gender" class="form-control">  
                    <option value="" disabled>Select gender</option>
                    <option value="1">Male</option>
                    <option value="0">Female</option>
                </select>  
                <div *ngIf="submitted && mf.gender.errors" class="invalid-feedback">
                    <span *ngIf="mf.gender.errors.required">Gender is required</span>
                </div>  
            </div>

            <div class="form-group col-md-8">
                <div class="input-group edit-border">
                    <span class="input-group-text calback border-left-0 family-calander"><i class="fa fa-calendar formicon"></i></span>
                    <input type="text" 
                        [maxDate]="maxDate" 
                        formControlName="dateOfBirth"  
                        bsDatepicker 
                        class="form-control" 
                        placeholder="Date of birth" 
                        [bsConfig]="{dateInputFormat: 'DD/MM/YYYY'}" 
                        (ngModelChange)="onCalcAge($event)"
                    >
                    <div class="input-group-prepend"> 
                        <span class="age-input">
                            <span class="mr-2">{{memberAge}}</span> 
                            <span>Years old</span>
                        </span>
                    </div>                
                </div>
                <div *ngIf="submitted && mf.dateOfBirth.errors" class="invalid-feedback">
                    <span *ngIf="mf.dateOfBirth.errors.required">Date of birth is required</span>
                </div> 
            </div>
        </div>

        <div *ngIf="!isMain" class="form-row subgraphs">
            <div class="form-group col-md-4 edit-border"> 
                <select #relationVal formControlName="relation" class="form-control"  [ngClass]="{'custom-arrow': isDisabled}" (change)="onRelationTypeChange(relationVal.value)">
                    <option value="" disabled>Select relation type</option>
                    <option value="Spouse">Spouse</option>
                    <option value="Child">Child</option>
                    <option value="Divorcee">Divorcee</option>
                    <option value="Parent">Parent</option>
                </select>
                <div *ngIf="!submitted && mf.relation.errors" class="invalid-feedback">
                    <span *ngIf="mf.relation.errors.required">Relation type is required</span>
                </div>  
            </div>
            <div class="form-group col-md-8 edit-border"> 
                <select formControlName="relations" class="form-control" [ngClass]="{'custom-arrow': isDisabled}" [compareWith]="relationCompareFn">
                    <option value="">Select relationship</option>
                    <option *ngFor="let relation of relationList" [ngValue]="relation">{{relation.name}}</option>
                </select>
                <div *ngIf="!isValid" class="invalid-feedback">
                    <span *ngIf="mf.relations.errors">Relationship is required</span>
                </div>  
            </div>
        </div>

        <div *ngIf="!isDisabled" class="form-row subgraphs">
            <div class="custom-control custom-checkbox custom-control-inline ml-2">
                <input formControlName="isDependent" type="checkbox" class="custom-control-input" id="dependant{{member.id}}">
                <label class="custom-control-label ccnew" for="dependant{{member.id}}">Dependant</label>
            </div>

            <div class="custom-control custom-checkbox custom-control-inline ml-2">
                <input formControlName="isDeceased" type="checkbox" class="custom-control-input" id="deceased{{member.id}}">
                <label class="custom-control-label ccnew" for="deceased{{member.id}}">Deceased</label>
            </div> 
        </div>

    </div>
</form>

<ng-template #deleteMemberRef>
    <div class="modal-body text-center">
        <img src="assets/img/warning.png" alt="" class="alert_img">
        <p class="del_text font-weight-normal">Do you want to delete this node?</p>
    </div>
    <div class="modal-footer justify-content-center border-top-0 nb-footer pt-0 pb-5" >
        <button (click)="decline()" type="button" class="btn alert_yes_b" style="font-size: 12px;"  data-dismiss="modal">Cancel</button>         
        <button (click)="deleteMember()" type="button" class="btn alert_no_b mr-1" style="font-size: 12px;">Yes, Delete</button>
    </div>
</ng-template> 
      