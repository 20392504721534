import { Pipe, PipeTransform } from '@angular/core';
import { Console } from 'console';

@Pipe({
  name: 'graphCalculation'
})
export class GraphCalculationPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return null;
  }

  getRetienmentGoal(age,retirement_age,balance_remaining,retirement_income,gender){



    // console.log("age>>>>>",age)
    // console.log("retirement_age>>>>>",retirement_age)
    // console.log("balance_remaining>>>>>",balance_remaining)
    // console.log("retirement_income>>>>>",retirement_income)


    //let retirement_age = 60
    //let age = 30
    let life_expectancy_add_5 = this.getLifeExpetancy(age,gender) + 5
    //let balance_remaining = 10000
    //let retirement_income = 5200
    let interest  = 0.05
    let inflation = 0.03
    
    let yrs_to_retirement =  retirement_age -  age
    let yrs_to_LE_add_5 = life_expectancy_add_5 - age


    
    let pef = this.pre_exponential_factor_burndown(balance_remaining, retirement_income,yrs_to_LE_add_5, interest, inflation)
    
    return Math.round(this.balance_at_time_burndown(retirement_income, interest, inflation,pef,yrs_to_retirement) / 100.0) * 100
    
    
    }
  
  getSuperGrowth(age, retirement_age,superBalance,income,percentWage){
  


    let secnarios = [0.07,0.05,0.09,0.075]

    //let retirement_age = 60
    //let age = 30
    //let superBalance = 10000
    let timeAtBalance  = age
    //let super_interest = 0.07
    let wage_growth  = 0.04
    //let income = 60000
  
    //let percentWage  = 0.10
    let initialInsurance = 0
    let insuranceInterest = 0.00


    let senarioArray = []

  for (let j = 0; j < 4; j++) { 
  
    let ic = income * percentWage * 0.85
    let ttb =  timeAtBalance - age
    let pef1 = this.pre_exponential_factor_growth(secnarios[j],ic,wage_growth,initialInsurance,insuranceInterest,superBalance,ttb)
  

    let arr = []
    let range = (retirement_age + 1) - age
    for (let i = 0; i < range; i++) {
  
      arr[i] = this.balance_at_time_growth(ic,wage_growth,initialInsurance,insuranceInterest,secnarios[j],pef1,i)

    }

    senarioArray.push(arr)
  
  }

  return senarioArray
  
  }
  
  getProtectionSuperGrowth(retirement_age,age,superBalance,income,percentWage,retirement_income,gender){
  
  
    //let retirement_age = 60
    //let age = 30
   // let superBalance = 0
    let timeAtBalance  = age
    let superInterest = 0.075
   // let income = 10000
    let wageGrowth  = 0.04
    //let percentWage  = 0.10
    let initialInsurance = 0
    let insuranceInterest = 0.00
  
  
  let le = this.getLifeExpetancy(age,gender)
  let life_expectancy_add_5 =  le + 5
  let balance_remaining = 0
  //let retirement_income = 5200
  let interest  = 0.07
  let inflation = 0.03
  
  
  let yrs_to_retirement =  retirement_age -  age
  let yrs_to_LE_add_5 = life_expectancy_add_5 - age
  
  let ic = income * percentWage * 0.85
  let ttb =  timeAtBalance - age
  let pef1 = this.pre_exponential_factor_growth(superInterest,ic,wageGrowth,initialInsurance,insuranceInterest,superBalance,ttb)
  
  
  
  var creationarr = []
  var steppdLine = []
  var deathLine = []
  let range = (retirement_age + 1) - age
  
  
  for (let i = 0; i < range; i++) {
  
  
    let bttg = this.balance_at_time_growth(ic,wageGrowth,initialInsurance,insuranceInterest,superInterest,pef1,i)
    creationarr[i] = bttg
    if (i == range - 1) {
  
        steppdLine[i] = bttg * 2
  
    }
    else {
  
      steppdLine[i] = 0
  }
  
  
  }

  let death = Math.ceil(range /  2)

  for (let i = 0; i < death; i++) {
  
    
  
    let bttg = this.balance_at_time_growth(ic,wageGrowth,initialInsurance,insuranceInterest,superInterest,pef1,i)
    //creationarr[i] = bttg
    if (i == death - 1) {
        deathLine[i] = 0
        deathLine[i + 1] = bttg * 2
  
    }
    else {
  
      deathLine[i] = 0
  }
  
  
  }
  
  
  
  superBalance = creationarr[range - 1]
  
  var bdtarr = []
 // console.log("yrs_to_retirement",yrs_to_retirement)
 // console.log("life_expectancy_add_5",life_expectancy_add_5)
  //console.log("life_expectancy_add_5",retirement_age)
   
  let pefbd = this.pre_exponential_factor_burndown(superBalance, retirement_income,yrs_to_retirement, interest, inflation)
  let r =  (life_expectancy_add_5 -  age ) + 1
  for (let i = (yrs_to_retirement); i < (r); i++) {
  
      //console.log("yrs_to_retirement",yrs_to_retirement) 
      //console.log("life_expectancy_add_5",life_expectancy_add_5) 
      //console.log("retirement_age",retirement_age) 

      let  bbb = this.balance_at_time_burndown(retirement_income,interest,inflation,pefbd,i)
  
      if (bbb <= 0){
  
        bdtarr[i] = 0
        break
  
      }
      else{
  
        bdtarr[i] = bbb
     }
  
  }
  
  
  return [creationarr,bdtarr,steppdLine,deathLine]
  
  }


  getProtectionGraphData(retirement_age,age,superBalance,income,percentWage,retirement_income,gender){

     let insuranacePremium = 0
     let advice_annual_rate_of_return  = this.advice_annual_rate_of_return(insuranacePremium)
     let timeAtBalance  = age

     let le = this.getLifeExpetancy(age,gender)
     let yrs_to_LE= le - age

     let net_change_at_death = 0

     let yrs_to_retirement =  retirement_age -  age
     let death_age =  Math.floor(yrs_to_retirement /  2)
     let yrs_to_death =  death_age///death_age -  age

    let  retirement_percentage_if_partner_deceased = 0  // Take from local store
    let  selected_person_salary = 0
    let  selected_person_contribution_rate = 0
    
    let superInterest = 0.075
    let wageGrowth  = 0.04
    let initialInsurance = 0
    let insuranceInterest = 0.00
    let inflation = 0.03
    let balanced_no_insurance_rate = 0.075

    let nominal_interest_rate = 0.07

    let  spouse_1_advice_differential_growing = this.super_balance_growing_to_retirement(age,retirement_age,superBalance,timeAtBalance,advice_annual_rate_of_return,income,wageGrowth,percentWage,initialInsurance,insuranceInterest)

    let  spouse_1_balanced_differential_growing = this.super_balance_growing_to_retirement(age,retirement_age,superBalance,timeAtBalance,superInterest,income,wageGrowth,percentWage,initialInsurance,insuranceInterest)
   
    let advice_growing_differential_balances =  spouse_1_advice_differential_growing
    let balanced_growing_differential_balances =  spouse_1_balanced_differential_growing

    let years_to_death_plus_one = yrs_to_death + 1

   

    let advice_growing_differential_till_death  =  advice_growing_differential_balances.slice(0,years_to_death_plus_one)
    let balanced_balances_till_death  =  balanced_growing_differential_balances.slice(0,years_to_death_plus_one)

    let advice_burndown_annuity_balances =  this.super_balance_from_retirement_toLE5(advice_growing_differential_balances[advice_growing_differential_balances.length  - 1],retirement_income,age,retirement_age,le,nominal_interest_rate,inflation)
    //console.log("advice_burndown_annuity_balances",advice_burndown_annuity_balances)
  
    let balanced_burndown_annuity_balances  =  this.super_balance_from_retirement_toLE5((balanced_growing_differential_balances[balanced_growing_differential_balances.length  - 1]),retirement_income,age,retirement_age,le,nominal_interest_rate,inflation)
    let balanced_burndown_at_death_aannuity_balances  =  this.super_balance_from_retirement_toLE5((balanced_balances_till_death[balanced_balances_till_death.length  - 1] + net_change_at_death),((retirement_percentage_if_partner_deceased / 100) *retirement_income),age,(age + death_age),le,nominal_interest_rate,inflation)

    
    let retIncome = (retirement_percentage_if_partner_deceased / 100) * retirement_income
    let retirement_value_selected_spouse = this.getRetienmentGoal(age,retirement_age,advice_growing_differential_balances[advice_growing_differential_balances.length  - 1],retIncome,gender) 
    let single_spouse_burndown_required_balances = this.super_balance_from_retirement_toLE5(retirement_value_selected_spouse,((retirement_percentage_if_partner_deceased / 100) *retirement_income),age,retirement_age,le,nominal_interest_rate,inflation)
    let single_spouse_growth_required_balances = this.super_balance_growing_to_retirement(age + death_age,retirement_age,single_spouse_burndown_required_balances[0],retirement_age,balanced_no_insurance_rate,income,wageGrowth,percentWage,initialInsurance,insuranceInterest)

    console.log("single_spouse_growth_required_balances",single_spouse_growth_required_balances)

    let yearArrayTodeath = this.fillArray(null, yrs_to_death)
    //single_spouse_growth_required_balances.unshift(yrs_to_death) 
    let single_spouse_1_growth_required_balances_from_death = yearArrayTodeath.concat(single_spouse_growth_required_balances)

  
    
 

    let ages = []
    for (let i = 0; i <= (yrs_to_retirement); i++) {
      ages.push(age + i)
    } 
   

    
    let advice_growing_annuity_graph_data  = this.advice_growing_annuity_data(yrs_to_LE,null,advice_growing_differential_balances)
    let advice_burndown_annuity_graph_data   = this.advice_burndown_annuity_data((yrs_to_LE + 1),null,advice_burndown_annuity_balances)

    let balanced_combined_burndown_data  =  this.advice_burndown_annuity_data(yrs_to_LE + 1,null,balanced_burndown_annuity_balances)
    let balanced_combined_creation_data  =  this.advice_growing_annuity_data((yrs_to_LE + 1),null,balanced_growing_differential_balances)

    let advise_creation_with_lump_sum = this.advice_growing_annuity_data(yrs_to_LE,null,single_spouse_1_growth_required_balances_from_death)
    let advise_creation_combined_till_death = this.advice_growing_annuity_data(yrs_to_LE,null,advice_growing_differential_till_death)
    let balanced_creation_to_death = this.advice_growing_annuity_data(yrs_to_LE,null,balanced_balances_till_death)
    
    console.log("mark here>>>>>>>>>")
    let balanced_burndown_to_death = this.get_balanced_burndown_to_death(death_age,null,balanced_burndown_at_death_aannuity_balances)
    let advise_burndown_with_lump_sum = this.get_advise_burndown_with_lump_sum(yrs_to_retirement,null,single_spouse_burndown_required_balances)
  
    console.log("single_spouse_burndown_required_balances",single_spouse_burndown_required_balances)
   
    return [advice_growing_annuity_graph_data,advice_burndown_annuity_graph_data,balanced_combined_burndown_data,balanced_combined_creation_data,advise_creation_with_lump_sum,advise_creation_combined_till_death,balanced_creation_to_death,balanced_burndown_to_death,advise_burndown_with_lump_sum]
 
//grpahsupport





  }


  get_advise_burndown_with_lump_sum(retAge,v,collection){

    let filed = this.fillArray(null,retAge)
    console.log("collection",collection)
    let combined = filed.concat(collection)
   //  combined.unshift(null)
     console.log("advise_burndown_with_lump_sum",combined)
  return combined

  }

  get_balanced_burndown_to_death(count,v,collection){

   // let difference = count - collection.length + 1
    console.log("difference",count)
    let filed = this.fillArray(null,count)
    console.log("collection",collection)
    let combined = filed.concat(collection)
    // combined.unshift(null)

  return combined

  }

  retirnmentGraph(le,retirnmentAge,age){

    let retirnment = []
    console.log("retirnmentGraph",le,retirnmentAge,age)

    for (let i = age; i <= (le); i++) {
      

      if (i == retirnmentAge){

        retirnment.push(10000)
        retirnment.push(10000000)

      }
      else{


        retirnment.push(null)


      }




    } 

    return retirnment

  }

  advice_growing_annuity_data(count,v,collection){

    let range = collection.length - count
    console.log("range",count)
    let filed = this.fillArray(null,range)
    let combined = collection.concat(filed)
  //  combined.unshift(count)
    return combined

  } 

   advice_burndown_annuity_data (count,v,collection){
 
    let difference = count - collection.length 
    console.log("difference",count)
    let filed = this.fillArray(null,difference)
    console.log("collection",collection)
    let combined = filed.concat(collection)
     combined.unshift(null)
    // let partitionArray = []
    // var partitionCurser = 0
    
    // console.log("combined",combined[40])

    //  for (let i = 0; i <= combined.length; i++) {


    //   let tempArray = []
    //   for (let j = 0; j < 2; j++) {

    //   //  console.log("for")
    //    // console.log(i  + j)
    //    // console.log(combined[i + j])
    //     tempArray.push[combined[i + j]]


    //   }
      
    //   partitionArray.push(tempArray)
    //   partitionCurser++;

    //  } 
    //  console.log("partitionArray")
    //  console.log(partitionArray)
 
    return combined//.unshift(count)

  } 



   fillArray(value, len) {
    if (len == 0) return [];
    var a = [value];
    while (a.length * 2 <= len) a = a.concat(a);
    if (a.length < len) a = a.concat(a.slice(0, len - a.length));
    return a;
  }

  super_balance_from_retirement_toLE5(balance_at_retirement,retirement_income,age,retirement_age,LEAdd5,superInterest,inflation){

    let yrs_to_retirement =  retirement_age -  age
    let yrs_to_LE_add_5 = LEAdd5 - age
    let pefbd = this.pre_exponential_factor_burndown(balance_at_retirement, retirement_income,yrs_to_retirement, superInterest, inflation)
    let range =   []//(yrs_to_LE_add_5 + 1) - yrs_to_retirement 

    // console.log("yrs_to_retirement",yrs_to_retirement) 
    // console.log("life_expectancy_add_5",LEAdd5) 
    // console.log("retirement_age",retirement_age) 
    // console.log("range",range,balance_at_retirement) 

    for (var i = yrs_to_retirement; i < (yrs_to_LE_add_5 + 1); i++) {
      range.push(i);
  }

    let balance = []

     for (let i = 0; i < (range.length); i++) {
  
      let value = range[i] 

      let  bbb = this.balance_at_time_burndown(retirement_income,superInterest,inflation,pefbd,value)
      balance.push(bbb)
  
    //   if (bbb <= 0){
  
    //     balance[i] = 0
    //     break
  
    //   }
    //   else{
  
    //     balance[i] = bbb
    //  }
    }
   // console.log("balance",balance.length) 

    return balance
  
  
  }



  super_balance_growing_to_retirement(age,retirement_age,superBalance,timeAtBalance,superInterest,income,wageGrowth,percentWage,initialInsurance,insuranceInterest){


    let ic = income * percentWage * 0.85
    let ttb =  timeAtBalance - age
    let pef1 = this.pre_exponential_factor_growth(superInterest,ic,wageGrowth,initialInsurance,insuranceInterest,superBalance,ttb)

    let range = (retirement_age + 1) - age
    var creationarr = []

    for (let i = 0; i < range; i++) {
  
  
      let bttg = this.balance_at_time_growth(ic,wageGrowth,initialInsurance,insuranceInterest,superInterest,pef1,i)
      creationarr[i] = bttg    
    
    }

    return creationarr


  }

  

advice_annual_rate_of_return(insuranacePremium){

if (insuranacePremium == 0){ 
  return 0.075 // balanced-no-insurance-rate
}
return 0.09 // growth-rate

}
  
  
  
  compound_term(initial_value, rate_of_return, time){
  
  
  return Math.pow((rate_of_return + 1),time) * initial_value
  }
  
  
  vertical_shift_contribution(initial_contribution, wage_growth, time, super_interest){
  
  let denominator = Math.log(wage_growth + 1) - super_interest
  if (denominator == 0){
  
    return 0
  
  } 
  
  let ct =  this.compound_term(initial_contribution, wage_growth, time)
  
  return ct / denominator
  
  }
  
  
  vertical_shift_insurance_Payment(initial_payment, insurance_interest, time, super_interest){
  
  let denominator = super_interest -  Math.log(insurance_interest + 1) 
  if (denominator == 0){
  
    return 0
  
  } 
  
  let ct =  this.compound_term(initial_payment, insurance_interest, time)
  return ct / denominator
  }
  
  
  pre_exponential_factor_growth(super_interest, initial_contribution, wage_growth, initial_insurance, insurance_interest, super_balance, time) {
  
    let vsc = this.vertical_shift_contribution(initial_contribution, wage_growth, time, super_interest)
   
    let vsip = this.vertical_shift_insurance_Payment(initial_insurance, insurance_interest, time, super_interest)
  
    let ef = this.exponential_factor(time ,super_interest)
  
    return (super_balance - vsc - vsip) / ef;
  }
  
  
  balance_at_time_growth(initial_contribution, wage_growth, initial_insurance, insurance_interest, super_interest, pre_exponential_factor, time){
  
    let vsc = this.vertical_shift_contribution(initial_contribution, wage_growth, time, super_interest)
    let vsip = this.vertical_shift_insurance_Payment(initial_insurance, insurance_interest, time, super_interest)
    let ef = this.exponential_factor(time ,super_interest)
    return (pre_exponential_factor * ef) + vsc + vsip
  
  }
   
   
  // ref, use
  getLifeExpetancy(age,gender){

    let le = {20 :[61,65],
       21 :[60, 64],
       22 :[59, 63],
       23 :[58.1, 62.1],
       24 :[57.1, 61.1],
       25 :[56.2, 60.1],
       26 :[55.2, 59.1],
       27 :[54.2, 58.1],
       28 :[53.3, 57.1],
       29 :[52.3, 56.2],
       30 :[51.3, 55.2],
       31 :[50.4, 54.2],
       32 :[49.4, 53.2],
       33 :[48.5, 52.2],
       34 :[47.5, 51.3],
       35 :[46.6, 50.3],
       36 :[45.6, 49.3],
       37 :[44.7, 48.3],
       38 :[43.7, 47.4],
       39 :[42.8, 46.4],
       40 :[41.8, 45.4],
       41 :[40.9, 44.5],
       42 :[39.9, 43.5],
       43 :[39, 42.6],
       44 :[38.1, 41.6],
       45 :[37.1, 40.6],
       46 :[36.2, 39.7],
       47 :[35.3, 38.7],
       48 :[34.4, 37.8],
       49 :[33.5, 36.9],
       50 :[32.5, 35.9],
       51 :[31.6, 35],
       52 :[30.7, 34],
       53 :[29.8, 33.1],
       54 :[29, 32.2],
       55 :[28.1, 31.3],
       56 :[27.2, 30.4],
       57 :[26.3, 29.4],
       58 :[25.5, 28.5],
       59 :[24.6, 27.6],
       60 :[23.8, 26.7],
       61 :[22.9, 25.8],
       62 :[22.1, 24.9],
       63 :[21.3, 24.1],
       64 :[20.4, 23.2],
       65 :[19.6, 22.3],
       66 :[18.8, 21.4],
       67 :[18, 20.6],
       68 :[17.2, 19.7],
       69 :[16.5, 18.9],
       70 :[15.7, 18],
       71 :[14.9, 17.2],
       72 :[14.2, 16.4],
       73 :[13.5, 15.6],
       74 :[12.8, 14.8],
       75 :[12.1, 14]}
   
   
   
       var ag = age
       
       
       if (ag < 20) {
   
         ag = 20
   
       }
       else if (ag > 75){
   
         ag = 75
          
       }
   
   
       let leArr = le[ag]
  
   
       if (gender == 1){
      
   
         return age + leArr[0]
  
   
       }
       else{
   
         return age + leArr[1]
  
       }
  
        return 85.2
   
   }

// ref, use
pre_exponential_factor_burndown(balance_remaining, retirement_income,time, interest, inflation) {

    let vsi = this.vertical_shift_inflation(retirement_income, time, interest, inflation)
    let ef = this.exponential_factor(time, interest)
    return (balance_remaining - vsi) / ef;
  }

// ref, use
balance_at_time_burndown(retirement_income, interest, inflation, pre_exponential_factor, time){

    let vsi = this.vertical_shift_inflation(retirement_income, time, interest, inflation)
    let ef = this.exponential_factor(time, interest)
      return (pre_exponential_factor * ef) + vsi
  
  }

// ref
vertical_shift_inflation(retirement_income, time, interest, inflation) {

    let denominator = interest - Math.log(inflation + 1)
    if (denominator == 0){
    
      return 0
    
    } 
    
    return (Math.pow((inflation + 1),time) * retirement_income) / denominator
    
    
    }

// ref
exponential_factor(interest, time) {

      return Math.exp(interest * time);
    }
}
