import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LeftsideComponent } from './family-tree/leftside/leftside.component';
import {FormsModule,ReactiveFormsModule} from '@angular/forms'; 
import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ContainerComponent } from './container/container.component';
import { JwtInterceptorService } from './shared/jwt-interceptor.service';
import { ErrorInterceptor } from './shared/error.interceptor';
import { TokenInterceptor } from './auth/token.interceptor';
import { CookieService } from 'ngx-cookie-service';
import { NgxUiLoaderModule } from  'ngx-ui-loader';
import { ToastrModule } from 'ngx-toastr';
import { ModalModule, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Ng2PanZoomModule } from 'ng2-panzoom';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { MouseWheelDirective } from './utility/mousewheel.directive';
import { ChartsModule } from 'ng2-charts';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { GraphCalculationPipe } from './shared/custom-pipes/graph-calculation.pipe';
import { CreationGraphPipe } from './shared/custom-pipes/creation-graph.pipe';
import { ProtectionGraphPipe } from './shared/custom-pipes/protection-graph.pipe';
import { DistributionGraphPipe } from './shared/custom-pipes/distribution-graph.pipe';
import { TreeComponent } from './family-tree/tree/tree.component';
import { RightsideComponent } from './family-tree/rightside/rightside.component';
import { FinancesComponent } from './family-tree/rightside/finances/finances.component';
import { BudgetComponent } from './family-tree/rightside/budget/budget.component';
import { FamilyComponent } from './family-tree/rightside/family/family.component';
import { RetirementComponent } from './family-tree/rightside/retirement/retirement.component';
import { ProvisionsComponent } from './family-tree/rightside/provisions/provisions.component';
import { DependantSupportComponent } from './family-tree/rightside/provisions/dependant-support/dependant-support.component';
import { OtherComponent } from './family-tree/rightside/provisions/other/other.component';
import { SpouseDeathTpdComponent } from './family-tree/rightside/provisions/spouse-death-tpd/spouse-death-tpd.component';
import { ImmediateNeedsdComponent } from './family-tree/rightside/provisions/immediate-needsd/immediate-needsd.component';
import { ProgressiveNeedsComponent } from './family-tree/rightside/provisions/progressive-needs/progressive-needs.component';
import { BenefitPeriodComponent } from './family-tree/rightside/provisions/benefit-period/benefit-period.component';
import { WillComponent } from './family-tree/rightside/will/will.component';
import { DocumentsComponent } from './family-tree/rightside/documents/documents.component';
import { InsuranceComponent } from './family-tree/rightside/insurance/insurance.component';
import { IncomeProtectionComponent } from './family-tree/rightside/insurance/income-protection/income-protection.component';
import { LifeComponent } from './family-tree/rightside/insurance/life/life.component';
import { TraumaComponent } from './family-tree/rightside/insurance/trauma/trauma.component';
import { SummaryComponent } from './family-tree/rightside/insurance/summary/summary.component';
import { TpdComponent } from './family-tree/rightside/insurance/tpd/tpd.component';
import { ExecutorComponent } from './family-tree/rightside/will/executor/executor.component';
import { ResidualBequestsComponent } from './family-tree/rightside/will/residual-bequests/residual-bequests.component';
import { CalamityBeneficiariesComponent } from './family-tree/rightside/will/calamity-beneficiaries/calamity-beneficiaries.component';
import { OtherEntitiesComponent } from './family-tree/rightside/will/other-entities/other-entities.component';
import { LifetimeArrangementsComponent } from './family-tree/rightside/will/lifetime-arrangements/lifetime-arrangements.component';
import { DistibutionComponent } from './family-tree/leftside/distibution/distibution.component';
import { CreationComponent } from './family-tree/leftside/creation/creation.component';
import { ProtectionComponent } from './family-tree/leftside/protection/protection.component';
import { CalculatorComponent } from './family-tree/leftside/calculator/calculator.component';
import { RightsidePipe } from './shared/custom-pipes/rightside.pipe';
import { FamilyNodesPipe } from './shared/custom-pipes/family-nodes.pipe';
import { MemberFormComponent } from './family-tree/rightside/family/member-form/member-form.component';
import { UpdateNoteComponent } from './family-tree/rightside/family/update-note/update-note.component';
import { FamilyTreePdfComponent } from './family-tree/rightside/documents/family-tree-pdf/family-tree-pdf.component';


const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    LeftsideComponent,
    ContainerComponent,
    MouseWheelDirective,
    GraphCalculationPipe,
    CreationGraphPipe,
    ProtectionGraphPipe,
    DistributionGraphPipe,
    TreeComponent,
    RightsideComponent,
    FinancesComponent,
    BudgetComponent,
    FamilyComponent,
    RetirementComponent,
    ProvisionsComponent,
    DependantSupportComponent,
    OtherComponent,
    SpouseDeathTpdComponent,
    ImmediateNeedsdComponent,
    ProgressiveNeedsComponent,
    BenefitPeriodComponent,
    WillComponent,
    DocumentsComponent,
    InsuranceComponent,
    IncomeProtectionComponent,
    LifeComponent,
    TraumaComponent,
    SummaryComponent,
    TpdComponent,
    ExecutorComponent,
    ResidualBequestsComponent,
    CalamityBeneficiariesComponent,
    OtherEntitiesComponent,
    LifetimeArrangementsComponent,
    DistibutionComponent,
    CreationComponent,
    ProtectionComponent,
    CalculatorComponent,
    RightsidePipe,
    FamilyNodesPipe,
    MemberFormComponent,
    UpdateNoteComponent,
    FamilyTreePdfComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,  
    FormsModule,  
    ReactiveFormsModule,
    DatepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule,
    NgxUiLoaderModule,
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    Ng2PanZoomModule,
    PinchZoomModule,
    ChartsModule,
    NgxMaskModule.forRoot(maskConfig),
  ],
  providers: [
    CookieService, BsModalService, BsModalRef, 
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    FinancesComponent
  ]
})
export class AppModule { }
