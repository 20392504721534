<div class="boxcolor mt-2">
  <table class="table tabledocument">
    <thead>
      <tr>
        <th class="tc2 font-weight-normal pt-0 pb-1 tab_fs">Download Documents</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="tablefist tableb border-right-0 doc_fontsize">File Note</td>
        <td class="tableleftb tablefist text-right"><i class="fa fa-download" (click)="getFileNote()"
            style="color: #1c8cc1;"></i></td>
      </tr>
      <tr>
        <td class="tablefist tableb border-right-0 doc_fontsize">Estate Briefing Notes</td>
        <td class="tableleftb tablefist text-right"><i class="fa fa-download" (click)="getFamilyTreeDocGenaration()"
            style="color: #1c8cc1;"></i></td>
      </tr>
      <tr>
        <td class="tablefist tableb border-right-0 doc_fontsize">Soft Copy Instruction Sheet(Core)</td>
        <td class="tableleftb tablefist text-right"><i class="fa fa-download" (click)="getInstructionSheetCore()"
            style="color: #1c8cc1;"></i></td>
      </tr>
      <tr>
        <td class="tablefist tableb border-right-0 doc_fontsize">Soft Copy Instruction Sheet(Multiple TDT)</td>
        <td class="tableleftb tablefist text-right"><i class="fa fa-download" (click)="getInstructionSheetMultipleTDT()"
            style="color: #1c8cc1;"></i></td>
      </tr>
      <tr>
        <td class="tablefist tableb border-right-0 doc_fontsize">Family Tree Document (PDF)</td>
        <td class="tableleftb tablefist text-right"><i class="fa fa-download" (click)="getFTree()"
            style="color: #1c8cc1;"></i></td>
      </tr>
    </tbody>
  </table>
</div>