import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FamilyTreeService } from 'src/app/family-tree/family-tree.service';
import { FamilyNodesPipe } from 'src/app/shared/custom-pipes/family-nodes.pipe';
import { GetTreeModel, MemberModel, RelationsModel } from 'src/app/shared/models/get-tree.model';
import { SharedDataService } from 'src/app/shared/service/shared-data.service';

@Component({
  selector: 'app-member-form',
  templateUrl: './member-form.component.html',
  styleUrls: ['./member-form.component.css'],
  providers: [FamilyNodesPipe, DatePipe]
})
export class MemberFormComponent implements OnInit , AfterViewInit{

  @Input() members: GetTreeModel[]
  @Input() member: GetTreeModel
  @Input() familyTreeId: string
  @Output() addMemberNode = new EventEmitter<any>();
  @Output() updateMemberNode = new EventEmitter<any>();
  @Output() deleteMemberNode = new EventEmitter<any>();
  @Output() clearTempMemberNode = new EventEmitter<any>();

  memberForm: FormGroup
  maxDate = new Date()
  submitted : boolean
  isMain: boolean
  isDisabled: boolean
  memberAge : number
  relationList: RelationsModel[] = []
  currentRelation = new RelationsModel()
  memberUpdateData = new MemberModel()
  isNewMember = false
  isUpdating = false
  isValid = false

  constructor(
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    private preloaderService: NgxUiLoaderService,
    public modalRef: BsModalRef,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private sharedDataService: SharedDataService, 
    private familyTreeService: FamilyTreeService,
    private familyNodePipe: FamilyNodesPipe
  ) { }

  ngOnInit(): void {
    if (this.member.id  === undefined) { // Check: member is available, by member id
      this.isNewMember = true;
    }
    this.maxDate.setDate(this.maxDate.getDate() + 1)

    const isRoot = false;
    this.memberForm = this.formBuilder.group({
      familyTreeId: [ this.familyTreeId ],
      isRoot: [ isRoot ],
      firstName: [ '', Validators.required ],
      middleName: [ '' ],   
      lastName: [ '', Validators.required ],
      gender: [ '', Validators.required ],
      dateOfBirth: [ '', Validators.required ],
      relation: [ '', Validators.required ],
      relations: [ '', Validators.required ],
      isDependent: [ false ],
      isDeceased: [ false ],
    });

    if (!this.isNewMember) { 
      this.setMemberDetails(this.member) // Set: current member details
    } 
  }

  get mf() { return this.memberForm.controls; }

  ngAfterViewInit() : void {
    // Listen for form value changes
    this.memberForm.valueChanges.subscribe( (values: any) => {
      // if(this.memberForm.value.relations == null){
      //   this.memberForm.controls['relations'].setValue(this.relationList[0])
      //  }
      this.isUpdating = true;
      this.submitted = true;
      if (this.memberForm.invalid) {
        this.isValid = false;
        return;
      }
      this.isValid = true;
    })
  }

  // Add New Member or Update Exsisting Member
  saveMember() { 
    this.submitted = true
    if (this.memberForm.invalid) {
      this.isValid = false;
      return;
    }
    this.memberUpdateData = this.memberForm.value
    this.memberUpdateData.relations =  this.memberForm.value.relations.relationIDs
    this.memberUpdateData.dateOfBirth =  this.datePipe.transform(this.memberUpdateData.dateOfBirth, 'yyyy-MM-dd')
    this.memberUpdateData.calculateAge = this.calcAge( this.memberForm.value.dateOfBirth)

    if (this.isValid) {
      if (!this.isNewMember) {
        this.updateMember(this.memberUpdateData, this.member.id)
      } else {
        this.addMember(this.memberUpdateData)
      }
    }
  }

  private addMember(data: MemberModel) { 
    this.preloaderService.start()
    this.familyTreeService.addMember(data).subscribe( res => {
      if (res.success) {
        this.toastr.success('Member added successfully')
        this.addMemberNode.emit(res.response);
      } else {
        this.toastr.error(res.error.message)
      }
      this.preloaderService.stop()
    }, err => {
      this.preloaderService.stop()
      this.toastr.error('Operation Failed')
    })
  }

  private updateMember(data: MemberModel, id: string) {
    this.preloaderService.start();
    this.familyTreeService.updateMember(data, id).subscribe( res => {
      if (res.success) {
        this.toastr.success('Member details updated successfully')
        this.updateMemberNode.emit(res.response);
      } else {
        this.toastr.error(res.error.message)
      }
      this.preloaderService.stop()
    }, err => {
      this.preloaderService.stop()
      this.toastr.error('Operation Failed')
    })
  }

  // Set member values
  setMemberDetails(member: GetTreeModel) {
    this.isMain = member.isRoot
    this.checkDisabled(member)
    this.memberAge = this.calcAge(member?.dateOfBirth)

    if (!this.isMain) { // if member is not Main, get relationship options
      this.currentRelation = this.familyNodePipe.getCurrentRepaltionShip( this.member, this.members )
      this.relationList = this.familyNodePipe.generateRelationshipOptionList( this.member.relation, this.member, this.members )
    } else {
      this.updateSharedMemberData(member)
    }

    this.memberForm.controls['relations'].enable()

    this.memberForm.controls['isRoot'].setValue(member.isRoot)
    this.memberForm.controls['firstName'].setValue(member.firstName)
    this.memberForm.controls['middleName'].setValue(member.middleName)
    this.memberForm.controls['lastName'].setValue(member.lastName)
    this.memberForm.controls['gender'].setValue(member.gender)
    this.memberForm.controls['dateOfBirth'].setValue( new Date(member.dateOfBirth) )
    this.memberForm.controls['relation'].setValue(member.relation)
    this.memberForm.controls['relations'].setValue(this.currentRelation)
    this.memberForm.controls['isDependent'].setValue(member.isDependent)
    this.memberForm.controls['isDeceased'].setValue(member.isDeceased)
    
    if (this.isDisabled) { // if member is Main or Spouse, Disable these fields 
      this.memberForm.controls['firstName'].disable()
      this.memberForm.controls['middleName'].disable()
      this.memberForm.controls['lastName'].disable()
      this.memberForm.controls['gender'].disable()
      this.memberForm.controls['dateOfBirth'].disable()
      this.memberForm.controls['relation'].disable()
      this.memberForm.controls['relations'].disable()
    }
  }

  onRelationTypeChange(relation: string) {
    this.memberForm.controls['relations'].enable()
    this.relationList = this.familyNodePipe.generateRelationshipOptionList( relation, this.member, this.members )
    
    // this.relationList[-1].name = 'select relationship'
  }

  relationCompareFn(option: RelationsModel, current: RelationsModel) : boolean {  // Set current relationship
    // current = (current == null) ? this.relationList[0] : current;
    if (option?.relationIDs &&  current?.relationIDs) {
      if (current.relationIDs?.length == 2 && option.relationIDs) {
        return current.relationIDs.every( r => {
          if (  option.relationIDs.find( e => e.relationDependantId == r.relationDependantId ) ) {
            return true
          } else {
            return false
          }
        })
      } else {
        return option.relationIDs[0].relationDependantId === current.relationIDs[0].relationDependantId
      }
    } else {
      return false
    }
  }

  checkDisabled(member: GetTreeModel) { // disable specific field if the member is main or spouse
    if (member.isRoot || this.isMainSpouse(member) ) {
      this.isDisabled = true
    }
  }

  isMainSpouse(member: GetTreeModel): boolean { // disable specific fields if the member is main or spouse
    const mainId = this.members.find(e => e.isRoot).id
    let isMainSpouse = false;
    if (member.relation === 'Spouse') {
      member.relations.forEach( e => {
        if (e.relationDependantId === mainId) {
          isMainSpouse = true;
          this.updateSharedPartnerData(member)
        }
      })
    } 
    return isMainSpouse
  }

  calcAge(dob: string) { // Calculate Age by DOB-- Add this to a pipe [TEMP]
    if (dob) {
      const bDay = moment(dob);
      const today = moment(new Date());

      const calAge = today.diff(bDay, 'years')
      return calAge;
    } else {
      return 0;
    }
  }

  onCalcAge(event: any) {
    // let getDate = new Date(event)
    // let today = new Date()
    
    // if ( today > getDate ) {
    //   // ((document.getElementById(id) as HTMLInputElement).value=calculateAge.toString());
    // }
    this.memberAge = this.calcAge(event);
    this.memberForm.patchValue({ // update form value
      calculateAge: this.memberAge
    });
  }

  // ---  Delete member --- //
  openDeleteDialog(template: TemplateRef<any>) { // delete member
    this.modalRef = this.modalService.show( template, { 
      class: 'modal-sm', 
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
    });
  }
  
  deleteMember() {
    const dependentId =  this.member.id
    if (this.isNewMember) {
      this.clearTempMemberNode.emit()
      this.modalRef.hide()
    } else {
      this.removeMember(dependentId)
    }
  }

  private removeMember(dependentId: string) {
    this.preloaderService.start();
    this.familyTreeService.deleteNode(dependentId).subscribe( res => {
      if (res.success) {
        this.toastr.success('Member deleted successfully')
        this.deleteMemberNode.emit(dependentId);
        this.modalRef.hide()
      } else {
        this.toastr.error(res.error.message)
      }
      this.preloaderService.stop()
    }, err => {
      this.preloaderService.stop()
      this.toastr.error('Operation Failed')
    })
  }

  decline(): void {
    this.modalRef.hide()
  }

  reset() {
    this.isValid = true
    if (this.isNewMember) {
      this.memberForm.reset();
      this.memberForm.controls['familyTreeId'].setValue(this.familyTreeId ) // set to default
      this.memberForm.controls['isRoot'].setValue(false) // set to default
      this.memberForm.controls['gender'].setValue('') // set to default
      this.memberForm.controls['relations'].setValue('') // set to default
      this.memberForm.controls['relations'].setValue('') // set to default
      this.memberForm.controls['isDependent'].setValue(false) // set to default
      this.memberForm.controls['isDeceased'].setValue(false) // set to default
      this.memberForm.controls['relations'].disable()
    } else {
      this.setMemberDetails(this.member)
    }
    this.isUpdating = false
    this.submitted = false
  }

  updateSharedPartnerData(spouseData: GetTreeModel) {
    const hasPertner = true
    this.sharedDataService.changePartnerFirstname(spouseData.firstName);
    this.sharedDataService.changePartnerHas(hasPertner);
    this.sharedDataService.changePartnerID(spouseData.firstName);
    this.sharedDataService.changPartnerAge(this.calcAge(spouseData.dateOfBirth));
  }

  updateSharedMemberData(memberData: GetTreeModel) {
    this.sharedDataService.changeClientFirstname(memberData.firstName);
    this.sharedDataService.changeClientID(memberData.id);
    this.sharedDataService.changClientAge(this.calcAge(memberData.dateOfBirth));
  }
}
