export class InsuranceModelBaseMOdel {
    public familyTreeId: string;
    public clientInsurances: InsurancesList[];
    public partnerInsurances: InsurancesList[];
    public note: string;
}

export class InsuranceModel extends InsuranceModelBaseMOdel{    
    public groupedCoversClient : any = []; 
    public groupedCoversPartner : any = []; 
}

export class InsurancesList{
    public clientCode: string;
    public policyNumber: string;
    public totalPremium: number;
    public totalPremiumCode: string;
    public policyOwner: string;
    public planName: string;
    public insuranceCoverType: number;
    public sumInsured: number;
    public lifeInsured: number;
    public waitingPeriod: string;
    public benefitPeriod: string;
    public insuranceCoverTypeName: string;
    public monthlyPremium: number; 
    public lifeSum: number; 
    public tpdSum: number; 
    public underwriter: string;
    public monthlyBenefit: number; 
    public policyDefenition: number; 
    public structure: number; 
    public fundingMechanism: number; 
}