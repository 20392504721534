import { Component, OnInit, Input } from '@angular/core';
import { FamilyTreeService } from 'src/app/family-tree/family-tree.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProvisionResponse, ImmediateNeedsResponse } from 'src/app/shared/models/response.model';
import { ProvisionModel, ImmediateNeedsItems } from 'src/app/shared/models/provision.model';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-immediate-needsd',
  templateUrl: './immediate-needsd.component.html',
  styleUrls: ['../../rightside.component.css']
})
export class ImmediateNeedsdComponent implements OnInit {

  @Input() family_tree_id:string;
  @Input() clientFirstname:string;
  @Input() partnerFirstname:string;
  @Input() partnerHas:boolean = false;
  isLoaded:boolean=false;
  @Input() provisionInfo:ProvisionModel;
  addImmediateNeedsForm: FormGroup;
  immediateNeedsItemsAdd:ImmediateNeedsItems;

  constructor(
    private familyTreeService :FamilyTreeService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getProvisionInfo();
  }

  getProvisionInfo(){
    // // this.ngxService.start();
    // this.familyTreeService.getProvision(this.family_tree_id).subscribe((provisionData:ProvisionResponse)=>{
    //   if(provisionData.success){
    //     this.provisionInfo = provisionData.response;
        
        let homePurchase = this.provisionInfo.immediateNeeds.clientImmediateNeeds !=null ? this.provisionInfo.immediateNeeds.clientImmediateNeeds.homePurchase :0,
        modificationHome = this.provisionInfo.immediateNeeds.clientImmediateNeeds !=null ? this.provisionInfo.immediateNeeds.clientImmediateNeeds.modificationHome :0,
        medicalTreatment = this.provisionInfo.immediateNeeds.clientImmediateNeeds !=null ? this.provisionInfo.immediateNeeds.clientImmediateNeeds.medicalTreatment :0,
        funeralLegalExpenses = this.provisionInfo.immediateNeeds.clientImmediateNeeds !=null ? this.provisionInfo.immediateNeeds.clientImmediateNeeds.funeralLegalExpenses :0,
        upfrontCapitalNeeds = this.provisionInfo.immediateNeeds.clientImmediateNeeds !=null ? this.provisionInfo.immediateNeeds.clientImmediateNeeds.upfrontCapitalNeeds :0,

        homePurchasePartner = this.provisionInfo.immediateNeeds.partnerImmediateNeeds !=null ? this.provisionInfo.immediateNeeds.partnerImmediateNeeds.homePurchase :0,
        modificationHomePartner = this.provisionInfo.immediateNeeds.partnerImmediateNeeds !=null ? this.provisionInfo.immediateNeeds.partnerImmediateNeeds.modificationHome :0,
        medicalTreatmentPartner = this.provisionInfo.immediateNeeds.partnerImmediateNeeds !=null ? this.provisionInfo.immediateNeeds.partnerImmediateNeeds.medicalTreatment :0,
        funeralLegalExpensesPartner = this.provisionInfo.immediateNeeds.partnerImmediateNeeds !=null ? this.provisionInfo.immediateNeeds.partnerImmediateNeeds.funeralLegalExpenses :0,
        upfrontCapitalNeedsPartner = this.provisionInfo.immediateNeeds.partnerImmediateNeeds !=null ? this.provisionInfo.immediateNeeds.partnerImmediateNeeds.upfrontCapitalNeeds :0
        ;
        this.addImmediateNeedsForm = this.formBuilder.group({  
          
          // Immediate Needs
          homePurchase: [homePurchase],
          modificationHome: [modificationHome],
          medicalTreatment: [medicalTreatment],
          funeralLegalExpenses: [funeralLegalExpenses],
          upfrontCapitalNeeds: [upfrontCapitalNeeds],
          homePurchasePartner: [homePurchasePartner],
          modificationHomePartner: [modificationHomePartner],
          medicalTreatmentPartner: [medicalTreatmentPartner],
          funeralLegalExpensesPartner: [funeralLegalExpensesPartner],
          upfrontCapitalNeedsPartner: [upfrontCapitalNeedsPartner],

        });
          
        

        this.isLoaded = true;
    //   } 
    //   // this.ngxService.stop();` 
    // },
    // (err: HttpErrorResponse)=>{
    //   //console.log("Error"+ err.message);
    // });
  }


  immediateNeedsSave(immediateNeeds, event: any){
    let value = event.target.value;
    let inputId = event.target.id;
    
    if(!immediateNeeds) {
      this.toastr.error('Error on updating immediate needs.');   
    }
    else {
      this.immediateNeedsItemsAdd = new ImmediateNeedsItems;
      this.immediateNeedsItemsAdd.clientCode = immediateNeeds.clientCode;
      if(inputId == 'homePurchase' || inputId == 'homePurchasePartner') {
        this.immediateNeedsItemsAdd.homePurchase = value;
        this.immediateNeedsItemsAdd.modificationHome = immediateNeeds.modificationHome;
        this.immediateNeedsItemsAdd.medicalTreatment = immediateNeeds.medicalTreatment;
        this.immediateNeedsItemsAdd.funeralLegalExpenses = immediateNeeds.funeralLegalExpenses;
        this.immediateNeedsItemsAdd.upfrontCapitalNeeds = immediateNeeds.upfrontCapitalNeeds;
      }
      if(inputId == 'modificationHome' || inputId == 'modificationHomePartner') {
        this.immediateNeedsItemsAdd.homePurchase = immediateNeeds.homePurchase;
        this.immediateNeedsItemsAdd.modificationHome = value;
        this.immediateNeedsItemsAdd.medicalTreatment = immediateNeeds.medicalTreatment;
        this.immediateNeedsItemsAdd.funeralLegalExpenses = immediateNeeds.funeralLegalExpenses;
        this.immediateNeedsItemsAdd.upfrontCapitalNeeds = immediateNeeds.upfrontCapitalNeeds;
      } 
      if(inputId == 'medicalTreatment' || inputId == 'medicalTreatmentPartner') {
        this.immediateNeedsItemsAdd.homePurchase = immediateNeeds.homePurchase;
        this.immediateNeedsItemsAdd.modificationHome = immediateNeeds.modificationHome;
        this.immediateNeedsItemsAdd.medicalTreatment = value;
        this.immediateNeedsItemsAdd.funeralLegalExpenses = immediateNeeds.funeralLegalExpenses;
        this.immediateNeedsItemsAdd.upfrontCapitalNeeds = immediateNeeds.upfrontCapitalNeeds;
      }
      if(inputId == 'funeralLegalExpenses' || inputId == 'funeralLegalExpensesPartner') {
        this.immediateNeedsItemsAdd.homePurchase = immediateNeeds.homePurchase;
        this.immediateNeedsItemsAdd.modificationHome = immediateNeeds.modificationHome;
        this.immediateNeedsItemsAdd.medicalTreatment = immediateNeeds.medicalTreatment;
        this.immediateNeedsItemsAdd.funeralLegalExpenses = value;
        this.immediateNeedsItemsAdd.upfrontCapitalNeeds = immediateNeeds.upfrontCapitalNeeds;
      }
      if(inputId == 'upfrontCapitalNeeds' || inputId == 'upfrontCapitalNeedsPartner') {
        this.immediateNeedsItemsAdd.homePurchase = immediateNeeds.homePurchase;
        this.immediateNeedsItemsAdd.modificationHome = immediateNeeds.modificationHome;
        this.immediateNeedsItemsAdd.medicalTreatment = immediateNeeds.medicalTreatment;
        this.immediateNeedsItemsAdd.funeralLegalExpenses = immediateNeeds.funeralLegalExpenses;
        this.immediateNeedsItemsAdd.upfrontCapitalNeeds = value;
      }

      this.familyTreeService.editImmediateNeed(immediateNeeds.id, this.immediateNeedsItemsAdd).subscribe((data:ImmediateNeedsResponse)=>{
        if(data.error && !data.success){      
          this.toastr.error('Error on updating immediate needs.');      
        }
        else{
          this.toastr.success('Immediate needs updated successfully.');
        }

        // this.getProvisionInfo();
      },err=>{   
        console.log(err);
        this.toastr.error('Error on updating immediate needs.');
      });
    }
  }

}
