<div class="boxcolor mt-2 z12">
        
  <table class="table tablecollect">
    
    <ng-container *ngIf="isLoaded2">
      <ng-container *ngIf="budgetInfo.budgets.length>0">
        <ng-container *ngIf="groupBudgetInfo.e1">
          <thead>
            <tr>
              <th class="tc2 font-weight-normal pt-0 pb-0 tab_fs">Taxes Expenses</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs pdAlignTableHead">Expense</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Frequency</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Annually</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let expense of groupBudgetInfo.e1; let i = index">
              <tr>
                <td class="tablefist tableb"><input readonly type="text"  #grocery class="form-control neweditinput" placeholder="{{expense.expenseSubTypeName}}"></td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="expense.amount">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder=" ${{expense.amount | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="expense.amount == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="expense.frequency">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="{{expense.expenseFrequencyName}}">
                  </ng-container>
                  <ng-container *ngIf="expense.frequency == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="-">
                  </ng-container>
                </td>
                <td class="tableleftb  td_leftnew">
                  <ng-container *ngIf="expense.annualAmount">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="${{expense.annualAmount | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="expense.annualAmount == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
              </tr>
            </ng-container>
            <tr> <td colspan="4" class="empty-tr"></td> </tr>
          </tbody>
        </ng-container>

        <ng-container *ngIf="groupBudgetInfo.e2">
          <thead>
            <tr>
              <th class="tc2 font-weight-normal pt-0 pb-0 tab_fs">Debt-Long Term Expenses</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs pdAlignTableHead">Expense</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Frequency</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Annually</th>
            </tr>
          </thead>
          
          <tbody>
            <ng-container *ngFor="let expense of groupBudgetInfo.e2; let i = index">
              <tr>
                <td class="tablefist tableb"><input readonly type="text"  #grocery class="form-control neweditinput" placeholder="{{expense.expenseSubTypeName}}"></td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="expense.amount">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder=" ${{expense.amount | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="expense.amount == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="expense.frequency">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="{{expense.expenseFrequencyName}}">
                  </ng-container>
                  <ng-container *ngIf="expense.frequency == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="-">
                  </ng-container>
                </td>
                <td class="tableleftb  td_leftnew">
                  <ng-container *ngIf="expense.annualAmount">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="${{expense.annualAmount | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="expense.annualAmount == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
              </tr>
            </ng-container>
            <tr> <td colspan="4" class="empty-tr"></td> </tr>
          </tbody>
        </ng-container>

        <ng-container *ngIf="groupBudgetInfo.e3">
          <thead>
            <tr>
              <th class="tc2 font-weight-normal pt-0 pb-0 tab_fs">Debt-Short Term Expenses</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs pdAlignTableHead">Expense</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Frequency</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Annually</th>
            </tr>
          </thead>
          
          <tbody>
            <ng-container *ngFor="let expense of groupBudgetInfo.e3; let i = index">
              <tr>
                <td class="tablefist tableb"><input readonly type="text"  #grocery class="form-control neweditinput" placeholder="{{expense.expenseSubTypeName}}"></td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="expense.amount">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder=" ${{expense.amount | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="expense.amount == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="expense.frequency">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="{{expense.expenseFrequencyName}}">
                  </ng-container>
                  <ng-container *ngIf="expense.frequency == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="-">
                  </ng-container>
                </td>
                <td class="tableleftb  td_leftnew">
                  <ng-container *ngIf="expense.annualAmount">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="${{expense.annualAmount | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="expense.annualAmount == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
              </tr>
            </ng-container>
            <tr> <td colspan="4" class="empty-tr"></td> </tr>
          </tbody>
        </ng-container>

        <ng-container *ngIf="groupBudgetInfo.e4">
          <thead>
            <tr>
              <th class="tc2 font-weight-normal pt-0 pb-0 tab_fs">Contributions Expenses</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs pdAlignTableHead">Expense</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Frequency</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Annually</th>
            </tr>
          </thead>
          
          <tbody>
            <ng-container *ngFor="let expense of groupBudgetInfo.e4; let i = index">
              <tr>
                <td class="tablefist tableb"><input readonly type="text"  #grocery class="form-control neweditinput" placeholder="{{expense.expenseSubTypeName}}"></td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="expense.amount">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder=" ${{expense.amount | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="expense.amount == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="expense.frequency">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="{{expense.expenseFrequencyName}}">
                  </ng-container>
                  <ng-container *ngIf="expense.frequency == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="-">
                  </ng-container>
                </td>
                <td class="tableleftb  td_leftnew">
                  <ng-container *ngIf="expense.annualAmount">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="${{expense.annualAmount | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="expense.annualAmount == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
              </tr>
            </ng-container>
            <tr> <td colspan="4" class="empty-tr"></td> </tr>
          </tbody>
        </ng-container>

        <ng-container *ngIf="groupBudgetInfo.e5">
          <thead>
            <tr>
              <th class="tc2 font-weight-normal pt-0 pb-0 tab_fs">Housing Expenses</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs pdAlignTableHead">Expense</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Frequency</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Annually</th>
            </tr>
          </thead>
          
          <tbody>
            <ng-container *ngFor="let expense of groupBudgetInfo.e5; let i = index">
              <tr>
                <td class="tablefist tableb"><input readonly type="text"  #grocery class="form-control neweditinput" placeholder="{{expense.expenseSubTypeName}}"></td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="expense.amount">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder=" ${{expense.amount | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="expense.amount == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="expense.frequency">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="{{expense.expenseFrequencyName}}">
                  </ng-container>
                  <ng-container *ngIf="expense.frequency == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="-">
                  </ng-container>
                </td>
                <td class="tableleftb  td_leftnew">
                  <ng-container *ngIf="expense.annualAmount">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="${{expense.annualAmount | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="expense.annualAmount == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
              </tr>
            </ng-container>
            <tr> <td colspan="4" class="empty-tr"></td> </tr>
          </tbody>
        </ng-container>

        <ng-container *ngIf="groupBudgetInfo.e6">
          <thead>
            <tr>
              <th class="tc2 font-weight-normal pt-0 pb-0 tab_fs">Utilities Expenses</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs pdAlignTableHead">Expense</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Frequency</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Annually</th>
            </tr>
          </thead>
          
          <tbody>
            <ng-container *ngFor="let expense of groupBudgetInfo.e6; let i = index">
              <tr>
                <td class="tablefist tableb"><input readonly type="text"  #grocery class="form-control neweditinput" placeholder="{{expense.expenseSubTypeName}}"></td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="expense.amount">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder=" ${{expense.amount | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="expense.amount == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="expense.frequency">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="{{expense.expenseFrequencyName}}">
                  </ng-container>
                  <ng-container *ngIf="expense.frequency == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="-">
                  </ng-container>
                </td>
                <td class="tableleftb  td_leftnew">
                  <ng-container *ngIf="expense.annualAmount">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="${{expense.annualAmount | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="expense.annualAmount == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
              </tr>
            </ng-container>
            <tr> <td colspan="4" class="empty-tr"></td> </tr>
          </tbody>
        </ng-container>

        <ng-container *ngIf="groupBudgetInfo.e7">
          <thead>
            <tr>
              <th class="tc2 font-weight-normal pt-0 pb-0 tab_fs">Car Expenses</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs pdAlignTableHead">Expense</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Frequency</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Annually</th>
            </tr>
          </thead>
          
          <tbody>
            <ng-container *ngFor="let expense of groupBudgetInfo.e7; let i = index">
              <tr>
                <td class="tablefist tableb"><input readonly type="text"  #grocery class="form-control neweditinput" placeholder="{{expense.expenseSubTypeName}}"></td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="expense.amount">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder=" ${{expense.amount | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="expense.amount == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="expense.frequency">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="{{expense.expenseFrequencyName}}">
                  </ng-container>
                  <ng-container *ngIf="expense.frequency == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="-">
                  </ng-container>
                </td>
                <td class="tableleftb  td_leftnew">
                  <ng-container *ngIf="expense.annualAmount">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="${{expense.annualAmount | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="expense.annualAmount == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
              </tr>
            </ng-container>
            <tr> <td colspan="4" class="empty-tr"></td> </tr>
          </tbody>
        </ng-container>

        <ng-container *ngIf="groupBudgetInfo.e8">
          <thead>
            <tr>
              <th class="tc2 font-weight-normal pt-0 pb-0 tab_fs">Food Expenses</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs pdAlignTableHead">Expense</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Frequency</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Annually</th>
            </tr>
          </thead>
          
          <tbody>
            <ng-container *ngFor="let expense of groupBudgetInfo.e8; let i = index">
              <tr>
                <td class="tablefist tableb"><input readonly type="text"  #grocery class="form-control neweditinput" placeholder="{{expense.expenseSubTypeName}}"></td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="expense.amount">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder=" ${{expense.amount | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="expense.amount == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="expense.frequency">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="{{expense.expenseFrequencyName}}">
                  </ng-container>
                  <ng-container *ngIf="expense.frequency == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="-">
                  </ng-container>
                </td>
                <td class="tableleftb  td_leftnew">
                  <ng-container *ngIf="expense.annualAmount">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="${{expense.annualAmount | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="expense.annualAmount == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
              </tr>
            </ng-container>
            <tr> <td colspan="4" class="empty-tr"></td> </tr>
          </tbody>
        </ng-container>

        <ng-container *ngIf="groupBudgetInfo.e9">
          <thead>
            <tr>
              <th class="tc2 font-weight-normal pt-0 pb-0 tab_fs">Insurance Expenses</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs pdAlignTableHead">Expense</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Frequency</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Annually</th>
            </tr>
          </thead>
          
          <tbody>
            <ng-container *ngFor="let expense of groupBudgetInfo.e9; let i = index">
              <tr>
                <td class="tablefist tableb"><input readonly type="text"  #grocery class="form-control neweditinput" placeholder="{{expense.expenseSubTypeName}}"></td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="expense.amount">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder=" ${{expense.amount | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="expense.amount == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="expense.frequency">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="{{expense.expenseFrequencyName}}">
                  </ng-container>
                  <ng-container *ngIf="expense.frequency == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="-">
                  </ng-container>
                </td>
                <td class="tableleftb  td_leftnew">
                  <ng-container *ngIf="expense.annualAmount">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="${{expense.annualAmount | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="expense.annualAmount == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
              </tr>
            </ng-container>
            <tr> <td colspan="4" class="empty-tr"></td> </tr>
          </tbody>
        </ng-container>

        <ng-container *ngIf="groupBudgetInfo.ea">
          <thead>
            <tr>
              <th class="tc2 font-weight-normal pt-0 pb-0 tab_fs">Health Care Expenses</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs pdAlignTableHead">Expense</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Frequency</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Annually</th>
            </tr>
          </thead>
          
          <tbody>
            <ng-container *ngFor="let expense of groupBudgetInfo.ea; let i = index">
              <tr>
                <td class="tablefist tableb"><input readonly type="text"  #grocery class="form-control neweditinput" placeholder="{{expense.expenseSubTypeName}}"></td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="expense.amount">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder=" ${{expense.amount | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="expense.amount == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="expense.frequency">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="{{expense.expenseFrequencyName}}">
                  </ng-container>
                  <ng-container *ngIf="expense.frequency == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="-">
                  </ng-container>
                </td>
                <td class="tableleftb  td_leftnew">
                  <ng-container *ngIf="expense.annualAmount">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="${{expense.annualAmount | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="expense.annualAmount == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
              </tr>
            </ng-container>
            <tr> <td colspan="4" class="empty-tr"></td> </tr>
          </tbody>
        </ng-container>

        <ng-container *ngIf="groupBudgetInfo.eb">
          <thead>
            <tr>
              <th class="tc2 font-weight-normal pt-0 pb-0 tab_fs">Personal Care Expenses</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs pdAlignTableHead">Expense</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Frequency</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Annually</th>
            </tr>
          </thead>
          
          <tbody>
            <ng-container *ngFor="let expense of groupBudgetInfo.eb; let i = index">
              <tr>
                <td class="tablefist tableb"><input readonly type="text"  #grocery class="form-control neweditinput" placeholder="{{expense.expenseSubTypeName}}"></td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="expense.amount">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder=" ${{expense.amount | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="expense.amount == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="expense.frequency">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="{{expense.expenseFrequencyName}}">
                  </ng-container>
                  <ng-container *ngIf="expense.frequency == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="-">
                  </ng-container>
                </td>
                <td class="tableleftb  td_leftnew">
                  <ng-container *ngIf="expense.annualAmount">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="${{expense.annualAmount | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="expense.annualAmount == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
              </tr>
            </ng-container>
            <tr> <td colspan="4" class="empty-tr"></td> </tr>
          </tbody>
        </ng-container>

        <ng-container *ngIf="groupBudgetInfo.ec">
          <thead>
            <tr>
              <th class="tc2 font-weight-normal pt-0 pb-0 tab_fs">Entertainment Expenses</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs pdAlignTableHead">Expense</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Frequency</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Annually</th>
            </tr>
          </thead>
          
          <tbody>
            <ng-container *ngFor="let expense of groupBudgetInfo.ec; let i = index">
              <tr>
                <td class="tablefist tableb"><input readonly type="text"  #grocery class="form-control neweditinput" placeholder="{{expense.expenseSubTypeName}}"></td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="expense.amount">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder=" ${{expense.amount | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="expense.amount == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="expense.frequency">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="{{expense.expenseFrequencyName}}">
                  </ng-container>
                  <ng-container *ngIf="expense.frequency == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="-">
                  </ng-container>
                </td>
                <td class="tableleftb  td_leftnew">
                  <ng-container *ngIf="expense.annualAmount">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="${{expense.annualAmount | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="expense.annualAmount == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
              </tr>
            </ng-container>
            <tr> <td colspan="4" class="empty-tr"></td> </tr>
          </tbody>
        </ng-container>

        <ng-container *ngIf="groupBudgetInfo.ed">
          <thead>
            <tr>
              <th class="tc2 font-weight-normal pt-0 pb-0 tab_fs">Other Expenditure Expenses</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs pdAlignTableHead">Expense</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Frequency</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Annually</th>
            </tr>
          </thead>
          
          <tbody>
            <ng-container *ngFor="let expense of groupBudgetInfo.ed; let i = index">
              <tr>
                <td class="tablefist tableb"><input readonly type="text"  #grocery class="form-control neweditinput" placeholder="{{expense.expenseSubTypeName}}"></td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="expense.amount">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder=" ${{expense.amount | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="expense.amount == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="expense.frequency">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="{{expense.expenseFrequencyName}}">
                  </ng-container>
                  <ng-container *ngIf="expense.frequency == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="-">
                  </ng-container>
                </td>
                <td class="tableleftb  td_leftnew">
                  <ng-container *ngIf="expense.annualAmount">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="${{expense.annualAmount | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="expense.annualAmount == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
              </tr>
            </ng-container>
            <tr> <td colspan="4" class="empty-tr"></td> </tr>
          </tbody>
        </ng-container>

        <ng-container *ngIf="groupBudgetInfo.ee">
          <thead>
            <tr>
              <th class="tc2 font-weight-normal pt-0 pb-0 tab_fs">Living Expenses</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs pdAlignTableHead">Expense</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Frequency</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Annually</th>
            </tr>
          </thead>
          
          <tbody>
            <ng-container *ngFor="let expense of groupBudgetInfo.ee; let i = index">
              <tr>
                <td class="tablefist tableb"><input readonly type="text"  #grocery class="form-control neweditinput" placeholder="{{expense.expenseSubTypeName}}"></td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="expense.amount">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder=" ${{expense.amount | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="expense.amount == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="expense.frequency">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="{{expense.expenseFrequencyName}}">
                  </ng-container>
                  <ng-container *ngIf="expense.frequency == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="-">
                  </ng-container>
                </td>
                <td class="tableleftb  td_leftnew">
                  <ng-container *ngIf="expense.annualAmount">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="${{expense.annualAmount | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="expense.annualAmount == ''">
                    <input readonly type="text"  #grocery class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
              </tr>
            </ng-container>
            <tr> <td colspan="4" class="empty-tr"></td> </tr>
          </tbody>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="budgetInfo.budgets.length == 0">No data</ng-container>
    </ng-container>
    
  </table>

</div>