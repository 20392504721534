import { Component, OnInit, Input } from '@angular/core';
import { FamilyTreeService } from '../../family-tree.service';
import { BudgetResponse, XplanChoicesResponse } from '../../../shared/models/response.model';
import { BudgetModel } from 'src/app/shared/models/budget.model';
import { XplanChoices } from 'src/app/shared/models/xplan-choices.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-budget',
  templateUrl: './budget.component.html',
  styleUrls: ['../rightside.component.css']
})
export class BudgetComponent implements OnInit {

  constructor(
    private familyTreeService :FamilyTreeService,
    private toastr: ToastrService,
  ) { }

  budgetInfo:BudgetModel;
  choicesList : XplanChoices[];
  groupBudgetInfo: any;
  isLoaded2: boolean;
  @Input() client_id: string;

  expense_cashflow_list: any;
  expense_cashflow_subtype_list: any;
  income_expense_cashflow_frequency_list:any;

  ngOnInit(): void {
    this.getChoiceList();
    
  }

  getBudgetInfo(){
    // this.ngxService.start();
    this.familyTreeService.getBudget(this.client_id).subscribe((budgetData:BudgetResponse)=>{
      if(budgetData.success){
        this.budgetInfo = budgetData.response;

        //var cars = [{ make: 'audi', model: 'r8', year: '2012' }, { make: 'audi', model: 'rs5', year: '2013' }, { make: 'ford', model: 'mustang', year: '2012' }, { make: 'ford', model: 'fusion', year: '2015' }, { make: 'kia', model: 'optima', year: '2012' }],
        // console.log('expenseTypeName - typeGroup',this.expense_cashflow_list);
        // console.log('expenseSubTypeName - type',this.expense_cashflow_subtype_list);

        this.budgetInfo.budgets.forEach(element => {            
          let choice = this.expense_cashflow_list.filter(choice => choice.key === element.typeGroup);
          if(choice && choice[0]) {
            element.expenseTypeName = choice[0].value;
          }
        });

        this.budgetInfo.budgets.forEach(element => {            
          let choice = this.expense_cashflow_subtype_list.filter(choice => choice.key === element.type);
          if(choice && choice[0]) {
            element.expenseSubTypeName = choice[0].value;
          }
        });

        this.budgetInfo.budgets.forEach(element => {            
          let choice = this.income_expense_cashflow_frequency_list.filter(choice => choice.key === element.frequency);
          if(choice && choice[0]) {
            element.expenseFrequencyName = choice[0].value;
          }
        });
        
        this.groupBudgetInfo = this.groupBy(this.budgetInfo.budgets, (c) => c.typeGroup);
        // console.log('groupBudgetInfo - typeGroup',this.groupBudgetInfo);
        // console.log('budgetInfo',this.budgetInfo);

        this.isLoaded2 = true;
      } 
      // this.ngxService.stop(); 
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });
  }

  getChoiceList(){
    this.familyTreeService.getChoiceList().subscribe((clientData:XplanChoicesResponse)=>{
      if(clientData.success){
        this.choicesList = clientData.response;  
        
        this.income_expense_cashflow_frequency_list = this.sortByKey(this.choicesList.filter(choice => choice.choiceType === 14));
        this.expense_cashflow_list = this.sortByKey(this.choicesList.filter(choice => choice.choiceType === 11));
        this.expense_cashflow_subtype_list = this.sortByKey(this.choicesList.filter(choice => choice.choiceType === 5));
        
        this.getBudgetInfo();
      } 
      
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message); 
      });
  }

  sortByKey(choiceArray){
    return choiceArray.sort((n1,n2) => {
      if (n1.key > n2.key) {
          return 1;
      }
  
      if (n1.key < n2.key) {
          return -1;
      }                                      
      return 0;
    });
  }

  groupBy(xs, f) {
    return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
  }


  clickExpense(frequency_id, annually_id, event: any) {
    let expense_val = event.target.value;
    let frequency_val = ((document.getElementById(frequency_id) as HTMLInputElement).value);
    
    // let inputId = event.target.id;
    // console.log(inputId);
    // console.log(frequency_id);
    // console.log(annually_id);
    this.calculateBudget(expense_val, frequency_val, annually_id);
  }

  clickFrequency(expense_id, annually_id, event: any) {
    let expense_val = ((document.getElementById(expense_id) as HTMLInputElement).value);
    let frequency_val = event.target.value;

    this.calculateBudget(expense_val, frequency_val, annually_id);
  }

  calculateBudget(expense_val, frequency_val, annually_id){
    let net_val = 0;
    
    expense_val = expense_val.replace(/,/g, '');
    net_val = expense_val * frequency_val;
    
    ((document.getElementById(annually_id) as HTMLInputElement).value=net_val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
    
  }


  noteSave(treeListFromServer2, event: any){
    // console.log(retirement);
    let value = event.target.value;
    let inputId = event.target.id;
    let errorVal = true;
    // console.log(treeListFromServer2);
    // console.log(value);

    if(treeListFromServer2==null){
      this.toastr.error('Error on updating note.');  
    }
    else {
      let familyTreeId  = treeListFromServer2.familyTreeId;

      // if(inputId == 'retirementAge' || inputId == 'retirementAgePartner') {
        // this.retirementAdd.retirementAge = value;
      // }
       
      if(errorVal){
        this.familyTreeService.addBudgetNote(familyTreeId , value).subscribe((data:BudgetResponse)=>{
          if(data.error && !data.success){      
            this.toastr.error('Error on updating note.');      
          }
          else{
            this.toastr.success('Note updated successfully.');
          }
    
          // this.getRetirementInfo();
        },err=>{   
          console.log(err);
          this.toastr.error('Error on updating note.');
        });
      }
    }
  }

}
