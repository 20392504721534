import { Component, OnInit } from '@angular/core';
import { FamilyTreeService } from '../../family-tree.service';
import { SharedDataService } from 'src/app/shared/service/shared-data.service';
import { ProvisionResponse } from 'src/app/shared/models/response.model';
import { ProvisionModel, OtherProvisionList } from 'src/app/shared/models/provision.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { stringify } from 'querystring';


@Component({
  selector: 'app-provisions',
  templateUrl: './provisions.component.html',
  styleUrls: ['../rightside.component.css']
})
export class ProvisionsComponent implements OnInit {

  family_tree_id: string = "";
  
  otherProvisionList: any=[];
  incomeEarnUponReturn_1: any = 0;
  incomeEarnUponReturnPartner_1: any = 0;
  isLoaded5: boolean;
  partnerHas:boolean = false;
  client_code: string;
  partnerClientCode:string;
  clientFirstname:string;
  partnerFirstname:string;

  addProvisionForm: FormGroup;
  provisionInfo:ProvisionModel;

  funeralLegalExpensesClient: number = 0;
  funeralLegalExpensesPartner: number = 0;
  housePurchaseClient: number = 0;
  housePurchasePartner: number = 0;
  totalNetImmediateNeedClient: number = 0;
  totalNetImmediateNeedPartner: number = 0;
  homeModificationClient: number = 0;
  homeModificationPartner: number = 0;
  medicalTreatmentClient: number = 0;
  medicalTreatmentPartner: number = 0;
  immediateNeedsTotalClient: number = 0;
  immediateNeedsTotalPartner: number = 0;
  immediateNonSuperIncomingAssetsTotalClient: number = 0;
  immediateNonSuperIncomingAssetsTotalPartner: number = 0;
  investmentPropertiesClient: number = 0;
  investmentPropertiesPartner: number = 0;
  deceasedPersonsSuperClient: number = 0;
  deceasedPersonsSuperPartner: number = 0;
  valueOfAllInvestmentsIfSoldClient: number = 0;
  valueOfAllInvestmentsIfSoldPartner: number = 0;
  upfrontCapitalNeedClient: number = 0;
  upfrontCapitalNeedPartner: number = 0;
  yearsOffWorkClient: number = 0;
  yearsOffWorkPartner: number = 0;
  coverForYearsOffWorkClient: number = 0;
  coverForYearsOffWorkPartner: number = 0;
  totalCoverRequiredClient: number = 0;
  totalCoverRequiredPartner: number = 0;
  less2YearsPassiveIncomeClient: number = 0;
  less2YearsPassiveIncomePartner: number = 0;
  cashedOutOfSuperImmediatelyClient: number = 0;
  cashedOutOfSuperImmediatelyPartner: number = 0;
  grossUpForTaxClient: number = 0;
  grossUpForTaxPartner: number = 0;
  totalNetImmediateNeedTPDClient: number = 0;
  totalNetImmediateNeedTPDPartner: number = 0;
  totalDebt: number = 0;
  totalValueOfInvestmentsIfSold: number = 0;
  financesInfo: any;
  interval;
  income;
  incomePartner
  provisionInfoDependent:any=[];

  constructor(
    private familyTreeService :FamilyTreeService,
    private sharedDataService: SharedDataService,
    private toastr: ToastrService,
  ) { 
    this.sharedDataService.familyTreeId.subscribe(family_tree_id => this.family_tree_id = family_tree_id);
    this.sharedDataService.partnerHas.subscribe(partnerHas => this.partnerHas = partnerHas);
    this.sharedDataService.client_code.subscribe(client_code => this.client_code = client_code);
    this.sharedDataService.partnerClientCode.subscribe(partnerClientCode => this.partnerClientCode = partnerClientCode);
    this.sharedDataService.clientFirstname.subscribe(clientFirstname => this.clientFirstname = clientFirstname);
    this.sharedDataService.partnerFirstname.subscribe(partnerFirstname => this.partnerFirstname = partnerFirstname);
    this.sharedDataService.totalDebt.subscribe(totalDebt => this.totalDebt = totalDebt);
    this.sharedDataService.financesInfo.subscribe(financesInfo => this.financesInfo = financesInfo);
    this.sharedDataService.totalValueOfInvestmentsIfSold.subscribe(totalValueOfInvestmentsIfSold => this.totalValueOfInvestmentsIfSold = totalValueOfInvestmentsIfSold);
    this.sharedDataService.investmentPropertiesClient.subscribe(investmentPropertiesClient => this.investmentPropertiesClient = investmentPropertiesClient);
    this.sharedDataService.income.subscribe(income => this.income = income);
    this.sharedDataService.incomePartner.subscribe(incomePartner => this.incomePartner = incomePartner);
  }

  ngOnInit(): void {
    this.sharedDataService.provisionInfo.subscribe(provisionInfo => this.provisionInfo = provisionInfo);

    this.sharedDataService.funeralLegalExpensesClient.subscribe(funeralLegalExpensesClient => this.funeralLegalExpensesClient = funeralLegalExpensesClient);
    this.sharedDataService.funeralLegalExpensesPartner.subscribe(funeralLegalExpensesPartner => this.funeralLegalExpensesPartner = funeralLegalExpensesPartner);
    this.sharedDataService.housePurchaseClient.subscribe(housePurchaseClient => this.housePurchaseClient = housePurchaseClient);
    this.sharedDataService.housePurchasePartner.subscribe(housePurchasePartner => this.housePurchasePartner = housePurchasePartner);
    this.sharedDataService.totalNetImmediateNeedClient.subscribe(totalNetImmediateNeedClient => this.totalNetImmediateNeedClient = totalNetImmediateNeedClient);
    this.sharedDataService.totalNetImmediateNeedPartner.subscribe(totalNetImmediateNeedPartner => this.totalNetImmediateNeedPartner = totalNetImmediateNeedPartner);

    this.sharedDataService.homeModificationClient.subscribe(homeModificationClient => this.homeModificationClient = homeModificationClient);
    this.sharedDataService.homeModificationPartner.subscribe(homeModificationPartner => this.homeModificationPartner = homeModificationPartner);
    this.sharedDataService.medicalTreatmentClient.subscribe(medicalTreatmentClient => this.medicalTreatmentClient = medicalTreatmentClient);
    this.sharedDataService.medicalTreatmentPartner.subscribe(medicalTreatmentPartner => this.medicalTreatmentPartner = medicalTreatmentPartner);
    this.sharedDataService.immediateNeedsTotalClient.subscribe(immediateNeedsTotalClient => this.immediateNeedsTotalClient = immediateNeedsTotalClient);
    this.sharedDataService.immediateNeedsTotalPartner.subscribe(immediateNeedsTotalPartner => this.immediateNeedsTotalPartner = immediateNeedsTotalPartner);
    this.sharedDataService.immediateNonSuperIncomingAssetsTotalClient.subscribe(immediateNonSuperIncomingAssetsTotalClient => this.immediateNonSuperIncomingAssetsTotalClient = immediateNonSuperIncomingAssetsTotalClient);
    this.sharedDataService.immediateNonSuperIncomingAssetsTotalPartner.subscribe(immediateNonSuperIncomingAssetsTotalPartner => this.immediateNonSuperIncomingAssetsTotalPartner = immediateNonSuperIncomingAssetsTotalPartner);
    this.sharedDataService.cashedOutOfSuperImmediatelyClient.subscribe(cashedOutOfSuperImmediatelyClient => this.cashedOutOfSuperImmediatelyClient = cashedOutOfSuperImmediatelyClient);
    this.sharedDataService.cashedOutOfSuperImmediatelyPartner.subscribe(cashedOutOfSuperImmediatelyPartner => this.cashedOutOfSuperImmediatelyPartner = cashedOutOfSuperImmediatelyPartner);
    this.sharedDataService.grossUpForTaxClient.subscribe(grossUpForTaxClient => this.grossUpForTaxClient = grossUpForTaxClient);
    this.sharedDataService.grossUpForTaxPartner.subscribe(grossUpForTaxPartner => this.grossUpForTaxPartner = grossUpForTaxPartner);
    this.sharedDataService.totalNetImmediateNeedTPDClient.subscribe(totalNetImmediateNeedTPDClient => this.totalNetImmediateNeedTPDClient = totalNetImmediateNeedTPDClient);
    this.sharedDataService.totalNetImmediateNeedTPDPartner.subscribe(totalNetImmediateNeedTPDPartner => this.totalNetImmediateNeedTPDPartner = totalNetImmediateNeedTPDPartner);
    this.sharedDataService.investmentPropertiesPartner.subscribe(investmentPropertiesPartner => this.investmentPropertiesPartner = investmentPropertiesPartner);

    this.sharedDataService.upfrontCapitalNeedClient.subscribe(upfrontCapitalNeedClient => this.upfrontCapitalNeedClient = upfrontCapitalNeedClient);
    this.sharedDataService.upfrontCapitalNeedPartner.subscribe(upfrontCapitalNeedPartner => this.upfrontCapitalNeedPartner = upfrontCapitalNeedPartner);
    this.sharedDataService.yearsOffWorkClient.subscribe(yearsOffWorkClient => this.yearsOffWorkClient = yearsOffWorkClient);
    this.sharedDataService.yearsOffWorkPartner.subscribe(yearsOffWorkPartner => this.yearsOffWorkPartner = yearsOffWorkPartner);
    this.sharedDataService.coverForYearsOffWorkClient.subscribe(coverForYearsOffWorkClient => this.coverForYearsOffWorkClient = coverForYearsOffWorkClient);
    this.sharedDataService.coverForYearsOffWorkPartner.subscribe(coverForYearsOffWorkPartner => this.coverForYearsOffWorkPartner = coverForYearsOffWorkPartner);
    this.sharedDataService.totalCoverRequiredClient.subscribe(totalCoverRequiredClient => this.totalCoverRequiredClient = totalCoverRequiredClient);
    this.sharedDataService.totalCoverRequiredPartner.subscribe(totalCoverRequiredPartner => this.totalCoverRequiredPartner = totalCoverRequiredPartner);
    this.sharedDataService.less2YearsPassiveIncomeClient.subscribe(less2YearsPassiveIncomeClient => this.less2YearsPassiveIncomeClient = less2YearsPassiveIncomeClient);
    this.sharedDataService.less2YearsPassiveIncomePartner.subscribe(less2YearsPassiveIncomePartner => this.less2YearsPassiveIncomePartner = less2YearsPassiveIncomePartner);
    this.sharedDataService.provisionInfoDependent.subscribe(provisionInfoDependent => this.provisionInfoDependent = provisionInfoDependent);
    

    this.getProvisionInfo();

   
    this.interval = setInterval(()=> { 
      
      if(this.totalDebt == 0 ) {
        this.getProvisionInfo();
      }
      this.income = this.income;
      this.incomePartner = this.incomePartner;
      
    }, 1 * 1000);
  }

  ngOnChanges () : void {
    this.totalCoverRequiredClient = this.totalCoverRequiredClient;
    this.upfrontCapitalNeedClient = this.upfrontCapitalNeedClient;
    this.upfrontCapitalNeedPartner = this.upfrontCapitalNeedPartner
    this.income = this.income;
    this.incomePartner = this.incomePartner;
    this.less2YearsPassiveIncomeClient = this.less2YearsPassiveIncomeClient;
    this.less2YearsPassiveIncomePartner = this.less2YearsPassiveIncomePartner;
    this.getProvisionInfo();
  }

  getProvisionInfo(){
    //this.ngxService.start();
    this.familyTreeService.getProvision(this.family_tree_id).subscribe((provisionData:ProvisionResponse)=>{
      if(provisionData.success){
        this.provisionInfo = provisionData.response;
        
        
        this.otherProvisionList = new OtherProvisionList;

        // let  age = this.provisionInfo.dependentSupports[0].age,
        //   yearAmount = this.provisionInfo.dependentSupports[0].yearAmount;
        this.incomeEarnUponReturn_1 = this.provisionInfo.spouseDeathOrTPDs.clientDeathOrTPDItems !=null ? this.provisionInfo.spouseDeathOrTPDs.clientDeathOrTPDItems.incomeEarnUponReturn : 0;
        this.incomeEarnUponReturnPartner_1 = this.provisionInfo.spouseDeathOrTPDs.partnerDeathOrTPDItems !=null ? this.provisionInfo.spouseDeathOrTPDs.partnerDeathOrTPDItems.incomeEarnUponReturn : 0;

        let returnToWork = this.provisionInfo.spouseDeathOrTPDs.clientDeathOrTPDItems !=null ? this.provisionInfo.spouseDeathOrTPDs.clientDeathOrTPDItems.returnToWork :false,
        yearsNeedOf = this.provisionInfo.spouseDeathOrTPDs.clientDeathOrTPDItems !=null ? this.provisionInfo.spouseDeathOrTPDs.clientDeathOrTPDItems.yearsNeedOf :0,
        incomeEarnUponReturn = this.provisionInfo.spouseDeathOrTPDs.clientDeathOrTPDItems !=null ? this.provisionInfo.spouseDeathOrTPDs.clientDeathOrTPDItems.incomeEarnUponReturn :0,
        returnToWorkPartner = this.provisionInfo.spouseDeathOrTPDs.partnerDeathOrTPDItems !=null ? this.provisionInfo.spouseDeathOrTPDs.partnerDeathOrTPDItems.returnToWork :false,
        yearsNeedOfPartner = this.provisionInfo.spouseDeathOrTPDs.partnerDeathOrTPDItems !=null ? this.provisionInfo.spouseDeathOrTPDs.partnerDeathOrTPDItems.yearsNeedOf :0,
        incomeEarnUponReturnPartner = this.provisionInfo.spouseDeathOrTPDs.partnerDeathOrTPDItems !=null ? this.provisionInfo.spouseDeathOrTPDs.partnerDeathOrTPDItems.incomeEarnUponReturn :0,

        value = this.provisionInfo.benefitPeriods.clientBenefitPeriods !=null ? this.provisionInfo.benefitPeriods.clientBenefitPeriods.value :0,
        valuePartner = this.provisionInfo.benefitPeriods.partnerBenefitPeriods !=null ? this.provisionInfo.benefitPeriods.partnerBenefitPeriods.value :0,

        description = this.provisionInfo.otherProvisions.clientOtherProvisions.description,
        provisionValue = this.provisionInfo.otherProvisions.clientOtherProvisions.value,
        descriptionPartner = this.provisionInfo.otherProvisions.partnerOtherProvisions.description,
        provisionValuePartner = this.provisionInfo.otherProvisions.partnerOtherProvisions.value,

        homePurchase = this.provisionInfo.immediateNeeds.clientImmediateNeeds !=null ? this.provisionInfo.immediateNeeds.clientImmediateNeeds.homePurchase :0,
        modificationHome = this.provisionInfo.immediateNeeds.clientImmediateNeeds !=null ? this.provisionInfo.immediateNeeds.clientImmediateNeeds.modificationHome :0,
        medicalTreatment = this.provisionInfo.immediateNeeds.clientImmediateNeeds !=null ? this.provisionInfo.immediateNeeds.clientImmediateNeeds.medicalTreatment :0,
        funeralLegalExpenses = this.provisionInfo.immediateNeeds.clientImmediateNeeds !=null ? this.provisionInfo.immediateNeeds.clientImmediateNeeds.funeralLegalExpenses :0,
        upfrontCapitalNeeds = this.provisionInfo.immediateNeeds.clientImmediateNeeds !=null ? this.provisionInfo.immediateNeeds.clientImmediateNeeds.upfrontCapitalNeeds :0,

        homePurchasePartner = this.provisionInfo.immediateNeeds.partnerImmediateNeeds !=null ? this.provisionInfo.immediateNeeds.partnerImmediateNeeds.homePurchase :0,
        modificationHomePartner = this.provisionInfo.immediateNeeds.partnerImmediateNeeds !=null ? this.provisionInfo.immediateNeeds.partnerImmediateNeeds.modificationHome :0,
        medicalTreatmentPartner = this.provisionInfo.immediateNeeds.partnerImmediateNeeds !=null ? this.provisionInfo.immediateNeeds.partnerImmediateNeeds.medicalTreatment :0,
        funeralLegalExpensesPartner = this.provisionInfo.immediateNeeds.partnerImmediateNeeds !=null ? this.provisionInfo.immediateNeeds.partnerImmediateNeeds.funeralLegalExpenses :0,
        upfrontCapitalNeedsPartner = this.provisionInfo.immediateNeeds.partnerImmediateNeeds !=null ? this.provisionInfo.immediateNeeds.partnerImmediateNeeds.upfrontCapitalNeeds :0,

        provisionForTotallyDisabled = this.provisionInfo.progressiveNeeds.clientProgressiveNeeds !=null ? this.provisionInfo.progressiveNeeds.clientProgressiveNeeds.provisionForTotallyDisabled :0,
        passiveIncomeForSpouse = this.provisionInfo.progressiveNeeds.clientProgressiveNeeds !=null ? this.provisionInfo.progressiveNeeds.clientProgressiveNeeds.passiveIncomeForSpouse :0,
        passiveIncomeContinuedPaid = this.provisionInfo.progressiveNeeds.clientProgressiveNeeds !=null ? this.provisionInfo.progressiveNeeds.clientProgressiveNeeds.passiveIncomeContinuedPaid :0,
        provisionForOngoingMedicalCosts = this.provisionInfo.progressiveNeeds.clientProgressiveNeeds !=null ? this.provisionInfo.progressiveNeeds.clientProgressiveNeeds.provisionForOngoingMedicalCosts :0,
        provisionForMedicalCosts = this.provisionInfo.progressiveNeeds.clientProgressiveNeeds !=null ? this.provisionInfo.progressiveNeeds.clientProgressiveNeeds.provisionForMedicalCosts :0,
        incomeLeavePerChild = this.provisionInfo.progressiveNeeds.clientProgressiveNeeds !=null ? this.provisionInfo.progressiveNeeds.clientProgressiveNeeds.incomeLeavePerChild :0,

        provisionForTotallyDisabledPartner = this.provisionInfo.progressiveNeeds.partnerProgressiveNeeds !=null ? this.provisionInfo.progressiveNeeds.partnerProgressiveNeeds.provisionForTotallyDisabled :0,
        passiveIncomeForSpousePartner = this.provisionInfo.progressiveNeeds.partnerProgressiveNeeds !=null ? this.provisionInfo.progressiveNeeds.partnerProgressiveNeeds.passiveIncomeForSpouse :0,
        passiveIncomeContinuedPaidPartner = this.provisionInfo.progressiveNeeds.partnerProgressiveNeeds !=null ? this.provisionInfo.progressiveNeeds.partnerProgressiveNeeds.passiveIncomeContinuedPaid :0,
        provisionForOngoingMedicalCostsPartner = this.provisionInfo.progressiveNeeds.partnerProgressiveNeeds !=null ? this.provisionInfo.progressiveNeeds.partnerProgressiveNeeds.provisionForOngoingMedicalCosts :0,
        provisionForMedicalCostsPartner = this.provisionInfo.progressiveNeeds.partnerProgressiveNeeds !=null ? this.provisionInfo.progressiveNeeds.partnerProgressiveNeeds.provisionForMedicalCosts :0,
        incomeLeavePerChildPartner = this.provisionInfo.progressiveNeeds.partnerProgressiveNeeds !=null ? this.provisionInfo.progressiveNeeds.partnerProgressiveNeeds.incomeLeavePerChild :0
        ;
        
          
        
        this.funeralLegalExpensesClient = this.provisionInfo.immediateNeeds.clientImmediateNeeds !=null ? this.provisionInfo.immediateNeeds.clientImmediateNeeds.funeralLegalExpenses :0;
        this.funeralLegalExpensesPartner = this.provisionInfo.immediateNeeds.partnerImmediateNeeds !=null ? this.provisionInfo.immediateNeeds.partnerImmediateNeeds.funeralLegalExpenses :0;

        this.housePurchaseClient = this.provisionInfo.immediateNeeds.clientImmediateNeeds !=null ? this.provisionInfo.immediateNeeds.clientImmediateNeeds.homePurchase :0;
        this.housePurchasePartner = this.provisionInfo.immediateNeeds.partnerImmediateNeeds !=null ? this.provisionInfo.immediateNeeds.partnerImmediateNeeds.homePurchase :0;

        this.totalNetImmediateNeedClient = this.totalDebt + this.funeralLegalExpensesClient + this.housePurchaseClient - (this.deceasedPersonsSuperClient + this.valueOfAllInvestmentsIfSoldClient);
        this.totalNetImmediateNeedPartner = this.totalDebt + this.funeralLegalExpensesPartner + this.housePurchasePartner - (this.deceasedPersonsSuperPartner + this.valueOfAllInvestmentsIfSoldPartner);

        this.homeModificationClient = modificationHome;
        this.homeModificationPartner = modificationHomePartner;

        this.medicalTreatmentClient = medicalTreatment;
        this.medicalTreatmentPartner = medicalTreatmentPartner;

        
        // this.immediateNeedsTotalClient = this.totalDebt + this.homeModificationClient + this.medicalTreatmentClient;
        // this.immediateNeedsTotalPartner = this.totalDebt + this.homeModificationPartner + this.medicalTreatmentPartner;
        
        this.immediateNeedsTotalClient = this.totalDebt + this.housePurchaseClient + this.medicalTreatmentClient;
        this.immediateNeedsTotalPartner = this.totalDebt + this.housePurchasePartner + this.medicalTreatmentPartner;

        this.immediateNonSuperIncomingAssetsTotalClient = this.investmentPropertiesClient + this.totalValueOfInvestmentsIfSold;
        this.immediateNonSuperIncomingAssetsTotalPartner = this.investmentPropertiesPartner + this.totalValueOfInvestmentsIfSold;

        this.upfrontCapitalNeedClient = upfrontCapitalNeeds;
        this.upfrontCapitalNeedPartner = upfrontCapitalNeedsPartner;

        this.yearsOffWorkClient = yearsNeedOf;
        this.yearsOffWorkPartner = yearsNeedOfPartner;

        this.coverForYearsOffWorkClient = 2*(this.income + this.incomePartner)*0.7; // 140*this.yearsOffWorkClient;
        this.coverForYearsOffWorkPartner = 2*(this.income + this.incomePartner)*0.7; // 140*this.yearsOffWorkPartner;

        this.totalCoverRequiredClient = Math.round((this.upfrontCapitalNeedClient+2*(this.income + this.incomePartner)*0.7 + this.less2YearsPassiveIncomeClient)/1000)*1000; // this.upfrontCapitalNeedClient + this.coverForYearsOffWorkClient;
        this.totalCoverRequiredPartner = Math.round((this.upfrontCapitalNeedPartner+2*(this.income + this.incomePartner)*0.7 + this.less2YearsPassiveIncomePartner)/1000)*1000; // this.upfrontCapitalNeedPartner + this.coverForYearsOffWorkPartner;
        this.less2YearsPassiveIncomeClient = 0; // passiveIncomeContinuedPaid*2;
        this.less2YearsPassiveIncomePartner = 0; // passiveIncomeContinuedPaidPartner*2;

        this.cashedOutOfSuperImmediatelyClient = this.immediateNeedsTotalClient - this.immediateNonSuperIncomingAssetsTotalClient;
        this.cashedOutOfSuperImmediatelyPartner = this.immediateNeedsTotalPartner - this.immediateNonSuperIncomingAssetsTotalPartner;

        this.grossUpForTaxClient = this.cashedOutOfSuperImmediatelyClient*16.5/100;
        this.grossUpForTaxPartner = this.cashedOutOfSuperImmediatelyPartner*16.5/100;

        this.totalNetImmediateNeedTPDClient = this.cashedOutOfSuperImmediatelyClient + this.grossUpForTaxClient;
        this.totalNetImmediateNeedTPDPartner = this.cashedOutOfSuperImmediatelyPartner + this.grossUpForTaxPartner;

        this.sharedDataService.changFuneralLegalExpensesClient(this.funeralLegalExpensesClient);
        this.sharedDataService.changFuneralLegalExpensesPartner(this.funeralLegalExpensesPartner);
        this.sharedDataService.changHousePurchaseClient(this.housePurchaseClient);
        this.sharedDataService.changHousePurchasePartner(this.housePurchasePartner);
        this.sharedDataService.changTotalNetImmediateNeedClient(this.totalNetImmediateNeedClient);
        this.sharedDataService.changTotalNetImmediateNeedPartner(this.totalNetImmediateNeedPartner);

        this.sharedDataService.changHomeModificationClient(this.homeModificationClient);
        this.sharedDataService.changHomeModificationPartner(this.homeModificationPartner);
        this.sharedDataService.changMedicalTreatmentClient(this.medicalTreatmentClient);
        this.sharedDataService.changMedicalTreatmentPartner(this.medicalTreatmentPartner);
        this.sharedDataService.changImmediateNeedsTotalClient(this.immediateNeedsTotalClient);
        this.sharedDataService.changImmediateNeedsTotalPartner(this.immediateNeedsTotalPartner);
        this.sharedDataService.changImmediateNonSuperIncomingAssetsTotalClient(this.immediateNonSuperIncomingAssetsTotalClient);
        this.sharedDataService.changImmediateNonSuperIncomingAssetsTotalPartner(this.immediateNonSuperIncomingAssetsTotalPartner);
        this.sharedDataService.changCashedOutOfSuperImmediatelyClient(this.cashedOutOfSuperImmediatelyClient);
        this.sharedDataService.changCashedOutOfSuperImmediatelyPartner(this.cashedOutOfSuperImmediatelyPartner);
        this.sharedDataService.changGrossUpForTaxClient(this.grossUpForTaxClient);
        this.sharedDataService.changGrossUpForTaxPartner(this.grossUpForTaxPartner);
        this.sharedDataService.changTotalNetImmediateNeedTPDClient(this.totalNetImmediateNeedTPDClient);
        this.sharedDataService.changTotalNetImmediateNeedTPDPartner(this.totalNetImmediateNeedTPDPartner);

        this.sharedDataService.changUpfrontCapitalNeedClient(this.upfrontCapitalNeedClient);
        this.sharedDataService.changUpfrontCapitalNeedPartner(this.upfrontCapitalNeedPartner);
        this.sharedDataService.changYearsOffWorkClient(this.yearsOffWorkClient);
        this.sharedDataService.changYearsOffWorkPartner(this.yearsOffWorkPartner);
        this.sharedDataService.changCoverForYearsOffWorkClient(this.coverForYearsOffWorkClient);
        this.sharedDataService.changCoverForYearsOffWorkPartner(this.coverForYearsOffWorkPartner);
        this.sharedDataService.changTotalCoverRequiredClient(this.totalCoverRequiredClient);
        this.sharedDataService.changTotalCoverRequiredPartner(this.totalCoverRequiredPartner);
        this.sharedDataService.changLess2YearsPassiveIncomeClient(this.less2YearsPassiveIncomeClient);
        this.sharedDataService.changLess2YearsPassiveIncomePartner(this.less2YearsPassiveIncomePartner);
        

        this.provisionInfoDependent = this.provisionInfo.dependentSupports;
        this.sharedDataService.changProvisionInfo(this.provisionInfo);
        this.sharedDataService.changProvisionInfoDependent(this.provisionInfoDependent);
        this.isLoaded5 = true;
        clearInterval(this.interval);
      } 
      // this.ngxService.stop();` 
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
    });
  }

  noteSave(treeListFromServer2, event: any){
    // console.log(retirement);
    let value = event.target.value;
    let inputId = event.target.id;
    let errorVal = true;
    // console.log(treeListFromServer2);
    // console.log(value);

    if(treeListFromServer2==null){
      this.toastr.error('Error on updating note.');  
    }
    else {
      let familyTreeId  = treeListFromServer2.familyTreeId;

      // if(inputId == 'retirementAge' || inputId == 'retirementAgePartner') {
        // this.retirementAdd.retirementAge = value;
      // }
       
      if(errorVal){
        this.familyTreeService.addProvisionNote(familyTreeId , value).subscribe((data:ProvisionResponse)=>{
          if(data.error && !data.success){      
            this.toastr.error('Error on updating note.');      
          }
          else{
            this.toastr.success('Note updated successfully.');
          }
    
          // this.getRetirementInfo();
        },err=>{   
          console.log(err);
          this.toastr.error('Error on updating note.');
        });
      }
    }
  }

  onSubmitProvision(provisionInfo)    
  {
    // this.submitted = true;
 
    // if (this.addProvisionForm.invalid  ) { //|| this.documentModel.docType == "0" || (title && title.trim()=="")

    //   //  console.log(this.addProvisionForm.controls);
    //   return;
    // }
    // else{
    //   //this.showAddButton = true;
    //   // console.log(this.addProvisionForm.value);  
    //   // console.log(provisionInfo);
    //   this.dependentSupporAdd = new DependentSupportsList;
    //   this.spouseDeathOrTPDsAdd = new DeathOrTPDItems;
    //   this.spouseDeathOrTPDsPartnerAdd = new DeathOrTPDItems;
    //   this.benefitPeriodsItemsAdd = new BenefitPeriodsItems;
    //   this.benefitPeriodsItemsPartnerAdd = new BenefitPeriodsItems;
    //   this.otherProvisionsItemsAdd = new OtherProvisionsItems;
    //   this.otherProvisionsItemsPartnerAdd = new OtherProvisionsItems;

    //   // Update Dependent Support
    //   this.dependentSupporAdd.dependentId =provisionInfo.clientRetirement.clientCode;
    //   this.dependentSupporAdd.age =this.addProvisionForm.value.age;
    //   this.dependentSupporAdd.yearAmount =this.addProvisionForm.value.yearAmount;

    //   // Update Spouse Death Or TPD
    //   this.spouseDeathOrTPDsAdd.clientCode =provisionInfo.clientRetirement.clientCode;
    //   this.spouseDeathOrTPDsAdd.returnToWork =this.addProvisionForm.value.returnToWork;
    //   this.spouseDeathOrTPDsAdd.yearsNeedOf =this.addProvisionForm.value.yearsNeedOf;
    //   this.spouseDeathOrTPDsAdd.incomeEarnUponReturn =this.addProvisionForm.value.incomeEarnUponReturn;

    //   this.spouseDeathOrTPDsPartnerAdd.clientCode =provisionInfo.clientRetirement.clientCode;
    //   this.spouseDeathOrTPDsPartnerAdd.returnToWork =this.addProvisionForm.value.returnToWorkPartner;
    //   this.spouseDeathOrTPDsPartnerAdd.yearsNeedOf =this.addProvisionForm.value.yearsNeedOfPartner;
    //   this.spouseDeathOrTPDsPartnerAdd.incomeEarnUponReturn =this.addProvisionForm.value.incomeEarnUponReturnPartner;

    //   // Update Benefit Period
    //   this.benefitPeriodsItemsAdd.clientCode =provisionInfo.clientRetirement.clientCode;
    //   this.benefitPeriodsItemsAdd.value =this.addProvisionForm.value.value;

    //   this.benefitPeriodsItemsPartnerAdd.clientCode =provisionInfo.clientRetirement.clientCode;
    //   this.benefitPeriodsItemsPartnerAdd.value =this.addProvisionForm.value.valuePartner;

    //   // Update Other Provision
    //   this.otherProvisionsItemsAdd.clientCode =provisionInfo.clientRetirement.clientCode;
    //   this.otherProvisionsItemsAdd.description =this.addProvisionForm.value.description;
    //   this.otherProvisionsItemsAdd.value =this.addProvisionForm.value.provisionValue;

    //   this.otherProvisionsItemsPartnerAdd.clientCode =provisionInfo.clientRetirement.clientCode;
    //   this.otherProvisionsItemsPartnerAdd.description =this.addProvisionForm.value.descriptionPartner;
    //   this.otherProvisionsItemsPartnerAdd.value =this.addProvisionForm.value.provisionValuePartner;

    //   // this.familyTreeService.editRetirement(this.retirementAdd, this.retirementInfo.clientRetirement.id).subscribe((data:RetirementAddResponse)=>{
    //   //   //this.ngxService.stop();
    //   //   if(data.error && !data.success){      
    //   //     this.toastr.error('Error on updating retirement.');      
    //   //   }
    //   //   else{
    //   //     this.familyTreeService.editRetirement(this.retirementPartnerAdd, this.retirementInfo.partnerRetirement.id).subscribe((data:RetirementAddResponse)=>{
    //   //       if(data.error && !data.success){      
    //   //         this.toastr.error('Error on updating retirement.');      
    //   //       }
    //   //       else{
    //   //         this.toastr.success('Retirement updated successfully.');
    //   //         //this.event.emit('OK');
    //   //         // console.log(this.event);
    //   //         //this.modalRef.hide();
    //   //       }

    //   //       this.getRetirementInfo();
    //   //     },err=>{   
    //   //       console.log(err);
    //   //       this.toastr.error('Error on updating retirement.');
    //   //     });
    //   //   }
    //   // },err=>{   
    //   //   console.log(err);
    //   //   //this.ngxService.stop();
    //   //   this.toastr.error('Error on updating retirement.');
    //   // });

    // }
      
  }


}
