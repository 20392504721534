<div class="boxcolor mt-2">
    <table class="table newtable tableinsurance">
      <thead>
        <tr>
          <th class="tc2 font-weight-normal ml-2 tab_fs">Total Insurance Recommended</th>
          <th class="tc1 font-weight-normal tab_fs">{{clientFirstname}}</th>
          <th class="tc1 font-weight-normal tab_fs">{{partnerFirstname}}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="isLoaded3 && isLoaded "> <!-- && isLoaded7 -->
          <tr>
            <td colspan="3" class="pb-0 ins_life">IMMEDIATE NEEDS</td>
          </tr>
          <tr>
            <td>Debt</td>
            <td>${{totalDebt | number: '1.0-0'}}</td>
            <td>${{totalDebt | number: '1.0-0'}}</td>
          </tr>
          <tr>
            <td>Home Modification</td>
            <td>${{homeModificationClient | number: '1.0-0'}}</td>
            <td>${{homeModificationPartner | number: '1.0-0'}}</td>
          </tr>
          <tr>
            <td>Medical Treatment</td>
            <td>${{medicalTreatmentClient | number: '1.0-0'}}</td>
            <td>${{medicalTreatmentPartner | number: '1.0-0'}}</td>
          </tr>
          <tr>
            <td>Income Replacement for Spouse's Time Off Work</td>
            <td>${{incomePartner * (70/100)*yearsNeedOfPartner  | number: '1.0-0'}}</td> <!-- salaryPartner * (70/100)*yearsNeedOf -->
            <td>${{income * (70/100)*yearsNeedOf  | number: '1.0-0'}}</td> <!-- salaryOwner * (70/100)*yearsNeedOfPartner -->
          </tr>
          <tr>
          <td>House Purchase</td>
            <td>${{housePurchaseClient | number: '1.0-0'}}</td>
            <td>${{housePurchasePartner | number: '1.0-0'}}</td>
          </tr>
          <tr class="tableinsurancetr">
            <td class="ins_life_total" >Immediate Needs Total</td>
            <td class="ins_life_total">${{totalDebt + homeModificationClient + medicalTreatmentClient + housePurchaseClient +incomePartner * (70/100)*yearsNeedOfPartner | number: '1.0-0'}}</td>  <!-- immediateNeedsTotalClient -->
            <td class="ins_life_total">${{totalDebt + homeModificationPartner + medicalTreatmentPartner + housePurchasePartner + income * (70/100)*yearsNeedOf | number: '1.0-0'}}</td> <!-- immediateNeedsTotalPartner -->
          </tr>
          <tr>
            <td colspan="3" class="pb-0 ins_life">IMMEDIATE NON SUPER INCOMING ASSETS</td>
          </tr>
          <tr>
            <td>Investment Properties</td>
            <td>${{investmentPropertiesClient | number: '1.0-0'}}</td>
            <td>${{investmentPropertiesPartner | number: '1.0-0'}}</td>
          </tr>
          <tr>
            <td>Value of Investments if Sold</td>
            <td>${{totalValueOfInvestmentsIfSold | number: '1.0-0'}}</td>
            <td>${{totalValueOfInvestmentsIfSold | number: '1.0-0'}}</td>
          </tr>
          <tr class="tableinsurancetr">
            <td class="ins_life_total" >Immediate Non Super Incoming Assets Total</td>
            <td class="ins_life_total">${{investmentPropertiesClient+investmentPropertiesPartner+ totalValueOfInvestmentsIfSold | number: '1.0-0'}}</td> <!--investmentPropertiesClient + totalValueOfInvestmentsIfSold, immediateNonSuperIncomingAssetsTotalClient -->
            <td class="ins_life_total">${{investmentPropertiesPartner+investmentPropertiesClient+ totalValueOfInvestmentsIfSold | number: '1.0-0'}}</td> <!--investmentPropertiesPartner + totalValueOfInvestmentsIfSold, immediateNonSuperIncomingAssetsTotalPartner -->
          </tr>
          <tr>
            <td colspan="3" class="pb-0 ins_life">IMMEDIATE SUPER</td>
          </tr>
          <tr>
            <td>Cashed Out of Super Immediately</td>
            <td>${{totalDebt + homeModificationClient + medicalTreatmentClient + housePurchaseClient +incomePartner * (70/100)*yearsNeedOfPartner -(investmentPropertiesClient+investmentPropertiesPartner+ totalValueOfInvestmentsIfSold) | number: '1.0-0'}}</td> 
            <!--totalDebt + homeModificationClient + medicalTreatmentClient + housePurchaseClient - (investmentPropertiesClient + totalValueOfInvestmentsIfSold), cashedOutOfSuperImmediatelyClient -->
            <td>${{totalDebt + homeModificationPartner + medicalTreatmentPartner + housePurchasePartner + income * (70/100)*yearsNeedOf -(investmentPropertiesPartner+investmentPropertiesClient+ totalValueOfInvestmentsIfSold) | number: '1.0-0'}}</td> 
            <!-- totalDebt + homeModificationPartner + medicalTreatmentPartner + housePurchasePartner - (investmentPropertiesPartner + totalValueOfInvestmentsIfSold), cashedOutOfSuperImmediatelyPartner -->
          </tr>
          <tr>
            <td>Preservation Age</td>
            <td>60</td>
            <td>60</td>
          </tr>
          <tr>
            <td>Gross Up for Tax</td>
            <td>${{grossUpForTax | number: '1.0-0'}}</td>
            <td>${{grossUpForTaxPartner | number: '1.0-0'}}</td>
          </tr>
          <tr class="tableinsurancetr">
            <td class="ins_life_total" >Total Net Immediate Need</td>
            <td class="ins_life_total">${{totalNetImmediateNeedTPDClient | number: '1.0-0'}}</td>
            <td class="ins_life_total">${{totalNetImmediateNeedTPDPartner | number: '1.0-0'}}</td>
          </tr>

          <tr>
            <td colspan="3" class="pb-0 ins_life">PROGRESSIVE NEEDS</td>
          </tr>
          <tr>
            <td>Income Support from Retirement to Longest Life Expectancy</td>
            <td>${{incomeSupportFromRetirementToLongestLifeExpectancy | number: '1.0-0'}}</td>
            <td>${{incomeSupportFromRetirementToLongestLifeExpectancyPartner | number: '1.0-0'}}</td>
          </tr>
          <tr>
            <td>Income Support till Retirement of Non-Injured Spouse</td>
            <td>${{incomeSupportTillRetirementOfNonInjuredSpouse | number: '1.0-0'}}</td>
            <td>${{incomeSupportTillRetirementOfNonInjuredSpousePartner | number: '1.0-0'}}</td>
          </tr>
          <tr>
            <td>Income Protection Benefits Paid</td>
            <td>${{incomeProtectionBenefitsPaid | number: '1.0-0'}}</td>
            <td>${{incomeProtectionBenefitsPaidPartner | number: '1.0-0'}}</td>
          </tr>
          <tr>
            <td>Childcare</td>
            <td>${{totalChildCare | number: '1.0-0'}}</td>
            <td>${{totalChildCare | number: '1.0-0'}}</td>
          </tr>
          <tr>
            <td>Injured Persons Super</td>
            <td>${{-superBalance | number: '1.0-0'}}</td>
            <td>${{-superBalancePartner | number: '1.0-0'}}</td>
          </tr>
          <tr>
            <td>Non-Injured Spouse's Super</td>
            <td>${{-superBalancePartner | number: '1.0-0'}}</td>
            <td>${{-superBalance | number: '1.0-0'}}</td>
          </tr>
          <tr class="tableinsurancetr">
            <td class="ins_life_total" >Total Net Progressive Need</td>
            <td class="ins_life_total">${{totalNetProgressiveNeed | number: '1.0-0'}}</td>
            <td class="ins_life_total">${{totalNetProgressiveNeedPartner | number: '1.0-0'}}</td>
          </tr>
          <tr class="tableinsurancetr">
            <td class="ins_life_total" >Total Required</td>
            <td class="ins_life_total">${{totalRequired | number: '1.0-0'}}</td>
            <td class="ins_life_total">${{totalRequiredPartner | number: '1.0-0'}}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>

  <div class="boxcolor mt-2 " >
       
    <table class="table newtable tableinsurance" style="padding-bottom: 20px;">
      <thead>
        <tr>
          <th class="tc2 font-weight-normal ml-2 tab_fs">Existing</th>
        </tr>
      </thead>
      <!-- <tbody>
        <tr>
          <td  class=" tableColWith">Policy Owner</td>
          <td>: {{clientFirstname}}</td>
          <td  class=" tableColWith">Monthly Benefit</td>
          <td>: $100</td>
        </tr>
        <tr>
          <td>Insurer</td>
          <td>: Insurer</td>
          <td>Waiting Period</td>
          <td>: 0</td>
        </tr>
        <tr>
          <td>Benefit Period</td>
          <td>: 0</td>
          <td>Monthly Premium</td>
          <td>: $0</td>
        </tr>
        <tr>
          <td>Structure</td>
          <td>: Not defined</td>
          <td>Policy Definition</td>
          <td>: Not defined</td>
        </tr>
        <tr class="tableinsurancetr">
          <td class="tabSummery">Funding Mechanism</td>
          <td>: Not defined</td>
          <td> </td>
          <td> </td>
        </tr>

      </tbody> -->
    </table>

    <ng-container *ngIf="isLoaded3">
      <table class="table tablecollect insutable">
        <thead>
          <tr>
            <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Policy Owner</th>
            <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs pdAlignTableHead">Insurer</th>
            <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Sum Insured</th>
            <!-- <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Waiting Period</th>
            <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Benefit Period</th> -->
            <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Monthly Premium</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="insuranceInfo.groupedCoversClient.tpd.length > 0">
            <ng-container *ngFor="let income of insuranceInfo.groupedCoversClient.tpd; let i = index">
              <tr>
                <td class="tablefist tableb">{{clientFirstname}}</td>
                <td class="tableleftbedit " style="background-color: #f9f9f9;">
                  <ng-container *ngIf="income.planName">
                    <input readonly type="text" class="form-control neweditinput" placeholder="{{income.planName}}" data-toggle="tooltip" data-html="true" title="{{income.planName}}" style="width: auto;">
                  </ng-container>
                  <ng-container *ngIf="income.planName =='' || income.planName == null">
                    <input readonly type="text" class="form-control neweditinput" placeholder="-">
                  </ng-container>
                </td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="income.sumInsured">
                    <input readonly type="text" class="form-control neweditinput" placeholder="${{income.sumInsured | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="income.sumInsured == null || income.sumInsured == ''">
                    <input readonly type="text" class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
                <!-- <td class="tableleftbedit ">
                  <ng-container *ngIf="income.waitingPeriod">
                    <input readonly type="text" class="form-control neweditinput" placeholder="{{income.waitingPeriod}}">
                  </ng-container>
                  <ng-container *ngIf="income.waitingPeriod == null || income.waitingPeriod == ''">
                    <input readonly type="text" class="form-control neweditinput" placeholder="-">
                  </ng-container>
                </td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="income.benefitPeriod">
                    <input readonly type="text" class="form-control neweditinput" placeholder="{{income.benefitPeriod}}">
                  </ng-container>
                  <ng-container *ngIf="income.benefitPeriod == null || income.benefitPeriod == ''">
                    <input readonly type="text" class="form-control neweditinput" placeholder="-">
                  </ng-container>
                </td> -->
                <td class="tableleftbedit ">
                  <ng-container *ngIf="income.totalPremium">
                    <!-- <input readonly type="text" class="form-control neweditinput" placeholder="${{income.monthlyPremium | number: '1.0-0'}}"> -->
                    <input readonly type="text" class="form-control neweditinput" placeholder="${{income.totalPremium/12 | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="income.totalPremium == null || income.totalPremium == ''">
                    <input readonly type="text" class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
              </tr>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="insuranceInfo.groupedCoversClient.tpd.length == 0">
            <tr>
              <td class="tablefist tableb">{{clientFirstname}}</td>
              <td class="tableleftbedit "><input readonly type="text" class="form-control neweditinput" placeholder="-"></td>
              <td class="tableleftbedit "><input readonly type="text" class="form-control neweditinput" placeholder="$0"></td>
              <td class="tableleftbedit "><input readonly type="text" class="form-control neweditinput" placeholder="0"></td>
              <td class="tableleftbedit "><input readonly type="text" class="form-control neweditinput" placeholder="0"></td>
              <td class="tableleftbedit "><input readonly type="text" class="form-control neweditinput" placeholder="0"></td>
            </tr>
          </ng-container>


          <ng-container *ngIf="insuranceInfo.groupedCoversPartner.tpd.length > 0">
            <ng-container *ngFor="let income of insuranceInfo.groupedCoversPartner.tpd; let i = index">
              <tr>
                <td class="tablefist tableb">{{partnerFirstname}}</td>
                <td class="tableleftbedit " style="background-color: #f9f9f9;">
                  <ng-container *ngIf="income.planName">
                    <input readonly type="text" class="form-control neweditinput" placeholder="{{income.planName}}" data-toggle="tooltip" data-html="true" title="{{income.planName}}" style="width: auto;">
                  </ng-container>
                  <ng-container *ngIf="income.planName =='' || income.planName == null">
                    <input readonly type="text" class="form-control neweditinput" placeholder="-">
                  </ng-container>
                </td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="income.sumInsured">
                    <input readonly type="text" class="form-control neweditinput" placeholder="${{income.sumInsured | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="income.sumInsured == null || income.sumInsured == ''">
                    <input readonly type="text" class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
                <!-- <td class="tableleftbedit ">
                  <ng-container *ngIf="income.waitingPeriod">
                    <input readonly type="text" class="form-control neweditinput" placeholder="{{income.waitingPeriod}}">
                  </ng-container>
                  <ng-container *ngIf="income.waitingPeriod == null || income.waitingPeriod == ''">
                    <input readonly type="text" class="form-control neweditinput" placeholder="-">
                  </ng-container>
                </td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="income.benefitPeriod">
                    <input readonly type="text" class="form-control neweditinput" placeholder="{{income.benefitPeriod}}">
                  </ng-container>
                  <ng-container *ngIf="income.benefitPeriod == null || income.benefitPeriod == ''">
                    <input readonly type="text" class="form-control neweditinput" placeholder="-">
                  </ng-container>
                </td> -->
                <td class="tableleftbedit ">
                  <ng-container *ngIf="income.totalPremium">
                    <!-- <input readonly type="text" class="form-control neweditinput" placeholder="${{income.monthlyPremium | number: '1.0-0'}}"> -->
                    <input readonly type="text" class="form-control neweditinput" placeholder="${{income.totalPremium/12 | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="income.totalPremium == null || income.totalPremium == ''">
                    <input readonly type="text" class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
              </tr>
            </ng-container>
          </ng-container>
          
        </tbody>
      </table>
    </ng-container>
     
  </div>


  <!-- Proposed section - TPD -->
  <table class="table newtable tableinsurance" style="padding-bottom: 20px;">
    <thead>
      <tr>
        <th class="tc2 font-weight-normal ml-2 tab_fs">Proposed</th>
      </tr>
    </thead>
  </table>
  <ng-container *ngIf="isLoaded8">
    <ng-container *ngIf="proposedInsuranceInfo.clientInsurances.length > 0">
      <ng-container *ngFor="let proposedTPD of proposedInsuranceInfo.clientInsurances; let i = index">
        <ng-container *ngIf="proposedTPD.insuranceCoverType == 2">
          <div class="boxcolor mt-2 proposed" style="background-color: #00000008;">
            <label for="inputEmail4" class="tc2"> </label>
            <i class="fa fa-close close_section" (click)="deleteProposedInsurance(proposedTPD)" ></i>
            <div class="form-row pad10 subgraphs">
              <div class="form-group col-md-6 edit-border"> 
                <ng-container *ngIf="proposedTPD.lifeInsured == clientID">
                  <input type="text" id="lifeInsured" value="{{clientFirstname}}" readonly class="form-control" placeholder="Life Insured">
                </ng-container>
                <ng-container *ngIf="proposedTPD.lifeInsured == partnerID">
                  <input type="text" id="lifeInsured" value="{{partnerFirstname}}" readonly class="form-control" placeholder="Life Insured">
                </ng-container>
              </div>
              <div class="form-group col-md-6 edit-border">
                <input type="text" id="underwriter" [(ngModel)]="proposedTPD.underwriter" (change)="proposedInsuranceSave(proposedTPD, $event)" required class="form-control" placeholder="Insurer">
              </div>
            </div>
            <div class="form-row subgraphs">
              <div class="form-group col-md-4 edit-border"> 
                <input type="text" required id="lifeSum" [(ngModel)]="proposedTPD.lifeSum" (change)="proposedInsuranceSave(proposedTPD, $event)" mask="separator.0" thousandSeparator="," class="form-control do" placeholder="Life sum">
              </div>
              <div class="form-group col-md-4 edit-border"> 
                <input type="text" required id="tpdSum" [(ngModel)]="proposedTPD.tpdSum" (change)="proposedInsuranceSave(proposedTPD, $event)" mask="separator.0" thousandSeparator="," class="form-control" placeholder="TPD sum">
              </div>    
              <div class="form-group col-md-4 edit-border"> 
                <input type="text" required id="monthlyPremium" [(ngModel)]="proposedTPD.monthlyPremium" (change)="proposedInsuranceSave(proposedTPD, $event)" mask="separator.0" thousandSeparator="," class="form-control do" placeholder="Monthly premium">
              </div>            
            </div>
            <div class="form-row subgraphs">
              <div class="form-group col-md-6 edit-border">
                <select id="structure" [(ngModel)]="proposedTPD.structure" (change)="proposedInsuranceSave(proposedTPD, $event)" class="form-control" placeholder="Structre">
                  <option value="" disabled selected>Select structure</option>
                  <option value="0">Stepped</option>
                  <option value="1">Level</option>
                </select>
              </div>
              <div class="form-group col-md-6 edit-border">
                <select id="fundingMechanism" [(ngModel)]="proposedTPD.fundingMechanism" (change)="proposedInsuranceSave(proposedTPD, $event)" class="form-control">
                  <option value="" disabled selected>Select mechanism</option>
                  <option value="0">Super</option>
                  <option value="1">Super via cash flow</option>
                  <option value="2">Cash flow</option>
                </select>
              </div>      
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="proposedInsuranceInfo.partnerInsurances.length > 0">
      <ng-container *ngFor="let proposedTPD of proposedInsuranceInfo.partnerInsurances; let i = index">
        <ng-container *ngIf="proposedTPD.insuranceCoverType == 2">
          <div class="boxcolor mt-2 proposed" style="background-color: #00000008;">
            <label for="inputEmail4" class="tc2"> </label>
            <i class="fa fa-close close_section" (click)="deleteProposedInsurance(proposedTPD)" ></i>
            <div class="form-row pad10 subgraphs">
              <div class="form-group col-md-6 edit-border"> 
                <ng-container *ngIf="proposedTPD.lifeInsured == clientID">
                  <input type="text" id="lifeInsured" value="{{clientFirstname}}" readonly class="form-control" placeholder="Life Insured">
                </ng-container>
                <ng-container *ngIf="proposedTPD.lifeInsured == partnerID">
                  <input type="text" id="lifeInsured" value="{{partnerFirstname}}" readonly class="form-control" placeholder="Life Insured">
                </ng-container>
              </div>
              <div class="form-group col-md-6 edit-border">
                <input type="text" id="underwriter" [(ngModel)]="proposedTPD.underwriter" (change)="proposedInsuranceSave(proposedTPD, $event)" required class="form-control" placeholder="Insurer">
              </div>
            </div>
            <div class="form-row subgraphs">
              <div class="form-group col-md-4 edit-border"> 
                <input type="text" required id="lifeSum" [(ngModel)]="proposedTPD.lifeSum" (change)="proposedInsuranceSave(proposedTPD, $event)" mask="separator.0" thousandSeparator="," class="form-control do" placeholder="Life sum">
              </div>
              <div class="form-group col-md-4 edit-border"> 
                <input type="text" required id="tpdSum" [(ngModel)]="proposedTPD.tpdSum" (change)="proposedInsuranceSave(proposedTPD, $event)" mask="separator.0" thousandSeparator="," class="form-control" placeholder="TPD sum">
              </div>    
              <div class="form-group col-md-4 edit-border"> 
                <input type="text" required id="monthlyPremium" [(ngModel)]="proposedTPD.monthlyPremium" (change)="proposedInsuranceSave(proposedTPD, $event)" mask="separator.0" thousandSeparator="," class="form-control do" placeholder="Monthly premium">
              </div>            
            </div>
            <div class="form-row subgraphs">
              <div class="form-group col-md-6 edit-border">
                <select id="structure" [(ngModel)]="proposedTPD.structure" (change)="proposedInsuranceSave(proposedTPD, $event)" class="form-control" placeholder="Structre">
                  <option value="" disabled selected>Select structure</option>
                  <option value="0">Stepped</option>
                  <option value="1">Level</option>
                </select>
              </div>
              <div class="form-group col-md-6 edit-border">
                <select id="fundingMechanism" [(ngModel)]="proposedTPD.fundingMechanism" (change)="proposedInsuranceSave(proposedTPD, $event)" class="form-control">
                  <option value="" disabled selected>Select mechanism</option>
                  <option value="0">Super</option>
                  <option value="1">Super via cash flow</option>
                  <option value="2">Cash flow</option>
                </select>
              </div>      
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <!-- Default-Client === IF Client list null  Start--> 
    <ng-container *ngIf="!hasTPDClient">
      <div class="boxcolor mt-2 proposed" style="background-color: #00000008;">
        <label for="inputEmail4" class="tc2">  </label>
        <i class="close_section" (click)="addProposedInsurance(proposedInsuranceInfo, 'tpd', 'defaultClient')" >Save Now</i>
        <div class="form-row pad10 subgraphs">
          <div class="form-group col-md-6 edit-border"> 
            <!-- <select id="lifeInsuredAddTPD" class="form-control" placeholder="Structre">
              <option value="" disabled selected>Select life insured</option>
              <option value="{{clientID}}">{{clientFirstname}}</option>
              <option value="{{partnerID}}">{{partnerFirstname}}</option>
            </select> -->
            <input type="text" id="lifeInsuredClient" value="{{clientFirstname}}" readonly class="form-control" placeholder="TPD Insured">
            <input type="text" id="lifeInsuredAddTPDClient" value="{{clientID}}" readonly class="form-control" placeholder="TPD Insured" style="display: none;">
          </div>
          <div class="form-group col-md-6 edit-border">
            <input type="text" id="insurerAddTPDClient" class="form-control" placeholder="Insurer">
          </div>
        </div>
        <div class="form-row subgraphs">
          <div class="form-group col-md-4 edit-border"> 
            <input type="text" required id="lifeSumAddTPDClient" mask="separator.0" thousandSeparator="," class="form-control do" placeholder="Life sum">
          </div>
          <div class="form-group col-md-4 edit-border"> 
            <input type="text" id="tpdSumAddTPDClient" mask="separator.0" [(ngModel)]="totalRequiredInput" value="{{totalRequiredInput}}" thousandSeparator="," class="form-control do" placeholder="TPD sum" required>
          </div>    
          <div class="form-group col-md-4 edit-border"> 
            <input type="text" required id="monthlyPremiumAddTPDClient" mask="separator.0" thousandSeparator="," class="form-control do" placeholder="Monthly premium">
          </div>            
        </div>
        <div class="form-row subgraphs">
          <div class="form-group col-md-6 edit-border">
            <select id="structureAddTPDClient" class="form-control" placeholder="Structre">
              <option value="" disabled selected>Select structure</option>
              <option value="0">Stepped</option>
              <option value="1">Level</option>
            </select>
          </div>
          <div class="form-group col-md-6 edit-border">
            <select id="fundingMechanismAddTPDClient" class="form-control">
              <option value="" disabled selected>Select mechanism</option>
              <option value="0">Super</option>
              <option value="1">Super via cash flow</option>
              <option value="2">Cash flow</option>
            </select>
          </div>      
        </div>
      </div>
    </ng-container>
    <!-- Default-Client === IF Client list null  End-->

    <!-- Default-Partner === IF Partner list null  Start--> 
    <ng-container *ngIf="!hasTPDPartner">
      <div class="boxcolor mt-2 proposed" style="background-color: #00000008;">
        <label for="inputEmail4" class="tc2">  </label>
        <i class="close_section" (click)="addProposedInsurance(proposedInsuranceInfo, 'tpd', 'defaultPartner')" >Save Now</i>
        <div class="form-row pad10 subgraphs">
          <div class="form-group col-md-6 edit-border"> 
            <!-- <select id="lifeInsuredAddTPD" class="form-control" placeholder="Structre">
              <option value="" disabled selected>Select life insured</option>
              <option value="{{clientID}}">{{clientFirstname}}</option>
              <option value="{{partnerID}}">{{partnerFirstname}}</option>
            </select> -->
            <input type="text" id="lifeInsuredPartner" value="{{partnerFirstname}}" readonly class="form-control" placeholder="TPD Insured">
            <input type="text" id="lifeInsuredAddTPDPartner" value="{{partnerID}}" readonly class="form-control" placeholder="TPD Insured" style="display: none;">
          </div>
          <div class="form-group col-md-6 edit-border">
            <input type="text" id="insurerAddTPDPartner" class="form-control" placeholder="Insurer">
          </div>
        </div>
        <div class="form-row subgraphs">
          <div class="form-group col-md-4 edit-border"> 
            <input type="text" required id="lifeSumAddTPDPartner" mask="separator.0" thousandSeparator="," class="form-control do" placeholder="Life sum">
          </div>
          <div class="form-group col-md-4 edit-border"> 
            <input type="text" required id="tpdSumAddTPDPartner" mask="separator.0" thousandSeparator="," [(ngModel)]="totalRequiredPartnerInput" value="{{totalRequiredPartnerInput}}" class="form-control do" placeholder="TPD sum">
          </div>    
          <div class="form-group col-md-4 edit-border"> 
            <input type="text" required id="monthlyPremiumAddTPDPartner" mask="separator.0" thousandSeparator="," class="form-control do" placeholder="Monthly premium">
          </div>            
        </div>
        <div class="form-row subgraphs">
          <div class="form-group col-md-6 edit-border">
            <select id="structureAddTPDPartner" class="form-control" placeholder="Structre">
              <option value="" disabled selected>Select structure</option>
              <option value="0">Stepped</option>
              <option value="1">Level</option>
            </select>
          </div>
          <div class="form-group col-md-6 edit-border">
            <select id="fundingMechanismAddTPDPartner" class="form-control">
              <option value="" disabled selected>Select mechanism</option>
              <option value="0">Super</option>
              <option value="1">Super via cash flow</option>
              <option value="2">Cash flow</option>
            </select>
          </div>      
        </div>
      </div>
    </ng-container>
    <!-- Default-Partner === IF Partner list null  End-->

    <!-- Add new TPD start -->
    <div class="boxcolor mt-2 proposed" style="background-color: #00000008;">
      <label for="inputEmail4" class="tc2"> Add Proposed </label>
      <i class="close_section" (click)="addProposedInsurance(proposedInsuranceInfo, 'tpd', 'addNew')" >Save Now</i>
      <div class="form-row pad10 subgraphs">
        <div class="form-group col-md-6 edit-border"> 
          <select id="lifeInsuredAddTPD" class="form-control" placeholder="Structre">
            <option value="" disabled selected>Select life insured</option>
            <option value="{{clientID}}">{{clientFirstname}}</option>
            <option value="{{partnerID}}">{{partnerFirstname}}</option>
          </select>
        </div>
        <div class="form-group col-md-6 edit-border">
          <input type="text" id="insurerAddTPD" class="form-control" placeholder="Insurer">
        </div>
      </div>
      <div class="form-row subgraphs">
        <div class="form-group col-md-4 edit-border"> 
          <input type="text" required id="lifeSumAddTPD" mask="separator.0" thousandSeparator="," class="form-control do" placeholder="Life sum">
        </div>
        <div class="form-group col-md-4 edit-border"> 
          <input type="text" required id="tpdSumAddTPD" mask="separator.0" thousandSeparator="," class="form-control do" placeholder="TPD sum">
        </div>    
        <div class="form-group col-md-4 edit-border"> 
          <input type="text" required id="monthlyPremiumAddTPD" mask="separator.0" thousandSeparator="," class="form-control do" placeholder="Monthly premium">
        </div>            
      </div>
      <div class="form-row subgraphs">
        <div class="form-group col-md-6 edit-border">
          <select id="structureAddTPD" class="form-control" placeholder="Structre">
            <option value="" disabled selected>Select structure</option>
            <option value="0">Stepped</option>
            <option value="1">Level</option>
          </select>
        </div>
        <div class="form-group col-md-6 edit-border">
          <select id="fundingMechanismAddTPD" class="form-control">
            <option value="" disabled selected>Select mechanism</option>
            <option value="0">Super</option>
            <option value="1">Super via cash flow</option>
            <option value="2">Cash flow</option>
          </select>
        </div>      
      </div>
    </div>
    <!-- Add new TPD end -->
  </ng-container>
  <!-- Proposed section ends - TPD -->  