import { Component, OnInit, Input } from '@angular/core';
import { FamilyTreeService } from 'src/app/family-tree/family-tree.service';
import { SharedDataService } from 'src/app/shared/service/shared-data.service';
import { ToastrService } from 'ngx-toastr';
import { WillModel, ResidualBequestDetail, AddResiduaryBequestUsers, ResidualBequests } from 'src/app/shared/models/will.model';
import { WillResponse, ResidualBequestsResponse, ResiduaryBequestUserResponse, ResiduaryBequestNoteResponse, DeleteOtherProvisionResponse } from 'src/app/shared/models/response.model';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-residual-bequests',
  templateUrl: './residual-bequests.component.html',
  styleUrls: ['../../rightside.component.css']
})
export class ResidualBequestsComponent implements OnInit {

  @Input() family_tree_id:string;
  @Input() clientFirstname:string;
  @Input() partnerFirstname:string;
  @Input() partnerHas:boolean;
  @Input() willInfo:WillModel;
  isLoaded6:boolean;
  residualBequestAdd:ResidualBequestDetail;
  RBMessage: string ='';
  addResiduaryBequestUser: any=[];
  addResiduaryBequestNote: any=[];

  constructor(
    private familyTreeService :FamilyTreeService,
    private sharedDataService: SharedDataService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
  }

  getWillInfo(){
    // this.ngxService.start();
    this.familyTreeService.getWill(this.family_tree_id).subscribe((willData:WillResponse)=>{
      if(willData.success){
        this.willInfo = willData.response;
        
        // let executorNmae = this.willInfo.executors.clientExecutors.name,
        // executorType = this.willInfo.executors.clientExecutors.combinedRetirementIncome,
        // precentageIfPartnerDeceased = this.willInfo.executors.partnerExecutors.precentageIfPartnerDeceased,
        // precentageIfPartnerDeceased = this.willInfo.executors.partnerExecutors.precentageIfPartnerDeceased;
          
        this.isLoaded6 = true;
      } 
      // this.ngxService.stop(); 
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });
  }


  residualBequestsTDTSave(residualBequestDetail, event: any){
    // console.log(residualBequestDetail);
    let value = event.target.value;
    let inputId = event.target.id;
    // console.log(value);
    // console.log(inputId);
    
    this.residualBequestAdd = new ResidualBequestDetail;
    this.residualBequestAdd.clientCode = residualBequestDetail.clientCode;

    if(inputId == 'clientTDT' || inputId == 'partnerTDT') {
      this.residualBequestAdd.tdt = value;
      this.residualBequestAdd.letterOfWish = residualBequestDetail.letterOfWish;
      this.residualBequestAdd.specificGifts = residualBequestDetail.specificGifts;
      this.RBMessage = 'TDT';
    }

    if(inputId == 'letterOfWishClient' || inputId == 'letterOfWishPartner') {
      this.residualBequestAdd.tdt = residualBequestDetail.tdt;
      this.residualBequestAdd.letterOfWish = value;
      this.residualBequestAdd.specificGifts = residualBequestDetail.specificGifts;
      this.RBMessage = 'Letter of wishes';
    }

    if(inputId == 'specificGiftsClient' || inputId == 'specificGiftsPartner') {
      this.residualBequestAdd.tdt = residualBequestDetail.tdt;
      this.residualBequestAdd.letterOfWish = residualBequestDetail.letterOfWish;
      this.residualBequestAdd.specificGifts = value;
      this.RBMessage = 'Specific gifts';
    }

    // console.log(this.willExecutorsNoteAdd);
    this.familyTreeService.editResidualBequestsTDT(residualBequestDetail.id, this.residualBequestAdd).subscribe((data:ResidualBequestsResponse)=>{
      if(data.error && !data.success){      
        this.toastr.error('Error on updating '+this.RBMessage+'.');      
      }
      else{
        this.toastr.success(this.RBMessage+' updated successfully.');
      }

      this.getWillInfo();
    },err=>{   
      console.log(err);
      this.toastr.error('Error on updating '+this.RBMessage+'.');
    });
  }

  addResiduaryBequestUsers(ResidualBequestDetail, type)
  {
    this.addResiduaryBequestUser = new AddResiduaryBequestUsers;

    this.addResiduaryBequestUser.residuaryBequestDetailId = ResidualBequestDetail.id;

    if(type == 1) {
      var clientBequestUsersNameAdd = ((document.getElementById("clientBequestUsersNameAdd") as HTMLInputElement).value);
      var clientBequestUsersPercentageAdd = ((document.getElementById("clientBequestUsersPercentageAdd") as HTMLInputElement).value);
    }
    
    if(type == 2) {
      var clientBequestUsersNameAdd = ((document.getElementById("partnerBequestUsersNameAdd") as HTMLInputElement).value);
      var clientBequestUsersPercentageAdd = ((document.getElementById("partnerBequestUsersPercentageAdd") as HTMLInputElement).value);      
    }

    clientBequestUsersPercentageAdd = clientBequestUsersPercentageAdd.replace('%', '');

    this.addResiduaryBequestUser.name = clientBequestUsersNameAdd;
    this.addResiduaryBequestUser.percentage = clientBequestUsersPercentageAdd;

    if(this.addResiduaryBequestUser.percentage >= 101) {
      this.toastr.error('Error on percentage value.');
    } else {
      this.residuaryBequestUserAdd(this.addResiduaryBequestUser);
    }

    ((document.getElementById("clientBequestUsersNameAdd") as HTMLInputElement).value='');
    ((document.getElementById("clientBequestUsersPercentageAdd") as HTMLInputElement).value='');
    ((document.getElementById("partnerBequestUsersNameAdd") as HTMLInputElement).value='');
    ((document.getElementById("partnerBequestUsersPercentageAdd") as HTMLInputElement).value='');
  }

  residuaryBequestUserAdd(residuaryBequestUser)
  {
    this.familyTreeService.addResiduaryBequestUser(residuaryBequestUser).subscribe((data:ResiduaryBequestUserResponse)=>{
      if(data.error && !data.success){      
        this.toastr.error('Error on adding residual bequests user.');      
      }
      else{
        this.toastr.success('Residual bequests user added successfully.');
      }

      this.getWillInfo();
    },err=>{   
      console.log(err);
      this.toastr.error('Error on adding residual bequests user.');
    });
  }

  residuaryBequestUserSave(residualBequestDetail, event: any){
    //console.log(residualBequestDetail);
    let value = event.target.value;
    let inputId = event.target.id;
    let errorVal = true;
    // console.log(value);
    // console.log(inputId);

    this.addResiduaryBequestUser = new AddResiduaryBequestUsers;
    if(inputId == 'clientBequestUsersName' || inputId == 'partnerBequestUsersName') {
      this.addResiduaryBequestUser.name = value;
      this.addResiduaryBequestUser.percentage = residualBequestDetail.percentage;
    }
    if(inputId == 'clientBequestUsersPercentage' || inputId == 'partnerBequestUsersPercentage') {
      let pvalue = value.replace('%', '');

      this.addResiduaryBequestUser.name = residualBequestDetail.name;
      this.addResiduaryBequestUser.percentage = pvalue;

      if(pvalue >= 101) {
        this.toastr.error('Error on percentage value.');
        errorVal = false;
      } 
    }

    if(errorVal){
      this.familyTreeService.editResiduaryBequestUser(residualBequestDetail.id, this.addResiduaryBequestUser).subscribe((data:ResiduaryBequestUserResponse)=>{
        if(data.error && !data.success){      
          this.toastr.error('Error on updating residual bequests user.');      
        }
        else{
          this.toastr.success('Residual bequests user updated successfully.');
        }
  
        this.getWillInfo();
      },err=>{   
        console.log(err);
        this.toastr.error('Error on updating residual bequests user.');
      });
    }
  }

  residuaryBequestNoteSave(residualBequests, event: any){
    //console.log(residualBequests);
    let value = event.target.value;
    let inputId = event.target.id;
    // console.log(value);
    // console.log(inputId);

    this.addResiduaryBequestNote = new ResidualBequests;
    this.addResiduaryBequestNote.note = value;
    
    // console.log(this.willExecutorsNoteAdd);
    this.familyTreeService.editResiduaryBequestNote(residualBequests.id, this.addResiduaryBequestNote).subscribe((data:ResiduaryBequestNoteResponse)=>{
      if(data.error && !data.success){      
        this.toastr.error('Error on updating residual bequests note.');      
      }
      else{
        this.toastr.success('Residual bequests note updated successfully.');
      }

      this.getWillInfo();
    },err=>{   
      console.log(err);
      this.toastr.error('Error on updating residual bequests note.');
    });
  }

  deleteResidualBequestUser(residuaryBequestUsers)
  {
    let id = residuaryBequestUsers.id;

    this.familyTreeService.deleteResidualBequestUser(id).subscribe((data:DeleteOtherProvisionResponse)=>{
      if(data.error && !data.success){      
        this.toastr.error("Error on deleting residual bequests user.");      
      }
      else{
        this.getWillInfo();
        this.toastr.success("Residual bequests user deleted successfully.");
      }

      //this.getProvisionInfo();
    },err=>{   
      console.log(err);
      this.toastr.error("Error on deleting residual bequests user.");
    });    
  }

}
