<div class="section-1">
    <h6> Lifetime Arrangements </h6>
  <div class="row gutter">
    <div class="col-md-6"> 
      <table class="table tablecollect">
        <thead>
          <tr>
            <th class="tc2 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs" colspan="2">Financial EPA for {{clientFirstname}}</th>
          </tr>
        </thead>
        <tbody>  
          <ng-container *ngFor="let financialEPA of willInfo.lifetimeArrangements.financialEPAs.clientFinancialEPAs; let i = index">
            <tr>
              <td class="tableleftbedit edit-border">
                <input type="text" [(ngModel)]="financialEPA.name" id="clientFinancialEPAName" (change)="financialEPASave(financialEPA, $event)" class="form-control neweditinput" placeholder="Name">
              </td>

              <td class="tableleftbedit edit-border">
                <select [(ngModel)]="financialEPA.type" id="clientFinancialEPAType" (change)="financialEPASave(financialEPA, $event)" class="form-control neweditinput select-box" placeholder="Please select" >         
                  <option value=0>Initial</option>
                  <option value=1>Substitute</option>
                  <option value=2>Joint</option>
                </select>
              </td>
              <td class="action-btn-td">
                <!-- <a href="#" class="action-btn edit_pencil"><i class="fa fa-pencil "></i></a> -->
                <a (click)="deleteFinancialEPA(financialEPA)" class="action-btn trash_depend"><i class="fa fa-trash "></i></a>
              </td>
            </tr>
          </ng-container>              
          <tr>
            <td class="tableleftbedit edit-border">
              <input type="text" id="clientFinancialEPANameAdd" class="form-control neweditinput" placeholder="Name">
            </td>
            <td class="tableleftbedit edit-border">
              <select id="clientFinancialEPATypeAdd" class="form-control neweditinput select-box" placeholder="Please select" >         
                <option value=0>Initial</option>
                <option value=1>Substitute</option>
                <option value=2>Joint</option>
              </select>
            </td>
            <td class="action-btn-td add-btn-td">
              <button class="add-btn" (click)="addFinancialEPA(willInfo.lifetimeArrangements, 1)" formtarget="_blank">Add</button>                    
            </td>  
          </tr>
        </tbody>
      </table>

      <table class="table tablecollect">
        <thead>
          <tr>
            <th class="tc2 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs" colspan="2">Medical EPA for {{clientFirstname}}</th>
          </tr>
        </thead>
        <tbody>   
          <ng-container *ngFor="let medicalEPA of willInfo.lifetimeArrangements.medicalEPAs.clientMedicalEPAs; let i = index">
            <tr>
              <td class="tableleftbedit edit-border">
                <input type="text" [(ngModel)]="medicalEPA.name" id="clientMedicalEPAName" (change)="medicalEPASave(medicalEPA, $event)" class="form-control neweditinput" placeholder="Name">
              </td>

              <td class="tableleftbedit edit-border">
                <select [(ngModel)]="medicalEPA.type" id="clientMedicalEPAType" (change)="medicalEPASave(medicalEPA, $event)" class="form-control neweditinput select-box" placeholder="Please select" >         
                  <option value=0>Initial</option>
                  <option value=1>Substitute</option>
                  <option value=2>Joint</option>
                </select>
              </td>
              <td class="action-btn-td">
                <!-- <a href="#" class="action-btn edit_pencil"><i class="fa fa-pencil "></i></a> -->
                <a (click)="deleteMedicalEPA(medicalEPA)" class="action-btn trash_depend"><i class="fa fa-trash "></i></a>
              </td>
            </tr>
          </ng-container>             
          <tr>
            <td class="tableleftbedit edit-border">
              <input type="text" id="clientMedicalEPANameAdd" class="form-control neweditinput" placeholder="Name">
            </td>

            <td class="tableleftbedit edit-border">
              <select id="clientMedicalEPATypeAdd" class="form-control neweditinput select-box" placeholder="Please select" >         
                <option value=0>Initial</option>
                  <option value=1>Substitute</option>
                  <option value=2>Joint</option>
              </select>
            </td>
            <td class="action-btn-td add-btn-td">
              <button class="add-btn" (click)="addMedicalEPA(willInfo.lifetimeArrangements, 1)" formtarget="_blank">Add</button>                    
            </td> 
          </tr>
        </tbody>
      </table>           
    </div>

    <ng-container *ngIf="partnerHas">
      <div class="col-md-6"> 
        <table class="table tablecollect">
          <thead>
            <tr>
              <th class="tc2 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs" colspan="2">Financial EPA for {{partnerFirstname}}</th>
            </tr>
          </thead>
          <tbody> 
            <ng-container *ngFor="let financialEPA of willInfo.lifetimeArrangements.financialEPAs.partnerFinancialEPAs; let i = index">
              <tr>
                <td class="tableleftbedit edit-border">
                  <input type="text" [(ngModel)]="financialEPA.name" id="partnerFinancialEPAName" (change)="financialEPASave(financialEPA, $event)" class="form-control neweditinput" placeholder="Name">
                </td>

                <td class="tableleftbedit edit-border">
                  <select [(ngModel)]="financialEPA.type" id="partnerFinancialEPAType" (change)="financialEPASave(financialEPA, $event)" class="form-control neweditinput select-box" placeholder="Please select" >         
                    <option value=0>Initial</option>
                    <option value=1>Substitute</option>
                    <option value=2>Joint</option>
                  </select>
                </td>
                <td class="action-btn-td">
                  <!-- <a href="#" class="action-btn edit_pencil"><i class="fa fa-pencil "></i></a> -->
                  <a (click)="deleteFinancialEPA(financialEPA)" class="action-btn trash_depend"><i class="fa fa-trash "></i></a>
                </td>
              </tr>
            </ng-container>               
            <tr>
              <td class="tableleftbedit edit-border">
                <input type="text" id="partnerFinancialEPANameAdd" class="form-control neweditinput" placeholder="Name">
              </td>

              <td class="tableleftbedit edit-border">
                <select id="partnerFinancialEPATypeAdd" class="form-control neweditinput select-box" placeholder="Please select" >         
                  <option value=0>Initial</option>
                  <option value=1>Substitute</option>
                  <option value=2>Joint</option>
                </select>
              </td>
              <td class="action-btn-td add-btn-td">
                <button class="add-btn" (click)="addFinancialEPA(willInfo.lifetimeArrangements, 2)" formtarget="_blank">Add</button>                    
              </td>  
            </tr>
          </tbody>
        </table>

        <table class="table tablecollect">
          <thead>
            <tr>
              <th class="tc2 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs" colspan="2">Medical EPA for {{partnerFirstname}}</th>
            </tr>
          </thead>
          <tbody>     
            <ng-container *ngFor="let medicalEPA of willInfo.lifetimeArrangements.medicalEPAs.partnerMedicalEPAs; let i = index">
              <tr>
                <td class="tableleftbedit edit-border">
                  <input type="text" [(ngModel)]="medicalEPA.name" id="partnerMedicalEPAName" (change)="medicalEPASave(medicalEPA, $event)" class="form-control neweditinput" placeholder="Name">
                </td>

                <td class="tableleftbedit edit-border">
                  <select [(ngModel)]="medicalEPA.type" id="partnerMedicalEPAType" (change)="medicalEPASave(medicalEPA, $event)" class="form-control neweditinput select-box" placeholder="Please select" >         
                    <option value=0>Initial</option>
                    <option value=1>Substitute</option>
                    <option value=2>Joint</option>
                  </select>
                </td>
                <td class="action-btn-td">
                  <!-- <a href="#" class="action-btn edit_pencil"><i class="fa fa-pencil "></i></a> -->
                  <a (click)="deleteMedicalEPA(medicalEPA)" class="action-btn trash_depend"><i class="fa fa-trash "></i></a>
                </td>
              </tr>
            </ng-container>           
            <tr>
              <td class="tableleftbedit edit-border">
                <input type="text" id="partnerMedicalEPANameAdd" class="form-control neweditinput" placeholder="Name">
              </td>

              <td class="tableleftbedit edit-border">
                <select id="partnerMedicalEPATypeAdd" class="form-control neweditinput select-box" placeholder="Please select" >         
                  <option value=0>Initial</option>
                  <option value=1>Substitute</option>
                  <option value=2>Joint</option>
                </select>
              </td>
              <td class="action-btn-td add-btn-td">
                <button class="add-btn" (click)="addMedicalEPA(willInfo.lifetimeArrangements, 2)" formtarget="_blank">Add</button>                    
              </td> 
            </tr>
          </tbody>
        </table>           
      </div>
    </ng-container>
  </div> 

  <div class="row gutter">
    <div class="col-md-12">  
      <table class="table tablecollect">
        <thead>
          <tr>
            <th class="tc2 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs">Notes</th>
          </tr>
        </thead>
        <tbody>                
          <tr>
            <td class="tableleftbedit edit-border">
              <textarea [(ngModel)]="willInfo.lifetimeArrangements.note" (change)="lifetimeArrangementsNoteSave(willInfo.lifetimeArrangements, $event)" id="lifetimeArrangementsNote" class="form-control neweditinput-1" rows="5" placeholder="Notes" style="resize: none;"></textarea>
            </td>
          </tr>
        </tbody>
      </table>  
    </div>     
  </div>
  </div>