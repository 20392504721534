import { Component, OnInit, Input } from '@angular/core';
import { ProvisionsComponent } from '../../provisions/provisions.component';
import { SharedDataService } from 'src/app/shared/service/shared-data.service';
import { InsurancesList } from 'src/app/shared/models/insurance.model';
import { ToastrService } from 'ngx-toastr';
import { ProposedInsuranceResponse, DeleteOtherProvisionResponse, ProposedInsuranceAddResponse } from 'src/app/shared/models/response.model';
import { FamilyTreeService } from 'src/app/family-tree/family-tree.service';
import { HttpErrorResponse } from '@angular/common/http';
import { GraphCalculationPipe } from 'src/app/shared/custom-pipes/graph-calculation.pipe';
import { RightsidePipe } from 'src/app/shared/custom-pipes/rightside.pipe';

@Component({
  selector: 'app-life',
  templateUrl: './life.component.html',
  styleUrls: ['../../rightside.component.css'],
  providers: [ GraphCalculationPipe, RightsidePipe ]
})
export class LifeComponent implements OnInit {

  @Input() family_tree_id: string;
  @Input() isLoaded3: boolean;
  @Input() isLoaded8: boolean;
  @Input() clientFirstname: string;
  @Input() partnerFirstname: string;
  provisionInfo: any;
  @Input() insuranceInfo: any;
  @Input() proposedInsuranceInfo: any;
  @Input() client_code: string;
  @Input() clientID: string;
  @Input() partnerID: string;
  totalDebt: number;
  deceasedPersonsSuperClient: number;
  deceasedPersonsSuperPartner: number;
  valueOfAllInvestmentsIfSoldClient: number;
  valueOfAllInvestmentsIfSoldPartner: number;
  valueOfAllInvestmentsIfSoldJoint: number;
  funeralLegalExpensesClient: number;
  funeralLegalExpensesPartner: number;
  housePurchaseClient: number;
  housePurchasePartner: number;
  totalNetImmediateNeedClient: number;
  totalNetImmediateNeedPartner: number;
  addProposedInsuranceData: any = [];

  isLoaded: boolean;
  clientAge: number;
  partnerAge: number;
  retirementAge: any = 0;
  retirementAgePartner: any = 0;
  income: number = 0;
  incomePartner: number = 0;
  yearsNeedOf: any;
  yearsNeedOfPartner: any;
  salaryOwner: number = 0;
  salaryPartner: number = 0;
  mainNodeAge:number;
  mainNodeGender:number;
  fundsRequiredForRetirement:number = 0;
  fundsRequiredForRetirementPartner:number = 0;
  survivingSpouseSuper:number = 0;
  survivingSpouseSuperPartner:number = 0;
  
  provisionsCompOneObj: ProvisionsComponent;
  partnerHas:boolean = false;
  combinedRetirementIncome:number =0;
  percentageIfPartnerDeceased:any;
  percentageIfPartnerDeceasedPartner:any;
  shortfallSavingsOfDesiredIncomeToRetirement:any =0;
  shortfallSavingsOfDesiredIncomeToRetirementPartner:any =0;
  incomeLeavePerChild:number =0;
  provisionInfoDependent:any;
  treeListFromServer:any;
  totalChildCare:number=0;
  totalProgressiveNeed:number =0;
  totalProgressiveNeedPartner:number =0;
  incomeReplacement:number =0;
  incomeReplacementPartner:number =0;
  valueOfAllInvestmentsIfSold:number =0;
  totalInsuranceRecommendedClient:number =0;
  totalInsuranceRecommendedPartner:number =0;
  hasLifeClient:boolean =false;
  hasLifePartner:boolean =false;
  totalInsuranceRecommendedClientInput:number =0
  totalInsuranceRecommendedPartnerInput:number =0;
  passiveIncomeForSpouse:number =0;
  passiveIncomeForSpousePartner:number =0;

  constructor(
    private sharedDataService: SharedDataService,
    private toastr: ToastrService,
    private familyTreeService :FamilyTreeService,
    private graphCalculation: GraphCalculationPipe,
    private rightsideCalculation: RightsidePipe,
    ) { 
    this.sharedDataService.totalDebt.subscribe(totalDebt => this.totalDebt = totalDebt);
    this.sharedDataService.deceasedPersonsSuperClient.subscribe(deceasedPersonsSuperClient => this.deceasedPersonsSuperClient = deceasedPersonsSuperClient);
    this.sharedDataService.deceasedPersonsSuperPartner.subscribe(deceasedPersonsSuperPartner => this.deceasedPersonsSuperPartner = deceasedPersonsSuperPartner);
    this.sharedDataService.valueOfAllInvestmentsIfSoldClient.subscribe(valueOfAllInvestmentsIfSoldClient => this.valueOfAllInvestmentsIfSoldClient = valueOfAllInvestmentsIfSoldClient);
    this.sharedDataService.valueOfAllInvestmentsIfSoldPartner.subscribe(valueOfAllInvestmentsIfSoldPartner => this.valueOfAllInvestmentsIfSoldPartner = valueOfAllInvestmentsIfSoldPartner);
    this.sharedDataService.valueOfAllInvestmentsIfSoldJoint.subscribe(valueOfAllInvestmentsIfSoldJoint => this.valueOfAllInvestmentsIfSoldJoint = valueOfAllInvestmentsIfSoldJoint);
    this.sharedDataService.funeralLegalExpensesClient.subscribe(funeralLegalExpensesClient => this.funeralLegalExpensesClient = funeralLegalExpensesClient);
    this.sharedDataService.funeralLegalExpensesPartner.subscribe(funeralLegalExpensesPartner => this.funeralLegalExpensesPartner = funeralLegalExpensesPartner);
    this.sharedDataService.housePurchaseClient.subscribe(housePurchaseClient => this.housePurchaseClient = housePurchaseClient);
    this.sharedDataService.housePurchasePartner.subscribe(housePurchasePartner => this.housePurchasePartner = housePurchasePartner);
    this.sharedDataService.totalNetImmediateNeedClient.subscribe(totalNetImmediateNeedClient => this.totalNetImmediateNeedClient = totalNetImmediateNeedClient);
    this.sharedDataService.clientAge.subscribe(clientAge => this.clientAge = clientAge);
    this.sharedDataService.totalNetImmediateNeedPartner.subscribe(totalNetImmediateNeedPartner => this.totalNetImmediateNeedPartner = totalNetImmediateNeedPartner);
    this.sharedDataService.partnerAge.subscribe(partnerAge => this.partnerAge = partnerAge);
    this.sharedDataService.income.subscribe(income => this.income = income);
    this.sharedDataService.incomePartner.subscribe(incomePartner => this.incomePartner = incomePartner);
    this.sharedDataService.yearsNeedOf.subscribe(yearsNeedOf => this.yearsNeedOf = yearsNeedOf);
    this.sharedDataService.yearsNeedOfPartner.subscribe(yearsNeedOfPartner => this.yearsNeedOfPartner = yearsNeedOfPartner);
    this.sharedDataService.salaryOwner.subscribe(salaryOwner => this.salaryOwner = salaryOwner);
    this.sharedDataService.salaryPartner.subscribe(salaryPartner => this.salaryPartner = salaryPartner);
    this.sharedDataService.mainNodeAge.subscribe(mainNodeAge => this.mainNodeAge = mainNodeAge);
    this.sharedDataService.mainNodeGender.subscribe(mainNodeGender => this.mainNodeGender = mainNodeGender);
    this.sharedDataService.partnerHas.subscribe(partnerHas => this.partnerHas = partnerHas);
    this.sharedDataService.combinedRetirementIncome.subscribe(combinedRetirementIncome => this.combinedRetirementIncome = combinedRetirementIncome);
    this.sharedDataService.percentageIfPartnerDeceased.subscribe(percentageIfPartnerDeceased => this.percentageIfPartnerDeceased = percentageIfPartnerDeceased);
    this.sharedDataService.percentageIfPartnerDeceasedPartner.subscribe(percentageIfPartnerDeceasedPartner => this.percentageIfPartnerDeceasedPartner = percentageIfPartnerDeceasedPartner);
    this.sharedDataService.retirementAge.subscribe(retirementAge => this.retirementAge = retirementAge);
    this.sharedDataService.retirementAgePartner.subscribe(retirementAgePartner => this.retirementAgePartner = retirementAgePartner);
    this.sharedDataService.incomeLeavePerChild.subscribe(incomeLeavePerChild => this.incomeLeavePerChild = incomeLeavePerChild);
    this.sharedDataService.provisionInfoDependent.subscribe(provisionInfoDependent => this.provisionInfoDependent = provisionInfoDependent);
    this.sharedDataService.treeListFromServer.subscribe(treeListFromServer => this.treeListFromServer = treeListFromServer);
    this.sharedDataService.passiveIncomeForSpouse.subscribe(passiveIncomeForSpouse => this.passiveIncomeForSpouse = passiveIncomeForSpouse);
    this.sharedDataService.passiveIncomeForSpousePartner.subscribe(passiveIncomeForSpousePartner => this.passiveIncomeForSpousePartner = passiveIncomeForSpousePartner);
    
  }

  ngOnInit(): void {
    // this.provisionsCompOneObj.getProvisionInfo();
    // setInterval(()=> { this.calTotalChildCare(); }, 3 * 1000);
    
    // this.incomeReplacement = this.incomePartner * (70/100)*this.yearsNeedOfPartner;
    this.sharedDataService.totalChildCare.subscribe(totalChildCare => this.totalChildCare = totalChildCare);
    this.sharedDataService.totalInsuranceRecommendedClient.subscribe(totalInsuranceRecommendedClient => this.totalInsuranceRecommendedClient = totalInsuranceRecommendedClient);
    this.sharedDataService.totalInsuranceRecommendedPartner.subscribe(totalInsuranceRecommendedPartner => this.totalInsuranceRecommendedPartner = totalInsuranceRecommendedPartner);
    
    this.isLoaded= true;
  }

  ngOnChanges () : void {
    this.provisionInfoDependent = this.provisionInfoDependent;
    this.treeListFromServer = this.treeListFromServer;
    this.incomeLeavePerChild = this.incomeLeavePerChild;
    this.totalDebt = this.totalDebt;
    this.funeralLegalExpensesClient = this.funeralLegalExpensesClient;
    this.housePurchaseClient = this.housePurchaseClient;
    this.incomePartner = this.incomePartner;
    this.yearsNeedOfPartner = this.yearsNeedOfPartner;
    this.funeralLegalExpensesPartner = this.funeralLegalExpensesPartner;
    this.housePurchasePartner = this.housePurchasePartner;
    this.deceasedPersonsSuperClient = this.deceasedPersonsSuperClient;
    this.deceasedPersonsSuperPartner = this.deceasedPersonsSuperPartner;
    this.valueOfAllInvestmentsIfSoldClient = this.valueOfAllInvestmentsIfSoldClient;
    this.valueOfAllInvestmentsIfSoldPartner = this.valueOfAllInvestmentsIfSoldPartner;
    this.valueOfAllInvestmentsIfSoldJoint = this.valueOfAllInvestmentsIfSoldJoint;
    this.clientID = this.clientID;
    this.partnerID = this.partnerID;
    this.totalProgressiveNeed = this.totalProgressiveNeed;
    this.totalProgressiveNeedPartner = this.totalProgressiveNeedPartner;
    this.combinedRetirementIncome = this.combinedRetirementIncome;
    this.passiveIncomeForSpouse = this.passiveIncomeForSpouse;
    this.passiveIncomeForSpousePartner = this.passiveIncomeForSpousePartner;

    this.incomeReplacement = this.incomePartner * (70/100)*this.yearsNeedOfPartner;
    this.incomeReplacementPartner = this.income * (70/100)*this.yearsNeedOf;
    this.valueOfAllInvestmentsIfSold = this.valueOfAllInvestmentsIfSoldClient + this.valueOfAllInvestmentsIfSoldPartner + this.valueOfAllInvestmentsIfSoldJoint;
    this.totalNetImmediateNeedClient = this.totalDebt + this.funeralLegalExpensesClient + this.housePurchaseClient + this.incomeReplacement - (this.deceasedPersonsSuperClient + this.valueOfAllInvestmentsIfSoldClient + this.valueOfAllInvestmentsIfSoldPartner + this.valueOfAllInvestmentsIfSoldJoint);
    this.totalNetImmediateNeedPartner = this.totalDebt + this.funeralLegalExpensesPartner + this.housePurchasePartner + this.incomeReplacementPartner - (this.deceasedPersonsSuperPartner + this.valueOfAllInvestmentsIfSoldClient + this.valueOfAllInvestmentsIfSoldPartner + this.valueOfAllInvestmentsIfSoldJoint);
    
    this.totalInsuranceRecommendedClient = (Math.round((this.totalNetImmediateNeedClient + this.totalProgressiveNeed)/10000))*10000;
    this.totalInsuranceRecommendedPartner = (Math.round((this.totalNetImmediateNeedPartner + this.totalProgressiveNeedPartner)/10000))*10000;
    
    this.sharedDataService.changTotalInsuranceRecommendedClient(this.totalInsuranceRecommendedClient)
    this.sharedDataService.changTotalInsuranceRecommendedPartner(this.totalInsuranceRecommendedPartner)

    if(this.totalInsuranceRecommendedClient > 0) {
      this.totalInsuranceRecommendedClientInput = this.totalInsuranceRecommendedClient;
    } else {
      this.totalInsuranceRecommendedClientInput = 0;
    }
    if(this.totalInsuranceRecommendedPartner > 0) {
      this.totalInsuranceRecommendedPartnerInput = this.totalInsuranceRecommendedPartner;
    } else {
      this.totalInsuranceRecommendedPartnerInput = 0;
    }
    
    if (this.provisionInfoDependent.length >0 && this.treeListFromServer.length >0){
      this.calTotalChildCare();
    }
    this.totalProgressiveNeedCall();

    if(this.clientID) {
      this.checkIfInsuranceTypeHas();
    }

    this.survivingSpouseSuperCal()
    this.fundsRequiredForRetirementCal()
    this.ShortfallSavingsOfDesiredIncomeToRetirement();
    
    this.sharedDataService.totalChildCare.subscribe(totalChildCare => this.totalChildCare = totalChildCare);
    setTimeout(() => {
      this.setProgressiveNeedsData();
    }, 2500);
  }

  addProposedInsurance(proposedInsuranceInfo, tabType, saveType) {
    this.addProposedInsuranceData = new InsurancesList;

    this.addProposedInsuranceData.familyTreeId = proposedInsuranceInfo.familyTreeId;
    this.addProposedInsuranceData.clientCode = this.client_code;
    

    if(tabType == 'life' && saveType == 'addNew') {
      this.addProposedInsuranceData.insuranceCoverType = 1;
      this.addProposedInsuranceData.lifeInsured = ((document.getElementById("lifeInsuredAddLife") as HTMLInputElement).value);
      this.addProposedInsuranceData.underwriter = ((document.getElementById("insurerAddLife") as HTMLInputElement).value);
      this.addProposedInsuranceData.lifeSum = ((document.getElementById("lifeSumAddLife") as HTMLInputElement).value);
      this.addProposedInsuranceData.monthlyPremium = ((document.getElementById("monthlyPremiumAddLife") as HTMLInputElement).value);
      this.addProposedInsuranceData.structure = ((document.getElementById("structureAddLife") as HTMLInputElement).value);
      this.addProposedInsuranceData.fundingMechanism = ((document.getElementById("fundingMechanismAddLife") as HTMLInputElement).value);
    } else if(tabType == 'life' && saveType == 'defaultClient') {
      this.addProposedInsuranceData.insuranceCoverType = 1;
      this.addProposedInsuranceData.lifeInsured = ((document.getElementById("lifeInsuredAddLifeClient") as HTMLInputElement).value);
      this.addProposedInsuranceData.underwriter = ((document.getElementById("insurerAddLifeClient") as HTMLInputElement).value);
      this.addProposedInsuranceData.lifeSum = ((document.getElementById("lifeSumAddLifeClient") as HTMLInputElement).value);
      this.addProposedInsuranceData.monthlyPremium = ((document.getElementById("monthlyPremiumAddLifeClient") as HTMLInputElement).value);
      this.addProposedInsuranceData.structure = ((document.getElementById("structureAddLifeClient") as HTMLInputElement).value);
      this.addProposedInsuranceData.fundingMechanism = ((document.getElementById("fundingMechanismAddLifeClient") as HTMLInputElement).value);
    } else if(tabType == 'life' && saveType == 'defaultPartner') {
      this.addProposedInsuranceData.insuranceCoverType = 1;
      this.addProposedInsuranceData.lifeInsured = ((document.getElementById("lifeInsuredAddLifePartner") as HTMLInputElement).value);
      this.addProposedInsuranceData.underwriter = ((document.getElementById("insurerAddLifePartner") as HTMLInputElement).value);
      this.addProposedInsuranceData.lifeSum = ((document.getElementById("lifeSumAddLifePartner") as HTMLInputElement).value);
      this.addProposedInsuranceData.monthlyPremium = ((document.getElementById("monthlyPremiumAddLifePartner") as HTMLInputElement).value);
      this.addProposedInsuranceData.structure = ((document.getElementById("structureAddLifePartner") as HTMLInputElement).value);
      this.addProposedInsuranceData.fundingMechanism = ((document.getElementById("fundingMechanismAddLifePartner") as HTMLInputElement).value);
    }
    
    // this.addProposedInsuranceData.insuranceCoverType = ((document.getElementById("insuranceCoverTypeAdd") as HTMLInputElement).value);
    let tabTypeUpper = tabType.toUpperCase();
    if(tabType == 'income') {
      if(this.addProposedInsuranceData.policyOwner == '') {
        this.toastr.error('Error on adding proposed '+tabTypeUpper+'.'); // 'Error on policy owner.'
      } else {
        this.proposedInsuranceAdd(this.addProposedInsuranceData, tabType);
      }
    }

    if(tabType == 'life' || tabType == 'tpd' || tabType == 'trauma') {
      if(this.addProposedInsuranceData.lifeInsured == '') {
        this.toastr.error('Error on adding proposed '+tabTypeUpper+'.'); // 'Error on life insured.'
      } else {
        this.proposedInsuranceAdd(this.addProposedInsuranceData, tabType);
      }
    }
  }

  removeComma(value) {
    if(value == 'null' || value =='') return; // locale returns string of NaN if fail
    return value = value.replace(/,/g, ""); // remove commas
  }

  proposedInsuranceAdd(addProposedInsuranceData, tabType) {
    let tabTypeUpper = tabType.toUpperCase();
    this.familyTreeService.addProposedInsurance(this.addProposedInsuranceData.clientCode, this.addProposedInsuranceData.familyTreeId, addProposedInsuranceData).subscribe((data:ProposedInsuranceAddResponse)=>{
      if(data.error && !data.success){      
        this.toastr.error('Error on adding proposed '+tabTypeUpper+'.');      
      }
      else{
        this.toastr.success('Proposed '+tabTypeUpper+' added successfully.');

        if(tabType == 'life') {
          ((document.getElementById("lifeInsuredAddLife") as HTMLInputElement).value='');
          ((document.getElementById("insurerAddLife") as HTMLInputElement).value='');
          ((document.getElementById("lifeSumAddLife") as HTMLInputElement).value='');
          ((document.getElementById("monthlyPremiumAddLife") as HTMLInputElement).value='');
          ((document.getElementById("structureAddLife") as HTMLInputElement).value='');
          ((document.getElementById("fundingMechanismAddLife") as HTMLInputElement).value='');
        }
      }

      this.getProposedInsuranceInfo();
    },err=>{   
      console.log(err);
      this.toastr.error('Error on adding proposed '+tabTypeUpper+'.');
    });
  }

  getProposedInsuranceInfo() {
    this.familyTreeService.getProposedInsurance(this.family_tree_id).subscribe((proposedInsuranceData:ProposedInsuranceResponse)=>{
      if(proposedInsuranceData.success){
        this.proposedInsuranceInfo = proposedInsuranceData.response;
        
        this.checkIfInsuranceTypeHas();
        this.isLoaded8 = true;
      } 
      // this.ngxService.stop(); 
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
    });
  }


  deleteProposedInsurance(proposedInsurance) {
    let id = proposedInsurance.id;

    this.familyTreeService.deleteProposedInsurance(id).subscribe((data:DeleteOtherProvisionResponse)=>{
      if(data.error && !data.success){      
        this.toastr.error("Error on deleting proposed insurance.");      
      }
      else{
        this.getProposedInsuranceInfo();
        this.toastr.success("Proposed insurance deleted successfully.");
      }
    },err=>{   
      console.log(err);
      this.toastr.error("Error on deleting proposed insurance.");
    });    
  }

  proposedInsuranceSave(proposedInsurance, event: any){
    // console.log(spouseDeathOrTPDS);
    let value = event.target.value;
    let inputId = event.target.id;
    // console.log(value);
    // console.log(inputId);

    this.addProposedInsuranceData = new InsurancesList;
    this.addProposedInsuranceData.insuranceCoverType = proposedInsurance.insuranceCoverType;
    this.addProposedInsuranceData.lifeInsured = proposedInsurance.lifeInsured;
    this.addProposedInsuranceData.underwriter = proposedInsurance.underwriter;
    this.addProposedInsuranceData.lifeSum = proposedInsurance.lifeSum;
    this.addProposedInsuranceData.monthlyPremium = proposedInsurance.monthlyPremium;
    this.addProposedInsuranceData.structure = proposedInsurance.structure;
    this.addProposedInsuranceData.fundingMechanism = proposedInsurance.fundingMechanism;

    // this.willExecutorsAdd.clientCode = (userType==1)?this.client_code:this.facIdPartner;
    //this.willExecutorsAdd.clientCode = proposedInsurance.clientCode;  // this.client_code

    if(inputId == 'insuranceCoverType') {
      this.addProposedInsuranceData.insuranceCoverType = value;
    }
    if(inputId == 'lifeInsured') {
      this.addProposedInsuranceData.lifeInsured = value;
    }
    if(inputId == 'underwriter') {
      this.addProposedInsuranceData.underwriter = value;
    }
    if(inputId == 'lifeSum') {
      this.addProposedInsuranceData.lifeSum = value;
    }
    if(inputId == 'monthlyPremium') {
      this.addProposedInsuranceData.monthlyPremium = value;
    }
    if(inputId == 'structure') {
      this.addProposedInsuranceData.structure = value;
    }
    if(inputId == 'fundingMechanism') {
      this.addProposedInsuranceData.fundingMechanism = value;
    }
    
    // console.log(this.addProposedInsuranceData);
    this.familyTreeService.editProposedInsurance(proposedInsurance.id, this.addProposedInsuranceData).subscribe((data:ProposedInsuranceAddResponse)=>{
      if(data.error && !data.success){      
        this.toastr.error('Error on updating proposed insurance.');      
      }
      else{
        this.toastr.success('Proposed insurance updated successfully.');
      }

      this.getProposedInsuranceInfo();
    },err=>{   
      console.log(err);
      this.toastr.error('Error on updating proposed insurance.');
    });
  }

  checkIfInsuranceTypeHas(){
      var clientID = this.clientID;
      var partnerID = this.partnerID;

      if(this.proposedInsuranceInfo){
        var isPresent1 = false;
        var isPresent2 = false;
        
        if(this.proposedInsuranceInfo.clientInsurances.length > 0) {
          var isCoverType = this.proposedInsuranceInfo.clientInsurances.some(function(el){ return el.insuranceCoverType === 1 });
          var target =  this.proposedInsuranceInfo.clientInsurances.filter(function(el) { return el.insuranceCoverType == 1;});
          if (isCoverType) {
            
            var isClient = target.some(function(el){ return el.lifeInsured === clientID });
            var isPresent = target.some(function(el){ return el.lifeInsured === partnerID });
            if (isClient) {
              isPresent1 = true;
            } else {
              isPresent1 = false;
            }
            if (isPresent) {
              isPresent2 = true;
            } else {
              isPresent2 = false;
            }
          } else {
            isPresent1 = false;
            isPresent2 = false;
          }
        }
  
        if(this.proposedInsuranceInfo.partnerInsurances.length > 0) {
          var isCoverType = this.proposedInsuranceInfo.partnerInsurances.some(function(el){ return el.insuranceCoverType === 1 });
          var target =  this.proposedInsuranceInfo.partnerInsurances.filter(function(el) { return el.insuranceCoverType == 1;});

          if (isCoverType) {
            var isClient = target.some(function(el){ return el.lifeInsured === clientID });
            var isPresent = target.some(function(el){ return el.lifeInsured === partnerID });
            if (isClient) {
              isPresent1 = true;
            } else {
              isPresent1 = false;
            }
            if (isPresent) {
              isPresent2 = true;
            } else {
              isPresent2 = false;
            }
          } else {
            isPresent1 = false;
            isPresent2 = false;
          }
        }

        if(isPresent1) {
          this.hasLifeClient = true;
        } else {
          this.hasLifeClient = false;
        }

        if(isPresent2) {
          this.hasLifePartner = true;
        } else {
          this.hasLifePartner = false;
        }
      }
  }

  fundsRequiredForRetirementCal(){
    let E6 = this.partnerAge+1  // Age Next Birthday
    let D6 = this.mainNodeAge+1  // Age Next Birthday
    // let D7 = this.mainNodeGender
    // // let E7 = 
    // // E9 = IF(E6=0,0,IF(E7="Male", VLOOKUP(D6, Assumptions!B16:D116,2),VLOOKUP(E6,Assumptions!B16:D116,3)))
    // // D9 = IF(D7="Male",VLOOKUP(D6,Assumptions!B16:D116,2),VLOOKUP(D6,Assumptions!B16:D116,3))
    let E9 = this.rightsideCalculation.getLifeExpetancy(D6,this.mainNodeGender); // 46.8
    let D9 = this.rightsideCalculation.getLifeExpetancy(D6,this.mainNodeGender); // 46.8
    let D10 = 5 // Life expectacy + yr
    let E10 = D10

    let E5 = 'Client 2' ;
    let D13 = 1.5/100 // 1.5%
    let Y39 = D13
    let AD38 = Y39
    let D12 = 6.8/100
    let Y38 = D12
    let AD37 = Y38
    let O13 = D6
    let D8 = this.retirementAge  // Desired Retirement Age 60
    let O14 = D8
    let Y27 = O14 - O13
    let P13 = E6
    let E8 = D8
    let P14 = E8
    
    let Z25 = E6
    let Z27
    if(Z25 == 0){
      Z27 = Y27
    } else {
      Z27 = P14 - P13
    }

    let Z47 = Z27
    let Z39 = Y39 
    let O17 = this.passiveIncomeForSpouse // What Passive income would continue to be paid to your spouse/partner in event of your Death? // Client
    let Z50 = O17
    let P16 = 67/100 // 67%   What percentage of this income if your partner was deceased?
    let P15 = this.combinedRetirementIncome // What is your combined income need at retirement?  // partner
    let Z36 = P15*P16
    let Z48 = Z36
    let AG41 = 1+AD37
    let AF41 = 1+AD38
    let AH41 = AF41/AG41
    let Z26 = E9+E10 // E9 + 5 // 5 = E10
    let Z28 = Z26-Z27
    let AD39 = Z28
    let AI41 = Math.pow(AH41, AD39) // AH41^AD39
    let AJ41 = 1-AI41
    let AD41 = AJ41

    let f
    let R17 = 'Lifetime' // Lifetime, Age 65
    if(R17 == 'lifetime') {
      f = Z50 
    } else {
      f = 0
    }

    let g = (1+Z39)
    let h = Math.pow(g, Z47)
    let AD36 = (Z48 - f) * h // (Z48 - IF(R17=='lifetime', Z50, 0))*(1+Z39)^Z47)
    let AD40 = AD36/(AD37-AD38)
    let AD42 = AD40*AD41
    let AD43 = AD42*(1+AD37)
    
    let e = (1+AD37)
    let d =Math.pow(e, Z27)
    let AD44 = AD43/ d  // AD43/(1+AD37)^Z27
    let O44
    if(E5 == 'client2'){  // (E5 == 'client2')
      O44 = 0
    } else {
      O44 = AD44
    }

    let Y26

    // IF(E5="client2", 0, D9+D10)
    if(E5=="client2") {
      Y26 = 0
    } else {
      Y26 = D9+D10
    }
    let Y28 = Y26-Y27
    let AD61 = Y28
    let AD60 = Y39
    let AD59 = Y38
    let AG63 = 1+AD59
    let AF63 = 1+AD60
    let AH63 = AF63/AG63
    let AI63 = Math.pow(AH63, AD61)
    let AJ63 = 1-AI63
    let AD63 = AJ63
    
    let Y47 = Y27
    let P17 = this.passiveIncomeForSpousePartner // What Passive income would continue to be paid to your spouse/partner in event of your Death?  // Partner
    let Y50 = P17
    let O16 = 67/100 // What percentage of this income if your partner was deceased?  // Client
    let O15 = this.combinedRetirementIncome // What is your combined income need at retirement?  // Client
    let Y36 = O15*O16
    let Y48 = Y36
    
    let i

    let S17 = 'Lifetime'; // Lifetime, Age65
    // IF(S17="lifetime",Y50,0)
    if(S17=="lifetime") {
      i = Y50
    } else {
      i = 0
    }

    let j = (1+Y39)
    let k = Math.pow(j, Y47)
    let AD58 = (Y48-i)*k
    
    let AD62 = AD58/(AD59-AD60)
    let AD64 = AD62*AD63
    let AD65 = AD64*(1+AD59)
    let l = (1+AD59)
    let m = Math.pow(l, Y27)
    let AD66 = AD65/ m // (1+AD59)^Y27
    let P44
    
    if(E5 == 'client2'){  // (E5 == 'client2')
      P44 = O44
    } else {
      P44 = AD66
    }

    this.fundsRequiredForRetirement = Math.round(O44);
    this.fundsRequiredForRetirementPartner = Math.round(P44);
  }

  survivingSpouseSuperCal() {
    // O45 = IF(E5='client2',0,-P37)  ----Client
    let P6 = this.deceasedPersonsSuperPartner; 
    let O45
    let P37 = P6
    let E5 = 'Client 2'
    if(E5 == 'client2'){ //if(Client)  E5 == 'client2' this.partnerHas
      O45 = 0
    } else {
      O45 = -P37
    }
    this.survivingSpouseSuper = O45

    // P45 = IF(E5='client2',0,-O37)  ----Partner
    let O6 = this.deceasedPersonsSuperClient;
    let P45
    let O37 = O6
    if(E5 == 'client2'){ //if(Partner)  (E5_p == 'client2')
      P45 = 0
    } else {
      P45 = -O37
    }
    this.survivingSpouseSuperPartner = P45
  }

  ShortfallSavingsOfDesiredIncomeToRetirement(){
    let D13 = 1.5/100;
    let Y39 = D13
    let AD27 = Y39;
    let D12 = 6.8/100; // 6.8%
    let Y38 = D12 
    let AD26 = Y38;
    console.log('mainNodeAge',this.mainNodeAge)
    let D6 = this.mainNodeAge+1   // Age Next Birthday
    console.log('yearsNeedOfPartner',this.yearsNeedOfPartner)
    let P11 = this.yearsNeedOfPartner;
    let Z52 = P11;

    let O13 = D6
    console.log('retirementAge',this.retirementAge)
    let D8 = this.retirementAge // Desired Retirement Age 60
    let O14 = D8
    let Y27 = O14-O13
    let E6 = this.partnerAge+1 // D6
    let Z25 = E6
    let E8 = D8
    let P13 = E6
    let P14 = E8
    let Z27

    // IF(Z25=0,Y27, P14-P13)
    if(Z25 == 0) {
      Z27 = Y27
    } else {
      Z27 = P14-P13
    }
  
    let Z47 = Z27
    let AD28 = Z47-Z52
    let AG30 = 1+AD26
    let AF30 = 1+AD27
    let AH30 = AF30/AG30
    let AI30 = Math.pow(AH30, AD28) // AH30^AD28
    let AJ30 = 1-AI30;
    let AD30 = AJ30
    console.log('passiveIncomeForSpouse',this.passiveIncomeForSpouse)
    let O17 = this.passiveIncomeForSpouse // What Passive income would continue to be paid to your spouse/partner in event of your Death?  // Client  // this.incomePartner*this.percentageIfPartnerDeceasedPartner/100
    let Z50 = O17;
    console.log('incomePartner',this.incomePartner)
    let P9 = this.incomePartner; // What is your current annual gross income/drawings? // Partner
    let P12 = 75/100
    let Z49 = P9*P12*0.7; // P9*P12*0.7
    let P16 = 67/100; //67%
    console.log('combinedRetirementIncome',this.combinedRetirementIncome)
    let P15 = this.combinedRetirementIncome; // What is your combined income need at retirement?  // Partner
    let Z36 = P15*P16
    let Z48 = Z36
    let Z51 = -(Z48-Z49-Z50)
    let a = (1+AD27)
    let d = Math.pow(a, Z52)
    let AD25 = -Z51*d // -Z51*(1+AD27)^Z52
    let AD29 = AD25/(AD26-AD27)
    let AD31 = AD29*AD30
    let AD32 = AD31*(1+AD26)
    let b = (1+AD26)
    let e = Math.pow(b, Z52)
    let AD33 = AD32/e // AD32/(1+AD26)^Z52
    let AE30 = AD30
    let Z39 = Y39
    let AE27 = Z39
    let Z38 = Y38
    let AE26 = Z38
    let AE25

    // IF(AD25<0,AD25*0.5,0)
    if(AD25<0){
      AE25 = AD25*0.5;
    } else {
      AE25 = 0;
    }

    let AE29 = AE25/(AE26-AE27)
    let AE31 = AE29*AE30
    let c = (1+AE26)
    let g = Math.pow(c, Z52)
    let AE33 = AE31/g // AE31/(1+AE26)^Z52
    


    //==================

    let O11 = this.yearsNeedOf // If your spouse were to pass away/become TPD many years off work would you want?
    let Y52 = O11
    let AD49 = Y39
    let AD48 = Y38
    let P17 = this.passiveIncomeForSpousePartner // What Passive income would continue to be paid to your spouse/partner in event of your Death?
    let Y50 = P17
    let O12 = 75/100 // 75%
    let O9 = this.income // What is your current annual gross income/drawings?  // Client
    let Y49 = O9*O12*0.7
    let O16 = 67/100 // 67%
    let O15 = this.combinedRetirementIncome // What is your combined income need at retirement?  // Client
    let Y36 = O15*O16
    let Y48 = Y36
    let Y51 = -(Y48-Y49-Y50)
    let j = (1+AD49)
    let k = Math.pow(j, Y52) // (1+AD49)^Y52
    let AD47 = -Y51*k
    let AD51 = AD47/(AD48-AD49)

    let h = (1+AD48)
    let i = Math.pow(h, Y52) // (1+AD48)^Y52

    let Y47 = Y27
    let AD50 = Y47-Y52
    let AG52 = 1+AD48
    let AF52 = 1+AD49
    let AH52 = AF52/AG52
    let AI52 = Math.pow(AH52, AD50) // AH52^AD50
    let AJ52 = 1-AI52
    let AD52 = AJ52
    let AD53 = AD51*AD52
    let AD54 = AD53*(1+AD48)
    let AD55

    // IF(AD51<0, AD53, AD54/(1+AD48)^Y52)
    if(AD51<0) {
      AD55 = AD53
    } else {
      AD55 = AD54/i
    }

    let AE47

    // IF(AD47<0,AD47*0.5,0)
    if(AD47<0) {
      AE47 = AD47*0.5
    } else {
      AE47 = 0
    }

    let AE49 = Z39
    let AE52 = AD52
    let AE48 = Z38
    let AE51 = AE47/(AE48-AE49)
    let AE53 = AE51*AE52
    let AE55 = AE53/(1+AE48)^Y52

    // O46 = IF(E5 = "client2", 0, MAX(AD33:AE33)) --Client
    // P46 = IF(E5 = "client2", O46, MAX(AD55:AE55)) --Partner

    let E5 = 'Client 2'
    if(E5 == 'client2'){ //if(Client) this.partnerHas
      this.shortfallSavingsOfDesiredIncomeToRetirement = 0;  // O46
    } else {
      this.shortfallSavingsOfDesiredIncomeToRetirement = Math.max(AD33, AE33);  // O46
    }
    
    if(E5 == 'client2'){ //if(Partner) this.partnerHas (E5_p == 'client2')
      this.shortfallSavingsOfDesiredIncomeToRetirementPartner =  this.shortfallSavingsOfDesiredIncomeToRetirement // P46
    } else {
      this.shortfallSavingsOfDesiredIncomeToRetirementPartner = Math.max(AD55, AE55)// P46
    }

  }

  calTotalChildCare() {
    this.totalChildCare =0;
    if(this.provisionInfoDependent.length >0){
      for(let i=0;i< this.provisionInfoDependent.length;i++)
      {
        
        for(let j=0;j< this.treeListFromServer.length;j++)
        {
          if(this.treeListFromServer[j].id == this.provisionInfoDependent[i].dependentId) {
            var timeDiff = Math.abs(Date.now() - new Date(this.treeListFromServer[j].dateOfBirth).getTime());
            let age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25); // + 1
            let dependantAge = this.provisionInfoDependent[i].age; 
            let perYear = this.provisionInfoDependent[i].yearAmount;
            if(this.provisionInfoDependent[i].age ==0) {
              dependantAge = 21
            }
            let childCareAmount = this.childCare(age, dependantAge, perYear)
            this.totalChildCare = this.totalChildCare + childCareAmount;

            this.sharedDataService.changTotalChildCare(this.totalChildCare);
          }
        }
      }
    }
  }

  childCare(currentAge, dependantAge, perYear) {
    
    let a = 0.015 // 1.5% $Z$39
    let b = 0.068 // 6.8% $Z$38
    let X12 = a
    let X11 = b
    let c = dependantAge //provisions- dependant age // $0$21
    let X8 = currentAge //currnt age of child 1
    let X9 // IF(X8=0,0,$0$21-X8)
    if(X8 == 0) {
      X9 = 0
    } else {
      X9 = c-X8
    }

    let X13 = X9
    let X16 = 1+X11
    let X15 = 1+X12
    let X17 = X15/X16
    let X18 = Math.pow(X17, X13) // X17^X13
    let X19 = 1-X18
    let d = this.incomeLeavePerChild // perYear // income leave per child (Provisions- per year) // $O$24
    let X10 //IF(X9>0, $O$24,0)
    if(X9>0){
      X10 = d
    } else {
      X10 = 0
    }
    let X14 = X10/(X11-X12)
    let X20 = X14*X19
    let X21 = X20*(1+X11)
    let AB22 = X21 // SUM(X21:AB21)
    return Math.round(AB22)
    // O47 = AB22
  }

  totalProgressiveNeedCall() {
    this.totalProgressiveNeed = this.totalChildCare + this.shortfallSavingsOfDesiredIncomeToRetirement + this.survivingSpouseSuper + this.fundsRequiredForRetirement
    if(this.totalProgressiveNeed <0){
      this.totalProgressiveNeed =0;
    }

    this.totalProgressiveNeedPartner = this.totalChildCare + this.shortfallSavingsOfDesiredIncomeToRetirementPartner + this.survivingSpouseSuperPartner + this.fundsRequiredForRetirementPartner
    if(this.totalProgressiveNeedPartner <0){
      this.totalProgressiveNeedPartner =0;
    }
  }

  private setProgressiveNeedsData() {
    const progressiveNeedsLifeModelData = {
      FundsRequiredForRetirementClient : this.fundsRequiredForRetirement,
      FundsRequiredForRetirementPartner : this.fundsRequiredForRetirementPartner,
      ProgressiveNeedsLifeModelSurvivingSpouseSuperClient : this.survivingSpouseSuper,
      ProgressiveNeedsLifeModelSurvivingSpouseSuperPartner : this.survivingSpouseSuperPartner,
      ProgressiveNeedsLifeModelShortfallSavingsOfDesiredIncomeToRetirementClient : this.shortfallSavingsOfDesiredIncomeToRetirement,
      ProgressiveNeedsLifeModelShortfallSavingsOfDesiredIncomeToRetirementPartner : this.shortfallSavingsOfDesiredIncomeToRetirementPartner,
      ProgressiveNeedsLifeModelChildcareClient : this.totalChildCare,
      ProgressiveNeedsLifeModelChildcarePartner : this.totalChildCare,
      ProgressiveNeedsLifeModelTotalProgressiveNeedClient : this.totalProgressiveNeed,
      ProgressiveNeedsLifeModelTotalProgressiveNeedPartner : this.totalProgressiveNeedPartner,
      ProgressiveNeedsLifeModelTotalInsuranceRecommendedClient : this.totalInsuranceRecommendedClient,
      ProgressiveNeedsLifeModelTotalInsuranceRecommendedPartner : this.totalInsuranceRecommendedPartner,
      ProgressiveNeedsLifeModeltotalNetImmediateNeedClient : this.totalNetImmediateNeedClient,
      ProgressiveNeedsLifeModeltotalNetImmediateNeedPartner : this.totalNetImmediateNeedClient,
      ProgressiveNeedsLifeModelIncomeReplacementClient : this.incomeReplacement,
      ProgressiveNeedsLifeModelIncomeReplacementPartner: this.incomeReplacementPartner
    }
   
    this.sharedDataService.setProgressiveNeedsLifedata(progressiveNeedsLifeModelData);
  }
}
