<div class="boxcolor mt-2">
    <table class="table newtable tableinsurance">
      <thead>
        <tr>
          <th class="tc2 font-weight-normal ml-2 tab_fs">Total Insurance Recommended</th>
          <th class="tc1 font-weight-normal tab_fs">{{clientFirstname}}</th>
          <th class="tc1 font-weight-normal tab_fs">{{partnerFirstname}}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="isLoaded">
          <tr>
            <td>Insurable Income</td>
            <!-- <ng-container *ngFor="let dataSet of financesInfo.income; let i = index"></ng-container> -->
            
              <td>${{insurableIncomeClient | number: '1.0-0'}}</td>
              <td>${{insurableIncomePartner | number: '1.0-0'}}</td>
            
          </tr>
          <tr>
            <td>Benefit Period in Years</td>
            <td>
              <ng-container *ngIf="provisionInfo.benefitPeriods.clientBenefitPeriods">
                <ng-container *ngIf="provisionInfo.benefitPeriods.clientBenefitPeriods.value == 1">
                  0
                </ng-container>
                <ng-container *ngIf="provisionInfo.benefitPeriods.clientBenefitPeriods.value == 65">
                  {{provisionInfo.benefitPeriods.clientBenefitPeriods.value - (clientAge +1)}}
                </ng-container>
                <ng-container *ngIf="provisionInfo.benefitPeriods.clientBenefitPeriods.value == 70">
                  {{provisionInfo.benefitPeriods.clientBenefitPeriods.value - (clientAge +1)}}
                </ng-container>
                <ng-container *ngIf="provisionInfo.benefitPeriods.clientBenefitPeriods.value == 5">
                  5
                </ng-container>
                <ng-container *ngIf="provisionInfo.benefitPeriods.clientBenefitPeriods.value == 3">
                  3
                </ng-container>
                <ng-container *ngIf="provisionInfo.benefitPeriods.clientBenefitPeriods.value == 2">
                  2
                </ng-container>
              </ng-container>
              <ng-container *ngIf="provisionInfo.benefitPeriods.clientBenefitPeriods==null">
                0
              </ng-container>
              
            </td>
            <td>
              <ng-container *ngIf="provisionInfo.benefitPeriods.partnerBenefitPeriods">
                <ng-container *ngIf="provisionInfo.benefitPeriods.partnerBenefitPeriods.value == 1">
                  0
                </ng-container>
                <ng-container *ngIf="provisionInfo.benefitPeriods.partnerBenefitPeriods.value == 65">
                  {{provisionInfo.benefitPeriods.partnerBenefitPeriods.value - (partnerAge +1)}}
                </ng-container>
                <ng-container *ngIf="provisionInfo.benefitPeriods.partnerBenefitPeriods.value == 70">
                  {{provisionInfo.benefitPeriods.partnerBenefitPeriods.value - (partnerAge +1)}}
                </ng-container>
                <ng-container *ngIf="provisionInfo.benefitPeriods.partnerBenefitPeriods.value == 5">
                  5
                </ng-container>
                <ng-container *ngIf="provisionInfo.benefitPeriods.partnerBenefitPeriods.value == 3">
                  3
                </ng-container>
                <ng-container *ngIf="provisionInfo.benefitPeriods.partnerBenefitPeriods.value == 2">
                  2
                </ng-container>
              </ng-container>
              <ng-container *ngIf="provisionInfo.benefitPeriods.partnerBenefitPeriods==null">
                0
              </ng-container>
            </td>
          </tr>
          <tr>
            <td>Annual Benefit</td>
              <td>${{annualBenefitClient | number: '1.0-0'}}</td>
              <td>${{annualBenefitPartner | number: '1.0-0'}}</td>
            
          </tr>
          <tr class="tableinsurancetr">
            <td>Benefit Available</td>
            <td>$
              <ng-container *ngIf="annualBenefitClient && annualBenefitClient >0">
                {{annualBenefitClient/12 | number: '1.0-0'}}
              </ng-container>
              <ng-container *ngIf="!annualBenefitClient || annualBenefitClient == 0">
                0
              </ng-container>
            </td>
            <td>$
              <ng-container *ngIf="annualBenefitPartner && annualBenefitPartner >0">
                {{annualBenefitPartner/12 | number: '1.0-0'}}
              </ng-container>
              <ng-container *ngIf="!annualBenefitPartner || annualBenefitPartner == 0">
                0
              </ng-container>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>

  <div class="boxcolor mt-2 " >
           
    <table class="table newtable tableinsurance">
      <thead>
        <tr>
          <th class="tc2 font-weight-normal ml-2 tab_fs">Existing</th>
        </tr>
      </thead>
      <!-- <tbody>
        <tr>
          <td  class=" tableColWith">Policy Owner</td>
          <td>: {{clientFirstname}}</td>
          <td  class=" tableColWith">Monthly Benefit</td>
          <td>: $100</td>
        </tr>
        <tr>
          <td>Insurer</td>
          <td>: Insurer</td>
          <td>Waiting Period</td>
          <td>: 0</td>
        </tr>
        <tr>
          <td>Benefit Period</td>
          <td>: 0</td>
          <td>Monthly Premium</td>
          <td>: $0</td>
        </tr>
        <tr>
          <td>Structure</td>
          <td>: Not defined</td>
          <td>Policy Definition</td>
          <td>: Not defined</td>
        </tr>
        <tr class="tableinsurancetr">
          <td class="tabSummery">Funding Mechanism</td>
          <td>: Not defined</td>
          <td> </td>
          <td> </td>
        </tr>

      </tbody> -->
    </table>

    <ng-container *ngIf="isLoaded3">
      <table class="table tablecollect insutable">
        <thead>
          <tr>
            <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Policy Owner</th>
            <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs pdAlignTableHead">Insurer</th>
            <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Monthly Benefit</th> <!-- Sum Insured -->
            <!-- <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Waiting Period</th>
            <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Benefit Period</th> -->
            <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Monthly Premium</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="insuranceInfo.groupedCoversClient.income.length > 0">
            <ng-container *ngFor="let income of insuranceInfo.groupedCoversClient.income; let i = index">
              <tr>
                <td class="tablefist tableb">{{clientFirstname}}</td>
                <td class="tableleftbedit " style="background-color: #f9f9f9;">
                  <ng-container *ngIf="income.planName">
                    <input readonly type="text" class="form-control neweditinput" placeholder="{{income.planName}}" data-toggle="tooltip" data-html="true" title="{{income.planName}}" style="width: auto;">
                  </ng-container>
                  <ng-container *ngIf="income.planName =='' || income.planName == null">
                    <input readonly type="text" class="form-control neweditinput" placeholder="-">
                  </ng-container>
                </td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="income.benefitAmount">
                    <input readonly type="text" class="form-control neweditinput" placeholder="${{income.monthlyBenefitAmount | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="income.benefitAmount == null || income.benefitAmount == ''">
                    <input readonly type="text" class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
                <!-- <td class="tableleftbedit ">
                  <ng-container *ngIf="income.waitingPeriod">
                    <input readonly type="text" class="form-control neweditinput" placeholder="{{income.waitingPeriod}}">
                  </ng-container>
                  <ng-container *ngIf="income.waitingPeriod == null || income.waitingPeriod == ''">
                    <input readonly type="text" class="form-control neweditinput" placeholder="-">
                  </ng-container>
                </td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="income.benefitPeriod">
                    <input readonly type="text" class="form-control neweditinput" placeholder="{{income.benefitPeriod}}">
                  </ng-container>
                  <ng-container *ngIf="income.benefitPeriod == null || income.benefitPeriod == ''">
                    <input readonly type="text" class="form-control neweditinput" placeholder="-">
                  </ng-container>
                </td> -->
                <td class="tableleftbedit ">
                  <ng-container *ngIf="income.totalPremium">
                    <!-- <input readonly type="text" class="form-control neweditinput" placeholder="${{income.monthlyPremium | number: '1.0-0'}}"> -->
                    <input readonly type="text" class="form-control neweditinput" placeholder="${{income.totalPremium/12 | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="income.totalPremium == null || income.totalPremium == ''">
                    <input readonly type="text" class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
              </tr>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="insuranceInfo.groupedCoversClient.income.length == 0">
            <tr>
              <td class="tablefist tableb">{{clientFirstname}}</td>
              <td class="tableleftbedit "><input readonly type="text" class="form-control neweditinput" placeholder="-"></td>
              <td class="tableleftbedit "><input readonly type="text" class="form-control neweditinput" placeholder="$0"></td>
              <td class="tableleftbedit "><input readonly type="text" class="form-control neweditinput" placeholder="0"></td>
              <td class="tableleftbedit "><input readonly type="text" class="form-control neweditinput" placeholder="0"></td>
              <td class="tableleftbedit "><input readonly type="text" class="form-control neweditinput" placeholder="0"></td>
            </tr>
          </ng-container>


          <ng-container *ngIf="insuranceInfo.groupedCoversPartner.income.length > 0">
            <ng-container *ngFor="let income of insuranceInfo.groupedCoversPartner.income; let i = index">
              <tr>
                <td class="tablefist tableb">{{partnerFirstname}}</td>
                <td class="tableleftbedit " style="background-color: #f9f9f9;">
                  <ng-container *ngIf="income.planName">
                    <input readonly type="text" class="form-control neweditinput" placeholder="{{income.planName}}" data-toggle="tooltip" data-html="true" title="{{income.planName}}" style="width: auto;">
                  </ng-container>
                  <ng-container *ngIf="income.planName =='' || income.planName == null">
                    <input readonly type="text" class="form-control neweditinput" placeholder="-">
                  </ng-container>
                </td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="income.benefitAmount">
                    <input readonly type="text" class="form-control neweditinput" placeholder="${{income.monthlyBenefitAmount | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="income.benefitAmount == null || income.benefitAmount == ''">
                    <input readonly type="text" class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>

                <!-- <td class="tableleftbedit ">
                  <ng-container *ngIf="income.waitingPeriod">
                    <input readonly type="text" class="form-control neweditinput" placeholder="{{income.waitingPeriod}}">
                  </ng-container>
                  <ng-container *ngIf="income.waitingPeriod == null || income.waitingPeriod == ''">
                    <input readonly type="text" class="form-control neweditinput" placeholder="-">
                  </ng-container>
                </td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="income.benefitPeriod">
                    <input readonly type="text" class="form-control neweditinput" placeholder="{{income.benefitPeriod}}">
                  </ng-container>
                  <ng-container *ngIf="income.benefitPeriod == null || income.benefitPeriod == ''">
                    <input readonly type="text" class="form-control neweditinput" placeholder="-">
                  </ng-container>
                </td> -->
                <td class="tableleftbedit ">
                  <ng-container *ngIf="income.totalPremium">
                    <!-- <input readonly type="text" class="form-control neweditinput" placeholder="${{income.monthlyPremium | number: '1.0-0'}}"> -->
                    <input readonly type="text" class="form-control neweditinput" placeholder="${{income.totalPremium/12 | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="income.totalPremium == null || income.totalPremium == ''">
                    <input readonly type="text" class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
              </tr>
            </ng-container>
          </ng-container>
          
        </tbody>
      </table>
    </ng-container>
  </div>


  <!-- Proposed -->
  
  <!-- Proposed section - income protection -->
  <table class="table newtable tableinsurance" style="padding-bottom: 20px;">
    <thead>
      <tr>
        <th class="tc2 font-weight-normal ml-2 tab_fs">Proposed</th>
      </tr>
    </thead>
  </table>
  <ng-container *ngIf="isLoaded8">
    <ng-container *ngIf="proposedInsuranceInfo.clientInsurances.length > 0">
      <ng-container *ngFor="let proposedInsurance of proposedInsuranceInfo.clientInsurances; let i = index">
        <ng-container *ngIf="proposedInsurance.insuranceCoverType == 0">
          <div class="boxcolor mt-2 proposed" style="background-color: #00000008;">
            <label for="inputEmail4" class="tc2">  </label>
            <i class="fa fa-close close_section" (click)="deleteProposedInsurance(proposedInsurance)" ></i>
            <div class="form-row pad10 subgraphs">
              <div class="form-group col-md-6 edit-border"> 
                <ng-container *ngIf="proposedInsurance.policyOwner == clientID">
                  <input type="text" required id="policyOwner" value="{{clientFirstname}}" readonly class="form-control" placeholder="Policy owner">
                </ng-container>
                <ng-container *ngIf="proposedInsurance.policyOwner == partnerID">
                  <input type="text" required id="policyOwner" value="{{partnerFirstname}}" readonly class="form-control" placeholder="Policy owner">
                </ng-container>
              </div>
              <div class="form-group col-md-6 edit-border">
                <input type="text" id="underwriter" [(ngModel)]="proposedInsurance.underwriter" (change)="proposedInsuranceSave(proposedInsurance, $event)" class="form-control" placeholder="Insurer">
              </div>
            </div>
            <div class="form-row subgraphs">
              <div class="form-group col-md-3 edit-border"> 
                <input type="text" required id="monthlyBenefit"  [(ngModel)]="proposedInsurance.monthlyBenefit" (change)="proposedInsuranceSave(proposedInsurance, $event)" mask="separator.0" thousandSeparator="," class="form-control do" placeholder="Monthly benefit">
              </div>
              <div class="form-group col-md-3 edit-border"> 
                <input type="text" required id="waitingPeriod" [(ngModel)]="proposedInsurance.waitingPeriod" (change)="proposedInsuranceSave(proposedInsurance, $event)" mask="separator.0" thousandSeparator="," class="form-control" placeholder="Waiting period">
              </div>
              <div class="form-group col-md-3 edit-border"> 
                <input type="text" required id="benefitPeriod" [(ngModel)]="proposedInsurance.benefitPeriod" (change)="proposedInsuranceSave(proposedInsurance, $event)" mask="separator.0" thousandSeparator="," class="form-control" placeholder="Benefit period">
              </div>
              <div class="form-group col-md-3 edit-border"> 
                <input type="text" required id="monthlyPremium" [(ngModel)]="proposedInsurance.monthlyPremium" (change)="proposedInsuranceSave(proposedInsurance, $event)" mask="separator.0" thousandSeparator="," class="form-control do" placeholder="Monthly premium">
              </div>            
            </div>
            <div class="form-row subgraphs">
              <div class="form-group col-md-4 edit-border">
                <select id="structure" [(ngModel)]="proposedInsurance.structure" (change)="proposedInsuranceSave(proposedInsurance, $event)" class="form-control" placeholder="Structre">
                  <option value="" disabled selected>Select structure</option>
                  <option value="0">Stepped</option>
                  <option value="1">Level</option>
                </select>
              </div>
              <div class="form-group col-md-4 edit-border">
                <select id="policyDefenition" [(ngModel)]="proposedInsurance.policyDefenition" (change)="proposedInsuranceSave(proposedInsurance, $event)" class="form-control">
                  <option value="" disabled selected>Select Definition</option>
                  <option value="0">Agreed</option>
                  <option value="1">Indemnity</option>
                </select>
              </div>
              <div class="form-group col-md-4 edit-border">
                <select id="fundingMechanism" [(ngModel)]="proposedInsurance.fundingMechanism" (change)="proposedInsuranceSave(proposedInsurance, $event)" class="form-control">
                  <option value="" disabled selected>Select mechanism</option>
                  <option value="0">Super</option>
                  <option value="1">Super via cash flow</option>
                  <option value="2">Cash flow</option>
                </select>
              </div>      
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="proposedInsuranceInfo.partnerInsurances.length > 0">
      <ng-container *ngFor="let proposedInsurance of proposedInsuranceInfo.partnerInsurances; let i = index">
        <ng-container *ngIf="proposedInsurance.insuranceCoverType == 0">
          <div class="boxcolor mt-2 proposed" style="background-color: #00000008;">
            <label for="inputEmail4" class="tc2">  </label>
            <i class="fa fa-close close_section" (click)="deleteProposedInsurance(proposedInsurance)" ></i>
            <div class="form-row pad10 subgraphs">
              <div class="form-group col-md-6 edit-border"> 
                <ng-container *ngIf="proposedInsurance.policyOwner == clientID">
                  <input type="text" id="policyOwner" value="{{clientFirstname}}" readonly class="form-control" placeholder="Policy owner">
                </ng-container>
                <ng-container *ngIf="proposedInsurance.policyOwner == partnerID">
                  <input type="text" id="policyOwner" value="{{partnerFirstname}}" readonly class="form-control" placeholder="Policy owner">
                </ng-container>
              </div>
              <div class="form-group col-md-6 edit-border">
                <input type="text" id="underwriter" [(ngModel)]="proposedInsurance.underwriter" (change)="proposedInsuranceSave(proposedInsurance, $event)" class="form-control" placeholder="Insurer">
              </div>
            </div>
            <div class="form-row subgraphs">
              <div class="form-group col-md-3 edit-border"> 
                <input type="text" required id="monthlyBenefit"  [(ngModel)]="proposedInsurance.monthlyBenefit" (change)="proposedInsuranceSave(proposedInsurance, $event)" mask="separator.0" thousandSeparator="," class="form-control do" placeholder="Monthly benefit">
              </div>
              <div class="form-group col-md-3 edit-border"> 
                <input type="text" required id="waitingPeriod" [(ngModel)]="proposedInsurance.waitingPeriod" (change)="proposedInsuranceSave(proposedInsurance, $event)" mask="separator.0" thousandSeparator="," class="form-control" placeholder="Waiting period">
              </div>
              <div class="form-group col-md-3 edit-border"> 
                <input type="text" required id="benefitPeriod" [(ngModel)]="proposedInsurance.benefitPeriod" (change)="proposedInsuranceSave(proposedInsurance, $event)" mask="separator.0" thousandSeparator="," class="form-control" placeholder="Benefit period">
              </div>
              <div class="form-group col-md-3 edit-border"> 
                <input type="text" required id="monthlyPremium" [(ngModel)]="proposedInsurance.monthlyPremium" (change)="proposedInsuranceSave(proposedInsurance, $event)" mask="separator.0" thousandSeparator="," class="form-control do" placeholder="Monthly premium">
              </div>            
            </div>
            <div class="form-row subgraphs">
              <div class="form-group col-md-4 edit-border">
                <select id="structure" [(ngModel)]="proposedInsurance.structure" (change)="proposedInsuranceSave(proposedInsurance, $event)" class="form-control" placeholder="Structre">
                  <option value="" disabled selected>Select structure</option>
                  <option value="0">Stepped</option>
                  <option value="1">Level</option>
                </select>
              </div>
              <div class="form-group col-md-4 edit-border">
                <select id="policyDefenition" [(ngModel)]="proposedInsurance.policyDefenition" (change)="proposedInsuranceSave(proposedInsurance, $event)" class="form-control">
                  <option value="" disabled selected>Select Definition</option>
                  <option value="0">Agreed</option>
                  <option value="1">Indemnity</option>
                </select>
              </div>
              <div class="form-group col-md-4 edit-border">
                <select id="fundingMechanism" [(ngModel)]="proposedInsurance.fundingMechanism" (change)="proposedInsuranceSave(proposedInsurance, $event)" class="form-control">
                  <option value="" disabled selected>Select mechanism</option>
                  <option value="0">Super</option>
                  <option value="1">Super via cash flow</option>
                  <option value="2">Cash flow</option>
                </select>
              </div>      
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <!-- Default-Client === IF Client list null  Start--> 
    <ng-container *ngIf="!hasIPClient">
      <div class="boxcolor mt-2 proposed" style="background-color: #00000008;">
        <label for="inputEmail4" class="tc2">  </label>
        <i class="close_section"  (click)="addProposedInsurance(proposedInsuranceInfo, 'income', 'defaultClient')"> Save Now</i>
        <div class="form-row pad10 subgraphs">
          <div class="form-group col-md-6 edit-border"> 
            <!-- <select id="policyOwnerAddIncome" class="form-control" placeholder="Structre">
              <option value="" disabled selected>Select policy owner</option>
              <option value="{{clientID}}">{{clientFirstname}}</option>
              <option value="{{partnerID}}">{{partnerFirstname}}</option>
            </select> -->
            <input type="text" id="incomeInsuredClient" value="{{clientFirstname}}" readonly class="form-control" placeholder="Life Insured">
            <input type="text" id="policyOwnerAddIncomeClient" value="{{clientID}}" readonly class="form-control" placeholder="Life Insured" style="display: none;">
          </div>
          <div class="form-group col-md-6 edit-border">
            <input type="text" id="insurerAddIncomeClient" class="form-control" placeholder="Insurer">
          </div>
        </div>
        <div class="form-row subgraphs">
          <div class="form-group col-md-3 edit-border"> 
            <input type="text" required id="monthlyBenefitAddIncomeClient" [(ngModel)]="benefitAvailableClient" value="{{benefitAvailableClient}}" mask="separator.0" thousandSeparator="," class="form-control do" placeholder="Monthly benefit">
          </div>
          <div class="form-group col-md-3 edit-border"> 
            <input type="text" required id="waitingPeriodAddIncomeClient" mask="separator.0" thousandSeparator="," class="form-control" placeholder="Waiting period">
          </div>
          <div class="form-group col-md-3 edit-border"> 
            <input type="text" required id="benefitPeriodAddIncomeClient" mask="separator.0" thousandSeparator="," class="form-control" placeholder="Benefit period">
          </div>
          <div class="form-group col-md-3 edit-border"> 
            <input type="text" required id="monthlyPremiumAddIncomeClient" mask="separator.0" thousandSeparator="," class="form-control do" placeholder="Monthly premium">
          </div>            
        </div>
        <div class="form-row subgraphs">
          <div class="form-group col-md-4 edit-border">
            <select id="structureAddIncomeClient" class="form-control" placeholder="Structre">
              <option value="" disabled selected>Select structure</option>
              <option value="0">Stepped</option>
              <option value="1">Level</option>
            </select>
          </div>
          <div class="form-group col-md-4 edit-border">
            <select id="policyDefenitionAddIncomeClient" class="form-control">
              <option value="" disabled selected>Select Definition</option>
              <option value="0">Agreed</option>
              <option value="1">Indemnity</option>
            </select>
          </div>
          <div class="form-group col-md-4 edit-border">
            <select id="fundingMechanismAddIncomeClient" class="form-control">
              <option value="" disabled selected>Select mechanism</option>
              <option value="0">Super</option>
              <option value="1">Super via cash flow</option>
              <option value="2">Cash flow</option>
            </select>
          </div>      
        </div>
      </div>
    </ng-container>
    <!-- Default-Client === IF Client list null  End-->
    
    <!-- Default-Partner === IF Partner list null  Start--> 
    <ng-container *ngIf="!hasIPPartner">
      <div class="boxcolor mt-2 proposed" style="background-color: #00000008;">
        <label for="inputEmail4" class="tc2">  </label>
        <i class="close_section"  (click)="addProposedInsurance(proposedInsuranceInfo, 'income', 'defaultPartner')"> Save Now</i>
        <div class="form-row pad10 subgraphs">
          <div class="form-group col-md-6 edit-border"> 
            <!-- <select id="policyOwnerAddIncome" class="form-control" placeholder="Structre">
              <option value="" disabled selected>Select policy owner</option>
              <option value="{{clientID}}">{{clientFirstname}}</option>
              <option value="{{partnerID}}">{{partnerFirstname}}</option>
            </select> -->
            <input type="text" id="incomeInsuredPartner" value="{{partnerFirstname}}" readonly class="form-control" placeholder="Life Insured">
            <input type="text" id="policyOwnerAddIncomePartner" value="{{partnerID}}" readonly class="form-control" placeholder="Life Insured" style="display: none;">
          </div>
          <div class="form-group col-md-6 edit-border">
            <input type="text" id="insurerAddIncomePartner" class="form-control" placeholder="Insurer">
          </div>
        </div>
        <div class="form-row subgraphs">
          <div class="form-group col-md-3 edit-border"> 
            <input type="text" required id="monthlyBenefitAddIncomePartner" [(ngModel)]="benefitAvailablePartner" value="{{benefitAvailablePartner}}" mask="separator.0" thousandSeparator="," class="form-control do" placeholder="Monthly benefit">
          </div>
          <div class="form-group col-md-3 edit-border"> 
            <input type="text" required id="waitingPeriodAddIncomePartner" mask="separator.0" thousandSeparator="," class="form-control" placeholder="Waiting period">
          </div>
          <div class="form-group col-md-3 edit-border"> 
            <input type="text" required id="benefitPeriodAddIncomePartner" mask="separator.0" thousandSeparator="," class="form-control" placeholder="Benefit period">
          </div>
          <div class="form-group col-md-3 edit-border"> 
            <input type="text" required id="monthlyPremiumAddIncomePartner" mask="separator.0" thousandSeparator="," class="form-control do" placeholder="Monthly premium">
          </div>            
        </div>
        <div class="form-row subgraphs">
          <div class="form-group col-md-4 edit-border">
            <select id="structureAddIncomePartner" class="form-control" placeholder="Structre">
              <option value="" disabled selected>Select structure</option>
              <option value="0">Stepped</option>
              <option value="1">Level</option>
            </select>
          </div>
          <div class="form-group col-md-4 edit-border">
            <select id="policyDefenitionAddIncomePartner" class="form-control">
              <option value="" disabled selected>Select Definition</option>
              <option value="0">Agreed</option>
              <option value="1">Indemnity</option>
            </select>
          </div>
          <div class="form-group col-md-4 edit-border">
            <select id="fundingMechanismAddIncomePartner" class="form-control">
              <option value="" disabled selected>Select mechanism</option>
              <option value="0">Super</option>
              <option value="1">Super via cash flow</option>
              <option value="2">Cash flow</option>
            </select>
          </div>      
        </div>
      </div>
    </ng-container>
    <!-- Default-Partner === IF Partner list null  End-->

    <!-- Add new Incone sysrt -->
    <div class="boxcolor mt-2 proposed" style="background-color: #00000008;">
      <label for="inputEmail4" class="tc2"> Add Proposed </label>
      <i class="close_section"  (click)="addProposedInsurance(proposedInsuranceInfo, 'income', 'addNew')"> Save Now</i>
      <div class="form-row pad10 subgraphs">
        <div class="form-group col-md-6 edit-border"> 
          <select id="policyOwnerAddIncome" class="form-control" placeholder="Structre">
            <option value="" disabled selected>Select policy owner</option>
            <option value="{{clientID}}">{{clientFirstname}}</option>
            <option value="{{partnerID}}">{{partnerFirstname}}</option>
          </select>
        </div>
        <div class="form-group col-md-6 edit-border">
          <input type="text" id="insurerAddIncome" class="form-control" placeholder="Insurer">
        </div>
      </div>
      <div class="form-row subgraphs">
        <div class="form-group col-md-3 edit-border"> 
          <input type="text" required id="monthlyBenefitAddIncome" mask="separator.0" thousandSeparator="," class="form-control do" placeholder="Monthly benefit">
        </div>
        <div class="form-group col-md-3 edit-border"> 
          <input type="text" required id="waitingPeriodAddIncome" mask="separator.0" thousandSeparator="," class="form-control" placeholder="Waiting period">
        </div>
        <div class="form-group col-md-3 edit-border"> 
          <input type="text" required id="benefitPeriodAddIncome" mask="separator.0" thousandSeparator="," class="form-control" placeholder="Benefit period">
        </div>
        <div class="form-group col-md-3 edit-border"> 
          <input type="text" required id="monthlyPremiumAddIncome" mask="separator.0" thousandSeparator="," class="form-control do" placeholder="Monthly premium">
        </div>            
      </div>
      <div class="form-row subgraphs">
        <div class="form-group col-md-4 edit-border">
          <select id="structureAddIncome" class="form-control" placeholder="Structre">
            <option value="" disabled selected>Select structure</option>
            <option value="0">Stepped</option>
            <option value="1">Level</option>
          </select>
        </div>
        <div class="form-group col-md-4 edit-border">
          <select id="policyDefenitionAddIncome" class="form-control">
            <option value="" disabled selected>Select Definition</option>
            <option value="0">Agreed</option>
            <option value="1">Indemnity</option>
          </select>
        </div>
        <div class="form-group col-md-4 edit-border">
          <select id="fundingMechanismAddIncome" class="form-control">
            <option value="" disabled selected>Select mechanism</option>
            <option value="0">Super</option>
            <option value="1">Super via cash flow</option>
            <option value="2">Cash flow</option>
          </select>
        </div>      
      </div>
    </div>
    <!-- Add new Incone end -->
  </ng-container>
  <!-- Proposed section ends - income protection --> 
