<div class="">
    <div class="boxcolor mt-2 "> <!-- <div class="boxcolor mt-2 z13"> -->
      <ng-container *ngIf="isLoaded7">
        <table class="table tablecollect">
          <thead>
            <tr>
              <th class="tc1 font-weight-normal pt-0 pb-0"></th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs pdAlignTableHead">{{clientFirstname}}</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">{{partnerFirstname}}</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Joint</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="tablefist tableb">Income</td>
              <ng-container *ngIf="financesInfo.income.length > 0">
                <ng-container *ngFor="let dataSet of financesInfo.income; let i = index">
                  <!-- <td class="tableleftbedit edit-border" *ngIf="dataSet.ownerType==1"> <input readonly type="text" [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," class="form-control neweditinput do" placeholder="0"> </td> -->
                  <td class="tableleftbedit " *ngIf="dataSet.ownerType==1"> <input readonly type="text" [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," class="form-control neweditinput do" placeholder="0"> </td> <!-- {{dataSet.value | number: '1.0-0'}} -->
                  <td class="tableleftbedit " *ngIf="dataSet.ownerType==2"> <input readonly type="text" [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," class="form-control neweditinput do" placeholder="0"> </td>
                  <td class="tableleftbedit " *ngIf="dataSet.ownerType==3"> <input readonly type="text" [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," class="form-control neweditinput do" placeholder="0"> </td>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="financesInfo.income.length==0">
                <td class="tableleftbedit "><input readonly type="text" [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," class="form-control neweditinput do" placeholder="0"></td>
                <td class="tableleftbedit "><input readonly type="text" [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," class="form-control neweditinput do" placeholder="0"></td>
                <td class="tableleftbedit "><input readonly type="text" [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," class="form-control neweditinput do" placeholder="0"></td>
              </ng-container>
            </tr>
            <tr>
              <td class="tablefist tableb">Super Contribution Rate</td>
              <ng-container *ngIf="financesInfo.superContributionRate.length>0">
                <ng-container *ngFor="let dataSet of financesInfo.superContributionRate; let i = index">
                  <td class="tableleftbedit edit-border" *ngIf="dataSet.ownerType==1"> <input [(ngModel)]="dataSet.value" mask="percent" suffix="%" type="text" class="form-control neweditinput " placeholder="0%" (change)="sCRChange(1, $event, financesInfo)"></td>
                  <td class="tableleftbedit edit-border" *ngIf="dataSet.ownerType==2"> <input [(ngModel)]="dataSet.value" mask="percent" suffix="%" type="text" class="form-control neweditinput " placeholder="0%" (change)="sCRChange(2, $event, financesInfo)"></td>
                  <!-- <td class="tableleftbedit edit-border" *ngIf="dataSet.ownerType==3"> <input [(ngModel)]="dataSet.value" mask="percent" suffix="%" type="text" class="form-control neweditinput " placeholder="0%"></td> -->
                </ng-container>
              </ng-container>
              <ng-container *ngIf="financesInfo.superContributionRate.length==0">
                <td class="tableleftbedit edit-border"><input [(ngModel)]="dataSet.value" mask="percent" suffix="%" type="text" class="form-control neweditinput " placeholder="10%" (change)="sCRChange(1, $event, financesInfo)"></td> <!-- 9.5  is previous value -->
                <td class="tableleftbedit edit-border"><input [(ngModel)]="dataSet.value" mask="percent" suffix="%" type="text" class="form-control neweditinput " placeholder="10%" (change)="sCRChange(2, $event, financesInfo)"></td> <!-- 9.5  is previous value -->
                <!-- <td class="tableleftbedit edit-border"><input [(ngModel)]="dataSet.value" mask="percent" suffix="%" type="text" class="form-control neweditinput " placeholder="9.5%"></td> -->
              </ng-container>
            </tr>
            <tr>
              <td class="tablefist tableb">Super Balance</td>
              <ng-container *ngIf="financesInfo.superBalance.length>0">
                <ng-container *ngFor="let dataSet of financesInfo.superBalance; let i = index">
                  <td class="tableleftbedit " *ngIf="dataSet.ownerType==1"> <input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                  <td class="tableleftbedit " *ngIf="dataSet.ownerType==2"> <input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                  <!-- <td class="tableleftbedit " *ngIf="dataSet.ownerType==3"> <input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td> -->
                </ng-container>
              </ng-container>
              <ng-container *ngIf="financesInfo.superBalance.length==0">
                <td class="tableleftbedit "><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                <td class="tableleftbedit "><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                <!-- <td class="tableleftbedit "><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td> -->
              </ng-container>
            
            </tr>
            <tr>
              <td class="tablefist tableb">Primary Place of Residence</td>
              <ng-container *ngIf="financesInfo.primaryPlaceofResidence.length>0">
                <ng-container *ngFor="let dataSet of financesInfo.primaryPlaceofResidence; let i = index">
                  <td class="tableleftbedit " *ngIf="dataSet.ownerType==1"> <input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                  <td class="tableleftbedit " *ngIf="dataSet.ownerType==2"> <input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                  <td class="tableleftbedit " *ngIf="dataSet.ownerType==3"> <input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="financesInfo.primaryPlaceofResidence.length==0">
                <td class="tableleftbedit "><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                <td class="tableleftbedit "><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                <td class="tableleftbedit "><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
              </ng-container>
            </tr>
            <tr>
              <td class="tablefist tableb">Investment Properties</td>
              <ng-container *ngIf="financesInfo.investmentProperties.length>0">
                <ng-container *ngFor="let dataSet of financesInfo.investmentProperties; let i = index">
                  <td class="tableleftbedit " *ngIf="dataSet.ownerType==1"><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                  <td class="tableleftbedit " *ngIf="dataSet.ownerType==2"><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                  <td class="tableleftbedit " *ngIf="dataSet.ownerType==3"><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="financesInfo.investmentProperties.length==0">
                <td class="tableleftbedit "><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput" placeholder="0"></td>
                <td class="tableleftbedit "><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput" placeholder="0"></td>
                <td class="tableleftbedit "><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput" placeholder="0"></td>
              </ng-container>
              
            </tr>
            <tr>
              <td class="tablefist tableb">Cash</td>
              <ng-container *ngIf="financesInfo.cash.length>0">
                <ng-container *ngFor="let dataSet of financesInfo.cash; let i = index">
                  <td class="tableleftbedit " *ngIf="dataSet.ownerType==1"><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                  <td class="tableleftbedit " *ngIf="dataSet.ownerType==2"><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                  <td class="tableleftbedit " *ngIf="dataSet.ownerType==3"><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="financesInfo.cash.length==0">
                <td class="tableleftbedit "><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                <td class="tableleftbedit "><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                <td class="tableleftbedit "><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
              </ng-container>
            </tr>
            <!-- <tr>
              <td class="tablefist tableb">Managed Funds</td>
              <ng-container *ngIf="financesInfo.managedFunds.length>0">
                <ng-container *ngFor="let dataSet of financesInfo.managedFunds; let i = index">
                  <td class="tableleftbedit " *ngIf="dataSet.ownerType==1"><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                  <td class="tableleftbedit " *ngIf="dataSet.ownerType==2"><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                  <td class="tableleftbedit " *ngIf="dataSet.ownerType==3"><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="financesInfo.managedFunds.length==0">
                <td class="tableleftbedit "><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                <td class="tableleftbedit "><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                <td class="tableleftbedit "><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
              </ng-container>
            </tr>
            <tr>
              <td class="tablefist tableb">Shares</td>
              <ng-container *ngIf="financesInfo.shares.length>0">
                <ng-container *ngFor="let dataSet of financesInfo.shares; let i = index">
                  <td class="tableleftbedit " *ngIf="dataSet.ownerType==1"><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                  <td class="tableleftbedit " *ngIf="dataSet.ownerType==2"><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                  <td class="tableleftbedit " *ngIf="dataSet.ownerType==3"><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="financesInfo.shares.length==0">
                <td class="tableleftbedit "><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="$0"></td>
                <td class="tableleftbedit "><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="$0"></td>
                <td class="tableleftbedit "><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="$0"></td>
              </ng-container>
            </tr> -->
            <tr>
              <td class="tablefist tableb">Investment</td>
              <ng-container *ngIf="financesInfo.investment.length>0">
                <ng-container *ngFor="let dataSet of financesInfo.investment; let i = index">
                  <td class="tableleftbedit " *ngIf="dataSet.ownerType==1"><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                  <td class="tableleftbedit " *ngIf="dataSet.ownerType==2"><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                  <td class="tableleftbedit " *ngIf="dataSet.ownerType==3"><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="financesInfo.investment.length==0">
                <td class="tableleftbedit "><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                <td class="tableleftbedit "><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                <td class="tableleftbedit "><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
              </ng-container>
            </tr>

            <tr>
              <td class="tablefist tableb">Non Investment</td>
              <ng-container *ngIf="financesInfo.nonInvestment.length>0">
                <ng-container *ngFor="let dataSet of financesInfo.nonInvestment; let i = index">
                  <td class="tableleftbedit " *ngIf="dataSet.ownerType==1"><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                  <td class="tableleftbedit " *ngIf="dataSet.ownerType==2"><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                  <td class="tableleftbedit " *ngIf="dataSet.ownerType==3"><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="financesInfo.nonInvestment.length==0">
                <td class="tableleftbedit "><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                <td class="tableleftbedit "><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                <td class="tableleftbedit "><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
              </ng-container>
            </tr>

            <tr>
              <td class="tablefist tableb">Deductible Debt</td>
              <ng-container *ngIf="financesInfo.deductibleDebt.length>0">
                <ng-container *ngFor="let dataSet of financesInfo.deductibleDebt; let i = index">
                  <td class="tableleftbedit " *ngIf="dataSet.ownerType==1"><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                  <td class="tableleftbedit " *ngIf="dataSet.ownerType==2"><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                  <td class="tableleftbedit " *ngIf="dataSet.ownerType==3"><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="financesInfo.deductibleDebt.length==0">
                <td class="tableleftbedit "><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="$0"></td>
                <td class="tableleftbedit "><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="$0"></td>
                <td class="tableleftbedit "><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="$0"></td>
              </ng-container>
            </tr>
            <tr>
              <td class="tablefist tableb">Non - Deductible Debt</td>
              <ng-container *ngIf="financesInfo.nonDeductibleDebt.length>0">
                <ng-container *ngFor="let dataSet of financesInfo.nonDeductibleDebt; let i = index">
                  <td class="tableleftbedit " *ngIf="dataSet.ownerType==1"><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                  <td class="tableleftbedit " *ngIf="dataSet.ownerType==2"><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                  <td class="tableleftbedit " *ngIf="dataSet.ownerType==3"><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="financesInfo.nonDeductibleDebt.length==0">
                <td class="tableleftbedit "><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                <td class="tableleftbedit "><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
                <td class="tableleftbedit "><input readonly [(ngModel)]="dataSet.value" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="0"></td>
              </ng-container>
            </tr>
          </tbody>
        </table>

        <table class="table tablecollect">
          <tbody>                
            <tr>
              <td class="tableleftbedit edit-border">
                <textarea id="otherEntityNote" [(ngModel)]="financesInfo.note" (change)="noteSave(financesInfo, $event)" class="form-control neweditinput-1" rows="5" placeholder="Notes" style="resize: none;"></textarea>
              </td>
            </tr>
          </tbody>
        </table>  
      </ng-container>
    </div>

    <div class="boxcolor mt-2" style="margin-bottom: 35px;">
      <form>
        <!-- <div class="tablefist tableb" style="background-color: #f9f9f9;  padding-top: 10px;">
          <label class="tc1 ml-3 tab_fs">Notes</label>
          <p class="tc1 ml-3 tab_fs" style=" color: #7b7b7b "> No any notes added</p >

        </div> -->
        <!-- <label for="inputEmail4" class="tc1 ml-3 tab_fs">Notes</label>
        <div class="form-row">
          <div class="form-group col-md-12">
            <textarea readonly class="form-control" id="exampleFormControlTextarea1" rows="5"> No any notes added</textarea>
          </div>
        </div> -->
      </form>
    </div>

    <!-- <div class="text-center add_ft_btn" > -->
        <!-- <a> <i class="add_ft_icon">+</i> </a> -->
        <!-- <a href="" class="add_ft_icon">+</a> -->
    <!-- </div> -->
  </div>