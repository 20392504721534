<div class="section-1">
    <h6> Residual Bequests </h6>
  <div class="row gutter">
    <!-- Main Side -->
    <div class="col-md-6"> 
      <table class="table tablecollect">
        <thead>
          <tr>
            <th class="tc2 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs">For {{clientFirstname}}</th>
            <th class="tc1 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs pdAlignTableHead"> </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="tablefist tableb">TDT</td>
            <td class="tableleftbedit edit-border ">                  
              <select class="form-control neweditinput" id="clientTDT" [(ngModel)]="willInfo.residualBequests.clientResidualBequestDetail.tdt" (change)="residualBequestsTDTSave(willInfo.residualBequests.clientResidualBequestDetail, $event)">         
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </td>                
          </tr>
          
        </tbody>
      </table>
      <table class="table tablecollect" [ngClass]="willInfo.residualBequests.clientResidualBequestDetail.tdt ? '':'tdtlisthide'">
        <ng-container *ngFor="let residuaryBequestUsers of willInfo.residualBequests.clientResidualBequestDetail.residuaryBequestUsers; let i = index">
          <tr>
            <td class="tableleftbedit edit-border left-extra-border">
              <input type="text" [(ngModel)]="residuaryBequestUsers.name" id="clientBequestUsersName" (change)="residuaryBequestUserSave(residuaryBequestUsers, $event)" class="form-control neweditinput" placeholder="Name">
            </td>
            <td class="tableleftbedit edit-border">
              <!-- <input type="text" [(ngModel)]="residuaryBequestUsers.percentage" id="clientBequestUsersPercentage" (change)="residuaryBequestUserSave(residuaryBequestUsers, $event)" class="form-control neweditinput percentage" placeholder="0"> -->
              <input type="text" value="{{residuaryBequestUsers.percentage}}%" id="clientBequestUsersPercentage" (change)="residuaryBequestUserSave(residuaryBequestUsers, $event)" class="form-control neweditinput " placeholder="0">
            </td>
            <td class="action-btn-td">
              <!-- <a href="#" class="action-btn edit_pencil"><i class="fa fa-pencil "></i></a> -->
              <a (click)="deleteResidualBequestUser(residuaryBequestUsers)" class="action-btn trash_depend"><i class="fa fa-trash "></i></a>
            </td>
          </tr>
        </ng-container>
        <tr>
          <td class="tableleftbedit edit-border left-extra-border">
            <input type="text" id="clientBequestUsersNameAdd" class="form-control neweditinput" placeholder="Name">
          </td>
          <td class="tableleftbedit edit-border">
            <input type="text" value="" id="clientBequestUsersPercentageAdd" class="form-control neweditinput " placeholder="0%">
          </td>
          <td class="action-btn-td add-btn-td">
            <button class="add-btn" (click)="addResiduaryBequestUsers(willInfo.residualBequests.clientResidualBequestDetail, 1)" formtarget="_blank">Add</button>                    
          </td>
        </tr>
      </table>

      <table class="table tablecollect" [ngClass]="willInfo.residualBequests.clientResidualBequestDetail.tdt ? '':'tdtlisthide'">
        <thead>
          <tr>
            <th class="tc2 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs">Letter of Wishes</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="tableleftbedit edit-border">
              <textarea id="letterOfWishClient" [(ngModel)]="willInfo.residualBequests.clientResidualBequestDetail.letterOfWish" (change)="residualBequestsTDTSave(willInfo.residualBequests.clientResidualBequestDetail, $event)" class="form-control neweditinput-1" rows="5" placeholder="Please type" style="resize: none;"></textarea>
            </td>
          </tr>
        </tbody>
      </table>      
      
      <table class="table tablecollect">
        <thead>
          <tr>
            <th class="tc2 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs">Specific Gifts</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="tableleftbedit edit-border">
              <textarea id="specificGiftsClient" [(ngModel)]="willInfo.residualBequests.clientResidualBequestDetail.specificGifts" (change)="residualBequestsTDTSave(willInfo.residualBequests.clientResidualBequestDetail, $event)" class="form-control neweditinput-1" rows="5" placeholder="Please type" style="resize: none;"></textarea>
            </td>
          </tr>
        </tbody>
      </table>                
    </div>

    <!-- Spouse Side -->
    <ng-container *ngIf="partnerHas">
      <div class="col-md-6"> 
        <table class="table tablecollect">
          <thead>
            <tr>
              <th class="tc2 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs">For {{partnerFirstname}}</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs pdAlignTableHead"> </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="tablefist tableb">TDT</td>
              <td class="tableleftbedit edit-border ">                  
                <select class="form-control neweditinput" id="partnerTDT" [(ngModel)]="willInfo.residualBequests.partnerResidualBequestDetail.tdt" (change)="residualBequestsTDTSave(willInfo.residualBequests.partnerResidualBequestDetail, $event)">         
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </td>                
            </tr>
            
          </tbody>
        </table>
        <table class="table tablecollect" [ngClass]="willInfo.residualBequests.partnerResidualBequestDetail.tdt ? '':'tdtlisthide'">
          <ng-container *ngFor="let residuaryBequestUsers of willInfo.residualBequests.partnerResidualBequestDetail.residuaryBequestUsers; let i = index">
            <tr>
              <td class="tableleftbedit edit-border left-extra-border">
                <input type="text" [(ngModel)]="residuaryBequestUsers.name" id="partnerBequestUsersName" (change)="residuaryBequestUserSave(residuaryBequestUsers, $event)" class="form-control neweditinput" placeholder="Name">
              </td>
              <td class="tableleftbedit edit-border" >
                <!-- <input type="text" [(ngModel)]="residuaryBequestUsers.percentage" id="partnerBequestUsersPercentage" (change)="residuaryBequestUserSave(residuaryBequestUsers, $event)" class="form-control neweditinput percentage" placeholder="0"> -->
                <input type="text" value="{{residuaryBequestUsers.percentage}}%" id="partnerBequestUsersPercentage" (change)="residuaryBequestUserSave(residuaryBequestUsers, $event)" class="form-control neweditinput " placeholder="0">
              </td>
              <td class="action-btn-td">
                <!-- <a href="#" class="action-btn edit_pencil"><i class="fa fa-pencil "></i></a> -->
                <a (click)="deleteResidualBequestUser(residuaryBequestUsers)" class="action-btn trash_depend"><i class="fa fa-trash "></i></a>
              </td>
            </tr>
          </ng-container>
          <tr>
            <td class="tableleftbedit edit-border left-extra-border">
              <input type="text" id="partnerBequestUsersNameAdd" class="form-control neweditinput" placeholder="Name">
            </td>
            <td class="tableleftbedit edit-border">
              <input type="text" id="partnerBequestUsersPercentageAdd" value="" class="form-control neweditinput " placeholder="0%">
            </td>
            <td class="action-btn-td add-btn-td">
              <button class="add-btn" (click)="addResiduaryBequestUsers(willInfo.residualBequests.partnerResidualBequestDetail, 2)" formtarget="_blank">Add</button>                    
            </td>
          </tr>
        </table>

        <table class="table tablecollect" [ngClass]="willInfo.residualBequests.partnerResidualBequestDetail.tdt ? '':'tdtlisthide'">
          <thead>
            <tr>
              <th class="tc2 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs">Letter of Wishes</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="tableleftbedit edit-border">
                <textarea id="letterOfWishPartner" [(ngModel)]="willInfo.residualBequests.partnerResidualBequestDetail.letterOfWish" (change)="residualBequestsTDTSave(willInfo.residualBequests.partnerResidualBequestDetail, $event)" class="form-control neweditinput-1" rows="5" placeholder="Please type" style="resize: none;"></textarea>
              </td>
            </tr>
          </tbody>
        </table>      
        
        <table class="table tablecollect">
          <thead>
            <tr>
              <th class="tc2 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs">Specific Gifts</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="tableleftbedit edit-border">
                <textarea id="specificGiftsPartner" [(ngModel)]="willInfo.residualBequests.partnerResidualBequestDetail.specificGifts" (change)="residualBequestsTDTSave(willInfo.residualBequests.partnerResidualBequestDetail, $event)" class="form-control neweditinput-1" rows="5" placeholder="Please type" style="resize: none;"></textarea>
              </td>
            </tr>
          </tbody>
        </table>                
      </div>
    </ng-container>
    
  </div> 

  <div class="row gutter">
    <div class="col-md-12">  
      <table class="table tablecollect">
        <thead>
          <tr>
            <th class="tc2 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs">Notes</th>
          </tr>
        </thead>
        <tbody>                
          <tr>
            <td class="tableleftbedit edit-border">
              <textarea id="residuaryBequestNote" [(ngModel)]="willInfo.residualBequests.note" (change)="residuaryBequestNoteSave(willInfo.residualBequests, $event)" class="form-control neweditinput-1" rows="5" id="" placeholder="Notes" style="resize: none;"></textarea>
            </td>
          </tr>
        </tbody>
      </table>  
    </div>     
  </div>
  </div>