<ng-container *ngIf="isLoaded5">
        
    <div class="boxcolor mt-2">
      <app-dependant-support [family_tree_id]="family_tree_id" [provisionInfo]="provisionInfo" ></app-dependant-support>
    </div>
  
  
    <div class="boxcolor mt-2">
      <app-spouse-death-tpd [family_tree_id]="family_tree_id" [partnerHas]="partnerHas" [clientFirstname]="clientFirstname" [partnerFirstname]="partnerFirstname" [provisionInfo]="provisionInfo" ></app-spouse-death-tpd>
    </div>

  
    <div class="boxcolor mt-2">
      <app-immediate-needsd [family_tree_id]="family_tree_id" [partnerHas]="partnerHas" [clientFirstname]="clientFirstname" [partnerFirstname]="partnerFirstname" [provisionInfo]="provisionInfo" ></app-immediate-needsd>
    </div>


    <div class="boxcolor mt-2">
      <app-progressive-needs [family_tree_id]="family_tree_id" [partnerHas]="partnerHas" [clientFirstname]="clientFirstname" [partnerFirstname]="partnerFirstname" [provisionInfo]="provisionInfo" ></app-progressive-needs>
    </div>


    <div class="boxcolor mt-2">
      <app-benefit-period [family_tree_id]="family_tree_id" [partnerHas]="partnerHas" [clientFirstname]="clientFirstname" [partnerFirstname]="partnerFirstname" [provisionInfo]="provisionInfo" ></app-benefit-period>
    </div>
    

    <div class="boxcolor mt-2">
      <app-other [family_tree_id]="family_tree_id" [client_code]="client_code" [partnerHas]="partnerHas" [partnerClientCode]="partnerClientCode" [clientFirstname]="clientFirstname" [partnerFirstname]="partnerFirstname" [provisionInfo]="provisionInfo" ></app-other>
    </div>

    <div class="boxcolor mt-2">
      <table class="table tablecollect">
        <tbody>                
          <tr>
            <td class="tableleftbedit edit-border">
              <textarea id="otherEntityNote" [(ngModel)]="provisionInfo.note" (change)="noteSave(provisionInfo, $event)" class="form-control neweditinput-1" rows="5" placeholder="Notes" style="resize: none;"></textarea>
            </td>
          </tr>
        </tbody>
      </table> 
    </div>
    


    <!-- <form [formGroup]="addProvisionForm" novalidate class="form " (ngSubmit)="onSubmitProvision(provisionInfo)" > -->
    <!-- <form [formGroup]="addProvisionForm" novalidate class="form " >  
    </form> -->
    
    <!-- No data available section -->
    <!-- <div class="boxcolor mt-2">
      <form>
        <table class="table tablecollect">
          <thead>
            <tr>
              <th class="tc2 font-weight-normal pt-0 pb-0 tab_fs">Other</th>
            </tr>
          </thead>
          <tbody>
              <tr>
                <td class="tableleftbedit  first_input_text">
                  <input readonly type="text" value="" class="form-control neweditinput" placeholder="No data available in this section">
                </td>
              </tr>
          </tbody>
        </table>  

      </form>
    </div> -->
    <!-- end of No data available section --> 
    
  
</ng-container>