import { Component, OnInit, Input } from '@angular/core';
import { FamilyTreeService } from 'src/app/family-tree/family-tree.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProvisionModel, ProgressiveNeedsItems } from 'src/app/shared/models/provision.model';
import { ProvisionResponse, ProgressiveNeedsResponse } from 'src/app/shared/models/response.model';
import { HttpErrorResponse } from '@angular/common/http';
import { SharedDataService } from 'src/app/shared/service/shared-data.service';

@Component({
  selector: 'app-progressive-needs',
  templateUrl: './progressive-needs.component.html',
  styleUrls: ['../../rightside.component.css']
})
export class ProgressiveNeedsComponent implements OnInit {

  @Input() family_tree_id:string;
  @Input() clientFirstname:string;
  @Input() partnerFirstname:string;
  @Input() partnerHas:boolean = false;
  addProgressiveNeedsForm: FormGroup;
  isLoaded:boolean=false;
  provisionInfo:ProvisionModel;
  progressiveNeedsItems:ProgressiveNeedsItems;
  incomeLeavePerChild:number =0;
  passiveIncomeForSpouse:number =0;
  passiveIncomeForSpousePartner:number =0;
  provisionForTotallyDisabled:number =0;
  passiveIncomeContinuedPaid:number =0;
  passiveIncomeContinuedPaidPartner:number =0;
  provisionForOngoingMedicalCosts:number =0;
  

  constructor(
    private familyTreeService :FamilyTreeService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private sharedDataService: SharedDataService,
  ) { }

  ngOnInit(): void {
    this.  getProvisionInfo();
    this.sharedDataService.incomeLeavePerChild.subscribe(incomeLeavePerChild => this.incomeLeavePerChild = incomeLeavePerChild);
    this.sharedDataService.passiveIncomeForSpouse.subscribe(passiveIncomeForSpouse => this.passiveIncomeForSpouse = passiveIncomeForSpouse);
    this.sharedDataService.passiveIncomeForSpousePartner.subscribe(passiveIncomeForSpousePartner => this.passiveIncomeForSpousePartner = passiveIncomeForSpousePartner);
    this.sharedDataService.provisionForTotallyDisabled.subscribe(provisionForTotallyDisabled => this.provisionForTotallyDisabled = provisionForTotallyDisabled);
    this.sharedDataService.passiveIncomeContinuedPaid.subscribe(passiveIncomeContinuedPaid => this.passiveIncomeContinuedPaid = passiveIncomeContinuedPaid);
    this.sharedDataService.passiveIncomeContinuedPaidPartner.subscribe(passiveIncomeContinuedPaidPartner => this.passiveIncomeContinuedPaidPartner = passiveIncomeContinuedPaidPartner);
    this.sharedDataService.provisionForOngoingMedicalCosts.subscribe(provisionForOngoingMedicalCosts => this.provisionForOngoingMedicalCosts = provisionForOngoingMedicalCosts);
  }

  getProvisionInfo(){
    //this.ngxService.start();
    this.familyTreeService.getProvision(this.family_tree_id).subscribe((provisionData:ProvisionResponse)=>{
      if(provisionData.success){
        this.provisionInfo = provisionData.response;
        
        this.provisionForTotallyDisabled = this.provisionInfo.progressiveNeeds.clientProgressiveNeeds !=null ? this.provisionInfo.progressiveNeeds.clientProgressiveNeeds.provisionForTotallyDisabled :0;
        this.passiveIncomeForSpouse = this.provisionInfo.progressiveNeeds.clientProgressiveNeeds !=null ? this.provisionInfo.progressiveNeeds.clientProgressiveNeeds.passiveIncomeForSpouse :0;
        this.passiveIncomeContinuedPaid = this.provisionInfo.progressiveNeeds.clientProgressiveNeeds !=null ? this.provisionInfo.progressiveNeeds.clientProgressiveNeeds.passiveIncomeContinuedPaid :0;
        this.provisionForOngoingMedicalCosts = this.provisionInfo.progressiveNeeds.clientProgressiveNeeds !=null ? this.provisionInfo.progressiveNeeds.clientProgressiveNeeds.provisionForOngoingMedicalCosts :0;
        let provisionForMedicalCosts = this.provisionInfo.progressiveNeeds.clientProgressiveNeeds !=null ? this.provisionInfo.progressiveNeeds.clientProgressiveNeeds.provisionForMedicalCosts :0;
        this.incomeLeavePerChild = this.provisionInfo.progressiveNeeds.clientProgressiveNeeds !=null ? this.provisionInfo.progressiveNeeds.clientProgressiveNeeds.incomeLeavePerChild :0;

        let provisionForTotallyDisabledPartner = this.provisionInfo.progressiveNeeds.partnerProgressiveNeeds !=null ? this.provisionInfo.progressiveNeeds.partnerProgressiveNeeds.provisionForTotallyDisabled :0;
        this.passiveIncomeForSpousePartner = this.provisionInfo.progressiveNeeds.partnerProgressiveNeeds !=null ? this.provisionInfo.progressiveNeeds.partnerProgressiveNeeds.passiveIncomeForSpouse :0;
        this.passiveIncomeContinuedPaidPartner = this.provisionInfo.progressiveNeeds.partnerProgressiveNeeds !=null ? this.provisionInfo.progressiveNeeds.partnerProgressiveNeeds.passiveIncomeContinuedPaid :0;
        let provisionForOngoingMedicalCostsPartner = this.provisionInfo.progressiveNeeds.partnerProgressiveNeeds !=null ? this.provisionInfo.progressiveNeeds.partnerProgressiveNeeds.provisionForOngoingMedicalCosts :0,
        provisionForMedicalCostsPartner = this.provisionInfo.progressiveNeeds.partnerProgressiveNeeds !=null ? this.provisionInfo.progressiveNeeds.partnerProgressiveNeeds.provisionForMedicalCosts :0,
        incomeLeavePerChildPartner = this.provisionInfo.progressiveNeeds.partnerProgressiveNeeds !=null ? this.provisionInfo.progressiveNeeds.partnerProgressiveNeeds.incomeLeavePerChild :0
        ;
        this.addProgressiveNeedsForm = this.formBuilder.group({  
          
          // Progressive Needs
          provisionForTotallyDisabled: [this.provisionForTotallyDisabled],
          passiveIncomeForSpouse: [this.passiveIncomeForSpouse],
          passiveIncomeContinuedPaid: [this.passiveIncomeContinuedPaid],
          provisionForOngoingMedicalCosts: [this.provisionForOngoingMedicalCosts],
          provisionForMedicalCosts: [provisionForMedicalCosts],
          incomeLeavePerChild: [this.incomeLeavePerChild],
          provisionForTotallyDisabledPartner: [provisionForTotallyDisabledPartner],
          passiveIncomeForSpousePartner: [this.passiveIncomeForSpousePartner],
          passiveIncomeContinuedPaidPartner: [this.passiveIncomeContinuedPaidPartner],
          provisionForOngoingMedicalCostsPartner: [provisionForOngoingMedicalCostsPartner],
          provisionForMedicalCostsPartner: [provisionForMedicalCostsPartner],
          incomeLeavePerChildPartner: [incomeLeavePerChildPartner],
        });
        
        this.sharedDataService.changPassiveIncomeForSpouse(this.passiveIncomeForSpouse);
        this.sharedDataService.changPassiveIncomeForSpousePartner(this.passiveIncomeForSpousePartner);
        this.sharedDataService.changIncomeLeavePerChild(this.incomeLeavePerChild);
        this.sharedDataService.changProvisionForTotallyDisabled(this.provisionForTotallyDisabled);
        this.sharedDataService.changPassiveIncomeContinuedPaid(this.passiveIncomeContinuedPaid);
        this.sharedDataService.changPassiveIncomeContinuedPaidPartner(this.passiveIncomeContinuedPaidPartner);
        this.sharedDataService.changProvisionForOngoingMedicalCosts(this.provisionForOngoingMedicalCosts);
        this.isLoaded = true;
      } 
      // this.ngxService.stop();
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
    });
  }


  progressiveNeedsSave(progressiveNeeds, event: any){
    let value = event.target.value;
    let inputId = event.target.id;
    let errorM ='';
    let successM ='';

    if(!progressiveNeeds) {
      this.toastr.error('Error on updating progressive needs.');
    }
    else {
      this.progressiveNeedsItems = new ProgressiveNeedsItems;
      this.progressiveNeedsItems.clientCode = progressiveNeeds.clientCode;
      if(inputId == 'provisionForTotallyDisabled' || inputId == 'provisionForTotallyDisabledPartner') {
        this.progressiveNeedsItems.provisionForTotallyDisabled = value;
        this.progressiveNeedsItems.passiveIncomeForSpouse = progressiveNeeds.passiveIncomeForSpouse;
        this.progressiveNeedsItems.passiveIncomeContinuedPaid = progressiveNeeds.passiveIncomeContinuedPaid;
        this.progressiveNeedsItems.provisionForOngoingMedicalCosts = progressiveNeeds.provisionForOngoingMedicalCosts;
        this.progressiveNeedsItems.incomeLeavePerChild = progressiveNeeds.incomeLeavePerChild;
        errorM = 'provision for care';
        successM = 'Provision for care';
      }
      if(inputId == 'passiveIncomeForSpouse' || inputId == 'passiveIncomeForSpousePartner') {
        this.progressiveNeedsItems.provisionForTotallyDisabled = progressiveNeeds.provisionForTotallyDisabled;
        this.progressiveNeedsItems.passiveIncomeForSpouse = value;
        this.progressiveNeedsItems.passiveIncomeContinuedPaid = progressiveNeeds.passiveIncomeContinuedPaid;
        this.progressiveNeedsItems.provisionForOngoingMedicalCosts = progressiveNeeds.provisionForOngoingMedicalCosts;
        this.progressiveNeedsItems.incomeLeavePerChild = progressiveNeeds.incomeLeavePerChild;
        errorM = 'passive income for spouse';
        successM = 'Passive income for spouse';
      } 
      if(inputId == 'passiveIncomeContinuedPaid' || inputId == 'passiveIncomeContinuedPaidPartner') {
        this.progressiveNeedsItems.provisionForTotallyDisabled = progressiveNeeds.provisionForTotallyDisabled;
        this.progressiveNeedsItems.passiveIncomeForSpouse = progressiveNeeds.passiveIncomeForSpouse;
        this.progressiveNeedsItems.passiveIncomeContinuedPaid = value;
        this.progressiveNeedsItems.provisionForOngoingMedicalCosts = progressiveNeeds.provisionForOngoingMedicalCosts;
        this.progressiveNeedsItems.incomeLeavePerChild = progressiveNeeds.incomeLeavePerChild;
        errorM = 'passive income';
        successM = 'Passive income';
      }
      if(inputId == 'provisionForOngoingMedicalCosts' || inputId == 'provisionForOngoingMedicalCostsPartner') {
        this.progressiveNeedsItems.provisionForTotallyDisabled = progressiveNeeds.provisionForTotallyDisabled;
        this.progressiveNeedsItems.passiveIncomeForSpouse = progressiveNeeds.passiveIncomeForSpouse;
        this.progressiveNeedsItems.passiveIncomeContinuedPaid = progressiveNeeds.passiveIncomeContinuedPaid;
        this.progressiveNeedsItems.provisionForOngoingMedicalCosts = value;
        this.progressiveNeedsItems.incomeLeavePerChild = progressiveNeeds.incomeLeavePerChild;
        errorM = 'provision for ongoing medical costs';
        successM = 'Provision for ongoing medical costs';
      }
      if(inputId == 'incomeLeavePerChild' || inputId == 'incomeLeavePerChildPartner') {
        this.progressiveNeedsItems.provisionForTotallyDisabled = progressiveNeeds.provisionForTotallyDisabled;
        this.progressiveNeedsItems.passiveIncomeForSpouse = progressiveNeeds.passiveIncomeForSpouse;
        this.progressiveNeedsItems.passiveIncomeContinuedPaid = progressiveNeeds.passiveIncomeContinuedPaid;
        this.progressiveNeedsItems.provisionForOngoingMedicalCosts = progressiveNeeds.provisionForOngoingMedicalCosts;
        this.progressiveNeedsItems.incomeLeavePerChild = value;
        errorM = 'income';
        successM = 'Income ';
      }

      // this.sharedDataService.changIncomeLeavePerChild(this.progressiveNeedsItems.incomeLeavePerChild);
      this.familyTreeService.editProgressiveNeeds(progressiveNeeds.id, this.progressiveNeedsItems).subscribe((data:ProgressiveNeedsResponse)=>{
        if(data.error && !data.success){      
          this.toastr.error('Error on updating '+ errorM +'.');      
        }
        else{
          this.toastr.success(successM +' updated successfully.');
        }

        this.getProvisionInfo();
      },err=>{   
        console.log(err);
        this.toastr.error('Error on updating '+ errorM +'.');
      });
    }
  }

}
