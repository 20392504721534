<ul class="nav nav-pills mb-1 mt-3 ml-2" id="pills-tab" role="tablist">
    <li class="nav-item">
      <a class="nav-link active navlinkinsurance tc1" id="pills-income-tab" data-toggle="pill"
        href="#pills-income" role="tab" aria-controls="pills-income" aria-selected="true">INCOME PROTECTION</a>
    </li>
    <li class="nav-item">
      <a class="nav-link navlinkinsurance tc1" id="pills-life-tab" data-toggle="pill" href="#pills-life"
        role="tab" aria-controls="pills-life" aria-selected="false">LIFE</a>
    </li>
    <li class="nav-item">
      <a class="nav-link navlinkinsurance tc1" id="pills-tpd-tab" data-toggle="pill" href="#pills-tpd" role="tab"
        aria-controls="pills-tpd" aria-selected="false">TPD</a>
    </li>
    <li class="nav-item">
      <a class="nav-link navlinkinsurance tc1" id="pills-trauma-tab" data-toggle="pill" href="#pills-trauma"
        role="tab" aria-controls="pills-trauma" aria-selected="false">TRAUMA</a>
    </li>
    <li class="nav-item">
      <a class="nav-link navlinkinsurance tc1" id="pills-summary-tab" data-toggle="pill" href="#pills-summary"
        role="tab" aria-controls="pills-summary" aria-selected="false">SUMMARY</a>
    </li>
  </ul>
  <div class="tab-content" id="pills-tabContent">
    <div class="tab-pane fade show active pt-3 pb-4" id="pills-income" role="tabpanel" aria-labelledby="pills-income-tab">

        <app-income-protection [family_tree_id]="family_tree_id" [isLoaded3]="isLoaded3" [isLoaded8]="isLoaded8" [clientFirstname]="clientFirstname" [partnerFirstname]="partnerFirstname" [insuranceInfo]="insuranceInfo" [proposedInsuranceInfo]="proposedInsuranceInfo" [client_code]="client_code" [clientID]="clientID" [partnerID]="partnerID" ></app-income-protection>

    </div>
    <div class="tab-pane fade pt-3" id="pills-life" role="tabpanel" aria-labelledby="pills-life-tab">
        
        <app-life [family_tree_id]="family_tree_id" [isLoaded3]="isLoaded3" [isLoaded8]="isLoaded8" [clientFirstname]="clientFirstname" [partnerFirstname]="partnerFirstname" [insuranceInfo]="insuranceInfo" [proposedInsuranceInfo]="proposedInsuranceInfo" [client_code]="client_code" [clientID]="clientID" [partnerID]="partnerID" ></app-life>

    </div>
    <div class="tab-pane fade pt-3" id="pills-tpd" role="tabpanel" aria-labelledby="pills-tpd-tab">
        
        <app-tpd [family_tree_id]="family_tree_id" [isLoaded3]="isLoaded3" [isLoaded8]="isLoaded8" [clientFirstname]="clientFirstname" [partnerFirstname]="partnerFirstname" [provisionInfo]="provisionInfo" [insuranceInfo]="insuranceInfo" [proposedInsuranceInfo]="proposedInsuranceInfo" [client_code]="client_code" [clientID]="clientID" [partnerID]="partnerID" ></app-tpd>

    </div>

    <div class="tab-pane fade pt-3" id="pills-trauma" role="tabpanel" aria-labelledby="pills-trauma-tab">
        
        <app-trauma [family_tree_id]="family_tree_id" [isLoaded3]="isLoaded3" [isLoaded8]="isLoaded8" [clientFirstname]="clientFirstname" [partnerFirstname]="partnerFirstname" [provisionInfo]="provisionInfo" [insuranceInfo]="insuranceInfo" [proposedInsuranceInfo]="proposedInsuranceInfo" [client_code]="client_code" [clientID]="clientID" [partnerID]="partnerID" ></app-trauma>

    </div>

    <div class="tab-pane fade pt-3" id="pills-summary" role="tabpanel" aria-labelledby="pills-summary-tab">

        <app-summary [family_tree_id]="family_tree_id" [isLoaded3]="isLoaded3" [isLoaded8]="isLoaded8" [clientFirstname]="clientFirstname" [partnerFirstname]="partnerFirstname" [client_id]="client_id" [insuranceInfo]="insuranceInfo" [clientID]="clientID" [partnerID]="partnerID"></app-summary>

    </div>
  </div>
