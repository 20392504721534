import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rightside'
})
export class RightsidePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return null;
  }

  getLifeExpetancy(age,gender){
    let le = {20 :[61.3,65.3],
        21  :[60.3, 64.3],
        22	:[59.3,	63.3],
        23	:[58.4,	62.3],
        24	:[57.4,	61.4],
        25	:[56.4,	60.4],
        26	:[55.5,	59.4],
        27	:[54.5,	58.4],
        28	:[53.5,	57.4],
        29	:[52.6,	56.4],
        30	:[51.6,	55.4],
        31	:[50.7,	54.5],
        32	:[49.7,	53.5],
        33	:[48.7,	52.5],
        34	:[47.8,	51.5],
        35	:[46.8,	50.6],
        36	:[45.9,	49.6],
        37	:[44.9,	48.6],
        38	:[44,	47.6],
        39	:[43,	46.7],
        40	:[42.1,	45.7],
        41	:[41.1,	44.7],
        42	:[40.2,	43.8],
        43	:[39.3,	42.8],
        44	:[38.3,	41.9],
        45	:[37.4,	40.9],
        46	:[36.5,	39.9],
        47	:[35.5,	39],
        48	:[34.6,	38.1],
        49	:[33.7,	37.1],
        50	:[32.8,	36.2],
        51	:[31.9,	35.2],
        52	:[31,	34.3],
        53	:[30.1,	33.4],
        54	:[29.2,	32.4],
        55	:[28.3,	31.5],
        56	:[27.4,	30.6],
        57	:[26.6,	29.7],
        58	:[25.7,	28.8],
        59	:[24.9,	27.9],
        60	:[24,	27],
        61	:[23.2,	26.1],
        62	:[22.3,	25.2],
        63	:[21.5,	24.3],
        64	:[20.7,	23.4],
        65	:[19.9,	22.6],
        66	:[19.1,	21.7],
        67	:[18.3,	20.8],
        68	:[17.5,	20],
        69	:[16.7,	19.1],
        70	:[15.9,	18.3],
        71	:[15.2,	17.5],
        72	:[14.4,	16.6],
        73	:[13.7,	15.8],
        74	:[13,	15],
        75	:[12.3,	14.3]}
   
   
   
       var ag = age
       
       
       if (ag < 20) {
   
         ag = 20
   
       }
       else if (ag > 75){
   
         ag = 75
          
       }
   
   
       let leArr = le[ag]
   
       if (gender == 1){
      
   
         return leArr[0]
  
   
       }
       else{
   
         return leArr[1]
  
       }
  
        return 85.2
   
   }

}
