export class WillModelBaseMOdel {
    public familyTreeId: string;
    public executors: ExecutorsList;
    public residualBequests: ResidualBequests;
    public calamityBeneficiaries: CalamityBeneficiaries;
    public otherEntities: OtherEntities;
    public lifetimeArrangements: LifetimeArrangements;
}

export class WillModel extends WillModelBaseMOdel{    
    AddWillExecutor;
    AddResiduaryBequestUsers;
    AddCalamityBeneficiaryUser;
    AddEPAsItems;
}

export class ExecutorsList{
    public id: string;
    public clientExecutors: ExecutorsItems[];
    public partnerExecutors: ExecutorsItems[];
    public recordStatus: number;
    public lastUpdatedDate: Date;
    public note: string;
}

export class ExecutorsItems{
    public id: string;
    public recordStatus: number;
    public clientCode: string;
    public name: string;
    public type: number;
    public xPlanIndex: any;
}

export class ResidualBequests{
    public id: string;
    public clientResidualBequestDetail: ResidualBequestDetail;
    public partnerResidualBequestDetail: ResidualBequestDetail;
    public recordStatus: number;
    public lastUpdatedDate: Date;
    public note: string;
}

export class ResidualBequestDetail{
    public id: string;
    public letterOfWish: string;
    public specificGifts: string;
    public residuaryBequestUsers: ResiduaryBequestUsers[];
    public recordStatus: number;
    public lastUpdatedDate: Date;
    public clientCode: string;
    public tdt: boolean;
}

export class ResiduaryBequestUsers{
    public id: string;
    public recordStatus: number;
    public lastUpdatedDate: Date;
    public name: string;
    public percentage: number;
}

export class AddResiduaryBequestUsers{
    public residuaryBequestDetailId: string;
    public name: string;
    public percentage: number;
}

// export class Wish{
//     public id: string;
//     public wish: string;
// }

export class CalamityBeneficiaries{
    public id: string;
    public clientCalamityBeneficiaryUsers: ResiduaryBequestUsers[];
    public partnerCalamityBeneficiaryUsers: ResiduaryBequestUsers[];
    public recordStatus: number;
    public lastUpdatedDate: Date;
    public type: number;
    public note: string;
}

export class OtherEntities{
    public id: string;
    public clientOtherEntities: OtherEntitie;
    public partnerOtherEntities: OtherEntitie;
    public recordStatus: number;
    public lastUpdatedDate: Date;
    public note: string;
}

export class OtherEntitie{
    public id: string;
    public recordStatus: number;
    public lastUpdatedDate: Date;
    public clientCode: string;
    public details: string;
}

export class LifetimeArrangements{
    public id: string;
    public financialEPAs: EPAs;
    public medicalEPAs: MedicalEPAs;
    public recordStatus: number;
    public lastUpdatedDate: Date;
    public note: string;
}

export class EPAs{
    public clientFinancialEPAs: EPAsItems[];
    public partnerFinancialEPAs: EPAsItems[];
}

export class MedicalEPAs{
    public clientMedicalEPAs: EPAsItems[];
    public partnerMedicalEPAs: EPAsItems[];
}

export class EPAsItems{
    public id: string;
    public recordStatus: number;
    public lastUpdatedDate: Date;
    public clientCode: string;
    public name: string;
    public type: number;
}

export class AddEPAsItems{
    public lifeArrangementId: string;
    public clientCode: string;
    public name: string;
    public type: number;
}

export class AddCalamityBeneficiaryUser{
    public calamityBeneficiaryId: string;
    public clientCode: string;
    public name: string;
    public percentage: number;
}




export class AddWillExecutor{
    public familyExecutorId: string;
    public clientCode: string;
    public name: string;
    public type: number;
}
