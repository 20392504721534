import { Component, OnInit, Input } from '@angular/core';
import { FamilyTreeService } from 'src/app/family-tree/family-tree.service';
import { ProvisionResponse, SpouseDeathOrTPDResponse } from 'src/app/shared/models/response.model';
import { ProvisionModel, DeathOrTPDItems } from 'src/app/shared/models/provision.model';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SharedDataService } from 'src/app/shared/service/shared-data.service';

@Component({
  selector: 'app-spouse-death-tpd',
  templateUrl: './spouse-death-tpd.component.html',
  styleUrls: ['../../rightside.component.css']
})
export class SpouseDeathTpdComponent implements OnInit {

  @Input() family_tree_id:string;
  @Input() clientFirstname:string;
  @Input() partnerFirstname:string;
  @Input() partnerHas:boolean = false;
  isLoaded:boolean=false;
  @Input() provisionInfo:ProvisionModel;
  incomeEarnUponReturn_1: any = 0;
  incomeEarnUponReturnPartner_1: any = 0;
  addSpouseDeathTpdForm: FormGroup;
  spouseDeathOrTPDsAdd:DeathOrTPDItems;
  yearsNeedOf:any;
  yearsNeedOfPartner:any;

  constructor(
    private familyTreeService :FamilyTreeService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private sharedDataService: SharedDataService,
  ) { }

  ngOnInit(): void {
    this.getProvisionInfo();

    this.sharedDataService.yearsNeedOf.subscribe(yearsNeedOf => this.yearsNeedOf = yearsNeedOf);
    this.sharedDataService.yearsNeedOfPartner.subscribe(yearsNeedOfPartner => this.yearsNeedOfPartner = yearsNeedOfPartner);
  }

  getProvisionInfo(){
    // //this.ngxService.start();
    // this.familyTreeService.getProvision(this.family_tree_id).subscribe((provisionData:ProvisionResponse)=>{
    //   if(provisionData.success){
    //     this.provisionInfo = provisionData.response;
        
        // let  age = this.provisionInfo.dependentSupports[0].age,
        //   yearAmount = this.provisionInfo.dependentSupports[0].yearAmount;
        this.incomeEarnUponReturn_1 = this.provisionInfo.spouseDeathOrTPDs.clientDeathOrTPDItems !=null ? this.provisionInfo.spouseDeathOrTPDs.clientDeathOrTPDItems.incomeEarnUponReturn : 0;
        this.incomeEarnUponReturnPartner_1 = this.provisionInfo.spouseDeathOrTPDs.partnerDeathOrTPDItems !=null ? this.provisionInfo.spouseDeathOrTPDs.partnerDeathOrTPDItems.incomeEarnUponReturn : 0;

        this.yearsNeedOf = this.provisionInfo.spouseDeathOrTPDs.clientDeathOrTPDItems !=null ? this.provisionInfo.spouseDeathOrTPDs.clientDeathOrTPDItems.yearsNeedOf :0;
        this.yearsNeedOfPartner = this.provisionInfo.spouseDeathOrTPDs.partnerDeathOrTPDItems !=null ? this.provisionInfo.spouseDeathOrTPDs.partnerDeathOrTPDItems.yearsNeedOf :0;
        let returnToWork = this.provisionInfo.spouseDeathOrTPDs.clientDeathOrTPDItems !=null ? this.provisionInfo.spouseDeathOrTPDs.clientDeathOrTPDItems.returnToWork :false,
        incomeEarnUponReturn = this.provisionInfo.spouseDeathOrTPDs.clientDeathOrTPDItems !=null ? this.provisionInfo.spouseDeathOrTPDs.clientDeathOrTPDItems.incomeEarnUponReturn :0,
        returnToWorkPartner = this.provisionInfo.spouseDeathOrTPDs.partnerDeathOrTPDItems !=null ? this.provisionInfo.spouseDeathOrTPDs.partnerDeathOrTPDItems.returnToWork :false,
        incomeEarnUponReturnPartner = this.provisionInfo.spouseDeathOrTPDs.partnerDeathOrTPDItems !=null ? this.provisionInfo.spouseDeathOrTPDs.partnerDeathOrTPDItems.incomeEarnUponReturn :0
        ;

        this.sharedDataService.changYearsNeedOf(this.yearsNeedOf);
        this.sharedDataService.changYearsNeedOfPartner(this.yearsNeedOfPartner);

        this.addSpouseDeathTpdForm = this.formBuilder.group({  
          // age: [age],  //[Validators.required, Validators.minLength(3)]
          // yearAmount: [yearAmount],

          // Spouse Death or TPD
          returnToWork: [returnToWork],
          yearsNeedOf: [this.yearsNeedOf],
          incomeEarnUponReturn: [incomeEarnUponReturn],
          returnToWorkPartner: [returnToWorkPartner],
          yearsNeedOfPartner: [this.yearsNeedOfPartner],
          incomeEarnUponReturnPartner: [incomeEarnUponReturnPartner],
        });
          
        

        this.isLoaded = true;
    //   } 
    //   // this.ngxService.stop();` 
    // },
    // (err: HttpErrorResponse)=>{
    //   //console.log("Error"+ err.message);
    // });
  }


  spouseDeathOrTPDSave(spouseDeathOrTPDS, event: any){
    let value = event.target.value;
    let inputId = event.target.id;
    let errorVal = true;
    
    if(!spouseDeathOrTPDS) {
      this.toastr.error('Error on updating spouse death or TPD.');
    }
    else{
      this.spouseDeathOrTPDsAdd = new DeathOrTPDItems;
      this.spouseDeathOrTPDsAdd.clientCode = spouseDeathOrTPDS.clientCode;
      if(inputId == 'returnToWork' || inputId == 'returnToWorkPartner') {
        this.spouseDeathOrTPDsAdd.returnToWork = value;
        this.spouseDeathOrTPDsAdd.yearsNeedOf = spouseDeathOrTPDS.yearsNeedOf;
        this.spouseDeathOrTPDsAdd.incomeEarnUponReturn = spouseDeathOrTPDS.incomeEarnUponReturn;
      }
      if(inputId == 'yearsNeedOf' || inputId == 'yearsNeedOfPartner') {
        this.spouseDeathOrTPDsAdd.returnToWork = spouseDeathOrTPDS.returnToWork;
        this.spouseDeathOrTPDsAdd.yearsNeedOf = value;
        this.spouseDeathOrTPDsAdd.incomeEarnUponReturn = spouseDeathOrTPDS.incomeEarnUponReturn;
      }
      if(inputId == 'incomeEarnUponReturn' || inputId == 'incomeEarnUponReturnPartner') {
        let pvalue = value.replace('%', '');

        this.spouseDeathOrTPDsAdd.returnToWork = spouseDeathOrTPDS.returnToWork;
        this.spouseDeathOrTPDsAdd.yearsNeedOf = spouseDeathOrTPDS.yearsNeedOf;
        this.spouseDeathOrTPDsAdd.incomeEarnUponReturn = pvalue;

        if(pvalue >= 101) {
          this.toastr.error('Error on percentage value.');
          errorVal = false;
        } 
      }

      if(errorVal) {
        this.familyTreeService.editSpouseDeathOrTPD(spouseDeathOrTPDS.id, this.spouseDeathOrTPDsAdd).subscribe((data:SpouseDeathOrTPDResponse)=>{
          if(data.error && !data.success){      
            this.toastr.error('Error on updating spouse death or TPD.');      
          }
          else{
            this.toastr.success('Spouse death or TPD updated successfully.');
          }
    
          // this.getProvisionInfo();
        },err=>{   
          console.log(err);
          this.toastr.error('Error on updating spouse death or TPD.');
        });
      }
    }
  }

}
