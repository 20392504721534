<ng-container *ngIf="isLoaded">
    <form [formGroup]="addSpouseDeathTpdForm" novalidate class="form " >
        <table class="table tablecollect">
            <thead>
            <tr>
                <th class="tc2 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs">Spouse Death or TPD</th>
                <th class="tc1 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs pdAlignTableHead val1">{{clientFirstname}}</th>
                <ng-container *ngIf="partnerHas">
                <th class="tc1 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs val2">{{partnerFirstname}}</th>
                </ng-container>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td class="tablefist tableb">Would you return to work?</td>
                <td class="tableleftbedit edit-border ">
                <!-- <ng-container *ngIf="provisionInfo.spouseDeathOrTPDs.clientDeathOrTPDItems.returnToWork==true">
                    <input type="text" class="form-control neweditinput" placeholder=":   Yes">
                </ng-container>
                <ng-container *ngIf="provisionInfo.spouseDeathOrTPDs.clientDeathOrTPDItems.returnToWork==false || provisionInfo.spouseDeathOrTPDs.clientDeathOrTPDItems.returnToWork==null">
                    <input type="text" class="form-control neweditinput" placeholder=":   No">
                </ng-container> -->
                
                <select formControlName="returnToWork" id="returnToWork" class="form-control neweditinput" placeholder="Return to work" (change)="spouseDeathOrTPDSave(provisionInfo.spouseDeathOrTPDs.clientDeathOrTPDItems, $event)">         
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                </select>
                </td>
                <ng-container *ngIf="partnerHas">
                <td class="tableleftbedit edit-border">
                    <!-- <ng-container *ngIf="provisionInfo.spouseDeathOrTPDs.partnerDeathOrTPDItems.returnToWork==true">
                    <input type="text" class="form-control neweditinput" placeholder="Yes">
                    </ng-container>
                    <ng-container *ngIf="provisionInfo.spouseDeathOrTPDs.partnerDeathOrTPDItems.returnToWork==false || provisionInfo.spouseDeathOrTPDs.partnerDeathOrTPDItems.returnToWork==null">
                    <input type="text" class="form-control neweditinput" placeholder="No">
                    </ng-container> -->
                    <select formControlName="returnToWorkPartner" id="returnToWorkPartner" class="form-control neweditinput" placeholder="Return to work" (change)="spouseDeathOrTPDSave(provisionInfo.spouseDeathOrTPDs.partnerDeathOrTPDItems, $event)">        
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                    </select>
                </td>
                </ng-container>

                <!-- <td class="tableleftbedit ">
                <select placeholder=":   Yes" id="inputState"  class="form-control neweditinput">
                    <option selected>Yes</option>
                    <option>No</option>
                </select>
                </td> -->
                <!-- <td class="tableleftbedit "> -->
                <!-- <input readonly placeholder="Yes" id="inputState"  class="form-control neweditinput"> -->
                    <!-- <option selected>Yes</option>
                    <option>No</option>
                </select> -->
                <!-- </td> -->
            </tr>
            <tr>
                <td class="tablefist tableb">How many years would you need off?</td>
                <td class="tableleftbedit edit-border">
                <!-- <ng-container *ngIf="provisionInfo.spouseDeathOrTPDs.clientDeathOrTPDItems.yearsNeedOf"> -->
                    <input type="text" formControlName="yearsNeedOf"  mask="separator.0" id="yearsNeedOf" class="form-control neweditinput" (change)="spouseDeathOrTPDSave(provisionInfo.spouseDeathOrTPDs.clientDeathOrTPDItems, $event)" placeholder="Please enter">
                <!-- </ng-container> -->
                <!-- <ng-container *ngIf="provisionInfo.spouseDeathOrTPDs.clientDeathOrTPDItems.yearsNeedOf=='' || provisionInfo.spouseDeathOrTPDs.clientDeathOrTPDItems.yearsNeedOf==null">
                    <input type="number" formControlName="yearsNeedOf" class="form-control neweditinput" placeholder=":   0">
                </ng-container> -->
                </td>
                <ng-container *ngIf="partnerHas">
                <td class="tableleftbedit edit-border">
                    <!-- <ng-container *ngIf="provisionInfo.spouseDeathOrTPDs.partnerDeathOrTPDItems.yearsNeedOf"> -->
                    <input type="text" formControlName="yearsNeedOfPartner" mask="separator.0" id="yearsNeedOfPartner" class="form-control neweditinput" (change)="spouseDeathOrTPDSave(provisionInfo.spouseDeathOrTPDs.partnerDeathOrTPDItems, $event)" placeholder="Please enter">
                    <!-- </ng-container> -->
                    <!-- <ng-container *ngIf="provisionInfo.spouseDeathOrTPDs.partnerDeathOrTPDItems.yearsNeedOf=='' || provisionInfo.spouseDeathOrTPDs.partnerDeathOrTPDItems.yearsNeedOf==null">
                    <input type="number" formControlName="yearsNeedOfPartner" class="form-control neweditinput" placeholder=":   0">
                    </ng-container> -->
                </td>
                </ng-container>
            </tr>
            <tr>
                <td class="tablefist tableb">Upon return, what % of income would you earn? </td>
                <td class="tableleftbedit edit-border">
                <!-- <ng-container *ngIf="provisionInfo.spouseDeathOrTPDs.clientDeathOrTPDItems.incomeEarnUponReturn"> -->
                    <input type="text" value="{{incomeEarnUponReturn_1}}%" id="incomeEarnUponReturn" class="form-control neweditinput" (change)="spouseDeathOrTPDSave(provisionInfo.spouseDeathOrTPDs.clientDeathOrTPDItems, $event)" placeholder="">
                <!-- </ng-container> -->
                <!-- <ng-container *ngIf="provisionInfo.spouseDeathOrTPDs.clientDeathOrTPDItems.incomeEarnUponReturn=='' || provisionInfo.spouseDeathOrTPDs.clientDeathOrTPDItems.incomeEarnUponReturn==null">
                    <input type="number" formControlName="incomeEarnUponReturn" class="form-control neweditinput" placeholder=":   %0">
                </ng-container> -->
                </td>
                <ng-container *ngIf="partnerHas">
                <td class="tableleftbedit edit-border">
                    <!-- <ng-container *ngIf="provisionInfo.spouseDeathOrTPDs.partnerDeathOrTPDItems.incomeEarnUponReturn"> -->
                    <input type="text" value="{{incomeEarnUponReturnPartner_1}}%" id="incomeEarnUponReturnPartner" class="form-control neweditinput" (change)="spouseDeathOrTPDSave(provisionInfo.spouseDeathOrTPDs.partnerDeathOrTPDItems, $event)" placeholder="">
                    <!-- </ng-container> -->
                    <!-- <ng-container *ngIf="provisionInfo.spouseDeathOrTPDs.partnerDeathOrTPDItems.incomeEarnUponReturn=='' || provisionInfo.spouseDeathOrTPDs.partnerDeathOrTPDItems.incomeEarnUponReturn==null">
                    <input type="number" formControlName="incomeEarnUponReturnPartner" class="form-control neweditinput" placeholder="%0">
                    </ng-container> -->
                </td>
                </ng-container>
            </tr>
            </tbody>
        </table>
    </form>
</ng-container>