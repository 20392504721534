import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AddResiduaryBequestUsers } from 'src/app/shared/models/will.model';
import { ToastrService } from 'ngx-toastr';
import { FamilyTreeService } from '../../family-tree.service';
import {saveAs} from 'file-saver';
import { ActivatedRoute } from '@angular/router';
import { SharedDataService } from 'src/app/shared/service/shared-data.service';
import { ProgressiveNeedsTPDModel } from '../insurance/tpd/tpd.mode';
import { FamilyTreePdfComponent } from './family-tree-pdf/family-tree-pdf.component';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['../rightside.component.css']
})
export class DocumentsComponent implements OnInit {
  @ViewChild('calResult') private element: ElementRef;

  @Input() xeppoCode:string;
  @Input() client_code: string;
  addResiduaryBequestUser: any=[];
  companyCode:any;
  tPDModelData: ProgressiveNeedsTPDModel;
  fileImage: File;
  imageURL: string;
  public navElement: HTMLElement;
  modalRef: any;

  constructor(
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService,
    private familyTreeService :FamilyTreeService,
    private route: ActivatedRoute,
    private sharedDataService: SharedDataService,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.companyCode = this.route.snapshot.queryParams['companyCode'];

    // -- PROGRESSIVE NEEDS Data -- //
    this.sharedDataService.progressiveNeedsObserver.subscribe( response => {
      this.tPDModelData = response;
    })
    this.sharedDataService.progressiveNeedsLifeObserver.subscribe( response => {
      Object.assign(this.tPDModelData, response)
    })
    this.imageURL = localStorage.getItem('imageURL');
  }

  getFTree() {
  //  this.ngxService.start();
    this.modalRef = this.modalService.show(FamilyTreePdfComponent,  {
      initialState: {
        title: 'Family Tree',
        data: {},
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: false,
      class: "modal-xl"
    });

    this.modalRef.content.downloded.subscribe((res:any) => {
      if(res){
        this.modalRef.hide();
      //  this.ngxService.stop();
      }
    });
  }

  dataURLtoFile(dataurl, filename) {
    console.log('mmmmm999=', dataurl)
    if(dataurl != null){
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
    }

    return new File([u8arr], filename, {type:mime});
  }


  getFileNote() {
    let client_code = this.client_code;
    let xeppoCode = this.xeppoCode;
    this.ngxService.start();

    // let image =  this.familyTreeService.currentMessage.subscribe(message => this.image = message);
    // this.GraphComponentObject.saveImageUrl();
    // this.saveImageUrl2();
    // let image2 =  this.component1.saveImageUrl();
    // console.log(image2);
    let imageURL = localStorage.getItem('imageURL');
    // console.log(imageURL);

    const file = this.dataURLtoFile(imageURL, 'a.png');
    // const formData = new FormData();
    // formData.append('file', file);

    console.log("FileSize",file);

    this.addResiduaryBequestUser = new AddResiduaryBequestUsers;
    if(this.tPDModelData) {
      this.familyTreeService.getFileNoteDocuments(client_code, xeppoCode, file, this.tPDModelData).subscribe((data: any)=>{
        saveAs(data, 'FileNote.docx');
      },err=>{
        console.log(err);
        this.toastr.error('Error on downloading document.');
      });
    }

    this.ngxService.stop();
  }

 

  getFamilyTreeDocGenaration(){
    let client_code = this.client_code;
    let xeppoCode = this.xeppoCode;

    let imageURL = localStorage.getItem('imageURL');
    console.log('imageURL == ', imageURL);
    const file = this.dataURLtoFile(imageURL, 'a.png');
    console.log('file == ', file);

    this.ngxService.start();
    this.familyTreeService.getDocuments(client_code, xeppoCode, this.companyCode, file).subscribe((data)=>{
      saveAs(data, 'EstateBriefingNotes.docx');
      // if(data.error && !data.success){
      //   this.toastr.error('Error on downloading document.');
      // }
      // else{
      //   this.toastr.success('Document downloaded successfully.');
      // }

      //this.getWillInfo();
    },err=>{
      console.log(err);
      this.toastr.error('Error on downloading document.');
    });
    this.ngxService.stop();
  }


  getInstructionSheetCore(){
    let client_code = this.client_code;
    this.ngxService.start();
    this.familyTreeService.getInstructionSheetCoreDocuments(client_code, this.companyCode,).subscribe((data)=>{
      saveAs(data, 'SoftCopyInstructionSheet_Core.docx');
    },err=>{
      console.log(err);
      this.toastr.error('Error on downloading document.');
    });
    this.ngxService.stop();
  }


  getInstructionSheetMultipleTDT(){
    let client_code = this.client_code;
    this.ngxService.start();
    this.familyTreeService.getInstructionSheetMultipleTDTDocuments(client_code, this.companyCode,).subscribe((data)=>{
      saveAs(data, 'SoftCopyInstructionSheet_MultipleTDT.docx');
    },err=>{
      console.log(err);
      this.toastr.error('Error on downloading document.');
    });
    this.ngxService.stop();
  }

}
