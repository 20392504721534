import { Pipe, PipeTransform } from '@angular/core';
import { GraphCalculationPipe } from './graph-calculation.pipe';

@Pipe({
  name: 'protectionGraph'
})
export class ProtectionGraphPipe implements PipeTransform {

  protectioPeak = 0;
  mainNodeAge = 0;
  mainNodeGender = 0;
  retirementAge = 0;

  constructor( private graphCalculation: GraphCalculationPipe) {
    
   }

  transform(value: unknown, ...args: unknown[]): unknown {
    return null;
  }

  protectionYAxis(value) { 

    var self = this;
    //console.log(value)
     //if (value == self.protectioPeak) {
     
        if(value > 1000000) {
          return `${((Math.round(value) / 1000000).toFixed(1))}M`
        }
        else {
          return `${((Math.round(value) / 1000).toFixed(1))}k`
        }
  
   //    }
      //  else if (value == 0) {
     
      //   return `${0}k` // return `${0}k`
  
      // }
  
  
    //return `${(value / 1000)}b`
  } 


  xPrtectedAxisEnds(value){
    var self = this;

    let le = Math.ceil(this.graphCalculation.getLifeExpetancy(self.mainNodeAge,this.mainNodeGender)) 

    if (value == self.mainNodeAge) { return self.mainNodeAge}
    else if  (value == self.retirementAge) {
       return value
    }
    else if  (value == le) {
      return value

    }

  }

}
