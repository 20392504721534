export class Tpd {
}

export class ProgressiveNeedsTPDModel{
  private IncomeSupportFromRetirement: IncomeSupportFromRetirementViewModel
  private IncomeSupportTillRetirement: IncomeSupportFromRetirementViewModel
  private IncomeProtectionBenefitsPaid: IncomeSupportFromRetirementViewModel
  private Childcare: IncomeSupportFromRetirementViewModel
  private InjuredPersonsSuper: IncomeSupportFromRetirementViewModel
  private NonInjuredSpouseSuper: IncomeSupportFromRetirementViewModel
  private TotalNetProgressiveNeed: IncomeSupportFromRetirementViewModel
  private TotalRequired: IncomeSupportFromRetirementViewModel
  private CashedOutOfSuperImmediately: IncomeSupportFromRetirementViewModel
  private GrossUpForTaxClient: IncomeSupportFromRetirementViewModel
  private ImmediateNeedsTotal: IncomeSupportFromRetirementViewModel
  private IncomeReplacementForSpouseTimeOffWork: IncomeSupportFromRetirementViewModel

  public setIncomeSupportFromRetirement(Client, Partner) {
    this.IncomeSupportFromRetirement = {
      Client : Client,
      Partner: Partner
    }
  }
  public setIncomeSupportTillRetirement(Client, Partner) {
    this.IncomeSupportTillRetirement = {
      Client : Client,
      Partner: Partner
    }
  }
  public setIncomeProtectionBenefitsPaid(Client, Partner) {
    this.IncomeProtectionBenefitsPaid = {
      Client : Client,
      Partner: Partner
    }
  }
  public setChildcare(Client, Partner) {
    this.Childcare = {
      Client : Client,
      Partner: Partner
    }
  }
  public setInjuredPersonsSuper(Client, Partner) {
    this.InjuredPersonsSuper = {
      Client : Client,
      Partner: Partner
    }
  }
  public setNonInjuredSpouseSuper(Client, Partner) {
    this.NonInjuredSpouseSuper = {
      Client : Client,
      Partner: Partner
    }
  }
  public setTotalNetProgressiveNeed(Client, Partner) {
    this.TotalNetProgressiveNeed = {
      Client : Client,
      Partner: Partner
    }
  }
  public setTotalRequired(Client, Partner) {
    this.TotalRequired = {
      Client : Client,
      Partner: Partner
    }
  }

  public setCashedOutOfSuperImmediately(Client, Partner) {
    this.CashedOutOfSuperImmediately = {
      Client : Client,
      Partner: Partner
    }
  }

  public setGrossUpForTaxClient(Client, Partner) {
    this.GrossUpForTaxClient = {
      Client : Client,
      Partner: Partner
    }
  }

  public setImmediateNeedsTotal(Client, Partner) {
    this.ImmediateNeedsTotal = {
      Client : Client,
      Partner: Partner
    }
  }

  public setIncomeReplacementForSpouseTimeOffWork(Client, Partner) {
    this.IncomeReplacementForSpouseTimeOffWork = {
      Client : Client,
      Partner: Partner
    }
  }
}

export interface IncomeSupportFromRetirementViewModel{
  Client: number
  Partner: number
}
