import { GetTreeModel, TreeExistModel } from './get-tree.model';
import { BudgetModel } from './budget.model';
import { InsuranceModel, InsurancesList } from './insurance.model';
import { XplanChoices } from './xplan-choices.model';
import { RetirementModel, RetirementList } from './retirement.model';
import { ProvisionModel, DependentSupportsList, DeathOrTPDItems, BenefitPeriodsItems, OtherProvisionsItems, ImmediateNeedsItems, ProgressiveNeedsItems } from './provision.model';
import { WillModel, ExecutorsItems, ResidualBequestDetail, ResiduaryBequestUsers, ResidualBequests, CalamityBeneficiaries, OtherEntitie, OtherEntities, EPAsItems, LifetimeArrangements } from './will.model';
import { AddWillExecutor } from './will.model';
import { FinancesModel } from './finances.model';
import { ProposedInsuranceModel } from './proposed-insurance.model';

export class Response {
    public  success:boolean;    
    public error:object;
    public  response:object;
}

export  class ErrorObject {
    public  errorCode:number;    
    public statusCode:number;    
    public message : string;
}    

export class TreeResponseObject {
    familyTreeId : string;
    members	: GetTreeModel[];
    note : string;
}

export  class TreeResponse extends Response {
    public  success:boolean;  
    public error:ErrorObject;    
    public response : TreeResponseObject;
}

export interface TreeExistResponseModel extends Response{
    success: boolean
    error: ErrorObject
    response : TreeExistModel
}

export  class FamilyTreeExistResponse extends Response {
    public  success:boolean;  
    public error:ErrorObject;    
    public response :GetTreeModel;
}

export  class SaveFamilyTreeNodeResponse extends Response {
    public  success:boolean;  
    public error:ErrorObject;    
    public response :GetTreeModel;
}

export  class XplanChoicesResponse extends Response {
    public  success:boolean;  
    public error:ErrorObject;    
    public response :XplanChoices[];
}

export  class BudgetResponse extends Response {
    public  success:boolean;  
    public error:ErrorObject;    
    public response :BudgetModel;
}

export  class InsuranceResponse extends Response {
    public  success:boolean;  
    public error:ErrorObject;  
    public response :InsuranceModel;  
    // public response : {
    //     clientInsurances:InsuranceModel[];  
    //     partnerInsurances:InsuranceModel[];
    // };
    
}

export  class ProposedInsuranceResponse extends Response {
    public  success:boolean;  
    public error:ErrorObject;  
    public response :ProposedInsuranceModel; 
}

export  class ProposedInsuranceAddResponse extends Response {
    public  success:boolean;  
    public error:ErrorObject;  
    public response :InsurancesList; 
}

export  class RetirementResponse extends Response {
    public  success:boolean;  
    public error:ErrorObject;    
    public response :RetirementModel;
}

export  class ProvisionResponse extends Response {
    public  success:boolean;  
    public error:ErrorObject;    
    public response :ProvisionModel;
}

export  class FinancesResponse extends Response {
    public  success:boolean;  
    public error:ErrorObject;    
    public response :FinancesModel;
}

export  class DocumentsResponse extends Response {
    public  success:boolean;  
    public error:ErrorObject;    
    public response;
}

export  class RetirementAddResponse extends Response {
    public  success:boolean;  
    public error:ErrorObject;    
}

export  class DependantSupportAddResponse extends Response {
    public  success:boolean;  
    public error:ErrorObject;    
    public response :DependentSupportsList;
}

export  class SpouseDeathOrTPDResponse extends Response {
    public  success:boolean;  
    public error:ErrorObject;    
    public response :DeathOrTPDItems;
}

export  class BenefitPeriodResponse extends Response {
    public  success:boolean;  
    public error:ErrorObject;    
    public response :BenefitPeriodsItems;
}

export  class OtherProvisionsResponse extends Response {
    public  success:boolean;  
    public error:ErrorObject;    
    public response :OtherProvisionsItems;
}

export  class DeleteOtherProvisionResponse extends Response {
    public  success:boolean;  
    public error:ErrorObject;    
}

export  class ImmediateNeedsResponse extends Response {
    public  success:boolean;  
    public error:ErrorObject;    
    public response :ImmediateNeedsItems;
}

export  class ProgressiveNeedsResponse extends Response {
    public  success:boolean;  
    public error:ErrorObject;    
    public response :ProgressiveNeedsItems;
}

export  class WillResponse extends Response {
    public  success:boolean;  
    public error:ErrorObject;    
    public response :WillModel;
}

export  class WillAddResponse extends Response {
    public  success:boolean;  
    public error:ErrorObject;    
    public response :AddWillExecutor;
}

export  class WillExecutorsResponse extends Response {
    public  success:boolean;  
    public error:ErrorObject;    
    public response :ExecutorsItems;
}

export  class ResidualBequestsResponse extends Response {
    public  success:boolean;  
    public error:ErrorObject;    
    public response :ResidualBequestDetail;
}

export  class ResiduaryBequestUserResponse extends Response {
    public  success:boolean;  
    public error:ErrorObject;    
    public response :ResiduaryBequestUsers;
}

export  class ResiduaryBequestNoteResponse extends Response {
    public  success:boolean;  
    public error:ErrorObject;    
    public response :ResidualBequests;
}

export  class CalamityBeneficiaryResponse extends Response {
    public  success:boolean;  
    public error:ErrorObject;    
    public response :CalamityBeneficiaries;
}

export  class CalamityBeneficiaryAddResponse extends Response {
    public  success:boolean;  
    public error:ErrorObject;    
    public response :ResiduaryBequestUsers;
}

export  class OtherEntityItemResponse extends Response {
    public  success:boolean;  
    public error:ErrorObject;    
    public response :OtherEntitie;
}

export  class OtherEntityNoteResponse extends Response {
    public  success:boolean;  
    public error:ErrorObject;    
    public response :OtherEntities;
}

export  class AddFinancialEPAResponse extends Response {
    public  success:boolean;  
    public error:ErrorObject;    
    public response :EPAsItems;
}

export  class LifetimeArrangementsNoteResponse extends Response {
    public  success:boolean;  
    public error:ErrorObject;    
    public response :LifetimeArrangements;
}

export  class FamilyNoteAddResponse extends Response {
    public  success:boolean;  
    public error:ErrorObject;    
    public response :TreeResponseObject;
}
