export class Distibution {
}

export interface FamilyDataModel {
  isMainNode: boolean;
  id: string;
  id1: string;
  firstName: boolean;
  middleName: boolean;
  lastName: boolean;
  dateOfBirth: string;
  gender: string;
  deceased: boolean;
  dependant: boolean;
  relation: string;
  relationOf: string;
  parent: FamilyDataModel[];
  spouse: FamilyDataModel[];
  divorcee: FamilyDataModel[]
  child: FamilyDataModel[]
  spouse_children: SpouseChildrenModel[];
  divorcee_children: FamilyDataModel[]
}

//intermediatemodel to capture
export interface RawTreeModel {

  nodeData: FamilyDataModel;
  partentId: string;
  role: number;
  sibilingId:string;
  ishidden:boolean;
  childrenIds: string[];

}

export interface TreeDataModel {
  id: string;
  name:string;
  age: string;
  type: number;
  hidden: boolean;
  gender: string
  no_parent: boolean;
  children: TreeDataModel[]
}

export interface SpouseChildrenModel{
  children: FamilyDataModel[];
  partner_id: string
}

export interface GrapyTypesList{
  value: number;
  name: string;
  count: number;
}
