<ng-container *ngIf="isLoaded">
    <table class="table tablecollect">
        <thead>
        <tr>
            <th class="tc2 font-weight-normal pt-0 pb-0 tab_fs">Other</th>
            <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs pdAlignTableHead val1">{{clientFirstname}}</th>
            <ng-container *ngIf="partnerHas">
            <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs val2">{{partnerFirstname}}</th>
            </ng-container>
            <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs pdAlignTableHead"></th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let otherProvision of provisionInfo.otherProvisionList; let i = index">
            <tr>
            <td class="tableleftbedit  first_input_text"><input type="text" required value="{{otherProvision.description}}" id="description" (change)="otherProvisionsSave(otherProvision, $event)" class="form-control neweditinput" placeholder="Type description"></td>
            <td class="tableleftbedit edit-border">
                <ng-container *ngIf="otherProvision.clientProvision">
                <input type="text" [(ngModel)]="otherProvision.clientProvision.value"  id="provisionValue" (change)="otherProvisionsSave(otherProvision.clientProvision, $event)" mask="separator" thousandSeparator="," class="form-control neweditinput do" placeholder="">
                </ng-container>
                <ng-container *ngIf="otherProvision.clientProvision && otherProvision.clientProvision.value==''">
                <input type="text" value="0" (change)="otherProvisionsSave(otherProvision.clientProvision, $event)" mask="separator.0" thousandSeparator="," id="provisionValue" class="form-control neweditinput do" placeholder="">
                </ng-container>
            </td>
            <ng-container *ngIf="partnerHas">
                <td class="tableleftbedit edit-border extra-border">
                <ng-container *ngIf="otherProvision.partnerProvision">
                    <input type="text" [(ngModel)]="otherProvision.partnerProvision.value" id="provisionValuePartner" (change)="otherProvisionsSave(otherProvision.partnerProvision, $event)" mask="separator" thousandSeparator="," class="form-control neweditinput do" placeholder="">
                </ng-container>
                <ng-container *ngIf="otherProvision.partnerProvision && otherProvision.partnerProvision.value==''">
                    <input type="text" value="0" (change)="otherProvisionsSave(otherProvision.partnerProvision, $event)" mask="separator.0" thousandSeparator="," id="provisionValuePartner" class="form-control neweditinput do" placeholder="">
                </ng-container>
                </td>
            </ng-container>
            <td class="action-btn-td">
                <!-- <a href="#" class="action-btn edit_pencil"><i class="fa fa-pencil "></i></a> -->
                <a class="action-btn trash_depend" (click)="deleteOtherProvision(otherProvision)" ><i class="fa fa-trash "></i></a>
            </td>
            </tr>
        </ng-container>
        <tr>
            <td class="tableleftbedit  first_input_text">
            <input type="text" value="" id="description_add" class="form-control neweditinput" placeholder="Type Description">
            </td>
            <td class="tableleftbedit edit-border">
            <input type="text" value="" id="provisionValue_add" mask="separator.0" thousandSeparator="," class="form-control neweditinput do" placeholder="Value">
            </td>
            <ng-container *ngIf="partnerHas">
            <td class="tableleftbedit edit-border extra-border">
                <input type="text" value="" id="provisionValuePartner_add" mask="separator.0" thousandSeparator="," class="form-control neweditinput do" placeholder="Value">
            </td>
            </ng-container>
            <td class="action-btn-td add-btn-td">
            <button class="add-btn" (click)="addOtherProvisions(provisionInfo)" formtarget="_blank">Add</button>                    
            </td>
        </tr>
        
        </tbody>
    </table>
</ng-container>