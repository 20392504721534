<section class="distribution-wr" [ngClass]="{'full-screen': isFullScreen}">
  <div class="graph-header row align-items-center">
      <div class="col-md-7">
          <span class="custom-text mx-2">Graph Type</span>
          <select
              (change)="changeDistributionGraphType($event.target.value)"
              class="form-control custom-dropdown">
              <option *ngFor="let graphType of graphTypesList" [value]="graphType.value" [selected]="graphType.value == selectedGraphType">{{graphType.name}}</option>
          </select>
      </div>
      <div class="col-md-5">
        <div class="d-flex align-items-center justify-content-end">
          <span class="custom-text mr-2">{{selectedGraphNumber}} / {{graphCountOfSelectedType}}</span>
          <img (click)="prevGraph()" class="arrow mr-2" src="assets/arrow-left.svg" />
          <img (click)="nextGraph()" class="arrow mr-2" src="assets/arrow-right.svg" />

          <button class="screen-toggle-btn btn shadow-sm p-2 d-flex ml-3" (click)="toggleScreen()">
            <img *ngIf="!isFullScreen" src="assets/open_in.svg" alt="Open Fullscreen">
            <img *ngIf="isFullScreen" src="assets/close_fullscreen.svg" alt="Close Fullscreen">
          </button>
        </div>
      </div>
  </div>

  <div class="content-body">
    <div class="row">
      <div class="col-md-12">
        <div class="graph-wr"[ngClass]="{'full-screen': isFullScreen}">
          <div id="svg-container" class="svg-wr" [ngClass]="{'full-screen': isFullScreen, 'top-space-fix': (selectedGraphType != graphType.ProtectedWealth)}" [ngStyle]="{'height': containerHeight}"></div>
        </div>
      </div>
    </div>
  </div>
</section>
