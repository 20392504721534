<section *ngIf="hasMembers" class="members-list" >
    <div class="member-wr" *ngFor="let member of currentMemberList">
        <app-member-form 
            (addMemberNode)="addMemberNodeEvent($event)"
            (updateMemberNode)="updateMemberNodeEvent($event)"
            (deleteMemberNode)="deleteMemberNodeEvent($event)"
            (clearTempMemberNode)="clearTempMemberNodeEvent($event)"
            [familyTreeId]="treeExistData.id" 
            [member]="member" 
            [members]="membersList">
        </app-member-form>
    </div>
</section>

<div class="text-center mt-3" *ngIf="!isAdd">
    <span class="add-new" (click)="addTempMember()"><i class="fa fa-plus"></i> Add New Member</span>
</div>

<section *ngIf="hasMembers" class="note-wr mt-3">
    <app-update-note [treeResponseData]="treeResponseData"></app-update-note>
</section>