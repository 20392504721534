<ng-container *ngIf="isLoaded">
    <form [formGroup]="addBenefitPeriodForm" novalidate class="form " >
        <table class="table tablecollect">
            <thead>
            <tr>
                <th class="tc2 font-weight-normal pt-0 pb-0 tab_fs">Benefit Period</th>
                <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs pdAlignTableHead val1">{{clientFirstname}}</th>
                <ng-container *ngIf="partnerHas">
                <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs val2">{{partnerFirstname}}</th>
                </ng-container>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td class="tablefist tableb">Benefit Period</td>
                <td class="tableleftbedit edit-border">
                <!-- <ng-container *ngIf="provisionInfo.benefitPeriods.clientBenefitPeriods.value">
                    <input type="number" formControlName="value" id="value" class="form-control neweditinput" (change)="benefitPeriodSave(provisionInfo.benefitPeriods.clientBenefitPeriods, $event)" placeholder="">
                </ng-container>
                <ng-container *ngIf="provisionInfo.benefitPeriods.clientBenefitPeriods.value=='' || provisionInfo.benefitPeriods.clientBenefitPeriods.value==null">
                    <input type="number" formControlName="value" id="value" class="form-control neweditinput" (change)="benefitPeriodSave(provisionInfo.benefitPeriods.clientBenefitPeriods, $event)" placeholder="">
                </ng-container> -->

                <select formControlName="value" id="value" class="form-control neweditinput" placeholder="Select value" (change)="benefitPeriodSave(provisionInfo.benefitPeriods.clientBenefitPeriods, $event)">         
                    <option value=65>To 65</option>
                    <option value=70>To 70</option>
                    <option value=5>5 Year</option>
                    <option value=3>3 Year</option>
                    <option value=2>2 Year</option>
                </select>

                </td>
                <ng-container *ngIf="partnerHas">
                <td class="tableleftbedit edit-border">
                    <!-- <ng-container *ngIf="provisionInfo.benefitPeriods.partnerBenefitPeriods.value">
                    <input type="number" formControlName="valuePartner" id="valuePartner" class="form-control neweditinput" (change)="benefitPeriodSave(provisionInfo.benefitPeriods.partnerBenefitPeriods, $event)" placeholder="">
                    </ng-container>
                    <ng-container *ngIf="provisionInfo.benefitPeriods.partnerBenefitPeriods.value=='' || provisionInfo.benefitPeriods.partnerBenefitPeriods.value==null">
                    <input type="number" formControlName="valuePartner" id="valuePartner" class="form-control neweditinput" (change)="benefitPeriodSave(provisionInfo.benefitPeriods.clientBenefitPeriods, $event)" placeholder="">
                    </ng-container> -->

                    <select formControlName="valuePartner" id="valuePartner" class="form-control neweditinput" placeholder="Select value" (change)="benefitPeriodSave(provisionInfo.benefitPeriods.partnerBenefitPeriods, $event)">         
                    <option value=65>To 65</option>
                    <option value=70>To 70</option>
                    <option value=5>5 Year</option>
                    <option value=3>3 Year</option>
                    <option value=2>2 Year</option>
                    </select>

                </td>
                </ng-container>
                <!-- <input readonly placeholder=":   To 60" id="inputState"  class="form-control neweditinput"> -->
                    <!-- <option selected>To 65</option>
                    <option>To 70</option>
                    <option>5 Years</option>
                    <option>3 Years</option>
                    <option>2 Years</option>
                </select> -->
                <!-- </td> -->
                <!-- <td class="tableleftbedit "> -->
                <!-- <input readonly placeholder="To 65" id="inputState"  class="form-control neweditinput"> -->
                    <!-- <option selected>To 65</option>
                    <option>To 70</option>
                    <option>5 Years</option>
                    <option>3 Years</option>
                    <option>2 Years</option>
                </select> -->
                <!-- </td> -->
            </tr>
            </tbody>
        </table>
    </form>
</ng-container>
