<div class="section-1">
    <h6> Other Entities </h6>
  <div class="row gutter">
    <!-- Main Side -->
    <div class="col-md-6"> 
      <table class="table tablecollect">
        <thead>
          <tr>
            <th class="tc2 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs">For {{clientFirstname}}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="tableleftbedit edit-border">
              <textarea [(ngModel)]="willInfo.otherEntities.clientOtherEntities.details" id="otherEntityDetailsClient" (change)="otherEntityItemSave(willInfo.otherEntities.clientOtherEntities, $event)" class="form-control neweditinput-1" rows="5" placeholder="Details" style="resize: none;"></textarea>
            </td>
          </tr>
        </tbody>
      </table>                
    </div>

    <!-- Spouse Side -->
    <ng-container *ngIf="partnerHas">
      <div class="col-md-6"> 
        <table class="table tablecollect">
          <thead>
            <tr>
              <th class="tc2 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs">For {{partnerFirstname}}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="tableleftbedit edit-border" *ngIf="willInfo.otherEntities.partnerOtherEntities">
                <textarea [(ngModel)]="willInfo.otherEntities.partnerOtherEntities.details" id="otherEntityDetailsPartner" (change)="otherEntityItemSave(willInfo.otherEntities.partnerOtherEntities, $event)" class="form-control neweditinput-1" rows="5" placeholder="Details" style="resize: none;"></textarea>
              </td>
            </tr>
          </tbody>
        </table>               
      </div>
    </ng-container>
  </div> 

  <div class="row gutter">
    <div class="col-md-12">  
      <table class="table tablecollect">
        <thead>
          <tr>
            <th class="tc2 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs">Notes</th>
          </tr>
        </thead>
        <tbody>                
          <tr>
            <td class="tableleftbedit edit-border">
              <textarea [(ngModel)]="willInfo.otherEntities.note" id="otherEntityNote" (change)="otherEntityNoteSave(willInfo.otherEntities, $event)" class="form-control neweditinput-1" rows="5" placeholder="Notes" style="resize: none;"></textarea>
            </td>
          </tr>
        </tbody>
      </table>  
    </div>     
  </div>
  </div>