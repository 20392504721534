import { Component, Input, OnInit} from '@angular/core';
import { GetTreeModel, TreeExistModel } from 'src/app/shared/models/get-tree.model';
import { FamilyTreeService } from '../../family-tree.service';
import { SharedDataService } from 'src/app/shared/service/shared-data.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TreeExistResponseModel, TreeResponse, TreeResponseObject } from 'src/app/shared/models/response.model';
import { FamilyNodesPipe } from 'src/app/shared/custom-pipes/family-nodes.pipe';

@Component({
  selector: 'app-family',
  templateUrl: './family.component.html',
  styleUrls: ['./family.component.css'],
  providers: [FamilyNodesPipe]
})
export class FamilyComponent implements OnInit {

  @Input() client_id: string
  @Input() clientFname: string
  @Input() clientLname: string
  @Input() clientMname: string
  @Input() clientDob: string
  @Input() clientGender: string
  @Input() xeppoCode: string
  @Input() client_code: string

  isLoading = false
  isTreeExist = true;
  hasMembers = false
  isAdd = false

  membersList: GetTreeModel[]
  currentMemberList: GetTreeModel[]
  treeExistData = new TreeExistModel()
  treeResponseData = new TreeResponseObject()

  constructor(
    private familyTreeService: FamilyTreeService,
    private sharedDataService: SharedDataService,
    private preloaderService: NgxUiLoaderService,
    private familyNodePipe: FamilyNodesPipe,
  ) { }

  ngOnInit(): void {
   
    // this.sharedDataService.familyTree.subscribe( res => console.log( "Shared member list" , res))
    if (this.client_id) {       
      this.getFamilyTreeExist(this.client_id, this.client_code)
    };
    if (this.client_id) {       
      this.getFamilyTreeExist(this.client_id, this.client_code)
    }
  }

  ngAfterViewInit(){
    
  }

  private getFamilyTreeExist(clientId: string, clientCode: string) {
    this.preloaderService.start()
    this.familyTreeService.getFamilyTreeExist(clientId, clientCode).subscribe( 
      (res: TreeExistResponseModel) => {
        this.treeExistData = res.response
        if (res.success && this.treeExistData.id) {
          this.updateSharedData(this.treeExistData)
          this.getFamilyTreeFromServer(this.treeExistData.id)
        } else {
          this.isTreeExist = false
        }
    }, err => { })
  }

  private getFamilyTreeFromServer(familyTreeId: string) { 
    this.familyTreeService.getFamilyTreeFromServer(familyTreeId).subscribe( 
      (res: TreeResponse) => {
        if (res.success) {
          this.hasMembers = true;
          this.treeResponseData = res.response
          this.membersList = this.familyNodePipe.sortMembers(res.response.members)  // Ishara Sort
          this.currentMemberList = this.membersList
          this.updateSharedMemberList(this.membersList)
        }
        this.preloaderService.stop()
    }, err => { })
  }

  addTempMember() {
    this.isAdd = true;
    const tempMember = new GetTreeModel()
    this.currentMemberList.push(tempMember)
  }

  addMemberNodeEvent(newMember: GetTreeModel) {
    this.isAdd = false;
    this.membersList.push(newMember)
    this.clearTempMember() 
    this.updateSharedMemberList(this.membersList)
  } 

  updateMemberNodeEvent(member: GetTreeModel) {
    const Index = this.membersList.findIndex(x => x.id == member.id)
    this.membersList[Index] = member;
    this.updateSharedMemberList(this.membersList)
  } 

  deleteMemberNodeEvent(memberId: string) { 
    const Index = this.membersList.findIndex(x => x.id == memberId)
    if (Index > -1) {
      this.membersList.splice(Index, 1);
    }
    this.currentMemberList = this.membersList;
    this.updateSharedMemberList(this.membersList)
  }

  clearTempMemberNodeEvent() {
    this.clearTempMember();
  }

  clearTempMember() {
    const Index = this.currentMemberList.findIndex(x => x.id === undefined)
    if (Index > -1) {
      this.currentMemberList.splice(Index, 1);
    }
    this.isAdd = false;
  }

  updateSharedData(data: TreeExistModel) {
    this.sharedDataService.changFamilyTreeExist(this.isTreeExist);
    this.sharedDataService.changeFamilyTreeId(data.id)
    this.sharedDataService.changPartnerClientCode(data.partnerClientCode);
  }

  updateSharedMemberData(memberData: GetTreeModel) {
    this.sharedDataService.changeClientFirstname(memberData.firstName);
    this.sharedDataService.changeClientID(memberData.id);
    this.sharedDataService.changClientAge(this.calcAge(memberData.dateOfBirth));
  }

  updateSharedMemberList(memberList: GetTreeModel[]) {
    const length = memberList.length
    this.sharedDataService.changMembersLength(length)
    this.sharedDataService.changFamilyTree(this.membersList)
    this.sharedDataService.changTreeListFromServer(this.membersList);
    const hasMembers = true;
    this.sharedDataService.changIsLoaded(hasMembers);
  }

  calcAge(dob: string) { // Calculate Age by DOB -- Add this to a pipe [TEMP]
    if (dob) {
      let timeDiff = Math.abs(Date.now() - new Date(dob).getTime());
      const calAge = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
      return calAge;
    } else {
      return 0;
    }
  }





}
