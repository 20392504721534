import { Component, OnInit, Input } from '@angular/core';
import { WillModel, CalamityBeneficiaries, AddCalamityBeneficiaryUser, AddResiduaryBequestUsers } from 'src/app/shared/models/will.model';
import { FamilyTreeService } from 'src/app/family-tree/family-tree.service';
import { ToastrService } from 'ngx-toastr';
import { WillResponse, CalamityBeneficiaryResponse, CalamityBeneficiaryAddResponse, DeleteOtherProvisionResponse } from 'src/app/shared/models/response.model';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-calamity-beneficiaries',
  templateUrl: './calamity-beneficiaries.component.html',
  styleUrls: ['../../rightside.component.css']
})
export class CalamityBeneficiariesComponent implements OnInit {

  @Input() family_tree_id:string;
  @Input() client_code:string;
  @Input() partnerClientCode:string;
  @Input() clientFirstname:string;
  @Input() partnerFirstname:string;
  @Input() partnerHas:boolean;
  @Input() willInfo:WillModel;
  @Input() showCBUsers:boolean;
  isLoaded6:boolean;
  // showCBUsers: boolean = false;
  addCalamityBeneficiaries: any=[];
  addResiduaryBequestUser: any=[];
  
  constructor(
    private familyTreeService :FamilyTreeService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
  }

  getWillInfo(){
    // this.ngxService.start();
    this.familyTreeService.getWill(this.family_tree_id).subscribe((willData:WillResponse)=>{
      if(willData.success){
        this.willInfo = willData.response;
        
        if(this.willInfo.calamityBeneficiaries.type == 2) {
          this.showCBUsers = true;
        }
        else {
          this.showCBUsers = false;
        }
        // let executorNmae = this.willInfo.executors.clientExecutors.name,
        // executorType = this.willInfo.executors.clientExecutors.combinedRetirementIncome,
        // precentageIfPartnerDeceased = this.willInfo.executors.partnerExecutors.precentageIfPartnerDeceased,
        // precentageIfPartnerDeceased = this.willInfo.executors.partnerExecutors.precentageIfPartnerDeceased;
          
        this.isLoaded6 = true;
      } 
      // this.ngxService.stop(); 
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });
  }

  calamityBeneficiariesSave(calamityBeneficiaries, event: any){
    // console.log(calamityBeneficiaries);
    let value = event.target.value;
    let inputId = event.target.id;
    // console.log(value);
    // console.log(inputId);

    this.addCalamityBeneficiaries = new CalamityBeneficiaries;
    
    if(inputId == 'calamityBeneficiaries') {

      if(value == 2) {
        this.showCBUsers = true;
      }
      else {
        this.showCBUsers = false;
      }

      this.addCalamityBeneficiaries.type = value;
      this.addCalamityBeneficiaries.note = calamityBeneficiaries.note;
    }
    if(inputId == 'calamityBeneficiariesNote') {
      this.addCalamityBeneficiaries.type = calamityBeneficiaries.type;
      this.addCalamityBeneficiaries.note = value;
    }
    
    // console.log(this.willExecutorsNoteAdd);
    this.familyTreeService.editCalamityBeneficiary(calamityBeneficiaries.id, this.addCalamityBeneficiaries).subscribe((data:CalamityBeneficiaryResponse)=>{
      if(data.error && !data.success){      
        this.toastr.error('Error on updating calamity beneficiaries.');      
      }
      else{
        this.toastr.success('Calamity beneficiaries updated successfully.');
      }

      this.getWillInfo();
    },err=>{   
      console.log(err);
      this.toastr.error('Error on updating calamity beneficiaries.');
    });
  }

  addCalamityBeneficiaryUser(calamityBeneficiaries, userType) {
    //console.log(calamityBeneficiaries);
    this.addCalamityBeneficiaries = new AddCalamityBeneficiaryUser;

    this.addCalamityBeneficiaries.calamityBeneficiaryId = calamityBeneficiaries.id;

    if(userType == 1) {
      var cl_CBUName = ((document.getElementById("clientCalamityBeneficiaryUsersNameAdd") as HTMLInputElement).value);
      var cl_CBUPercentage = ((document.getElementById("clientCalamityBeneficiaryUsersPercentageAdd") as HTMLInputElement).value);
      this.addCalamityBeneficiaries.clientCode = this.client_code;
    }
    
    if(userType == 2) {
      var cl_CBUName = ((document.getElementById("partnerCalamityBeneficiaryUsersNameAdd") as HTMLInputElement).value);
      var cl_CBUPercentage = ((document.getElementById("partnerCalamityBeneficiaryUsersPercentageAdd") as HTMLInputElement).value);
      this.addCalamityBeneficiaries.clientCode = this.partnerClientCode;
    }
    cl_CBUPercentage = cl_CBUPercentage.replace('%', '');

    this.addCalamityBeneficiaries.name = cl_CBUName;
    this.addCalamityBeneficiaries.percentage = cl_CBUPercentage;

    if(this.addCalamityBeneficiaries.percentage >= 101) {
      this.toastr.error('Error on percentage value.');
    } else {
      this.calamityBeneficiaryUserAdd(this.addCalamityBeneficiaries);
    }

    ((document.getElementById("clientCalamityBeneficiaryUsersNameAdd") as HTMLInputElement).value='');
    ((document.getElementById("clientCalamityBeneficiaryUsersPercentageAdd") as HTMLInputElement).value='');
    ((document.getElementById("partnerCalamityBeneficiaryUsersNameAdd") as HTMLInputElement).value='');
    ((document.getElementById("partnerCalamityBeneficiaryUsersPercentageAdd") as HTMLInputElement).value='');
  }

  calamityBeneficiaryUserAdd(addCalamityBeneficiaryUsers)
  {
    this.familyTreeService.addCalamityBeneficiaryUser(addCalamityBeneficiaryUsers).subscribe((data:CalamityBeneficiaryAddResponse)=>{
      if(data.error && !data.success){      
        this.toastr.error('Error on adding calamity beneficiary users.');      
      }
      else{
        this.toastr.success('Calamity beneficiary users added successfully.');
      }

      this.getWillInfo();
    },err=>{   
      console.log(err);
      this.toastr.error('Error on adding calamity beneficiary users.');
    });
  }

  calamityBeneficiaryUserSave(calamityBeneficiaryUser, event: any){
    //console.log(residualBequestDetail);
    let value = event.target.value;
    let inputId = event.target.id;
    let errorVal = true;
    // console.log(value);
    // console.log(inputId);

    this.addResiduaryBequestUser = new AddResiduaryBequestUsers;
    this.addResiduaryBequestUser.clientCode = calamityBeneficiaryUser.clientCode;

    if(inputId == 'clientCalamityBeneficiaryUsersName' || inputId == 'partnerCalamityBeneficiaryUsersName') {
      this.addResiduaryBequestUser.name = value;
      this.addResiduaryBequestUser.percentage = calamityBeneficiaryUser.percentage;
    }
    if(inputId == 'clientCalamityBeneficiaryUsersPercentage' || inputId == 'partnerCalamityBeneficiaryUsersPercentage') {

      let pvalue = value.replace('%', '');

      this.addResiduaryBequestUser.name = calamityBeneficiaryUser.name;
      this.addResiduaryBequestUser.percentage = pvalue;

      if(value >= 101) {
        this.toastr.error('Error on percentage value.');
        errorVal = false;
      }
    }

    if(errorVal) {
      this.familyTreeService.editCalamityBeneficiaryUser(calamityBeneficiaryUser.id, this.addResiduaryBequestUser).subscribe((data:CalamityBeneficiaryAddResponse)=>{
        if(data.error && !data.success){      
          this.toastr.error('Error on updating calamity beneficiary users.');      
        }
        else{
          this.toastr.success('Calamity beneficiary users updated successfully.');
        }
  
        this.getWillInfo();
      },err=>{   
        console.log(err);
        this.toastr.error('Error on updating calamity beneficiary users.');
      });
    }
  }

  deleteCalamityBeneficiaryUser(calamityBeneficiaryUsers)
  {
    let id = calamityBeneficiaryUsers.id;

    this.familyTreeService.deleteCalamityBeneficiaryUser(id).subscribe((data:DeleteOtherProvisionResponse)=>{
      if(data.error && !data.success){      
        this.toastr.error("Error on deleting user.");      
      }
      else{
        this.getWillInfo();
        this.toastr.success("User deleted successfully.");
      }

      //this.getProvisionInfo();
    },err=>{   
      console.log(err);
      this.toastr.error("Error on deleting user.");
    });    
  }

}
