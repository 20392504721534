import { Component, OnInit, Input } from '@angular/core';
import { ProvisionsComponent } from '../../provisions/provisions.component';
import { SharedDataService } from 'src/app/shared/service/shared-data.service';
import { InsurancesList } from 'src/app/shared/models/insurance.model';
import { ToastrService } from 'ngx-toastr';
import { FamilyTreeService } from 'src/app/family-tree/family-tree.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ProposedInsuranceResponse, ProposedInsuranceAddResponse, DeleteOtherProvisionResponse } from 'src/app/shared/models/response.model';

@Component({
  selector: 'app-trauma',
  templateUrl: './trauma.component.html',
  styleUrls: ['../../rightside.component.css']
})
export class TraumaComponent implements OnInit {

  @Input() family_tree_id:string;
  @Input() isLoaded3:boolean;
  @Input() isLoaded8:boolean;
  @Input() clientFirstname:string;
  @Input() partnerFirstname:string;
  @Input() provisionInfo:any;
  @Input() insuranceInfo:any;
  @Input() proposedInsuranceInfo:any;
  @Input() client_code:string;
  @Input() clientID:string;
  @Input() partnerID:string;

  provisionsCompOneObj: ProvisionsComponent;

  isLoaded:boolean;
  upfrontCapitalNeedClient:number;
  upfrontCapitalNeedPartner:number;
  yearsOffWorkClient:number;
  yearsOffWorkPartner:number;
  coverForYearsOffWorkClient:number;
  coverForYearsOffWorkPartner:number;
  totalCoverRequiredClient:number;
  totalCoverRequiredPartner:number;
  less2YearsPassiveIncomeClient:number;
  less2YearsPassiveIncomePartner:number;
  income:number= 0;
  incomePartner:number= 0;

  totalCoverRequiredClientInput:number;
  totalCoverRequiredPartnerInput:number;

  addProposedInsuranceData: any=[];
  salaryOwner: number = 0;
  salaryPartner: number = 0;
  interval;
  hasTraumaClient:boolean =false;
  hasTraumaPartner:boolean =false;
  
  constructor(
    private sharedDataService: SharedDataService,
    private toastr: ToastrService,
    private familyTreeService :FamilyTreeService,
    ) { 
    this.sharedDataService.upfrontCapitalNeedClient.subscribe(upfrontCapitalNeedClient => this.upfrontCapitalNeedClient = upfrontCapitalNeedClient);
    this.sharedDataService.upfrontCapitalNeedPartner.subscribe(upfrontCapitalNeedPartner => this.upfrontCapitalNeedPartner = upfrontCapitalNeedPartner);
    this.sharedDataService.yearsOffWorkClient.subscribe(yearsOffWorkClient => this.yearsOffWorkClient = yearsOffWorkClient);
    this.sharedDataService.yearsOffWorkPartner.subscribe(yearsOffWorkPartner => this.yearsOffWorkPartner = yearsOffWorkPartner);
    this.sharedDataService.coverForYearsOffWorkClient.subscribe(coverForYearsOffWorkClient => this.coverForYearsOffWorkClient = coverForYearsOffWorkClient);
    this.sharedDataService.coverForYearsOffWorkPartner.subscribe(coverForYearsOffWorkPartner => this.coverForYearsOffWorkPartner = coverForYearsOffWorkPartner);
    this.sharedDataService.totalCoverRequiredClient.subscribe(totalCoverRequiredClient => this.totalCoverRequiredClient = totalCoverRequiredClient);
    this.sharedDataService.totalCoverRequiredPartner.subscribe(totalCoverRequiredPartner => this.totalCoverRequiredPartner = totalCoverRequiredPartner);
    this.sharedDataService.less2YearsPassiveIncomeClient.subscribe(less2YearsPassiveIncomeClient => this.less2YearsPassiveIncomeClient = less2YearsPassiveIncomeClient);
    this.sharedDataService.less2YearsPassiveIncomePartner.subscribe(less2YearsPassiveIncomePartner => this.less2YearsPassiveIncomePartner = less2YearsPassiveIncomePartner);
    this.sharedDataService.salaryOwner.subscribe(salaryOwner => this.salaryOwner = salaryOwner);
    this.sharedDataService.salaryPartner.subscribe(salaryPartner => this.salaryPartner = salaryPartner);
    this.sharedDataService.income.subscribe(income => this.income = income);
    this.sharedDataService.incomePartner.subscribe(incomePartner => this.incomePartner = incomePartner);
  }

  ngOnInit(): void {
    // this.provisionsCompOneObj.getProvisionInfo();

    // this.interval = setInterval(()=> { 
    //   this.totalCoverRequiredClient = this.totalCoverRequiredClient;
    //   this.totalCoverRequiredPartner = this.totalCoverRequiredPartner;
    // }, 1 * 1000);

    this.isLoaded= true;
  }

  ngOnChanges () : void {
    this.totalCoverRequiredClient = this.totalCoverRequiredClient;
    this.totalCoverRequiredPartner = this.totalCoverRequiredPartner;
    this.clientID = this.clientID;
    this.partnerID = this.partnerID;

    if(this.totalCoverRequiredClient > 0) {
      this.totalCoverRequiredClientInput = this.totalCoverRequiredClient;
    } else {
      this.totalCoverRequiredClientInput = 0;
    }
    if(this.totalCoverRequiredPartner > 0) {
      this.totalCoverRequiredPartnerInput = this.totalCoverRequiredPartner;
    } else {
      this.totalCoverRequiredPartnerInput = 0;
    }

    if(this.clientID) {
      this.checkIfInsuranceTypeHas();
    }
  }

  addProposedInsurance(proposedInsuranceInfo, tabType, saveType) {
    this.addProposedInsuranceData = new InsurancesList;

    this.addProposedInsuranceData.familyTreeId = proposedInsuranceInfo.familyTreeId;
    this.addProposedInsuranceData.clientCode = this.client_code;
    
    if(tabType == 'trauma' && saveType == 'addNew') {
      this.addProposedInsuranceData.insuranceCoverType = 3;
      this.addProposedInsuranceData.lifeInsured = ((document.getElementById("lifeInsuredAddTrauma") as HTMLInputElement).value);
      this.addProposedInsuranceData.policyOwner = ((document.getElementById("policyOwnerAddTrauma") as HTMLInputElement).value);
      this.addProposedInsuranceData.underwriter = ((document.getElementById("insurerAddTrauma") as HTMLInputElement).value);
      this.addProposedInsuranceData.sumInsured = ((document.getElementById("sumInsuredAddTrauma") as HTMLInputElement).value);
      this.addProposedInsuranceData.monthlyPremium = ((document.getElementById("monthlyPremiumAddTrauma") as HTMLInputElement).value);
      this.addProposedInsuranceData.structure = ((document.getElementById("structureAddTrauma") as HTMLInputElement).value);
      this.addProposedInsuranceData.fundingMechanism = ((document.getElementById("fundingMechanismAddTrauma") as HTMLInputElement).value);
    } else if(tabType == 'trauma' && saveType == 'defaultClient') {
      this.addProposedInsuranceData.insuranceCoverType = 3;
      this.addProposedInsuranceData.lifeInsured = ((document.getElementById("lifeInsuredAddTraumaClient") as HTMLInputElement).value);
      this.addProposedInsuranceData.policyOwner = ((document.getElementById("policyOwnerAddTraumaClient") as HTMLInputElement).value);
      this.addProposedInsuranceData.underwriter = ((document.getElementById("insurerAddTraumaClient") as HTMLInputElement).value);
      this.addProposedInsuranceData.sumInsured = ((document.getElementById("sumInsuredAddTraumaClient") as HTMLInputElement).value);
      this.addProposedInsuranceData.monthlyPremium = ((document.getElementById("monthlyPremiumAddTraumaClient") as HTMLInputElement).value);
      this.addProposedInsuranceData.structure = ((document.getElementById("structureAddTraumaClient") as HTMLInputElement).value);
      this.addProposedInsuranceData.fundingMechanism = ((document.getElementById("fundingMechanismAddTraumaClient") as HTMLInputElement).value);
    } else if(tabType == 'trauma' && saveType == 'defaultPartner') {
      this.addProposedInsuranceData.insuranceCoverType = 3;
      this.addProposedInsuranceData.lifeInsured = ((document.getElementById("lifeInsuredAddTraumaPartner") as HTMLInputElement).value);
      this.addProposedInsuranceData.policyOwner = ((document.getElementById("policyOwnerAddTraumaPartner") as HTMLInputElement).value);
      this.addProposedInsuranceData.underwriter = ((document.getElementById("insurerAddTraumaPartner") as HTMLInputElement).value);
      this.addProposedInsuranceData.sumInsured = ((document.getElementById("sumInsuredAddTraumaPartner") as HTMLInputElement).value);
      this.addProposedInsuranceData.monthlyPremium = ((document.getElementById("monthlyPremiumAddTraumaPartner") as HTMLInputElement).value);
      this.addProposedInsuranceData.structure = ((document.getElementById("structureAddTraumaPartner") as HTMLInputElement).value);
      this.addProposedInsuranceData.fundingMechanism = ((document.getElementById("fundingMechanismAddTraumaPartner") as HTMLInputElement).value);
    }

    // this.addProposedInsuranceData.insuranceCoverType = ((document.getElementById("insuranceCoverTypeAdd") as HTMLInputElement).value);
    let tabTypeUpper = tabType.toUpperCase();
    if(tabType == 'income') {
      if(this.addProposedInsuranceData.policyOwner == '') {
        this.toastr.error('Error on adding proposed '+tabTypeUpper+'.'); // 'Error on policy owner.'
      } else {
        this.proposedInsuranceAdd(this.addProposedInsuranceData, tabType);
      }
    }

    if(tabType == 'life' || tabType == 'tpd' || tabType == 'trauma') {
      if(this.addProposedInsuranceData.lifeInsured == '') {
        this.toastr.error('Error on adding proposed '+tabTypeUpper+'.'); // 'Error on life insured.'
      } else {
        this.proposedInsuranceAdd(this.addProposedInsuranceData, tabType);
      }
    }
  }


  removeComma(value) {
    if(value == 'null' || value =='') return; // locale returns string of NaN if fail
    return value = value.replace(/,/g, ""); // remove commas
  }

  proposedInsuranceAdd(addProposedInsuranceData, tabType) {
    let tabTypeUpper = tabType.toUpperCase();
    this.familyTreeService.addProposedInsurance(this.addProposedInsuranceData.clientCode, this.addProposedInsuranceData.familyTreeId, addProposedInsuranceData).subscribe((data:ProposedInsuranceAddResponse)=>{
      if(data.error && !data.success){      
        this.toastr.error('Error on adding proposed '+tabTypeUpper+'.');      
      }
      else{
        this.toastr.success('Proposed '+tabTypeUpper+' added successfully.');

        if(tabType == 'trauma') {
          ((document.getElementById("lifeInsuredAddTrauma") as HTMLInputElement).value='');
          ((document.getElementById("policyOwnerAddTrauma") as HTMLInputElement).value='');
          ((document.getElementById("insurerAddTrauma") as HTMLInputElement).value='');
          ((document.getElementById("sumInsuredAddTrauma") as HTMLInputElement).value='');
          ((document.getElementById("monthlyPremiumAddTrauma") as HTMLInputElement).value='');
          ((document.getElementById("structureAddTrauma") as HTMLInputElement).value='');
          ((document.getElementById("fundingMechanismAddTrauma") as HTMLInputElement).value='');
        }
      }

      this.getProposedInsuranceInfo();
    },err=>{   
      console.log(err);
      this.toastr.error('Error on adding proposed '+tabTypeUpper+'.');
    });
  }

  getProposedInsuranceInfo() {
    this.familyTreeService.getProposedInsurance(this.family_tree_id).subscribe((proposedInsuranceData:ProposedInsuranceResponse)=>{
      if(proposedInsuranceData.success){
        this.proposedInsuranceInfo = proposedInsuranceData.response;
        this.checkIfInsuranceTypeHas();
        this.isLoaded8 = true;
      } 
      // this.ngxService.stop(); 
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
    });
  }

  deleteProposedInsurance(proposedInsurance) {
    let id = proposedInsurance.id;

    this.familyTreeService.deleteProposedInsurance(id).subscribe((data:DeleteOtherProvisionResponse)=>{
      if(data.error && !data.success){      
        this.toastr.error("Error on deleting proposed insurance.");      
      }
      else{
        this.getProposedInsuranceInfo();
        this.toastr.success("Proposed insurance deleted successfully.");
      }
    },err=>{   
      console.log(err);
      this.toastr.error("Error on deleting proposed insurance.");
    });    
  }

  proposedInsuranceSave(proposedInsurance, event: any){
    // console.log(spouseDeathOrTPDS);
    let value = event.target.value;
    let inputId = event.target.id;
    // console.log(value);
    // console.log(inputId);

    this.addProposedInsuranceData = new InsurancesList;
    this.addProposedInsuranceData.insuranceCoverType = proposedInsurance.insuranceCoverType;
    this.addProposedInsuranceData.lifeInsured = proposedInsurance.lifeInsured;
    this.addProposedInsuranceData.policyOwner = proposedInsurance.policyOwner;
    this.addProposedInsuranceData.underwriter = proposedInsurance.underwriter;
    this.addProposedInsuranceData.sumInsured = proposedInsurance.sumInsured;
    this.addProposedInsuranceData.monthlyPremium = proposedInsurance.monthlyPremium;
    this.addProposedInsuranceData.structure = proposedInsurance.structure;
    this.addProposedInsuranceData.fundingMechanism = proposedInsurance.fundingMechanism;

    // this.willExecutorsAdd.clientCode = (userType==1)?this.client_code:this.facIdPartner;
    //this.willExecutorsAdd.clientCode = proposedInsurance.clientCode;  // this.client_code

    if(inputId == 'insuranceCoverType') {
      this.addProposedInsuranceData.insuranceCoverType = value;
    }
    if(inputId == 'lifeInsured') {
      this.addProposedInsuranceData.lifeInsured = value;
    }
    if(inputId == 'policyOwner') {
      this.addProposedInsuranceData.policyOwner = value;
    }
    if(inputId == 'underwriter') {
      this.addProposedInsuranceData.underwriter = value;
    }
    if(inputId == 'sumInsured') {
      this.addProposedInsuranceData.sumInsured = value;
    }
    if(inputId == 'monthlyPremium') {
      this.addProposedInsuranceData.monthlyPremium = value;
    }
    if(inputId == 'structure') {
      this.addProposedInsuranceData.structure = value;
    }
    if(inputId == 'fundingMechanism') {
      this.addProposedInsuranceData.fundingMechanism = value;
    }

    // console.log(this.addProposedInsuranceData);
    this.familyTreeService.editProposedInsurance(proposedInsurance.id, this.addProposedInsuranceData).subscribe((data:ProposedInsuranceAddResponse)=>{
      if(data.error && !data.success){      
        this.toastr.error('Error on updating proposed insurance.');      
      }
      else{
        this.toastr.success('Proposed insurance updated successfully.');
      }

      // this.getProposedInsuranceInfo();
    },err=>{   
      console.log(err);
      this.toastr.error('Error on updating proposed insurance.');
    });
  }

  checkIfInsuranceTypeHas(){
    var clientID = this.clientID;
    var partnerID = this.partnerID;
    
    if(this.proposedInsuranceInfo){
      var isPresent1 = false;
      var isPresent2 = false;
      
      if(this.proposedInsuranceInfo.clientInsurances.length > 0) {
        var isCoverType = this.proposedInsuranceInfo.clientInsurances.some(function(el){ return el.insuranceCoverType === 3 });
        var target =  this.proposedInsuranceInfo.clientInsurances.filter(function(el) { return el.insuranceCoverType == 3;});
        
        if (isCoverType) {
          var isClient = target.some(function(el){ return el.lifeInsured === clientID });
          var isPresent = target.some(function(el){ return el.lifeInsured === partnerID });
          if (isClient) {
            isPresent1 = true;
          } else {
            isPresent1 = false;
          }
          if (isPresent) {
            isPresent2 = true;
          } else {
            isPresent2 = false;
          }
        } else {
          isPresent1 = false;
          isPresent2 = false;
        }
      }

      if(this.proposedInsuranceInfo.partnerInsurances.length > 0) {
        var isCoverType = this.proposedInsuranceInfo.partnerInsurances.some(function(el){ return el.insuranceCoverType === 3 });
        var target =  this.proposedInsuranceInfo.partnerInsurances.filter(function(el) { return el.insuranceCoverType == 3;});

        if (isCoverType) {
          var isClient = target.some(function(el){ return el.lifeInsured === clientID });
          var isPresent = target.some(function(el){ return el.lifeInsured === partnerID });
          if (isClient) {
            isPresent1 = true;
          } else {
            isPresent1 = false;
          }
          if (isPresent) {
            isPresent2 = true;
          } else {
            isPresent2 = false;
          }
        } else {
          isPresent1 = false;
          isPresent2 = false;
        }
      }

      if(isPresent1) {
        this.hasTraumaClient = true;
      } else {
        this.hasTraumaClient = false;
      }

      if(isPresent2) {
        this.hasTraumaPartner = true;
      } else {
        this.hasTraumaPartner = false;
      }
    }
}

}
