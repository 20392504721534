<ng-container *ngIf="isLoaded">
    <table class="table tablecollect">
        <thead>
        <tr>
            <th class="tc2 font-weight-normal pt-0 pb-0 tab_fs">Dependant Support</th>
            <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs pdAlignTableHead val1">Age</th>
            <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs val2">Per Year</th>
        </tr>
        </thead>
        <tbody>
            
        <ng-container *ngFor="let dependentSupports of provisionInfo.dependentSupports; let i = index">
            <tr>
            <td class="tablefist tableb">
                <ng-container *ngIf="dependentSupports.dependentName">{{dependentSupports.dependentName}}</ng-container>
                <ng-container *ngIf="dependentSupports.dependentName==null">-</ng-container>
            </td>
            <td class="tableleftbedit edit-border ">
                <ng-container *ngIf="dependentSupports.age == 0 || dependentSupports.age == ''">
                    <input value="18" id="age" type="number" class="form-control neweditinput" placeholder="Age" (change)="dependantSupportSave(dependentSupports, $event)">
                </ng-container>
                <ng-container *ngIf="dependentSupports.age">
                    <input value="{{dependentSupports.age}}" id="age" type="number" class="form-control neweditinput" placeholder="Age" (change)="dependantSupportSave(dependentSupports, $event)">
                </ng-container>
                
            </td>
            <td class="tableleftbedit edit-border">
                <input [(ngModel)]="dependentSupports.yearAmount" id="yearAmount" mask="separator.0" thousandSeparator="," type="text" class="form-control neweditinput do" placeholder="Per year" (change)="dependantSupportSave(dependentSupports, $event)">
            </td>
            </tr>
        </ng-container>
        <ng-container *ngIf="provisionInfo.dependentSupports.length==0">
            <tr>
            <td colspan="3" >No data</td>
            </tr>
        </ng-container>
        </tbody>
    </table>
</ng-container>