<ng-container *ngIf="isLoaded3">
    <div class="boxcolor mt-2">
      <table class="table newtable mb-0 tableinsurance">
        <thead>
          <tr>
            <th class="tc2 font-weight-normal ml-2 tab_fs">Income Protection</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="ins_summary ins_life" style="width: 237px;">{{clientFirstname}}</td>
            <td class="ins_life" style="width: 155px;">EXISTING</td>
            <td class="ins_life" >PROPOSED</td>
          </tr>
          <tr>
            <td>Insurer</td>
            <td>{{insurerIncome}}</td>
            <td>{{ipInsurerProposed}}</td>
          </tr>
          <!-- <tr>
            <td>Life Sum Insured</td>
            <td>${{lifeSumInsuredIncome}}</td>
            <td>${{ipLifeSumInsured}}</td>
          </tr> -->
          <!-- <tr>
            <td>TPD Sum Insured</td>
            <td>-</td>
          </tr> -->
          <tr>
            <td>Monthly Benefit</td> <!-- Monthly Premium -->
            <td>${{monthlyBenefitAmountIncome | number: '1.0-0'}}</td> <!-- monthlyPremiumIncome -->
            <td>${{ipMonthlyBenefit | number: '1.0-0'}}</td> <!-- ipMonthlyPremium -->
          </tr>
          <tr>
            <td>Waiting Period</td>
            <td><ng-container *ngIf="ipWaitingPeriodExisting">{{ipWaitingPeriodExisting}}</ng-container> <ng-container *ngIf="ipWaitingPeriodExisting == null">-</ng-container></td>
            <td><ng-container *ngIf="ipWaitingPeriodProposed != '-'">{{ipWaitingPeriodProposed}}</ng-container> <ng-container *ngIf="ipWaitingPeriodProposed == '-'">-</ng-container></td>
          </tr>
          <tr>
            <td>Benefit Period</td>
            <td><ng-container *ngIf="ipBenefitPeriodExisting">{{ipBenefitPeriodExisting}}</ng-container> <ng-container *ngIf="ipBenefitPeriodExisting == null">-</ng-container></td>
            <td><ng-container *ngIf="ipBenefitPeriodProposed != '-'">{{ipBenefitPeriodProposed}}</ng-container> <ng-container *ngIf="ipBenefitPeriodProposed == '-'">-</ng-container></td>
          </tr>
          <tr>
            <td>Monthly Premium</td>
            <td>${{monthlyPremiumIncome}}</td>
            <td>${{ipMonthlyPremium}}</td>
          </tr>
          <tr>
            <td>Structure</td>
            <td>-</td>
            <td>{{ipStructureProposed}}</td>
          </tr>
          <tr>
            <td>Policy Definition</td>
            <td>-</td>
            <td>{{ipPolicyDefinitionProposed}}</td>
          </tr>
          <tr>
            <td>Funding Mechanism</td>
            <td>-</td>
            <td>{{ipFundingMechanismProposed}}</td>
          </tr>
          <!-- <tr class="tableinsurancetr">
            <td>Funding Mechanism</td>
            <td></td>
          </tr> -->
          <tr>
            <td> &nbsp; </td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <br/>
      <table class="table pb-2 newtable tableinsurance">
        <tbody>
          <tr>
            <td class="ins_summary ins_life" style="width: 237px;">{{partnerFirstname}}</td>
            <td class="ins_life" style="width: 155px;">EXISTING</td>
            <td class="ins_life">PROPOSED</td>
          </tr>
          <tr>
            <td>Insurer</td>
            <td>{{insurerIncomePartner}}</td>
            <td>{{ipInsurerProposedPartner}}</td>
          </tr>
          <!-- <tr>
            <td>Life Sum Insured</td>
            <td>${{lifeSumInsuredIncomePartner | number: '1.0-0'}}</td>
            <td>${{ipLifeSumInsuredPartner}}</td>
          </tr> -->
          <!-- <tr>
            <td>TPD Sum Insured</td>
            <td>-</td>
          </tr> -->
          <tr>
            <td>Monthly Benefit</td><!-- Monthly Premium --> 
            <td>${{monthlyBenefitAmountIncomePartner | number: '1.0-0'}}</td> <!-- monthlyPremiumIncomePartner -->
            <td>${{ipMonthlyBenefitPartner | number: '1.0-0'}}</td> <!-- ipMonthlyPremiumPartner -->
          </tr>
          <tr>
            <td>Waiting Period</td>
            <td><ng-container *ngIf="ipWaitingPeriodExistingPartner">{{ipWaitingPeriodExistingPartner}}</ng-container> <ng-container *ngIf="ipWaitingPeriodExistingPartner == null">-</ng-container></td>
            <td><ng-container *ngIf="ipWaitingPeriodProposedPartner != '-'">{{ipWaitingPeriodProposedPartner}}</ng-container> <ng-container *ngIf="ipWaitingPeriodProposedPartner == '-'">-</ng-container></td>
          </tr>
          <tr>
            <td>Benefit Period</td>
            <td><ng-container *ngIf="ipBenefitPeriodExistingPartner">{{ipBenefitPeriodExistingPartner}}</ng-container> <ng-container *ngIf="ipBenefitPeriodExistingPartner == null">-</ng-container></td>
            <td><ng-container *ngIf="ipBenefitPeriodProposedPartner != '-'">{{ipBenefitPeriodProposedPartner}}</ng-container> <ng-container *ngIf="ipBenefitPeriodProposedPartner == '-'">-</ng-container></td>
          </tr>
          <tr>
            <td>Monthly Premium</td>
            <td>${{monthlyPremiumIncomePartner}}</td>
            <td>${{ipMonthlyPremiumPartner}}</td>
          </tr>
          <tr>
            <td>Structure</td>
            <td>-</td>
            <td>{{ipStructureProposedPartner}}</td>
          </tr>
          <tr>
            <td>Policy Definition</td>
            <td>-</td>
            <td>{{ipPolicyDefinitionProposedPartner}}</td>
          </tr>
          <tr>
            <td>Funding Mechanism</td>
            <td>-</td>
            <td>{{ipFundingMechanismProposedPartner}}</td>
          </tr>
          <tr>
            <td> &nbsp; </td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <br/>


      <table class="table newtable mb-0 tableinsurance">
        <thead>
          <tr>
            <th class="tc2 font-weight-normal ml-2 tab_fs">Life Insurance</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="ins_summary ins_life" style="width: 237px;">{{clientFirstname}}</td>
            <td class="ins_life" style="width: 155px;">EXISTING</td>
            <td class="ins_life">PROPOSED</td>
          </tr>
          <tr>
            <td>Insurer</td>
            <td>{{insurerLife}}</td>
            <td>{{lifeInsurerProposed}}</td>
          </tr>
          <tr>
            <td>Life Sum Insured</td>
            <td>${{lifeSumInsuredLife | number: '1.0-0'}}</td>
            <td>${{lifeLifeSumInsured | number: '1.0-0'}}</td>
          </tr>
          <tr>
            <td>TPD Sum Insured</td>
            <td>-</td>
            <td>${{lifeTPDSumInsured}}</td>
          </tr>
          <tr>
            <td>Monthly Premium</td>
            <td>${{monthlyPremiumLife | number: '1.0-0'}}</td>
            <td>${{lifeMonthlyPremium | number: '1.0-0'}}</td>
          </tr>
          <tr>
            <td>Premium Structure</td>
            <td>-</td>
            <td>{{lifeStructureProposed}}</td>
          </tr>
          <tr>
            <td>Funding Mechanism</td>
            <td>-</td>
            <td>{{lifeFundingMechanismProposed}}</td>
          </tr>
          <!-- <tr>
            <td>Waiting Period</td>
            <td><ng-container *ngIf="lifeWaitingPeriodExisting">{{lifeWaitingPeriodExisting}}</ng-container> <ng-container *ngIf="lifeWaitingPeriodExisting == null">-</ng-container></td>
            <td><ng-container *ngIf="lifeWaitingPeriodProposed != '-'">{{lifeWaitingPeriodProposed}} Days</ng-container> <ng-container *ngIf="lifeWaitingPeriodProposed == '-'">-</ng-container></td>
          </tr>
          <tr>
            <td>Benefit Period</td>
            <td><ng-container *ngIf="lifeBenefitPeriodExisting">{{lifeBenefitPeriodExisting}}</ng-container> <ng-container *ngIf="lifeBenefitPeriodExisting == null">-</ng-container></td>
            <td><ng-container *ngIf="lifeBenefitPeriodProposed != '-'">{{lifeBenefitPeriodProposed}} Days</ng-container> <ng-container *ngIf="lifeBenefitPeriodProposed == '-'">-</ng-container></td>
          </tr> -->
          <!-- <tr class="tableinsurancetr">
            <td>Funding Mechanism</td>
            <td></td>
          </tr> -->
          <tr>
            <td> &nbsp; </td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <br/>
      <table class="table pb-2 newtable tableinsurance">
        <tbody>
          <tr>
            <td class="ins_summary ins_life" style="width: 237px;">{{partnerFirstname}}</td>
            <td class="ins_life" style="width: 155px;">EXISTING</td>
            <td class="ins_life">PROPOSED</td>
          </tr>
          <tr>
            <td>Insurer</td>
            <td>{{insurerLifePartner}}</td>
            <td>{{lifeInsurerProposedPartner}}</td>
          </tr>
          <tr>
            <td>Life Sum Insured</td>
            <td>${{lifeSumInsuredLifePartner| number: '1.0-0'}}</td>
            <td>${{lifeLifeSumInsuredPartner | number: '1.0-0'}}</td>
          </tr>
          <tr>
            <td>TPD Sum Insured</td>
            <td>-</td>
            <td>${{lifeTPDSumInsuredPartner | number: '1.0-0'}}</td>
          </tr>
          <tr>
            <td>Monthly Premium</td>
            <td>${{monthlyPremiumLifePartner | number: '1.0-0'}}</td>
            <td>${{lifeMonthlyPremiumPartner | number: '1.0-0'}}</td>
          </tr>
          <tr>
            <td>Premium Structure</td>
            <td>-</td>
            <td>{{lifeStructureProposedPartner}}</td>
          </tr>
          <tr>
            <td>Funding Mechanism</td>
            <td>-</td>
            <td>{{lifeFundingMechanismProposedPartner}}</td>
          </tr>
          <!-- <tr>
            <td>Waiting Period</td>
            <td><ng-container *ngIf="lifeWaitingPeriodExistingPartner">{{lifeWaitingPeriodExistingPartner}}</ng-container> <ng-container *ngIf="lifeWaitingPeriodExistingPartner == null">-</ng-container></td>
            <td><ng-container *ngIf="lifeWaitingPeriodProposedPartner != '-'">{{lifeWaitingPeriodProposedPartner}} Days</ng-container> <ng-container *ngIf="lifeWaitingPeriodProposedPartner == '-'">-</ng-container></td>
          </tr>
          <tr>
            <td>Benefit Period</td>
            <td><ng-container *ngIf="lifeBenefitPeriodExistingPartner">{{lifeBenefitPeriodExistingPartner}}</ng-container> <ng-container *ngIf="lifeBenefitPeriodExistingPartner == null">-</ng-container></td>
            <td><ng-container *ngIf="lifeBenefitPeriodProposedPartner != '-'">{{lifeBenefitPeriodProposedPartner}} Days</ng-container> <ng-container *ngIf="lifeBenefitPeriodProposedPartner == '-'">-</ng-container></td>
          </tr> -->
          <tr>
            <td> &nbsp; </td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>

      <br/>

      <table class="table newtable mb-0 tableinsurance">
        <thead>
          <tr>
            <th class="tc2 font-weight-normal ml-2 tab_fs">TPD</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="ins_summary ins_life" style="width: 237px;">{{clientFirstname}}</td>
            <td class="ins_life" style="width: 155px;">EXISTING</td>
            <td class="ins_life">PROPOSED</td>
          </tr>
          <tr>
            <td>Insurer</td>
            <td>{{insurerTPD}}</td>
            <td>{{tpdInsurerProposed}}</td>
          </tr>
          <tr>
            <td>Life Sum Insured</td>
            <td>${{lifeSumInsuredTPD}}</td>
            <td>${{tpdLifeSumInsured}}</td>
          </tr>
          <tr>
            <td>TPD Sum Insured</td>
            <td>-</td>
            <td>${{tpdTPDSumInsured | number: '1.0-0'}}</td>
          </tr>
          <tr>
            <td>Monthly Premium</td>
            <td>${{monthlyPremiumTPD | number: '1.0-0'}}</td>
            <td>${{tpdMonthlyPremium | number: '1.0-0'}}</td>
          </tr>
          <tr>
            <td>Premium Structure</td>
            <td>-</td>
            <td>{{tpdStructureProposed}}</td>
          </tr>
          <tr>
            <td>Funding Mechanism</td>
            <td>-</td>
            <td>{{tpdFundingMechanismProposed}}</td>
          </tr>
          <!-- <tr>
            <td>Waiting Period</td>
            <td><ng-container *ngIf="tpdWaitingPeriodExisting">{{tpdWaitingPeriodExisting}}</ng-container> <ng-container *ngIf="tpdWaitingPeriodExisting == null">-</ng-container></td>
            <td><ng-container *ngIf="tpdWaitingPeriodProposed != '-'">{{tpdWaitingPeriodProposed}} Days</ng-container> <ng-container *ngIf="tpdWaitingPeriodProposed == '-'">-</ng-container></td>
          </tr>
          <tr>
            <td>Benefit Period</td>
            <td><ng-container *ngIf="tpdBenefitPeriodExisting">{{tpdBenefitPeriodExisting}}</ng-container> <ng-container *ngIf="tpdBenefitPeriodExisting == null">-</ng-container></td>
            <td><ng-container *ngIf="tpdBenefitPeriodProposed != '-'">{{tpdBenefitPeriodProposed}} Days</ng-container> <ng-container *ngIf="tpdBenefitPeriodProposed == '-'">-</ng-container></td>
          </tr> -->
          <!-- <tr class="tableinsurancetr">
            <td>Funding Mechanism</td>
            <td></td>
          </tr> -->
          <tr>
            <td> &nbsp; </td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <br/>
      <table class="table pb-2 newtable tableinsurance">
        <tbody>
          <tr>
            <td class="ins_summary ins_life" style="width: 237px;">{{partnerFirstname}}</td>
            <td class="ins_life" style="width: 155px;">EXISTING</td>
            <td class="ins_life">PROPOSED</td>
          </tr>
          <tr>
            <td>Insurer</td>
            <td>{{insurerTPDPartner}}</td>
            <td>{{tpdInsurerProposedPartner}}</td>
          </tr>
          <tr>
            <td>Life Sum Insured</td>
            <td>${{lifeSumInsuredTPDPartner | number: '1.0-0'}}</td>
            <td>${{tpdLifeSumInsuredPartner | number: '1.0-0'}}</td>
          </tr>
          <tr>
            <td>TPD Sum Insured</td>
            <td>-</td>
            <td>${{tpdTPDSumInsuredPartner | number: '1.0-0'}}</td>
          </tr>
          <tr>
            <td>Monthly Premium</td>
            <td>${{monthlyPremiumTPDPartner | number: '1.0-0'}}</td>
            <td>${{tpdMonthlyPremiumPartner | number: '1.0-0'}}</td>
          </tr>
          <tr>
            <td>Premium Structure</td>
            <td>-</td>
            <td>{{tpdStructureProposedPartner}}</td>
          </tr>
          <tr>
            <td>Funding Mechanism</td>
            <td>-</td>
            <td>{{tpdFundingMechanismProposedPartner}}</td>
          </tr>
          <!-- <tr>
            <td>Waiting Period</td>
            <td><ng-container *ngIf="tpdWaitingPeriodExistingPartner">{{tpdWaitingPeriodExistingPartner}}</ng-container> <ng-container *ngIf="tpdWaitingPeriodExistingPartner == null">-</ng-container></td>
            <td><ng-container *ngIf="tpdWaitingPeriodProposedPartner != '-'">{{tpdWaitingPeriodProposedPartner}} Days</ng-container> <ng-container *ngIf="tpdWaitingPeriodProposedPartner == '-'">-</ng-container></td>
          </tr>
          <tr>
            <td>Benefit Period</td>
            <td><ng-container *ngIf="tpdBenefitPeriodExistingPartner">{{tpdBenefitPeriodExistingPartner}}</ng-container> <ng-container *ngIf="tpdBenefitPeriodExistingPartner == null">-</ng-container></td>
            <td><ng-container *ngIf="tpdBenefitPeriodProposedPartner != '-'">{{tpdBenefitPeriodProposedPartner}} Days</ng-container> <ng-container *ngIf="tpdBenefitPeriodProposedPartner == '-'">-</ng-container></td>
          </tr> -->
          <tr>
            <td> &nbsp; </td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>

      <br/>

      <table class="table newtable mb-0 tableinsurance">
        <thead>
          <tr>
            <th class="tc2 font-weight-normal ml-2 tab_fs">Trauma</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="ins_summary ins_life" style="width: 237px;">{{clientFirstname}}</td>
            <td class="ins_life" style="width: 155px;">EXISTING</td>
            <td class="ins_life">PROPOSED</td>
          </tr>
          <tr>
            <td>Policy Owner</td>
            <td>{{traumapolicyOwnerExisting}}</td>
            <td>{{traumapolicyOwnerProposed}}</td>
          </tr>
          <tr>
            <td>Insurer</td>
            <td>{{insurerTrauma}}</td>
            <td>{{traumaInsurerProposed}}</td>
          </tr>
          <tr>
            <td>Life Sum Insured</td>
            <td>${{lifeSumInsuredTrauma | number: '1.0-0'}}</td>
            <td>${{traumaLifeSumInsured | number: '1.0-0'}}</td>
          </tr>
          <tr>
            <td>Monthly Premium</td>
            <td>${{monthlyPremiumTrauma | number: '1.0-0'}}</td>
            <td>${{traumaMonthlyPremium | number: '1.0-0'}}</td>
          </tr>
          <tr>
            <td>Premium Structure</td>
            <td>-</td>
            <td>{{traumaStructureProposed}}</td>
          </tr>
          <tr>
            <td>Funding Mechanism</td>
            <td>-</td>
            <td>{{traumaFundingMechanismProposed}}</td>
          </tr>
          <!-- <tr>
            <td>Waiting Period</td>
            <td><ng-container *ngIf="traumaWaitingPeriodExisting">{{traumaWaitingPeriodExisting}}</ng-container> <ng-container *ngIf="traumaWaitingPeriodExisting == null">-</ng-container></td>
            <td><ng-container *ngIf="traumaWaitingPeriodProposed != '-'">{{traumaWaitingPeriodProposed}} Days</ng-container> <ng-container *ngIf="traumaWaitingPeriodProposed == '-'">-</ng-container></td>
          </tr>
          <tr>
            <td>Benefit Period</td>
            <td><ng-container *ngIf="traumaBenefitPeriodExisting">{{traumaBenefitPeriodExisting}}</ng-container> <ng-container *ngIf="traumaBenefitPeriodExisting == null">-</ng-container></td>
            <td><ng-container *ngIf="traumaBenefitPeriodProposed != '-'">{{traumaBenefitPeriodProposed}} Days</ng-container> <ng-container *ngIf="traumaBenefitPeriodProposed == '-'">-</ng-container></td>
          </tr> -->
          <!-- <tr class="tableinsurancetr">
            <td>Funding Mechanism</td>
            <td></td>
          </tr> -->
          <tr>
            <td> &nbsp; </td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <br/>
      <table class="table pb-2 newtable tableinsurance">
        <tbody>
          <tr>
            <td class="ins_summary ins_life" style="width: 237px;">{{partnerFirstname}}</td>
            <td class="ins_life" style="width: 155px;">EXISTING</td>
            <td class="ins_life">PROPOSED</td>
          </tr>
          <tr>
            <td>Policy Owner</td>
            <td>{{traumapolicyOwnerExistingPartner}}</td>
            <td>{{traumapolicyOwnerProposedPartner}}</td>
          </tr>
          <tr>
            <td>Insurer</td>
            <td>{{insurerTraumaPartner}}</td>
            <td>{{traumaInsurerProposedPartner}}</td>
          </tr>
          <tr>
            <td>Life Sum Insured</td>
            <td>${{lifeSumInsuredTraumaPartner | number: '1.0-0'}}</td>
            <td>${{traumaLifeSumInsuredPartner | number: '1.0-0'}}</td>
          </tr>
          <tr>
            <td>Monthly Premium</td>
            <td>${{monthlyPremiumTraumaPartner | number: '1.0-0'}}</td>
            <td>${{traumaMonthlyPremiumPartner | number: '1.0-0'}}</td>
          </tr>
          <tr>
            <td>Premium Structure</td>
            <td>-</td>
            <td>{{traumaStructureProposedPartner}}</td>
          </tr>
          <tr>
            <td>Funding Mechanism</td>
            <td>-</td>
            <td>{{traumaFundingMechanismProposedPartner}}</td>
          </tr>
          <!-- <tr>
            <td>Waiting Period</td>
            <td><ng-container *ngIf="traumaWaitingPeriodExistingPartner">{{traumaWaitingPeriodExistingPartner}}</ng-container> <ng-container *ngIf="traumaWaitingPeriodExistingPartner == null">-</ng-container></td>
            <td><ng-container *ngIf="traumaWaitingPeriodProposedPartner != '-'">{{traumaWaitingPeriodProposedPartner}} Days</ng-container> <ng-container *ngIf="traumaWaitingPeriodProposedPartner == '-'">-</ng-container></td>
          </tr>
          <tr>
            <td>Benefit Period</td>
            <td><ng-container *ngIf="traumaBenefitPeriodExistingPartner">{{traumaBenefitPeriodExistingPartner}}</ng-container> <ng-container *ngIf="traumaBenefitPeriodExistingPartner == null">-</ng-container></td>
            <td><ng-container *ngIf="traumaBenefitPeriodProposedPartner != '-'">{{traumaBenefitPeriodProposedPartner}} Days</ng-container> <ng-container *ngIf="traumaBenefitPeriodProposedPartner == '-'">-</ng-container></td>
          </tr> -->
          <tr>
            <td> &nbsp; </td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>

    </div>
  </ng-container>