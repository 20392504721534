export class ProvisionModelBaseMOdel {
    public familyTreeId: string;
    public dependentSupports: DependentSupportsList[];
    public spouseDeathOrTPDs: SpouseDeathOrTPDs;
    public benefitPeriods: BenefitPeriods;
    public otherProvisions: OtherProvisions;
    public immediateNeeds: ImmediateNeeds;
    public progressiveNeeds: ProgressiveNeeds;
    public otherProvisionList: OtherProvisionList[];
    public note: string;
}

export class ProvisionModel extends ProvisionModelBaseMOdel{    
    OtherProvisionsAddItems;
}

export class DependentSupportsList{
    public id: string;
    public dependentName: string;
    public recordStatus: number = 0;
    public dependentId: string;
    public age: number = 0;
    public yearAmount: number = 0;
}

export class SpouseDeathOrTPDs{
    public clientDeathOrTPDItems: DeathOrTPDItems;
    public partnerDeathOrTPDItems: DeathOrTPDItems;
}

export class DeathOrTPDItems{
    public id: string;
    public recordStatus: number = 0;
    public clientCode: string;
    public returnToWork: boolean;
    public yearsNeedOf: number = 0;
    public incomeEarnUponReturn: number = 0;
}

export class BenefitPeriods{
    public clientBenefitPeriods: BenefitPeriodsItems;
    public partnerBenefitPeriods: BenefitPeriodsItems;
}

export class BenefitPeriodsItems{
    public id: string;
    public recordStatus: number = 0;
    public clientCode: string;
    public value: number = 0;
}

export class OtherProvisions{
    public clientOtherProvisions: OtherProvisionsItems;
    public partnerOtherProvisions: OtherProvisionsItems;
}

export class OtherProvisionsItems{
    public id: string;
    public recordStatus: number = 0;
    public clientCode: string;
    public value: number = 0;
    public description: string;
}

export class ImmediateNeeds{
    public clientImmediateNeeds: ImmediateNeedsItems;
    public partnerImmediateNeeds: ImmediateNeedsItems;
}

export class ImmediateNeedsItems{
    public id: string;
    public clientCode: string;
    public homePurchase: number = 0;
    public modificationHome: number = 0;
    public medicalTreatment: number = 0;
    public funeralLegalExpenses: number = 0;
    public upfrontCapitalNeeds: number = 0;
}

export class ProgressiveNeeds{
    public clientProgressiveNeeds: ProgressiveNeedsItems;
    public partnerProgressiveNeeds: ProgressiveNeedsItems;
}

export class ProgressiveNeedsItems{
    public id: string;
    public clientCode: string;
    public provisionForTotallyDisabled: number = 0;
    public passiveIncomeForSpouse: number = 0;
    public passiveIncomeContinuedPaid: number = 0;
    public provisionForOngoingMedicalCosts: number = 0;
    public provisionForMedicalCosts: number = 0;
    public incomeLeavePerChild: number = 0;
}

export class OtherProvisionList{
    public description: string;
    public clientProvision: OtherProvisionsItems;
    public partnerProvision: OtherProvisionsItems;
}

export class OtherProvisionsAddItems{
    public familyTreeId: string;
    public clientCode: string;
    public description: string;
    public value: number = 0;
}