<div class="col-md-12" style="background: #f9f9f9; padding: 20px 20px 20px; text-align: right;">
 
    <span style="font-size: 13px;">Select graph:</span>
      <!-- <div class="form-group" style="width:50%; margin-left: auto; margin-right: auto; margin-bottom: 0px;"> -->
        <select (change)="onWillDistributionChange($event.target.value)" class="form-control sel_ft" id="exampleFormControlSelect1" style="width: 220px; display: inline; margin-top: -5px; margin-left: 5px; font-size: 13px; height: 33px; border: 1px solid #ced4da; padding-left: 10px;">
            <option value="1">Standard Will Distribution</option>
            <option value="2">New De Facto Distribution</option>
            <option value="3">Age Based Dependencies</option>
            <option value="4">Testamentary Discretionary Trust</option>
            <option value="5">Personal Succession Plan</option>
            <option value="6">Protected Wealth</option>
        </select>
      <!-- </div> -->
    </div>
<div class="boxcolor mt-2 scroll_content scroll_height" style="margin-left: 0px; margin-right: 0px;margin-top: 0px !important; background-color: #ffffff;">
  
<!-- {{tree | json}} -->

<!-- <div class="tree d-flex justify-content-center"> -->
<div class="tree">
  <ul>  
        <!-- <app-draw-tree [tree]="tree" ></app-draw-tree> -->
  <!-- <ng-container *ngFor="let node of tree ;let i=index;">
        <li>
            <a href="javascript:void(0);" [ngClass]="node.isMainNode ? 'parent' : ''" class="parent">{{node.firstName}}</a>  
            <span class="textname"></span>
            
        </li>
        <ng-container *ngIf="node.spouse && node.spouse.length > 0">
            
        </ng-container>
                
  </ng-container>   -->
  </ul>

  <!-- <div> -->
    <!-- <div #canvasWrapper class="canvas-wrapper" (window:resize)="draw()"> -->
      <!-- <canvas [class.display_none]="willType==5 || willType==6" #canvas width="{{width}}" height="{{height}}"></canvas> -->
    <!-- </div> -->

    <!--  -->

    <!-- <div style="position: absolute; top: 100px; bottom: 0; left: 0; right: 0;" >
      <pan-zoom [config]="panZoomConfig"> -->
        
        <!-- <div > -->
          <!-- <pinch-zoom [backgroundColor]="'#f9f9f9'"> -->
            <!-- <div mouseWheel (mouseWheelUp)="handleScroll(event)" (mouseWheelDown)="handleScroll(event)"> -->
              
              <!-- <div style="color: #f9f9f9; background-color: #f9f9f9;" #fullScreen> -->
                <a class="fullscreen_btn" (click)="openFullscreen()" href="javascript:void(0);" title="Full Screen"> Fullscreen </a>  
              <canvas style="color: #f9f9f9; background-color: #ffffff;width: 130%;" [class.display_none]="willType==5 || willType==6" #canvas width="{{width}}" id="canvas" height="{{height}}"></canvas>
              <!-- </div> -->
            <!-- </div> -->
          <!-- </pinch-zoom> -->
        <!-- </div> -->

      <!-- </pan-zoom>
    </div> -->

    <div *ngIf="willType==5"> 
        <img class="img-fluid" src="../../assets/est.jpg" alt="">
    </div>

    <!-- red text -->
    <div *ngIf="willType==6" class="container d-flex h-100 flex-column">
        <div class="flex-grow-1"></div>
        <div class="row justify-content-center">
            <div class="col-3">
                <div style="font-size: 44px;color: red; text-align: center; margin-top: 50%; margin-bottom: 50%;">$0</div>
            </div>
        </div>
        <div class="flex-grow-1"></div>
      </div>
      <!-- red text end -->
    <!-- ../../assets//myfortress.png -->
  <!-- </div> -->
  
<div>
  
</div>

</div>