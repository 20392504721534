import { Component, OnInit, Input } from '@angular/core';
import { FamilyTreeService } from 'src/app/family-tree/family-tree.service';
import { ToastrService } from 'ngx-toastr';
import { WillResponse, WillExecutorsResponse, DeleteOtherProvisionResponse, XplanChoicesResponse } from 'src/app/shared/models/response.model';
import { WillModel, ExecutorsItems, AddWillExecutor, ExecutorsList } from 'src/app/shared/models/will.model';
import { HttpErrorResponse } from '@angular/common/http';
import { XplanChoices } from 'src/app/shared/models/xplan-choices.model';

@Component({
  selector: 'app-executor',
  templateUrl: './executor.component.html',
  styleUrls: ['../../rightside.component.css']
})
export class ExecutorComponent implements OnInit {

  @Input() family_tree_id:string;
  @Input() client_code:string;
  @Input() partnerClientCode:string;
  @Input() clientFirstname:string;
  @Input() partnerFirstname:string;
  @Input() partnerHas:boolean;
  @Input() willInfo:WillModel;
  isLoaded6:boolean;
  // willInfo:WillModel;
  addWillExecutorD: any=[];
  willExecutorsAdd:ExecutorsItems;
  willExecutorsNoteAdd:ExecutorsList;
  choicesList : XplanChoices[];
  executortype_list:any;

  constructor(
    private familyTreeService :FamilyTreeService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getChoiceList();
  }

  getChoiceList(){
    this.familyTreeService.getChoiceList().subscribe((clientData:XplanChoicesResponse)=>{
      if(clientData.success){
        this.choicesList = clientData.response;
        this.executortype_list = this.sortByKey(this.choicesList.filter(choice => choice.choiceType === 36));
      }
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message); 
    });
  }

  sortByKey(choiceArray){
    return choiceArray.sort((n1,n2) => {
      if (n1.key > n2.key) {
          return 1;
      }
  
      if (n1.key < n2.key) {
          return -1;
      }                                      
      return 0;
    });
  }

  getWillInfo(){
    // this.ngxService.start();
    this.familyTreeService.getWill(this.family_tree_id).subscribe((willData:WillResponse)=>{
      if(willData.success){
        this.willInfo = willData.response;
        
        // let executorNmae = this.willInfo.executors.clientExecutors.name,
        // executorType = this.willInfo.executors.clientExecutors.combinedRetirementIncome,
        // precentageIfPartnerDeceased = this.willInfo.executors.partnerExecutors.precentageIfPartnerDeceased,
        // precentageIfPartnerDeceased = this.willInfo.executors.partnerExecutors.precentageIfPartnerDeceased;
          
        this.isLoaded6 = true;
      } 
      // this.ngxService.stop(); 
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });
  }

  willExecutorsSave(willExecutors, event: any){
    // console.log(spouseDeathOrTPDS);
    let value = event.target.value;
    let inputId = event.target.id;
    // console.log(value);
    // console.log(inputId);

    this.willExecutorsAdd = new ExecutorsItems;

    // this.willExecutorsAdd.clientCode = (userType==1)?this.client_code:this.facIdPartner;
    this.willExecutorsAdd.clientCode = willExecutors.clientCode;  // this.client_code
    this.willExecutorsAdd.xPlanIndex = willExecutors.xPlanIndex;

    if(inputId == 'executorName' || inputId == 'executorNamePartner') {
      this.willExecutorsAdd.name = value;
      this.willExecutorsAdd.type = willExecutors.type;
    }
    if(inputId == 'executorType' || inputId == 'executorTypePartner') {
      this.willExecutorsAdd.name = willExecutors.name;
      this.willExecutorsAdd.type = value;
    }

    let arrayO = {executorType: value, xPlanIndex:willExecutors.xPlanIndex}

    
    // this.familyTreeService.editWillExecutors(willExecutors.id, this.willExecutorsAdd).subscribe((data:WillExecutorsResponse)=>{
    this.familyTreeService.editWillExecutors(willExecutors.clientCode, arrayO ).subscribe((data:WillExecutorsResponse)=>{
      if(data.error && !data.success){      
        this.toastr.error('Error on updating executor user.');      
      }
      else{
        this.toastr.success('Executor user updated successfully.');
      }

      this.getWillInfo();
    },err=>{   
      console.log(err);
      this.toastr.error('Error on updating executor user.');
    });
  }


  addWillExecutors(executors, userType) {
    //console.log(willInfo);
    this.addWillExecutorD = new AddWillExecutor;

    this.addWillExecutorD.familyExecutorId = executors.id;

    if(userType == 1) {
      var cl_executor_name = ((document.getElementById("cl_executor_name") as HTMLInputElement).value);
      var cl_executor_type = ((document.getElementById("cl_executor_type") as HTMLInputElement).value);

      this.addWillExecutorD.clientCode = this.client_code;
      this.addWillExecutorD.name = cl_executor_name;
      this.addWillExecutorD.type = cl_executor_type;

      this.willExecutorAdd(this.addWillExecutorD);
    }
    
    if(userType == 2) {
      var pa_executor_name = ((document.getElementById("pa_executor_name") as HTMLInputElement).value);
      var pa_executor_type = ((document.getElementById("pa_executor_type") as HTMLInputElement).value);

      this.addWillExecutorD.clientCode = this.partnerClientCode;
      this.addWillExecutorD.name = pa_executor_name;
      this.addWillExecutorD.type = pa_executor_type;

      this.willExecutorAdd(this.addWillExecutorD);
    }

    ((document.getElementById("cl_executor_name") as HTMLInputElement).value='');
    ((document.getElementById("pa_executor_name") as HTMLInputElement).value='');
  }

  willExecutorAdd(addExecutor)
  {
    this.familyTreeService.addWillExecutors(addExecutor).subscribe((data:WillExecutorsResponse)=>{
      if(data.error && !data.success){      
        this.toastr.error('Error on adding executor user.');      
      }
      else{
        this.toastr.success('Executor user added successfully.');
      }

      this.getWillInfo();
    },err=>{   
      console.log(err);
      this.toastr.error('Error on adding executor user.');
    });
  }

  willExecutorsNoteSave(willExecutors, event: any){
    // console.log(willExecutors);
    let value = event.target.value;
    let inputId = event.target.id;
    // console.log(value);
    // console.log(inputId);

    this.willExecutorsNoteAdd = new ExecutorsList;
    if(inputId == 'executorNote') {
      this.willExecutorsNoteAdd.note = value;
    }

    // console.log(this.willExecutorsNoteAdd);
    this.familyTreeService.editWillExecutorNote(willExecutors.id, this.willExecutorsNoteAdd).subscribe((data:WillResponse)=>{
      if(data.error && !data.success){      
        this.toastr.error('Error on updating executor note.');      
      }
      else{
        this.toastr.success('Executor note updated successfully.');
      }

      this.getWillInfo();
    },err=>{   
      console.log(err);
      this.toastr.error('Error on updating executor note.');
    });
  }

  deleteExecutorUser(executor)
  {
    let id = executor.id;

    this.familyTreeService.deleteExecutorUser(id).subscribe((data:DeleteOtherProvisionResponse)=>{
      if(data.error && !data.success){      
        this.toastr.error("Error on deleting executor user.");      
      }
      else{
        this.getWillInfo();
        this.toastr.success("Executor user deleted successfully.");
      }

      //this.getProvisionInfo();
    },err=>{   
      console.log(err);
      this.toastr.error("Error on deleting executor user.");
    });    
  }

}
