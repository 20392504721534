import { Component, OnInit, Input } from '@angular/core';
import { FamilyTreeService } from '../../family-tree.service';
import { RetirementResponse, BudgetResponse, RetirementAddResponse } from '../../../shared/models/response.model';
import { RetirementModel, RetirementList } from 'src/app/shared/models/retirement.model';
import { BudgetModel } from 'src/app/shared/models/budget.model';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, NgForm, FormArray, AbstractControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SharedDataService } from 'src/app/shared/service/shared-data.service'

@Component({
  selector: 'app-retirement',
  templateUrl: './retirement.component.html',
  styleUrls: ['../rightside.component.css']
})
export class RetirementComponent implements OnInit {

  constructor(
    private familyTreeService :FamilyTreeService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private sharedDataService: SharedDataService,
  ) { 
    this.sharedDataService.familyTreeId.subscribe(family_tree_id => this.family_tree_id = family_tree_id);
    this.sharedDataService.clientFirstname.subscribe(clientFirstname => this.clientFirstname = clientFirstname);
    this.sharedDataService.partnerFirstname.subscribe(partnerFirstname => this.partnerFirstname = partnerFirstname);

    this.sharedDataService.partnerHas.subscribe(partnerHas => this.partnerHas = partnerHas);
    this.sharedDataService.partnerID.subscribe(partnerID => this.partnerID = partnerID);
  }

  @Input() client_id: string;
  family_tree_id: string = "";
  retirementInfo:RetirementModel;
  budgetInfo:BudgetModel;
  budgetTotalAmount = 0;
  budgetTotalAmountPartner = 0;
  isLoaded4: boolean;
  CRIncome: number = 0;
  CRIncomePartner: number = 0;
  addRetirementForm: FormGroup;
  precentageIfPartnerDeceased_1: any = 0;
  precentageIfPartnerDeceasedPartner_1: any = 0;
  clientFirstname:string = "";
  partnerFirstname:string = "";
  partnerHas:boolean;
  partnerID:string;
  retirementAdd:RetirementList;
  retirementAge:any = 0;
  retirementAgePartner:any = 0;
  combinedRetirementIncome:any = 0;
  submitted : boolean = false;
  retirementPartnerAdd:RetirementList;
  percentageIfPartnerDeceased:any;
  percentageIfPartnerDeceasedPartner:any;

  ngOnInit(): void {
    this.getRetirementInfo();

    this.sharedDataService.retirementAge.subscribe(retirementAge => this.retirementAge = retirementAge);
    this.sharedDataService.retirementAgePartner.subscribe(retirementAgePartner => this.retirementAgePartner = retirementAgePartner);
    this.sharedDataService.combinedRetirementIncome.subscribe(combinedRetirementIncome => this.combinedRetirementIncome = combinedRetirementIncome);
    this.sharedDataService.percentageIfPartnerDeceased.subscribe(percentageIfPartnerDeceased => this.percentageIfPartnerDeceased = percentageIfPartnerDeceased);
    this.sharedDataService.percentageIfPartnerDeceasedPartner.subscribe(percentageIfPartnerDeceasedPartner => this.percentageIfPartnerDeceasedPartner = percentageIfPartnerDeceasedPartner);
    
  }

  getRetirementInfo(){
    // this.ngxService.start();
    this.familyTreeService.getRetirement(this.family_tree_id).subscribe((retirementData:RetirementResponse)=>{
      if(retirementData.success){
        this.retirementInfo = retirementData.response;

        this.familyTreeService.getBudget(this.client_id).subscribe((budgetData:BudgetResponse)=>{
          if(budgetData.success){
            this.budgetInfo = budgetData.response;
            this.budgetTotalAmount = 0;
            this.budgetTotalAmountPartner = 0;
            this.budgetInfo.budgets.forEach( data => this.budgetTotalAmount = this.budgetTotalAmount + data.annualAmount);

            // for(let j=0; j<this.budgetInfo.budgets.length; j++){  
            //   if(this.budgetInfo.budgets[j].ownerType=='Client') {
            //     this.budgetTotalAmount = this.budgetTotalAmount + this.budgetInfo.budgets[j].annualAmount;
            //   }
            //   else if(this.budgetInfo.budgets[j].ownerType=='Partner') {
            //     this.budgetTotalAmountPartner = this.budgetTotalAmountPartner + this.budgetInfo.budgets[j].annualAmount;
            //   }
            // }
          }

          this.sharedDataService.changeCombinedRetirementIncome(this.budgetTotalAmount);
          this.isLoaded4 = true;  
        },
        (err: HttpErrorResponse)=>{
        });

        if(this.retirementInfo) {
          if(this.retirementInfo.clientRetirement && this.retirementInfo.clientRetirement.combinedRetirementIncome > 0) {
            this.CRIncome = this.retirementInfo.clientRetirement.combinedRetirementIncome;
          }
          else {
            this.CRIncome = this.budgetTotalAmount;
          }

          if(this.retirementInfo.partnerRetirement && this.retirementInfo.partnerRetirement.combinedRetirementIncome > 0) {
            this.CRIncomePartner = this.retirementInfo.partnerRetirement.combinedRetirementIncome;
          }
          else {
            this.CRIncomePartner = this.budgetTotalAmount;
          }
        }

        this.retirementAge = this.retirementInfo.clientRetirement !=null ? this.retirementInfo.clientRetirement.retirementAge : 0;
        this.combinedRetirementIncome = this.retirementInfo.clientRetirement !=null ? this.retirementInfo.clientRetirement.combinedRetirementIncome : 0;
        let precentageIfPartnerDeceased = this.retirementInfo.clientRetirement !=null ? this.retirementInfo.clientRetirement.precentageIfPartnerDeceased : 0;

        this.retirementAgePartner = this.retirementInfo.partnerRetirement!=null ? this.retirementInfo.partnerRetirement.retirementAge : 0;
        let combinedRetirementIncomePartner = this.retirementInfo.partnerRetirement!=null ? this.retirementInfo.partnerRetirement.combinedRetirementIncome : 0,
        precentageIfPartnerDeceasedPartner = this.retirementInfo.partnerRetirement!=null ? this.retirementInfo.partnerRetirement.precentageIfPartnerDeceased : 0;
      
        localStorage.removeItem('retirementAge');
        localStorage.removeItem('combinedRetirementIncome');
        // localStorage.setItem('retirementAge', JSON.stringify(this.retirementAge));
        // localStorage.setItem('combinedRetirementIncome', JSON.stringify(combinedRetirementIncome));
        
        this.sharedDataService.changeRetirementAge(this.retirementAge);
        this.sharedDataService.changeRetirementAgePartner(this.retirementAgePartner);

        this.addRetirementForm = this.formBuilder.group({  
          retirementAge: [this.retirementAge],  //[Validators.required, Validators.minLength(3)]
          combinedRetirementIncome: [this.combinedRetirementIncome],
          precentageIfPartnerDeceased: [precentageIfPartnerDeceased],
          retirementAgePartner: [this.retirementAgePartner],  //[Validators.required, Validators.minLength(3)]
          combinedRetirementIncomePartner: [combinedRetirementIncomePartner],
          precentageIfPartnerDeceasedPartner: [precentageIfPartnerDeceasedPartner],
          budgetTotalAmount: [this.CRIncome],
          budgetTotalAmountPartner: [this.CRIncomePartner],
          note: [this.retirementInfo.note],
        });

        this.precentageIfPartnerDeceased_1 = this.retirementInfo.clientRetirement !=null ? this.retirementInfo.clientRetirement.precentageIfPartnerDeceased : 0;
        this.precentageIfPartnerDeceasedPartner_1 = this.retirementInfo.partnerRetirement!=null ? this.retirementInfo.partnerRetirement.precentageIfPartnerDeceased : 0;

        this.sharedDataService.changePercentageIfPartnerDeceased(this.precentageIfPartnerDeceased_1);
        this.sharedDataService.changePercentageIfPartnerDeceasedPartner(this.precentageIfPartnerDeceasedPartner_1);
      } 
      // this.ngxService.stop(); 
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
    });
  }


  retirementSave(retirement, event: any){
    // console.log(retirement);
    let value = event.target.value;
    let inputId = event.target.id;
    let errorVal = true;
    // console.log(value);
    // console.log(inputId);

    if(retirement==null){
      this.toastr.error('Error on updating retirement.');  
    }
    else {
      this.retirementAdd = new RetirementList;
      this.retirementAdd.clientCode = retirement.clientCode;
      if(inputId == 'retirementAge' || inputId == 'retirementAgePartner') {
        this.retirementAdd.retirementAge = value;
        this.retirementAdd.combinedRetirementIncome = retirement.combinedRetirementIncome;
        this.retirementAdd.precentageIfPartnerDeceased = retirement.precentageIfPartnerDeceased;
      }
      if(inputId == 'combinedRetirementIncome' || inputId == 'combinedRetirementIncomePartner') {
        this.retirementAdd.retirementAge = retirement.retirementAge;
        this.retirementAdd.combinedRetirementIncome = value;
        this.retirementAdd.precentageIfPartnerDeceased = retirement.precentageIfPartnerDeceased;
      }
      if(inputId == 'precentageIfPartnerDeceased' || inputId == 'precentageIfPartnerDeceasedPartner') {
        this.retirementAdd.retirementAge = retirement.retirementAge;
        this.retirementAdd.combinedRetirementIncome = retirement.combinedRetirementIncome;
        let pvalue = value.replace('%', '');
        this.retirementAdd.precentageIfPartnerDeceased = pvalue;

        if(pvalue > 100) {
          this.toastr.error('Error on percentage value.');
          errorVal = false;
        } 
      } 

      if(errorVal){
        this.familyTreeService.editRetirement(this.retirementAdd, retirement.id).subscribe((data:RetirementAddResponse)=>{
          if(data.error && !data.success){      
            this.toastr.error('Error on updating retirement.');      
          }
          else{
            this.toastr.success('Retirement updated successfully.');
          }
    
          this.getRetirementInfo();
        },err=>{   
          console.log(err);
          this.toastr.error('Error on updating retirement.');
        });
      }
    }
  }

  noteSave(treeListFromServer2, event: any){
    // console.log(retirement);
    let value = event.target.value;
    let inputId = event.target.id;
    let errorVal = true;
    // console.log(treeListFromServer2);
    // console.log(value);

    if(treeListFromServer2==null){
      this.toastr.error('Error on updating note.');  
    }
    else {
      let familyTreeId  = treeListFromServer2.familyTreeId;

      // if(inputId == 'retirementAge' || inputId == 'retirementAgePartner') {
        // this.retirementAdd.retirementAge = value;
      // }
       
      if(errorVal){
        this.familyTreeService.addRetirementNote(familyTreeId , value).subscribe((data:RetirementResponse)=>{
          if(data.error && !data.success){      
            this.toastr.error('Error on updating note.');      
          }
          else{
            this.toastr.success('Note updated successfully.');
          }
    
          // this.getRetirementInfo();
        },err=>{   
          console.log(err);
          this.toastr.error('Error on updating note.');
        });
      }
    }
  }


  // onSubmitRetirement(retirementInfo)    
  // {
  //   this.submitted = true;
 
  //   if (this.addRetirementForm.invalid  ) { //|| this.documentModel.docType == "0" || (title && title.trim()=="")

  //     //  console.log(this.addRetirementForm.controls);
  //     return;
  //   }
  //   else{
  //     //this.showAddButton = true;
  //     //console.log(this.addRetirementForm.value);    
  //     this.retirementAdd = new RetirementList;
  //     this.retirementPartnerAdd = new RetirementList;

  //     this.retirementAdd.clientCode =retirementInfo.clientRetirement.clientCode;
  //     this.retirementAdd.retirementAge =this.addRetirementForm.value.retirementAge;
  //     this.retirementAdd.combinedRetirementIncome =this.addRetirementForm.value.combinedRetirementIncome;
  //     this.retirementAdd.precentageIfPartnerDeceased =this.addRetirementForm.value.precentageIfPartnerDeceased;

  //     this.retirementPartnerAdd.clientCode =retirementInfo.partnerRetirement.clientCode;
  //     this.retirementPartnerAdd.retirementAge =this.addRetirementForm.value.retirementAgePartner;
  //     this.retirementPartnerAdd.combinedRetirementIncome =this.addRetirementForm.value.combinedRetirementIncomePartner;
  //     this.retirementPartnerAdd.precentageIfPartnerDeceased =this.addRetirementForm.value.precentageIfPartnerDeceasedPartner;
  //     // this.dependantAddModel.dependantUntilAge = 0;
  //     // this.dependantAddModel.financiallyDependent = false;

  //     this.familyTreeService.editRetirement(this.retirementAdd, this.retirementInfo.clientRetirement.id).subscribe((data:RetirementAddResponse)=>{
  //       //this.ngxService.stop();
  //       if(data.error && !data.success){      
  //         this.toastr.error('Error on updating retirement.');      
  //       }
  //       else{
  //         this.familyTreeService.editRetirement(this.retirementPartnerAdd, this.retirementInfo.partnerRetirement.id).subscribe((data:RetirementAddResponse)=>{
  //           if(data.error && !data.success){      
  //             this.toastr.error('Error on updating retirement.');      
  //           }
  //           else{
  //             this.toastr.success('Retirement updated successfully.');
  //             //this.event.emit('OK');
  //             // console.log(this.event);
  //             //this.modalRef.hide();
  //           }

  //           this.getRetirementInfo();
  //         },err=>{   
  //           console.log(err);
  //           this.toastr.error('Error on updating retirement.');
  //         });
  //       }
  //     },err=>{   
  //       console.log(err);
  //       //this.ngxService.stop();
  //       this.toastr.error('Error on updating retirement.');
  //     });

  //   }
      
  // }

}
