import { Pipe, PipeTransform } from '@angular/core';
import { FamilyDataModel, GrapyTypesList, SpouseChildrenModel,RawTreeModel } from 'src/app/family-tree/leftside/distibution/distibution.model';
import { GenderType, GraphType, RelationType, TreeRole } from 'src/app/family-tree/leftside/distibution/distibution.enum';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'distributionGraph'
})
export class DistributionGraphPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return null;
  }

  getdistributionTree(nodeData: FamilyDataModel) {

   // Initial Tree Node
   const rootNode = {
    id: null,
    hidden: true,
    no_parent: true,
    children: [],
  };

  let sibilings = []

  let parentLayer = this.parentLayer(nodeData)


  let ownerLayerr = this.ownerLayer(nodeData)



  let childrenLayer = this.childrenLayer(nodeData)
  let grandchildrenLayer = this.grandchildrenLayer(nodeData)

  let owner = ownerLayerr.find( (element:RawTreeModel) => element.role == TreeRole.Main)
  let sopuse = ownerLayerr.find( (element:RawTreeModel) => element.role == TreeRole.Spouse)



  var ownerNode = this.createNode(owner.nodeData, false, true, RelationType.Main)
  var sopuseNode = this.createNode(sopuse.nodeData, false, true, RelationType.Spouse)

  let ownerChildren = childrenLayer.filter( (element:RawTreeModel) => element.role == TreeRole.MainChild) ?? []
  let spouseChildren = childrenLayer.filter( (element:RawTreeModel) => element.role == TreeRole.SpouseChild) ?? []


  let ownerhildrenValues = this.calculateCommonChildrenTree(childrenLayer,2)

  ownerhildrenValues.nodes.children.forEach(element => {

    ownerNode.children.push(element)

  });

  ownerhildrenValues.sibilings.forEach(element => {

    sibilings.push(element)

  });
  console.log("ownerhildrenValues",ownerhildrenValues)


//add spouse children tree
  let spouseChildrenValues = this.calculateCommonChildrenTree(childrenLayer,3)

  spouseChildrenValues.nodes.children.forEach(element => {

    sopuseNode.children.push(element)

  });

  spouseChildrenValues.sibilings.forEach(element => {

    sibilings.push(element)

  });


  let commenChildrenValues = this.calculateCommonChildrenTree(childrenLayer,1)

  //addOwnerDivorcee
  let ownerdivorcee = ownerLayerr.find( (element:RawTreeModel) => element.role == TreeRole.MainDivorcee)


  if (ownerdivorcee != null){



    let hiddenNode = this.createHiddenNode()
      let d =  this.createNode(ownerdivorcee.nodeData, false, true, RelationType.Divorcee)

      hiddenNode.children.push(d)
      rootNode.children.push(hiddenNode)
     //rootNode.children.push(this.createHiddenNodeWithChild([ownerdivorcee.nodeData],true,RelationType.Divorcee))
    // sibilings.push(this.getlinkNodes(ownerdivorcee.nodeData.id1,nodeData.id1))
  }





  //handle OwnerParents
  let ownerParents = parentLayer.filter( (element:RawTreeModel) => element.role == TreeRole.MainParent)

  if (ownerParents.length == 1){

    let parent = ownerParents[0]
    var parentNode = this.createNode(parent.nodeData, parent.ishidden, true, RelationType.Parent)
    ownerNode.no_parent = false
    parentNode.children.push(ownerNode)
    rootNode.children.push(parentNode)

  }
  else  if (ownerParents.length == 2){

    ownerNode.no_parent = false
    let parent1 = ownerParents[0]
    var parentNode1 = this.createNode(parent1.nodeData, parent1.ishidden, true, RelationType.Parent)

    rootNode.children.push(parentNode1)

    let parent2 = ownerParents[1]
    var parentNode2 = this.createNode(parent2.nodeData, parent2.ishidden, true, RelationType.Parent)



    let hiddenNode = this.createHiddenNode()//rootNode.children.push(this.createHiddenNodeWithChild([owner],false,RelationType.Main))
    hiddenNode.children.push(ownerNode)

    rootNode.children.push(hiddenNode)
    rootNode.children.push(parentNode2)
    sibilings.push(this.getlinkNodes(parent1.nodeData.id1,parent2.nodeData.id1))

  }
  else{

    console.log("###########here")
   let hiddenNode = this.createHiddenNode()
    hiddenNode.children.push(ownerNode)
    rootNode.children.push(hiddenNode)
  //  rootNode.children.push(this.createHiddenNodeWithChild([owner],true,RelationType.Main))

  }

  sibilings.push(this.getlinkNodes(owner.nodeData.id1,sopuse.nodeData.id1))


  if (commenChildrenValues.nodes.children.length > 0){

    let hiddenNode = this.createHiddenNode()
    hiddenNode.children.push(commenChildrenValues.nodes)
    rootNode.children.push(hiddenNode)
     commenChildrenValues.sibilings.forEach(element => {

      sibilings.push(element)

    });

  }


 //handle spouseParents
  let spouseParents = parentLayer.filter( (element:RawTreeModel) => element.role == TreeRole.SpouseParent)
  if (spouseParents.length == 1){
    sopuseNode.no_parent = false
    let parent = spouseParents[0]
    var parentNode = this.createNode(parent.nodeData, parent.ishidden, true, RelationType.Parent)
    parentNode.children.push(sopuseNode)
    rootNode.children.push(parentNode)

  }
  else  if (spouseParents.length == 2){

    let parent1 = spouseParents[0]
    var parentNode1 = this.createNode(parent1.nodeData, parent1.ishidden, true, RelationType.Parent)

    rootNode.children.push(parentNode1)

    let parent2 = spouseParents[1]
    var parentNode2 = this.createNode(parent2.nodeData, parent2.ishidden, true, RelationType.Parent)
    sopuseNode.no_parent = false
    let hiddenNode = this.createHiddenNode()//rootNode.children.push(this.createHiddenNodeWithChild([owner],false,RelationType.Main))
    hiddenNode.children.push(sopuseNode)
    rootNode.children.push(hiddenNode)
    //let hiddenNode = rootNode.children.push(this.createHiddenNodeWithChild([sopuse],false,RelationType.Spouse))
  //  rootNode.children.push(hiddenNode)
    rootNode.children.push(parentNode2)
    sibilings.push(this.getlinkNodes(parent1.nodeData.id1,parent2.nodeData.id1))


  }
  else{

    let hiddenNode = this.createHiddenNode()
    hiddenNode.children.push(sopuseNode)
    rootNode.children.push(hiddenNode)
    //rootNode.children.push(this.createHiddenNodeWithChild([sopuse.nodeData],true,RelationType.Spouse))

  }


  //addOwnerDivorcee
  let spousedivorcee = ownerLayerr.find( (element:RawTreeModel) => element.role == TreeRole.SpouseDivorcee)
  if (spousedivorcee != null){

     rootNode.children.push(this.createHiddenNodeWithChild(spousedivorcee.nodeData,false,RelationType.Divorcee))
    // sibilings.push(this.getlinkNodes(sopuse.nodeData.id1,spousedivorcee.nodeData.id1))
  }










  // for (let i = 0; i < parentLayer.length; i++) {

  //   let rawmodel = parentLayer[i] as RawTreeModel
  //   //let no_parent = rawmodel.partentId ? true : false
  //   let relation = this.getNodeRelationship(rawmodel.role)
  //   rootNode.children.push(this.createNode(rawmodel.nodeData, rawmodel.ishidden, true, relation))

  //  }


  // console.log("ownerLayerr>>>1",ownerLayerr)





  //  console.log("parentLayer",parentLayer)
  //  console.log("ownerLayer",ownerLayerr)
  // // console.log("childrenLayer",childrenLayer)
  // // console.log("grandchildrenLayer",grandchildrenLayer)

   return {
    nodes: rootNode,
    sibilings: sibilings,
    };

  }

  calculateCommonChildrenTree(childrenLayer:RawTreeModel[],type){

    let sibilings = []
    var commonChildren = childrenLayer.filter( (element:RawTreeModel) => element.role == TreeRole.CommonChild)

    if (type == 2){

        commonChildren = childrenLayer.filter( (element:RawTreeModel) => element.role == TreeRole.MainChild)


    }
    else if (type == 3){


      commonChildren = childrenLayer.filter( (element:RawTreeModel) => element.role == TreeRole.SpouseChild)

    }


    let hiddenNode = this.createHiddenNode()

     for (let i = 0; i < commonChildren.length; i++) {

        let child = commonChildren[i]
        let childNode  = this.createNode(child.nodeData, false, false, RelationType.Child)



        if (child.nodeData.spouse.length > 0) {

          let childSpouse = child.nodeData.spouse[0]
          let csNode  = this.createNode(childSpouse, false, true, RelationType.Spouse)
          sibilings.push(this.getlinkNodes(child.nodeData.id1,childSpouse.id1))


          if (child.nodeData.spouse_children.length > 0){

            //same should repeat if child only children
            let spc = child.nodeData.spouse_children[0].children

            if(spc.length > 0){

                let gchiddenNode = this.createHiddenNode()

                for (let i = 0; i < spc.length; i++) {

                    let gc = spc[i]
                    var gcNode = this.createNode(gc, false, false, RelationType.Child)
                    gchiddenNode.children.push(gcNode)

                }



                hiddenNode.children.push(gchiddenNode)

            }



        }



      if (childSpouse != null) {


        for (let i = 0; i < childSpouse.child.length; i++) {

          let gc = childSpouse.child[i]
          var gcNode = this.createNode(gc, false, false, RelationType.Child)
          csNode.children.push(gcNode)

      }

      }




        hiddenNode.children.push(csNode)

        }


        for (let i = 0; i < child.nodeData.child.length; i++) {

          let gc = child.nodeData.child[i]
          var gcNode = this.createNode(gc, false, false, RelationType.Child)
          childNode.children.push(gcNode)

      }

        hiddenNode.children.unshift(childNode)






     }


     return {
      nodes: hiddenNode,
      sibilings: sibilings,
      };

  }

  getNodeRelationship(role){

    switch(role){

      case TreeRole.MainParent,TreeRole.SpouseParent:{

        return RelationType.Parent
        break

      }
      case TreeRole.Main:{

        return RelationType.Main
        break

      }
      case TreeRole.Spouse:{

        return RelationType.Spouse
        break

      }

      case TreeRole.MainDivorcee,TreeRole.SpouseDivorcee:{

        return RelationType.DivorceeChild
        break

      }
      default:{

        return RelationType.Spouse
        break

      }



    }



  }

  grandchildrenLayer(nodeData: FamilyDataModel){

    var gchildrenLayer = []
    let cc = this.getCommonChildren(nodeData)
    cc.forEach( child => {



      if (child.spouse_children.length > 0){

        let spc = child.spouse_children[0]

        spc.children.forEach( gchild => {

          gchildrenLayer.push(this.createTreeModel(child, false, null,TreeRole.GrandChild,null,[]))

        });


      }


    });

     return gchildrenLayer


   }

  childrenLayer(nodeData: FamilyDataModel){

    var childrenLayer = []
    let oc = this.getChildren(nodeData)

    oc.forEach( child => {

      //childrenLayer.push(this.createNode(child, false, true, RelationType.Child))
      childrenLayer.push(this.createTreeModel(child, false, null,TreeRole.MainChild,null,[]))


    });


    let cc = this.getCommonChildren(nodeData)
    cc.forEach( child => {


      childrenLayer.push(this.createTreeModel(child, false, null,TreeRole.CommonChild,null,[]))

      if (child.spouse.length > 0){

      if (child.spouse_children.length > 0 ){

        let commonChildren = child.spouse_children[0].children.map(a => a.id);


        if (commonChildren.length > 0){


           childrenLayer.push(this.createTreeModel(null, true, null,TreeRole.Hidden,null,commonChildren))

        }

      }

        childrenLayer.push(this.createTreeModel(child.spouse[0], false, null,TreeRole.ChildSpouse,child.id,[]))


      }


    });



    let sc = this.getSpouseChildren(nodeData)

    sc.forEach( child => {

      childrenLayer.push(this.createTreeModel(child, false, null,TreeRole.SpouseChild,null,[]))


    });

    return childrenLayer

  }

  ownerLayer(nodeData: FamilyDataModel){

   var ownserLayer = []

   let od = this.ownerDivorcies(nodeData)

   let spouse = this.getSpouse(nodeData)
  // var divorceId:string
   od.forEach( divorcee => {

    console.log("divorcee",divorcee)
      ownserLayer.push(this.createTreeModel(divorcee, false, null,TreeRole.MainDivorcee,null,[]))

    //  divorceId = divorcee.nodeData.id1

     // ownserLayer.push(this.createNode(divorcee, false, true, RelationType.Divorcee))

    });




    let ownerChildren = this.getChildren(nodeData).map(a => a.id1);
    ownserLayer.push(this.createTreeModel(nodeData, false, null,TreeRole.Main,null,ownerChildren))


    if(this.hasSpouse(nodeData)){



      let commonChildren = this.getCommonChildren(nodeData).map(a => a.id1);
      if (commonChildren.length > 0){


          ownserLayer.push(this.createTreeModel(null, true, null,TreeRole.Hidden,null,commonChildren))

      }

      let spouseChildren = this.getSpouseChildren(nodeData).map(a => a.id);

      ownserLayer.push(this.createTreeModel(this.getSpouse(nodeData), false, null,TreeRole.Spouse,nodeData.id1,spouseChildren))


      let sd = this.spouseDivorcies(nodeData)

      sd.forEach( divorcee => {

          ownserLayer.push(this.createTreeModel(divorcee, false, null,TreeRole.SpouseDivorcee,spouse.id1,[]))

       });
    }



      return ownserLayer

  }

  parentLayer(nodeData: FamilyDataModel){

    var parentLayer = []


    if (this.hasParent(nodeData) || this.spousehasParent(nodeData)){

      let ownserPartents = this.getParent(nodeData)
      let spousePartents = this.getSpouseParent(nodeData)

      if (ownserPartents.length > 0){

      for (let i = 0; i < ownserPartents.length; i++) {

        let parentData  = ownserPartents[i]

        if (i == 0) {

          if (ownserPartents.length > 1){

            parentLayer.push(this.createTreeModel(parentData, false, null,TreeRole.MainParent,null,[nodeData.id1]))

          }
          else{

            parentLayer.push(this.createTreeModel(parentData, false, null,TreeRole.MainParent,null,[nodeData.id1]))


          }


         }

         if (i == 1) {

        //  parentLayer.push(this.createTreeModel(null, true, null,TreeRole.Hidden,null,[nodeData.id1]))
          parentLayer.push(this.createTreeModel(parentData, false, null,TreeRole.MainParent,parentLayer[0].nodeData.id1,[nodeData.id1]))


         }



      }
    }
    else{

      //parentLayer.push(this.createTreeModel(null, true, null,TreeRole.Hidden,null,[]))


    }


      let spouse = this.getSpouse(nodeData)

      if (spousePartents.length > 0){

      for (let i = 0; i < spousePartents.length; i++) {

        let parentData  = spousePartents[i]

        if (i == 0) {


          if (spousePartents.length > 1){

            parentLayer.push(this.createTreeModel(parentData, false, null,TreeRole.SpouseParent,null,[spouse.id1]))

          }
          else{

            parentLayer.push(this.createTreeModel(parentData, false, null,TreeRole.SpouseParent,null,[spouse.id1]))


          }



         }

         if (i == 1) {

         // parentLayer.push(this.createTreeModel(null, true, null,TreeRole.Hidden,null,[]))
          parentLayer.push(this.createTreeModel(parentData, false, null,TreeRole.SpouseParent,parentLayer[0].nodeData.id1,[spouse.id]))


         }


      }
    }
      else{

      ///  parentLayer.push(this.createTreeModel(null, true, null,TreeRole.Hidden,null,[]))

      }

    }
    else{

     // parentLayer.push(this.createTreeModel(null, true, null,TreeRole.Hidden,null,[]))
     // parentLayer.push(this.createTreeModel(null, true, null,TreeRole.Hidden,null,[]))

    }

    return parentLayer

  }

  hasSpouse(nodeData: FamilyDataModel){

    return nodeData.spouse.length > 0

  }

  getSpouse(nodeData: FamilyDataModel){

    if (this.hasSpouse){

      return nodeData.spouse[0]

    }

    return null


  }

  hasParent(nodeData: FamilyDataModel){

    return (nodeData.parent?.length) ? true : false;


  }

  spousehasParent(nodeData: FamilyDataModel){


    return (nodeData.spouse[0]?.parent.length) ? true : false;

  }

  getParent(nodeData: FamilyDataModel){

    if (this.hasParent){

      return nodeData.parent

    }

    return []

  }

  getSpouseParent(nodeData: FamilyDataModel){

    if (this.spousehasParent){

      return nodeData.spouse[0]?.parent

    }

    return []


  }

  getChildren(nodeData: FamilyDataModel){

    return nodeData.child.length ?  nodeData.child : []

  }

  getCommonChildren(nodeData: FamilyDataModel){

    return (nodeData.spouse_children[0].children.length) ? nodeData.spouse_children[0].children : [];

  }

  getSpouseChildren(nodeData: FamilyDataModel){

    if (nodeData.spouse_children.length){

      return (nodeData.spouse_children[0].children.length) ? nodeData.spouse_children[0].children : [];

    }

  return []
  
  }


  ownerDivorcies(nodeData: FamilyDataModel){



  var ownerLayer = []

    if (nodeData.divorcee.length) {


      nodeData.divorcee.forEach( divorcee => {

        ownerLayer.push(this.createNode(divorcee, false, true, RelationType.Divorcee))


      });


    }


    return ownerLayer


  }

  spouseDivorcies(nodeData: FamilyDataModel){

    var ownerLayer = []

    let spouse = this.getSpouse(nodeData)

    if (spouse != null){


      spouse.divorcee.forEach( divorcee => {

          ownerLayer.push(this.createNode(divorcee, false, true, RelationType.Divorcee))


      });



    }

  return ownerLayer

  }

  // Calculate age from date of birth
  private calcAgeFromDOB(dob){

    let timeDiff = Math.abs(Date.now() - dob.getTime());
    return Math.floor((timeDiff / (1000 * 3600 * 24))/365.25);

  }

  createHiddenNode() {

    const hiddenNode = {
      id: null,
      hidden: true,
      no_parent: true,
      children: []
    };
    return hiddenNode;
  }

  createTreeModel(nodeData: FamilyDataModel, hidden: boolean, parent_id: string, role: number,sibiling_id:string,childrenIDs:string[]) {

    var trole:RawTreeModel = {nodeData:nodeData,ishidden:hidden,partentId:parent_id,role:role,sibilingId:sibiling_id,childrenIds:childrenIDs}
    return trole

  }

  getlinkNodes(sourceId, targetId, linkColour?) {

    const link = {
      source: {
        id: sourceId,
        name: "source"
      },
      target: {
        id:  targetId,
        name: "target"
      },
      colour:linkColour ? linkColour :"#ccc"
    }

    return link
  }

  // Create Tree Node
  createNode(nodeData: FamilyDataModel, hidden: boolean, no_parent: boolean, type: number,linkColour?:string) {

    const node = {
      hidden: hidden,
      no_parent: no_parent,
      type: type,
      id: nodeData.id1 ? nodeData.id1 : null,
      name: nodeData.firstName ? nodeData.firstName: null,
      age: nodeData.dateOfBirth ? this.calcAgeFromDOB(nodeData.dateOfBirth): null,
      gender: nodeData.gender ? parseInt(nodeData.gender): null,
      icon: this.setIcon(nodeData.deceased, nodeData.dependant, parseInt(nodeData.gender), type),
      children: [],
      colour:linkColour ? linkColour :"#ccc"
    }

    return node
  }

  // Create hidden parent Node with child
  createHiddenNodeWithChild(childData, hasParent: boolean, type: number) {

    const hiddenNode = {
      id: null,
      hidden: true,
      no_parent: true,
      children: []
    };

    if ( childData?.length ) {
      childData.forEach(child => {
        hiddenNode.children.push(this.createNode(child, false, hasParent, type));
      });
    } else {
      hiddenNode.children.push(this.createNode(childData, false, hasParent, type));
    }

    return hiddenNode;
  }

  // Set node icon
  private setIcon(deceased: boolean, dependant: boolean, gender: number, type?: number) {

    let icon: string;

    // const maleIcon = "../assets/icons/men.svg";
    // const femaleIcon = "../assets/icons/women.svg";
    // const maleDeceasedIcon = "../assets/icons/deceased-men.svg";
    // const femaleDeceasedIcon = "../assets/icons/deceased-women.svg";
    // const maleChildIcon = "../assets/icons/boy.svg";
    // const femaleChildIcon = "../assets/icons/girl.svg";
    // const maleDeceasedChildIcon = "../assets/icons/boy.svg";
    // const femaleDeceasedChildIcon = "../assets/icons/girl.svg";

    const maleIcon = environment.icons.maleIcon
    const femaleIcon = environment.icons.femaleIcon
    const maleDeceasedIcon = environment.icons.maleDeceasedIcon
    const femaleDeceasedIcon = environment.icons.femaleDeceasedIcon
    const maleChildIcon = environment.icons.maleChildIcon
    const femaleChildIcon = environment.icons.femaleChildIcon

    // default icons (not dependent)
    // const maleDefaultIcon = (deceased && (type != this.relationType.Main)) ? maleChildIcon : maleChildIcon;
    const maleDefaultIcon = (deceased) ? maleDeceasedIcon : ((type == RelationType.Main) ? maleIcon : maleChildIcon);
    const femaleDefaultIcon = (deceased) ? femaleDeceasedIcon : ((type == RelationType.Main) ? femaleIcon: femaleChildIcon);

    // Dependent icons
    const maleDependantIcon = (deceased) ? maleDeceasedIcon : maleIcon;
    const femaleDependantIcon = (deceased) ? femaleDeceasedIcon : femaleIcon;

    // Not considering the relation type at this stage.
    // ( Main | Parent | Spouse | Divorcee | Child | DivorceeChild | SpouseChild )
    // when consider type handle logic here //

    icon = (gender == GenderType.Female) ? (dependant ? femaleDependantIcon : femaleDefaultIcon) : (dependant ? maleDependantIcon : maleDefaultIcon);

    return icon
  }
}

