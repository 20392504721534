import { Component, OnInit, Input } from '@angular/core';
import { SharedDataService } from 'src/app/shared/service/shared-data.service';
import { InsuranceResponse, XplanChoicesResponse, ProposedInsuranceResponse } from 'src/app/shared/models/response.model';
import { FamilyTreeService } from 'src/app/family-tree/family-tree.service';
import { HttpErrorResponse } from '@angular/common/http';
import { XplanCoverGrouped } from 'src/app/shared/models/xplan-cover-grouped.model';
import { ProposedInsuranceModel } from 'src/app/shared/models/proposed-insurance.model';
import { XplanChoices } from 'src/app/shared/models/xplan-choices.model';
import { InsuranceComponent } from '../insurance.component';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['../../rightside.component.css']
})
export class SummaryComponent implements OnInit {

  @Input() family_tree_id:string;
  @Input() isLoaded3:boolean;
  @Input() isLoaded8:boolean;
  @Input() clientFirstname:string;
  @Input() partnerFirstname:string;
  @Input() insuranceInfo:any;

  monthlyPremiumLifePartner: number = 0;
  insurerLife: any = '';
  lifeSumInsuredLife: number = 0;
  monthlyPremiumLife: number = 0;
  insurerLifePartner: any = '-';
  lifeSumInsuredLifePartner: number = 0;
  insurerIncome: any = '';
  lifeSumInsuredIncome: number = 0;
  monthlyPremiumIncome: number = 0;
  insurerIncomePartner: any = '-';
  lifeSumInsuredIncomePartner: number = 0;
  monthlyPremiumIncomePartner: number = 0;
  insurerTPD: any = '';
  lifeSumInsuredTPD: number = 0;
  monthlyPremiumTPD: number = 0;
  insurerTPDPartner: any = '-';
  lifeSumInsuredTPDPartner: number = 0;
  monthlyPremiumTPDPartner: number = 0;
  insurerTrauma: any = '';
  lifeSumInsuredTrauma: number = 0;
  monthlyPremiumTrauma: number = 0;
  insurerTraumaPartner: any = '-';
  lifeSumInsuredTraumaPartner: number = 0;
  monthlyPremiumTraumaPartner: number = 0;
  choicesList : XplanChoices[];
  insurance_type_list:any;

  monthlyBenefitAmountIncome: number = 0;
  monthlyBenefitAmountIncomePartner: number = 0;
  ipMonthlyBenefit:number = 0;
  ipMonthlyBenefitPartner:number = 0;
  
  ipLifeSumInsured:number = 0;
  ipMonthlyPremium:number = 0;
  lifeLifeSumInsured:number = 0;
  lifeTPDSumInsured:number = 0;
  lifeMonthlyPremium:number = 0;
  tpdLifeSumInsured:number = 0;
  tpdTPDSumInsured:number = 0;
  tpdMonthlyPremium:number = 0;
  traumaLifeSumInsured:number = 0;
  traumaMonthlyPremium:number = 0;
  
  ipLifeSumInsuredPartner:number = 0;
  ipMonthlyPremiumPartner:number = 0;
  lifeLifeSumInsuredPartner:number = 0;
  lifeTPDSumInsuredPartner:number = 0;
  lifeMonthlyPremiumPartner:number = 0;
  tpdLifeSumInsuredPartner:number = 0;
  tpdTPDSumInsuredPartner:number = 0;
  tpdMonthlyPremiumPartner:number = 0;
  traumaLifeSumInsuredPartner:number = 0;
  traumaMonthlyPremiumPartner:number = 0;
  

  proposedInsuranceInfo:ProposedInsuranceModel;
  insurance_type_list_filtered: any=[];
  @Input() client_id: string;
  @Input() clientID:string;
  @Input() partnerID:string;

  insuranceCompOneObj: InsuranceComponent;

  ipInsurerProposed: any = '-';
  ipWaitingPeriodProposed: any = '-';
  ipBenefitPeriodProposed: any = '-';
  lifeInsurerProposed: any = '-';
  lifeWaitingPeriodProposed: any = '-';
  lifeBenefitPeriodProposed: any = '-';
  tpdInsurerProposed: any = '-';
  tpdWaitingPeriodProposed: any = '-';
  tpdBenefitPeriodProposed: any = '-';
  traumaInsurerProposed: any = '-';
  traumaWaitingPeriodProposed: any = '-';
  traumaBenefitPeriodProposed: any = '-';

  ipInsurerProposedPartner: any = '-';
  ipWaitingPeriodProposedPartner: any = '-';
  ipBenefitPeriodProposedPartner: any = '-';
  lifeInsurerProposedPartner: any = '-';
  lifeWaitingPeriodProposedPartner: any = '-';
  lifeBenefitPeriodProposedPartner: any = '-';
  tpdInsurerProposedPartner: any = '-';
  tpdWaitingPeriodProposedPartner: any = '-';
  tpdBenefitPeriodProposedPartner: any = '-';
  traumaInsurerProposedPartner: any = '-';
  traumaWaitingPeriodProposedPartner: any = '-';
  traumaBenefitPeriodProposedPartner: any = '-';

  ipWaitingPeriodExisting: any = '-';
  lifeWaitingPeriodExisting: any = '-';
  tpdWaitingPeriodExisting: any = '-';
  traumaWaitingPeriodExisting: any = '-';
  ipBenefitPeriodExisting: any = '-';
  lifeBenefitPeriodExisting: any = '-';
  tpdBenefitPeriodExisting: any = '-';
  traumaBenefitPeriodExisting: any = '-';

  ipWaitingPeriodExistingPartner: any = '-';
  lifeWaitingPeriodExistingPartner: any = '-';
  tpdWaitingPeriodExistingPartner: any = '-';
  traumaWaitingPeriodExistingPartner: any = '-';
  ipBenefitPeriodExistingPartner: any = '-';
  lifeBenefitPeriodExistingPartner: any = '-';
  tpdBenefitPeriodExistingPartner: any = '-';
  traumaBenefitPeriodExistingPartner: any = '-';

  ipStructureProposed: any = '-';
  ipPolicyDefinitionProposed: any = '-';
  ipFundingMechanismProposed: any = '-';

  ipStructureProposedPartner: any = '-';
  ipPolicyDefinitionProposedPartner: any = '-';
  ipFundingMechanismProposedPartner: any = '-';

  lifeStructureProposed: any = '-';
  lifeStructureProposedPartner: any = '-';
  lifeFundingMechanismProposed: any = '-';
  lifeFundingMechanismProposedPartner: any = '-';

  tpdStructureProposed: any = '-';
  tpdStructureProposedPartner: any = '-';
  tpdFundingMechanismProposed: any = '-';
  tpdFundingMechanismProposedPartner: any = '-';

  traumaStructureProposed: any = '-';
  traumaStructureProposedPartner: any = '-';
  traumaFundingMechanismProposed: any = '-';
  traumaFundingMechanismProposedPartner: any = '-';

  traumapolicyOwnerExisting: any = '-';
  traumapolicyOwnerExistingPartner: any = '-';
  traumapolicyOwnerProposed: any = '-';
  traumapolicyOwnerProposedPartner: any = '-';

  benefitAvailableClient:number = 0;
  benefitAvailablePartner:number = 0;
  totalInsuranceRecommendedClient:number = 0;
  totalInsuranceRecommendedPartner:number = 0;
  totalRequired:number = 0;
  totalRequiredPartner:number = 0;
  totalCoverRequiredClient:number = 0;
  totalCoverRequiredPartner:number = 0;

  constructor(private sharedDataService: SharedDataService,
    private familyTreeService :FamilyTreeService,) { 
      this.sharedDataService.benefitAvailableClient.subscribe(benefitAvailableClient => this.benefitAvailableClient = benefitAvailableClient);
      this.sharedDataService.benefitAvailablePartner.subscribe(benefitAvailablePartner => this.benefitAvailablePartner = benefitAvailablePartner);
      this.sharedDataService.totalInsuranceRecommendedClient.subscribe(totalInsuranceRecommendedClient => this.totalInsuranceRecommendedClient = totalInsuranceRecommendedClient);
      this.sharedDataService.totalInsuranceRecommendedPartner.subscribe(totalInsuranceRecommendedPartner => this.totalInsuranceRecommendedPartner = totalInsuranceRecommendedPartner);
      this.sharedDataService.totalRequired.subscribe(totalRequired => this.totalRequired = totalRequired);
      this.sharedDataService.totalRequiredPartner.subscribe(totalRequiredPartner => this.totalRequiredPartner = totalRequiredPartner);
      this.sharedDataService.totalCoverRequiredClient.subscribe(totalCoverRequiredClient => this.totalCoverRequiredClient = totalCoverRequiredClient);
      this.sharedDataService.totalCoverRequiredPartner.subscribe(totalCoverRequiredPartner => this.totalCoverRequiredPartner = totalCoverRequiredPartner);

  }

  ngOnInit(): void {
    this.getChoiceList();
    this.getInsuranceInfo();
    
  }

  ngOnChanges () : void {
    this.clientID = this.clientID
    this.partnerID = this.partnerID
    this.benefitAvailableClient = this.benefitAvailableClient
    this.benefitAvailablePartner = this.benefitAvailablePartner
    this.totalInsuranceRecommendedClient = this.totalInsuranceRecommendedClient
    this.totalInsuranceRecommendedPartner = this.totalInsuranceRecommendedPartner
    this.totalRequired = this.totalRequired
    this.totalRequiredPartner = this.totalRequiredPartner
    this.totalCoverRequiredClient = this.totalCoverRequiredClient
    this.totalCoverRequiredPartner = this.totalCoverRequiredPartner
    console.log('dddddd 23', this.totalCoverRequiredClient)
    if(this.clientID) {
      this.getProposedInsuranceInfo();
    }
  }

  getInsuranceInfo(){
    // this.ngxService.start();
    
    this.monthlyPremiumLifePartner = 0;
    this.familyTreeService.getInsurance(this.client_id).subscribe((insuranceData:InsuranceResponse)=>{
      if(insuranceData.success){
        this.insuranceInfo = insuranceData.response;
        this.sharedDataService.changInsuranceInfo(this.insuranceInfo);
        console.log('this.insuranceInfo 111==', this.insuranceInfo)
        if(this.insuranceInfo.clientInsurances){
          this.insuranceInfo.groupedCoversClient =  this.groupCovers(this.insuranceInfo.clientInsurances);
        }

        if(this.insuranceInfo.partnerInsurances){
          this.insuranceInfo.groupedCoversPartner =  this.groupCovers(this.insuranceInfo.partnerInsurances);
        }
        // console.log(this.insuranceInfo);

        // Life
        if(this.insuranceInfo.groupedCoversClient.life.length>0) {
          for(let j=0;j<this.insuranceInfo.groupedCoversClient.life.length;j++){  

            if(j>=1) {
              this.insurerLife = 'Various';
              this.lifeWaitingPeriodExisting = 'Various';
              this.lifeBenefitPeriodExisting = 'Various';
            } else {
              this.insurerLife = this.insuranceInfo.groupedCoversClient.life[j].planName;
              this.lifeWaitingPeriodExisting = this.insuranceInfo.groupedCoversClient.life[j].waitingPeriod;
              this.lifeBenefitPeriodExisting = this.insuranceInfo.groupedCoversClient.life[j].benefitPeriod;
            }
            this.lifeSumInsuredLife += Math.round(this.insuranceInfo.groupedCoversClient.life[j].sumInsured); // lifeInsured
            this.monthlyPremiumLife += Math.round(this.insuranceInfo.groupedCoversClient.life[j].totalPremium); // monthlyPremium
          } 
        }

        if(this.insuranceInfo.groupedCoversPartner.life.length>0) {
          for(let j=0;j<this.insuranceInfo.groupedCoversPartner.life.length;j++){  

            if(j>=1) {
              this.insurerLifePartner = 'Various';
              this.lifeWaitingPeriodExistingPartner = 'Various';
              this.lifeBenefitPeriodExistingPartner = 'Various';
            } else {
              this.insurerLifePartner = this.insuranceInfo.groupedCoversPartner.life[j].planName;
              this.lifeWaitingPeriodExistingPartner = this.insuranceInfo.groupedCoversPartner.life[j].waitingPeriod;
              this.lifeBenefitPeriodExistingPartner = this.insuranceInfo.groupedCoversPartner.life[j].benefitPeriod;
            }
            this.lifeSumInsuredLifePartner += Math.round(this.insuranceInfo.groupedCoversPartner.life[j].sumInsured); // lifeInsured
            this.monthlyPremiumLifePartner += Math.round(this.insuranceInfo.groupedCoversPartner.life[j].totalPremium); // monthlyPremium
          } 
        }


        // Income Protection
        if(this.insuranceInfo.groupedCoversClient.income.length>0) {
          for(let j=0;j<this.insuranceInfo.groupedCoversClient.income.length;j++){  

            if(j>=1) {
              this.insurerIncome = 'Various';
              this.ipBenefitPeriodExisting = 'Various';
              this.ipWaitingPeriodExisting = 'Various';
            } else {
              this.insurerIncome = this.insuranceInfo.groupedCoversClient.income[j].planName;
              this.ipBenefitPeriodExisting = this.benefitPeriod(this.insuranceInfo.groupedCoversClient.income[j].benefitPeriod);
              this.ipWaitingPeriodExisting = this.waitingPeriod(this.insuranceInfo.groupedCoversClient.income[j].waitingPeriod);
            }

            let monthlyBenifit = this.setMonthlyBenifit(this.insuranceInfo.groupedCoversClient.income[j]);

            this.lifeSumInsuredIncome += Math.round(this.insuranceInfo.groupedCoversClient.income[j].sumInsured); // lifeInsured
            this.monthlyPremiumIncome += Math.round(this.insuranceInfo.groupedCoversClient.income[j].totalPremium); // monthlyPremium
            this.monthlyBenefitAmountIncome += Math.round(monthlyBenifit); 
            // console.log('hhhhhoooooggggg1', this.monthlyBenefitAmountIncome)
          } 
        }

        if(this.insuranceInfo.groupedCoversPartner.income.length>0) {
          for(let j=0;j<this.insuranceInfo.groupedCoversPartner.income.length;j++){  

            if(j>=1) {
              this.insurerIncomePartner = 'Various';
              // this.premiumStructureIncomePartner = 'Various';
              this.ipBenefitPeriodExistingPartner = 'Various';
              this.ipWaitingPeriodExistingPartner = 'Various';
            } else {
              this.insurerIncomePartner = this.insuranceInfo.groupedCoversPartner.income[j].planName;
              // this.premiumStructureIncomePartner = this.insuranceInfo.groupedCoversPartner.income[j].waitingPeriod;
              this.ipBenefitPeriodExistingPartner = this.benefitPeriod(this.insuranceInfo.groupedCoversPartner.income[j].benefitPeriod);
              this.ipWaitingPeriodExistingPartner = this.waitingPeriod(this.insuranceInfo.groupedCoversPartner.income[j].waitingPeriod);
            }

            let monthlyBenifit = this.setMonthlyBenifit(this.insuranceInfo.groupedCoversPartner.income[j]);

            this.lifeSumInsuredIncomePartner += Math.round(this.insuranceInfo.groupedCoversPartner.income[j].sumInsured); // lifeInsured
            this.monthlyPremiumIncomePartner += Math.round(this.insuranceInfo.groupedCoversPartner.income[j].totalPremium); // monthlyPremium
            this.monthlyBenefitAmountIncomePartner += Math.round(monthlyBenifit); 
            // console.log('hhhhhoooooggggg2', this.monthlyBenefitAmountIncomePartner)
          } 
        }

        // TPD
        if(this.insuranceInfo.groupedCoversClient.tpd.length>0) {
          for(let j=0;j<this.insuranceInfo.groupedCoversClient.tpd.length;j++){  

            if(j>=1) {
              this.insurerTPD = 'Various';
              this.tpdWaitingPeriodExisting = 'Various';
              this.tpdBenefitPeriodExisting = 'Various';
            } else {
              this.insurerTPD = this.insuranceInfo.groupedCoversClient.tpd[j].planName;
              this.tpdWaitingPeriodExisting = this.insuranceInfo.groupedCoversClient.tpd[j].waitingPeriod;
              this.tpdBenefitPeriodExisting = this.insuranceInfo.groupedCoversClient.tpd[j].benefitPeriod;
            }
            this.lifeSumInsuredTPD += Math.round(this.insuranceInfo.groupedCoversClient.tpd[j].sumInsured); // lifeInsured
            this.monthlyPremiumTPD += Math.round(this.insuranceInfo.groupedCoversClient.tpd[j].totalPremium); // monthlyPremium
          } 
        }

        if(this.insuranceInfo.groupedCoversPartner.tpd.length>0) {
          for(let j=0;j<this.insuranceInfo.groupedCoversPartner.tpd.length;j++){  

            if(j>=1) {
              this.insurerTPDPartner = 'Various';
              this.tpdWaitingPeriodExistingPartner = 'Various';
              this.tpdBenefitPeriodExistingPartner = 'Various';
            } else {
              this.insurerTPDPartner = this.insuranceInfo.groupedCoversPartner.tpd[j].planName;
              this.tpdWaitingPeriodExistingPartner = this.insuranceInfo.groupedCoversPartner.tpd[j].waitingPeriod;
              this.tpdBenefitPeriodExistingPartner = this.insuranceInfo.groupedCoversPartner.tpd[j].benefitPeriod;
            }
            this.lifeSumInsuredTPDPartner += Math.round(this.insuranceInfo.groupedCoversPartner.tpd[j].sumInsured); // lifeInsured
            this.monthlyPremiumTPDPartner += Math.round(this.insuranceInfo.groupedCoversPartner.tpd[j].totalPremium); // monthlyPremium
          } 
        }

        // Trauma
        if(this.insuranceInfo.groupedCoversClient.trauma.length>0) {
          for(let j=0;j<this.insuranceInfo.groupedCoversClient.trauma.length;j++){  

            if(j>=1) {
              this.insurerTrauma = 'Various';
              this.traumaWaitingPeriodExisting = 'Various';
              this.traumaBenefitPeriodExisting = 'Various';
              this.traumapolicyOwnerExisting = 'Various';
            } else {
              this.insurerTrauma = this.insuranceInfo.groupedCoversClient.trauma[j].planName;
              this.traumaWaitingPeriodExisting = this.insuranceInfo.groupedCoversClient.trauma[j].waitingPeriod;
              this.traumaBenefitPeriodExisting = this.insuranceInfo.groupedCoversClient.trauma[j].benefitPeriod;
              this.traumapolicyOwnerExisting =  this.policyOwnersName(this.insuranceInfo.groupedCoversClient.trauma[j].policyOwners);
            }
            this.lifeSumInsuredTrauma += Math.round(this.insuranceInfo.groupedCoversClient.trauma[j].sumInsured); // lifeInsured
            this.monthlyPremiumTrauma += Math.round(this.insuranceInfo.groupedCoversClient.trauma[j].totalPremium); // monthlyPremium
          } 
        }

        if(this.insuranceInfo.groupedCoversPartner.trauma.length>0) {
          for(let j=0;j<this.insuranceInfo.groupedCoversPartner.trauma.length;j++){  

            if(j>=1) {
              this.insurerTraumaPartner = 'Various';
              this.traumaWaitingPeriodExistingPartner = 'Various';
              this.traumaBenefitPeriodExistingPartner = 'Various';
              this.traumapolicyOwnerExistingPartner = 'Various';
            } else {
              this.insurerTraumaPartner = this.insuranceInfo.groupedCoversPartner.trauma[j].planName;
              this.traumaWaitingPeriodExistingPartner = this.insuranceInfo.groupedCoversPartner.trauma[j].waitingPeriod;
              this.traumaBenefitPeriodExistingPartner = this.insuranceInfo.groupedCoversPartner.trauma[j].benefitPeriod;
              this.traumapolicyOwnerExistingPartner = this.policyOwnersName(this.insuranceInfo.groupedCoversPartner.trauma[j].policyOwners);
            }
            this.lifeSumInsuredTraumaPartner += Math.round(this.insuranceInfo.groupedCoversPartner.trauma[j].sumInsured); // lifeInsured
            this.monthlyPremiumTraumaPartner += Math.round(this.insuranceInfo.groupedCoversPartner.trauma[j].totalPremium); // monthlyPremium
          } 
        }

        // this.financesCompOneObj.getFinancesInfo();
        // this.provisionsCompOneObj.getProvisionInfo();
        // this.getProposedInsuranceInfo();
        
        this.isLoaded3 = true;
      } 
      // this.ngxService.stop(); 
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });
  }
 
  getProposedInsuranceInfo() {
    var clientID = this.clientID;
    var partnerID = this.partnerID;
    this.ipLifeSumInsured = 0;
    this.ipMonthlyPremium = 0;
    this.ipMonthlyBenefit = 0;
    this.ipLifeSumInsuredPartner = 0;
    this.ipMonthlyPremiumPartner = 0;
    this.ipMonthlyBenefitPartner = 0;
    this.lifeLifeSumInsured = 0;
    this.lifeTPDSumInsured = 0;
    this.lifeMonthlyPremium = 0;
    this.lifeLifeSumInsuredPartner = 0;
    this.lifeTPDSumInsuredPartner = 0;
    this.lifeMonthlyPremiumPartner = 0;
    this.tpdLifeSumInsured = 0;
    this.tpdTPDSumInsured = 0;
    this.tpdMonthlyPremium = 0;
    this.tpdLifeSumInsuredPartner = 0;
    this.tpdTPDSumInsuredPartner = 0;
    this.tpdMonthlyPremiumPartner = 0;
    this.traumaLifeSumInsured = 0;
    this.traumaMonthlyPremium = 0;
    this.traumaLifeSumInsuredPartner = 0;
    this.traumaMonthlyPremiumPartner = 0;

    this.familyTreeService.getProposedInsurance(this.family_tree_id).subscribe((proposedInsuranceData:ProposedInsuranceResponse)=>{
      if(proposedInsuranceData.success){
        this.proposedInsuranceInfo = proposedInsuranceData.response;
        
        if(this.proposedInsuranceInfo && this.proposedInsuranceInfo.clientInsurances.length>0) {
          let iPClientList, iPPartnerList, lifeClientList, TPDClientList, TraumaClientList, lifePartnerList, TPDPartnerList, TraumaPartnerList
          
          // groupByUser in policyOwner  == IP
          const groupByUserPolicyOwner = this.groupBy(this.proposedInsuranceInfo.clientInsurances, type => type.policyOwner);

          const policyOwnerClientList = groupByUserPolicyOwner.get(clientID);
          const policyOwnerPartnerList = groupByUserPolicyOwner.get(partnerID);

          if(policyOwnerClientList && policyOwnerClientList.length>0) {
            const groupIPClientList = this.groupBy(policyOwnerClientList, type => type.insuranceCoverType);
            iPClientList = groupIPClientList.get(0);
          }
          if(policyOwnerPartnerList && policyOwnerPartnerList.length>0) {
            const groupIPPartnerList = this.groupBy(policyOwnerPartnerList, type => type.insuranceCoverType);
            iPPartnerList = groupIPPartnerList.get(0);
          }

          // groupByUser in lifeInsured  == IP
          const groupByUserLifeInsured = this.groupBy(this.proposedInsuranceInfo.clientInsurances, type => type.lifeInsured);
          
          const lifeInsuredClientList = groupByUserLifeInsured.get(clientID);
          const lifeInsuredPartnerList = groupByUserLifeInsured.get(partnerID);

          if(lifeInsuredClientList && lifeInsuredClientList.length>0) {
            const group_Life_TPDT_TraumaClientList = this.groupBy(lifeInsuredClientList, type => type.insuranceCoverType);
            console.log('group_Life_TPDT_TraumaClientList 222==', group_Life_TPDT_TraumaClientList)

            lifeClientList = group_Life_TPDT_TraumaClientList.get(1);
            TPDClientList = group_Life_TPDT_TraumaClientList.get(2);
            TraumaClientList = group_Life_TPDT_TraumaClientList.get(3);
          }

          if(lifeInsuredPartnerList && lifeInsuredPartnerList.length>0) {
            const group_Life_TPDT_TraumaPartnerList = this.groupBy(lifeInsuredPartnerList, type => type.insuranceCoverType);
            console.log('group_Life_TPDT_TraumaPartnerList 333==', group_Life_TPDT_TraumaPartnerList)
            lifePartnerList = group_Life_TPDT_TraumaPartnerList.get(1);
            TPDPartnerList = group_Life_TPDT_TraumaPartnerList.get(2);
            TraumaPartnerList = group_Life_TPDT_TraumaPartnerList.get(3);
          }
          
          // Income Protection - Client
          if(iPClientList) {
            console.log('dddddd 2')
            iPClientList.forEach((element, index) => {
              if(index>=1) {
                this.ipInsurerProposed = 'Various';
                this.ipWaitingPeriodProposed = 'Various';
                this.ipBenefitPeriodProposed = 'Various';
                this.ipStructureProposed = 'Various';
                this.ipPolicyDefinitionProposed = 'Various';
                this.ipFundingMechanismProposed = 'Various';
              } else {
                this.ipInsurerProposed = element.underwriter;
                this.ipWaitingPeriodProposed = this.waitingPeriod(element.waitingPeriod);
                this.ipBenefitPeriodProposed = this.benefitPeriod(element.benefitPeriod);
                this.ipStructureProposed = this.structure(element.structure)
                this.ipPolicyDefinitionProposed = this.policyDefinition(element.policyDefenition)
                this.ipFundingMechanismProposed = this.fundingMechanism(element.fundingMechanism)
              }
              
              this.ipLifeSumInsured += element.lifeSum;
              this.ipMonthlyPremium += element.monthlyPremium;
              this.ipMonthlyBenefit += element.monthlyBenefit;
            });
          } else { console.log('dddddd 3', this.ipMonthlyBenefit); this.ipMonthlyBenefit = this.benefitAvailableClient }

          // Income Protection - Partner
          if(iPPartnerList) {
            iPPartnerList.forEach((element, index) => {
              if(index>=1) {
                this.ipInsurerProposedPartner = 'Various';
                this.ipWaitingPeriodProposedPartner = 'Various';
                this.ipBenefitPeriodProposedPartner = 'Various';
                this.ipStructureProposedPartner = 'Various';
                this.ipPolicyDefinitionProposedPartner = 'Various';
                this.ipFundingMechanismProposedPartner = 'Various';
              } else {
                this.ipInsurerProposedPartner = element.underwriter;
                this.ipWaitingPeriodProposedPartner= this.waitingPeriod(element.waitingPeriod);
                this.ipBenefitPeriodProposedPartner = this.benefitPeriod(element.benefitPeriod);
                this.ipStructureProposedPartner = this.structure(element.structure)
                this.ipPolicyDefinitionProposedPartner = this.policyDefinition(element.policyDefenition)
                this.ipFundingMechanismProposedPartner = this.fundingMechanism(element.fundingMechanism)
              }

              this.ipLifeSumInsuredPartner += element.lifeSum;
              this.ipMonthlyPremiumPartner += element.monthlyPremium;
              this.ipMonthlyBenefitPartner += element.monthlyBenefit;
            });
          } else { this.ipMonthlyBenefitPartner = this.benefitAvailablePartner  }

          // Life - Client
          if(lifeClientList) {
            lifeClientList.forEach((element, index) => {
              if(index>=1) {
                this.lifeInsurerProposed = 'Various';
                this.lifeWaitingPeriodProposed = 'Various';
                this.lifeBenefitPeriodProposed = 'Various';
                this.lifeStructureProposed = 'Various';
                this.lifeFundingMechanismProposed = 'Various';
              } else {
                this.lifeInsurerProposed = element.underwriter;
                this.lifeWaitingPeriodProposed = element.waitingPeriod;
                this.lifeBenefitPeriodProposed = element.benefitPeriod;
                this.lifeStructureProposed = this.structure(element.structure)
                this.lifeFundingMechanismProposed = this.fundingMechanism(element.fundingMechanism)
              }

              this.lifeLifeSumInsured += element.lifeSum;
              this.lifeTPDSumInsured += element.tpdSum;
              this.lifeMonthlyPremium += element.monthlyPremium;
            });
          } else { 
            if(this.totalInsuranceRecommendedClient > 0) {
              this.lifeLifeSumInsured = this.totalInsuranceRecommendedClient;
            } else {
              this.lifeLifeSumInsured = 0;
            }
          }

          // Life - Partner
          if(lifePartnerList) {
            lifePartnerList.forEach((element, index) => {
              if(index>=1) {
                this.lifeInsurerProposedPartner = 'Various';
                this.lifeWaitingPeriodProposedPartner = 'Various';
                this.lifeBenefitPeriodProposedPartner = 'Various';
                this.lifeStructureProposedPartner = 'Various';
                this.lifeFundingMechanismProposedPartner = 'Various';
              } else {
                this.lifeInsurerProposedPartner = element.underwriter;
                this.lifeWaitingPeriodProposedPartner = element.waitingPeriod;
                this.lifeBenefitPeriodProposedPartner = element.benefitPeriod;
                this.lifeStructureProposedPartner = this.structure(element.structure)
                this.lifeFundingMechanismProposedPartner = this.fundingMechanism(element.fundingMechanism)
              }

              this.lifeLifeSumInsuredPartner += element.lifeSum;
              this.lifeTPDSumInsuredPartner += element.tpdSum;
              this.lifeMonthlyPremiumPartner += element.monthlyPremium;
            });
          } else { 
            if(this.totalInsuranceRecommendedPartner > 0) {
              this.lifeLifeSumInsuredPartner = this.totalInsuranceRecommendedPartner;
            } else {
              this.lifeLifeSumInsuredPartner = 0;
            }
          }

          // TPD - Client
          if(TPDClientList) {
            TPDClientList.forEach((element, index) => {
              if(index>=1) {
                this.tpdInsurerProposed = 'Various';
                this.tpdWaitingPeriodProposed = 'Various';
                this.tpdBenefitPeriodProposed = 'Various';
                this.tpdStructureProposed = 'Various';
                this.tpdFundingMechanismProposed = 'Various';
              } else {
                this.tpdInsurerProposed = element.underwriter;
                this.tpdWaitingPeriodProposed = element.waitingPeriod;
                this.tpdBenefitPeriodProposed = element.benefitPeriod;
                this.tpdStructureProposed = this.structure(element.structure)
                this.tpdFundingMechanismProposed = this.fundingMechanism(element.fundingMechanism)
              }

              this.tpdLifeSumInsured += element.lifeSum;
              this.tpdTPDSumInsured += element.tpdSum;
              this.tpdMonthlyPremium += element.monthlyPremium;
            });
          } else { 
            if(this.totalRequired > 0) {
              this.tpdTPDSumInsured = this.totalRequired;
            } else {
              this.tpdTPDSumInsured = 0;
            }
          }

          // TPD - Partner
          if(TPDPartnerList) {
            TPDPartnerList.forEach((element, index) => {
              if(index>=1) {
                this.tpdInsurerProposedPartner = 'Various';
                this.tpdWaitingPeriodProposedPartner = 'Various';
                this.tpdBenefitPeriodProposedPartner = 'Various';
                this.tpdStructureProposedPartner = 'Various';
                this.tpdFundingMechanismProposedPartner = 'Various';
              } else {
                this.tpdInsurerProposedPartner = element.underwriter;
                this.tpdWaitingPeriodProposedPartner = element.waitingPeriod;
                this.tpdBenefitPeriodProposedPartner = element.benefitPeriod;
                this.tpdStructureProposedPartner = this.structure(element.structure)
                this.tpdFundingMechanismProposedPartner = this.fundingMechanism(element.fundingMechanism)
              }

              this.tpdLifeSumInsuredPartner += element.lifeSum;
              this.tpdTPDSumInsuredPartner += element.tpdSum;
              this.tpdMonthlyPremiumPartner+= element.monthlyPremium;
            });
          } else { 
            if(this.totalRequiredPartner > 0) {
              this.tpdTPDSumInsuredPartner = this.totalRequiredPartner;
            } else {
              this.tpdTPDSumInsuredPartner = 0;
            }
          }

           // Trauma - Client
           if(TraumaClientList) {
            TraumaClientList.forEach((element, index) => {
              if(index>=1) {
                this.traumaInsurerProposed = 'Various';
                this.traumaWaitingPeriodProposed = 'Various';
                this.traumaBenefitPeriodProposed = 'Various';
                this.traumaStructureProposed = 'Various';
                this.traumaFundingMechanismProposed = 'Various';
                this.traumapolicyOwnerProposed = 'Various';
              } else {
                this.traumaInsurerProposed = element.underwriter;
                this.traumaWaitingPeriodProposed = element.waitingPeriod;
                this.traumaBenefitPeriodProposed = element.benefitPeriod;
                this.traumaStructureProposed = this.structure(element.structure)
                this.traumaFundingMechanismProposed = this.fundingMechanism(element.fundingMechanism)
                this.traumapolicyOwnerProposed = this.policyOwnersName(element.policyOwner)
              }

              this.traumaLifeSumInsured += element.lifeSum;
              this.traumaMonthlyPremium += element.monthlyPremium;
            });
          } else { 
            if(this.totalCoverRequiredClient > 0) {
              this.traumaLifeSumInsured = this.totalCoverRequiredClient;
            } else {
              this.traumaLifeSumInsured = 0;
            }
          }

          // Trauma - Partner
          if(TraumaPartnerList) {
            TraumaPartnerList.forEach((element, index) => {
              if(index>=1) {
                this.traumaInsurerProposedPartner = 'Various';
                this.traumaWaitingPeriodProposedPartner = 'Various';
                this.traumaBenefitPeriodProposedPartner = 'Various';
                this.traumaStructureProposedPartner = 'Various';
                this.traumaFundingMechanismProposedPartner = 'Various';
                this.traumapolicyOwnerProposedPartner = 'Various';
              } else {
                this.traumaInsurerProposedPartner = element.underwriter;
                this.traumaWaitingPeriodProposedPartner = element.waitingPeriod;
                this.traumaBenefitPeriodProposedPartner = element.benefitPeriod;
                this.traumaStructureProposedPartner = this.structure(element.structure)
                this.traumaFundingMechanismProposedPartner = this.fundingMechanism(element.fundingMechanism)
                this.traumapolicyOwnerProposedPartner = this.policyOwnersName(element.policyOwner)
              }

              this.traumaLifeSumInsuredPartner += element.lifeSum;
              this.traumaMonthlyPremiumPartner += element.monthlyPremium;
            });
          } else { 
            if(this.totalCoverRequiredPartner > 0) {
              this.traumaLifeSumInsuredPartner = this.totalCoverRequiredPartner;
            } else {
              this.traumaLifeSumInsuredPartner = 0;
            }
          }
        } else {
          this.ipMonthlyBenefit = this.benefitAvailableClient
          this.ipMonthlyBenefitPartner = this.benefitAvailablePartner

          if(this.totalInsuranceRecommendedClient > 0) {
            this.lifeLifeSumInsured = this.totalInsuranceRecommendedClient;
          } else {
            this.lifeLifeSumInsured = 0;
          }
          if(this.totalInsuranceRecommendedPartner > 0) {
            this.lifeLifeSumInsuredPartner = this.totalInsuranceRecommendedPartner;
          } else {
            this.lifeLifeSumInsuredPartner = 0;
          }

          if(this.totalRequired > 0) {
            this.tpdTPDSumInsured = this.totalRequired;
          } else {
            this.tpdTPDSumInsured = 0;
          }
          if(this.totalRequiredPartner > 0) {
            this.tpdTPDSumInsuredPartner = this.totalRequiredPartner;
          } else {
            this.tpdTPDSumInsuredPartner = 0;
          }

          if(this.totalCoverRequiredClient > 0) {
            this.traumaLifeSumInsured = this.totalCoverRequiredClient;
          } else {
            this.traumaLifeSumInsured = 0;
          }
          if(this.totalCoverRequiredPartner > 0) {
            this.traumaLifeSumInsuredPartner = this.totalCoverRequiredPartner;
          } else {
            this.traumaLifeSumInsuredPartner = 0;
          }
        }

        if(this.proposedInsuranceInfo && this.proposedInsuranceInfo.partnerInsurances.length>0) {
          // this.proposedInsuranceInfo.partnerInsurances.forEach((element, index) => {
          //   // Income Protection
          //   if(element.insuranceCoverType == 0){

          //     if(index>1) {
          //       this.ipInsurerProposedPartner = 'Various';
          //       this.ipWaitingPeriodProposedPartner = 'Various';
          //       this.ipBenefitPeriodProposedPartner = 'Various';
          //     } else {
          //       this.ipInsurerProposedPartner = element.underwriter;
          //       this.ipWaitingPeriodProposedPartner= element.waitingPeriod;
          //       this.ipBenefitPeriodProposedPartner = element.benefitPeriod;
          //     }

          //     this.ipLifeSumInsuredPartner += element.lifeSum;
          //     this.ipMonthlyPremiumPartner += element.monthlyPremium;
          //     this.ipMonthlyBenefitPartner += element.monthlyBenefit;
          //   }
          //   // Life
          //   if(element.insuranceCoverType == 1){

          //     if(index>1) {
          //       this.lifeInsurerProposedPartner = 'Various';
          //       this.lifeWaitingPeriodProposedPartner = 'Various';
          //       this.lifeBenefitPeriodProposedPartner = 'Various';
          //     } else {
          //       this.lifeInsurerProposedPartner = element.underwriter;
          //       this.lifeWaitingPeriodProposedPartner = element.waitingPeriod;
          //       this.lifeBenefitPeriodProposedPartner = element.benefitPeriod;
          //     }

          //     this.lifeLifeSumInsuredPartner += element.lifeSum;
          //     this.lifeTPDSumInsuredPartner += element.tpdSum;
          //     this.lifeMonthlyPremiumPartner += element.monthlyPremium;
          //   }
          //   // TPD
          //   if(element.insuranceCoverType == 2){

          //     if(index>1) {
          //       this.tpdInsurerProposedPartner = 'Various';
          //       this.tpdWaitingPeriodProposedPartner = 'Various';
          //       this.tpdBenefitPeriodProposedPartner = 'Various';
          //     } else {
          //       this.tpdInsurerProposedPartner = element.underwriter;
          //       this.tpdWaitingPeriodProposedPartner = element.waitingPeriod;
          //       this.tpdBenefitPeriodProposedPartner = element.benefitPeriod;
          //     }

          //     this.tpdLifeSumInsuredPartner += element.lifeSum;
          //     this.tpdTPDSumInsuredPartner += element.tpdSum;
          //     this.tpdMonthlyPremiumPartner+= element.monthlyPremium;
          //   }
          //   // Trauma
          //   if(element.insuranceCoverType == 3){

          //     if(index>1) {
          //       this.traumaInsurerProposedPartner = 'Various';
          //       this.traumaWaitingPeriodProposedPartner = 'Various';
          //       this.traumaBenefitPeriodProposedPartner = 'Various';
          //     } else {
          //       this.traumaInsurerProposedPartner = element.underwriter;
          //       this.traumaWaitingPeriodProposedPartner = element.waitingPeriod;
          //       this.traumaBenefitPeriodProposedPartner = element.benefitPeriod;
          //     }

          //     this.traumaLifeSumInsuredPartner += element.lifeSum;
          //     this.traumaMonthlyPremiumPartner += element.monthlyPremium;
          //   }
          // });
        }


        this.isLoaded8 = true;
      } 
      // this.ngxService.stop(); 
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
    });
  }
  

  groupCovers(xFacInsuranceCovers){

    let grouped = new XplanCoverGrouped();
    xFacInsuranceCovers.forEach(element => {

      element.monthlyPremium = element.totalPremium/12;
      if(element.insuranceCoverType){
        if(element.insuranceCoverType.indexOf("l")==0){

          let choice = this.insurance_type_list_filtered.filter(choice => choice.key === element.insuranceCoverType);
          if(choice && choice[0]) {
            element.insuranceCoverTypeName = choice[0].value;
          }

          grouped.life.push(element);
          // console.log("life-----");
          // console.log(grouped.life);
        }
        else if(element.insuranceCoverType.indexOf("d")==0){

          let choice = this.insurance_type_list_filtered.filter(choice => choice.key === element.insuranceCoverType);
          if(choice && choice[0]) {
            element.insuranceCoverTypeName = choice[0].value;
          }

          grouped.tpd.push(element);
          // console.log("tpd-----");
          // console.log(grouped.tpd);
        }
        else if(element.insuranceCoverType.indexOf("t")==0){

          let choice = this.insurance_type_list_filtered.filter(choice => choice.key === element.insuranceCoverType);
          if(choice && choice[0]) {
            element.insuranceCoverTypeName = choice[0].value;
          }

          grouped.trauma.push(element);
          // console.log("trauma-----");
          // console.log(grouped.trauma);
        }
        else if(element.insuranceCoverType.indexOf("i")==0){

          let choice = this.insurance_type_list_filtered.filter(choice => choice.key === element.insuranceCoverType);
          if(choice && choice[0]) {
            element.insuranceCoverTypeName = choice[0].value;
          }

          grouped.income.push(element);
          // console.log("income-----");
          // console.log(grouped.income);
        }
        else if(element.insuranceCoverType.indexOf("b")==0){

          let choice = this.insurance_type_list_filtered.filter(choice => choice.key === element.insuranceCoverType);
          if(choice && choice[0]) {
            element.insuranceCoverTypeName = choice[0].value;
          }

          grouped.business.push(element);
          // console.log("business-----");
          // console.log(grouped.business);
        }
        else if(element.insuranceCoverType.indexOf("o")==0){

          let choice = this.insurance_type_list_filtered.filter(choice => choice.key === element.insuranceCoverType);
          if(choice && choice[0]) {
            element.insuranceCoverTypeName = choice[0].value;
          }

          grouped.additional.push(element);
          // console.log("additional-----");
          // console.log(grouped.additional);
        }
        else if(element.insuranceCoverType.indexOf("x")==0){

          let choice = this.insurance_type_list_filtered.filter(choice => choice.key === element.insuranceCoverType);
          if(choice && choice[0]) {
            element.insuranceCoverTypeName = choice[0].value;
          }

          grouped.severity.push(element);
          // console.log("severity-----");
          // console.log(grouped.severity);
        }
        
      }
    });  
    return grouped;
  }

  getChoiceList(){
    this.familyTreeService.getChoiceList().subscribe((clientData:XplanChoicesResponse)=>{
      if(clientData.success){
        this.choicesList = clientData.response;  
        
        this.insurance_type_list = this.sortByKey(this.choicesList.filter(choice => choice.choiceType === 17));

        this.filterInsuranceTypeList(this.insurance_type_list);    
        
        //console.log(this.contact_type_list);      
      } 
      
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message); 
      });
  }


  filterInsuranceTypeList(insurance_type_list){
    insurance_type_list.forEach(element => {
      if(element.key.indexOf("l")==0){
        element.insurance_type = "life";
        element.insurance_value = "Life Cover";
        this.insurance_type_list_filtered.push(element);
      }
      else if(element.key.indexOf("d")==0){
        element.insurance_type = "tpd";
        element.insurance_value = "TPD Cover";
        this.insurance_type_list_filtered.push(element);
      }
      else if(element.key.indexOf("t")==0){
        element.insurance_type = "trauma";
        element.insurance_value = "Trauma Cover";
        this.insurance_type_list_filtered.push(element);
      }
      else if(element.key.indexOf("i")==0){
        element.insurance_type = "income";
        element.insurance_value = "Income Cover";
        this.insurance_type_list_filtered.push(element);
      }
      else if(element.key.indexOf("b")==0){
        element.insurance_type = "business";
        element.insurance_value = "Business Cover";
        this.insurance_type_list_filtered.push(element);
      }
      else if(element.key.indexOf("o")==0){
        element.insurance_type = "additional";
        element.insurance_value = "Additional Cover";
        this.insurance_type_list_filtered.push(element);
      }
      else if(element.key.indexOf("x")==0){
        element.insurance_type = "severity";
        element.insurance_value = "Severity Cover";
        this.insurance_type_list_filtered.push(element);
      }
      // insurance_type_list_filtered
    });
  }

  sortByKey(choiceArray){
    return choiceArray.sort((n1,n2) => {
      if (n1.key > n2.key) {
          return 1;
      }
  
      if (n1.key < n2.key) {
          return -1;
      }                                      
      return 0;
    });
  }

  setMonthlyBenifit(data){
    
        if(data.benefitFrequency == 1){
          return data.benefitAmount/12 // Annually
        }
        if(data.benefitFrequency == 2){
          return data.benefitAmount/6 // Biannually
        }
        if(data.benefitFrequency == 4){
          return data.benefitAmount/3 // Quarterly
        }
        if(data.benefitFrequency == 12){
          return data.benefitAmount // Monthly
        }
        if(data.benefitFrequency == 26){
          return data.benefitAmount*2 // Fortnightly
        }
        if(data.benefitFrequency == 52){
          return data.benefitAmount*4 // Weekly
        }
    
  }

  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  waitingPeriod(value) {
    if(value == null || value == "" || value<=0) {
      return "-"
    } else if(value > 0 && value <= 10) {
      // If it is 1, 2 5, 6 or 10 it should say xx Years.
      if(value == 1) {
        return value+" Year"
      } else {
        return value+" Years"
      }
    } else {
      // If it is 55, 60 (benefit period only) 65, 67, 70. or 80 it should say To Age xx.
      let val = value.toString().replace("Days","");
      return val+" Days"
    }
  }

  benefitPeriod(value) {
    if(value == null || value == "" || value<=0) {
      return "-"
    } else if(value > 0 && value <= 10) {
      // If it is 1, 2 5, 6 or 10 it should say xx Years.
      if(value == 1) {
        return value+" Year"
      } else {
        return value+" Years"
      }
    } else {
      // If it is 55, 60 (benefit period only) 65, 67, 70. or 80 it should say To Age xx.
      return "Age "+value
    }
  }

  structure(value) {
    if(value == 0) { return 'Stepped' } else if(value == 1) { return 'Level' }
  }

  policyDefinition(value) {
    if(value == 0) { return 'Agreed' } else if(value == 1) { return 'Indemnity' }
  }

  fundingMechanism(value) {
    if(value == 0) { return 'Super' } else if(value == 1) { return 'Super via cash flow' } else if(value == 2) { return 'Cash flow' }
  }
  
  policyOwnersName(id) {
    if(id==null || id=="") {
      return "-"
    } else if(id==this.clientID) {
      return this.clientFirstname
    } else if(id==this.partnerID) {
      return this.partnerFirstname
    }
  }
}
