import { Component, OnInit, ViewChild, ElementRef, QueryList,ViewChildren, Inject } from '@angular/core';
import { Color, Label } from 'ng2-charts';
import { BaseChartDirective } from 'ng2-charts';
import { AppConstants } from '../../utility/app-constants';
import { GraphCalculationPipe } from 'src/app/shared/custom-pipes/graph-calculation.pipe';
import { CreationGraphPipe } from 'src/app/shared/custom-pipes/creation-graph.pipe';
import { ProtectionGraphPipe } from 'src/app/shared/custom-pipes/protection-graph.pipe';
import { FinancesComponent } from '../rightside/finances/finances.component';
import { FamilyTreeService } from '../family-tree.service';
import { SharedDataService } from 'src/app/shared/service/shared-data.service';
import { $ } from 'protractor';
import { GenderType, GraphType, RelationType } from './distibution/distibution.enum';
import * as annotations from 'chartjs-plugin-annotations';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-leftside',
  templateUrl: './leftside.component.html',
  styleUrls: ['./leftside.component.css'],
  template: `
    Message: {{ message }}
    <app-finances></app-finances>
  `,
  providers: [ GraphCalculationPipe, CreationGraphPipe, ProtectionGraphPipe ]
})


export class LeftsideComponent implements OnInit {

  @ViewChild('canvas1', { static: true }) canvas1: ElementRef<HTMLCanvasElement>;
  @ViewChild('canvas2', { static: true }) canvas2: ElementRef<HTMLCanvasElement>;
  @ViewChildren(BaseChartDirective) charts: QueryList<BaseChartDirective>;
  @ViewChild(FinancesComponent) finances: FinancesComponent;

  //Calculator
  showTerms : boolean = true;
  calculateDvalue = 0;
  moratage_value = 0;
  interestrate_value = 0;
  monthlyterms_value = 0;
  monthlypayments_value = 0;

  //Graphs
  super_interest = 0.075
  wage_growth  = 0.04
  retirement_goal = 0

  mainNodeAge = 0
  mainNodeGender = 0
  retirementAge = 0
  combinedRetirementIncome = 0;

  protectioPeak = 0

  moratage = 0;
  interestrate = 0;
  monthlyterms = 0;
  monthlypayments = 0;

  //retirementAge = 65;

  loadChart = false;

  creationAge = null;
  creationIncome = null;
  redrawCreationg = false;
  redrawProtectiong = false;
  loadChart1 = false;
  logoURL :string = '';

  message:string;

  financesInfo:any ='';
  mainUserFinanceTotal:number = 0;
  partnerUserFinanceTotal: number = 0;
  jointUserFinanceTotal: number = 0;


  // Nex Prev
  currentProtectionNo = 1;
  totalProtectionCount = 4;

  currentCreationNo = 1;
  totalCreationCount = 4;
  clientAge:number=0;

   colour_red = "#ff4d4d"
   colour_blue = "#2cbad3"
   colour_gray = "#a9a9a9"
   colour_bg_white = 'rgba(249,249,249,0.3)'
   colour_white = 'rgba(249,249,249,1.0)'

  isFullScreen = false;
  viewBoxVal = 2;
  transDVal = 7.3;

  constructor( private graphCalculation: GraphCalculationPipe, private creationGraph: CreationGraphPipe, 
    private protectionGraph: ProtectionGraphPipe, private familyTreeService :FamilyTreeService, 
    private sharedDataService: SharedDataService, @Inject(DOCUMENT) private document: Document,) {

    this.logoURL = environment.getLogoURL;
    this.sharedDataService.retirementAge.subscribe(retirementAge => this.retirementAge = retirementAge);
    this.sharedDataService.combinedRetirementIncome.subscribe(combinedRetirementIncome => this.combinedRetirementIncome = combinedRetirementIncome);
    this.sharedDataService.mainNodeAge.subscribe(mainNodeAge => this.mainNodeAge = mainNodeAge);
    this.sharedDataService.mainNodeGender.subscribe(mainNodeGender => this.mainNodeGender = mainNodeGender);
  this.sharedDataService.financesInfo.subscribe(financesInfo => this.financesInfo = financesInfo);
    this.sharedDataService.clientAge.subscribe(clientAge => this.clientAge = clientAge);

    BaseChartDirective.registerPlugin(annotations);

   }

  ngOnInit() {
    // this.familyTreeService.currentMessage.subscribe(message => this.message = message)
    // console.log(this.message, '===== finance info')

    var creationAge2 = this.retirementAge; // localStorage.getItem('retirementAge');
    var creationIncome2 = this.combinedRetirementIncome; // localStorage.getItem('combinedRetirementIncome');

    this.creationAge = this.retirementAge; // localStorage.getItem('retirementAge');
    this.creationIncome = this.combinedRetirementIncome; // localStorage.getItem('combinedRetirementIncome');

    this.checkValueChnage();

    setInterval(()=> {
      if(creationAge2 != this.retirementAge || creationIncome2 != this.combinedRetirementIncome) {

        this.creationAge = this.retirementAge; // localStorage.getItem('retirementAge');
        this.creationIncome = this.combinedRetirementIncome; // localStorage.getItem('combinedRetirementIncome');

        creationAge2 = this.retirementAge;
        creationIncome2 = this.combinedRetirementIncome; // localStorage.getItem('combinedRetirementIncome');
        this.clientAge = this.clientAge;
 
        this.checkValueChnage();

      }
    }, 3 * 1000);
  }




  dataPoints = [];

  //Creation chart data array
  public lineChartData = [
   // public lineChartData: ChartDataSets[] = [
    //{ data: [], label: 'Retirement Goal', borderWidth: 1 ,hoverRadius:0,hitRadius:0},
   // { data: [], label: 'Balanced', borderWidth: 1,hoverRadius:3,hitRadius:10 },
    // { data: [], label: 'Defensive', borderWidth: 1,hoverRadius:3,hitRadius:10 },
    // { data: [], label: 'Growth', borderWidth: 1,hoverRadius:3,hitRadius:10 },
    // { data: [], label: 'Advice', borderWidth: 1,hoverRadius:3,hitRadius:10 },
  ];

  public lineChartLabels: Label[] = [];


public lineChartOptions = {
  // public lineChartOptions: (ChartOptions ) = {
   responsive: true,
   maintainAspectRatio: true,
   multiTooltipTemplate: '<%= datasetLabel %> - <%= value %>',
   tooltips: {
    mode: 'label',
    filter: function (tooltipItem) {
      return (tooltipItem.datasetIndex === 1 || tooltipItem.datasetIndex === 2|| tooltipItem.datasetIndex === 3 || tooltipItem.datasetIndex === 4);
  },
  
    //enabled: true,
     callbacks: {
         label: function(tooltipItem, data) {

             var label = data.datasets[tooltipItem.datasetIndex].label || '';
             var retirementAge = data.datasets[tooltipItem.datasetIndex].retirementAge || 0;
            // label += " : " + (Math.round(Number(tooltipItem.yLabel) * 100) / 100) / 1000 + "k";
            if(tooltipItem.yLabel > 1000000) {
              label += " : " + (Math.round(tooltipItem.yLabel) / 1000000).toFixed(1)  + "M";
            }
            else {
              label += " : " + (Math.round(tooltipItem.yLabel) / 1000).toFixed(1)  + "k";
            }
             return label;
         },

     }
   },
   legend: {
    display: true,
    position: "bottom",
    labels : {
      fillStyle:true,
     // usePointStyle: true,
      usePointStyle: true,
     // pointStyle: 'rect',
      boxWidth: 20
  }
  


  //   item : {
  //     fillStyle: : true,
  //     boxWidth: 20
  // }
  },
  legendCallback: function(chart) {
    var text = [];
    text.push('<ul class="' + chart.id + '-legend">');
    var data = chart.data;
    var datasets = data.datasets;
    if (datasets.length) {
      for (var i = 0; i < datasets.length; ++i) {
        text.push('<li>');
        if (datasets[i].type=='line') {
          text.push('<span class="'+datasets[i].type+'" style="background-color:' + datasets[i].backgroundColor + '"></span>');
        } else {
          text.push('<span class="'+datasets[i].type+'" style="background-color:' + datasets[i].backgroundColor + '"></span>');
        }
        text.push(datasets[i].label);
        text.push('</li>');
      }
    }
    text.push('</ul>');
    return text.join('');
  },
   scales: {
     xAxes: [{

         gridLines: {
           drawOnChartArea: false
         },
         ticks: {
          autoSkip: false,
           stepSize:5,
           callback: function(value, index, values) {

        }
         },


     }],
     yAxes: [{
         offset:true,
         gridLines: {
           drawOnChartArea: false
         },
         ticks: {
           min: 0,
           stepSize:1,
           callback: function(value, index, values) {

           }

          // callback: label => `${(label / 1000)}k`
         }
     },
    {

     ticks: {
       stepSize:-1,
       display: false

 },
     gridLines: {
       drawOnChartArea: false
     },
         id: 'y-axis-1',
         position: 'right',

       }]
   },
   elements:
   {
       point:
       {
           radius: 0,
           hitRadius: 10,
           hoverRadius: 2,
           hoverBorderWidth: 1
       },
   }
 };



 public protectionChartOptions = {
  // public lineChartOptions: (ChartOptions ) = {
   responsive: true,
   maintainAspectRatio: true,
   multiTooltipTemplate: '<%= datasetLabel %> - <%= value %>',
   annotation: {
    annotations: [

    ],
  },
   tooltips: {
    mode: 'label',
  //   filter: function (tooltipItem) {
  //     return (tooltipItem.datasetIndex === 0 || tooltipItem.datasetIndex === 1 || tooltipItem.datasetIndex === 2 || tooltipItem.datasetIndex === 3);
  // },
    //enabled: true,
     callbacks: {
         label: function(tooltipItem, data) {


            // console.log("lbl")
             var label = data.datasets[tooltipItem.datasetIndex].label || '';
             var retirementAge = data.datasets[tooltipItem.datasetIndex].retirementAge || 0;
            // label += " : " + (Math.round(Number(tooltipItem.yLabel) * 100) / 100) / 1000 + "k";

            if(tooltipItem.yLabel > 1000000) {
              label += " : " + (Math.round(tooltipItem.yLabel) / 1000000).toFixed(1)  + "M";
            }
            else {
              label += " : " + (Math.round(tooltipItem.yLabel) / 1000).toFixed(1)  + "k";
            }
             return label;
         },

     }
   },
   scales: {
     xAxes: [{

         gridLines: {
           drawOnChartArea: false
         },
         ticks: {
          autoSkip: false,
           stepSize:-1,
           callback: function(value, index, values) {

        }
         },


     }],
     yAxes: [{
         offset:false,
         gridLines: {
           drawOnChartArea: false
         },
         ticks: {
          min: 0,
          // stepSize:1,
          callback: function(value, index, values) {

        }

          // callback: label => `${(label / 1000)}k`
         }
     },
    {

     ticks: {
      //  stepSize:-1,
       display: false

 },
     gridLines: {
       drawOnChartArea: false
     },
         id: 'y-axis-1',
         position: 'right',

       }]
   },
   elements:
   {
       point:
       {
           radius: 0,
           hitRadius: 10,
           hoverRadius: 2,
           hoverBorderWidth: 1
       },
   }
 };


  public lineChartColors: Color[] = [
 
  ];


  public protectedChartColors: Color[] = [
    // {
    //   borderColor: this.colour_blue,
    //   backgroundColor: this.colour_bg_white,
    //   borderWidth: [1],

    // },
    // {
    //   borderColor: this.colour_blue,
    //   backgroundColor: this.colour_bg_white,
    //   borderWidth: [1],

    // },
    // {
    //   borderColor: this.colour_gray,
    //   backgroundColor: this.colour_bg_white,
    //   borderWidth: [1],

    // },
    // {
    //   borderColor: this.colour_gray,
    //   backgroundColor: this.colour_bg_white,
    //   borderWidth: [1],

    // }
  ];

  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];
  public steppedLine: false

  public lineChartDataProtection = [
    //public lineChartDataProtection: ChartDataSets[] = [
      // { data: [], label: 'Creation Protected', retirementAge: this.creationAge, borderWidth: 1},
      // { data: [], label: 'Burndown Protected', retirementAge: this.creationAge, borderWidth: 1},
      // { data: [], label: 'Retirment', retirementAge:  this.creationAge, borderWidth: 1,steppedLine:true,borderDash: [10,5]},
      // { data: [], label: 'Death', retirementAge: this.creationAge, borderWidth: 1,steppedLine:true,borderDash: [10,5]}


  ];
  public lineChartLabelsProtection: Label[] = [];

  showTermsChange(val){
    if(val==1){
      this.showTerms = true;
    }
    else{
      this.showTerms = false;
    }
    this.calculateDvalue = 0;
    this.calculteTotal();

  }

  calculteTotal(){

    let moratage = this.moratage,
    interestrate = this.interestrate,
    monthlyterms = this.monthlyterms,
    monthlypayments = this.monthlypayments;

    this.moratage_value = (moratage)?moratage:0;
    this.interestrate_value = (interestrate)?interestrate:0;
    this.monthlyterms_value = (monthlyterms)?monthlyterms:0;
    this.monthlypayments_value = (monthlypayments)?monthlypayments:0;

    var term_val = 0;
    if(this.showTerms){
      term_val = this.monthlyterms_value;
      if(term_val > 0){
        if(this.interestrate_value >0){

          //by Ishara
          let monthInteretsRate = (this.interestrate_value / 1200);
          let rValue = 1 + monthInteretsRate;
          let powValue = Math.pow(rValue, term_val);
          this.calculateDvalue = Math.ceil(this.moratage_value * ((monthInteretsRate * powValue) / (powValue - 1)));

         // this.calculateDvalue =   (this.moratage_value * (1+this.interestrate_value/100))/term_val;
        }
        else{
          this.calculateDvalue = this.moratage_value / term_val;
        }
      }
    }
    else{
      term_val = this.monthlypayments_value;
      if(term_val > 0){
        if(this.interestrate_value >0){

          //By Ishara
          let monthInteretsRate = (this.interestrate_value / 1200);
          let xVal =  (term_val) /  (term_val - (monthInteretsRate * this.moratage_value))
          let yVal =  (1.0 + monthInteretsRate)
          this.calculateDvalue = Math.ceil((Math.log(xVal) / Math.log(yVal)) / 12)

          //this.calculateDvalue = (this.moratage_value * (1+this.interestrate_value/100))/term_val/12


        }
        else{
          this.calculateDvalue = this.moratage_value /term_val/12;
        }
      }
    }


  }


  checkValueChnage(){



    var combinedRetirementIncome = Number(this.creationIncome);
    var retirementAge = Number(this.creationAge);
    
    if(this.redrawCreationg){
      if(Number(this.creationIncome) > 0){
        combinedRetirementIncome = Number(this.creationIncome);
      }
      if(Number(this.creationAge) > 0){
         retirementAge = Number(this.creationAge);
        // console.log("creationAge ",this.creationAge);
      }
    }

    this.drawCreationGraph(combinedRetirementIncome,retirementAge)
    this.drawProtectionGraph(combinedRetirementIncome,retirementAge)

  }


  drawCreationGraph(ret_income,ret_age){

    // console.log("retirementAge",this.financesInfo)
    let retirementAge1 = Number(this.creationAge); // localStorage.getItem('retirementAge')
    this.creationAge = retirementAge1;
    let mainNodeAge1 = Number(this.mainNodeAge); // localStorage.getItem('mainNodeAge')
    this.mainNodeAge = mainNodeAge1;
    let mainNodeGender1 = Number(this.mainNodeGender); // localStorage.getItem('mainNodeGender')

    this.mainNodeGender =  mainNodeGender1

   

    let combinedRetirementIncome1 = Number(this.creationIncome); // this.combinedRetirementIncome // localStorage.getItem('combinedRetirementIncome')
    this.creationIncome = combinedRetirementIncome1;
    var income = 0, superContributionRate = 10, superBalance = 0;  // superContributionRate = 9.5  is previous value

     console.log("combinedRetirementIncome1",combinedRetirementIncome1)



    let financesInfo =  JSON.parse(localStorage.getItem('financesInfo')); // this.financesInfo;

    // console.log("financesInfo",financesInfo)
    if(financesInfo && financesInfo.income){
     let  ic = financesInfo.income;

     let ownerIncome = ic.filter(incomeType => incomeType.ownerType === 1)

     if (ownerIncome.length > 0) {

        income =  ownerIncome[0].value


     }

    }
    if(financesInfo && financesInfo.superContributionRate){
      let superContributionArray = financesInfo.superContributionRate;
      if (superContributionArray.length > 0) {

        superContributionRate =  superContributionArray[0].value


      }

    }
    if(financesInfo && financesInfo.superBalance){

      let superBalanceArr = financesInfo.superBalance;;
      if (superBalanceArr.length > 0) {

        superBalance =  superBalanceArr[0].value


      }
    }


    //let age_diff = Number(retirementAge)-Number(mainNodeAge);

    if (ret_income > 0) {

      this.creationIncome = ret_income // this.combinedRetirementIncome = ret_income

    }
    // else{

    //   this.creationIncome =  this.combinedRetirementIncome

    // }

    if (ret_age > 0) {

      this.creationAge = ret_age

    }


    // this.retirement_goal  = this.graphCalculation.getRetienmentGoal(this.mainNodeAge,this.retirementAge,superBalance,this.combinedRetirementIncome,getRetienmentGoal);
    this.retirement_goal  = this.graphCalculation.getRetienmentGoal(this.mainNodeAge,this.creationAge,superBalance,this.creationIncome,this.mainNodeGender);
    //this.lineChartData[0].label = "Retirement Goal";
    //this.lineChartData[1].label = "Balanced";

 

    this.lineChartLabels.length = 0;
    //this.lineChartData[0].data.length = 0;
    //this.lineChartData[1].data.length = 0;
    // this.lineChartData[2].data.length = 0;
    // this.lineChartData[3].data.length = 0;
    // this.lineChartData[4].data.length = 0;
    this.makeCreationGraps()
    for(let i=this.mainNodeAge;i <= this.creationAge;i++){

      this.lineChartLabels.push(String(i));
      this.lineChartData[0].data.push(this.retirement_goal);

    }

    console.log("retirement_goal",combinedRetirementIncome1)

    if (superContributionRate ==  0) {

       superContributionRate = 10; // superContributionRate = 9.5  is previous value

    }

    let superRate =  superContributionRate /  100


    this.loadChart = false;

   


    let secnarioArray = this.graphCalculation.getSuperGrowth(this.mainNodeAge,this.creationAge,superBalance,income,superRate)

    if (this.currentCreationNo == 1) {

      this.lineChartData[1].data = secnarioArray[0]

      this.lineChartColors[1].borderColor = this.colour_blue
      this.lineChartColors[1].pointBackgroundColor = this.colour_blue


      console.log("lineChartColors",this.lineChartColors.length)

      // this.lineChartColors[2].borderColor = this.colour_gray
      // this.lineChartColors[3].borderColor = this.colour_gray
      // this.lineChartColors[4].borderColor = this.colour_gray
      // this.lineChartColors[2].pointBackgroundColor = this.colour_gray
      // this.lineChartColors[3].pointBackgroundColor = this.colour_gray
      // this.lineChartColors[4].pointBackgroundColor = this.colour_gray

    }
    else if  (this.currentCreationNo == 2) {

      this.lineChartData[1].data = secnarioArray[0]
      this.lineChartColors[1].borderColor = this.colour_gray
      this.lineChartColors[1].pointBackgroundColor = this.colour_gray


      this.lineChartData[2].data = secnarioArray[1]
      this.lineChartColors[2].borderColor = this.colour_blue
      this.lineChartColors[2].pointBackgroundColor = this.colour_blue



      this.lineChartColors[3].borderColor = this.colour_gray
      this.lineChartColors[4].borderColor = this.colour_gray
      this.lineChartColors[3].pointBackgroundColor = this.colour_gray
      this.lineChartColors[4].pointBackgroundColor = this.colour_gray

    

   
      // this.lineChartColors[3].pointBackgroundColor = this.colour_gray
      // this.lineChartColors[4].pointBackgroundColor = this.colour_gray

    }
    else if  (this.currentCreationNo == 3) {

      this.lineChartData[1].data = secnarioArray[0]
      this.lineChartColors[1].borderColor = this.colour_gray
      this.lineChartColors[1].pointBackgroundColor = this.colour_gray
      this.lineChartData[2].data = secnarioArray[1]
      this.lineChartColors[2].borderColor = this.colour_gray
      this.lineChartColors[2].pointBackgroundColor = this.colour_gray
      this.lineChartData[3].data = secnarioArray[2]
      this.lineChartColors[3].borderColor = this.colour_blue
      this.lineChartColors[3].pointBackgroundColor = this.colour_blue
    }
    else if  (this.currentCreationNo == 4) {


      this.lineChartData[1].data = secnarioArray[0]
      this.lineChartColors[1].borderColor = this.colour_gray
      this.lineChartColors[1].pointBackgroundColor = this.colour_gray
      this.lineChartData[2].data = secnarioArray[1]
      this.lineChartColors[2].borderColor = this.colour_gray
      this.lineChartColors[2].pointBackgroundColor = this.colour_gray
      this.lineChartData[3].data = secnarioArray[2]
      this.lineChartColors[3].borderColor = this.colour_gray
      this.lineChartColors[3].pointBackgroundColor = this.colour_gray
      this.lineChartData[4].data = secnarioArray[3]
      this.lineChartColors[4].borderColor = this.colour_blue
      this.lineChartColors[4].pointBackgroundColor = this.colour_blue
    }
   
    
    this.lineChartOptions.scales.yAxes[0].ticks.stepSize =  (this.retirement_goal > 1000000) ? 1000000 : 1000 
     this.lineChartOptions.scales.xAxes[0].ticks.stepSize = this.creationAge;

    // this.chart.update();


    this.lineChartOptions.scales.yAxes[0].ticks.callback =  this.creationGraph.processThis.bind(this)
    this.lineChartOptions.scales.xAxes[0].ticks.callback =  this.creationGraph.xAxisEnds.bind(this)

    if (this.charts != null) {

      this.charts.forEach((child) => {
       // console.log("ra ",this.retirementAge);

        child.chart.options.scales.yAxes[0].ticks.stepSize = this.retirement_goal
        child.chart.options.scales.xAxes[0].ticks.stepSize = this.creationAge
        child.chart.generateLegend();
       // child.cha

      });

    }

    this.loadChart = true;


  }

  makeProtectionGraps(){

    this.protectionChartOptions.annotation.annotations.length = 0
    this.protectedChartColors.length = 0
    this.lineChartDataProtection.length = 0

    this.protectionChartOptions.annotation.annotations.push( {
      drawTime: "afterDatasetsDraw",
      type: 'line',
      mode: 'vertical',
      scaleID: 'x-axis-0',
      value: 0,
      borderColor: this.colour_gray,
      borderWidth: 1,
      display: false,
      steppedLine:true,
      borderDash: [10,5],
      label: {
        position: 'top',
        enabled: true,
        fontColor: this.colour_gray,
        content: 'Retirement age'
      }
    })

  
    let yrs_to_retirement =  this.retirementAge -  this.mainNodeAge
    let death_age =  Math.floor(yrs_to_retirement /  2)

    console.log("death_age",death_age)

    this.protectionChartOptions.annotation.annotations.push(  {
      drawTime: "afterDatasetsDraw",
      type: '',
      mode: 'vertical',
      scaleID: 'x-axis-0',
      value: 0,
      borderColor: this.colour_red,
      display: false,
      borderWidth: 0,
      steppedLine:true,
      borderDash: [10,5],
      label: {
        position: 'top',
        enabled: true,
        fontColor: this.colour_white,
        backgroundColor:this.colour_red,
        content: 'Death'
      }
    })



    if (this.currentProtectionNo == 1){

          this.protectedChartColors.push( {
            borderColor: this.colour_blue, 
            backgroundColor: this.colour_bg_white,
            borderWidth: [1],
      
          },
          {
            borderColor: this.colour_blue,
            backgroundColor: this.colour_bg_white,
            borderWidth: [1],
      
          })

          this.lineChartDataProtection.push({ data: [], label: 'Creation Protected', retirementAge: this.creationAge, borderWidth: 1})

          this.lineChartDataProtection.push(
          { data: [], label: 'Burndown Protected', retirementAge: this.creationAge, borderWidth: 1})

    }
    else {

  
  
      if (this.currentProtectionNo == 2){

        this.lineChartDataProtection.push({ data: [], label: 'Creation Protected', retirementAge: this.creationAge, borderWidth: 1})

        this.lineChartDataProtection.push(
        { data: [], label: 'Burndown Protected', retirementAge: this.creationAge, borderWidth: 1})

        this.lineChartDataProtection.push(
          { data: [], label: 'Burndown Unprotected', retirementAge: this.creationAge, borderWidth: 1})

          this.lineChartDataProtection.push(
            { data: [], label: 'Creation Unprotected', retirementAge: this.creationAge, borderWidth: 1})

        this.protectedChartColors.push( {
          borderColor: this.colour_gray, 
          backgroundColor: this.colour_bg_white,
          borderWidth: [1],
    
        },
        {
          borderColor: this.colour_gray,
          backgroundColor: this.colour_bg_white,
          borderWidth: [1],
    
        },{
          borderColor: this.colour_gray, 
          backgroundColor: this.colour_bg_white,
          borderWidth: [1],
    
        },
        {
          borderColor: this.colour_gray,
          backgroundColor: this.colour_bg_white,
          borderWidth: [1],
    
        })


        
      }
      else if (this.currentProtectionNo == 3){ 

        this.lineChartDataProtection.push({ data: [], label: 'Creation + Lump Sum', retirementAge: this.creationAge, borderWidth: 1})

        this.lineChartDataProtection.push(
        { data: [], label: 'Creation Protected', retirementAge: this.creationAge, borderWidth: 1})

        this.lineChartDataProtection.push(
        { data: [], label: 'Creation Unprotected', retirementAge: this.creationAge, borderWidth: 1})

        this.lineChartDataProtection.push(
        { data: [], label: 'Burndown Unprotected', retirementAge: this.creationAge, borderWidth: 1})

        this.lineChartDataProtection.push(
        { data: [], label: 'Burndown Protected', retirementAge: this.creationAge, borderWidth: 1})

     


           
        this.protectedChartColors.push( {
          borderColor: this.colour_gray, 
          backgroundColor: this.colour_bg_white,
          borderWidth: [1],
    
        },
        {
          borderColor: this.colour_red,
          backgroundColor: this.colour_bg_white,
          borderWidth: [1],
    
        },{
          borderColor: this.colour_gray, 
          backgroundColor: this.colour_bg_white,
          borderWidth: [1],
    
        },
        {
          borderColor: this.colour_red,
          backgroundColor: this.colour_bg_white,
          borderWidth: [1],
    
        },{
          borderColor: this.colour_gray,
          backgroundColor: this.colour_bg_white,
          borderWidth: [1],
    
        })

      }
      else if (this.currentProtectionNo == 4){ 

        this.lineChartDataProtection.push({ data: [], label: 'Creation + Lump Sum', retirementAge: this.creationAge, borderWidth: 1})

        this.lineChartDataProtection.push(
        { data: [], label: 'Creation Unprotected', retirementAge: this.creationAge, borderWidth: 1})



        this.lineChartDataProtection.push(
          { data: [], label: 'Burndown Unprotected', retirementAge: this.creationAge, borderWidth: 1})


        this.lineChartDataProtection.push(
          { data: [], label: 'Creation Protected', retirementAge: this.creationAge, borderWidth: 1})


        this.lineChartDataProtection.push(
        { data: [], label: 'Burndown Protected', retirementAge: this.creationAge, borderWidth: 1})




           
        this.protectedChartColors.push( {
          borderColor: this.colour_blue, 
          backgroundColor: this.colour_bg_white,
          borderWidth: [1],
    
        },
        {
          borderColor: this.colour_blue,
          backgroundColor: this.colour_bg_white,
          borderWidth: [1],
    
        },{
          borderColor: this.colour_red, 
          backgroundColor: this.colour_bg_white,
          borderWidth: [1],
    
        },
        {
          borderColor: this.colour_blue,
          backgroundColor: this.colour_bg_white,
          borderWidth: [1],
    
        },{
          borderColor: this.colour_blue,
          backgroundColor: this.colour_bg_white,
          borderWidth: [1],
    
        })




      }



    }



  }

  makeCreationGraps(){

  this.lineChartColors =  [ {
      borderColor: this.colour_red,
      backgroundColor: this.colour_bg_white,
      pointBackgroundColor:this.colour_red,
      pointStyle:"rect"
    },
    {
      borderColor: this.colour_blue,
      backgroundColor: this.colour_bg_white,
      borderWidth: [1],
      pointBackgroundColor:this.colour_blue,
      pointStyle:"rect"
    },
    {
      borderColor: this.colour_gray,
      backgroundColor: this.colour_bg_white,
      pointBackgroundColor:this.colour_gray,
      pointStyle:"rect"
    },
    {
      borderColor: this.colour_gray,
      backgroundColor: this.colour_bg_white,
      pointBackgroundColor:this.colour_gray,
      pointStyle:"rect"
    },
    {
      borderColor: this.colour_gray,
      backgroundColor: this.colour_bg_white,
      pointBackgroundColor:this.colour_gray,
      pointStyle:"rect"
    }]
    // { data: [], label: 'Retirement Goal', borderWidth: 1 ,hoverRadius:0,hitRadius:0},
    // { data: [], label: 'Balanced', borderWidth: 1,hoverRadius:3,hitRadius:10 },
    // // { data: [], label: 'Defensive', borderWidth: 1,hoverRadius:3,hitRadius:10 },
    // // { data: [], label: 'Growth', borderWidth: 1,hoverRadius:3,hitRadius:10 },
    // // { data: [], label: 'Advice', borderWidth: 1,hoverRadius:3,hitRadius:10 },

   console.log("makeCreationGraps",this.currentCreationNo)  
    this.lineChartData.length = 0

    this.lineChartData.push({ data: [], label: 'Retirement Goal', borderWidth: 1 ,hoverRadius:0,hitRadius:0})
    this.lineChartData.push({ data: [], label: 'Balanced', borderWidth: 1,hoverRadius:3,hitRadius:10 })


    if (this.currentCreationNo > 1) {

      this.lineChartData.push({ data: [], label: 'Defensive', borderWidth: 1,hoverRadius:3,hitRadius:10 })


    }
    
    
    if (this.currentCreationNo > 2) {  

      //this.lineChartData.push({ data: [], label: 'Defensive', borderWidth: 1,hoverRadius:3,hitRadius:10 })
      this.lineChartData.push({ data: [], label: 'Growth', borderWidth: 1,hoverRadius:3,hitRadius:10 })
   



    }
  
    if (this.currentCreationNo > 3) { 


     // this.lineChartData.push({ data: [], label: 'Defensive', borderWidth: 1,hoverRadius:3,hitRadius:10 })
     // this.lineChartData.push({ data: [], label: 'Growth', borderWidth: 1,hoverRadius:3,hitRadius:10 })
      this.lineChartData.push({ data: [], label: 'Advice', borderWidth: 1,hoverRadius:3,hitRadius:10 })
    }


  }

  drawProtectionGraph(ret_income,ret_age){


    let retirementAge = Number(this.creationAge); // localStorage.getItem('retirementAge')
    this.creationAge = retirementAge;
    let mainNodeAge1 = Number(this.mainNodeAge); // localStorage.getItem('mainNodeAge')
    this.mainNodeAge =  mainNodeAge1

    let mainNodeGender1 = Number(this.mainNodeGender); // localStorage.getItem('mainNodeGender')

    this.mainNodeGender =  mainNodeGender1

    let combinedRetirementIncome = Number(this.creationIncome);  // Number(this.combinedRetirementIncome);// localStorage.getItem('combinedRetirementIncome')
    let income = 0, superContributionRate = 10, superBalance = 0; // superContributionRate = 9.5  is previous value


    let financesInfo = JSON.parse(localStorage.getItem('financesInfo')); // this.financesInfo;
    if(financesInfo && financesInfo.income){
      let  ic = financesInfo.income;

      let ownerIncome = ic.filter(incomeType => incomeType.ownerType === 1)

      if (ownerIncome.length > 0) {

         income =  ownerIncome[0].value


      }

     }
     if(financesInfo && financesInfo.superContributionRate){
       let superContributionArray = financesInfo.superContributionRate;
       if (superContributionArray.length > 0) {

         superContributionRate =  superContributionArray[0].value


       }

     }
     if(financesInfo && financesInfo.superBalance){

       let superBalanceArr = financesInfo.superBalance;;
       if (superBalanceArr.length > 0) {

         superBalance =  superBalanceArr[0].value


       }
     }

    if(this.redrawCreationg){
      if(Number(this.creationIncome) > 0){
        combinedRetirementIncome = Number(this.creationIncome);
      }
      if(Number(this.creationAge) > 0){
        retirementAge = Number(this.creationAge);
      }
    }

    if (ret_income > 0) {

      this.creationIncome = ret_income; // this.combinedRetirementIncome = ret_income
      
    }

    if (ret_age > 0) {

      this.creationAge = ret_age

    }



  //  this.lineChartDataProtection[0].label = "Creation Protected";

    let ini = income*superContributionRate/100;

    this.lineChartLabelsProtection.length = 0;
   // this.lineChartDataProtection[0].data.length = 0;
   // this.lineChartDataProtection[3].data.length = 0;
    let le_5 = this.graphCalculation.getLifeExpetancy(this.mainNodeAge,this.mainNodeGender)

    for(let i=this.mainNodeAge;i <= le_5 + 1;i++){

      this.lineChartLabelsProtection.push(String(i));

    }

    if  (superContributionRate == 0){

        superContributionRate = 10; // superContributionRate = 9.5  is previous value

    }


    this.makeProtectionGraps()
    let data  = this.graphCalculation.getProtectionGraphData(this.retirementAge,this.mainNodeAge,superBalance,income,(9/100),combinedRetirementIncome,this.mainNodeGender)




   if (this.currentProtectionNo == 1) {

    let creation  =  data[0]
    let burndown =  data[1]

    this.lineChartDataProtection[0].data = creation
    this.lineChartDataProtection[1].data = burndown


   }
   else if (this.currentProtectionNo == 2) {

    //this.protectionChartOptions.annotation.annotations[1].value = death_age

    this.lineChartDataProtection[0].data = data[0]
    this.lineChartDataProtection[1].data = data[1]
    this.lineChartDataProtection[2].data = data[2]
    this.lineChartDataProtection[3].data = data[3]
 
   }


   else if (this.currentProtectionNo == 3) {

    //this.protectionChartOptions.annotation.annotations[1].value = death_age

    //  this.lineChartDataProtection[0].data = data[4]
     this.lineChartDataProtection[1].data = data[5] // red
     this.lineChartDataProtection[2].data = data[6]
     //this.lineChartDataProtection[3].data = data[7]
     //this.lineChartDataProtection[4].data = data[8]

     let arr = []
     arr = this.createCoordinates(arr, data[4]); // added x, y coordinates

     arr = this.setDoublePoint(arr, data[6]) // set double x coordinates

     //sorrt for added point for positioning
    arr.sort((a,b) => (parseInt(a.x) > parseInt(b.x)) ? 1 : ((parseInt(b.x) > parseInt(a.x)) ? -1 : (parseInt(b.x) === parseInt(a.x)) ? -1 : 0))

    this.lineChartDataProtection[0].data = arr;
 
   }
   else if (this.currentProtectionNo == 4) {

    // this.lineChartDataProtection[0].data = data[4]
    this.lineChartDataProtection[1].data = data[6]
    this.lineChartDataProtection[2].data = data[7]
    this.lineChartDataProtection[3].data = data[5]
    // this.lineChartDataProtection[4].data = data[8]

    let arr = []
    arr = this.createCoordinates(arr, data[4]);

    if (data[6][data[6].length - 2] > data[5][data[5].length - 2]) {
        arr = this.setDoublePoint(arr, data[6])
    } else {
        arr = this.setDoublePoint(arr, data[5])
    }

    arr.sort((a,b) => (parseInt(a.x) > parseInt(b.x)) ? 1 : ((parseInt(b.x) > parseInt(a.x)) ? -1 : (parseInt(b.x) === parseInt(a.x)) ? -1 : 0))
    this.lineChartDataProtection[0].data = arr;
   }
 



    //this.lineChartDataProtection[0].data = creation
   // this.lineChartDataProtection[1].data = burndown
    //this.lineChartDataProtection[2].data = stepped
    // this.lineChartDataProtection[2].data = stepped
    // this.protectioPeak = creation[creation.length - 1]
   

    // if (this.currentProtectionNo >  1){

    //   this.lineChartDataProtection[3].data = death
   

    // }

   
    // console.log("protectioPeak",burndown[burndown.length - 1])

    // this.protectionChartOptions.scales.yAxes[0].ticks.stepSize =  (this.protectioPeak > 1000000) ? 1000000 : 1000 
    //this.protectionChartOptions.scales.yAxes[0].ticks.stepSize = 1000//this.protectioPeak
    this.protectionChartOptions.scales.yAxes[0].ticks.callback =  this.creationGraph.processThis.bind(this)


   //  this.protectionChartOptions.scales.xAxes[0].ticks.stepSize = this.retirementAge;
    // this.chart.update();

    if (this.charts != null) {

      this.charts.forEach((child) => {


        if (child.chart.options["annotation"] != null){

          console.log("annotation", child.chart.options["annotation"]["annotations"]);
          child.chart.options["annotation"]["annotations"][0]["type"] = "line"
          child.chart.options["annotation"]["annotations"][0]["value"] = this.retirementAge - this.mainNodeAge

            if (this.currentProtectionNo > 1) {

              let yrs_to_retirement =  this.retirementAge -  this.mainNodeAge
              let death_age =  Math.floor(yrs_to_retirement /  2)
              child.chart.options["annotation"]["annotations"][1]["type"] = "line"
              child.chart.options["annotation"]["annotations"][1]["value"] = death_age

            }
            else{

              child.chart.options["annotation"]["annotations"][1]["type"] = ""
            


            }


        }
        
      

        // this.protectionChartOptions.scales.yAxes[0].ticks.stepSize =  (this.protectioPeak > 1000000) ? 1000000 : 1000 
   
    
        child.chart.generateLegend();
        child.chart.update();
       // child.cha

      });

    }

 

    this.protectionChartOptions.scales.xAxes[0].ticks.callback =  this.protectionGraph.xPrtectedAxisEnds.bind(this)
   

    this.loadChart1 = true;

  }

  setDoublePoint(arr, cArray) {
    arr.push(
      { 
        x: this.lineChartLabelsProtection[cArray.length - 2],
        y: cArray[cArray.length - 2]
      }
    )
    return arr;
  }

  createCoordinates(arr, cArray) {
    this.lineChartLabelsProtection.map((labl)=> {
      const a = {
        x: labl,
        y: null
      }
      arr.push(a)
    })

    cArray.map((b, j)=> {
      if (b) {
        arr[j].y = b;
      }
   })
    return arr;
  }

  redrawCreation(){

    this.loadChart = false;
    this.redrawCreationg = true;
    this.lineChartLabels.length = 0;
    this.lineChartData[0].data.length = 0;
    this.lineChartData[1].data.length = 0;
   // this.lineChartOptions.scales.yAxes[0].ticks.stepSize = -1

    this.loadChart = true;
    this.loadChart = false;


    this.loadChart1 = false;
    this.redrawProtectiong = true;
    this.lineChartLabelsProtection.length = 0;
   // this.lineChartDataProtection[0].data.length = 0;
  //  this.lineChartDataProtection[3].data.length = 0;
    this.checkValueChnage()
  //  this.drawProtectionGraph();
  }

  resetCreation(){

    this.loadChart = true; // false
    this.redrawCreationg = true;  // false
    this.lineChartLabels.length = 0;


    this.creationAge = this.retirementAge; // localStorage.getItem('retirementAge'); // null
    this.creationIncome = this.combinedRetirementIncome; // localStorage.getItem('combinedRetirementIncome'); // null

    // this.checkValueChnage()

    this.loadChart1 = true; // false
    this.redrawProtectiong = true; // false
   // this.lineChartLabelsProtection.length = 0;
   // this.lineChartDataProtection[0].data.length = 0;
   // this.lineChartDataProtection[3].data.length = 0;
   // this.creationAge = this.retirementAge; // localStorage.getItem('retirementAge'); // null
   // this.creationIncome = this.combinedRetirementIncome; // localStorage.getItem('combinedRetirementIncome'); // null


    var combinedRetirementIncome = 0
    var retirementAge = 0

    if(this.redrawCreationg){
      if(Number(this.creationIncome) > 0){
        combinedRetirementIncome = Number(this.creationIncome);
        // console.log("creationIncome ",this.creationIncome);
      }
      if(Number(this.creationAge) > 0){
         retirementAge = Number(this.creationAge);
        // console.log("creationAge ",this.creationAge);
      }
    }

    this.drawCreationGraph(combinedRetirementIncome,retirementAge);
    this.drawProtectionGraph(combinedRetirementIncome,retirementAge);

    //   this.drawProtectionGraph();
  }


  openFullscreenCreation() {
    // // Use this.divRef.nativeElement here to request fullscreen
    // const elem = this.canvas1.nativeElement;

    // if (elem.requestFullscreen) {
    //   elem.requestFullscreen();
    // }


    // d3.selectAll("svg").remove();
    this.isFullScreen = !this.isFullScreen;

    if (this.isFullScreen) {
        this.viewBoxVal = 3;
        this.transDVal = 1.6;
        this.document.body.classList.add('full-screen');
    } else {
      this.viewBoxVal = 2;
      this.transDVal = 7.3;
      this.document.body.classList.remove('full-screen');
    }

  }

  openFullscreenProtection() {
    // // Use this.divRef.nativeElement here to request fullscreen
    // const elem = this.canvas2.nativeElement;

    // if (elem.requestFullscreen) {
    //   elem.requestFullscreen();
    // }

    this.isFullScreen = !this.isFullScreen;

    if (this.isFullScreen) {
        this.viewBoxVal = 3;
        this.transDVal = 1.6;
        this.document.body.classList.add('full-screen');
    } else {
      this.viewBoxVal = 2;
      this.transDVal = 7.3;
      this.document.body.classList.remove('full-screen');
    }
  }

  // // -- Toggle graph - FULL SCREEN -- //
  // public toggleScreen() {

  //   // d3.selectAll("svg").remove();
  //   this.isFullScreen = !this.isFullScreen;

  //   if (this.isFullScreen) {
  //       this.viewBoxVal = 3;
  //       this.transDVal = 1.6;
  //       this.document.body.classList.add('full-screen');
  //   } else {
  //     this.viewBoxVal = 2;
  //     this.transDVal = 7.3;
  //     this.document.body.classList.remove('full-screen');
  //   }
  //   this.createSVG(this.viewBoxVal, this.transDVal); // Re create svg
  //   this.changeGraph(this.selectedGraphNumber);
  // }


  public prevCreationGraph() {
    if (this.currentCreationNo != 1) {
      this.currentCreationNo = this.currentCreationNo - 1;
      console.log('Creation Graph : ', this.currentCreationNo);
      this.checkValueChnage()

    }

  }

  public nextCreationGraph() {
    if (this.currentCreationNo != this.totalCreationCount) {
    this.currentCreationNo = this.currentCreationNo + 1;
    console.log('Creation Graph : ', this.currentCreationNo);
    this.checkValueChnage()
  }
  }

  public prevProtectionGraph() {
    if (this.currentProtectionNo != 1)
    this.currentProtectionNo = this.currentProtectionNo - 1;
    console.log('Protection Graph : ', this.currentProtectionNo);
    this.checkValueChnage()
  }

  public nextProtectionGraph() {
    if (this.currentProtectionNo != this.totalProtectionCount)
    this.currentProtectionNo = this.currentProtectionNo + 1;
    console.log('Protection Graph : ', this.currentProtectionNo);
    this.checkValueChnage()
  }


}
