<div class="row">
  <div class="col-md-12">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item">
        <a class="nav-link active tc1" id="family-tab" data-toggle="tab" href="#family" role="tab"
          aria-controls="family" aria-selected="true" style="text-transform: uppercase;">Family</a>
      </li>
      <li class="nav-item">
        <a class="nav-link tc1" id="finances-tab" data-toggle="tab" href="#finances" (click)="setfinance()" role="tab" aria-controls="finances"
          aria-selected="false" style="text-transform: uppercase;">Finances</a>
      </li>
      <li class="nav-item">
        <a class="nav-link tc1" id="budget-tab" data-toggle="tab" href="#budget"  role="tab" aria-controls="budget" aria-selected="false" style="text-transform: uppercase;">Budget</a>
      </li>
      <li class="nav-item">
        <a class="nav-link tc1" id="retirement-tab" data-toggle="tab" href="#retirement" role="tab" aria-controls="retirement" aria-selected="false" style="text-transform: uppercase;">Retirement</a>
      </li>
      <li class="nav-item">
        <a class="nav-link tc1" id="provisions-tab" data-toggle="tab" href="#provisions" role="tab" aria-controls="provisions" aria-selected="false" style="text-transform: uppercase;">Provisions</a>
      </li>
      <li class="nav-item">
        <a class="nav-link tc1" id="insurance-tab" data-toggle="tab" href="#insurance" role="tab" aria-controls="insurance" aria-selected="false" style="text-transform: uppercase;">Insurance</a>
      </li>
      <li class="nav-item">
        <a class="nav-link tc1" id="will-tab" data-toggle="tab" href="#will" role="tab" aria-controls="will"
          aria-selected="false" style="text-transform: uppercase;">Will</a>
      </li>
      <ng-container *ngIf="membersLength>0">
        <li class="nav-item">
          <a class="nav-link tc1" id="documents-tab" data-toggle="tab" href="#documents" role="tab"
            aria-controls="documents" aria-selected="false" style="text-transform: uppercase;">Documents</a>
        </li>
      </ng-container>
      <ng-container *ngIf="!familyTreeExist">
        <li class="nav-item">
          <a class="nav-link tc1" id="documents-tab" data-toggle="tab" href="#documents" role="tab"
            aria-controls="documents" aria-selected="false" style="text-transform: uppercase;">Documents</a>
        </li>
      </ng-container>
    </ul>

    <ngx-ui-loader></ngx-ui-loader>

    <div class="tab-content tab_height scroll_content" id="myTabContent">

    <!-- Family -->
      <div class="tab-pane fade show active pt-3 pb-4 familytab" id="family" role="tabpanel" aria-labelledby="family-tab" style="margin-bottom: 36px;">
        <app-family [client_id]="client_id" [clientFname]="clientFname" [clientLname]="clientLname" [clientMname]="clientMname" [clientDob]="clientDob" [clientGender]="clientGender" [xeppoCode]="xeppoCode" [client_code]="client_code"></app-family>
      </div>
    <!-- Family -->

    <!-- Finances -->
    <div *ngIf="isLoaded && familyTreeExist" class="tab-pane fade pt-3 pb-4 financetab" id="finances" role="tabpanel" aria-labelledby="finances-tab">
      <app-finances [client_id]="client_id" [xeppoCode]="xeppoCode"></app-finances>
    </div>
    <!-- Finances -->

    <!-- Budget -->
   <div *ngIf="isLoaded && familyTreeExist" class="tab-pane fade pt-3 pb-4 budgettab" id="budget" role="tabpanel" aria-labelledby="budget-tab">
      <app-budget [client_id]="client_id"></app-budget>
    </div>
    <!-- Budget -->

    <!-- Retirement -->
    <div *ngIf="isLoaded && familyTreeExist" class="tab-pane fade pt-3 pb-4 retirementtab" id="retirement" role="tabpanel" aria-labelledby="retirement-tab">
      <app-retirement [client_id]="client_id"></app-retirement>
    </div>
    <!-- Retirement -->

    <!-- Provisions -->
    <div *ngIf="isLoaded && familyTreeExist" class="tab-pane fade pt-3 pb-4 provisionstab" id="provisions" role="tabpanel" aria-labelledby="provisions-tab">
      <app-provisions></app-provisions>
    </div>
    <!-- Provisions -->

    <!-- Insurance -->
    <div *ngIf="isLoaded && familyTreeExist" class="tab-pane fade pt-3 pb-4" id="insurance" role="tabpanel" aria-labelledby="insurance-tab">
      <app-insurance [client_id]="client_id" ></app-insurance>
    </div>
    <!-- Insurance -->

    <!-- Will -->
    <div *ngIf="isLoaded && familyTreeExist" class="tab-pane fade pt-3 pb-4 willtab" id="will" role="tabpanel" aria-labelledby="will-tab">
      <app-will></app-will>
    </div>
    <!-- Will -->

    <!-- Document -->
    <div *ngIf="isLoaded && familyTreeExist" class="tab-pane fade pt-3 pb-4" id="documents" role="tabpanel" aria-labelledby="documents-tab">
      <app-documents [xeppoCode]="xeppoCode" [client_code]="client_code"></app-documents>
    </div>
    <!-- Document -->
    <ng-container *ngIf="!familyTreeExist">
      <div class="col-md-12 col-12 border-right-blue-grey border-right-lighten-5 text-center">
          <img class="mx-auto d-block" src="../../../assets/img/no_data_found.png" alt="avatar" style="height: 90px; margin-bottom: 10px;margin-top: 50px;">
          <h4 class="text-center" style="color: #808493; margin-bottom: 20px; font-size: 20px;">Client not found in Xplan.</h4>
      </div>
    </ng-container>
    </div>
  </div>
</div>



