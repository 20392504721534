import { Component, OnInit, Input } from '@angular/core';
import { ProvisionsComponent } from '../provisions.component';
import { FamilyTreeService } from 'src/app/family-tree/family-tree.service';
import { SharedDataService } from 'src/app/shared/service/shared-data.service';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProvisionModel, OtherProvisionsItems, OtherProvisionsAddItems } from 'src/app/shared/models/provision.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ProvisionResponse, OtherProvisionsResponse, DeleteOtherProvisionResponse } from 'src/app/shared/models/response.model';

@Component({
  selector: 'app-other',
  templateUrl: './other.component.html',
  styleUrls: ['../../rightside.component.css']
})
export class OtherComponent implements OnInit {

  @Input() family_tree_id:string;
  @Input() partnerHas:boolean = false;
  @Input() client_code: string;
  @Input() partnerClientCode:string;
  @Input() clientFirstname:string;
  @Input() partnerFirstname:string;
  provisionsCompOneObj:ProvisionsComponent;
  provisionInfo:ProvisionModel;
  otherProvisionsItemsAdd:OtherProvisionsItems;
  isLoaded:boolean=false;
  addOtherProvisionD: any=[];
  
  constructor(
    private familyTreeService :FamilyTreeService,
    private sharedDataService: SharedDataService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.provisionsCompOneObj = new ProvisionsComponent(this.familyTreeService, this.sharedDataService, this.toastr );
    this.getProvisionInfo();
  }

  getProvisionInfo(){
    //this.ngxService.start();
    this.familyTreeService.getProvision(this.family_tree_id).subscribe((provisionData:ProvisionResponse)=>{
      if(provisionData.success){
        this.provisionInfo = provisionData.response;
        

      } 
      // this.ngxService.stop();
      this.isLoaded = true;
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
    });
  }

  otherProvisionsSave(otherProvisions, event: any){
    let value = event.target.value;
    let inputId = event.target.id;
    
    this.otherProvisionsItemsAdd = new OtherProvisionsItems;

    // if (this.favouriteModel.docType1 && this.favouriteModel.docType1 == '2' && this.searchStr == "") {
    //   this.otherProvisionsItemsAdd.controls['searchText1'].setErrors({'required': true});
    // }

    // var description_add = ((document.getElementById("description_add") as HTMLInputElement).value);
    // description_add = description_add.replace(/\s/g, "");
    // console.log(description_add);
    // if(description_add == ""){
    //   this.toastr.error('Description is required.');
    // }

    if(inputId == 'description') {
      if(otherProvisions.clientProvision) {

        this.otherProvisionsItemsAdd.clientCode = otherProvisions.clientProvision.clientCode;
        this.otherProvisionsItemsAdd.description = value;
        this.otherProvisionsItemsAdd.value = otherProvisions.clientProvision.value;

        //this.otherProvisionsUpdate(otherProvisions.clientProvision.id, this.otherProvisionsItemsAdd);
        this.familyTreeService.editOtherProvisions(otherProvisions.clientProvision.id, this.otherProvisionsItemsAdd).subscribe((data:OtherProvisionsResponse)=>{
          
        },err=>{   
          console.log(err);
          this.toastr.error('Error on updating other provisions.');
        });

      }

      if(otherProvisions.partnerProvision) {
        this.otherProvisionsItemsAdd.clientCode = otherProvisions.partnerProvision.clientCode;
        this.otherProvisionsItemsAdd.description = value;
        this.otherProvisionsItemsAdd.value = otherProvisions.partnerProvision.value;

        //this.otherProvisionsUpdate(otherProvisions.partnerProvision.id, this.otherProvisionsItemsAdd);
        this.familyTreeService.editOtherProvisions(otherProvisions.partnerProvision.id, this.otherProvisionsItemsAdd).subscribe((data:OtherProvisionsResponse)=>{
          if(data.error && !data.success){      
            this.toastr.error('Error on updating other provisions.');      
          }
          else{
            this.toastr.success('Other provisions updated successfully.');
          }
    
          this.getProvisionInfo();
        },err=>{   
          console.log(err);
          this.toastr.error('Error on updating other provisions.');
        });
      }
      
    }
    else {
      this.otherProvisionsItemsAdd.clientCode = otherProvisions.clientCode;
    
      if(inputId == 'provisionValue' || inputId == 'provisionValuePartner') {
        this.otherProvisionsItemsAdd.value = value;
        this.otherProvisionsItemsAdd.description = otherProvisions.description;
      } 

      this.otherProvisionsUpdate(otherProvisions.id, this.otherProvisionsItemsAdd);
    }
    
  }

  otherProvisionsUpdate(id, otherProvisionsItemsAdd)
  {
    this.familyTreeService.editOtherProvisions(id, otherProvisionsItemsAdd).subscribe((data:OtherProvisionsResponse)=>{
      if(data.error && !data.success){      
        this.toastr.error('Error on updating other provisions.');      
      }
      else{
        this.toastr.success('Other provisions updated successfully.');
      }

      this.getProvisionInfo();
    },err=>{   
      console.log(err);
      this.toastr.error('Error on updating other provisions.');
    });
  }


  deleteOtherProvision(otherProvision) {

    if(otherProvision.clientProvision){
      let id = otherProvision.clientProvision.id;
      let sMessage = '';
      let eMessage = 'Error on deleting other provisions.';

      this.familyTreeService.deleteOtherProvision(id, otherProvision).subscribe((data:DeleteOtherProvisionResponse)=>{
          
        //this.getProvisionInfo();
      },err=>{   
        console.log(err);
        this.toastr.error(eMessage);
      });

      //this.otherProvisiondelete(id, otherProvision, sMessage, eMessage);
    }
    if(otherProvision.partnerProvision){
      let id = otherProvision.partnerProvision.id;
      let sMessage = 'Other provisions deleted successfully.';
      let eMessage = 'Error on deleting other provisions.';

      this.familyTreeService.deleteOtherProvision(id, otherProvision).subscribe((data:DeleteOtherProvisionResponse)=>{
        if(data.error && !data.success){      
          this.toastr.error(eMessage);      
        }
        else{
          this.getProvisionInfo();
          this.toastr.success(sMessage);
        }
  
        //this.getProvisionInfo();
      },err=>{   
        console.log(err);
        this.toastr.error(eMessage);
      });

      //this.otherProvisiondelete(id, otherProvision, sMessage, eMessage);
    }
  }


  addOtherProvisions(provisionInfo) {
    
    var description_add = ((document.getElementById("description_add") as HTMLInputElement).value);
    var provisionValue_add = ((document.getElementById("provisionValue_add") as HTMLInputElement).value);

    description_add = description_add.trim(); // .replace(/\s/g, "");
    
    if(description_add == ""){
        this.toastr.error('Description is required.');
    }
    else {
      //var clientCode = provisionInfo.spouseDeathOrTPDs.clientDeathOrTPDItems.clientCode;

      this.addOtherProvisionD = new OtherProvisionsAddItems;

      this.addOtherProvisionD.familyTreeId = provisionInfo.familyTreeId;
      this.addOtherProvisionD.clientCode = this.client_code;
      this.addOtherProvisionD.description = description_add;
      this.addOtherProvisionD.value = provisionValue_add;

      if(!this.partnerHas) {
        // if(description_add == ""){
        //   this.toastr.error('Description is required.');
        // }
        // else {
          this.otherProvisionsAdd(this.addOtherProvisionD);
        // }
      }

      if(this.partnerHas) {
        var provisionValuePartner_add = ((document.getElementById("provisionValuePartner_add") as HTMLInputElement).value);
        if(provisionValue_add != "" && provisionValuePartner_add != ""){
          this.familyTreeService.addOtherProvision(this.addOtherProvisionD).subscribe((data:OtherProvisionsResponse)=>{
            if(data.error && !data.success){      
              this.toastr.error('Error on adding other provisions.');      
            }
            else{

              this.addOtherProvisionD.clientCode = this.partnerClientCode;
              this.addOtherProvisionD.value = provisionValuePartner_add;
              this.familyTreeService.addOtherProvision(this.addOtherProvisionD).subscribe((data:OtherProvisionsResponse)=>{
                if(data.error && !data.success){      
                  this.toastr.error('Error on adding other provisions.');      
                }
                else{
                  this.toastr.success('Other provisions added successfully.');
                }
          
                this.getProvisionInfo();
              },err=>{   
                console.log(err);
                this.toastr.error('Error on adding other provisions.');
              });
            }
      
          },err=>{   
            console.log(err);
            this.toastr.error('Error on adding other provisions.');
          });
        }
        else {
          this.toastr.error('Error on adding other provisions.');
        }
      }

      ((document.getElementById("description_add") as HTMLInputElement).value='');
      ((document.getElementById("provisionValue_add") as HTMLInputElement).value='');
      ((document.getElementById("provisionValuePartner_add") as HTMLInputElement).value='');
    }
  }


  otherProvisionsAdd(addOtherProvisionD) {
    this.familyTreeService.addOtherProvision(addOtherProvisionD).subscribe((data:OtherProvisionsResponse)=>{
      if(data.error && !data.success){      
        this.toastr.error('Error on adding other provisions.');      
      }
      else{
        this.toastr.success('Other provisions added successfully.');
      }

      this.getProvisionInfo();
    },err=>{   
      console.log(err);
      this.toastr.error('Error on adding other provisions.');
    });
  }

  otherProvisiondelete(id, otherProvision, sMessage, eMessage)
  {
    this.familyTreeService.deleteOtherProvision(id, otherProvision).subscribe((data:DeleteOtherProvisionResponse)=>{
      if(data.error && !data.success){      
        this.toastr.error(eMessage);      
      }
      else{
        this.toastr.success(sMessage);
      }

      //this.getProvisionInfo();
    },err=>{   
      console.log(err);
      this.toastr.error(eMessage);
    });
  }

}
