import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AppConstants } from '../utility/app-constants';
import { TreeResponse, SaveFamilyTreeNodeResponse, BudgetResponse, InsuranceResponse, XplanChoicesResponse, RetirementResponse, ProvisionResponse, WillResponse, FinancesResponse, WillAddResponse, RetirementAddResponse, SpouseDeathOrTPDResponse, DependantSupportAddResponse, BenefitPeriodResponse, OtherProvisionsResponse, DocumentsResponse, ImmediateNeedsResponse, ProgressiveNeedsResponse, WillExecutorsResponse, ResidualBequestsResponse, ResiduaryBequestUserResponse, ResiduaryBequestNoteResponse, CalamityBeneficiaryResponse, CalamityBeneficiaryAddResponse, DeleteOtherProvisionResponse, OtherEntityItemResponse, OtherEntityNoteResponse, AddFinancialEPAResponse, LifetimeArrangementsNoteResponse, ProposedInsuranceResponse, ProposedInsuranceAddResponse, TreeExistResponseModel } from '../shared/models/response.model';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpErrorResponse, HttpEvent, HttpResponse, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { GetTreeModel } from '../shared/models/get-tree.model';
import * as moment from 'moment';
import { data } from 'jquery';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FamilyTreeService {

  public readonly family_tree: Subject<any> = new Subject<any>();
  baseUrl:String;
  token :String;

  private messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();

  constructor(private http:HttpClient, private authenticationService: AuthService) {
    this.baseUrl = environment.getBaseURL;
    this.token = this.authenticationService.userToken;
  }

  changeMessage(message: string) {
    this.messageSource.next(message)
  }

  getFamilyTree(): Observable<any> {
    return this.family_tree.asObservable();
  }

  setFamilyTree(treeData){
    this.family_tree.next({treeData :treeData});
    // console.log("set family tree");
    // console.log(treeData);
  }

  public getFamilyTreeExist(clientId, clientCode){
    // console.log("--- clientId ---");
    // console.log(clientId);
    const params = new HttpParams().set('ClientCode',clientId);

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });

    return this.http.get<TreeExistResponseModel>(this.baseUrl+'/FamilyTree/GetByClientCode',{params, headers: headers});
  }

  public getFamilyTreeFromServer(family_tree_id){
    const params = new HttpParams().set('familyTreeId',family_tree_id);

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });

    return this.http.get<TreeResponse>(this.baseUrl+'/FamilyDependent/getByFamilyTreeId',{params, headers: headers});
  }

  public addMember(dependantModel) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token 
    });

    return this.http.post<SaveFamilyTreeNodeResponse>(this.baseUrl +'/FamilyDependent', dependantModel, {headers: headers} );
  }

  public updateMember(dependantModel, memberId) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token 
    });

    const params = new HttpParams().set('id', memberId);

    return this.http.put<SaveFamilyTreeNodeResponse>(this.baseUrl +'/FamilyDependent', dependantModel, {params, headers: headers} );
  }

  public async saveTreeLink(node, family_tree_id, nodeList, treeListFromServerOld){
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });

    let relations = [];
    if(node && node.relationOf){

      // console.log(" -- relationOf == " , node.relationOf);

      let relation_list = node.relationOf.split("-");

      if(relation_list ){
        // console.log(" -- relation_list == " , relation_list);

        relation_list.forEach(rel => {
          let rela = nodeList.find(x => x.id1 == rel);
          // console.log(" -- relation find == " , rela);
          if(rela){
            relations.push({
              dependantId	: node.id,
              relationDependantId : rela.id
            });

          }
        });

        // console.log(" -- relations == " , relations);

      }
      // else{
      //   let rela = nodeList.find(x => x.id1 == node.relationOf);
      //   if(rela){
      //     relations.push(rela.id);
      //   }
      // }


    }

    let relation = null;
    if(node.relation){
      if(node.relation == "1"){
        relation = "Spouse";
      }
      else if(node.relation == "2"){
        relation = "Child";
      }
      else if(node.relation == "3"){
        relation = "Devorcee";
      }
      else if(node.relation == "4"){
        relation = "Parent";
      }
      else{
        relation = null;
      }
    }

    if(node.gender==2){
      node.gender = 0;
    }

     node.dateOfBirth.setDate(node.dateOfBirth.getDate()+1);
    //node.dateOfBirth= new Date(node.dateOfBirth+'Z');

    let dependantModel = new GetTreeModel();
    dependantModel.familyTreeId = family_tree_id;
    dependantModel.firstName = node.firstName;
    dependantModel.lastName = node.lastName;
    dependantModel.middleName = node.middleName;
    dependantModel.gender = node.gender;
    dependantModel.dateOfBirth = node.dateOfBirth;
    dependantModel.isDependent = node.dependant;
    dependantModel.isDeceased = node.deceased;
    dependantModel.isRoot = node.isMainNode;
    dependantModel.relation = relation;
    dependantModel.relations = relations;

    if(!dependantModel.isDependent || dependantModel.isDependent ==null){
      dependantModel.isDependent = false;
    }
    else{
      dependantModel.isDependent = true;
    }

    if(!dependantModel.isDeceased || dependantModel.isDeceased ==null){
      dependantModel.isDeceased = false;
    }
    else{
      dependantModel.isDeceased = true;
    }


    if(node && node.id){
      let existing_node = treeListFromServerOld.find(x => x.id == node.id);
      let update = false;
      if( dependantModel.firstName != existing_node.firstName
        || dependantModel.lastName != existing_node.lastName
        || dependantModel.middleName != existing_node.middleName
        || dependantModel.gender != existing_node.gender
        || moment(new Date(existing_node.dateOfBirth)).format("YYYY/MM/DD")  != moment(new Date(dependantModel.dateOfBirth)).format("YYYY/MM/DD")
        || dependantModel.isDependent != existing_node.isDependent
        || dependantModel.isDeceased != existing_node.isDeceased
        || dependantModel.isRoot != existing_node.isRoot
        || dependantModel.relation != existing_node.relation
      ){
        update = true;
        // console.log(moment(new Date(existing_node.dateOfBirth)).format("YYYY/MM/DD")  != moment(new Date(dependantModel.dateOfBirth)).format("YYYY/MM/DD") );
        // console.log(dependantModel.firstName != existing_node.firstName);
        // console.log(dependantModel.lastName != existing_node.lastName);
        // console.log(dependantModel.middleName != existing_node.middleName);
        // console.log(dependantModel.gender != existing_node.gender);
        // console.log(dependantModel.isDependent != existing_node.isDependent);
        // console.log(dependantModel.isDeceased != existing_node.isDeceased);
        // console.log(dependantModel.isRoot != existing_node.isRoot);
        // console.log(dependantModel.relation != existing_node.relation);
        // console.log(" -- update 1 dependantModel ", dependantModel);
        // console.log(" -- update 01 existing_node ", existing_node);
      }



      if(dependantModel && dependantModel.relations){
        let relationss = dependantModel.relations;

        let relationss_old = [];
        if(existing_node && existing_node.relations){
          relationss_old = existing_node.relations;
        }

        relationss.forEach(rel => {
          if(relationss_old && relationss_old.length >0){
            let rela1 = relationss_old.find(x => x.relationDependantId == rel.relationDependantId);
            if(!rela1){
              update = true;
              // console.log(" -- update 2 dependantModel ", dependantModel);
              // console.log(" -- update 02 existing_node ", existing_node);
            }
          }
        });

        if(relationss.length != relationss_old.length ){
          update = true;
        }
      }

      //console.log(" -- update ", update);

      if(update){
        // console.log(" dependantModel ", dependantModel);
        // console.log(" existing_node ", existing_node);
        // console.log(" ---- ", update);
        // console.log(" -- update ", update);
        const params = new HttpParams().set('id', node.id);
        return await this.http.put<SaveFamilyTreeNodeResponse>(this.baseUrl +'/FamilyDependent', dependantModel, {params, headers: headers}).toPromise();
      }

    }
    else{
      //console.log(" -- create ", true);
      return await this.http.post<SaveFamilyTreeNodeResponse>(this.baseUrl +'/FamilyDependent', dependantModel, {headers: headers}).toPromise();
    }


  }

  public handleError(error) {
    return throwError(error);
  }

  deleteNode(id){
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });

    // return this.http.delete<Response>(this.baseUrl +'/FamilyDependent?dependentId='+id, {headers: headers}).pipe(
    return this.http.delete<any>(this.baseUrl +'/FamilyDependent?dependentId='+id, {headers: headers}).pipe(
      catchError(this.handleError)
      );
  }

  public getChoiceList(){
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });

    return this.http.get<XplanChoicesResponse>(this.baseUrl+'/XFacChoices', {headers: headers}).pipe(
      catchError(this.handleError)
    );
  }

  public getBudget(clientCode :string)
  {
    //console.log("client service")
    //console.log(id);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    const params = new HttpParams().set('clientCode',clientCode);
    return this.http.get<BudgetResponse>(this.baseUrl+'/FamilyBudget/getByClientCode',{params, headers: headers});
  }

  public getInsurance(clientCode :string)
  {
    //console.log("client service")
    //console.log(id);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    const params = new HttpParams().set('clientCode',clientCode);
    return this.http.get<InsuranceResponse>(this.baseUrl+'/FamilyInsurance/getByClientCode',{params, headers: headers});
  }

  public getProposedInsurance(family_tree_id :string)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    const params = new HttpParams().set('familyTreeId',family_tree_id);
    return this.http.get<ProposedInsuranceResponse>(this.baseUrl+'/FamilyInsurance/getProposedInsuranceCoversByFamilyTreeId',{params, headers: headers});
  }

  public addProposedInsurance(clientCode :string, familyTreeId :string, proposedInsuranceAdd)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    const params = new HttpParams().set('clientCode',clientCode).set('familyTreeId',familyTreeId);
    return this.http.post<ProposedInsuranceAddResponse>(this.baseUrl+'/FamilyInsurance/AddProposedInsuranceCover',proposedInsuranceAdd, {headers: headers});
  }

  public editProposedInsurance(id :string, proposedInsuranceAdd)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    const params = new HttpParams().set('id',id);
    return this.http.put<ProposedInsuranceAddResponse>(this.baseUrl+'/FamilyInsurance/UpdateProposedInsuranceCover?id='+id,proposedInsuranceAdd,{headers: headers});
  }

  public deleteProposedInsurance(id :string)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    return this.http.delete<DeleteOtherProvisionResponse>(this.baseUrl+'/FamilyInsurance/DeleteProposedInsuranceCover?id='+id,{headers: headers});
  }

  public getRetirement(familyTreeId :string)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    const params = new HttpParams().set('familyTreeId',familyTreeId);
    return this.http.get<RetirementResponse>(this.baseUrl+'/FamilyRetirement/getByFamilyTreeId',{params, headers: headers});
  }

  public getProvision(familyTreeId :string)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    const params = new HttpParams().set('familyTreeId',familyTreeId);
    return this.http.get<ProvisionResponse>(this.baseUrl+'/FamilyProvision/getByFamilyTreeId',{params, headers: headers});
  }

  public getWill(familyTreeId :string)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    const params = new HttpParams().set('familyTreeId',familyTreeId);
    return this.http.get<WillResponse>(this.baseUrl+'/FamilyWill/getByFamilyTreeId',{params, headers: headers});
  }

  public addWill(familyTreeId :string)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    const params = new HttpParams().set('familyTreeId',familyTreeId);
    return this.http.post<WillAddResponse>(this.baseUrl+'/FamilyWill/AddExecutor',{params, headers: headers});
  }

  public getFinances(clientCode :string, xeppoCode :string)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    const params = new HttpParams().set('clientCode',clientCode).set('xeppoCode',xeppoCode);
    //return  this.http.get<FinancesResponse>("");
   return  this.http.get<FinancesResponse>(this.baseUrl+'/FamilyFinance/getByClientCode',{params, headers: headers});
  }

  public getFamilyTreePdf(userName: string, fileData: any) {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token });
    const formData = new FormData();
    formData.append("file", fileData, fileData.fileName);
    const params = new HttpParams().set('userName', userName)
    return this.http.post(this.baseUrl + '/FamilyDocument/getFamilyTreePDF', formData, { params, headers: headers });
  }

  public getDocuments(clientCode :string, xeppoCode :string, companyCode, fileData:any)
  {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token });

    var formData = new FormData();
    formData.append("file", fileData, fileData.name);

    const params = new HttpParams().set('xeppoCode',xeppoCode).set('clientCode',clientCode).set('companyCode',companyCode);
    // return this.http.get(this.baseUrl+'/FamilyTreeDocGenaration/GenarateDocTypeOne',{params, headers: headers, responseType: "blob"});
    return this.http.post(this.baseUrl+'/FamilyDocument/getEstateBriefingNotes?clientCode='+clientCode+'&xeppoCode='+xeppoCode, formData, {headers: headers, responseType: "blob"});
    // return this.http.get<HttpResponse<Blob>>(this.baseUrl+'/FamilyTreeDocGenaration/GenarateDocTypeOne',{params, headers: headers});
  }

  public getInstructionSheetMultipleTDTDocuments(clientCode :string, companyCode)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    const params = new HttpParams().set('clientCode',clientCode).set('companyCode',companyCode);
    return this.http.get(this.baseUrl+'/FamilyDocument/getInstructionSheetMultipleTDTByClientCode',{params, headers: headers, responseType: "blob"});
  }

  public getInstructionSheetCoreDocuments(clientCode :string, companyCode)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    const params = new HttpParams().set('clientCode',clientCode).set('companyCode',companyCode);
    return this.http.get(this.baseUrl+'/FamilyDocument/getInstructionSheetCoreByClientCode',{params, headers: headers, responseType: "blob"});
  }

  public getFileNoteDocuments(clientCode :string, xeppoCode :string, fileData:any, tPDModelData: any)
  {
    let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token});

    // const header: HttpHeaders = new HttpHeaders()
    //           .append('Content-Type', 'application/json; charset=UTF-8')
    //           .append('Authorization', 'Bearer ' + this.token);
    // const httpOptions = {
    //     headers: header,
    //     body: fileData,
    //     responseType: "blob"
    // };

  //  console.log(fileData, '========fileData=====');
    var formData = new FormData();
    formData.append("file", fileData, fileData.name);
    formData.append("ProgressiveNeedsTPDModel.IncomeSupportFromRetirement.Client", tPDModelData.IncomeSupportFromRetirement.Client);
    formData.append("ProgressiveNeedsTPDModel.IncomeSupportFromRetirement.Partner", tPDModelData.IncomeSupportFromRetirement.Partner);
    formData.append("ProgressiveNeedsTPDModel.IncomeSupportTillRetirement.Client", tPDModelData.IncomeSupportTillRetirement.Client);
    formData.append("ProgressiveNeedsTPDModel.IncomeSupportTillRetirement.Partner", tPDModelData.IncomeSupportTillRetirement.Partner);
    formData.append("ProgressiveNeedsTPDModel.IncomeProtectionBenefitsPaid.Client", tPDModelData.IncomeProtectionBenefitsPaid.Client);
    formData.append("ProgressiveNeedsTPDModel.IncomeProtectionBenefitsPaid.Partner", tPDModelData.IncomeProtectionBenefitsPaid.Partner);
    formData.append("ProgressiveNeedsTPDModel.Childcare.Client", tPDModelData.Childcare.Client);
    formData.append("ProgressiveNeedsTPDModel.Childcare.Partner", tPDModelData.Childcare.Partner);
    formData.append("ProgressiveNeedsTPDModel.InjuredPersonsSuper.Client", tPDModelData.InjuredPersonsSuper.Client);
    formData.append("ProgressiveNeedsTPDModel.InjuredPersonsSuper.Partner", tPDModelData.InjuredPersonsSuper.Partner);
    formData.append("ProgressiveNeedsTPDModel.NonInjuredSpouseSuper.Client", tPDModelData.NonInjuredSpouseSuper.Client);
    formData.append("ProgressiveNeedsTPDModel.NonInjuredSpouseSuper.Partner", tPDModelData.NonInjuredSpouseSuper.Partner);
    formData.append("ProgressiveNeedsTPDModel.TotalNetProgressiveNeed.Client", tPDModelData.TotalNetProgressiveNeed.Client);
    formData.append("ProgressiveNeedsTPDModel.TotalNetProgressiveNeed.Partner", tPDModelData.TotalNetProgressiveNeed.Partner);
    formData.append("ProgressiveNeedsTPDModel.TotalRequired.Client", tPDModelData.TotalRequired.Client);
    formData.append("ProgressiveNeedsTPDModel.TotalRequired.Partner", tPDModelData.TotalRequired.Partner);

    formData.append("TPDImmediateSuper.CashedOutOfSuperImmediately.Client", tPDModelData.CashedOutOfSuperImmediately.Client);
    formData.append("TPDImmediateSuper.CashedOutOfSuperImmediately.Partner", tPDModelData.CashedOutOfSuperImmediately.Partner);
    formData.append("TPDImmediateSuper.GrossUpForTax.Client", tPDModelData.GrossUpForTaxClient.Client);
    formData.append("TPDImmediateSuper.GrossUpForTax.Partner", tPDModelData.GrossUpForTaxClient.Partner);

    formData.append("ProgressiveNeedsLifeModel.FundsRequiredForRetirement.Client", tPDModelData.FundsRequiredForRetirementClient);
    formData.append("ProgressiveNeedsLifeModel.FundsRequiredForRetirement.Partner", tPDModelData.FundsRequiredForRetirementPartner);
    formData.append("ProgressiveNeedsLifeModel.SurvivingSpouseSuper.Client", tPDModelData.ProgressiveNeedsLifeModelSurvivingSpouseSuperClient);
    formData.append("ProgressiveNeedsLifeModel.SurvivingSpouseSuper.Partner", tPDModelData.ProgressiveNeedsLifeModelSurvivingSpouseSuperPartner);
    formData.append("ProgressiveNeedsLifeModel.ShortfallSavingsOfDesiredIncomeToRetirement.Client", tPDModelData.ProgressiveNeedsLifeModelShortfallSavingsOfDesiredIncomeToRetirementClient);
    formData.append("ProgressiveNeedsLifeModel.ShortfallSavingsOfDesiredIncomeToRetirement.Partner", tPDModelData.ProgressiveNeedsLifeModelShortfallSavingsOfDesiredIncomeToRetirementPartner);
    formData.append("ProgressiveNeedsLifeModel.Childcare.Client", tPDModelData.ProgressiveNeedsLifeModelChildcareClient);
    formData.append("ProgressiveNeedsLifeModel.Childcare.Partner", tPDModelData.ProgressiveNeedsLifeModelChildcarePartner);
    formData.append("ProgressiveNeedsLifeModel.TotalProgressiveNeed.Client", tPDModelData.ProgressiveNeedsLifeModelTotalProgressiveNeedClient);
    formData.append("ProgressiveNeedsLifeModel.TotalProgressiveNeed.Partner", tPDModelData.ProgressiveNeedsLifeModelTotalProgressiveNeedPartner);
    formData.append("ProgressiveNeedsLifeModel.TotalInsuranceRecommended.Client", tPDModelData.ProgressiveNeedsLifeModelTotalInsuranceRecommendedClient);
    formData.append("ProgressiveNeedsLifeModel.TotalInsuranceRecommended.Partner", tPDModelData.ProgressiveNeedsLifeModelTotalInsuranceRecommendedPartner); 
    formData.append("TPDImmediateSuper.TotalNetImmediateNeed.Client", tPDModelData.ProgressiveNeedsLifeModeltotalNetImmediateNeedClient);
    formData.append("TPDImmediateSuper.TotalNetImmediateNeed.Partner", tPDModelData.ProgressiveNeedsLifeModeltotalNetImmediateNeedPartner);
    formData.append("TPDImmediateNeeds.IncomeReplacementForSpouseTimeOffWork.Client", tPDModelData.IncomeReplacementForSpouseTimeOffWork.Client);
    formData.append("TPDImmediateNeeds.IncomeReplacementForSpouseTimeOffWork.Partner", tPDModelData.IncomeReplacementForSpouseTimeOffWork.Partner);
    formData.append("TPDImmediateNeeds.ImmediateNeedsTotal.Client", tPDModelData.ImmediateNeedsTotal.Client);
    formData.append("TPDImmediateNeeds.ImmediateNeedsTotal.Partner", tPDModelData.ImmediateNeedsTotal.Partner);
    // let query_str = "fileType=2&fileName="+fileUploadModel.fileName+"&businessId="+fileUploadModel.businessId+"&userId="+this.cookieService.get('CurrentUserId')+"&companyCode="+this.cookieService.get('companyCode');

    // var result = await this.http.post<FileUploadResponse>(
    //   this.baseUrl+"/FileUpload/NoticeUpload?"+query_str,
    //         formData
    //     )
    //     .toPromise();


    // const params = new HttpParams().set('file', fileData);
    // const params = new HttpParams().set('familyTreeId',familyTreeId).set('clientCode',clientCode).set('description',description).set('value',value);
    // const params = new HttpParams().set('xeppoCode',xeppoCode).set('clientCode',clientCode);
    return this.http.post(this.baseUrl+'/FamilyDocument/getfileNoteByClientCode?clientCode='+clientCode+'&xeppoCode='+xeppoCode, formData, {headers: headers, responseType: "blob"});
    // return this.http.post(this.baseUrl+'/FamilyDocument/getfileNoteByClientCode?clientCode='+clientCode+'&xeppoCode='+xeppoCode, httpOptions);
  }

  public editRetirement(retirementAdd, familyTreeId :string)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    //const params = new HttpParams().set('clientCode',retirementAdd.clientCode).set('retirementAge',retirementAdd.retirementAge).set('combinedRetirementIncome',retirementAdd.combinedRetirementIncome).set('precentageIfPartnerDeceased',retirementAdd.precentageIfPartnerDeceased);
    return this.http.put<RetirementAddResponse>(this.baseUrl+'/FamilyRetirement?id='+familyTreeId,retirementAdd,{headers: headers});
  }

  public editDependantSupport(id :string, dependantSupportAdd)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    //const params = new HttpParams().set('clientCode',dependantSupportAdd.clientCode).set('retirementAge',dependantSupportAdd.retirementAge).set('combinedRetirementIncome',dependantSupportAdd.combinedRetirementIncome).set('precentageIfPartnerDeceased',dependantSupportAdd.precentageIfPartnerDeceased);
    return this.http.put<DependantSupportAddResponse>(this.baseUrl+'/FamilyProvision/UpdateDependentSupport?id='+id,dependantSupportAdd,{headers: headers});
  }

  public editSpouseDeathOrTPD(id :string, spouseDeathOrTPDAdd)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    //const params = new HttpParams().set('clientCode',spouseDeathOrTPDAdd.clientCode).set('retirementAge',spouseDeathOrTPDAdd.retirementAge).set('combinedRetirementIncome',spouseDeathOrTPDAdd.combinedRetirementIncome).set('precentageIfPartnerDeceased',spouseDeathOrTPDAdd.precentageIfPartnerDeceased);
    return this.http.put<SpouseDeathOrTPDResponse>(this.baseUrl+'/FamilyProvision/UpdateSpouseDeathOrTPD?id='+id,spouseDeathOrTPDAdd,{headers: headers});
  }

  public editBenefitPeriod(id :string, benefitPeriodAdd)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    //const params = new HttpParams().set('clientCode',benefitPeriodAdd.clientCode).set('retirementAge',benefitPeriodAdd.retirementAge).set('combinedRetirementIncome',benefitPeriodAdd.combinedRetirementIncome).set('precentageIfPartnerDeceased',benefitPeriodAdd.precentageIfPartnerDeceased);
    return this.http.put<BenefitPeriodResponse>(this.baseUrl+'/FamilyProvision/UpdateBenefitPeriod?id='+id,benefitPeriodAdd,{headers: headers});
  }

  public editOtherProvisions(id :string, otherProvisionsAdd)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    //const params = new HttpParams().set('clientCode',otherProvisionsAdd.clientCode).set('retirementAge',otherProvisionsAdd.retirementAge).set('combinedRetirementIncome',otherProvisionsAdd.combinedRetirementIncome).set('precentageIfPartnerDeceased',otherProvisionsAdd.precentageIfPartnerDeceased);
    return this.http.put<OtherProvisionsResponse>(this.baseUrl+'/FamilyProvision/UpdateOtherProvision?id='+id,otherProvisionsAdd,{headers: headers});
  }

  public editImmediateNeed(id :string, immediateNeedsAdd)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    //const params = new HttpParams().set('clientCode',immediateNeedsAdd.clientCode).set('retirementAge',immediateNeedsAdd.retirementAge).set('combinedRetirementIncome',immediateNeedsAdd.combinedRetirementIncome).set('precentageIfPartnerDeceased',immediateNeedsAdd.precentageIfPartnerDeceased);
    return this.http.put<ImmediateNeedsResponse>(this.baseUrl+'/FamilyProvision/UpdateImmediateNeed?id='+id,immediateNeedsAdd,{headers: headers});
  }

  public editProgressiveNeeds(id :string, progressiveNeedsAdd)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    //const params = new HttpParams().set('clientCode',progressiveNeedsAdd.clientCode).set('retirementAge',progressiveNeedsAdd.retirementAge).set('combinedRetirementIncome',progressiveNeedsAdd.combinedRetirementIncome).set('precentageIfPartnerDeceased',progressiveNeedsAdd.precentageIfPartnerDeceased);
    return this.http.put<ProgressiveNeedsResponse>(this.baseUrl+'/FamilyProvision/UpdateProgressiveNeed?id='+id,progressiveNeedsAdd,{headers: headers});
  }

  public addOtherProvision(addOtherProvisionData)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    //const params = new HttpParams().set('familyTreeId',familyTreeId).set('clientCode',clientCode).set('description',description).set('value',value);
    return this.http.post<OtherProvisionsResponse>(this.baseUrl+'/FamilyProvision/AddOtherProvision', addOtherProvisionData, {headers: headers});
  }

  public deleteOtherProvision(id :string, calamityBeneficiaryUser)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    return this.http.delete<DeleteOtherProvisionResponse>(this.baseUrl+'/FamilyProvision/DeleteOtherProvision?id='+id,{headers: headers});
  }

  public editWillExecutors(id :string, executorsAdd)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    // return this.http.put<WillExecutorsResponse>(this.baseUrl+'/FamilyWill/UpdateExecutorUser?id='+id,executorsAdd,{headers: headers});
    return this.http.put<WillExecutorsResponse>(this.baseUrl+'/FamilyWill/UpdateExecutorUser?clientCode='+id,executorsAdd,{headers: headers});
  }

  public addWillExecutors(addWillExecutorsData)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    return this.http.post<WillExecutorsResponse>(this.baseUrl+'/FamilyWill/AddExecutorUser', addWillExecutorsData, {headers: headers});
  }

  public editWillExecutorNote(id :string, executorsNoteAdd)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    return this.http.put<WillResponse>(this.baseUrl+'/FamilyWill/UpdateExecutor?id='+id,executorsNoteAdd,{headers: headers});
  }

  public editResidualBequestsTDT(id :string, residualBequestsTDT)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    return this.http.put<ResidualBequestsResponse>(this.baseUrl+'/FamilyWill/UpdateResidualBequestDetail?id='+id,residualBequestsTDT,{headers: headers});
  }

  public addResiduaryBequestUser(residuaryBequestUser)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    return this.http.post<ResiduaryBequestUserResponse>(this.baseUrl+'/FamilyWill/AddResidualBequestUser',residuaryBequestUser,{headers: headers});
  }

  public editResiduaryBequestUser(id :string, residuaryBequestUser)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    return this.http.put<ResiduaryBequestUserResponse>(this.baseUrl+'/FamilyWill/UpdateResidualBequestUser?id='+id,residuaryBequestUser,{headers: headers});
  }

  public editResiduaryBequestNote(id :string, residuaryBequestNote)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    return this.http.put<ResiduaryBequestNoteResponse>(this.baseUrl+'/FamilyWill/UpdateResidualBequest?id='+id,residuaryBequestNote,{headers: headers});
  }

  public editCalamityBeneficiary(id :string, calamityBeneficiary)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    return this.http.put<CalamityBeneficiaryResponse>(this.baseUrl+'/FamilyWill/UpdateCalamityBeneficiary?id='+id,calamityBeneficiary,{headers: headers});
  }

  public addCalamityBeneficiaryUser(calamityBeneficiaryUser)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    return this.http.post<CalamityBeneficiaryAddResponse>(this.baseUrl+'/FamilyWill/AddCalamityBeneficiaryUser',calamityBeneficiaryUser,{headers: headers});
  }

  public editCalamityBeneficiaryUser(id :string, calamityBeneficiaryUser)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    return this.http.put<CalamityBeneficiaryAddResponse>(this.baseUrl+'/FamilyWill/UpdateCalamityBeneficiaryUser?id='+id,calamityBeneficiaryUser,{headers: headers});
  }

  public editOtherEntityItem(id :string, otherEntityItem)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    return this.http.put<OtherEntityItemResponse>(this.baseUrl+'/FamilyWill/UpdateOtherEntityItem?id='+id,otherEntityItem,{headers: headers});
  }

  public editOtherEntityNote(id :string, otherEntity)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    return this.http.put<OtherEntityNoteResponse>(this.baseUrl+'/FamilyWill/UpdateOtherEntity?id='+id,otherEntity,{headers: headers});
  }

  public addFinancialEPA(financialEPA)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    return this.http.post<AddFinancialEPAResponse>(this.baseUrl+'/FamilyWill/AddFinancialEPA',financialEPA,{headers: headers});
  }

  public editFinancialEPA(id :string, financialEPA)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    return this.http.put<AddFinancialEPAResponse>(this.baseUrl+'/FamilyWill/UpdateFinancialEPA?id='+id,financialEPA,{headers: headers});
  }

  public addMedicalEPA(financialEPA)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    return this.http.post<AddFinancialEPAResponse>(this.baseUrl+'/FamilyWill/AddMedicalEPA',financialEPA,{headers: headers});
  }

  public editMedicalEPA(id :string, financialEPA)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    return this.http.put<AddFinancialEPAResponse>(this.baseUrl+'/FamilyWill/UpdateMedicalEPA?id='+id,financialEPA,{headers: headers});
  }

  public editLifetimeArrangementsNote(id :string, lifetimeArrangements)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    return this.http.put<LifetimeArrangementsNoteResponse>(this.baseUrl+'/FamilyWill/UpdateLifetimeArrangement?id='+id,lifetimeArrangements,{headers: headers});
  }


  public deleteExecutorUser(id :string)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    return this.http.delete<DeleteOtherProvisionResponse>(this.baseUrl+'/FamilyWill/DeleteExecutorUser?id='+id,{headers: headers});
  }

  public deleteResidualBequestUser(id :string)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    return this.http.delete<DeleteOtherProvisionResponse>(this.baseUrl+'/FamilyWill/DeleteResidualBequestUser?id='+id,{headers: headers});
  }

  public deleteCalamityBeneficiaryUser(id :string)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    return this.http.delete<DeleteOtherProvisionResponse>(this.baseUrl+'/FamilyWill/DeleteCalamityBeneficiaryUser?id='+id,{headers: headers});
  }

  public deleteFinancialEPA(id :string)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    return this.http.delete<DeleteOtherProvisionResponse>(this.baseUrl+'/FamilyWill/DeleteFinancialEPA?id='+id,{headers: headers});
  }

  public deleteMedicalEPA(id :string)
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    return this.http.delete<DeleteOtherProvisionResponse>(this.baseUrl+'/FamilyWill/DeleteMedicalEPA?id='+id,{headers: headers});
  }

  public addFamilyNote(familyTreeId :string, note )
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    //const params = new HttpParams().set('clientCode',retirementAdd.clientCode).set('retirementAge',retirementAdd.retirementAge).set('combinedRetirementIncome',retirementAdd.combinedRetirementIncome).set('precentageIfPartnerDeceased',retirementAdd.precentageIfPartnerDeceased);
    return this.http.put<RetirementAddResponse>(this.baseUrl+'/FamilyDependent/UpdateNote?familyTreeId='+familyTreeId+'&note='+note,note,{headers: headers});
  }

  public addFinancesNote(familyTreeId :string, note )
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    //const params = new HttpParams().set('clientCode',retirementAdd.clientCode).set('retirementAge',retirementAdd.retirementAge).set('combinedRetirementIncome',retirementAdd.combinedRetirementIncome).set('precentageIfPartnerDeceased',retirementAdd.precentageIfPartnerDeceased);
    return this.http.put<FinancesResponse>(this.baseUrl+'/FamilyFinance/UpdateNote?familyTreeId='+familyTreeId+'&note='+note,note,{headers: headers});
  }

  public superContributionRateChange(familyTreeId :string, clientCode, rate )
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    //const params = new HttpParams().set('clientCode',retirementAdd.clientCode).set('retirementAge',retirementAdd.retirementAge).set('combinedRetirementIncome',retirementAdd.combinedRetirementIncome).set('precentageIfPartnerDeceased',retirementAdd.precentageIfPartnerDeceased);
    return this.http.patch<FinancesResponse>(this.baseUrl+'/FamilyFinance/SuperContributionRateChange?familyTreeId='+familyTreeId+'&clientCode='+clientCode+'&rate='+rate,rate,{headers: headers});
  }

  public addBudgetNote(familyTreeId :string, note )
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    //const params = new HttpParams().set('clientCode',retirementAdd.clientCode).set('retirementAge',retirementAdd.retirementAge).set('combinedRetirementIncome',retirementAdd.combinedRetirementIncome).set('precentageIfPartnerDeceased',retirementAdd.precentageIfPartnerDeceased);
    return this.http.put<BudgetResponse>(this.baseUrl+'/FamilyBudget/UpdateNote?familyTreeId='+familyTreeId+'&note='+note,note,{headers: headers});
  }

  public addRetirementNote(familyTreeId :string, note )
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    //const params = new HttpParams().set('clientCode',retirementAdd.clientCode).set('retirementAge',retirementAdd.retirementAge).set('combinedRetirementIncome',retirementAdd.combinedRetirementIncome).set('precentageIfPartnerDeceased',retirementAdd.precentageIfPartnerDeceased);
    return this.http.put<RetirementResponse>(this.baseUrl+'/FamilyRetirement/UpdateNote?familyTreeId='+familyTreeId+'&note='+note,note,{headers: headers});
  }

  public addProvisionNote(familyTreeId :string, note )
  {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token });
    //const params = new HttpParams().set('clientCode',retirementAdd.clientCode).set('retirementAge',retirementAdd.retirementAge).set('combinedRetirementIncome',retirementAdd.combinedRetirementIncome).set('precentageIfPartnerDeceased',retirementAdd.precentageIfPartnerDeceased);
    return this.http.put<ProvisionResponse>(this.baseUrl+'/FamilyProvision/UpdateNote?familyTreeId='+familyTreeId+'&note='+note,note,{headers: headers});
  }

}
