import { Component, OnInit, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ProvisionResponse, BenefitPeriodResponse } from 'src/app/shared/models/response.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FamilyTreeService } from 'src/app/family-tree/family-tree.service';
import { ToastrService } from 'ngx-toastr';
import { ProvisionModel, BenefitPeriodsItems } from 'src/app/shared/models/provision.model';
import { SharedDataService } from 'src/app/shared/service/shared-data.service';

@Component({
  selector: 'app-benefit-period',
  templateUrl: './benefit-period.component.html',
  styleUrls: ['../../rightside.component.css']
})
export class BenefitPeriodComponent implements OnInit {

  @Input() family_tree_id:string;
  @Input() clientFirstname:string;
  @Input() partnerFirstname:string;
  @Input() partnerHas:boolean = false;
  addBenefitPeriodForm: FormGroup;
  isLoaded:boolean=false;
  @Input() provisionInfo:ProvisionModel;
  benefitPeriodsItemsAdd:BenefitPeriodsItems;
  benefitPeriodInYear:any;
  benefitPeriodInYearPartner:any;

  constructor(
    private familyTreeService :FamilyTreeService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private sharedDataService: SharedDataService,
  ) { }

  ngOnInit(): void {
    this.getProvisionInfo();
    this.sharedDataService.benefitPeriodInYear.subscribe(benefitPeriodInYear => this.benefitPeriodInYear = benefitPeriodInYear);
    this.sharedDataService.benefitPeriodInYearPartner.subscribe(benefitPeriodInYearPartner => this.benefitPeriodInYearPartner = benefitPeriodInYearPartner);
  }

  getProvisionInfo(){
    //this.ngxService.start();
    // this.familyTreeService.getProvision(this.family_tree_id).subscribe((provisionData:ProvisionResponse)=>{
    //   if(provisionData.success){
        // this.provisionInfo = provisionData.response;
        
        let value = this.provisionInfo.benefitPeriods.clientBenefitPeriods !=null ? this.provisionInfo.benefitPeriods.clientBenefitPeriods.value :0,
        valuePartner = this.provisionInfo.benefitPeriods.partnerBenefitPeriods !=null ? this.provisionInfo.benefitPeriods.partnerBenefitPeriods.value :0
        ;

        this.benefitPeriodInYear = value
        this.benefitPeriodInYearPartner = valuePartner
        this.sharedDataService.changBenefitPeriodInYear(this.benefitPeriodInYear);
        this.sharedDataService.changBenefitPeriodInYearPartner(this.benefitPeriodInYearPartner);

        this.addBenefitPeriodForm = this.formBuilder.group({  
          
          // Benefit Period
          value: [value],
          valuePartner: [valuePartner],

        });
        
        this.isLoaded = true;
      // } 
      // this.ngxService.stop();` 
    // },
    // (err: HttpErrorResponse)=>{
    //   //console.log("Error"+ err.message);
    // });
  }

  benefitPeriodSave(benefitPeriods, event: any){
    let value = event.target.value;
    let inputId = event.target.id;
    
    this.benefitPeriodsItemsAdd = new BenefitPeriodsItems;
    this.benefitPeriodsItemsAdd.clientCode = benefitPeriods.clientCode;
    if(inputId == 'value' || inputId == 'valuePartner') {
      this.benefitPeriodsItemsAdd.value = value;
    }

    this.familyTreeService.editBenefitPeriod(benefitPeriods.id, this.benefitPeriodsItemsAdd).subscribe((data:BenefitPeriodResponse)=>{
      if(data.error && !data.success){      
        this.toastr.error('Error on updating benefit period.');      
      }
      else{
        this.toastr.success('Benefit period updated successfully.');
      }

      // this.getProvisionInfo();
    },err=>{   
      console.log(err);
      this.toastr.error('Error on updating benefit period.');
    });
  }

}
