import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { ContainerComponent } from './container/container.component';

const routes: Routes = [
  { 
    path: 'dashboard', 
    component: ContainerComponent , 
    //canActivate: [AuthGuard], 
    //data: { roles: [Role.Admin] } 
  },
  {path:'**',redirectTo:'dashboard'}  

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
