import { NgIf } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'creationGraph'
})
export class CreationGraphPipe implements PipeTransform {

  mainNodeAge = 0
  retirementAge = 0
  //Graphs
  retirement_goal = 0

  transform(value: unknown, ...args: unknown[]): unknown {
    return null;
  }

   processThis(value) { 

    var self = this;
    //console.log(value)
     // if (value == self.retirement_goal) {
     
        if(value > 1000000) {

          return `${((Math.round(value) / 1000000).toFixed(1))}M`
       
        }
        else {

          return `${((Math.round(value) / 1000).toFixed(1))}k`
       
        }
  
       //}
       //else if (value == 0) {
     
       // return `${0}k`  // return `${0}k`
  
    //  }
  
  
    //return `${(value / 1000)}b`
  } 


  xAxisEnds(value){
    var self = this;
   // console.log("value",value)
   // console.log("ret",self.retirementAge)
    if (value == self.mainNodeAge) { return self.mainNodeAge}
    else if  (value == self.retirementAge) {
       return value
      }
    else{

      return (value % 5) == 0 ? value : null

    }

  }
  

}
