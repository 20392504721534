<ng-container *ngIf="isLoaded">
  <div class="boxcolor mt-2">
    <table class="table newtable tableinsurance">
      <thead>
        <tr>
          <th class="tc2 font-weight-normal ml-2 tab_fs">Total Insurance Recommended</th>
          <th class="tc1 font-weight-normal">{{clientFirstname}}</th>
          <th class="tc1 font-weight-normal">{{partnerFirstname}}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Upfront Capital Needs</td>
          <td>${{upfrontCapitalNeedClient | number: '1.0-0'}}</td>
          <td>${{upfrontCapitalNeedPartner | number: '1.0-0'}}</td>
        </tr>
        <tr>
          <td>Years Off Work</td>
          <td>{{yearsOffWorkClient}}</td>
          <td>{{yearsOffWorkPartner}}</td>
        </tr>
        <tr>
          <td>Cover for Years Off Work</td>
          <td>${{2*(income + incomePartner)*0.7 | number: '1.0-0' }}</td> <!--salaryOwner * (70/100)*2 --><!-- coverForYearsOffWorkClient -->
          <td>${{2*(income + incomePartner)*0.7 | number: '1.0-0'}}</td> <!--salaryPartner * (70/100)*2 --><!-- coverForYearsOffWorkPartner -->
        </tr>
        <tr class="tableinsurancetr">
          <td class="ins_life_total" >Total Cover Required</td>
          <td class="ins_life_total">${{upfrontCapitalNeedClient+2*(income + incomePartner)*0.7 | number: '1.0-0'}}</td> <!-- totalCoverRequiredClient -->
          <td class="ins_life_total">${{upfrontCapitalNeedPartner+2*(income + incomePartner)*0.7 | number: '1.0-0'}}</td> <!-- totalCoverRequiredPartner -->
        </tr>
        <tr>
          <td>Less 2 Years Passive Income</td>
          <td>${{less2YearsPassiveIncomeClient | number: '1.0-0'}}</td>
          <td>${{less2YearsPassiveIncomePartner | number: '1.0-0'}}</td>
        </tr>
        <tr class="tableinsurancetr">
          <td class="ins_life_total" >Additional Insurance Needs</td>
          <td class="ins_life_total">${{totalCoverRequiredClient | number: '1.0-0'}}</td> <!-- upfrontCapitalNeedClient+2*(income + incomePartner)*0.7 + less2YearsPassiveIncomeClient -->
          <td class="ins_life_total">${{totalCoverRequiredPartner | number: '1.0-0'}}</td> <!-- upfrontCapitalNeedPartner+2*(income + incomePartner)*0.7 + less2YearsPassiveIncomePartner -->
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>
  <div class="boxcolor mt-2 " >
       
    <table class="table newtable tableinsurance" style="padding-bottom: 20px;">
      <thead>
        <tr>
          <th class="tc2 font-weight-normal ml-2 tab_fs">Existing</th>
        </tr>
      </thead>
      <!-- <tbody>
        <tr>
          <td  class=" tableColWith">Policy Owner</td>
          <td>: {{clientFirstname}}</td>
          <td  class=" tableColWith">Monthly Benefit</td>
          <td>: $100</td>
        </tr>
        <tr>
          <td>Insurer</td>
          <td>: Insurer</td>
          <td>Waiting Period</td>
          <td>: 0</td>
        </tr>
        <tr>
          <td>Benefit Period</td>
          <td>: 0</td>
          <td>Monthly Premium</td>
          <td>: $0</td>
        </tr>
        <tr>
          <td>Structure</td>
          <td>: Not defined</td>
          <td>Policy Definition</td>
          <td>: Not defined</td>
        </tr>
        <tr class="tableinsurancetr">
          <td class="tabSummery">Funding Mechanism</td>
          <td>: Not defined</td>
          <td> </td>
          <td> </td>
        </tr>

      </tbody> -->
    </table>

    <ng-container *ngIf="isLoaded3">
      <table class="table tablecollect insutable">
        <thead>
          <tr>
            <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Policy Owner</th>
            <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs pdAlignTableHead">Insurer</th>
            <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Sum Insured</th>
            <!-- <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Waiting Period</th>
            <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Benefit Period</th> -->
            <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">Monthly Premium</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="insuranceInfo.groupedCoversClient.trauma.length > 0">
            <ng-container *ngFor="let income of insuranceInfo.groupedCoversClient.trauma; let i = index">
              <tr>
                <td class="tablefist tableb">{{clientFirstname}}</td>
                <td class="tableleftbedit " style="background-color: #f9f9f9;">
                  <ng-container *ngIf="income.planName">
                    <input readonly type="text" class="form-control neweditinput" placeholder="{{income.planName}}" data-toggle="tooltip" data-html="true" title="{{income.planName}}" style="width: auto;">
                  </ng-container>
                  <ng-container *ngIf="income.planName =='' || income.planName == null">
                    <input readonly type="text" class="form-control neweditinput" placeholder="-">
                  </ng-container>
                </td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="income.sumInsured">
                    <input readonly type="text" class="form-control neweditinput" placeholder="${{income.sumInsured | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="income.sumInsured == null || income.sumInsured == ''">
                    <input readonly type="text" class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
                <!-- <td class="tableleftbedit ">
                  <ng-container *ngIf="income.waitingPeriod">
                    <input readonly type="text" class="form-control neweditinput" placeholder="{{income.waitingPeriod}}">
                  </ng-container>
                  <ng-container *ngIf="income.waitingPeriod == null || income.waitingPeriod == ''">
                    <input readonly type="text" class="form-control neweditinput" placeholder="-">
                  </ng-container>
                </td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="income.benefitPeriod">
                    <input readonly type="text" class="form-control neweditinput" placeholder="{{income.benefitPeriod}}">
                  </ng-container>
                  <ng-container *ngIf="income.benefitPeriod == null || income.benefitPeriod == ''">
                    <input readonly type="text" class="form-control neweditinput" placeholder="-">
                  </ng-container>
                </td> -->
                <td class="tableleftbedit ">
                  <ng-container *ngIf="income.totalPremium">
                    <!-- <input readonly type="text" class="form-control neweditinput" placeholder="${{income.monthlyPremium | number: '1.0-0'}}"> -->
                    <input readonly type="text" class="form-control neweditinput" placeholder="${{income.totalPremium/12 | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="income.totalPremium == null || income.totalPremium == ''">
                    <input readonly type="text" class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
              </tr>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="insuranceInfo.groupedCoversClient.trauma.length == 0">
            <tr>
              <td class="tablefist tableb">{{clientFirstname}}</td>
              <td class="tableleftbedit "><input readonly type="text" class="form-control neweditinput" placeholder="-"></td>
              <td class="tableleftbedit "><input readonly type="text" class="form-control neweditinput" placeholder="$0"></td>
              <td class="tableleftbedit "><input readonly type="text" class="form-control neweditinput" placeholder="0"></td>
              <td class="tableleftbedit "><input readonly type="text" class="form-control neweditinput" placeholder="0"></td>
              <td class="tableleftbedit "><input readonly type="text" class="form-control neweditinput" placeholder="0"></td>
            </tr>
          </ng-container>


          <ng-container *ngIf="insuranceInfo.groupedCoversPartner.trauma.length > 0">
            <ng-container *ngFor="let income of insuranceInfo.groupedCoversPartner.trauma; let i = index">
              <tr>
                <td class="tablefist tableb">{{partnerFirstname}}</td>
                <td class="tableleftbedit " style="background-color: #f9f9f9;">
                  <ng-container *ngIf="income.planName">
                    <input readonly type="text" class="form-control neweditinput" placeholder="{{income.planName}}" data-toggle="tooltip" data-html="true" title="{{income.planName}}" style="width: auto;">
                  </ng-container>
                  <ng-container *ngIf="income.planName =='' || income.planName == null">
                    <input readonly type="text" class="form-control neweditinput" placeholder="-">
                  </ng-container>
                </td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="income.sumInsured">
                    <input readonly type="text" class="form-control neweditinput" placeholder="${{income.sumInsured | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="income.sumInsured == null || income.sumInsured == ''">
                    <input readonly type="text" class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
                <!-- <td class="tableleftbedit ">
                  <ng-container *ngIf="income.waitingPeriod">
                    <input readonly type="text" class="form-control neweditinput" placeholder="{{income.waitingPeriod}}">
                  </ng-container>
                  <ng-container *ngIf="income.waitingPeriod == null || income.waitingPeriod == ''">
                    <input readonly type="text" class="form-control neweditinput" placeholder="-">
                  </ng-container>
                </td>
                <td class="tableleftbedit ">
                  <ng-container *ngIf="income.benefitPeriod">
                    <input readonly type="text" class="form-control neweditinput" placeholder="{{income.benefitPeriod}}">
                  </ng-container>
                  <ng-container *ngIf="income.benefitPeriod == null || income.benefitPeriod == ''">
                    <input readonly type="text" class="form-control neweditinput" placeholder="-">
                  </ng-container>
                </td> -->
                <td class="tableleftbedit ">
                  <ng-container *ngIf="income.totalPremium">
                    <!-- <input readonly type="text" class="form-control neweditinput" placeholder="${{income.monthlyPremium | number: '1.0-0'}}"> -->
                    <input readonly type="text" class="form-control neweditinput" placeholder="${{income.totalPremium/12 | number: '1.0-0'}}">
                  </ng-container>
                  <ng-container *ngIf="income.totalPremium == null || income.totalPremium == ''">
                    <input readonly type="text" class="form-control neweditinput" placeholder="$0">
                  </ng-container>
                </td>
              </tr>
            </ng-container>
          </ng-container>
          
        </tbody>
      </table>
    </ng-container>
     
  </div>

  
  <!-- Proposed section - TRAUMA -->
  <table class="table newtable tableinsurance" style="padding-bottom: 20px;">
    <thead>
      <tr>
        <th class="tc2 font-weight-normal ml-2 tab_fs">Proposed</th>
      </tr>
    </thead>
  </table>
  <ng-container *ngIf="isLoaded8">
    <ng-container *ngIf="proposedInsuranceInfo.clientInsurances.length > 0">
      <ng-container *ngFor="let proposedTrauma of proposedInsuranceInfo.clientInsurances; let i = index">
        <ng-container *ngIf="proposedTrauma.insuranceCoverType == 3">
          <div class="boxcolor mt-2 proposed" style="background-color: #00000008;">
            <label for="inputEmail4" class="tc2">  </label>
            <i class="fa fa-close close_section" (click)="deleteProposedInsurance(proposedTrauma)" ></i>
            <div class="form-row pad10 subgraphs">
              <div class="form-group col-md-4 edit-border"> 
                <ng-container *ngIf="proposedTrauma.lifeInsured == clientID">
                  <input type="text" id="lifeInsured" value="{{clientFirstname}}" readonly class="form-control" placeholder="Life Insured">
                </ng-container>
                <ng-container *ngIf="proposedTrauma.lifeInsured == partnerID">
                  <input type="text" id="lifeInsured" value="{{partnerFirstname}}" readonly class="form-control" placeholder="Life Insured">
                </ng-container>
              </div>    
              <div class="form-group col-md-4 edit-border"> 
                <select id="policyOwner" [(ngModel)]="proposedTrauma.policyOwner" (change)="proposedInsuranceSave(proposedTrauma, $event)" class="form-control" placeholder="Structre">
                  <option value="" disabled selected>Select policy owner</option>
                  <option value="{{clientID}}">{{clientFirstname}}</option>
                  <option value="{{partnerID}}">{{partnerFirstname}}</option>
                </select>
              </div>
              <div class="form-group col-md-4 edit-border">
                <input type="text" id="underwriter" [(ngModel)]="proposedTrauma.underwriter" (change)="proposedInsuranceSave(proposedTrauma, $event)" required class="form-control" placeholder="Insurer">
              </div>
            </div>
            <div class="form-row subgraphs">
              <div class="form-group col-md-6 edit-border"> 
                <input type="text" required id="sumInsured" [(ngModel)]="proposedTrauma.sumInsured" (change)="proposedInsuranceSave(proposedTrauma, $event)" mask="separator.0" thousandSeparator="," class="form-control do" placeholder="Sum insured">
              </div>    
              <div class="form-group col-md-6 edit-border"> 
                <input type="text" required id="monthlyPremium" [(ngModel)]="proposedTrauma.monthlyPremium" (change)="proposedInsuranceSave(proposedTrauma, $event)" mask="separator.0" thousandSeparator="," class="form-control do" placeholder="Monthly premium">
              </div>            
            </div>
            <div class="form-row subgraphs">
              <div class="form-group col-md-6 edit-border">
                <select id="structure" [(ngModel)]="proposedTrauma.structure" (change)="proposedInsuranceSave(proposedTrauma, $event)" class="form-control" placeholder="Structre">
                  <option value="" disabled selected>Select structure</option>
                  <option value="0">Stepped</option>
                  <option value="1">Level</option>
                </select>
              </div>
              <div class="form-group col-md-6 edit-border">
                <select id="fundingMechanism" [(ngModel)]="proposedTrauma.fundingMechanism" (change)="proposedInsuranceSave(proposedTrauma, $event)" class="form-control">
                  <option value="" disabled selected>Select mechanism</option>
                  <option value="0">Super</option>
                  <option value="1">Super via cash flow</option>
                  <option value="2">Cash flow</option>
                </select>
              </div>      
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="proposedInsuranceInfo.partnerInsurances.length > 0">
      <ng-container *ngFor="let proposedTrauma of proposedInsuranceInfo.partnerInsurances; let i = index">
        <ng-container *ngIf="proposedTrauma.insuranceCoverType == 3">
          <div class="boxcolor mt-2 proposed" style="background-color: #00000008;">
            <label for="inputEmail4" class="tc2">  </label>
            <i class="fa fa-close close_section" (click)="deleteProposedInsurance(proposedTrauma)" ></i>
            <div class="form-row pad10 subgraphs">
              <div class="form-group col-md-4 edit-border"> 
                <ng-container *ngIf="proposedTrauma.lifeInsured == clientID">
                  <input type="text" id="lifeInsured" value="{{clientFirstname}}" readonly class="form-control" placeholder="Life Insured">
                </ng-container>
                <ng-container *ngIf="proposedTrauma.lifeInsured == partnerID">
                  <input type="text" id="lifeInsured" value="{{partnerFirstname}}" readonly class="form-control" placeholder="Life Insured">
                </ng-container>
              </div>    
              <div class="form-group col-md-4 edit-border"> 
                <select id="policyOwner" [(ngModel)]="proposedTrauma.policyOwner" (change)="proposedInsuranceSave(proposedTrauma, $event)" class="form-control" placeholder="Structre">
                  <option value="" disabled selected>Select policy owner</option>
                  <option value="{{clientID}}">{{clientFirstname}}</option>
                  <option value="{{partnerID}}">{{partnerFirstname}}</option>
                </select>
              </div>
              <div class="form-group col-md-4 edit-border">
                <input type="text" id="underwriter" [(ngModel)]="proposedTrauma.underwriter" (change)="proposedInsuranceSave(proposedTrauma, $event)" required class="form-control" placeholder="Insurer">
              </div>
            </div>
            <div class="form-row subgraphs">
              <div class="form-group col-md-6 edit-border"> 
                <input type="text" required id="sumInsured" [(ngModel)]="proposedTrauma.sumInsured" (change)="proposedInsuranceSave(proposedTrauma, $event)" mask="separator.0" thousandSeparator="," class="form-control do" placeholder="Sum insured">
              </div>    
              <div class="form-group col-md-6 edit-border"> 
                <input type="text" required id="monthlyPremium" [(ngModel)]="proposedTrauma.monthlyPremium" (change)="proposedInsuranceSave(proposedTrauma, $event)" mask="separator.0" thousandSeparator="," class="form-control do" placeholder="Monthly premium">
              </div>            
            </div>
            <div class="form-row subgraphs">
              <div class="form-group col-md-6 edit-border">
                <select id="structure" [(ngModel)]="proposedTrauma.structure" (change)="proposedInsuranceSave(proposedTrauma, $event)" class="form-control" placeholder="Structre">
                  <option value="" disabled selected>Select structure</option>
                  <option value="0">Stepped</option>
                  <option value="1">Level</option>
                </select>
              </div>
              <div class="form-group col-md-6 edit-border">
                <select id="fundingMechanism" [(ngModel)]="proposedTrauma.fundingMechanism" (change)="proposedInsuranceSave(proposedTrauma, $event)" class="form-control">
                  <option value="" disabled selected>Select mechanism</option>
                  <option value="0">Super</option>
                  <option value="1">Super via cash flow</option>
                  <option value="2">Cash flow</option>
                </select>
              </div>      
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>


    <!-- Default-Client === IF Client list null  Start--> 
    <ng-container *ngIf="!hasTraumaClient">
      <div class="boxcolor mt-2 proposed" style="background-color: #00000008;">
        <label for="inputEmail4" class="tc2">  </label>
        <i class="close_section" (click)="addProposedInsurance(proposedInsuranceInfo, 'trauma', 'defaultClient')" >Save Now</i>
        <div class="form-row pad10 subgraphs">
          <div class="form-group col-md-4 edit-border"> 
            <!-- <select id="lifeInsuredAddTrauma" class="form-control" placeholder="Structre">
              <option value="" disabled selected>Select life insured</option>
              <option value="{{clientID}}">{{clientFirstname}}</option>
              <option value="{{partnerID}}">{{partnerFirstname}}</option>
            </select> -->
            <input type="text" id="lifeInsuredClient" value="{{clientFirstname}}" readonly class="form-control" placeholder="Life Insured">
            <input type="text" id="lifeInsuredAddTraumaClient" value="{{clientID}}" readonly class="form-control" placeholder="Life Insured" style="display: none;">
          </div>    
          <div class="form-group col-md-4 edit-border"> 
            <select id="policyOwnerAddTraumaClient" class="form-control" placeholder="Structre">
              <option value="" disabled selected>Select policy owner</option>
              <option value="{{clientID}}">{{clientFirstname}}</option>
              <option value="{{partnerID}}">{{partnerFirstname}}</option>
            </select>
          </div>
          <div class="form-group col-md-4 edit-border">
            <input type="text" id="insurerAddTraumaClient" required class="form-control" placeholder="Insurer">
          </div>
        </div>
        <div class="form-row subgraphs">
          <div class="form-group col-md-6 edit-border"> 
            <input type="text" required id="sumInsuredAddTraumaClient" [(ngModel)]="totalCoverRequiredClientInput" value="{{totalCoverRequiredClientInput}}" mask="separator.0" thousandSeparator="," class="form-control do" placeholder="Sum insured">
          </div>    
          <div class="form-group col-md-6 edit-border"> 
            <input type="text" required id="monthlyPremiumAddTraumaClient" mask="separator.0" thousandSeparator="," class="form-control do" placeholder="Monthly premium">
          </div>            
        </div>
        <div class="form-row subgraphs">
          <div class="form-group col-md-6 edit-border">
            <select id="structureAddTraumaClient" class="form-control" placeholder="Structre">
              <option value="" disabled selected>Select structure</option>
              <option value="0">Stepped</option>
              <option value="1">Level</option>
            </select>
          </div>
          <div class="form-group col-md-6 edit-border">
            <select id="fundingMechanismAddTraumaClient" class="form-control">
              <option value="" disabled selected>Select mechanism</option>
              <option value="0">Super</option>
              <option value="1">Super via cash flow</option>
              <option value="2">Cash flow</option>
            </select>
          </div>      
        </div>
      </div>
    </ng-container>
    <!-- Default-Client === IF Client list null  End-->

    <!-- Default-Partner === IF Partner list null  Start--> 
    <ng-container *ngIf="!hasTraumaPartner">
      <div class="boxcolor mt-2 proposed" style="background-color: #00000008;">
        <label for="inputEmail4" class="tc2">  </label>
        <i class="close_section" (click)="addProposedInsurance(proposedInsuranceInfo, 'trauma', 'defaultPartner')" >Save Now</i>
        <div class="form-row pad10 subgraphs">
          <div class="form-group col-md-4 edit-border"> 
            <!-- <select id="lifeInsuredAddTrauma" class="form-control" placeholder="Structre">
              <option value="" disabled selected>Select life insured</option>
              <option value="{{clientID}}">{{clientFirstname}}</option>
              <option value="{{partnerID}}">{{partnerFirstname}}</option>
            </select> -->
            <input type="text" id="lifeInsuredPartner" value="{{partnerFirstname}}" readonly class="form-control" placeholder="Life Insured">
            <input type="text" id="lifeInsuredAddTraumaPartner" value="{{partnerID}}" readonly class="form-control" placeholder="Life Insured" style="display: none;">
          </div>    
          <div class="form-group col-md-4 edit-border"> 
            <select id="policyOwnerAddTraumaPartner" class="form-control" placeholder="Structre">
              <option value="" disabled selected>Select policy owner</option>
              <option value="{{clientID}}">{{clientFirstname}}</option>
              <option value="{{partnerID}}">{{partnerFirstname}}</option>
            </select>
          </div>
          <div class="form-group col-md-4 edit-border">
            <input type="text" id="insurerAddTraumaPartner" required class="form-control" placeholder="Insurer">
          </div>
        </div>
        <div class="form-row subgraphs">
          <div class="form-group col-md-6 edit-border"> 
            <input type="text" required id="sumInsuredAddTraumaPartner" [(ngModel)]="totalCoverRequiredPartnerInput" value="{{totalCoverRequiredPartnerInput}}" mask="separator.0" thousandSeparator="," class="form-control do" placeholder="Sum insured">
          </div>    
          <div class="form-group col-md-6 edit-border"> 
            <input type="text" required id="monthlyPremiumAddTraumaPartner" mask="separator.0" thousandSeparator="," class="form-control do" placeholder="Monthly premium">
          </div>            
        </div>
        <div class="form-row subgraphs">
          <div class="form-group col-md-6 edit-border">
            <select id="structureAddTraumaPartner" class="form-control" placeholder="Structre">
              <option value="" disabled selected>Select structure</option>
              <option value="0">Stepped</option>
              <option value="1">Level</option>
            </select>
          </div>
          <div class="form-group col-md-6 edit-border">
            <select id="fundingMechanismAddTraumaPartner" class="form-control">
              <option value="" disabled selected>Select mechanism</option>
              <option value="0">Super</option>
              <option value="1">Super via cash flow</option>
              <option value="2">Cash flow</option>
            </select>
          </div>      
        </div>
      </div>
    </ng-container>
    <!-- Default-Partner === IF Partner list null  End-->

    <!-- Add new TRAUMA start -->
    <div class="boxcolor mt-2 proposed" style="background-color: #00000008;">
      <label for="inputEmail4" class="tc2"> Add Proposed </label>
      <i class="close_section" (click)="addProposedInsurance(proposedInsuranceInfo, 'trauma', 'addNew')" >Save Now</i>
      <div class="form-row pad10 subgraphs">
        <div class="form-group col-md-4 edit-border"> 
          <select id="lifeInsuredAddTrauma" class="form-control" placeholder="Structre">
            <option value="" disabled selected>Select life insured</option>
            <option value="{{clientID}}">{{clientFirstname}}</option>
            <option value="{{partnerID}}">{{partnerFirstname}}</option>
          </select>
        </div>    
        <div class="form-group col-md-4 edit-border"> 
          <select id="policyOwnerAddTrauma" class="form-control" placeholder="Structre">
            <option value="" disabled selected>Select policy owner</option>
            <option value="{{clientID}}">{{clientFirstname}}</option>
            <option value="{{partnerID}}">{{partnerFirstname}}</option>
          </select>
        </div>
        <div class="form-group col-md-4 edit-border">
          <input type="text" id="insurerAddTrauma" required class="form-control" placeholder="Insurer">
        </div>
      </div>
      <div class="form-row subgraphs">
        <div class="form-group col-md-6 edit-border"> 
          <input type="text" required id="sumInsuredAddTrauma" mask="separator.0" thousandSeparator="," class="form-control do" placeholder="Sum insured">
        </div>    
        <div class="form-group col-md-6 edit-border"> 
          <input type="text" required id="monthlyPremiumAddTrauma" mask="separator.0" thousandSeparator="," class="form-control do" placeholder="Monthly premium">
        </div>            
      </div>
      <div class="form-row subgraphs">
        <div class="form-group col-md-6 edit-border">
          <select id="structureAddTrauma" class="form-control" placeholder="Structre">
            <option value="" disabled selected>Select structure</option>
            <option value="0">Stepped</option>
            <option value="1">Level</option>
          </select>
        </div>
        <div class="form-group col-md-6 edit-border">
          <select id="fundingMechanismAddTrauma" class="form-control">
            <option value="" disabled selected>Select mechanism</option>
            <option value="0">Super</option>
            <option value="1">Super via cash flow</option>
            <option value="2">Cash flow</option>
          </select>
        </div>      
      </div>
    </div>
    <!-- Add new TRAUMA end -->
  </ng-container>
  <!-- Proposed section ends - TPAUMA -->  