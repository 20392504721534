<div class="row">
  <div class="col-md-12">
    <div class="text-center">
      <img src="{{logoURL}}" class="left_imagelogo" alt="">
      <!-- <img src="../../assets/entity1_b2.png" class="left_imagelogo" alt=""> -->
      <br class="res_br">
      <span style="font-size: 25px;">Family Tree</span>
    </div>

    <ul class="nav nav-tabs pt-3 justify-content-center" id="myTab" role="tablist">
      <li class="nav-item nav-tabsul">
        <a class="nav-link active tc1 navlinkf" id="distibution-tab" data-toggle="tab" href="#distibution" role="tab"
          aria-controls="distibution" aria-selected="true">Distribution</a>
      </li>
      <li class="nav-item nav-tabsul">
        <a class="nav-link tc1 navlinkf" id="creation-tab" data-toggle="tab" href="#creation" role="tab" aria-controls="creation"
          aria-selected="false">Creation</a>
      </li>
      <li class="nav-item nav-tabsul">
        <a class="nav-link tc1 navlinkf" id="protection-tab" data-toggle="tab" href="#protection" role="tab"
          aria-controls="protection" aria-selected="false">Protection</a>
      </li>
      <li class="nav-item nav-tabsul">
        <a class="nav-link tc1 navlinkf" id="calculator-tab" data-toggle="tab" href="#calculator" role="tab"
          aria-controls="calculator" aria-selected="false">Calculator</a>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">

      <div class="tab-pane fade show active pt-3 pb-4" id="distibution" role="tabpanel" aria-labelledby="distibution-tab">

        <!-- <app-tree></app-tree> -->
        <app-distibution></app-distibution>
        <!-- <a class="fullscreen_btn" (click)="openFullscreenCreation()" href="javascript:void(0);" title="Full Screen"> Fullscreen </a>  -->
      </div>

          <!-- <div class="tab-pane fade show pt-3 pb-4" id="creation" role="tabpanel" aria-labelledby="creation-tab"> -->
          <div class="tab-pane fade show bg-white distribution-wr" [ngClass]="{'full-screen': isFullScreen}"  id="creation" role="tabpanel" aria-labelledby="creation-tab">

            <div class="graph-header row align-items-center mb-3">
                <div class="col-md-12">
                  <div class="wr d-flex align-items-center justify-content-between">
                    <div class="title">
                      <p class="m-0 text-white text-uppercase" [ngSwitch]="currentCreationNo">
                        <span *ngSwitchCase="1">Balanced</span>
                        <span *ngSwitchCase="2">Defensive</span>
                        <span *ngSwitchCase="3">Growth</span>
                        <span *ngSwitchCase="4">Advice</span>
                      </p>
                    </div>
                    <div class="d-flex align-items-center justify-content-end">
                      <span class="custom-text mr-2">{{currentCreationNo}} / {{totalCreationCount}} </span>
                      <img (click)="prevCreationGraph()" class="arrow mr-2" src="assets/arrow-left.svg" />
                      <img (click)="nextCreationGraph()" class="arrow mr-2" src="assets/arrow-right.svg" />

                      <button class="screen-toggle-btn btn shadow-sm p-2 d-flex ml-3" (click)="openFullscreenCreation()">
                        <img *ngIf="!isFullScreen" src="assets/open_in.svg" alt="Open Fullscreen">
                        <img *ngIf="isFullScreen" src="assets/close_fullscreen.svg" alt="Close Fullscreen">
                      </button>
                    </div>
                  </div>
                </div>
            </div>

            <!-- <div class="boxcolor mt-2 scroll_content scroll_height" style="background-color: #ffffff;"> -->
            <div class="boxcolor mt-2 scroll_content" style="background-color: #ffffff;">
              <!-- <img src="../../../assets/creation.png" class="img-fluid" style="margin-top: 100px;" alt=""> -->

              <!-- *ngIf="loadChart" -->
            <!-- <a class="fullscreen_btn" (click)="openFullscreenCreation()" href="javascript:void(0);" title="Full Screen"> Fullscreen </a> -->
            <!-- <canvas  *ngIf="loadChart" style="margin-top: 50px;" baseChart width="900" height="330" #canvas1 id="canvas1" -->
            <canvas style="margin-top: 50px; color: #f9f9f9; background-color: #ffffff;width: 100%;" viewBox="0 0 2142 1248" baseChart width="900" height="330" #canvas1 id="canvas1"
              [datasets]="lineChartData"
              [labels]="lineChartLabels"
              [colors]="lineChartColors"
              [legend]="lineChartLegend"
              [chartType]="lineChartType"
              [options]="lineChartOptions"
              [plugins]="lineChartPlugins">
            </canvas>

            <!-- <canvas style="color: #f9f9f9; background-color: #ffffff;width: 100%;"  #canvas1 width="999" id="canvas1" height="444"></canvas> -->
              <!-- [options]="lineChartOptions" -->


            </div>
            <div class="boxcolor mt-2 subgraphs">
              <form>
                <div class="form-row">
                  <div class="form-group col-md-5 edit-border">
                    <label for="inputEmail4" class="tc2">Retirement Age</label>
                    <input type="number" min="0" step="1" [ngModelOptions]="{standalone: true}" [(ngModel)]="creationAge" (input)="redrawCreation()" class="form-control" placeholder="Please enter">
                  </div>
                  <div class="form-group col-md-5 edit-border">
                    <label for="inputEmail4" class="tc2">Retirement Income</label>
                    <input type="text" min="0" step="0.1"mask="separator.0" thousandSeparator=","  [ngModelOptions]="{standalone: true}" [(ngModel)]="creationIncome"  (input)="redrawCreation()" class="form-control" placeholder="Please enter">
                  </div>

                  <div class="form-group col-md-2">
                    <label for="inputEmail4" class="tc2">&nbsp;</label>
                    <button class="resetbtn"  (click)="resetCreation();" >Reset</button>
                    <!-- <input type="number" min="0" step="0.1" [ngModelOptions]="{standalone: true}" max="100" [(ngModel)]="creationIncome"  (input)="redrawCreation()" class="form-control" placeholder="Retirement Income"> -->
                  </div>

                </div>
              </form>
            </div>
          </div>
      

      <!-- <div class="tab-pane fade show pt-3 pb-4"  id="protection" role="tabpanel" aria-labelledby="protection-tab"> -->
      <div class="tab-pane fade show bg-white distribution-wr" [ngClass]="{'full-screen': isFullScreen}" id="protection" role="tabpanel" aria-labelledby="protection-tab">

        <div class="graph-header row align-items-center mb-3">
            <div class="col-md-12">
              <div class="wr d-flex align-items-center justify-content-between">
                <div class="title">
                  <p class="m-0 text-white text-uppercase" [ngSwitch]="currentProtectionNo">
                    <span *ngSwitchCase="1">BURNDOWN</span>
                    <span *ngSwitchCase="2">DEATH</span>
                    <span *ngSwitchCase="3">DEATH WITHOUT PROTECTION</span>
                    <span *ngSwitchCase="4">DEATH WITH PROTECTION</span>
                  </p>
                </div>
                <div class="d-flex align-items-center justify-content-end">
                  <span class="custom-text mr-2">{{currentProtectionNo}} / {{totalProtectionCount}}</span>
                  <img (click)="prevProtectionGraph()" class="arrow mr-2" src="assets/arrow-left.svg" />
                  <img (click)="nextProtectionGraph()" class="arrow mr-2" src="assets/arrow-right.svg" />

                  <button class="screen-toggle-btn btn shadow-sm p-2 d-flex ml-3" (click)="openFullscreenProtection()">
                    <img *ngIf="!isFullScreen" src="assets/open_in.svg" alt="Open Fullscreen">
                    <img *ngIf="isFullScreen" src="assets/close_fullscreen.svg" alt="Close Fullscreen">
                  </button>
                </div>
              </div>
            </div>
        </div>

        <div class="boxcolor mt-2 scroll_content" style="background-color: #ffffff;">
            <!-- <img src="../../../assets/protection.png" class="img-fluid" style="margin-top: 100px;" alt=""> -->
            <!-- <a class="fullscreen_btn" (click)="openFullscreenProtection()" href="javascript:void(0);" title="Full Screen"> Fullscreen </a> -->
            <!-- <canvas *ngIf="loadChart1" style="margin-top: 50px;" baseChart width="900" -->
            <canvas style="margin-top: 50px; color: #f9f9f9; background-color: #ffffff;width: 100%;" baseChart width="900" height="330" #canvas2 id="canvas2"
              [datasets]="lineChartDataProtection"
              [labels]="lineChartLabelsProtection"
              [colors]="protectedChartColors"
              [legend]="false"
              [chartType]="lineChartType"
              [options]="protectionChartOptions"
              [plugins]="lineChartPlugins">
            </canvas>
            <div id="legend"></div>
            <!-- <div id="chartContainer" style="margin-top: 100px; height: 330px; width: 900px; margin-left:auto;margin-right:auto;"></div> -->

        </div>
        <div class="boxcolor mt-2 subgraphs">
          <form>
            <div class="form-row">
              <div class="form-group col-md-5 edit-border">
                <label for="inputEmail4" class="tc2">Retirement Age</label>
                <input type="number" class="form-control" min="0" step="1" [ngModelOptions]="{standalone: true}" [(ngModel)]="creationAge"  (input)="redrawCreation()" placeholder="Please enter">
              </div>
              <div class="form-group col-md-5 edit-border">
                <label for="inputEmail4" class="tc2">Retirement Income</label>
                <input type="text" class="form-control" min="0" step="0.1" mask="separator.0" thousandSeparator="," [ngModelOptions]="{standalone: true}"  [(ngModel)]="creationIncome"  (input)="redrawCreation()" placeholder="Please enter">
              </div>
              <div class="form-group col-md-2">
                <label for="inputEmail4" class="tc2">&nbsp;</label>
                <button class="resetbtn" (click)="resetCreation();" >Reset</button>
                <!-- <input type="number" min="0" step="0.1" [ngModelOptions]="{standalone: true}" max="100" [(ngModel)]="creationIncome"  (input)="redrawCreation()" class="form-control" placeholder="Retirement Income"> -->
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="tab-pane fade show pt-3 pb-4" id="calculator" role="tabpanel" aria-labelledby="calculator-tab">
        <div class="boxcolor mt-2 scroll_content" style="background-color: #ffffff;">
            <!-- <div class="container h-100"> -->
                <div class="row align-items-center h-100">

                    <div class="calculation-output mt-3">
                        <div class="calculation-output-left"><div>
                          <p class="calculation-heading">Mortgage</p>
                          <p class="calculation-value">${{ moratage_value | number }}</p>
                        </div>
                        <div>
                          <p class="calculation-heading">Annual Interest Rate</p>
                          <p class="calculation-value">{{ interestrate_value | number : '1.1-1' }}%</p>
                        </div>
                        <div *ngIf="showTerms">
                          <p class="calculation-heading">Monthly Terms</p>
                          <p class="calculation-value last">{{ monthlyterms_value | number }}</p>
                        </div>
                        <div *ngIf="!showTerms">
                          <p class="calculation-heading">Monthly Payments</p>
                          <p class="calculation-value last">{{ monthlypayments_value | number }}</p>
                        </div>
                      </div>
                      <div class="calculation-output-right" *ngIf="showTerms">
                        <p class="calculated-value">${{ calculateDvalue | number:'1.0-0' }}</p>
                        <p class="calculated-subheading">Per Month</p>
                      </div>

                      <div class="calculation-output-right" *ngIf="!showTerms">
                        <p class="calculated-value">{{ calculateDvalue | number:'1.0-0' }}</p>
                        <p class="calculated-subheading">Years</p>
                      </div>
                    </div>
                </div>
          </div>
        <div class="boxcolor mt-2 subgraphs">
          <form>
            <div class="form-group edit-border">
              <label for="inputEmail4" class="tc2">Calculation Type</label>
              <select (change)="showTermsChange($event.target.value)" id="inputState" #moratagetype class="form-control">
                <option value="1">Mortgage Payments</option>
                <option value="0">Mortgage Terms</option>
              </select>
            </div>
            <div class="form-row">
              <div class="form-group col-md-4 edit-border">
                <label for="inputEmail4" class="tc2">Mortgage </label>
                <input type="number" min="0" step="1" [ngModelOptions]="{standalone: true}" [(ngModel)]="moratage"  (input)="calculteTotal()" class="form-control"  placeholder="Mortgage">
              </div>
              <div class="form-group col-md-4 edit-border">
                <label for="inputEmail4" class="tc2">Annual Interest Rate</label>
                <input type="number" min="0" step="0.1" [ngModelOptions]="{standalone: true}" max="100" [(ngModel)]="interestrate"  (input)="calculteTotal()" class="form-control" placeholder="Annual Interest Rate">
              </div>
              <div class="form-group col-md-4 edit-border" *ngIf="showTerms">
                <label for="inputEmail4" class="tc2">Monthly Terms</label>
                <input type="number" min="0" step="1" [ngModelOptions]="{standalone: true}" [(ngModel)]="monthlyterms"  (input)="calculteTotal()" class="form-control" placeholder="Monthly Terms">
              </div>
              <div class="form-group col-md-4 edit-border" *ngIf="!showTerms">
                <label for="inputEmail4" class="tc2">Monthly Payments</label>
                <input type="number" min="0" step="1" [ngModelOptions]="{standalone: true}" [(ngModel)]="monthlypayments"  (input)="calculteTotal()" class="form-control" placeholder="Monthly Terms">
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
