export class GetTreeModel {
    public id : string ;
    public relations: Relations[];
    public familyTreeId : string;
    public firstName : string ;
    public middleName : string ;
    public lastName : string ;
    public gender : number;
    public dateOfBirth : string;
    public isDependent : boolean;
    public isDeceased : boolean;
    public isRoot : boolean;
    public relation : string;
    public lastUpdatedDate : string;
    public clientCode : string;
    public calculateAge:number =0;
}

export class Relations{
    public id : string;
    public dependantId: string;
    public lastUpdatedDate: string;
    public relationDependantId: string;
}

export class GetTreeModel2 {
    public familyTreeId : string ;
    public members: GetTreeModel[];
    public note : string;
}


export class RelationsModel{
    public name : string;
    public relationIDs: RelationIdsModel[];
}

export class RelationIdsModel{
    dependantId: string;
    relationDependantId: string;
}
export class MemberModel {
    familyTreeId: string
    isRoot: boolean
    firstName: string
    middleName: string
    lastName: string
    gender: number
    dateOfBirth: string
    calculateAge: number
    relation: string
    relations: RelationIdsModel[]
    isDependent: boolean
    isDeceased: boolean
}

export class TreeExistModel{
    clientCode: string
    id: string
    lastUpdatedDate: string
    partnerClientCode: string
    recordType: number
    versionNumber: number
}


