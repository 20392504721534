<div class="section-1">
    <h6> Executor </h6>
  <div class="row gutter">
    <div class="col-md-6"> 
      <table class="table tablecollect">
        <thead>
          <tr>
            <th class="tc2 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs">For {{clientFirstname}}</th>
            <th class="tc1 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs pdAlignTableHead"> </th>
            <!-- <th class="tc1 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs pdAlignTableHead"> </th> -->
          </tr>
        </thead>
        <tbody>  
          <ng-container *ngFor="let executor of willInfo.executors.clientExecutors; let i = index">
            <tr>
              <td class="tableleftbedit">
                <input type="text" readonly [(ngModel)]="executor.name" id="executorName" (change)="willExecutorsSave(executor, $event)" class="form-control neweditinput" placeholder="">
              </td>

              <td class="tableleftbedit">
                <!-- <ng-container *ngIf="executor.type == 0">
                  <input type="text" readonly value="Initial" id="executorNamePartner" (change)="willExecutorsSave(executor, $event)" class="form-control neweditinput" placeholder="">
                </ng-container>
                <ng-container *ngIf="executor.type == 1">
                  <input type="text" readonly value="Substitute" id="executorNamePartner" (change)="willExecutorsSave(executor, $event)" class="form-control neweditinput" placeholder="">
                </ng-container>
                <ng-container *ngIf="executor.type == 2">
                  <input type="text" readonly value="Joint" id="executorNamePartner" (change)="willExecutorsSave(executor, $event)" class="form-control neweditinput" placeholder="">
                </ng-container> -->
                <select [(ngModel)]="executor.type" id="executorType" (change)="willExecutorsSave(executor, $event)" class="form-control neweditinput select-box" placeholder="Please select" >  
                  <!-- <option value=0>Initial</option>
                  <option value=1>Substitute</option>
                  <option value=2>Joint</option> -->

                  <ng-container *ngFor="let executortype of executortype_list; let j=index;" >
                    <option [value]="executortype.value">{{executortype.key}}</option>
                  </ng-container>
                </select>
              </td>
              <!-- <td class="action-btn-td">
                  <a (click)="deleteExecutorUser(executor)" class="action-btn trash_depend"><i class="fa fa-trash "></i></a>
              </td> -->
            </tr>
          </ng-container> 
          <ng-container *ngIf="willInfo.executors.clientExecutors.length == 0">
            <tr>
              <td colspan="">No records to show.</td>
            </tr>
          </ng-container>
          <!-- <tr>
            <td class="tableleftbedit edit-border">
              <input type="text" value="" id="cl_executor_name" class="form-control neweditinput" placeholder="Name">
            </td>
            <td class="tableleftbedit edit-border extra-border">
              <select class="form-control neweditinput select-box" id="cl_executor_type" placeholder="Please select" >         
                <option value=0>Initial</option>
                <option value=1>Substitute</option>
                <option value=2>Joint</option>
              </select>
            </td>
            <td class="action-btn-td add-btn-td">
              <button class="add-btn" (click)="addWillExecutors(willInfo.executors, 1)" formtarget="_blank">Add</button>                    
            </td>
          </tr> -->
        </tbody>
      </table>
    </div>

    <ng-container *ngIf="partnerHas">
      <div class="col-md-6"> 
        <table class="table tablecollect">
          <thead>
            <tr>
              <th class="tc2 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs">For {{partnerFirstname}}</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs pdAlignTableHead"> </th>
              <!-- <th class="tc1 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs pdAlignTableHead"> </th> -->
            </tr>
          </thead>
          <tbody> 
            <ng-container *ngFor="let executor of willInfo.executors.partnerExecutors; let i = index">
              <tr>
                <td class="tableleftbedit">
                  <input type="text" readonly [(ngModel)]="executor.name" id="executorNamePartner" (change)="willExecutorsSave(executor, $event)" class="form-control neweditinput" placeholder="">
                </td>

                <td class="tableleftbedit">
                  <!-- <ng-container *ngIf="executor.type == 0">
                    <input type="text" readonly value="Initial" id="executorNamePartner" (change)="willExecutorsSave(executor, $event)" class="form-control neweditinput" placeholder="">
                  </ng-container>
                  <ng-container *ngIf="executor.type == 1">
                    <input type="text" readonly value="Substitute" id="executorNamePartner" (change)="willExecutorsSave(executor, $event)" class="form-control neweditinput" placeholder="">
                  </ng-container>
                  <ng-container *ngIf="executor.type == 2">
                    <input type="text" readonly value="Joint" id="executorNamePartner" (change)="willExecutorsSave(executor, $event)" class="form-control neweditinput" placeholder="">
                  </ng-container> -->
                  <select [(ngModel)]="executor.type" id="executorTypePartner" (change)="willExecutorsSave(executor, $event)" class="form-control neweditinput select-box" placeholder="Please select" >         
                    <!-- <option value=0>Initial</option>
                    <option value=1>Substitute</option>
                    <option value=2>Joint</option> -->

                    <ng-container *ngFor="let executortype of executortype_list; let j=index;" >
                      <option [value]="executortype.value">{{executortype.key}}</option>
                    </ng-container>
                  </select>
                </td>
                <!-- <td class="action-btn-td">
                  <a (click)="deleteExecutorUser(executor)" class="action-btn trash_depend"><i class="fa fa-trash "></i></a>
                </td> -->
              </tr>
            </ng-container>  
            <ng-container *ngIf="willInfo?.executors?.partnerExecutors && willInfo?.executors?.partnerExecutors.length == 0">
              <tr>
                <td colspan="">No records to show.</td>
              </tr>
            </ng-container>
            <!-- <tr>
              <td class="tableleftbedit edit-border">
                <input type="text" value="" id="pa_executor_name" class="form-control neweditinput" placeholder="Name">
              </td>
              <td class="tableleftbedit edit-border extra-border">
                <select class="form-control neweditinput select-box" id="pa_executor_type" placeholder="Please select" >         
                  <option value=0>Initial</option>
                  <option value=1>Substitute</option>
                  <option value=2>Joint</option>
                </select>
              </td>
              <td class="action-btn-td add-btn-td">
                <button class="add-btn" (click)="addWillExecutors(willInfo.executors, 2)" formtarget="_blank">Add</button>                    
              </td>
            </tr> -->
          </tbody>
        </table>        
      </div>
    </ng-container>
    
  </div> 

  <div class="row gutter">
    <div class="col-md-12">  
      <table class="table tablecollect">
        <thead>
          <tr>
            <th class="tc2 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs">Notes</th>
          </tr>
        </thead>
        <tbody>                
          <tr>
            <td class="tableleftbedit edit-border">
              <textarea [(ngModel)]="willInfo.executors.note" id="executorNote" (change)="willExecutorsNoteSave(willInfo.executors, $event)" class="form-control neweditinput-1" rows="5" placeholder="Notes" style="resize: none;" ></textarea>
            </td>
          </tr>
        </tbody>
      </table>  
    </div>     
  </div>
  </div>