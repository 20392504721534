<div class="section-1">
    <h6> Calamity Beneficiaries </h6>

    <div class="row gutter">
      <div class="col-md-12">  
        <table class="table tablecollect">
            <!--<thead>
            <tr>
              <th class="tc2 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs">Notes</th>
            </tr>
          </thead>-->
          <tbody>                
            <tr>
              <td class="tableleftbedit edit-border">
                <select [(ngModel)]="willInfo.calamityBeneficiaries.type" id="calamityBeneficiaries" (change)="calamityBeneficiariesSave(willInfo.calamityBeneficiaries, $event)" class="form-control neweditinput" placeholder="Please select" >         
                  <option value="0">Both respective sets of parents (50/50), then siblings</option>
                  <option value="1">Both respective sets of siblings (50/50), then their children</option>                      
                  <option value="2">Other</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>  
      </div>     
    </div>

    <div class="row gutter" [ngClass]="showCBUsers ? '':'tdtlisthide'">
      <div class="col-md-6"> 
        <table class="table tablecollect">
          <thead>
            <tr>
              <th class="tc2 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs">For {{clientFirstname}}</th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs pdAlignTableHead"> </th>
            </tr>
          </thead>
          <tbody>   
            <ng-container *ngFor="let calamityBeneficiaryUsers of willInfo.calamityBeneficiaries.clientCalamityBeneficiaryUsers; let i = index">
              <tr>
                <td class="tableleftbedit edit-border">
                  <input type="text" [(ngModel)]="calamityBeneficiaryUsers.name" id="clientCalamityBeneficiaryUsersName" (change)="calamityBeneficiaryUserSave(calamityBeneficiaryUsers, $event)" class="form-control neweditinput" placeholder="Name">
                </td>
                <td class="tableleftbedit edit-border extra-border">
                  <input type="text" value="{{calamityBeneficiaryUsers.percentage}}%" id="clientCalamityBeneficiaryUsersPercentage" (change)="calamityBeneficiaryUserSave(calamityBeneficiaryUsers, $event)" class="form-control neweditinput" placeholder="0">
                </td>
                <td class="action-btn-td">
                  <!-- <a href="#" class="action-btn edit_pencil"><i class="fa fa-pencil "></i></a> -->
                  <a (click)="deleteCalamityBeneficiaryUser(calamityBeneficiaryUsers)" class="action-btn trash_depend"><i class="fa fa-trash "></i></a>
                </td>
              </tr>
            </ng-container>   
            <tr>
              <td class="tableleftbedit edit-border">
                <input type="text" id="clientCalamityBeneficiaryUsersNameAdd" class="form-control neweditinput" placeholder="Name">
              </td>
              <td class="tableleftbedit edit-border extra-border">
                <input type="text" id="clientCalamityBeneficiaryUsersPercentageAdd" class="form-control neweditinput" placeholder="0%">
              </td>
              <td class="action-btn-td add-btn-td">
                <button class="add-btn" (click)="addCalamityBeneficiaryUser(willInfo.calamityBeneficiaries, 1)" formtarget="_blank">Add</button>                    
              </td>  
            </tr>
            
          </tbody>
        </table>
      </div>

      <ng-container *ngIf="partnerHas">
        <div class="col-md-6"> 
          <table class="table tablecollect">
            <thead>
              <tr>
                <th class="tc2 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs">For {{partnerFirstname}}</th>
                <th class="tc1 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs pdAlignTableHead"> </th>
              </tr>
            </thead>
            <tbody> 
              <ng-container *ngFor="let calamityBeneficiaryUsers of willInfo.calamityBeneficiaries.partnerCalamityBeneficiaryUsers; let i = index">
                <tr>
                  <td class="tableleftbedit edit-border">
                    <input type="text" [(ngModel)]="calamityBeneficiaryUsers.name" id="partnerCalamityBeneficiaryUsersName" (change)="calamityBeneficiaryUserSave(calamityBeneficiaryUsers, $event)" class="form-control neweditinput" placeholder="Name">
                  </td>
                  <td class="tableleftbedit edit-border extra-border" >
                    <input type="text" value="{{calamityBeneficiaryUsers.percentage}}%" id="partnerCalamityBeneficiaryUsersPercentage" (change)="calamityBeneficiaryUserSave(calamityBeneficiaryUsers, $event)" class="form-control neweditinput" placeholder="0%">
                  </td>
                  <td class="action-btn-td">
                    <!-- <a href="#" class="action-btn edit_pencil"><i class="fa fa-pencil "></i></a> -->
                    <a (click)="deleteCalamityBeneficiaryUser(calamityBeneficiaryUsers)" class="action-btn trash_depend"><i class="fa fa-trash "></i></a>
                  </td>
                </tr>
              </ng-container> 
              <tr>
                <td class="tableleftbedit edit-border">
                  <input type="text" id="partnerCalamityBeneficiaryUsersNameAdd" class="form-control neweditinput" placeholder="Name">
                </td>
                <td class="tableleftbedit edit-border extra-border">
                  <input type="text" id="partnerCalamityBeneficiaryUsersPercentageAdd" class="form-control neweditinput" placeholder="0%">
                </td>
                <td class="action-btn-td add-btn-td">
                  <button class="add-btn" (click)="addCalamityBeneficiaryUser(willInfo.calamityBeneficiaries, 2)" formtarget="_blank">Add</button>                    
                </td>  
              </tr>

            </tbody>
          </table>        
        </div>
      </ng-container>
      
    </div> 

    <div class="row gutter">
      <div class="col-md-12">  
        <table class="table tablecollect">
          <thead>
            <tr>
              <th class="tc2 font-weight-normal pt-0 pb-0 tab_fspt-0 pb-0 tab_fs">Notes</th>
            </tr>
          </thead>
          <tbody>                
            <tr>
              <td class="tableleftbedit edit-border">
                <textarea [(ngModel)]="willInfo.calamityBeneficiaries.note" id="calamityBeneficiariesNote" (change)="calamityBeneficiariesSave(willInfo.calamityBeneficiaries, $event)" class="form-control neweditinput-1" rows="5" placeholder="Notes" style="resize: none;"></textarea>
              </td>
            </tr>
          </tbody>
        </table>  
      </div>     
    </div>
  </div>