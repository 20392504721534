import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { ProgressiveNeedsTPDModel } from 'src/app/family-tree/rightside/insurance/tpd/tpd.mode';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  // -- PROGRESSIVE NEEDS Data -- //
  private progressiveNeedsTPDdata = new BehaviorSubject<ProgressiveNeedsTPDModel>(null);
  progressiveNeedsObserver = this.progressiveNeedsTPDdata.asObservable();

  private progressiveNeedsLifedata = new BehaviorSubject<ProgressiveNeedsTPDModel>(null);
  progressiveNeedsLifeObserver = this.progressiveNeedsLifedata.asObservable();
  
  constructor() { }


  setProgressiveNeedsTPDdata(data: ProgressiveNeedsTPDModel) { // -- PROGRESSIVE NEEDS Data -- //
    this.progressiveNeedsTPDdata.next(data)
  }

  setProgressiveNeedsLifedata(data: any) {
    this.progressiveNeedsLifedata.next(data)
  }

  private familyTreeIdSource = new BehaviorSubject<string>('');
  familyTreeId = this.familyTreeIdSource.asObservable();

  private clientFirstnameSource = new BehaviorSubject<string>('');
  clientFirstname = this.clientFirstnameSource.asObservable();

  private partnerFirstnameSource = new BehaviorSubject<string>('');
  partnerFirstname = this.partnerFirstnameSource.asObservable();

  private partnerHasSource = new BehaviorSubject<boolean>(false);
  partnerHas = this.partnerHasSource.asObservable();

  private clientIDSource = new BehaviorSubject<string>('');
  clientID = this.clientIDSource.asObservable();

  private partnerIDSource = new BehaviorSubject<string>('');
  partnerID = this.partnerIDSource.asObservable();

  private retirementAgeSource = new BehaviorSubject<number>(0);
  retirementAge = this.retirementAgeSource.asObservable();

  private retirementAgePartnerSource = new BehaviorSubject<number>(0);
  retirementAgePartner = this.retirementAgePartnerSource.asObservable();

  private combinedRetirementIncomeSource = new BehaviorSubject<any>(0);
  combinedRetirementIncome = this.combinedRetirementIncomeSource.asObservable();

  private percentageIfPartnerDeceasedSource = new BehaviorSubject<number>(0);
  percentageIfPartnerDeceased = this.percentageIfPartnerDeceasedSource.asObservable();

  private percentageIfPartnerDeceasedPartnerSource = new BehaviorSubject<number>(0);
  percentageIfPartnerDeceasedPartner = this.percentageIfPartnerDeceasedPartnerSource.asObservable();

  private mainNodeAgeSource = new BehaviorSubject<number>(0);
  mainNodeAge = this.mainNodeAgeSource.asObservable();

  private mainNodeGenderSource = new BehaviorSubject<number>(0);
  mainNodeGender = this.mainNodeGenderSource.asObservable();

  private financesInfoSource = new BehaviorSubject<any>('');
  financesInfo = this.financesInfoSource.asObservable();

  private clientCodeSource = new BehaviorSubject<string>('');
  client_code = this.clientCodeSource.asObservable();

  private partnerClientCodeSource = new BehaviorSubject<string>('');
  partnerClientCode = this.partnerClientCodeSource.asObservable();

  public editDataDetails: any = {};
  private provisionInfoSource = new BehaviorSubject<any>(this.editDataDetails);
  provisionInfo = this.provisionInfoSource.asObservable();

  public editDataDetails2: any = {};
  private insuranceInfoSource = new BehaviorSubject<any>(this.editDataDetails2);
  insuranceInfo = this.insuranceInfoSource.asObservable();

  private insurableIncomeClientSource = new BehaviorSubject<number>(0);
  insurableIncomeClient = this.insurableIncomeClientSource.asObservable();

  private insurableIncomePartnerSource = new BehaviorSubject<number>(0);
  insurableIncomePartner = this.insurableIncomePartnerSource.asObservable();

  private clientAgeSource = new BehaviorSubject<number>(0);
  clientAge = this.clientAgeSource.asObservable();

  private partnerAgeSource = new BehaviorSubject<number>(0);
  partnerAge = this.partnerAgeSource.asObservable();

  private annualBenefitClientSource = new BehaviorSubject<number>(0);
  annualBenefitClient = this.annualBenefitClientSource.asObservable();

  private annualBenefitPartnerSource = new BehaviorSubject<number>(0);
  annualBenefitPartner = this.annualBenefitPartnerSource.asObservable();

  changeFamilyTreeId(familyTreeId: string) {
    this.familyTreeIdSource.next(familyTreeId)
  }
  changeClientFirstname(clientFirstname: string) {
    this.clientFirstnameSource.next(clientFirstname)
  }
  changePartnerFirstname(partnerFirstname: string) {
    this.partnerFirstnameSource.next(partnerFirstname)
  }
  changePartnerHas(partnerHas: boolean) {
    this.partnerHasSource.next(partnerHas)
  }
  changeClientID(clientID: string) {
    this.clientIDSource.next(clientID)
  }
  changePartnerID(partnerID: string) {
    this.partnerIDSource.next(partnerID)
  }
  changeRetirementAge(retirementAge: any) {
    this.retirementAgeSource.next(retirementAge)
  }
  changeRetirementAgePartner(retirementAgePartner: any) {
    this.retirementAgePartnerSource.next(retirementAgePartner)
  }
  changeCombinedRetirementIncome(combinedRetirementIncome: any) {
    this.combinedRetirementIncomeSource.next(combinedRetirementIncome)
  }
  changePercentageIfPartnerDeceased(percentageIfPartnerDeceased: any) {
    this.percentageIfPartnerDeceasedSource.next(percentageIfPartnerDeceased)
  }
  changePercentageIfPartnerDeceasedPartner(percentageIfPartnerDeceasedPartner: any) {
    this.percentageIfPartnerDeceasedPartnerSource.next(percentageIfPartnerDeceasedPartner)
  }

  changeMainNodeAge(mainNodeAge: any) {
    this.mainNodeAgeSource.next(mainNodeAge)
  }
  changEmainNodeGender(mainNodeGender: any) {
    this.mainNodeGenderSource.next(mainNodeGender)
  }
  changFinancesInfo(financesInfo: any) {
    this.financesInfoSource.next(financesInfo)
  }
  changClientCode(client_code: string) {
    this.clientCodeSource.next(client_code)
  }
  changPartnerClientCode(partnerClientCode: string) {
    this.partnerClientCodeSource.next(partnerClientCode)
  }
  changProvisionInfo(provisionInfo: any) {
    this.provisionInfoSource.next(provisionInfo)
  }
  changInsuranceInfo(insuranceInfo: any) {
    this.insuranceInfoSource.next(insuranceInfo)
  }
  changInsurableIncomeClient(insurableIncomeClient: number) {
    this.insurableIncomeClientSource.next(insurableIncomeClient)
  }
  changInsurableIncomePartner(insurableIncomePartner: number) {
    this.insurableIncomePartnerSource.next(insurableIncomePartner)
  }
  changClientAge(clientAge: number) {
    this.clientAgeSource.next(clientAge)
  }
  changPartnerAge(partnerAge: number) {
    this.partnerAgeSource.next(partnerAge)
  }
  changAnnualBenefitClient(annualBenefitClient: number) {
    this.annualBenefitClientSource.next(annualBenefitClient)
  }
  changAnnualBenefitPartner(annualBenefitPartner: number) {
    this.annualBenefitPartnerSource.next(annualBenefitPartner)
  }


  private totalDebtSource = new BehaviorSubject<number>(0);
  totalDebt = this.totalDebtSource.asObservable();

  private funeralLegalExpensesClientSource = new BehaviorSubject<number>(0);
  funeralLegalExpensesClient = this.funeralLegalExpensesClientSource.asObservable();

  private funeralLegalExpensesPartnerSource = new BehaviorSubject<number>(0);
  funeralLegalExpensesPartner = this.funeralLegalExpensesPartnerSource.asObservable();

  private housePurchaseClientSource = new BehaviorSubject<number>(0);
  housePurchaseClient = this.housePurchaseClientSource.asObservable();

  private housePurchasePartnerSource = new BehaviorSubject<number>(0);
  housePurchasePartner = this.housePurchasePartnerSource.asObservable();

  private deceasedPersonsSuperClientSource = new BehaviorSubject<number>(0);
  deceasedPersonsSuperClient = this.deceasedPersonsSuperClientSource.asObservable();

  private deceasedPersonsSuperPartnerSource = new BehaviorSubject<number>(0);
  deceasedPersonsSuperPartner = this.deceasedPersonsSuperPartnerSource.asObservable();

  private valueOfAllInvestmentsIfSoldClientSource = new BehaviorSubject<number>(0);
  valueOfAllInvestmentsIfSoldClient = this.valueOfAllInvestmentsIfSoldClientSource.asObservable();

  private valueOfAllInvestmentsIfSoldPartnerSource = new BehaviorSubject<number>(0);
  valueOfAllInvestmentsIfSoldPartner = this.valueOfAllInvestmentsIfSoldPartnerSource.asObservable();

  private valueOfAllInvestmentsIfSoldJointSource = new BehaviorSubject<number>(0);
  valueOfAllInvestmentsIfSoldJoint = this.valueOfAllInvestmentsIfSoldJointSource.asObservable();

  private totalNetImmediateNeedClientSource = new BehaviorSubject<number>(0);
  totalNetImmediateNeedClient = this.totalNetImmediateNeedClientSource.asObservable();

  private totalNetImmediateNeedPartnerSource = new BehaviorSubject<number>(0);
  totalNetImmediateNeedPartner = this.totalNetImmediateNeedPartnerSource.asObservable();

  changTotalDebt(totalDebt: number) {
    this.totalDebtSource.next(totalDebt)
  }
  changFuneralLegalExpensesClient(funeralLegalExpensesClient: number) {
    this.funeralLegalExpensesClientSource.next(funeralLegalExpensesClient)
  }
  changFuneralLegalExpensesPartner(funeralLegalExpensesPartner: number) {
    this.funeralLegalExpensesPartnerSource.next(funeralLegalExpensesPartner)
  }
  changHousePurchaseClient(housePurchaseClient: number) {
    this.housePurchaseClientSource.next(housePurchaseClient)
  }
  changHousePurchasePartner(housePurchasePartner: number) {
    this.housePurchasePartnerSource.next(housePurchasePartner)
  }
  changDeceasedPersonsSuperClient(deceasedPersonsSuperClient: number) {
    this.deceasedPersonsSuperClientSource.next(deceasedPersonsSuperClient)
  }
  changDeceasedPersonsSuperPartner(deceasedPersonsSuperPartner: number) {
    this.deceasedPersonsSuperPartnerSource.next(deceasedPersonsSuperPartner)
  }
  changValueOfAllInvestmentsIfSoldClient(valueOfAllInvestmentsIfSoldClient: number) {
    this.valueOfAllInvestmentsIfSoldClientSource.next(valueOfAllInvestmentsIfSoldClient)
  }
  changValueOfAllInvestmentsIfSoldPartner(valueOfAllInvestmentsIfSoldPartner: number) {
    this.valueOfAllInvestmentsIfSoldPartnerSource.next(valueOfAllInvestmentsIfSoldPartner)
  }
  changValueOfAllInvestmentsIfSoldJoint(valueOfAllInvestmentsIfSoldJoint: number) {
    this.valueOfAllInvestmentsIfSoldJointSource.next(valueOfAllInvestmentsIfSoldJoint)
  }
  changTotalNetImmediateNeedClient(totalNetImmediateNeedClient: number) {
    this.totalNetImmediateNeedClientSource.next(totalNetImmediateNeedClient)
  }
  changTotalNetImmediateNeedPartner(totalNetImmediateNeedPartner: number) {
    this.totalNetImmediateNeedPartnerSource.next(totalNetImmediateNeedPartner)
  }


  private homeModificationClientSource = new BehaviorSubject<number>(0);
  homeModificationClient = this.homeModificationClientSource.asObservable();

  private homeModificationPartnerSource = new BehaviorSubject<number>(0);
  homeModificationPartner = this.homeModificationPartnerSource.asObservable();

  private medicalTreatmentClientSource = new BehaviorSubject<number>(0);
  medicalTreatmentClient = this.medicalTreatmentClientSource.asObservable();

  private medicalTreatmentPartnerSource = new BehaviorSubject<number>(0);
  medicalTreatmentPartner = this.medicalTreatmentPartnerSource.asObservable();

  private immediateNeedsTotalClientSource = new BehaviorSubject<number>(0);
  immediateNeedsTotalClient = this.immediateNeedsTotalClientSource.asObservable();

  private immediateNeedsTotalPartnerSource = new BehaviorSubject<number>(0);
  immediateNeedsTotalPartner = this.immediateNeedsTotalPartnerSource.asObservable();

  private totalValueOfInvestmentsIfSoldSource = new BehaviorSubject<number>(0);
  totalValueOfInvestmentsIfSold = this.totalValueOfInvestmentsIfSoldSource.asObservable();

  private immediateNonSuperIncomingAssetsTotalClientSource = new BehaviorSubject<number>(0);
  immediateNonSuperIncomingAssetsTotalClient = this.immediateNonSuperIncomingAssetsTotalClientSource.asObservable();

  private immediateNonSuperIncomingAssetsTotalPartnerSource = new BehaviorSubject<number>(0);
  immediateNonSuperIncomingAssetsTotalPartner = this.immediateNonSuperIncomingAssetsTotalPartnerSource.asObservable();

  private cashedOutOfSuperImmediatelyClientSource = new BehaviorSubject<number>(0);
  cashedOutOfSuperImmediatelyClient = this.cashedOutOfSuperImmediatelyClientSource.asObservable();

  private cashedOutOfSuperImmediatelyPartnerSource = new BehaviorSubject<number>(0);
  cashedOutOfSuperImmediatelyPartner = this.cashedOutOfSuperImmediatelyPartnerSource.asObservable();

  private grossUpForTaxClientSource = new BehaviorSubject<number>(0);
  grossUpForTaxClient = this.grossUpForTaxClientSource.asObservable();

  private totalNetImmediateNeedTPDClientSource = new BehaviorSubject<number>(0);
  totalNetImmediateNeedTPDClient = this.totalNetImmediateNeedTPDClientSource.asObservable();

  private totalNetImmediateNeedTPDPartnerSource = new BehaviorSubject<number>(0);
  totalNetImmediateNeedTPDPartner = this.totalNetImmediateNeedTPDPartnerSource.asObservable();

  private grossUpForTaxPartnerSource = new BehaviorSubject<number>(0);
  grossUpForTaxPartner = this.grossUpForTaxPartnerSource.asObservable();


  changHomeModificationClient(homeModificationClient: number) {
    this.homeModificationClientSource.next(homeModificationClient)
  }
  changHomeModificationPartner(homeModificationPartner: number) {
    this.homeModificationPartnerSource.next(homeModificationPartner)
  }
  changMedicalTreatmentClient(medicalTreatmentClient: number) {
    this.medicalTreatmentClientSource.next(medicalTreatmentClient)
  }
  changMedicalTreatmentPartner(medicalTreatmentPartner: number) {
    this.medicalTreatmentPartnerSource.next(medicalTreatmentPartner)
  }
  changImmediateNeedsTotalClient(immediateNeedsTotalClient: number) {
    this.immediateNeedsTotalClientSource.next(immediateNeedsTotalClient)
  }
  changImmediateNeedsTotalPartner(immediateNeedsTotalPartner: number) {
    this.immediateNeedsTotalPartnerSource.next(immediateNeedsTotalPartner)
  }
  changTotalValueOfInvestmentsIfSold(totalValueOfInvestmentsIfSold: number) {
    this.totalValueOfInvestmentsIfSoldSource.next(totalValueOfInvestmentsIfSold)
  }
  changImmediateNonSuperIncomingAssetsTotalClient(immediateNonSuperIncomingAssetsTotalClient: number) {
    this.immediateNonSuperIncomingAssetsTotalClientSource.next(immediateNonSuperIncomingAssetsTotalClient)
  }
  changImmediateNonSuperIncomingAssetsTotalPartner(immediateNonSuperIncomingAssetsTotalPartner: number) {
    this.immediateNonSuperIncomingAssetsTotalPartnerSource.next(immediateNonSuperIncomingAssetsTotalPartner)
  }
  changCashedOutOfSuperImmediatelyClient(cashedOutOfSuperImmediatelyClient: number) {
    this.cashedOutOfSuperImmediatelyClientSource.next(cashedOutOfSuperImmediatelyClient)
  }
  changCashedOutOfSuperImmediatelyPartner(cashedOutOfSuperImmediatelyPartner: number) {
    this.cashedOutOfSuperImmediatelyPartnerSource.next(cashedOutOfSuperImmediatelyPartner)
  }
  changGrossUpForTaxClient(grossUpForTaxClient: number) {
    this.grossUpForTaxClientSource.next(grossUpForTaxClient)
  }
  changGrossUpForTaxPartner(grossUpForTaxPartner: number) {
    this.grossUpForTaxPartnerSource.next(grossUpForTaxPartner)
  }
  changTotalNetImmediateNeedTPDClient(totalNetImmediateNeedTPDClient: number) {
    this.totalNetImmediateNeedTPDClientSource.next(totalNetImmediateNeedTPDClient)
  }
  changTotalNetImmediateNeedTPDPartner(totalNetImmediateNeedTPDPartner: number) {
    this.totalNetImmediateNeedTPDPartnerSource.next(totalNetImmediateNeedTPDPartner)
  }


  private investmentPropertiesClientSource = new BehaviorSubject<number>(0);
  investmentPropertiesClient = this.investmentPropertiesClientSource.asObservable();

  private investmentPropertiesPartnerSource = new BehaviorSubject<number>(0);
  investmentPropertiesPartner = this.investmentPropertiesPartnerSource.asObservable();

  changInvestmentPropertiesClient(investmentPropertiesClient: number) {
    this.investmentPropertiesClientSource.next(investmentPropertiesClient)
  }
  changInvestmentPropertiesPartner(investmentPropertiesPartner: number) {
    this.investmentPropertiesPartnerSource.next(investmentPropertiesPartner)
  }


  private financeMainUserTotal = new BehaviorSubject<number>(0);
  mainUserFinanceTotal = this.financeMainUserTotal.asObservable();

  private financePartnerUserTotal = new BehaviorSubject<number>(0);
  partnerUserFinanceTotal = this.financePartnerUserTotal.asObservable();

  private financeJointUserTotal = new BehaviorSubject<number>(0);
  jointUserFinanceTotal = this.financeJointUserTotal.asObservable();

  private incomeSource = new BehaviorSubject<number>(0);
  income = this.incomeSource.asObservable();

  private incomePartnerSource = new BehaviorSubject<number>(0);
  incomePartner = this.incomePartnerSource.asObservable();

  private yearsNeedOfSource = new BehaviorSubject<number>(0);
  yearsNeedOf = this.yearsNeedOfSource.asObservable();

  private yearsNeedOfPartnerSource = new BehaviorSubject<number>(0);
  yearsNeedOfPartner = this.yearsNeedOfPartnerSource.asObservable();

  private salaryOwnerSource = new BehaviorSubject<number>(0);
  salaryOwner = this.salaryOwnerSource.asObservable();

  private salaryPartnerSource = new BehaviorSubject<number>(0);
  salaryPartner = this.salaryPartnerSource.asObservable();


  private family_tree = new BehaviorSubject<any>([]);
  familyTree = this.family_tree.asObservable()



  changFamilyTree(currentTree: any) {
     this.family_tree.next(currentTree)
  }

  changMainUserFinanceTotal(mainUserFinanceTotal: number) {
    this.financeMainUserTotal.next(mainUserFinanceTotal)
  }

  changPartnerUserFinanceTotal(partnerUserFinanceTotal: number) {
    this.financePartnerUserTotal.next(partnerUserFinanceTotal)
  }

  changJointUserFinanceTotal(jointUserFinanceTotal: number) {
    this.financeJointUserTotal.next(jointUserFinanceTotal)
  }

  changIncome(income: number) {
    this.incomeSource.next(income)
  }

  changIncomePartner(incomePartner: number) {
    this.incomePartnerSource.next(incomePartner)
  }

  changYearsNeedOf(yearsNeedOf: number) {
    this.yearsNeedOfSource.next(yearsNeedOf)
  }

  changYearsNeedOfPartner(yearsNeedOfPartner: number) {
    this.yearsNeedOfPartnerSource.next(yearsNeedOfPartner)
  }

  changSalaryOwner(salaryOwner: number) {
    this.salaryOwnerSource.next(salaryOwner)
  }

  changSalaryPartner(salaryPartner: number) {
    this.salaryPartnerSource.next(salaryPartner)
  }


  private upfrontCapitalNeedClientSource = new BehaviorSubject<number>(0);
  upfrontCapitalNeedClient = this.upfrontCapitalNeedClientSource.asObservable();

  private upfrontCapitalNeedPartnerSource = new BehaviorSubject<number>(0);
  upfrontCapitalNeedPartner = this.upfrontCapitalNeedPartnerSource.asObservable();

  private yearsOffWorkClientSource = new BehaviorSubject<number>(0);
  yearsOffWorkClient = this.yearsOffWorkClientSource.asObservable();

  private yearsOffWorkPartnerSource = new BehaviorSubject<number>(0);
  yearsOffWorkPartner = this.yearsOffWorkPartnerSource.asObservable();

  private coverForYearsOffWorkClientSource = new BehaviorSubject<number>(0);
  coverForYearsOffWorkClient = this.coverForYearsOffWorkClientSource.asObservable();

  private coverForYearsOffWorkPartnerSource = new BehaviorSubject<number>(0);
  coverForYearsOffWorkPartner = this.coverForYearsOffWorkPartnerSource.asObservable();

  private less2YearsPassiveIncomeClientSource = new BehaviorSubject<number>(0);
  less2YearsPassiveIncomeClient = this.less2YearsPassiveIncomeClientSource.asObservable();

  private less2YearsPassiveIncomePartnerSource = new BehaviorSubject<number>(0);
  less2YearsPassiveIncomePartner = this.less2YearsPassiveIncomePartnerSource.asObservable();

  changUpfrontCapitalNeedClient(upfrontCapitalNeedClient: number) {
    this.upfrontCapitalNeedClientSource.next(upfrontCapitalNeedClient)
  }
  changUpfrontCapitalNeedPartner(upfrontCapitalNeedPartner: number) {
    this.upfrontCapitalNeedPartnerSource.next(upfrontCapitalNeedPartner)
  }
  changYearsOffWorkClient(yearsOffWorkClient: number) {
    this.yearsOffWorkClientSource.next(yearsOffWorkClient)
  }
  changYearsOffWorkPartner(yearsOffWorkPartner: number) {
    this.yearsOffWorkPartnerSource.next(yearsOffWorkPartner)
  }
  changCoverForYearsOffWorkClient(coverForYearsOffWorkClient: number) {
    this.coverForYearsOffWorkClientSource.next(coverForYearsOffWorkClient)
  }
  changCoverForYearsOffWorkPartner(coverForYearsOffWorkPartner: number) {
    this.coverForYearsOffWorkPartnerSource.next(coverForYearsOffWorkPartner)
  }
  changLess2YearsPassiveIncomeClient(less2YearsPassiveIncomeClient: number) {
    this.less2YearsPassiveIncomeClientSource.next(less2YearsPassiveIncomeClient)
  }
  changLess2YearsPassiveIncomePartner(less2YearsPassiveIncomePartner: number) {
    this.less2YearsPassiveIncomePartnerSource.next(less2YearsPassiveIncomePartner)
  }


  private familyTreeExistSource = new BehaviorSubject<boolean>(false);
  familyTreeExist = this.familyTreeExistSource.asObservable();

  private isLoadedSource = new BehaviorSubject<boolean>(false);
  isLoaded = this.isLoadedSource.asObservable();

  private membersLengthSource = new BehaviorSubject<number>(0);
  membersLength = this.membersLengthSource.asObservable();

  changFamilyTreeExist(familyTreeExist: boolean) {
    this.familyTreeExistSource.next(familyTreeExist)
  }
  changIsLoaded(isLoaded: boolean) {
    this.isLoadedSource.next(isLoaded)
  }
  changMembersLength(membersLength: number) {
    this.membersLengthSource.next(membersLength)
  }


  private incomeLeavePerChildSource = new BehaviorSubject<number>(0);
  incomeLeavePerChild = this.incomeLeavePerChildSource.asObservable();

  changIncomeLeavePerChild(incomeLeavePerChild: number) {
    this.incomeLeavePerChildSource.next(incomeLeavePerChild)
  }

  private provisionInfoDependentSource = new BehaviorSubject<any>([]);
  provisionInfoDependent = this.provisionInfoDependentSource.asObservable();

  changProvisionInfoDependent(provisionInfoDependent: any) {
    this.provisionInfoDependentSource.next(provisionInfoDependent)
  }

  private treeListFromServerSource = new BehaviorSubject<any>([]);
  treeListFromServer = this.treeListFromServerSource.asObservable();

  changTreeListFromServer(treeListFromServer: any) {
    this.treeListFromServerSource.next(treeListFromServer)
  }

  private passiveIncomeForSpouseSource = new BehaviorSubject<number>(0);
  passiveIncomeForSpouse = this.passiveIncomeForSpouseSource.asObservable();

  changPassiveIncomeForSpouse(passiveIncomeForSpouse: number) {
    this.passiveIncomeForSpouseSource.next(passiveIncomeForSpouse)
  }

  private passiveIncomeForSpousePartnerSource = new BehaviorSubject<number>(0);
  passiveIncomeForSpousePartner = this.passiveIncomeForSpousePartnerSource.asObservable();

  changPassiveIncomeForSpousePartner(passiveIncomeForSpousePartner: number) {
    this.passiveIncomeForSpousePartnerSource.next(passiveIncomeForSpousePartner)
  }

  private provisionForTotallyDisabledSource = new BehaviorSubject<number>(0);
  provisionForTotallyDisabled = this.provisionForTotallyDisabledSource.asObservable();

  changProvisionForTotallyDisabled(provisionForTotallyDisabled: number) {
    this.provisionForTotallyDisabledSource.next(provisionForTotallyDisabled)
  }

  private passiveIncomeContinuedPaidSource = new BehaviorSubject<number>(0);
  passiveIncomeContinuedPaid = this.passiveIncomeContinuedPaidSource.asObservable();

  changPassiveIncomeContinuedPaid(passiveIncomeContinuedPaid: number) {
    this.passiveIncomeContinuedPaidSource.next(passiveIncomeContinuedPaid)
  }

  private passiveIncomeContinuedPaidPartnerSource = new BehaviorSubject<number>(0);
  passiveIncomeContinuedPaidPartner = this.passiveIncomeContinuedPaidPartnerSource.asObservable();

  changPassiveIncomeContinuedPaidPartner(passiveIncomeContinuedPaidPartner: number) {
    this.passiveIncomeContinuedPaidPartnerSource.next(passiveIncomeContinuedPaidPartner)
  }

  private provisionForOngoingMedicalCostsSource = new BehaviorSubject<number>(0);
  provisionForOngoingMedicalCosts = this.provisionForOngoingMedicalCostsSource.asObservable();

  changProvisionForOngoingMedicalCosts(provisionForOngoingMedicalCosts: number) {
    this.provisionForOngoingMedicalCostsSource.next(provisionForOngoingMedicalCosts)
  }

  private benefitPeriodInYearSource = new BehaviorSubject<number>(0);
  benefitPeriodInYear = this.benefitPeriodInYearSource.asObservable();

  changBenefitPeriodInYear(benefitPeriodInYear: number) {
    this.benefitPeriodInYearSource.next(benefitPeriodInYear)
  }

  private benefitPeriodInYearPartnerSource = new BehaviorSubject<number>(0);
  benefitPeriodInYearPartner = this.benefitPeriodInYearPartnerSource.asObservable();

  changBenefitPeriodInYearPartner(benefitPeriodInYearPartner: number) {
    this.benefitPeriodInYearPartnerSource.next(benefitPeriodInYearPartner)
  }

  private totalChildCareSource = new BehaviorSubject<number>(0);
  totalChildCare = this.totalChildCareSource.asObservable();

  changTotalChildCare(totalChildCare: number) {
    this.totalChildCareSource.next(totalChildCare)
  }

  private superBalanceSource = new BehaviorSubject<number>(0);
  superBalance = this.superBalanceSource.asObservable();

  changSuperBalance(superBalance: number) {
    this.superBalanceSource.next(superBalance)
  }

  private superBalancePartnerSource = new BehaviorSubject<number>(0);
  superBalancePartner = this.superBalancePartnerSource.asObservable();

  changSuperBalancePartner(superBalancePartner: number) {
    this.superBalancePartnerSource.next(superBalancePartner)
  }

  // IP
  private benefitAvailableClientSource = new BehaviorSubject<number>(0);
  benefitAvailableClient = this.benefitAvailableClientSource.asObservable();

  changBenefitAvailableClient(benefitAvailableClient: number) {
    this.benefitAvailableClientSource.next(benefitAvailableClient)
  }

  private benefitAvailablePartnerSource = new BehaviorSubject<number>(0);
  benefitAvailablePartner = this.benefitAvailablePartnerSource.asObservable();

  changBenefitAvailablePartner(benefitAvailablePartner: number) {
    this.benefitAvailablePartnerSource.next(benefitAvailablePartner)
  }

  // Life
  private totalInsuranceRecommendedClientSource = new BehaviorSubject<number>(0);
  totalInsuranceRecommendedClient = this.totalInsuranceRecommendedClientSource.asObservable();

  changTotalInsuranceRecommendedClient(totalInsuranceRecommendedClient: number) {
    this.totalInsuranceRecommendedClientSource.next(totalInsuranceRecommendedClient)
  }

  private totalInsuranceRecommendedPartnerSource = new BehaviorSubject<number>(0);
  totalInsuranceRecommendedPartner = this.totalInsuranceRecommendedPartnerSource.asObservable();

  changTotalInsuranceRecommendedPartner(totalInsuranceRecommendedPartner: number) {
    this.totalInsuranceRecommendedPartnerSource.next(totalInsuranceRecommendedPartner)
  }

  // TPD
  private totalRequiredSource = new BehaviorSubject<number>(0);
  totalRequired = this.totalRequiredSource.asObservable();

  changTotalRequired(totalRequired: number) {
    this.totalRequiredSource.next(totalRequired)
  }

  private totalRequiredPartnerSource = new BehaviorSubject<number>(0);
  totalRequiredPartner = this.totalRequiredPartnerSource.asObservable();

  changTotalRequiredPartner(totalRequiredPartner: number) {
    this.totalRequiredPartnerSource.next(totalRequiredPartner)
  }

  // Trauma
  private totalCoverRequiredClientSource = new BehaviorSubject<number>(0);
  totalCoverRequiredClient = this.totalCoverRequiredClientSource.asObservable();

  changTotalCoverRequiredClient(totalCoverRequiredClient: number) {
    this.totalCoverRequiredClientSource.next(totalCoverRequiredClient)
  }

  private totalCoverRequiredPartnerSource = new BehaviorSubject<number>(0);
  totalCoverRequiredPartner = this.totalCoverRequiredPartnerSource.asObservable();

  changTotalCoverRequiredPartner(totalCoverRequiredPartner: number) {
    this.totalCoverRequiredPartnerSource.next(totalCoverRequiredPartner)
  }

}
