import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FamilyTreeService } from 'src/app/family-tree/family-tree.service';
import { FamilyNoteAddResponse, TreeResponseObject } from 'src/app/shared/models/response.model';

@Component({
  selector: 'app-update-note',
  templateUrl: './update-note.component.html',
  styleUrls: ['./update-note.component.css']
})
export class UpdateNoteComponent implements OnInit {

  @Input() treeResponseData: TreeResponseObject;
  currentNote: string
  updatedNote: string
  isUpdating = false

  constructor(
    private familyTreeService: FamilyTreeService,
    private toastr: ToastrService,
    private preloaderService: NgxUiLoaderService
  ) { }

  ngOnInit(): void {
    this.updatedNote = this.treeResponseData.note
    this.currentNote = this.updatedNote
  }

  saveNote() {
    const familyTreeId = this.treeResponseData.familyTreeId
    this.updateFamilyNote(familyTreeId, this.currentNote)
  }

  updatingNote() {
    this.isUpdating = true
  }

  reset() {
    this.isUpdating = false
    this.currentNote = this.updatedNote
  }

  private updateFamilyNote(familyTreeId: string, note: string) {
    if(note){
      this.preloaderService.start()
      this.familyTreeService.addFamilyNote(familyTreeId , note).subscribe( 
        (res: FamilyNoteAddResponse) => {
          if (  res.success ) {
            this.toastr.success('Note updated successfully.')
            this.updatedNote = note
            this.isUpdating = false
          } else {
            this.toastr.error('Error on updating note.')
          }
          this.preloaderService.stop()
        }, err=>{   
          this.toastr.error('Error on updating note.')
          this.preloaderService.stop()
        });
    }
  }
}
