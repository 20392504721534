export class RetirementModelBaseMOdel {
    public familyTreeId: string;
    public clientRetirement: RetirementList;
    public partnerRetirement: RetirementList;
    public note: string;
}

export class RetirementModel extends RetirementModelBaseMOdel{    
       
}

export class RetirementList{
    public id: string;
    public recordStatus: number = 0;
    public clientCode: string;
    public retirementAge: number = 0;
    public combinedRetirementIncome: number = 0;
    public precentageIfPartnerDeceased: number = 0;
}
