<ng-container *ngIf="isLoaded4">
    <form [formGroup]="addRetirementForm" novalidate class="form " (ngSubmit)="onSubmitRetirement(retirementInfo)" >
      <div class="boxcolor mt-2">
        <table class="table tablecollect">
          <thead>
            <tr>
              <th class="tc1 font-weight-normal"></th>
              <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs pdAlignTableHead">{{clientFirstname}}</th>
              <ng-container *ngIf="partnerHas">
                <th class="tc1 font-weight-normal pt-0 pb-0 tab_fs">{{partnerFirstname}}</th>
              </ng-container>
            </tr>
          </thead>
          <tbody>
            
              <tr>
                <td class="tablefist tableb">Retirement Age</td>
                <td class="tableleftbedit edit-border">
                  <!-- <ng-container *ngIf="retirementInfo.clientRetirement"> -->
                    <input formControlName="retirementAge" id="retirementAge" type="number" (change)="retirementSave(retirementInfo.clientRetirement, $event)" class="form-control neweditinput" placeholder="Age">
                  <!-- </ng-container> -->
                  <!-- <ng-container *ngIf="retirementInfo.clientRetirement==null">
                    <input formControlName="retirementAge" id="retirementAge" type="number" class="form-control neweditinput" placeholder="Age">
                  </ng-container> -->
                  <!-- <div *ngIf="submitted && retirementF.retirementAge.errors" class="invalid-feedback">
                    <div *ngIf="retirementF.retirementAge.errors.required">Age is required</div>
                  </div> -->
                </td>
                <ng-container *ngIf="partnerHas">
                  <td class="tableleftbedit edit-border">
                    <!-- <ng-container *ngIf="retirementInfo.partnerRetirement"> -->
                      <input formControlName="retirementAgePartner" id="retirementAgePartner" type="number" (change)="retirementSave(retirementInfo.partnerRetirement, $event)" class="form-control neweditinput" placeholder="Age">
                    <!-- </ng-container> -->
                    <!-- <ng-container *ngIf="retirementInfo.partnerRetirement==null">
                      <input formControlName="retirementAgePartner" type="number" class="form-control neweditinput" placeholder="Age">
                    </ng-container> -->
                    <!-- <div *ngIf="submitted && retirementF.retirementAgePartner.errors" class="invalid-feedback">
                      <div *ngIf="retirementF.retirementAgePartner.errors.required">Age is required</div>
                    </div> -->
                  </td>
                </ng-container>
              </tr>
              <tr>
                <td class="tablefist tableb">Combined Retirement Income </td>
                <td class="tableleftbedit">
                  <!-- <ng-container *ngIf="retirementInfo.clientRetirement"> -->
                    <!-- <input readonly type="text" class="form-control neweditinput" placeholder="${{budgetTotalAmount | number: '1.0-0'}}"> -->
                    <input readonly type="text" [(ngModel)]="budgetTotalAmount" formControlName="budgetTotalAmount" id="combinedRetirementIncome" mask="separator.0" thousandSeparator="," (change)="retirementSave(retirementInfo.clientRetirement, $event)" class="form-control neweditinput do" placeholder="Retirement Income">
                  <!-- </ng-container> -->
                  <!-- <ng-container *ngIf="retirementInfo.clientRetirement==null">
                    <input readonly formControlName="combinedRetirementIncome" type="number" class="form-control neweditinput" placeholder="Combined retirement income">
                  </ng-container> -->
                  <!-- <div *ngIf="submitted && retirementF.combinedRetirementIncome.errors" class="invalid-feedback">
                    <div *ngIf="retirementF.combinedRetirementIncome.errors.required">Combined retirement income is required</div>
                  </div> -->
                </td>

                <ng-container *ngIf="partnerHas">
                  <td class="tableleftbedit">
                    <!-- <ng-container *ngIf="retirementInfo.partnerRetirement"> -->
                      <!-- <input readonly type="text" class="form-control neweditinput" placeholder="${{budgetTotalAmountPartner | number: '1.0-0'}}"> -->
                      <input readonly type="text" [(ngModel)]="budgetTotalAmount" formControlName="budgetTotalAmountPartner" id="combinedRetirementIncomePartner" mask="separator.0" thousandSeparator="," (change)="retirementSave(retirementInfo.partnerRetirement, $event)" class="form-control neweditinput do" placeholder="Retirement Income">
                    <!-- </ng-container> -->
                    <!-- <ng-container *ngIf="retirementInfo.partnerRetirement==null">
                      <input readonly formControlName="combinedRetirementIncomePartner" type="number" class="form-control neweditinput" placeholder="Combined retirement income">
                    </ng-container> -->
                    <!-- <div *ngIf="submitted && retirementF.combinedRetirementIncomePartner.errors" class="invalid-feedback">
                      <div *ngIf="retirementF.combinedRetirementIncomePartner.errors.required">Combined retirement income is required</div>
                    </div> -->
                  </td>
                </ng-container>
              </tr>
              <tr>                           
                <td class="tablefist tableb">Percentage if Partner Deceased (%)</td>
                <td class="tableleftbedit edit-border">
                  <!-- <ng-container *ngIf="retirementInfo.clientRetirement"> -->
                    <input value="{{precentageIfPartnerDeceased_1}}%" id="precentageIfPartnerDeceased" type="text" (change)="retirementSave(retirementInfo.clientRetirement, $event)" class="form-control neweditinput" placeholder="Partner deceased">
                  <!-- </ng-container> -->
                  <!-- <ng-container *ngIf="retirementInfo.clientRetirement==null">
                    <input formControlName="precentageIfPartnerDeceased" type="number" class="form-control neweditinput" placeholder="Partner deceased">
                  </ng-container> -->
                  <!-- <div *ngIf="submitted && retirementF.combinedRetirementIncomePartner.errors" class="invalid-feedback">
                    <div *ngIf="retirementF.combinedRetirementIncomePartner.errors.required">Partner deceased is required</div>
                  </div> -->
                </td>
                <ng-container *ngIf="partnerHas">
                  <td class="tableleftbedit edit-border">
                    <!-- <ng-container *ngIf="retirementInfo.partnerRetirement"> -->
                      <input value="{{precentageIfPartnerDeceasedPartner_1}}%" id="precentageIfPartnerDeceasedPartner" type="text" (change)="retirementSave(retirementInfo.partnerRetirement, $event)" class="form-control neweditinput" placeholder="Partner deceased">
                    <!-- </ng-container> -->
                    <!-- <ng-container *ngIf="retirementInfo.partnerRetirement==null">
                      <input formControlName="precentageIfPartnerDeceasedPartner" type="number" class="form-control neweditinput" placeholder="Partner deceased">
                    </ng-container> -->
                    <!-- <div *ngIf="submitted && retirementF.precentageIfPartnerDeceasedPartner.errors" class="invalid-feedback">
                      <div *ngIf="retirementF.precentageIfPartnerDeceasedPartner.errors.required">Partner deceased is required</div>
                    </div> -->
                  </td>
                </ng-container>
              </tr>

          </tbody>
        </table>

        <table class="table tablecollect">
          <tbody>                
            <tr>
              <td class="tableleftbedit edit-border">
                <textarea id="otherEntityNote" formControlName="note" (change)="noteSave(retirementInfo, $event)" class="form-control neweditinput-1" rows="5" placeholder="Notes" style="resize: none;"></textarea>
              </td>
            </tr>
          </tbody>
        </table>  

      </div>

      <!-- <div class="col-md-12 saveft">
        <div class="mt-2 saveftd">
            <button  class="btn savebtnft">Save</button>
        </div>
      </div> -->
    </form>
  </ng-container>

  <div class="boxcolor mt-2">
    <form>
      <!-- <div class="tablefist tableb" style="background-color: #f9f9f9;  padding-top: 10px;">
        <label class="tc1 ml-3 tab_fs">Notes</label>
        <p class="tc1 ml-3 tab_fs" style=" color: #7b7b7b "> No any notes added</p >

      </div> -->
    </form>
  </div>