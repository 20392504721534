import { Component, OnInit, Input } from '@angular/core';
import { FamilyTreeService } from 'src/app/family-tree/family-tree.service';
import { ProvisionModel } from 'src/app/shared/models/provision.model';
import { ProvisionResponse, ProposedInsuranceAddResponse, ProposedInsuranceResponse, DeleteOtherProvisionResponse } from 'src/app/shared/models/response.model';
import { HttpErrorResponse } from '@angular/common/http';
import { InsuranceComponent } from '../insurance.component';
import { SharedDataService } from 'src/app/shared/service/shared-data.service';
import { InsurancesList } from 'src/app/shared/models/insurance.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-income-protection',
  templateUrl: './income-protection.component.html',
  styleUrls: ['../../rightside.component.css']
})
export class IncomeProtectionComponent implements OnInit {

  insuranceCompOneObj: InsuranceComponent;

  @Input() family_tree_id:string;
  @Input() isLoaded3:boolean;
  @Input() isLoaded8:boolean;
  @Input() clientFirstname:string;
  @Input() partnerFirstname:string;
  @Input() insuranceInfo:any;
  @Input() proposedInsuranceInfo:any;
  @Input() client_code:string;
  @Input() clientID:string;
  @Input() partnerID:string;
  insurableIncomeClient:number;
  insurableIncomePartner:number;
  annualBenefitClient:number;
  annualBenefitPartner:number;
  clientAge:number;
  partnerAge:number;
  addProposedInsuranceData: any=[];
  
  provisionInfo:ProvisionModel;
  isLoaded:boolean=false;
  hasIPClient:boolean= false;
  hasIPPartner:boolean= false;
  benefitAvailableClient:number= 0;
  benefitAvailablePartner:number= 0;
  
  constructor(
    private familyTreeService :FamilyTreeService,
    private sharedDataService :SharedDataService,
    private toastr: ToastrService,
  ) {
    this.sharedDataService.insurableIncomeClient.subscribe(insurableIncomeClient => this.insurableIncomeClient = insurableIncomeClient);
    this.sharedDataService.insurableIncomePartner.subscribe(insurableIncomePartner => this.insurableIncomePartner = insurableIncomePartner);
    this.sharedDataService.annualBenefitClient.subscribe(annualBenefitClient => this.annualBenefitClient = annualBenefitClient);
    this.sharedDataService.annualBenefitPartner.subscribe(annualBenefitPartner => this.annualBenefitPartner = annualBenefitPartner);
    this.sharedDataService.clientAge.subscribe(clientAge => this.clientAge = clientAge);
    this.sharedDataService.partnerAge.subscribe(partnerAge => this.partnerAge = partnerAge);
    
   }

  ngOnInit(): void {
    
    this.getProvisionInfo();
    // this.insuranceCompOneObj.getInsuranceInfo();
    this.sharedDataService.benefitAvailableClient.subscribe(benefitAvailableClient => this.benefitAvailableClient = benefitAvailableClient);
    this.sharedDataService.benefitAvailablePartner.subscribe(benefitAvailablePartner => this.benefitAvailablePartner = benefitAvailablePartner);
  }

  ngOnChanges() {
    console.log('ngOnChanges', this.insuranceInfo)
    if(this.insuranceInfo && this.insuranceInfo.groupedCoversClient && this.insuranceInfo.groupedCoversClient.income.length > 0) {
      this.insuranceInfo.groupedCoversClient.income.forEach(element => {
        if(element.benefitFrequency == 1){
          element.monthlyBenefitAmount = element.benefitAmount/12 // Annually
        }
        if(element.benefitFrequency == 2){
          element.monthlyBenefitAmount = element.benefitAmount/6 // Biannually
        }
        if(element.benefitFrequency == 4){
          element.monthlyBenefitAmount = element.benefitAmount/3 // Quarterly
        }
        if(element.benefitFrequency == 12){
          element.monthlyBenefitAmount = element.benefitAmount // Monthly
        }
        if(element.benefitFrequency == 26){
          element.monthlyBenefitAmount = element.benefitAmount*2 // Fortnightly
        }
        if(element.benefitFrequency == 52){
          element.monthlyBenefitAmount = element.benefitAmount*4 // Weekly
        }
      });
    }

    if(this.insuranceInfo && this.insuranceInfo.groupedCoversPartner && this.insuranceInfo.groupedCoversPartner.income.length > 0) {
      this.insuranceInfo.groupedCoversPartner.income.forEach(element => {
        if(element.benefitFrequency == 1){
          element.monthlyBenefitAmount = element.benefitAmount/12 // Annually
        }
        if(element.benefitFrequency == 2){
          element.monthlyBenefitAmount = element.benefitAmount/6 // Biannually
        }
        if(element.benefitFrequency == 4){
          element.monthlyBenefitAmount = element.benefitAmount/3 // Quarterly
        }
        if(element.benefitFrequency == 12){
          element.monthlyBenefitAmount = element.benefitAmount // Monthly
        }
        if(element.benefitFrequency == 26){
          element.monthlyBenefitAmount = element.benefitAmount*2 // Fortnightly
        }
        if(element.benefitFrequency == 52){
          element.monthlyBenefitAmount = element.benefitAmount*4 // Weekly
        }
      });
    }

    this.annualBenefitClient = this.annualBenefitClient;
    this.annualBenefitPartner = this.annualBenefitPartner;
    this.clientID = this.clientID;
    this.partnerID = this.partnerID;

    if(this.annualBenefitClient) {
      this.benefitAvailableClient = Math.round(this.annualBenefitClient/12);
    }
    if(this.annualBenefitPartner) {
      this.benefitAvailablePartner = Math.round(this.annualBenefitPartner/12);
    }

    if(this.benefitAvailableClient < 0) {
      this.benefitAvailableClient = 0;
    } 
    if(this.benefitAvailablePartner < 0) {
      this.benefitAvailablePartner = 0;
    }

    if(this.clientID) {
      this.checkIfInsuranceTypeHas();
    }
    this.sharedDataService.changBenefitAvailableClient(this.benefitAvailableClient)
    this.sharedDataService.changBenefitAvailablePartner(this.benefitAvailablePartner)
  }

  getProvisionInfo(){
    //this.ngxService.start();
    this.familyTreeService.getProvision(this.family_tree_id).subscribe((provisionData:ProvisionResponse)=>{
      if(provisionData.success){
        this.provisionInfo = provisionData.response;

        this.isLoaded = true;
      } 
      // this.ngxService.stop();` 
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
    });
  }


  addProposedInsurance(proposedInsuranceInfo, tabType, saveType) {
    this.addProposedInsuranceData = new InsurancesList;

    this.addProposedInsuranceData.familyTreeId = proposedInsuranceInfo.familyTreeId;
    this.addProposedInsuranceData.clientCode = this.client_code;
    
    if(tabType == 'income' && saveType == 'addNew') {
      this.addProposedInsuranceData.insuranceCoverType = 0;
      this.addProposedInsuranceData.policyOwner = ((document.getElementById("policyOwnerAddIncome") as HTMLInputElement).value);
      this.addProposedInsuranceData.underwriter = ((document.getElementById("insurerAddIncome") as HTMLInputElement).value);
      this.addProposedInsuranceData.monthlyBenefit = ((document.getElementById("monthlyBenefitAddIncome") as HTMLInputElement).value);
      this.addProposedInsuranceData.waitingPeriod = this.removeComma(((document.getElementById("waitingPeriodAddIncome") as HTMLInputElement).value));
      this.addProposedInsuranceData.benefitPeriod = this.removeComma(((document.getElementById("benefitPeriodAddIncome") as HTMLInputElement).value));
      this.addProposedInsuranceData.monthlyPremium = ((document.getElementById("monthlyPremiumAddIncome") as HTMLInputElement).value);
      this.addProposedInsuranceData.structure = ((document.getElementById("structureAddIncome") as HTMLInputElement).value);
      this.addProposedInsuranceData.policyDefenition = ((document.getElementById("policyDefenitionAddIncome") as HTMLInputElement).value);
      this.addProposedInsuranceData.fundingMechanism = ((document.getElementById("fundingMechanismAddIncome") as HTMLInputElement).value);
    } else if(tabType == 'income' && saveType == 'defaultClient') {
      this.addProposedInsuranceData.insuranceCoverType = 0;
      this.addProposedInsuranceData.policyOwner = ((document.getElementById("policyOwnerAddIncomeClient") as HTMLInputElement).value);
      this.addProposedInsuranceData.underwriter = ((document.getElementById("insurerAddIncomeClient") as HTMLInputElement).value);
      this.addProposedInsuranceData.monthlyBenefit = ((document.getElementById("monthlyBenefitAddIncomeClient") as HTMLInputElement).value);
      this.addProposedInsuranceData.waitingPeriod = this.removeComma(((document.getElementById("waitingPeriodAddIncomeClient") as HTMLInputElement).value));
      this.addProposedInsuranceData.benefitPeriod = this.removeComma(((document.getElementById("benefitPeriodAddIncomeClient") as HTMLInputElement).value));
      this.addProposedInsuranceData.monthlyPremium = ((document.getElementById("monthlyPremiumAddIncomeClient") as HTMLInputElement).value);
      this.addProposedInsuranceData.structure = ((document.getElementById("structureAddIncomeClient") as HTMLInputElement).value);
      this.addProposedInsuranceData.policyDefenition = ((document.getElementById("policyDefenitionAddIncomeClient") as HTMLInputElement).value);
      this.addProposedInsuranceData.fundingMechanism = ((document.getElementById("fundingMechanismAddIncomeClient") as HTMLInputElement).value);
    } else if(tabType == 'income' && saveType == 'defaultPartner') {
      this.addProposedInsuranceData.insuranceCoverType = 0;
      this.addProposedInsuranceData.policyOwner = ((document.getElementById("policyOwnerAddIncomePartner") as HTMLInputElement).value);
      this.addProposedInsuranceData.underwriter = ((document.getElementById("insurerAddIncomePartner") as HTMLInputElement).value);
      this.addProposedInsuranceData.monthlyBenefit = ((document.getElementById("monthlyBenefitAddIncomePartner") as HTMLInputElement).value);
      this.addProposedInsuranceData.waitingPeriod = this.removeComma(((document.getElementById("waitingPeriodAddIncomePartner") as HTMLInputElement).value));
      this.addProposedInsuranceData.benefitPeriod = this.removeComma(((document.getElementById("benefitPeriodAddIncomePartner") as HTMLInputElement).value));
      this.addProposedInsuranceData.monthlyPremium = ((document.getElementById("monthlyPremiumAddIncomePartner") as HTMLInputElement).value);
      this.addProposedInsuranceData.structure = ((document.getElementById("structureAddIncomePartner") as HTMLInputElement).value);
      this.addProposedInsuranceData.policyDefenition = ((document.getElementById("policyDefenitionAddIncomePartner") as HTMLInputElement).value);
      this.addProposedInsuranceData.fundingMechanism = ((document.getElementById("fundingMechanismAddIncomePartner") as HTMLInputElement).value);
    }


    // this.addProposedInsuranceData.insuranceCoverType = ((document.getElementById("insuranceCoverTypeAdd") as HTMLInputElement).value);
    let tabTypeUpper = tabType.toUpperCase();
    if(tabType == 'income') {
      if(this.addProposedInsuranceData.policyOwner == '') {
        this.toastr.error('Error on adding proposed '+tabTypeUpper+'.'); // 'Error on policy owner.'
      } else {
        this.proposedInsuranceAdd(this.addProposedInsuranceData, tabType);
      }
    }

    if(tabType == 'life' || tabType == 'tpd' || tabType == 'trauma') {
      if(this.addProposedInsuranceData.lifeInsured == '') {
        this.toastr.error('Error on adding proposed '+tabTypeUpper+'.'); // 'Error on life insured.'
      } else {
        this.proposedInsuranceAdd(this.addProposedInsuranceData, tabType);
      }
    }
  }

  removeComma(value) {
    if(value == 'null' || value =='') return; // locale returns string of NaN if fail
    return value = value.replace(/,/g, ""); // remove commas
  }

  proposedInsuranceAdd(addProposedInsuranceData, tabType) {
    let tabTypeUpper = tabType.toUpperCase();
    this.familyTreeService.addProposedInsurance(this.addProposedInsuranceData.clientCode, this.addProposedInsuranceData.familyTreeId, addProposedInsuranceData).subscribe((data:ProposedInsuranceAddResponse)=>{
      if(data.error && !data.success){      
        this.toastr.error('Error on adding proposed '+tabTypeUpper+'.');      
      }
      else{
        this.toastr.success('Proposed '+tabTypeUpper+' added successfully.');

        if(tabType == 'income') {
          ((document.getElementById("policyOwnerAddIncome") as HTMLInputElement).value='');
          ((document.getElementById("insurerAddIncome") as HTMLInputElement).value='');
          ((document.getElementById("monthlyBenefitAddIncome") as HTMLInputElement).value='');
          ((document.getElementById("waitingPeriodAddIncome") as HTMLInputElement).value='');
          ((document.getElementById("benefitPeriodAddIncome") as HTMLInputElement).value='');
          ((document.getElementById("monthlyPremiumAddIncome") as HTMLInputElement).value='');
          ((document.getElementById("structureAddIncome") as HTMLInputElement).value='');
          ((document.getElementById("policyDefenitionAddIncome") as HTMLInputElement).value='');
          ((document.getElementById("fundingMechanismAddIncome") as HTMLInputElement).value='');
        }
        
      }

      this.getProposedInsuranceInfo();
    },err=>{   
      console.log(err);
      this.toastr.error('Error on adding proposed '+tabTypeUpper+'.');
    });
  }

  
  getProposedInsuranceInfo() {
    this.familyTreeService.getProposedInsurance(this.family_tree_id).subscribe((proposedInsuranceData:ProposedInsuranceResponse)=>{
      if(proposedInsuranceData.success){
        this.proposedInsuranceInfo = proposedInsuranceData.response;
        this.checkIfInsuranceTypeHas();
        this.isLoaded8 = true;
      } 
      // this.ngxService.stop(); 
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
    });
  }


  deleteProposedInsurance(proposedInsurance) {
    let id = proposedInsurance.id;

    this.familyTreeService.deleteProposedInsurance(id).subscribe((data:DeleteOtherProvisionResponse)=>{
      if(data.error && !data.success){      
        this.toastr.error("Error on deleting proposed insurance.");      
      }
      else{
        this.getProposedInsuranceInfo();
        this.toastr.success("Proposed insurance deleted successfully.");
      }
    },err=>{   
      console.log(err);
      this.toastr.error("Error on deleting proposed insurance.");
    });    
  }


  proposedInsuranceSave(proposedInsurance, event: any){
    // console.log(spouseDeathOrTPDS);
    let value = event.target.value;
    let inputId = event.target.id;
    // console.log(value);
    // console.log(inputId);

    this.addProposedInsuranceData = new InsurancesList;
    this.addProposedInsuranceData.insuranceCoverType = proposedInsurance.insuranceCoverType;
    this.addProposedInsuranceData.policyOwner = proposedInsurance.policyOwner;
    this.addProposedInsuranceData.underwriter = proposedInsurance.underwriter;
    this.addProposedInsuranceData.monthlyBenefit = proposedInsurance.monthlyBenefit;
    this.addProposedInsuranceData.waitingPeriod = proposedInsurance.waitingPeriod;
    this.addProposedInsuranceData.benefitPeriod = proposedInsurance.benefitPeriod;
    this.addProposedInsuranceData.monthlyPremium = proposedInsurance.monthlyPremium;
    this.addProposedInsuranceData.structure = proposedInsurance.structure;
    this.addProposedInsuranceData.policyDefenition = proposedInsurance.policyDefenition;
    this.addProposedInsuranceData.fundingMechanism = proposedInsurance.fundingMechanism;

    // this.willExecutorsAdd.clientCode = (userType==1)?this.client_code:this.facIdPartner;
    //this.willExecutorsAdd.clientCode = proposedInsurance.clientCode;  // this.client_code

    if(inputId == 'insuranceCoverType') {
      this.addProposedInsuranceData.insuranceCoverType = value;
    }
    if(inputId == 'policyOwner') {
      this.addProposedInsuranceData.policyOwner = value;
    }
    if(inputId == 'monthlyBenefit') {
      this.addProposedInsuranceData.monthlyBenefit = value;
    }
    if(inputId == 'waitingPeriod') {
      this.addProposedInsuranceData.waitingPeriod = this.removeComma(value);
    }
    if(inputId == 'benefitPeriod') {
      this.addProposedInsuranceData.benefitPeriod = this.removeComma(value);
    }
    if(inputId == 'monthlyPremium') {
      this.addProposedInsuranceData.monthlyPremium = value;
    }
    if(inputId == 'structure') {
      this.addProposedInsuranceData.structure = value;
    }
    if(inputId == 'policyDefenition') {
      this.addProposedInsuranceData.policyDefenition = value;
    }
    if(inputId == 'fundingMechanism') {
      this.addProposedInsuranceData.fundingMechanism = value;
    }
    
    // console.log(this.addProposedInsuranceData);
    this.familyTreeService.editProposedInsurance(proposedInsurance.id, this.addProposedInsuranceData).subscribe((data:ProposedInsuranceAddResponse)=>{
      if(data.error && !data.success){      
        this.toastr.error('Error on updating proposed insurance.');      
      }
      else{
        this.toastr.success('Proposed insurance updated successfully.');
      }

      this.getProposedInsuranceInfo();
    },err=>{   
      console.log(err);
      this.toastr.error('Error on updating proposed insurance.');
    });
  }

  checkIfInsuranceTypeHas(){
    var clientID = this.clientID;
    var partnerID = this.partnerID;

    if(this.proposedInsuranceInfo){
      var isPresent1 = false;
      var isPresent2 = false;
      
      if(this.proposedInsuranceInfo.clientInsurances.length > 0) {
        var isCoverType = this.proposedInsuranceInfo.clientInsurances.some(function(el){ return el.insuranceCoverType === 0 });
        var target =  this.proposedInsuranceInfo.clientInsurances.filter(function(el) { return el.insuranceCoverType == 0;});
        if (isCoverType) {
          
          var isClient = target.some(function(el){ return el.policyOwner === clientID });
          var isPresent = target.some(function(el){ return el.policyOwner === partnerID });
          if (isClient) {
            isPresent1 = true;
          } else {
            isPresent1 = false;
          }
          if (isPresent) {
            isPresent2 = true;
          } else {
            isPresent2 = false;
          }
        } else {
          isPresent1 = false;
          isPresent2 = false;
        }
      }

      if(this.proposedInsuranceInfo.partnerInsurances.length > 0) {
        var isCoverType = this.proposedInsuranceInfo.partnerInsurances.some(function(el){ return el.insuranceCoverType === 0 });
        var target =  this.proposedInsuranceInfo.partnerInsurances.filter(function(el) { return el.insuranceCoverType == 0;});

        if (isCoverType) {
          var isClient = target.some(function(el){ return el.policyOwner === clientID });
          var isPresent = target.some(function(el){ return el.policyOwner === partnerID });
          if (isClient) {
            isPresent1 = true;
          } else {
            isPresent1 = false;
          }
          if (isPresent) {
            isPresent2 = true;
          } else {
            isPresent2 = false;
          }
        } else {
          isPresent1 = false;
          isPresent2 = false;
        }
      }

      if(isPresent1) {
        this.hasIPClient = true;
      } else {
        this.hasIPClient = false;
      }

      if(isPresent2) {
        this.hasIPPartner = true;
      } else {
        this.hasIPPartner = false;
      }
    }
}

}
