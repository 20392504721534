import { Component, OnInit, Input } from '@angular/core';
import { FamilyTreeService } from '../../family-tree.service';
import { ToastrService } from 'ngx-toastr';
import { XplanChoicesResponse, ProposedInsuranceResponse, DeleteOtherProvisionResponse, ProposedInsuranceAddResponse, InsuranceResponse } from 'src/app/shared/models/response.model';
import { InsurancesList } from 'src/app/shared/models/insurance.model';
import { FinancesComponent } from '../finances/finances.component';
import { ProvisionsComponent } from '../provisions/provisions.component';
import { HttpErrorResponse } from '@angular/common/http';
import { XplanCoverGrouped } from 'src/app/shared/models/xplan-cover-grouped.model';
import { ProposedInsuranceModel } from 'src/app/shared/models/proposed-insurance.model';
import { SharedDataService } from 'src/app/shared/service/shared-data.service';
import { InsuranceModel } from 'src/app/shared/models/insurance.model';
import { XplanChoices } from 'src/app/shared/models/xplan-choices.model';

@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['../rightside.component.css']
})
export class InsuranceComponent implements OnInit {

  financesCompOneObj: FinancesComponent;
  provisionsCompOneObj:ProvisionsComponent;

  isLoaded3: boolean;
  isLoaded8: boolean;

  insurance_type_list_filtered: any=[];
  proposedInsuranceInfo:ProposedInsuranceModel;
  addProposedInsuranceData: any=[];

  insuranceInfo:InsuranceModel;
  monthlyPremiumLifePartner: number = 0;
  choicesList : XplanChoices[];
  insurance_type_list:any;

  @Input() client_id: string;
  family_tree_id: string = "";
  client_code: string;
  clientFirstname:string;
  partnerFirstname:string;
  provisionInfo:any;
  partnerID:string = "";
  clientID:string = "";

  constructor(
    private familyTreeService :FamilyTreeService,
    private toastr: ToastrService,
    private sharedDataService: SharedDataService,
  ) { 
    this.sharedDataService.familyTreeId.subscribe(family_tree_id => this.family_tree_id = family_tree_id);
    this.sharedDataService.client_code.subscribe(client_code => this.client_code = client_code);
    this.sharedDataService.clientFirstname.subscribe(clientFirstname => this.clientFirstname = clientFirstname);
    this.sharedDataService.partnerFirstname.subscribe(partnerFirstname => this.partnerFirstname = partnerFirstname);
    this.sharedDataService.provisionInfo.subscribe(provisionInfo => this.provisionInfo = provisionInfo);
    this.sharedDataService.partnerID.subscribe(partnerID => this.partnerID = partnerID);
    this.sharedDataService.clientID.subscribe(clientID => this.clientID = clientID);
  }

  ngOnInit(): void {
    
    this.sharedDataService.insuranceInfo.subscribe(insuranceInfo => this.insuranceInfo = insuranceInfo);
    this.getChoiceList();
    this.getInsuranceInfo();
  }


  getInsuranceInfo(){
    // this.ngxService.start();
    
    this.monthlyPremiumLifePartner = 0;
    this.familyTreeService.getInsurance(this.client_id).subscribe((insuranceData:InsuranceResponse)=>{
      if(insuranceData.success){
        this.insuranceInfo = insuranceData.response;
        this.sharedDataService.changInsuranceInfo(this.insuranceInfo);
        
        if(this.insuranceInfo.clientInsurances){
          this.insuranceInfo.groupedCoversClient =  this.groupCovers(this.insuranceInfo.clientInsurances);
        }

        if(this.insuranceInfo.partnerInsurances){
          this.insuranceInfo.groupedCoversPartner =  this.groupCovers(this.insuranceInfo.partnerInsurances);
        }
        // console.log(this.insuranceInfo);

        
        // this.financesCompOneObj.getFinancesInfo();
        // this.provisionsCompOneObj.getProvisionInfo();
        this.getProposedInsuranceInfo();
        
        this.isLoaded3 = true;
      } 
      // this.ngxService.stop(); 
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });
  }


  getProposedInsuranceInfo() {
    this.familyTreeService.getProposedInsurance(this.family_tree_id).subscribe((proposedInsuranceData:ProposedInsuranceResponse)=>{
      if(proposedInsuranceData.success){
        this.proposedInsuranceInfo = proposedInsuranceData.response;
        
        this.isLoaded8 = true;
      } 
      // this.ngxService.stop(); 
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
    });
  }

  groupCovers(xFacInsuranceCovers){

    let grouped = new XplanCoverGrouped();
    xFacInsuranceCovers.forEach(element => {

      element.monthlyPremium = element.totalPremium/12;
      if(element.insuranceCoverType){
        if(element.insuranceCoverType.indexOf("l")==0){

          let choice = this.insurance_type_list_filtered.filter(choice => choice.key === element.insuranceCoverType);
          if(choice && choice[0]) {
            element.insuranceCoverTypeName = choice[0].value;
          }

          grouped.life.push(element);
          // console.log("life-----");
          // console.log(grouped.life);
        }
        else if(element.insuranceCoverType.indexOf("d")==0){

          let choice = this.insurance_type_list_filtered.filter(choice => choice.key === element.insuranceCoverType);
          if(choice && choice[0]) {
            element.insuranceCoverTypeName = choice[0].value;
          }

          grouped.tpd.push(element);
          // console.log("tpd-----");
          // console.log(grouped.tpd);
        }
        else if(element.insuranceCoverType.indexOf("t")==0){

          let choice = this.insurance_type_list_filtered.filter(choice => choice.key === element.insuranceCoverType);
          if(choice && choice[0]) {
            element.insuranceCoverTypeName = choice[0].value;
          }

          grouped.trauma.push(element);
          // console.log("trauma-----");
          // console.log(grouped.trauma);
        }
        else if(element.insuranceCoverType.indexOf("i")==0){

          let choice = this.insurance_type_list_filtered.filter(choice => choice.key === element.insuranceCoverType);
          if(choice && choice[0]) {
            element.insuranceCoverTypeName = choice[0].value;
          }

          grouped.income.push(element);
          // console.log("income-----");
          // console.log(grouped.income);
        }
        else if(element.insuranceCoverType.indexOf("b")==0){

          let choice = this.insurance_type_list_filtered.filter(choice => choice.key === element.insuranceCoverType);
          if(choice && choice[0]) {
            element.insuranceCoverTypeName = choice[0].value;
          }

          grouped.business.push(element);
          // console.log("business-----");
          // console.log(grouped.business);
        }
        else if(element.insuranceCoverType.indexOf("o")==0){

          let choice = this.insurance_type_list_filtered.filter(choice => choice.key === element.insuranceCoverType);
          if(choice && choice[0]) {
            element.insuranceCoverTypeName = choice[0].value;
          }

          grouped.additional.push(element);
          // console.log("additional-----");
          // console.log(grouped.additional);
        }
        else if(element.insuranceCoverType.indexOf("x")==0){

          let choice = this.insurance_type_list_filtered.filter(choice => choice.key === element.insuranceCoverType);
          if(choice && choice[0]) {
            element.insuranceCoverTypeName = choice[0].value;
          }

          grouped.severity.push(element);
          // console.log("severity-----");
          // console.log(grouped.severity);
        }
        
      }
    });  
    return grouped;
  }

  getChoiceList(){
    this.familyTreeService.getChoiceList().subscribe((clientData:XplanChoicesResponse)=>{
      if(clientData.success){
        this.choicesList = clientData.response;  
        
        this.insurance_type_list = this.sortByKey(this.choicesList.filter(choice => choice.choiceType === 17));

        this.filterInsuranceTypeList(this.insurance_type_list);    
        
        //console.log(this.contact_type_list);      
      } 
      
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message); 
      });
  }


  filterInsuranceTypeList(insurance_type_list){
    insurance_type_list.forEach(element => {
      if(element.key.indexOf("l")==0){
        element.insurance_type = "life";
        element.insurance_value = "Life Cover";
        this.insurance_type_list_filtered.push(element);
      }
      else if(element.key.indexOf("d")==0){
        element.insurance_type = "tpd";
        element.insurance_value = "TPD Cover";
        this.insurance_type_list_filtered.push(element);
      }
      else if(element.key.indexOf("t")==0){
        element.insurance_type = "trauma";
        element.insurance_value = "Trauma Cover";
        this.insurance_type_list_filtered.push(element);
      }
      else if(element.key.indexOf("i")==0){
        element.insurance_type = "income";
        element.insurance_value = "Income Cover";
        this.insurance_type_list_filtered.push(element);
      }
      else if(element.key.indexOf("b")==0){
        element.insurance_type = "business";
        element.insurance_value = "Business Cover";
        this.insurance_type_list_filtered.push(element);
      }
      else if(element.key.indexOf("o")==0){
        element.insurance_type = "additional";
        element.insurance_value = "Additional Cover";
        this.insurance_type_list_filtered.push(element);
      }
      else if(element.key.indexOf("x")==0){
        element.insurance_type = "severity";
        element.insurance_value = "Severity Cover";
        this.insurance_type_list_filtered.push(element);
      }
      // insurance_type_list_filtered
    });
  }

  sortByKey(choiceArray){
    return choiceArray.sort((n1,n2) => {
      if (n1.key > n2.key) {
          return 1;
      }
  
      if (n1.key < n2.key) {
          return -1;
      }                                      
      return 0;
    });
  }




  

}
