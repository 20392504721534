<div class="note-wr pb-4 px-3">
    <div class="btn-wr d-flex justify-content-end align-items-center mb-1">
        <span *ngIf="isUpdating" class="reset-btn p-1 mr-auto" (click)="reset()">[ Reset ]</span>
        <span *ngIf="isUpdating" class="save-btn p-1" (click)="saveNote()">Save Now</span>
    </div>
    <div class="form-row">
        <div class="form-group col-md-12 m-0">
            <textarea 
                [(ngModel)]="currentNote"
                (keyup)="updatingNote()" 
                rows="5" 
                placeholder="Notes"
                class="form-control"> 
            </textarea>
        </div>
    </div>
</div>