import { Component, OnInit, Input } from '@angular/core';
import { ProvisionsComponent } from '../provisions.component';
import { FamilyTreeService } from 'src/app/family-tree/family-tree.service';
import { SharedDataService } from 'src/app/shared/service/shared-data.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DependentSupportsList, ProvisionModel, OtherProvisionList } from 'src/app/shared/models/provision.model';
import { DependantSupportAddResponse, ProvisionResponse } from 'src/app/shared/models/response.model';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-dependant-support',
  templateUrl: './dependant-support.component.html',
  styleUrls: ['../../rightside.component.css']
})
export class DependantSupportComponent implements OnInit {

  provisionsCompOneObj:ProvisionsComponent;
  dependentSupporAdd:DependentSupportsList;

  provisionInfo:ProvisionModel;
  @Input() family_tree_id:string;
  otherProvisionList: any=[];
  addProvisionForm: FormGroup;
  isLoaded:boolean=false;
  data:any

  constructor(
    private familyTreeService :FamilyTreeService,
    private sharedDataService: SharedDataService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.provisionsCompOneObj = new ProvisionsComponent(this.familyTreeService, this.sharedDataService, this.toastr );
    this.getProvisionInfo();
  }


  getProvisionInfo(){
    //this.ngxService.start();
    this.familyTreeService.getProvision(this.family_tree_id).subscribe((provisionData:ProvisionResponse)=>{
      if(provisionData.success){
        this.provisionInfo = provisionData.response;
        
        this.otherProvisionList = new OtherProvisionList;

      } 
      // this.ngxService.stop();
      this.isLoaded = true;
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
    });
  }

  dependantSupportSave(dependentSupports, event: any){
    let value = event.target.value;
    let inputId = event.target.id;
    
    this.dependentSupporAdd = new DependentSupportsList;
    this.dependentSupporAdd.dependentId = dependentSupports.dependentId;
    if(inputId == 'age') {
      this.dependentSupporAdd.age = value;
      this.dependentSupporAdd.yearAmount = dependentSupports.yearAmount;
    } 
    if(inputId == 'yearAmount') {
      this.dependentSupporAdd.age = dependentSupports.age;
      this.dependentSupporAdd.yearAmount = value;
    }


    this.familyTreeService.editDependantSupport(dependentSupports.id, this.dependentSupporAdd).subscribe((data:DependantSupportAddResponse)=>{
      if(data.error && !data.success){      
        this.toastr.error('Error on updating dependant support.');      
      }
      else{
        this.toastr.success('Dependant support updated successfully.');
      }

      this.provisionsCompOneObj.getProvisionInfo();
    },err=>{   
      console.log(err);
      this.toastr.error('Error on updating dependant support.');
    });
  }

}
