<ng-container *ngIf="isLoaded6">
    <!-- <div class="pdLeftforWill" >
      <form>
        <div class="row">
          <div class="form-row col-md-6 ">   
            <div class="form-group input-group">
              <label for="inputEmail4" class="tc2 ">Executor For {{clientFirstname}}</label> 
            </div>
            <ng-container *ngIf="willInfo.clientExecutors.length>0">
              <ng-container *ngFor="let executors of willInfo.clientExecutors; let i = index">
                <div class="form-group input-group">
                  <ng-container *ngIf="executors.type==0">
                    <input readonly name="" class="form-control shadownone" placeholder="{{executors.name}} (Initial)" type="text">
                  </ng-container>
                  <ng-container *ngIf="executors.type==1">
                    <input readonly name="" class="form-control shadownone" placeholder="{{executors.name}} (Substitute)" type="text">
                  </ng-container>
                  <ng-container *ngIf="executors.type==2">
                    <input readonly name="" class="form-control shadownone" placeholder="{{executors.name}} (Join)" type="text">
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="willInfo.clientExecutors.length==0">
              <div class="form-group input-group">
                No data
              </div>
            </ng-container>

          </div>
          <div class="form-row col-md-6 pdRightforWill" >
            <div class="form-group input-group">  
              <label for="inputEmail4" class="tc2 ">Executor For {{partnerFirstname}}</label>
            </div>
            <ng-container *ngIf="willInfo.partnerExecutors.length>0">
              <ng-container *ngFor="let executors of willInfo.partnerExecutors; let i = index">
                <div class="form-group input-group">
                  <ng-container *ngIf="executors.type==0">
                    <input readonly name="" class="form-control shadownone" placeholder="{{executors.name}} (Initial)" type="text">
                  </ng-container>
                  <ng-container *ngIf="executors.type==1">
                    <input readonly name="" class="form-control shadownone" placeholder="{{executors.name}} (Substitute)" type="text">
                  </ng-container>
                  <ng-container *ngIf="executors.type==2">
                    <input readonly name="" class="form-control shadownone" placeholder="{{executors.name}} (Join)" type="text">
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="willInfo.partnerExecutors.length==0">
              <div class="form-group input-group">
                No data
              </div>
            </ng-container>
          </div>
        </div>

      </form>
    </div> -->

    
            
    <!-- Executor -->
    <app-executor [family_tree_id]="family_tree_id" [client_code]="client_code" [partnerClientCode]="partnerClientCode" [clientFirstname]="clientFirstname" [partnerFirstname]="partnerFirstname" [partnerHas]="partnerHas" [willInfo]="willInfo" ></app-executor>
    <!-- End of Executor -->


    <!-- Residual Bequests -->
    <app-residual-bequests [family_tree_id]="family_tree_id" [clientFirstname]="clientFirstname" [partnerFirstname]="partnerFirstname" [partnerHas]="partnerHas" [willInfo]="willInfo" ></app-residual-bequests>
    <!-- End of Residual Bequests -->


    <!-- Calamity Beneficiaries -->
    <app-calamity-beneficiaries [family_tree_id]="family_tree_id" [client_code]="client_code" [partnerClientCode]="partnerClientCode" [clientFirstname]="clientFirstname" [partnerFirstname]="partnerFirstname" [partnerHas]="partnerHas" [willInfo]="willInfo" [showCBUsers]="showCBUsers" ></app-calamity-beneficiaries>
    <!-- End of Calamity Beneficiaries -->


    <!-- Other Entities -->
    <app-other-entities [family_tree_id]="family_tree_id" [client_code]="client_code" [partnerClientCode]="partnerClientCode" [clientFirstname]="clientFirstname" [partnerFirstname]="partnerFirstname" [partnerHas]="partnerHas" [willInfo]="willInfo" ></app-other-entities>
    <!-- End of Other Entities -->


    <!-- Lifetime Arrangements -->
    <app-lifetime-arrangements [family_tree_id]="family_tree_id" [client_code]="client_code" [partnerClientCode]="partnerClientCode" [clientFirstname]="clientFirstname" [partnerFirstname]="partnerFirstname" [partnerHas]="partnerHas" [willInfo]="willInfo" ></app-lifetime-arrangements>
    <!-- End of Lifetime Arrangements -->
    

    
  </ng-container>
