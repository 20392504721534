import { Pipe, PipeTransform } from '@angular/core';
import { GetTreeModel, RelationIdsModel, Relations, RelationsModel } from '../models/get-tree.model';
import { DistributionGraphPipe } from './distribution-graph.pipe';
import { environment } from 'src/environments/environment';
import { GenderType, GraphType, RelationType, TreeRole } from 'src/app/family-tree/leftside/distibution/distibution.enum';
import { NgIf } from '@angular/common';
import { node } from 'canvg/lib/presets';
import { each } from 'jquery';

@Pipe({
  name: 'familyNodes'
})
export class FamilyNodesPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return null;
  }


  sortMembers(members: GetTreeModel[]) {

    var membersArray = members
    let newArray = []
    let owner = this.getOwner(members)
    let spouse = this.getMainSpouse(members)

    if (owner) {
      let ownerIndex = membersArray.findIndex((element: GetTreeModel) => (element.id == owner.id))
      if (ownerIndex >= 0) {
        newArray.push(owner)
        let removeItem = this.removeItem(ownerIndex, membersArray)
        membersArray = removeItem

      }
    }

    if (spouse) {
      let spouseIndex = membersArray.findIndex((element: GetTreeModel) => (element.id == spouse.id))
      if (spouseIndex >= 0) {
        newArray.push(spouse)
        let removeItem = this.removeItem(spouseIndex, membersArray)
        membersArray = removeItem

      }
    }


    membersArray.forEach(element => {

      newArray.push(element)

    });

    return newArray




  }


  generateRelationshipOptionList(selectedRelationship: String, member: GetTreeModel, members: GetTreeModel[]) {

    var familyArray = members

    //remove self // divorcees //  parents 
    let excliudedLiast = familyArray.filter((element: GetTreeModel) => (element.relation == "Divorcee" || element.relation == "Parents" || element.id == member?.id))
    excliudedLiast.forEach(excluded => {

      let divorceesIndex = familyArray.findIndex((element: GetTreeModel) => (element.id == excluded.id))
      if (divorceesIndex >= 0) {

        let removeItem = this.removeItem(divorceesIndex, familyArray)
        // console.log("divorceesIndex",familyArray)
        familyArray = removeItem
        // console.log("divorceesIndexR",familyArray)
      }
    });


    //  return members

    if (selectedRelationship == "Spouse") {

      //'getspouses list/ remove their partners from list (People who already have spouses)
      let spousesList = familyArray.filter((element: GetTreeModel) => (element.relation == "Spouse"))
      spousesList.forEach(spouse => {

        if (spouse && spouse.relation.length > 0) {

          let spouseOfIndex = familyArray.findIndex((element: GetTreeModel) => element.id == spouse.relations[0].relationDependantId)
          if (spouseOfIndex >= 0) {

            let removeItem = this.removeItem(spouseOfIndex, familyArray)
            familyArray = removeItem
            //familyArray.splice(spouseOfIndex,1).slice(0);

          }

        }
      });


    }
    else if (selectedRelationship == "Divorcee") {

      let DivorceeList = familyArray.filter((element: GetTreeModel) => (element.relation == "Divorcee"))
      DivorceeList.forEach(spouse => {

        if (spouse && spouse.relation.length > 0) {

          let spouseOfIndex = familyArray.findIndex((element: GetTreeModel) => element.id == spouse.relations[0].relationDependantId)
          if (spouseOfIndex >= 0) {

            let removeItem = this.removeItem(spouseOfIndex, familyArray)
            familyArray = removeItem

          }

        }
      });
      /// if user is main and divocess count is 0

    }
    else if (selectedRelationship == "Parent") {


      familyArray.length = 0
      let getOWner = this.getOwner(members)
      let spouse = this.getMainSpouse(members)
      familyArray.push(getOWner, spouse)
      /// if user is main and spouse parent count is less than 2

    }



    var relationList = []//familyArray.map(a => new RelationsModel())
    familyArray.forEach(r => {

      var relationModel = new RelationsModel()
      relationModel.name = (r || {}).firstName
      relationModel.relationIDs = []

      const idsArr = new RelationIdsModel()
      idsArr.dependantId = member.id ? member.id : null
      idsArr.relationDependantId = (r || {}).id
      relationModel.relationIDs.push(idsArr)

      relationList.push(relationModel)

    });


    if (selectedRelationship == "Child") {

      let spousesList = familyArray.filter((element: GetTreeModel) => (element.relation == "Spouse"))

      spousesList.forEach(couple => {

        let partnerID = couple.relations[0].relationDependantId
        //let second  =  couple.relations[1].relationDependantId

        let partner = members.find((element: GetTreeModel) => element.id == partnerID)

        if (couple != null && partner != null) {

          var relationModel = new RelationsModel()
          relationModel.name = partner.firstName + " & " + couple.firstName
          relationModel.relationIDs = []

          const fpIds = new RelationIdsModel()
          fpIds.dependantId = member.id ? member.id : null
          fpIds.relationDependantId = partner.id
          relationModel.relationIDs.push(fpIds)

          const spIds = new RelationIdsModel()
          spIds.dependantId = member.id ? member.id : null
          spIds.relationDependantId = couple.id
          relationModel.relationIDs.push(spIds)

          relationList.push(relationModel)

        }
      });

      let ownerParents = this.getOwnerParents(members)
      if (ownerParents.length == 1) {


        let parent1 = ownerParents[0]

        var relationModel = new RelationsModel()
        relationModel.name = parent1.firstName
        relationModel.relationIDs = []

        const fpIds = new RelationIdsModel()
        fpIds.dependantId = member.id ? member.id : null
        fpIds.relationDependantId = parent1.id
        relationModel.relationIDs.push(fpIds)

        // relationList.push(relationModel)


      }
      else if (ownerParents.length == 2) {


        let parent1 = ownerParents[0]
        let parent2 = ownerParents[1]

        var relationModel = new RelationsModel()
        relationModel.name = parent1.firstName + " & " + parent2.firstName
        relationModel.relationIDs = []

        const fpIds = new RelationIdsModel()
        fpIds.dependantId = member.id ? member.id : null
        fpIds.relationDependantId = parent1.id
        relationModel.relationIDs.push(fpIds)

        const spIds = new RelationIdsModel()
        spIds.dependantId = member.id ? member.id : null
        spIds.relationDependantId = parent2.id
        relationModel.relationIDs.push(spIds)

        relationList.push(relationModel)



      }


      let spouseParents = this.getSpouseParents(members)
      if (spouseParents.length == 1) {


        let parent1 = spouseParents[0]

        var relationModel = new RelationsModel()
        relationModel.name = parent1.firstName
        relationModel.relationIDs = []

        const fpIds = new RelationIdsModel()
        fpIds.dependantId = member.id ? member.id : null
        fpIds.relationDependantId = parent1.id
        relationModel.relationIDs.push(fpIds)

        // relationList.push(relationModel)


      }
      else if (spouseParents.length == 2) {


        let parent1 = spouseParents[0]
        let parent2 = spouseParents[1]

        var relationModel = new RelationsModel()
        relationModel.name = parent1.firstName + " & " + parent2.firstName
        relationModel.relationIDs = []

        const fpIds = new RelationIdsModel()
        fpIds.dependantId = member.id ? member.id : null
        fpIds.relationDependantId = parent1.id
        relationModel.relationIDs.push(fpIds)

        const spIds = new RelationIdsModel()
        spIds.dependantId = member.id ? member.id : null
        spIds.relationDependantId = parent2.id
        relationModel.relationIDs.push(spIds)

        relationList.push(relationModel)



      }



    }


    return relationList


  }

  removeItem(index, array) {
    var tempList = array;
    let uploadFile = [];

    for (var j = 0; j < tempList.length; j++) {
      if (j != index)
        uploadFile.push(tempList[j]);
    }

    return uploadFile
  }



  getCurrentRepaltionShip(member: GetTreeModel, members: GetTreeModel[]) {

    let relations = member.relations
    if (relations.length > 0) {

      if (relations.length == 1) {


        let firstPerson = members.find((element: GetTreeModel) => element.id == relations[0].relationDependantId)

        if (firstPerson != null) {

          var relationModel = new RelationsModel()
          relationModel.name = firstPerson.firstName
          // relationModel.relationIDs = [firstPerson.id]
          relationModel.relationIDs = []
          const idsArr = new RelationIdsModel()
          idsArr.dependantId = member.id
          idsArr.relationDependantId = firstPerson.id
          relationModel.relationIDs.push(idsArr)

          return relationModel

        }

      }
      if (relations.length == 2) {



        let firstPerson = members.find((element: GetTreeModel) => element.id == relations[0].relationDependantId)
        let secondPerson = members.find((element: GetTreeModel) => element.id == relations[1].relationDependantId)

        if (firstPerson != null && secondPerson != null) {



          var relationModel = new RelationsModel()
          relationModel.name = firstPerson.firstName + " & " + secondPerson.firstName
          // relationModel.relationIDs = [firstPerson.id,secondPerson.id]
          relationModel.relationIDs = []
          const fpIds = new RelationIdsModel()
          fpIds.dependantId = member.id
          fpIds.relationDependantId = firstPerson.id
          relationModel.relationIDs.push(fpIds)

          const spIds = new RelationIdsModel()
          spIds.dependantId = member.id
          spIds.relationDependantId = secondPerson.id
          relationModel.relationIDs.push(spIds)
          console.log("getCurrentRepaltionShip->>membre", member.firstName)
          console.log("getCurrentRepaltionShip->>relationship", relationModel.name)
          //console.log("getCurrentRepaltionShip",secondPerson)
          return relationModel

        }


      }


    }

    return null

  }

  generateDistributionTree(members: GetTreeModel[]) {

    // Initial Tree Node
    const rootNode = {
      id: null,
      hidden: true,
      no_parent: true,
      children: [],
    };

    let sibilings = []


    //  add owner divorcee
    // let ownerDivocreeTree = this.getOwnerDivorceeParentTree(members)
    // if  (ownerDivocreeTree != null){
    //   rootNode.children.push(ownerDivocreeTree)
    // }
    //


    //owner tree
    let ownerParentTreeResult = this.getOwnerParentTree(members)


    ownerParentTreeResult.nodes.forEach(element => {

      rootNode.children.push(element)

    });
    ownerParentTreeResult.sibilings.forEach(element => {
      console.log("ownerParentTreeResult", element)
      sibilings.push(element)

    });




    //push commn children to hidden node
    let comonChildren = this.getOwnerCommonChildren(members)
    console.log("getownerCommonChildTree", comonChildren)
    if (comonChildren != null) {

      let getownerCommonChildTree = this.getownerCommonChildTree(members, comonChildren)

      let hiddenNode = this.createHiddenNode()

      getownerCommonChildTree.nodes.forEach(element => {
        //set divorcee for owner common children
        const devorcee = this.getDivorcee(element?.id, members)
        const devorceeNode = this.createNode(devorcee, false, true, RelationType.Divorcee)
        if (devorcee && !element.no_parent) {
          hiddenNode.children.push(devorceeNode)
        }
        hiddenNode.children.push(element)
        if (devorcee && element.no_parent) {
          hiddenNode.children.push(devorceeNode)
        }
      });
      getownerCommonChildTree.sibilings.forEach(element => {

        sibilings.push(element)

      });

      if (this.isParentsExistsForMainUsers(members)) {

        let hiddenParentNode = this.createHiddenNode()
        hiddenParentNode.children.push(hiddenNode)
        rootNode.children.push(hiddenParentNode)

      }
      else {

        rootNode.children.push(hiddenNode)

      }


    }



    //spouse tree
    let spouseParentResult = this.getSpouseParentTree(members)

    spouseParentResult.nodes.forEach(element => {

      rootNode.children.push(element)

    });
    spouseParentResult.sibilings.forEach(element => {

      sibilings.push(element)

    });

    console.log("rootNode4", rootNode)

    //add owner divorcee
    // let spouseDivocreeTree = this.getSpouseDivorceeParentTree(members)
    // if  (spouseDivocreeTree != null){

    //     rootNode.children.push(spouseDivocreeTree)

    // }


    console.log("rootNode5", rootNode)



    //linkMainNodes
    let mainNodeLinks = this.linkMainNodes(members)
    sibilings.push(mainNodeLinks)


    return {
      nodes: rootNode,
      sibilings: sibilings,
    };


  }



  getownerSingleChildTree(members: GetTreeModel[]) {


    let owner = this.getOwner(members)

    let ownerSingleChildren = this.getSingleChildren(owner.id, members)
    var nodes = []
    let sibilings = []

    if (ownerSingleChildren != null) {

      ownerSingleChildren.forEach(child => {

        let childTreeResult = this.getChildTree(members, child)
        childTreeResult.nodes.forEach(element => {

          nodes.push(element)

        });
        childTreeResult.sibilings.forEach(element => {

          sibilings.push(element)

        });


      })

    }



    return {
      nodes: nodes,
      sibilings: sibilings,
    };





  }


  getChildSpouseTree(members: GetTreeModel[], element) {

    var nodes = []
    let sibilings = []



        let childTreeResult = this.getChildTree(members, element)
       
        childTreeResult.sibilings.forEach(element => {

          sibilings.push(element)

        });

    return {
      nodes: nodes,
      sibilings: sibilings,
    };
  }


  getSingleChildTreeMember(member, members) {
    let spouse = this.getSpouse((member || {}).id, members);
    let spouseSingleChildren
    if (spouse) {
      spouseSingleChildren = this.getSingleChildren((spouse || {}).id, members)
    }
    var nodes = []
    let sibilings = []
    if (spouseSingleChildren != null) {

      spouseSingleChildren.forEach(child => {

        let childTreeResult = this.getChildTree(members, child)
        childTreeResult.nodes.forEach(element => {
          nodes.push(element)
        });
        childTreeResult.sibilings.forEach(element => {
          sibilings.push(element)
        });
      })
    }
    return {
      nodes: nodes,
      sibilings: sibilings,
    };

  }

  getSpouseSingleChildTree(members: GetTreeModel[]) {


    let spouse = this.getMainSpouse(members);
    let spouseSingleChildren
    if (spouse) {
      spouseSingleChildren = this.getSingleChildren(spouse.id, members)
    }
    var nodes = []
    let sibilings = []
    console.log("getSpouseSingleChildTree", spouseSingleChildren)

    if (spouseSingleChildren != null) {

      spouseSingleChildren.forEach(child => {

        let childTreeResult = this.getChildTree(members, child)
        childTreeResult.nodes.forEach(element => {

          nodes.push(element)

        });
        childTreeResult.sibilings.forEach(element => {

          sibilings.push(element)

        });


      })




    }



    return {
      nodes: nodes,
      sibilings: sibilings,
    };





  }


  getownerCommonChildTree(members: GetTreeModel[], commonChildren: GetTreeModel[]) {


    var nodes = []
    let sibilings = []

    //if (commonChildren != null){

    commonChildren.forEach(child => {

      let childTreeResult = this.getChildTree(members, child)
      childTreeResult.nodes.forEach(element => {

        nodes.push(element)

      });
      childTreeResult.sibilings.forEach(element => {

        sibilings.push(element)

      });


    })

    //  }



    return {
      nodes: nodes,
      sibilings: sibilings,
    };



  }


  //repeat this for every child
  getChildTree(members: GetTreeModel[], child: GetTreeModel) {

    let spouse = this.getSpouse(child.id, members)
    var childNode = this.createNode(child, false, false, RelationType.Child)

    var nodes = []
    let sibilings = []

    //child's single children
    let getSingleChildren = this.getSingleChildren(child.id, members)
    getSingleChildren.forEach(element => {
      // all grand children's divorcee
      const devorcee = this.getDivorcee(element?.id , members);
      const devorceeNode = this.createNode(devorcee, false, true, RelationType.Divorcee)
      if (devorcee) {
        childNode.children.push(devorceeNode)
      }

      var cn = this.createNode(element, false, false, RelationType.Child)
      let spouse1 = this.getSpouse(element?.id, members)

      childNode.children.push(cn)

      // all grand children's partner
      if (spouse1) {
        var cns = this.createNode(spouse1, false, true, RelationType.Spouse)
        const l = this.getlinkNodes(element?.id, cns?.id)
        sibilings.push(l)
        childNode.children.push(cns)
      }
    });

    nodes.push(childNode)


    //child has a spouse
    if (spouse != null) {

      var spouseNode = this.createNode(spouse, false, true, RelationType.Child)

      let getSingleChildrenSpouse = this.getSingleChildren(spouseNode.id, members)
      getSingleChildrenSpouse.forEach(element => {
        var cn = this.createNode(element, false, false, RelationType.Child)
        // child's partner child's divorcee
        const devorcee = this.getDivorcee(element?.id , members);
        const devorceeNode = this.createNode(devorcee, false, true, RelationType.Divorcee)
        if (devorcee) {
          spouseNode.children.push(devorceeNode)
        }
        spouseNode.children.push(cn)
      
        // child's partner child's partner
        let spouse1 = this.getSpouse(element?.id, members)
        if (spouse1) {
          var cns = this.createNode(spouse1, false, true, RelationType.Spouse)
          const l = this.getlinkNodes(element?.id, cns?.id)
          sibilings.push(l)
          spouseNode.children.push(cns)
        }
      });

      sibilings.push(this.getlinkNodes(child.id, spouse.id))

      let commonChildren = this.getCommonChildren([child.id, spouse.id], members)

      //child and spouse children
      if (commonChildren.length > 0) {

        let hiddenNode = this.createHiddenNode()

        commonChildren.forEach(element => {
          // child common children's divorcee
          const devorcee = this.getDivorcee(element?.id , members);
          const devorceeNode = this.createNode(devorcee, false, true, RelationType.Divorcee)
          if (devorcee) {
            hiddenNode.children.push(devorceeNode)
          }
          var cn = this.createNode(element, false, false, RelationType.Child)
          hiddenNode.children.push(cn)

          // child common children's partner
          let spouse2 = this.getSpouse(element?.id, members)
          if (spouse2) {
            const cns2 = this.createNode(spouse2, false, true, RelationType.Spouse)
            const l = this.getlinkNodes(element?.id, cns2?.id)
            sibilings.push(l)
            hiddenNode.children.push(cns2)
          }
        });

        nodes.push(hiddenNode)


      }

      nodes.push(spouseNode)




    }
    return {
      nodes: nodes,
      sibilings: sibilings,
    };


  }

  getOwnerCommonChildren(members: GetTreeModel[]) {


    let owner = this.getOwner(members)
    let spouse = this.getMainSpouse(members)

    if (owner != null && spouse != null) {



      return this.getCommonChildren([owner.id, spouse.id], members)


    }




    return []




  }




  linkMainNodes(members: GetTreeModel[]) {

    let owner = this.getOwner(members)

    if (owner != null) {
      3
      let spouse = this.getSpouse(owner.id, members)
      if (spouse != null) {

        return this.getlinkNodes(owner.id, spouse.id)

      }



    }

    return null

  }

  getOwnerDivorceeParentTree(members: GetTreeModel[]) {

    let ownerDivorcee = this.getOwnerDivorcee(members)
    if (ownerDivorcee != null) {

      if (this.isParentsExistsForMainUsers(members)) {


        let hiddenNode = this.createHiddenNode()
        var divorceeNode = this.createNode(ownerDivorcee, false, true, RelationType.Divorcee)
        hiddenNode.children.push(divorceeNode)
        return hiddenNode



      }
      else {

        var divorceeNode = this.createNode(ownerDivorcee, false, true, RelationType.Divorcee)
        return (divorceeNode)


      }



    }

    return null


  }

  getSpouseDivorceeParentTree(members: GetTreeModel[]) {

    let spouseDivorcee = this.getSpouseDivorcee(members)
    if (spouseDivorcee != null) {

      if (this.isParentsExistsForMainUsers(members)) {

        let hiddenNode = this.createHiddenNode()
        var divorceeNode = this.createNode(spouseDivorcee, false, true, RelationType.Divorcee)
        hiddenNode.children.push(divorceeNode)
        //let divorceeNodeWithParent = this.createHiddenNodeWithChild(spouseDivorcee,false,RelationType.Divorcee)
        return hiddenNode


      }
      else {

        var divorceeNode = this.createNode(spouseDivorcee, false, true, RelationType.Divorcee)
        return (divorceeNode)


      }


    }

    return null


  }




  getOwnerParentTree(members: GetTreeModel[]) {

    //owner
    let owner = this.getOwner(members)
    let ownerParents = this.getOwnerParents(members)
    var ownerNode = this.createNode(owner, false, true, RelationType.Main)


    var nodes = []
    let sibilings = []

    if (ownerParents.length > 0) {

      if (ownerParents.length == 1) {

        let parent = ownerParents[0]
        var parentNode = this.createNode(parent, false, true, RelationType.Parent)
        ownerNode.no_parent = false
        let hiddenNode = this.createHiddenNode()

        let ownerSibilings = this.getSingleChildren(parent.id, members)

        ownerSibilings.forEach(element => {
          var sib1 = this.createNode(element, false, false, RelationType.Child);
          parentNode.children.push(sib1);// <---------- owner sibling family starts ---------------->

          // ==============================

          const spouse1 = this.getSpouse(element?.id, members)
          const hiddenNodeSibling = this.createHiddenNode()
          let siblingPartner
          if (spouse1) {
            //owner single sibling partner (from single parent)
            siblingPartner = this.createNode(spouse1, false, true, RelationType.Spouse)
            const l = this.getlinkNodes(element?.id, siblingPartner?.id)
            sibilings.push(l)
            parentNode.children.push(hiddenNodeSibling)
            parentNode.children.push(siblingPartner)
          }

          // ===============================
            //owner single sibling single children
            const sibilingSingleChildren = this.getSingleChildren(element?.id, members);
            sibilingSingleChildren.forEach(e => {
              const parentnodeSibilingChildren = this.createNode(e, false, false, RelationType.Parent)
              sib1.children.push(parentnodeSibilingChildren);
  
              // <---------- owner single sibling child family starts ---------------->
              const spouseSibilingChild = this.getSpouse(e?.id, members)
              const hidnnodeSiblingChild = this.createHiddenNode()
              let singleChChildPartner
              if (spouseSibilingChild) {
                //owner single sibling  single children partner
                singleChChildPartner = this.createNode(spouseSibilingChild, false, true, RelationType.Spouse)
                const m = this.getlinkNodes(e?.id, singleChChildPartner?.id)
                sibilings.push(m)
                sib1.children.push(hidnnodeSiblingChild)
                sib1.children.push(singleChChildPartner)
              }
  
              //owner single sibling single children single children
              const singleC = this.getSingleChildren(e.id, members);
              singleC.forEach(f => {
                var parentno = this.createNode(f, false, false, RelationType.Parent)
                parentnodeSibilingChildren.children.push(parentno);
                const spouseSibilingChild = this.getSpouse(f?.id, members);
                if (spouseSibilingChild) {
                  //owner single sibling  single children single children partner
                  const scp = this.createNode(spouseSibilingChild, false, true, RelationType.Spouse)
                  const m = this.getlinkNodes(f?.id, scp?.id)
                  sibilings.push(m)
                  parentnodeSibilingChildren.children.push(scp)
                }
              });

              //owner single sibling single children  partner single children
              if (spouseSibilingChild) {
                let getSpouseSingleChildT = this.getSingleChildTreeMember(e, members)
                getSpouseSingleChildT.nodes.forEach(a => {
                  singleChChildPartner.children.push(a)
                });
  
                //owner single sibling single children common children
                const commonCh = this.getCommonChildren([e.id, spouseSibilingChild?.id], members)
                commonCh.forEach(b => {
                  let childTreeResult = this.getChildTree(members, b)
                  childTreeResult.nodes.forEach(p => {
                    hidnnodeSiblingChild.children.push(p)
                  });
                });
              }
              // <---------- owner single sibling child family ends ---------------->
            });

            //owner single sibling partner single children
          if (spouse1) {
            let getSpouseSingleChildTree = this.getSingleChildTreeMember(element, members)
            getSpouseSingleChildTree.nodes.forEach(c => {
              siblingPartner.children.push(c);

              const sSC = this.getSpouse(c?.id, members)
              if (sSC) {
                //owner single sibling partner single children partner
                const scp = this.createNode(sSC, false, true, RelationType.Spouse)
                const m = this.getlinkNodes(c?.id, scp?.id)
                sibilings.push(m)
              }
            });

            //owner single sibling common children
            const commonC = this.getCommonChildren([element.id, spouse1.id], members)
            commonC.forEach(e => {
              let childTreeResult = this.getChildTree(members, e)
              childTreeResult.nodes.forEach(p => {
                hiddenNodeSibling.children.push(p)
              });

              // owner single sibling common children's partner
              let spouse2 = this.getSpouse(e?.id, members)
              if (spouse2) {
                const cns3 = this.createNode(spouse2, false, true, RelationType.Spouse)
                const l = this.getlinkNodes(e?.id, cns3?.id)
                sibilings.push(l)
              }
            });
          }
        });

        //set owner divorcee
        const devorcee = this.getOwnerDivorcee(members)
        const devorceeNode = this.createNode(devorcee, false, true, RelationType.Divorcee)
        if (devorcee) {
          parentNode.children.push(devorceeNode)
        }

        parentNode.children.push(ownerNode)

         //parentNode divorcee if only have one parent
         const d = this.getDivorcee(parentNode?.id , members);
         const dn = this.createNode(d, false, true, RelationType.Divorcee)
         if (d) {
           nodes.push(dn)
         }
         
        nodes.push(parentNode)

      }
      else if (ownerParents.length > 1) {


        let parent1 = ownerParents[0]
        var parentNode1 = this.createNode(parent1, false, true, RelationType.Parent)

        let parent2 = ownerParents[1]
        var parentNode2 = this.createNode(parent2, false, true, RelationType.Parent)

        let hiddenNode = this.createHiddenNode()

        ownerNode.no_parent = false

        // =========================

        // let singleOwnerSibilings1 = this.getSingleChildren(parent1.id, members)
        // let singleOwnerSibilings2 = this.getSingleChildren(parent2.id, members)

        // singleOwnerSibilings1.forEach(element => {
        //   var parentNode = this.createNode(element, false, false, RelationType.Parent)
        //   parentNode1.children.push(parentNode)
        // });

        // singleOwnerSibilings2.forEach(element => {
        //   var parentNode = this.createNode(element, false, false, RelationType.Parent)
        //   parentNode2.children.push(parentNode)
        // });

        // =========================

        let ownerSibilings = this.getCommonChildren([parent1.id, parent2.id], members)

        ownerSibilings.forEach(element => {


          var parentNode = this.createNode(element, false, false, RelationType.Parent)
          hiddenNode.children.push(parentNode)

          // <---------- owner sibling family starts ---------------->
          const spouse1 = this.getSpouse(element?.id, members)
          const hiddenNodeSibling = this.createHiddenNode()
          let siblingPartner
          if (spouse1) {
            //owner sibling partner
            siblingPartner = this.createNode(spouse1, false, true, RelationType.Spouse)
            const l = this.getlinkNodes(element?.id, siblingPartner?.id)
            sibilings.push(l)
            hiddenNode.children.push(hiddenNodeSibling)
            hiddenNode.children.push(siblingPartner)
          }

          //owner sibling single children
          const sibilingSingleChildren = this.getSingleChildren(element?.id, members);
          sibilingSingleChildren.forEach(e => {
            const parentnodeSibilingChildren = this.createNode(e, false, false, RelationType.Parent)
            parentNode.children.push(parentnodeSibilingChildren);

            // <---------- owner sibling child family starts ---------------->
            const spouseSibilingChild = this.getSpouse(e?.id, members)
            const hidnnodeSiblingChild = this.createHiddenNode()
            let singleChChildPartner
            if (spouseSibilingChild) {
              //owner sibling  single children partner
              singleChChildPartner = this.createNode(spouseSibilingChild, false, true, RelationType.Spouse)
              const m = this.getlinkNodes(e?.id, singleChChildPartner?.id)
              sibilings.push(m)
              parentNode.children.push(hidnnodeSiblingChild)
              parentNode.children.push(singleChChildPartner)
            }

            //owner sibling single children single children
            const singleC = this.getSingleChildren(e.id, members);
            singleC.forEach(f => {
              var parentno = this.createNode(f, false, false, RelationType.Parent)
              parentnodeSibilingChildren.children.push(parentno);
              const spouseSibilingChild = this.getSpouse(f?.id, members);
              if (spouseSibilingChild) {
                //owner sibling  single children single children partner
                const scp = this.createNode(spouseSibilingChild, false, true, RelationType.Spouse)
                const m = this.getlinkNodes(f?.id, scp?.id)
                sibilings.push(m)
                parentnodeSibilingChildren.children.push(scp)
              }
            });

            //owner sibling single children  partner single children
            if (spouseSibilingChild) {
              let getSpouseSingleChildT = this.getSingleChildTreeMember(e, members)
              getSpouseSingleChildT.nodes.forEach(a => {
                singleChChildPartner.children.push(a)
              });

              //owner sibling single children common children
              const commonCh = this.getCommonChildren([e.id, spouseSibilingChild?.id], members)
              commonCh.forEach(b => {
                let childTreeResult = this.getChildTree(members, b)
                childTreeResult.nodes.forEach(p => {
                  hidnnodeSiblingChild.children.push(p)
                });
              });
            }
            // <---------- owner sibling child family ends ---------------->
          });

          //owner sibling partner single children
          if (spouse1) {
            let getSpouseSingleChildTree = this.getSingleChildTreeMember(element, members)
            getSpouseSingleChildTree.nodes.forEach(c => {
              siblingPartner.children.push(c);

              const sSC = this.getSpouse(c?.id, members)
              if (sSC) {
                //owner sibling partner single children partner
                const scp = this.createNode(sSC, false, true, RelationType.Spouse)
                const m = this.getlinkNodes(c?.id, scp?.id)
                sibilings.push(m)
              }
            });

            //owner sibling common children
            const commonC = this.getCommonChildren([element.id, spouse1.id], members)
            commonC.forEach(e => {
              let childTreeResult = this.getChildTree(members, e)
              childTreeResult.nodes.forEach(p => {
                hiddenNodeSibling.children.push(p)
              });

              // owner sibling common children's partner
              let spouse2 = this.getSpouse(e?.id, members)
              if (spouse2) {
                const cns3 = this.createNode(spouse2, false, true, RelationType.Spouse)
                const l = this.getlinkNodes(e?.id, cns3?.id)
                sibilings.push(l)
              }
            });
          }
          // <---------- owner sibling family ends ---------------->
        });

        //set owner divorcee
        const devorcee = this.getOwnerDivorcee(members)
        const devorceeNode = this.createNode(devorcee, false, true, RelationType.Divorcee)
        if (devorcee) {
          hiddenNode.children.push(devorceeNode)
        }

        hiddenNode.children.push(ownerNode)

        sibilings.push(this.getlinkNodes(parent1.id, parent2.id))

        //parentNode1 divorcee if more than one parent
        const d = this.getDivorcee(parentNode1?.id , members);
        const dn = this.createNode(d, false, true, RelationType.Divorcee)
        if (d) {
          nodes.push(dn)
        }
        nodes.push(parentNode1)
        nodes.push(hiddenNode)
        nodes.push(parentNode2)
        
        //parentNode2 divorcee if more than one parent
        const d1 = this.getDivorcee(parentNode2?.id , members);
        const dn1 = this.createNode(d1, false, true, RelationType.Divorcee)
        if (d1) {
          nodes.push(dn1)
        }


      }
    }
    else {

      if (this.isParentsExistsForMainUsers(members)) {

        let hiddenNode = this.createHiddenNode()
        //set owner divorcee
        const devorcee = this.getOwnerDivorcee(members)
        const devorceeNode = this.createNode(devorcee, false, true, RelationType.Divorcee)
        if (devorcee) {
          hiddenNode.children.push(devorceeNode)
        }

        hiddenNode.children.push(ownerNode)
        nodes.push(hiddenNode)

      }
      else {
        //set owner divorcee
        const devorcee = this.getOwnerDivorcee(members)
        const devorceeNode = this.createNode(devorcee, false, true, RelationType.Divorcee)
        if (devorcee) {
          nodes.push(devorceeNode)
        }

        nodes.push(ownerNode)

      }

      //console.log("ownerParentTreeResult",ownerNode)
    }



    //featch single childrenTrees
    let getownerSingleChildTreeResult = this.getownerSingleChildTree(members)
    getownerSingleChildTreeResult.nodes.forEach(element => {
      // set divorcee node for owner single children
      if (!element.no_parent) {
        const devorcee = this.getDivorcee(element?.id, members)
        const devorceeNode = this.createNode(devorcee, false, true, RelationType.Divorcee)
        if (devorcee) {
          ownerNode.children.push(devorceeNode)
        }
      }

      ownerNode.children.push(element)
      //client single children partner divorcee
      if (element.no_parent) {
        const devorcee = this.getDivorcee(element?.id, members)
        const devorceeNode = this.createNode(devorcee, false, true, RelationType.Divorcee)
        if (devorcee) {
          ownerNode.children.push(devorceeNode)
        }
      }

    });
    getownerSingleChildTreeResult.sibilings.forEach(element => {
      sibilings.push(element)
    });




    return {
      nodes: nodes,
      sibilings: sibilings,
    };
  }


  getlinkNodes(sourceId, targetId, linkColour?) {

    const link = {
      source: {
        id: sourceId,
        name: "source"
      },
      target: {
        id: targetId,
        name: "target"
      },
      colour: linkColour ? linkColour : "#ccc"
    }

    return link
  }


  getSpouseParentTree(members: GetTreeModel[]) {

    //owner
    let spouse = this.getMainSpouse(members)
    let spouseParents = this.getSpouseParents(members)
    var spouseNode = this.createNode(spouse, false, true, RelationType.Spouse)

    var nodes = []
    let sibilings = []

    if (spouse && spouseParents.length > 0) {

      if (spouseParents.length == 1) {

        let parent = spouseParents[0]
        var parentNode = this.createNode(parent, false, true, RelationType.Parent)
        spouseNode.no_parent = false
        parentNode.children.push(spouseNode)
        //set spouse divorcee
        const devorcee = this.getSpouseDivorcee(members)
        const devorceeNode = this.createNode(devorcee, false, true, RelationType.Divorcee)
        if (devorcee) {
          parentNode.children.push(devorceeNode)
        }
        let spouseSibilings = this.getSingleChildren(parent.id, members)

        spouseSibilings.forEach(element => {

          var sib = this.createNode(element, false, false, RelationType.Child)
          parentNode.children.push(sib);

            // ============partner single sibling ==================
            const spouse1 = this.getSpouse(element?.id, members)
            const hiddenNodeSibling = this.createHiddenNode()
            let siblingPartner
            if (spouse1) {
              //partner single sibling partner (from single parent)
              siblingPartner = this.createNode(spouse1, false, true, RelationType.Spouse)
              const l = this.getlinkNodes(element?.id, siblingPartner?.id)
              sibilings.push(l)
              parentNode.children.push(hiddenNodeSibling)
              parentNode.children.push(siblingPartner)
            }

            // ===============================
            //partner single sibling single children
            const sibilingSingleChildren = this.getSingleChildren(element?.id, members);
            sibilingSingleChildren.forEach(e => {
              const parentnodeSibilingChildren = this.createNode(e, false, false, RelationType.Parent)
              sib.children.push(parentnodeSibilingChildren);
  
              // <---------- partner single sibling child family starts ---------------->
              const spouseSibilingChild = this.getSpouse(e?.id, members)
              const hidnnodeSiblingChild = this.createHiddenNode()
              let singleChChildPartner
              if (spouseSibilingChild) {
                //partner single sibling  single children partner
                singleChChildPartner = this.createNode(spouseSibilingChild, false, true, RelationType.Spouse)
                const m = this.getlinkNodes(e?.id, singleChChildPartner?.id)
                sibilings.push(m)
                sib.children.push(hidnnodeSiblingChild)
                sib.children.push(singleChChildPartner)
              }
  
              //partner single sibling single children single children
              const singleC = this.getSingleChildren(e.id, members);
              singleC.forEach(f => {
                var parentno = this.createNode(f, false, false, RelationType.Parent)
                parentnodeSibilingChildren.children.push(parentno);
                const spouseSibilingChild = this.getSpouse(f?.id, members);
                if (spouseSibilingChild) {
                  //partner single sibling  single children single children partner
                  const scp = this.createNode(spouseSibilingChild, false, true, RelationType.Spouse)
                  const m = this.getlinkNodes(f?.id, scp?.id)
                  sibilings.push(m)
                  parentnodeSibilingChildren.children.push(scp)
                }
              });
  
              //partner single sibling single children  partner single children
              if (spouseSibilingChild) {
                let getSpouseSingleChildT = this.getSingleChildTreeMember(e, members)
                getSpouseSingleChildT.nodes.forEach(a => {
                  singleChChildPartner.children.push(a)
                });
  
                //partner single sibling single children common children
                const commonCh = this.getCommonChildren([e.id, spouseSibilingChild?.id], members)
                commonCh.forEach(b => {
                  let childTreeResult = this.getChildTree(members, b)
                  childTreeResult.nodes.forEach(p => {
                    hidnnodeSiblingChild.children.push(p)
                  });
                });
              }
              // <---------- partner single sibling child family ends ---------------->
            });

            //partner single sibling partner single children
          if (spouse1) {
            let getSpouseSingleChildTree = this.getSingleChildTreeMember(element, members)
            getSpouseSingleChildTree.nodes.forEach(c => {
              siblingPartner.children.push(c);

              const sSC = this.getSpouse(c?.id, members)
              if (sSC) {
                //partner single sibling partner single children partner
                const scp = this.createNode(sSC, false, true, RelationType.Spouse)
                const m = this.getlinkNodes(c?.id, scp?.id)
                sibilings.push(m)
              }
            });

            //partner single sibling common children
            const commonC = this.getCommonChildren([element.id, spouse1.id], members)
            commonC.forEach(e => {
              let childTreeResult = this.getChildTree(members, e)
              childTreeResult.nodes.forEach(p => {
                hiddenNodeSibling.children.push(p)
              });

              // partner single sibling common children's partner
              let spouse2 = this.getSpouse(e?.id, members)
              if (spouse2) {
                const cns3 = this.createNode(spouse2, false, true, RelationType.Spouse)
                const l = this.getlinkNodes(e?.id, cns3?.id)
                sibilings.push(l)
              }
            });
          }

        });
        //parentNode divorcee if only one parent
        const d1 = this.getDivorcee(parentNode?.id , members);
        const dn1 = this.createNode(d1, false, true, RelationType.Divorcee)
        if (d1) {
          nodes.push(dn1)
        }
        nodes.push(parentNode)

      }
      else if (spouseParents.length == 2) {


        let parent1 = spouseParents[0]
        var parentNode1 = this.createNode(parent1, false, true, RelationType.Parent)

        let parent2 = spouseParents[1]
        var parentNode2 = this.createNode(parent2, false, true, RelationType.Parent)

        let hiddenNode = this.createHiddenNode()

        spouseNode.no_parent = false

        // =========================

        // let singleOwnerSibilings1 = this.getSingleChildren(parent1.id, members)
        // let singleOwnerSibilings2 = this.getSingleChildren(parent2.id, members)

        // console.log('ownerSibilings', singleOwnerSibilings1, parent)
        // singleOwnerSibilings1.forEach(element => {
        //   var parentNode = this.createNode(element, false, false, RelationType.Parent)
        //   parentNode1.children.push(parentNode)
        // });

        // singleOwnerSibilings2.forEach(element => {
        //   var parentNode = this.createNode(element, false, false, RelationType.Parent)
        //   parentNode2.children.push(parentNode)
        // });

        // =========================
        hiddenNode.children.push(spouseNode)
        //set spouse divorcee
        const devorcee = this.getSpouseDivorcee(members)
        const devorceeNode = this.createNode(devorcee, false, true, RelationType.Divorcee)
        if (devorcee) {
          hiddenNode.children.push(devorceeNode)
        }

        let spouseSibilings = this.getCommonChildren([parent1.id, parent2.id], members)

        spouseSibilings.forEach(element => {

          var parentNode = this.createNode(element, false, false, RelationType.Parent)
          hiddenNode.children.push(parentNode)

          let spouse1 = this.getSpouse(element?.id, members)
          let hidn = this.createHiddenNode()
          let cns2;
          if (spouse1) {
            cns2 = this.createNode(spouse1, false, true, RelationType.Spouse)
            const l = this.getlinkNodes(element?.id, cns2?.id)
            sibilings.push(l)
            hiddenNode.children.push(hidn)
            hiddenNode.children.push(cns2)
          }

          //partner sibling single children
          let singleChildren = this.getSingleChildren(element.id, members);

          singleChildren.forEach(e => {
            const parentnodeSibilingChildren = this.createNode(e, false, false, RelationType.Parent)
            parentNode.children.push(parentnodeSibilingChildren);

            // <---------- partner sibling child family starts ---------------->
            const spouseSibilingChild = this.getSpouse(e?.id, members)
            const hidnnodeSiblingChild = this.createHiddenNode()
            let singleChChildPartner
            if (spouseSibilingChild) {
              //partner sibling  single children child's partner
              singleChChildPartner = this.createNode(spouseSibilingChild, false, true, RelationType.Spouse)
              const l = this.getlinkNodes(e?.id, singleChChildPartner?.id)
              sibilings.push(l)
              parentNode.children.push(hidnnodeSiblingChild)
              parentNode.children.push(singleChChildPartner)
            }

            //partner sibling single children single children
            const singleC = this.getSingleChildren(e.id, members);
            singleC.forEach(f => {
              var parentno = this.createNode(f, false, false, RelationType.Parent)
              parentnodeSibilingChildren.children.push(parentno);
              const spouseSibilingChild = this.getSpouse(f?.id, members);
              if (spouseSibilingChild) {
                //partner sibling  single children single children partner
                const scp = this.createNode(spouseSibilingChild, false, true, RelationType.Spouse)
                const m = this.getlinkNodes(f?.id, scp?.id)
                sibilings.push(m)
                parentnodeSibilingChildren.children.push(scp)
              }
            });

            //partner sibling single children  partner single children
            if (spouseSibilingChild) {
              let getSpouseSingleChildT = this.getSingleChildTreeMember(e, members)
              getSpouseSingleChildT.nodes.forEach(d => {
                singleChChildPartner.children.push(d);
              });

              //partner sibling single children common children
              const commonCh = this.getCommonChildren([e.id, spouseSibilingChild?.id], members)
              commonCh.forEach(b => {
                let childTreeResult = this.getChildTree(members, b)
                childTreeResult.nodes.forEach(p => {
                  hidnnodeSiblingChild.children.push(p)
                });
              });
            }
            // <---------- partner sibling child family ends ---------------->
          });

          //partner sibling partner single children
          if (spouse1) {
            let getSpouseSingleChildTree = this.getSingleChildTreeMember(element, members)

            getSpouseSingleChildTree.nodes.forEach(s => {
              cns2.children.push(s)
              const sSC = this.getSpouse(s?.id, members)
              if (sSC) {
                //partner sibling partner single children partner
                const scp = this.createNode(sSC, false, true, RelationType.Spouse)
                const m = this.getlinkNodes(s?.id, scp?.id)
                sibilings.push(m)
              }
            });

            //partner sibling common children
            let commonC = this.getCommonChildren([element.id, spouse1.id], members)
            commonC.forEach(e => {
              let childTreeResult = this.getChildTree(members, e)
              childTreeResult.nodes.forEach(p => {
                hidn.children.push(p)
              });

              // partner sibling common children's partner
              let spouse2 = this.getSpouse(e?.id, members)
              if (spouse2) {
                const cns3 = this.createNode(spouse2, false, true, RelationType.Spouse)
                const l = this.getlinkNodes(e?.id, cns3?.id)
                sibilings.push(l)
              }
            });
          }
        });



        sibilings.push(this.getlinkNodes(parent1.id, parent2.id))
        //parentNode1 divorcee if more than one parent
        const d = this.getDivorcee(parentNode1?.id , members);
        const dn = this.createNode(d, false, true, RelationType.Divorcee)
        if (d) {
          nodes.push(dn)
        }
        nodes.push(parentNode1)
        nodes.push(hiddenNode)
        nodes.push(parentNode2)
        //parentNode2 divorcee if more than one parent
        const d1 = this.getDivorcee(parentNode2?.id , members);
        const dn1 = this.createNode(d1, false, true, RelationType.Divorcee)
        if (d1) {
          nodes.push(dn1)
        }


      }
    }
    else {


      if (this.isParentsExistsForMainUsers(members)) {

        let hiddenNode = this.createHiddenNode()
        if (spouse) {
          hiddenNode.children.push(spouseNode)
          nodes.push(hiddenNode)
          //set spouse divorcee
          const devorcee = this.getSpouseDivorcee(members)
          const devorceeNode = this.createNode(devorcee, false, true, RelationType.Divorcee)
          if (devorcee) {
            hiddenNode.children.push(devorceeNode)
          }
        }

      }
      else {
        if (spouse) {
          nodes.push(spouseNode)
          //set spouse divorcee
          const devorcee = this.getSpouseDivorcee(members)
          const devorceeNode = this.createNode(devorcee, false, true, RelationType.Divorcee)
          if (devorcee) {
            nodes.push(devorceeNode)
          }
        }
      }


    }


    //featch single childrenTrees
    let getSpouseSingleChildTree = this.getSpouseSingleChildTree(members)

    getSpouseSingleChildTree.nodes.forEach(element => {
      //set divorcee for spouse single children
      const devorcee = this.getDivorcee(element?.id, members)
      const devorceeNode = this.createNode(devorcee, false, true, RelationType.Divorcee)
      //partner's single children's divorcee
      if (devorcee && !element.no_parent) {
        spouseNode.children.push(devorceeNode)
      }
      spouseNode.children.push(element)
      //partner's single children's partner's divorcee
      if (devorcee && element.no_parent) {
        spouseNode.children.push(devorceeNode)
      }
    });
    getSpouseSingleChildTree.sibilings.forEach(element => {
      sibilings.push(element)
    });


    return {
      nodes: nodes,
      sibilings: sibilings,
    };

  }



  isParentsExistsForMainUsers(members: GetTreeModel[]) {

    if (this.getOwnerParents(members) != null || this.getSpouseParents(members)) {

      return true

    }

    return false

  }

  isParentsExistsForUser(id, members: GetTreeModel[]) {

    if (this.getParent(id, members)) {

      return true

    }

    return false

  }


  getOwner(members: GetTreeModel[]) {

    let owner = members.find((element: GetTreeModel) => element.isRoot == true)

    return owner

  }

  getMainSpouse(members: GetTreeModel[]) {

    let owner = this.getOwner(members)

    if (owner != null) {

      return this.getSpouse(owner.id, members)

    }

    return null
  }

  getOwnerParents(members: GetTreeModel[]) {

    let owner = this.getOwner(members)
    if (owner != null) {

      return this.getParent(owner.id, members)

    }
    return []

  }

  getSpouseParents(members: GetTreeModel[]) {

    let spouse = this.getMainSpouse(members)
    if (spouse != null) {

      return this.getParent(spouse.id, members)

    }
    return []
  }

  getOwnerDivorcee(members: GetTreeModel[]) {

    let owner = this.getOwner(members)
    if (owner != null) {

      return this.getDivorcee(owner.id, members)

    }

  }

  getSpouseDivorcee(members: GetTreeModel[]) {

    let spouse = this.getMainSpouse(members)
    if (spouse != null) {

      return this.getDivorcee(spouse.id, members)

    }

  }

  getSingleChildren(parentid: String, members: GetTreeModel[]) {

    // let spouse  = this.getOwner(members)
    //if (spouse != null) {
    let parentsList = members.filter((element: GetTreeModel) => ((element.relation == "Child") && (element.relations.length == 1)))
    var singleChildren = []
    parentsList.forEach(element => {

      let parent1 = element.relations.find((element: Relations) => (element.relationDependantId == parentid))
      if (parent1 != null) {

        singleChildren.push(element)
      }

    });


    return singleChildren


  }


  getCommonChildren(parents: String[], members: GetTreeModel[]) {

    let parentsList = members.filter((element: GetTreeModel) => ((element.relation == "Child") && (element.relations.length > 1)))
    var commonChildren = []
    parentsList.forEach(element => {

      let parent1 = element.relations.find((element: Relations) => (element.relationDependantId == parents[0]))
      let parent2 = element.relations.find((element: Relations) => (element.relationDependantId == parents[1]))

      if (parent1 != null && parent2 != null) {

        commonChildren.push(element)



      }

    });

    return commonChildren

  }

  getAllChildren(parents: String[], members: GetTreeModel[]) {

    let children = []
    let common = this.getCommonChildren(parents, members)
    common.forEach(element => {

      children.push(element)

    });

    let owner = this.getSingleChildren(parents[0], members)
    owner.forEach(element => {

      children.push(element)

    });



    if (parents.length > 1) {

      let spouse = this.getSingleChildren(parents[1], members)
      if (spouse) {
        spouse.forEach(element => {
          children.push(element)
        });
      }

    }

    return children

  }

  getParent(id: String, members: GetTreeModel[]) {


    let parents = members.filter((element: GetTreeModel) => ((element.relation == "Parent") && (element.relations.find((relaton: Relations) => relaton.relationDependantId == id) != null)))

    return parents


  }

  getDivorcee(id: String, members: GetTreeModel[]) {

    let divorcee = members.find((element: GetTreeModel) => ((element.relation == "Divorcee") && (element.relations.find((relaton: Relations) => relaton.relationDependantId == id) != null)))
    return divorcee

  }


  getSpouse(id: String, members: GetTreeModel[]) {


    let spouse = members.find((element: GetTreeModel) => ((element.relation == "Spouse") && (element.relations.find((relaton: Relations) => relaton.relationDependantId == id) != null)))
    return spouse


  }

  createHiddenNode() {

    const hiddenNode = {
      id: null,
      hidden: true,
      no_parent: true,
      children: []
    };
    return hiddenNode;
  }


  // Create Tree Node
  createNode(nodeData: GetTreeModel, hidden: boolean, no_parent: boolean, type: number, linkColour?: string) {


    if (nodeData != null) {

      const node = {
        hidden: hidden,
        no_parent: no_parent,
        type: type,
        id: nodeData.id ? nodeData.id : null,
        name: nodeData.firstName ? nodeData.firstName : null,
        age: nodeData.dateOfBirth ? this.calcAgeFromDOB(nodeData.dateOfBirth) : null,
        gender: nodeData.gender ? nodeData.gender : null,
        icon: this.setIcon(nodeData.isDeceased, nodeData.isDependent, nodeData.gender, nodeData.relation, type, nodeData.dateOfBirth ? this.calcAgeFromDOB(nodeData.dateOfBirth) : null),
        children: [],
        colour: linkColour ? linkColour : "#ccc"
      }

      return node
    }
  }



  // // Create hidden parent Node with child
  // createHiddenNodeWithChild(childData, hasParent: boolean, type: number) {

  //   const hiddenNode = {
  //     id: null,
  //     hidden: true,
  //     no_parent: true,
  //     children: []
  //   };

  //   if ( childData?.length ) {
  //     childData.forEach(child => {

  //       hiddenNode.children.push(this.createNode(child, false, hasParent, type));
  //     });
  //   } else {

  //     hiddenNode.children.push(this.createNode(childData, false, hasParent, type));

  //   }

  //   return hiddenNode;
  // }


  // Calculate age from date of birth
  private calcAgeFromDOB(dob) {

    let timeDiff = Math.abs(Date.now() - new Date(dob).getTime());
    return Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);

  }
  // Set node icon
  private setIcon(deceased: boolean, dependant: boolean, gender: number, relation: string, type?: number, age?: number) {

    let icon: string;

    // const maleIcon = "../assets/icons/men.svg";
    // const femaleIcon = "../assets/icons/women.svg";
    // const maleDeceasedIcon = "../assets/icons/deceased-men.svg";
    // const femaleDeceasedIcon = "../assets/icons/deceased-women.svg";
    // const maleChildIcon = "../assets/icons/boy.svg";
    // const femaleChildIcon = "../assets/icons/girl.svg";
    // const maleDeceasedChildIcon = "../assets/icons/boy.svg";
    // const femaleDeceasedChildIcon = "../assets/icons/girl.svg";

    const maleIcon = environment.icons.maleIcon
    const femaleIcon = environment.icons.femaleIcon
    const maleDeceasedIcon = environment.icons.maleDeceasedIcon
    const femaleDeceasedIcon = environment.icons.femaleDeceasedIcon
    const maleChildIcon = environment.icons.maleChildIcon
    const femaleChildIcon = environment.icons.femaleChildIcon
    const divorcee = environment.icons.divorceeIcon
    const divorceeFemale = environment.icons.divorceeFemaleIcon
    const childMaleDeceasedIcon = environment.icons.childMaleDeceasedIcon
    const childFemaleDeceasedIcon = environment.icons.childFemaleDeceasedIcon

    // default icons (not dependent)
    // const maleDefaultIcon = (deceased && (type != this.relationType.Main)) ? maleChildIcon : maleChildIcon;

    //const maleDefaultIcon = (deceased) ? maleDeceasedIcon : ((type == RelationType.Main) ? maleIcon : maleChildIcon);
    // const femaleDefaultIcon = (deceased) ? femaleDeceasedIcon : ((type == RelationType.Main) ? femaleIcon: femaleChildIcon);

    const maleDefaultIcon = (deceased) ? maleDeceasedIcon : maleIcon;
    const femaleDefaultIcon = (deceased) ? femaleDeceasedIcon : femaleIcon;

    // Dependent icons
    let maleDependantIcon = (age >= 18) ? maleDefaultIcon : ((type == RelationType.Main) ? maleDefaultIcon : (deceased) ? childMaleDeceasedIcon : maleChildIcon); //(dependant) ? maleDeceasedIcon : maleIcon;
    let femaleDependantIcon = (age >= 18) ? femaleDefaultIcon : ((type == RelationType.Main) ? femaleIcon : (deceased) ? childFemaleDeceasedIcon : femaleChildIcon);// (dependant) ? femaleDeceasedIcon : femaleIcon;

    maleDependantIcon = (relation == 'Divorcee') ? divorcee : maleDependantIcon;
    femaleDependantIcon = (relation == 'Divorcee') ? divorceeFemale : femaleDependantIcon;
    // Not considering the relation type at this stage.
    // ( Main | Parent | Spouse | Divorcee | Child | DivorceeChild | SpouseChild )
    // when consider type handle logic here //
    console.log("dependant>>>>>", dependant)
    // icon = (gender == GenderType.Female) ? (dependant ? femaleChildIcon : femaleDefaultIcon) : (dependant ? maleChildIcon : maleDefaultIcon);
    icon = (gender == GenderType.Female) ? femaleDependantIcon : maleDependantIcon;
    console.log("dependant>>>>>", icon)
    return icon
  }

}
