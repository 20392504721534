import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { FamilyTreeService } from '../family-tree.service';
import { FamilyTreeEditModel } from 'src/app/shared/models/family-tree.model';
import { Router } from '@angular/router';
import { PanZoomConfig } from 'ng2-panzoom';
import * as moment from 'moment';
import { SharedDataService } from 'src/app/shared/service/shared-data.service';

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.css']
})
export class TreeComponent implements OnInit {

  nodeList : FamilyTreeEditModel[]=[];
  tree: FamilyTreeEditModel[] = []; 
  treeArray :any; 
  @ViewChild('canvas', { static: true }) canvas: ElementRef<HTMLCanvasElement>;  
  // @ViewChild('fullScreen', { static: true }) divRef;  

  width = 1950; //1100 //1250 
  height = 1250; // 900
  child_distance_factor_x = 10;
  X =380; // 250
  Y =180; // 75
  R =35; // 25
  main_distance = 24*this.R;
  separate_distance = 0;//23*this.R;
  willType :any = 1; 
  showPersonalSuccess:boolean = false;
  dependantList = [];
  mainNodeSpouseId : any;
  lastX : any;
  lastY : any;

  femaleImg :any = "";
  femaleMainImg :any = "";
  femaleMainDeceasedImg :any = "";
  femaleDeceasedImg :any="";
  femaleDependentImg :any="";
  femaleOtherImg :any="";
 
  maleImg :any = "";
  maleDeceasedImg :any = "";
  maleDependentImg :any="";
  maleMainImg :any = "";
  maleMainDeceasedImg :any = "";
  maleOtherImg :any = "";

  mainNodeAge:any =0;
  mainNodeGender:any =0;

  private ctx: CanvasRenderingContext2D;
  public panZoomConfig: PanZoomConfig = new PanZoomConfig;
  scaleFactor = 1;

  image : any = '';
  isLoaded : boolean = false; 
  @Output() demo = new EventEmitter<string>();

  constructor(private familyTreeService :FamilyTreeService,private router: Router, private sharedDataService: SharedDataService,) { 
    // this.familyTreeService.getFamilyTree().subscribe(familyTree => { 
    //   //console.log(566565656665);
    //   this.nodeList = familyTree; 
    // });
  }

  ngOnInit() {
    this.femaleImg = './assets/img/female@2x.png';
    this.femaleDeceasedImg = './assets/img/female_deceaced@2x.png';
    this.femaleDependentImg = './assets/img/female_dependant@2x.png';
    this.femaleMainImg = './assets/img/female_main@2x.png';
    this.femaleMainDeceasedImg = './assets/img/female_main_deceaced@2x.png';
    this.femaleOtherImg = './assets/img/female_other@2x.png';
    this.maleImg = './assets/img/male@2x.png';
    this.maleDeceasedImg = './assets/img/male_deceaced@2x.png';
    this.maleDependentImg = './assets/img/male_depandant@2x.png';
    this.maleMainImg = './assets/img/male_main@2x.png';
    this.maleMainDeceasedImg = './assets/img/male_main_deceaced@2x.png';
    this.maleOtherImg = './assets/img/male_other@2x.png';
    
    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.ctx.scale(.5,.5);
    //this.tree = new FamilyTreeEditModel();
    this.familyTreeService.getFamilyTree().subscribe(familyTree => { 
      //console.log(566565656665);
      this.tree = familyTree.treeData; 
      console.log('tree data===',this.tree);
      // console.log("draw graph");
      // console.log(this.nodeList);
      //this.createDynamicArray();
      //this.separate_distance = 850;
      this.ctx.clearRect(0, 0, this.width, this.height);
      this.ctx.beginPath();

      //this.animate(this.tree);
      if(this.tree.length >0){
        this.onWillDistributionChange(this.willType);
        this.isLoaded = true;        
      }

        // Ctrate Tree Image - start

        // const elem = this.canvas.nativeElement;
        // this.image = new Image();
        // this.image.src = elem.toDataURL("image/png").replace("image/png", "image/octet-stream"); 
        // // this.image = elem.toDataURL('image/jpeg', 0.5);
        // // this.image = elem.toDataURL("image/png").replace("image/png", "image/png");

        // localStorage.removeItem('imageURL');
        // localStorage.setItem('imageURL', this.image.src);
      
        // Ctrate Tree Image - end
        this.openDocumentImage();
        setInterval(()=> { this.openDocumentImage() }, 3 * 1000);
        
      // console.log("draw graph this tree");
      // console.log(this.tree);
    });
   
    this.sharedDataService.mainNodeAge.subscribe(mainNodeAge => this.mainNodeAge = mainNodeAge);
    this.sharedDataService.mainNodeGender.subscribe(mainNodeGender => this.mainNodeGender = mainNodeGender);
  }

  openFullscreen() {
    // Use this.divRef.nativeElement here to request fullscreen
    const elem = this.canvas.nativeElement;
  
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } 

    // else if (elem.msRequestFullscreen) {
    //   elem.msRequestFullscreen();
    // } else if (elem.mozRequestFullScreen) {
    //   elem.mozRequestFullScreen();
    // } else if (elem.webkitRequestFullscreen) {
    //   elem.webkitRequestFullscreen();
    // }
  }

  saveImageUrl() {
    
    // alert(this.image);
    return this.image;
    // var iframe = "<iframe width='100%' height='100%' src='" +
    // this.image + "'></iframe>"
    //                 var x = window.open();
    //                 x.document.open();
    //                 x.document.write(iframe);
    //                 x.document.close();
    // window.location.href=iframe; 
  }

  openDocumentImage() {
    // Ctrate Tree Image - start

    const elem = this.canvas.nativeElement;
    // this.image = new Image();
    this.image = elem.toDataURL("image/png").replace("image/png", "image/octet-stream"); 
    console.log('this.image== ',this.image)
    // this.image = elem.toDataURL('image/jpeg', 0.5);
    // this.image = elem.toDataURL("image/png").replace("image/png", "image/png");
    localStorage.removeItem('imageURL');
    localStorage.setItem('imageURL', this.image);
    // this.demo.emit(this.image);

    // var blob = this.dataURItoBlob(this.image);
    // var fd = new FormData(document.forms[0]);
    // fd.append("canvasImage", blob, "thumb.jpg");
    // console.log(blob, '---blob---');
    // console.log(fd, '---fd---');

    // Ctrate Tree Image - end
  }

  resolveAfter2Seconds(x) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(x);
      }, 2000);
    });
  }

  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type:mimeString});
  }

  // @HostListener("mousewheel", ["$event"])
  // onMouseWheel(event) {
  //   //const nativeValue: number = this.handleParse(this.el.nativeElement.value);

  //   if (event.wheelDelta > 0) {
  //     alert(" zoom+ clicked");
  //   } else {
  //     alert(" zoom- clicked");
  //   }
  //   //propagate ngModel changes
  //   // this.ngModelChange.emit(this.el.nativeElement.value);
  //   // return false;
  // }

  handleScroll(evt){   
    var evt = window.event || evt;
    var delta = evt.wheelDelta ? evt.wheelDelta/40 : evt.detail ? -evt.detail : 0;
    //alert(delta);
    if (delta) this.zoom(delta);
    this.lastX = evt.offsetX;
    this.lastY = evt.offsetY;
    return evt.preventDefault() && false;
  }

  zoom(clicks){
    this.ctx.translate(this.lastX,this.lastY);
    var factor = Math.pow(this.scaleFactor,clicks);
   // alert(factor);
    this.ctx.scale(factor,factor);
    this.ctx.translate(this.lastX,this.lastY);
    if(this.tree.length >0){
      this.onWillDistributionChange(this.willType);
    }
      // var pt = ctx.transformedPoint(this.lastX,this.lastY);
      // ctx.translate(pt.x,pt.y);
      // var factor = Math.pow(scaleFactor,clicks);
      // ctx.scale(factor,factor);
      // ctx.translate(-pt.x,-pt.y);
      // redraw();
  }
  
  // mouseWheelDownFunc(event){
  //   //alert(2);
  // }

  // draw() {
  //   let ctx: CanvasRenderingContext2D = this.canvas.nativeElement.getContext('2d');
  //   console.log("canvas size: ", ctx.canvas.width, ctx.canvas.height);
  // }

  onWillDistributionChange(value){
    this.ctx.clearRect(0, 0, this.width, this.height);
    this.ctx.beginPath();

    // console.log("value");
    // console.log(value);
    this.willType = value;

    if(value ==5){
      this.showPersonalSuccess = true;
    }
    else{
      this.showPersonalSuccess = false;
    }

    

    var tree3 =  JSON.parse(JSON.stringify( this.tree ));// [...this.tree];

    // console.log(this.showPersonalSuccess);
    // console.log("this.tree ",this.tree);
    // console.log("--- tree3 ====");
    // console.log(tree3);

    if(tree3 && tree3[0]){
      this.dependantList =[];
      this.filteredData(tree3[0], true);    
    }

    var tree1 = this.resetArray(tree3)

    if(value ==4){
      tree1[0].deceased = true;
      if(this.tree && this.tree[0] && this.tree[0].spouse ){
        tree1[0].spouse = this.resetArray(JSON.parse(JSON.stringify( this.tree[0].spouse) ));// [...this.resetArray(this.tree[0].spouse)]
        
        if(tree1[0].spouse && this.tree[0].spouse[0]){
          tree1[0].spouse_children.push({children:this.dependantList, partner_id: this.tree[0].spouse[0].id1});
        } 
      }
      else{
        tree1[0].child = this.dependantList;
      }
    }
    else{
      if(this.tree && this.tree[0] && this.tree[0].spouse ){
        tree1[0].spouse = this.resetArray(JSON.parse(JSON.stringify( this.tree[0].spouse) ));// [...this.resetArray(this.tree[0].spouse)]
        
        if(tree1[0].spouse && tree1[0].spouse[0]){
          tree1[0].spouse[0].child = this.dependantList;
        } 
      }
      else{
        tree1[0].child = this.dependantList;
      }
    }
    
    // console.log(tree3[0]);
    // console.log("---this.dependantList---");
    // console.log(this.dependantList);
    // console.log(tree1);

    if(value ==1){
      this.animate(this.tree);
    }
    else if(value ==2){
      //var tree1 = this.tree;
      let children =[]; 
            
      this.animate(tree1);
    }
    else if(value ==3){
      //var tree1 = this.tree;
      let children =[]; 
      
      if(this.tree && this.tree[0] && this.tree[0].spouse && this.tree[0].spouse.length >0 ){
        
        let X = this.main_distance + this.X +200;
        let Y = this.Y;
        let R = this.R;
        var ct1 = this.ctx, node_color = "#f9f9f9", border_color = "#000000";
        ct1.textAlign = 'center';
        ct1.textBaseline = 'middle';



        ct1.beginPath();
        ct1.arc(X, Y, this.R,0,Math.PI * 2);
        ct1.strokeStyle = border_color;
        ct1.fillStyle = node_color;
        ct1.fill()
        ct1.closePath();
        ct1.stroke();

        ct1.beginPath();
        ct1.arc(X+120, Y, this.R,0,Math.PI * 2);
        ct1.strokeStyle = border_color;
        ct1.fillStyle = node_color;
        ct1.fill()
        ct1.closePath();
        ct1.stroke();


        ct1.beginPath(); 
        ct1.moveTo(this.main_distance + this.X+R,Y);
        ct1.fillStyle ="#000";
        ct1.lineTo(X-R, Y);     
        ct1.stroke();

        ct1.beginPath(); 
        ct1.moveTo(X+R, Y);
        ct1.fillStyle ="#000";
        ct1.lineTo(X+R+15, Y);     
        ct1.stroke();

        ct1.beginPath(); 
        ct1.moveTo(X+120-R-15, Y);
        ct1.fillStyle ="#000";
        ct1.lineTo(X+120-R, Y);     
        ct1.stroke();

        ct1.beginPath(); 
        ct1.moveTo(X+R+15, Y+30);
        ct1.fillStyle ="#000";
        ct1.lineTo(X+120-R-15, Y-30);     
        ct1.stroke();

        ct1.beginPath(); 
        ct1.moveTo(X+60, Y+30);
        ct1.fillStyle ="#000";
        ct1.lineTo(X+60, Y+65);     
        ct1.stroke();

        ct1.beginPath();
        ct1.arc(X+60, Y+45+R, 15,0,Math.PI * 2);
        ct1.strokeStyle = border_color;
        ct1.fillStyle = node_color;
        ct1.fill()
        ct1.closePath();
        ct1.stroke();
        
        // tree1[0].spouse = this.resetArray(JSON.parse(JSON.stringify( this.tree[0].spouse) ));// [...this.resetArray(this.tree[0].spouse)] 
      }

      this.animate(tree1);
    }
    else if(value ==4){
      //var tree1 = this.tree;
      let children =[]; 
      
      // console.log(children);
      // console.log("tree1");
      // console.log(tree1);
      // console.log(this.tree);

      this.animate(tree1);
    }

    else{
      this.animate(this.tree);
    }


  }

  resetArray(tree1){
    if(tree1 && tree1[0]){      
      if(tree1[0].child){
        tree1[0].child=[]
      }
      if(tree1[0].divorcee){
        tree1[0].divorcee=[]
      }
      if(tree1[0].divorcee_children){
        tree1[0].divorcee_children=[]
      }
      if(tree1[0].parent){
        tree1[0].parent=[]
      }
      if(tree1[0].spouse_children){
        tree1[0].spouse_children=[]
      }
    }
    return tree1;
  }

  filteredData(node, ignoreDependants =false) {
    if(node.spouse && node.spouse.length > 0){ 
      let status = false;
      if(ignoreDependants && node.isMainNode){
       status = true;
      }         
      this.filteredData(node.spouse[0], status);    
    }

    if(node.divorcee && node.divorcee.length > 0){        
      this.filteredData(node.divorcee[0]);        
    }    
  
    if(node.child && node.child.length > 0){
      node.child.forEach(node_child => {
        this.filteredData(node_child);        
      });
    }

    if(node.divorcee_children && node.divorcee_children.length > 0 && node.divorcee && node.divorcee[0] && node.divorcee[0].id1){
      // node.divorcee_children.forEach(divorcee_childre => {
      //   this.filteredData(divorcee_childre);        
      // });

      let findDivorcee = node.divorcee_children.filter(function(divorcee){ 
        return divorcee.partner_id == node.divorcee[0].id1;      
      });

      // console.log("===findDivorcee---");      
      // console.log(findDivorcee);
      
      if(findDivorcee && findDivorcee[0] ){
        let childrens = findDivorcee[0].children;
        // console.log(childrens);
        childrens.forEach(spouse_childre => {
          this.filteredData(spouse_childre);        
        });
      }
    }

    if(node.spouse_children && node.spouse_children.length > 0 && node.spouse && node.spouse[0] && node.spouse[0].id1 ){
      let findSpouse1 = node.spouse_children.filter(function(spouse){ 
        return spouse.partner_id == node.spouse[0].id1;      
      });

      // console.log("===findSpouse1---");      
      // console.log(findSpouse1);
      
      if(findSpouse1 && findSpouse1[0] ){
        let childrens = findSpouse1[0].children;
        // console.log(childrens);
        childrens.forEach(spouse_childre => {
          this.filteredData(spouse_childre);        
        });
      }      
    }   
    
    if(node.parent && node.parent.length > 0){
      if(node.parent[0]){
        this.filteredData(node.parent[0]);
      }        
      if(node.parent[1]){
        this.filteredData(node.parent[1]);
      }
    }

    // console.log(node.parent);

    // console.log("+++node.dependant---");
    // console.log(node);
    // console.log(ignoreDependants);

    if(node.dependant && !ignoreDependants){
      let nodex = this.resetArray(JSON.parse(JSON.stringify(node) ));
      nodex.spouse =[];
      this.dependantList.push(nodex);
    }
   
    // return r;
  }

  animate(tree) {
    this.separate_distance = 1000;

    //console.log(33333333333333333);
    var ctx = this.ctx; 
    var X = this.X;
    var Y = this.Y;
    var R = this.R;
    var main_distance = this.main_distance;
    var separate_distance = this.separate_distance;
    
    var i = 0;
    
    if(tree && tree[0]){
      this.getChildren(tree[0], null, X, Y, R, main_distance, separate_distance);    
    }

  }

  getChildren(node,parent=null, X, Y, R, main_distance, separate_distance){
    var node_color = "#f9f9f9", border_color = "#000", text_color = "#000000"; 

    let node_img = this.processNode(node);

    // if(node.isMainNode || this.mainNodeSpouseId ==node.id1){
    //   node_color = "#6B969E";
    //   border_color = "#6B969E";
    //   text_color = "#fff"
    // }
    
    // if(node.dependant){
    //   border_color = "#6B969E";
    // }

    // if(node.deceased){
    //   node_color = "#797979";  
    //   text_color = "#fff"    
    // }
    
    var ct1 = this.ctx;
    ct1.textAlign = 'center';
    ct1.textBaseline = 'bottom';
    ct1.font = '32px sans-serif'; // Arial - cursive - caption - sans-serif
    

    var image = new Image();
    image.src = node_img;
    //image.src = 'http://www.monkey-and-banana.com/wp-content/uploads/2009/10/star-wars-droids.jpg';
    // console.log(image.src);
    

    ct1.beginPath();
    ct1.arc(X, Y, R,0,Math.PI * 2);
    ct1.strokeStyle = border_color;
    // ct1.fillStyle = node_color;
    image.onload = function (e)
        {
          ct1.drawImage(image, X-R, Y-R, 2*R, 2*R);
        }
    ct1.fill()
    ct1.closePath();
    ct1.lineWidth = 3;
    ct1.stroke();

    

    // image.onload = function() {
    //   ct1.save();
    //   ct1.globalCompositeOperation = 'source-in';
    //   ct1.drawImage(image, X-R, Y-R, 2*R, 2*R);
    //   //ct1.restore();
    // }

    let age = this.calculateAge(node.dateOfBirth);
    let gender = node.gender
console.log('age9999=',age)
console.log('gender9999=',gender)
    if(node.isMainNode ){
      this.sharedDataService.changeMainNodeAge(age); // localStorage.setItem('mainNodeAge', JSON.stringify(age));
      this.sharedDataService.changEmainNodeGender(gender); // localStorage.setItem('mainNodeGender', gender);
  
    }

    //ct1.fillStyle = text_color;
    // ct1.fillText(node.firstName, X, Y); 
    if(age > 0){
      ct1.fillText(age.toString(), X, Y+10); 
    }
    ct1.stroke();  

    this.drawName(node.firstName, X, Y, R, age.toString(), text_color);

    if(node.spouse && node.spouse.length > 0){
      ct1.beginPath(); 
      ct1.moveTo(X+R,Y);
      ct1.fillStyle ="#000";

      var node_distance =0;
      if( node && node.isMainNode ){
        node_distance = main_distance+30;
        ct1.lineTo(X-R+node_distance,Y);
        //X = X + node_distance;
      }
      else{
        node_distance = (this.child_distance_factor_x-6)*R+8;
        ct1.lineTo(X+node_distance,Y);
        //X = X + node_distance;
      }
      
      ct1.stroke();

      if(node.isMainNode && node.spouse[0] && node.spouse[0].id1){
        this.mainNodeSpouseId = node.spouse[0].id1;
      }

      if(node.spouse[0].firstName == "cc"){
        // console.log(node.isMainNode);
        // console.log(X+node_distance);
        // console.log(node.spouse[0]);
      }

      this.getChildren(node.spouse[0], node, X + node_distance, Y, R, main_distance, separate_distance);

      var spouse_children = this.filterSpouseChilden(node.spouse[0], node);
        // console.log("spouse_children");
        // console.log(spouse_children);
      /*
      start here
      */
      if(spouse_children && spouse_children.children && spouse_children.children.length >0){
        this.processSpouseChildren(spouse_children.children, node, X, Y, R, main_distance, separate_distance,X + node_distance);
      }

    }

    let divorce_children_children =[];
    if(node.divorcee && node.divorcee.length > 0){
      var X1 = this.separate_distance - R+80;
      
      // console.log("+++node.divorcee+++");
      // console.log(node.divorcee);
      // console.log("+++ X1 +++",X1);
      
      //Object.assign(X1, this.addTreeForm.value.formNodeList);
      var divorce_children = this.filterDivorceChilden(node.divorcee[0], node);
      let children_distance = 0;
      if(divorce_children && divorce_children.children && divorce_children.children.length >1){
        children_distance = (divorce_children.children.length-1)*5*R
      }
      X1 = children_distance + X1;

      this.getChildren(node.divorcee[0], node, X1, Y, R, main_distance, this.separate_distance);
      this.separate_distance = this.separate_distance + 5*R + children_distance;

    
      if(divorce_children && divorce_children.children && divorce_children.children.length >0){
        divorce_children_children = divorce_children.children;
          // console.log("divorce_children");
          // console.log(divorce_children);
        //this.processSpouseChildren(divorce_children.children, node, X, Y, R, main_distance, separate_distance,X+node_distance);

        //this.processChildren(divorce_children.children, node, X1, Y, R, main_distance, separate_distance);
      }
      
    }

    let divorce_children_my =[];
    if(parent && parent.divorcee && parent.divorcee.length > 0){
      //var X1 = X + this.separate_distance - R;
            
      //this.separate_distance = this.separate_distance + 5*R;

      //this.getChildren(node.divorcee[0], node, X1, Y, R, main_distance, this.separate_distance);

      var divorce_children_frm_parent = this.filterDivorceChilden(node, parent);
    
      if(divorce_children_frm_parent && divorce_children_frm_parent.children && divorce_children_frm_parent.children.length >0){
        divorce_children_my = divorce_children_frm_parent.children;
          // console.log("+++++ node +++++");
          // console.log(node);
          // console.log(divorce_children_my);
        //this.processSpouseChildren(divorce_children.children, node, X, Y, R, main_distance, separate_distance,X+node_distance);
        
        //this.processChildren(divorce_children.children, node, X1, Y, R, main_distance, separate_distance);
      }
      
    }
   
    let children = [];

    if(divorce_children_my){
      children = children.concat(divorce_children_my);
    }

    if(divorce_children_children && divorce_children_children.length > 0){
      children = children.concat(divorce_children_children);
    }
    
    children = children.concat(node.child);
    
    // console.log(children) ;

    if(children && children.length > 0){
      this.processChildren(children, node, X, Y, R, main_distance, separate_distance);
    }

    if(node.parent && node.parent.length > 0){
      this.processParents(node.parent, node, X, Y, R, main_distance, separate_distance);
    }

  }

  drawName(firstName, X, Y, R, age, text_color){
    var ct1 = this.ctx;
    ct1.textAlign = 'center';
     ct1.textBaseline = 'hanging';
    ct1.fillStyle = text_color;

    if(age > 0){
      firstName = firstName+"("+age.toString()+")"
      //ct1.fillText(age.toString(), X, Y+10); 
    }

    ct1.fillText(firstName, X, Y+R+20); 
    ct1.stroke();  
  }

  processNode(node){
    let img = this.maleImg;
    let sex = this.getMaleFemale(node);
    if(node.isMainNode){
      //let sex = this.getMaleFemale(node);
      if(node.deceased){
        if(sex && sex ==1){
          return this.maleMainDeceasedImg;
        }
        else{
          return this.femaleMainDeceasedImg;
        }
        // node_color = "#797979";  
        // text_color = "#fff"    
      }
      
      if(sex && sex ==1){
        return this.maleMainImg;
      }
      else{
        return this.femaleMainImg;
      }
      // node_color = "#6B969E";
      // border_color = "#6B969E";
      // text_color = "#fff"
      
    }

    if(this.mainNodeSpouseId ==node.id1){
      //let sex = this.getMaleFemale(node);
      if(node.deceased){
        if(sex && sex ==1){
          return this.maleMainDeceasedImg;
        }
        else{
          return this.femaleMainDeceasedImg;
        }
        // node_color = "#797979";  
        // text_color = "#fff"    
      }

      if(sex && sex ==1){
        return this.maleMainImg;
      }
      else{
        return this.femaleMainImg;
      }
      // node_color = "#6B969E";
      // border_color = "#6B969E";
      // text_color = "#fff"
    }
    
    

    if(node.deceased){
      if(sex && sex ==1){
        return this.maleDeceasedImg;
      }
      else{
        return this.femaleDeceasedImg;
      }
      // node_color = "#797979";  
      // text_color = "#fff"    
    }
    else if(node.dependant){
      if(sex && sex ==1){
        return this.maleDependentImg;
      }
      else{
        return this.femaleDependentImg;
      }
      // border_color = "#6B969E";
    }
    else{
      if(sex && sex ==1){
        return this.maleImg;
      }
      else{
        return this.femaleImg;
      }
    }

    
  }

  getMaleFemale(node){
    if(node.gender && node.gender=='1'){
      return 1;
    }
    else{
      return 2;
    }
  }

  // drawCircle(X, Y, R, SA, EA, img){
  //   var ct1 = this.ctx;
  //   // ct1.textAlign = 'center';
  //   // ct1.textBaseline = 'middle';

  //   var image = new Image();
  //   image.src = img;
  //   //image.src = 'http://www.monkey-and-banana.com/wp-content/uploads/2009/10/star-wars-droids.jpg';
  //   console.log(image.src);
    
  //   ct1.beginPath();
  //   ct1.arc(X, Y, R,0,Math.PI * 2);
  //   //ct1.strokeStyle = border_color;
  //   //ct1.fillStyle = node_color;
  //   image.onload = function (e)
  //       {
  //         ct1.drawImage(image, X-R, Y-R, 2*R, 2*R);
  //       }
  //   ct1.fill()
  //   ct1.closePath();
  //   ct1.stroke();
  // }

  processChildren(child, node, X, Y, R, main_distance, separate_distance){
    var ct1 = this.ctx;
    ct1.beginPath(); 
    ct1.moveTo(X,Y+R);
    ct1.fillStyle ="#000";
    ct1.lineTo(X,Y+(this.child_distance_factor_x)*R);
    ct1.stroke();

    if(child.length > 1){
      var child_distance_factor_x = this.child_distance_factor_x;
      var new_x = X-(child.length-1)*child_distance_factor_x*R/2;
      var new_y = Y+child_distance_factor_x*R;
  
      // draw children line
      ct1.beginPath();
      ct1.moveTo(new_x,new_y);
      ct1.fillStyle ="#000";
      ct1.lineTo(X+(child.length-1)*child_distance_factor_x*R/2,new_y);
      ct1.stroke();
  
      var i =0;
      child.forEach(node_child => {
        var ct1 = this.ctx;
        ct1.beginPath(); 
        ct1.moveTo(new_x+i*child_distance_factor_x*R, new_y);
        ct1.fillStyle ="#000";
        ct1.lineTo(new_x+i*child_distance_factor_x*R, new_y +2*R);
        ct1.stroke();        
        this.getChildren(node_child, node, (new_x+i*child_distance_factor_x*R), (new_y +2*R), R, main_distance, separate_distance);
        i++;
      });
    }
    else{
      child.forEach(node_child => {        
        this.getChildren(node_child, node, X, Y+this.child_distance_factor_x*R, R, main_distance, separate_distance);
      });
    }
    

  }

  processSpouseChildren(child, node, X, Y, R, main_distance, separate_distance,spouse_X){
    var ct1 = this.ctx;
     X = (X + spouse_X)/2;
    ct1.beginPath(); 
    ct1.moveTo(X,Y);
    ct1.fillStyle ="#000";
    ct1.lineTo(X,Y+(this.child_distance_factor_x-2)*R*2);
    ct1.stroke();

    if(child.length > 1){
      var child_distance_factor_x = this.child_distance_factor_x+3;
      var child_distance_factor_y = this.child_distance_factor_x-2;
      var new_x = X-(child.length-1)*child_distance_factor_x*R/2;
      var new_y = Y+child_distance_factor_y*R*2;
  
      // draw children line
      ct1.beginPath();
      ct1.moveTo(new_x,new_y);
      ct1.fillStyle ="#000";
      ct1.lineTo(X+(child.length-1)*child_distance_factor_x*R/2,new_y);
      ct1.stroke();
  
      var i =0;
      child.forEach(node_child => {
        var ct1 = this.ctx;
        ct1.beginPath(); 
        ct1.moveTo(new_x+i*child_distance_factor_x*R, new_y);
        ct1.fillStyle ="#000";
        ct1.lineTo(new_x+i*child_distance_factor_x*R, new_y +2*R);
        ct1.stroke();        
        this.getChildren(node_child, node, (new_x+i*child_distance_factor_x*R), (new_y +2*R), R, main_distance, separate_distance);
        i++;
      });
    }
    else{
      child.forEach(node_child => {        
        this.getChildren(node_child, node, X, Y+(this.child_distance_factor_x-2)*R*2, R, main_distance, separate_distance);
      });
    }
  }

  processParents(parent, node, X, Y, R, main_distance, separate_distance){
    var ct1 = this.ctx;
    

    if(parent[0]){
      ct1.beginPath(); 
      ct1.moveTo(X,Y-R);
      ct1.fillStyle ="#000";
      ct1.lineTo(X,Y-4*R);
      ct1.stroke();
  
      // draw children line
      ct1.beginPath();
      ct1.moveTo(X,Y-4*R);
      ct1.fillStyle ="#000";
      ct1.lineTo(X+4*R,Y-4*R);
      ct1.stroke();
  
      var node_color = "#f9f9f9", border_color = "#000", text_color = "#000000";

      if(node.dependant){
        border_color = "#6B969E";
      }
  
      if(node.deceased){
        node_color = "#797979";  
        text_color = "#fff"    
      }

      //var ct1 = this.ctx;
      ct1.textAlign = 'center';
      ct1.textBaseline = 'middle';

      let node_img1 = this.processNode(parent[0]);
      var image1 = new Image();
      image1.src = node_img1;
      // console.log(image1.src);

      //border_color = "#fff"
      ct1.beginPath();
      ct1.arc(X+4+4*R,Y-4*R, R,0,Math.PI * 2);
      ct1.strokeStyle = border_color;
      // ct1.fillStyle = node_color;
      image1.onload = function (e)
        {
          ct1.drawImage(image1, X+4+4*R-R, Y-4*R-R, 2*R, 2*R);
        }
      ct1.fill()
      ct1.closePath();
      ct1.stroke();

      let age = this.calculateAge(parent[0].dateOfBirth);

      this.drawName(parent[0].firstName, X+4+4*R,Y-4*R, R, age.toString(), text_color);
    
      // ct1.fillStyle = text_color;
      // if(age > 0){
      //   ct1.fillText(age.toString(), X, Y+10); 
      // }
      // ct1.fillText(parent[0].firstName, X+2+3*R/2,Y-3*R/2); 
      // ct1.stroke();
    }

    if(parent[1]){
      ct1.beginPath(); 
      ct1.moveTo(X,Y-R);
      ct1.fillStyle ="#000";
      ct1.lineTo(X,Y-4*R);
      ct1.stroke();
  
      // draw children line
      ct1.beginPath();
      ct1.moveTo(X,Y-4*R); 
      ct1.fillStyle ="#000";
      ct1.lineTo(X-4*R,Y-4*R);
      ct1.stroke();
  
      var node_color = "#f9f9f9", border_color = "#000", text_color = "#000000";

      if(node.dependant){
        border_color = "#6B969E";
      }
  
      if(node.deceased){
        node_color = "#797979";  
        text_color = "#fff"    
      }

      //var ct1 = this.ctx;
      ct1.textAlign = 'center';
      ct1.textBaseline = 'middle';

      let node_img = this.processNode(parent[1]);
      var image = new Image();
      image.src = node_img;

      //border_color = "#fff" X+2+2*R,Y-2*R
      ct1.beginPath();
      ct1.arc(X-4-4*R,Y-4*R, R,0,Math.PI * 2);
       ct1.strokeStyle = border_color;
      // ct1.fillStyle = node_color;
      image.onload = function (e)
        {
          ct1.drawImage(image, X-4-4*R-R, Y-4*R-R, 2*R, 2*R);
        }
      ct1.fill()
      ct1.closePath();
      ct1.stroke();

      let age = this.calculateAge(parent[1].dateOfBirth);
    
      this.drawName(parent[1].firstName, X-4-4*R,Y-4*R, R, age.toString(), text_color);

      // ct1.fillStyle = text_color;
      // if(age > 0){
      //   ct1.fillText(age.toString(), X, Y+10); 
      // }
      // ct1.fillText(parent[1].firstName, X-2-3*R/2,Y-3*R/2); 
      // ct1.stroke();
    }
  }

  filterSpouseChilden(node, parent){
    // if(!parent || !node){
    //   return null;
    // }
    if(parent.spouse_children){
      // console.log("node", node);
      // console.log("parentNode", parent);
      let children = parent.spouse_children.filter(function(spouse){ return spouse.partner_id == node.id1; });
      // console.log("children", children);
      if(children && children.length >0){
        return children[0];
      }
      else{
        return null;
      }
    }
    
  }

  filterDivorceChilden(node, parent){
    
    // if(!parent || !node){
    //   return null;
    // }
    // console.log("node", node);
    // console.log("parentNode", parent);

    if(parent.divorcee_children){
      // console.log("node ", node);
      // console.log("parentNode ", parent);
      let children = parent.divorcee_children.filter(function(spouse){ return spouse.partner_id == node.id1; });
     
      // console.log("children ", children);
      if(children && children.length >0){
        return children[0];
      }
      else{
        return null;
      }
    }
    
  }

  public calculateAge(birthdate: any) {
    return moment().diff(birthdate, 'years');
  }

}