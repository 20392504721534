import { Component, OnInit, Input } from '@angular/core';
import { WillModel, AddEPAsItems, LifetimeArrangements, EPAsItems } from 'src/app/shared/models/will.model';
import { FamilyTreeService } from 'src/app/family-tree/family-tree.service';
import { ToastrService } from 'ngx-toastr';
import { WillResponse, DeleteOtherProvisionResponse, AddFinancialEPAResponse, LifetimeArrangementsNoteResponse } from 'src/app/shared/models/response.model';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-lifetime-arrangements',
  templateUrl: './lifetime-arrangements.component.html',
  styleUrls: ['../../rightside.component.css']
})
export class LifetimeArrangementsComponent implements OnInit {

  @Input() family_tree_id:string;
  @Input() client_code:string;
  @Input() partnerClientCode:string;
  @Input() clientFirstname:string;
  @Input() partnerFirstname:string;
  @Input() partnerHas:boolean;
  @Input() willInfo:WillModel;
  isLoaded6:boolean;
  addEPAsItems: any=[];
  editEPAsItems: any=[];

  constructor(
    private familyTreeService :FamilyTreeService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
  }

  getWillInfo(){
    // this.ngxService.start();
    this.familyTreeService.getWill(this.family_tree_id).subscribe((willData:WillResponse)=>{
      if(willData.success){
        this.willInfo = willData.response;
        
        // let executorNmae = this.willInfo.executors.clientExecutors.name,
        // executorType = this.willInfo.executors.clientExecutors.combinedRetirementIncome,
        // precentageIfPartnerDeceased = this.willInfo.executors.partnerExecutors.precentageIfPartnerDeceased,
        // precentageIfPartnerDeceased = this.willInfo.executors.partnerExecutors.precentageIfPartnerDeceased;
          
        this.isLoaded6 = true;
      } 
      // this.ngxService.stop(); 
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });
  }

  deleteFinancialEPA(financialEPA)
  {
    let id = financialEPA.id;

    this.familyTreeService.deleteFinancialEPA(id).subscribe((data:DeleteOtherProvisionResponse)=>{
      if(data.error && !data.success){      
        this.toastr.error("Error on deleting financial EPA.");      
      }
      else{
        this.getWillInfo();
        this.toastr.success("Financial EPA deleted successfully.");
      }

      //this.getProvisionInfo();
    },err=>{   
      console.log(err);
      this.toastr.error("Error on deleting financial EPA.");
    });    
  }

  addFinancialEPA(financialEPA, userType) {
    //console.log(financialEPA);
    this.addEPAsItems = new AddEPAsItems;

    this.addEPAsItems.lifeArrangementId = financialEPA.id;

    if(userType == 1) {
      var cl_CBUName = ((document.getElementById("clientFinancialEPANameAdd") as HTMLInputElement).value);
      var cl_CBUPercentage = ((document.getElementById("clientFinancialEPATypeAdd") as HTMLInputElement).value);

      this.addEPAsItems.clientCode = this.client_code;
      this.addEPAsItems.name = cl_CBUName;
      this.addEPAsItems.type = cl_CBUPercentage;

      this.financialEPAAdd(this.addEPAsItems);
    }
    
    if(userType == 2) {
      var pa_CBUName = ((document.getElementById("partnerFinancialEPANameAdd") as HTMLInputElement).value);
      var pa_CBUPercentage = ((document.getElementById("partnerFinancialEPATypeAdd") as HTMLInputElement).value);

      this.addEPAsItems.clientCode = this.partnerClientCode;
      this.addEPAsItems.name = pa_CBUName;
      this.addEPAsItems.type = pa_CBUPercentage;

      this.financialEPAAdd(this.addEPAsItems);
    }

    ((document.getElementById("clientFinancialEPANameAdd") as HTMLInputElement).value='');
    ((document.getElementById("clientFinancialEPATypeAdd") as HTMLInputElement).value='');
    ((document.getElementById("partnerFinancialEPANameAdd") as HTMLInputElement).value='');
    ((document.getElementById("partnerFinancialEPATypeAdd") as HTMLInputElement).value='');
  }

  financialEPAAdd(addEPAsItems)
  {
    this.familyTreeService.addFinancialEPA(addEPAsItems).subscribe((data:AddFinancialEPAResponse)=>{
      if(data.error && !data.success){      
        this.toastr.error('Error on adding financial EPA.');      
      }
      else{
        this.toastr.success('Financial EPA added successfully.');
      }

      this.getWillInfo();
    },err=>{   
      console.log(err);
      this.toastr.error('Error on adding financial EPA.');
    });
  }

  deleteMedicalEPA(medicalEPA)
  {
    let id = medicalEPA.id;

    this.familyTreeService.deleteMedicalEPA(id).subscribe((data:DeleteOtherProvisionResponse)=>{
      if(data.error && !data.success){      
        this.toastr.error("Error on deleting medical EPA.");      
      }
      else{
        this.getWillInfo();
        this.toastr.success("Medical EPA deleted successfully.");
      }

      //this.getProvisionInfo();
    },err=>{   
      console.log(err);
      this.toastr.error("Error on deleting medical EPA.");
    });    
  }

  addMedicalEPA(financialEPA, userType) {
    //console.log(financialEPA);
    this.addEPAsItems = new AddEPAsItems;

    this.addEPAsItems.lifeArrangementId = financialEPA.id;

    if(userType == 1) {
      var cl_CBUName = ((document.getElementById("clientMedicalEPANameAdd") as HTMLInputElement).value);
      var cl_CBUPercentage = ((document.getElementById("clientMedicalEPATypeAdd") as HTMLInputElement).value);

      this.addEPAsItems.clientCode = this.client_code;
      this.addEPAsItems.name = cl_CBUName;
      this.addEPAsItems.type = cl_CBUPercentage;

      this.medicalEPAAdd(this.addEPAsItems);
    }
    
    if(userType == 2) {
      var pa_CBUName = ((document.getElementById("partnerMedicalEPANameAdd") as HTMLInputElement).value);
      var pa_CBUPercentage = ((document.getElementById("partnerMedicalEPATypeAdd") as HTMLInputElement).value);

      this.addEPAsItems.clientCode = this.partnerClientCode;
      this.addEPAsItems.name = pa_CBUName;
      this.addEPAsItems.type = pa_CBUPercentage;

      this.medicalEPAAdd(this.addEPAsItems);
    }

    ((document.getElementById("clientMedicalEPANameAdd") as HTMLInputElement).value='');
    ((document.getElementById("clientMedicalEPATypeAdd") as HTMLInputElement).value='');
    ((document.getElementById("partnerMedicalEPANameAdd") as HTMLInputElement).value='');
    ((document.getElementById("partnerMedicalEPATypeAdd") as HTMLInputElement).value='');
  }

  medicalEPAAdd(addEPAsItems)
  {
    this.familyTreeService.addMedicalEPA(addEPAsItems).subscribe((data:AddFinancialEPAResponse)=>{
      if(data.error && !data.success){      
        this.toastr.error('Error on adding medical EPA.');      
      }
      else{
        this.toastr.success('Medical EPA added successfully.');
      }

      this.getWillInfo();
    },err=>{   
      console.log(err);
      this.toastr.error('Error on adding medical EPA.');
    });
  }

  financialEPASave(financialEPA, event: any){
    //console.log(financialEPA);
    let value = event.target.value;
    let inputId = event.target.id;
    // console.log(value);
    // console.log(inputId);

    this.editEPAsItems = new EPAsItems;
    this.editEPAsItems.clientCode = financialEPA.clientCode;

    if(inputId == 'clientFinancialEPAName' || inputId == 'partnerFinancialEPAName') {
      this.editEPAsItems.name = value;
      this.editEPAsItems.type = financialEPA.type;
    }
    if(inputId == 'clientFinancialEPAType' || inputId == 'partnerFinancialEPAType') {
      this.editEPAsItems.name = financialEPA.name;
      this.editEPAsItems.type = value;
    }

    // console.log(this.willExecutorsNoteAdd);
    this.familyTreeService.editFinancialEPA(financialEPA.id, this.editEPAsItems).subscribe((data:AddFinancialEPAResponse)=>{
      if(data.error && !data.success){      
        this.toastr.error('Error on updating financial EPA.');      
      }
      else{
        this.toastr.success('Financial EPA updated successfully.');
      }

      this.getWillInfo();
    },err=>{   
      console.log(err);
      this.toastr.error('Error on updating financial EPA.');
    });
  }

  medicalEPASave(medicalEPA, event: any){
    //console.log(medicalEPA);
    let value = event.target.value;
    let inputId = event.target.id;
    // console.log(value);
    // console.log(inputId);

    this.editEPAsItems = new EPAsItems;
    this.editEPAsItems.clientCode = medicalEPA.clientCode;

    if(inputId == 'clientMedicalEPAName' || inputId == 'partnerMedicalEPAName') {
      this.editEPAsItems.name = value;
      this.editEPAsItems.type = medicalEPA.type;
    }
    if(inputId == 'clientMedicalEPAType' || inputId == 'partnerMedicalEPAType') {
      this.editEPAsItems.name = medicalEPA.name;
      this.editEPAsItems.type = value;
    }

    // console.log(this.willExecutorsNoteAdd);
    this.familyTreeService.editMedicalEPA(medicalEPA.id, this.editEPAsItems).subscribe((data:AddFinancialEPAResponse)=>{
      if(data.error && !data.success){      
        this.toastr.error('Error on updating medical EPA.');      
      }
      else{
        this.toastr.success('Medical EPA updated successfully.');
      }

      this.getWillInfo();
    },err=>{   
      console.log(err);
      this.toastr.error('Error on updating medical EPA.');
    });
  }

  lifetimeArrangementsNoteSave(lifetimeArrangements, event: any){
    //console.log(lifetimeArrangements);
    let value = event.target.value;
    let inputId = event.target.id;
    // console.log(value);
    // console.log(inputId);

    this.editEPAsItems = new LifetimeArrangements;

    this.editEPAsItems.note = value;
   
    // console.log(this.willExecutorsNoteAdd);
    this.familyTreeService.editLifetimeArrangementsNote(lifetimeArrangements.id, this.editEPAsItems).subscribe((data:LifetimeArrangementsNoteResponse)=>{
      if(data.error && !data.success){      
        this.toastr.error('Error on updating note.');      
      }
      else{
        this.toastr.success('Note updated successfully.');
      }

      this.getWillInfo();
    },err=>{   
      console.log(err);
      this.toastr.error('Error on updating note.');
    });
  }

}
