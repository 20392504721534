import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { FamilyTree, FamilyTreeEditModel, ChildrenTree } from 'src/app/shared/models/family-tree.model';
import { FormBuilder, FormGroup, Validators, NgForm, FormArray, AbstractControl } from '@angular/forms';
import { FamilyTreeService } from '../family-tree.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { GetTreeModel } from '../../shared/models/get-tree.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TreeResponse, FamilyTreeExistResponse, BudgetResponse, XplanChoicesResponse, InsuranceResponse, RetirementResponse, ProvisionResponse, WillResponse, FinancesResponse, RetirementAddResponse, DependantSupportAddResponse, SpouseDeathOrTPDResponse, BenefitPeriodResponse, OtherProvisionsResponse, ImmediateNeedsResponse, ProgressiveNeedsResponse, WillExecutorsResponse, DocumentsResponse, ResidualBequestsResponse, ResiduaryBequestUserResponse, ResiduaryBequestNoteResponse, CalamityBeneficiaryResponse, CalamityBeneficiaryAddResponse, DeleteOtherProvisionResponse, OtherEntityItemResponse, OtherEntityNoteResponse, AddFinancialEPAResponse, LifetimeArrangementsNoteResponse, ProposedInsuranceResponse, ProposedInsuranceAddResponse } from '../../shared/models/response.model';
import { BudgetModel, BudgetsList } from 'src/app/shared/models/budget.model';
import { XplanChoices } from 'src/app/shared/models/xplan-choices.model';
import { InsuranceModel, InsurancesList } from 'src/app/shared/models/insurance.model';
import { RetirementList, RetirementModel } from 'src/app/shared/models/retirement.model';
import { ProvisionModel, DependentSupportsList, DeathOrTPDItems, BenefitPeriodsItems, OtherProvisionsItems, ImmediateNeedsItems, ProgressiveNeedsItems, OtherProvisionList, OtherProvisionsAddItems } from 'src/app/shared/models/provision.model';
import { genderValidator } from '../../validators/gender-validator';
import { WillModel, ExecutorsItems, AddWillExecutor, ExecutorsList, ResidualBequestDetail, AddResiduaryBequestUsers, ResidualBequests, CalamityBeneficiaries, AddCalamityBeneficiaryUser, OtherEntitie, OtherEntities, AddEPAsItems, EPAsItems, LifetimeArrangements } from 'src/app/shared/models/will.model';

import { XplanCoverGrouped } from 'src/app/shared/models/xplan-cover-grouped.model';
import {saveAs} from 'file-saver';
import { ProposedInsuranceModel } from 'src/app/shared/models/proposed-insurance.model';
import { TreeComponent } from '../tree/tree.component';

import { FinancesComponent } from './finances/finances.component';
import { RetirementComponent } from './retirement/retirement.component';
import { SharedDataService } from 'src/app/shared/service/shared-data.service';

import { WillComponent } from './will/will.component';
import { FamilyComponent } from './family/family.component';
import { FinancesModel } from 'src/app/shared/models/finances.model';

@Component({
  selector: 'app-rightside',
  templateUrl: './rightside.component.html',
  styleUrls: ['./rightside.component.css'],
  template: `<app-graph #component1></app-graph>`
})
export class RightsideComponent implements OnInit {
 
  client_id: string;
  client_code: string;
  isLoaded:boolean = false; 
  membersLength: number = 0;
  fileToUpload: File = null;
  familyTreeExist: boolean = true;
  clientFname:string = "";
  clientLname:string = "";
  clientMname:string = "";
  clientDob:string = null;
  clientGender:string = "";
  xeppoCode: string;
  isfinance:boolean=false;

  GraphComponentObject: any;
  @ViewChild('canvas', { static: true }) canvas: ElementRef<HTMLCanvasElement>;
  //@ViewChild("component1") component1: GraphComponent; 
  image : string;
  retirementCompOneObj:RetirementComponent;
  
  willCompOneObj:WillComponent;
  familyCompOneObj:FamilyComponent;
  financesInfo: FinancesModel;

  constructor(
    private familyTreeService :FamilyTreeService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService,
    private router: Router, 
    private sharedDataService: SharedDataService,
    public modalRef: BsModalRef,
    private modalService: BsModalService,
    // private financesComponent: FinancesComponent,
    ) { 
      this.GraphComponentObject = new TreeComponent(this.familyTreeService, this.router, this.sharedDataService);
      // this.familyCompOneObj = new FamilyComponent(this.familyTreeService, this.ngxService, this.formBuilder, this.toastr, this.sharedDataService, this.router, this.modalRef, this.modalService );
      
      this.router.routeReuseStrategy.shouldReuseRoute = function() {
        return false;
      };

      this.sharedDataService.familyTreeExist.subscribe(familyTreeExist => this.familyTreeExist = familyTreeExist);
      this.sharedDataService.isLoaded.subscribe(isLoaded => this.isLoaded = isLoaded);
      this.sharedDataService.membersLength.subscribe(membersLength => this.membersLength = membersLength);
      //this.createRetirementForm();
    }

  ngOnInit() {
    this.client_id = this.route.snapshot.queryParams['clientCode'];
    this.clientFname = this.route.snapshot.queryParams['firstName'];
    this.clientLname = this.route.snapshot.queryParams['lastName'];
    this.clientMname = this.route.snapshot.queryParams['middleName']; 
    this.clientDob = this.route.snapshot.queryParams['dateOfBirth'];
    this.clientGender = this.route.snapshot.queryParams['gender'];
    this.xeppoCode = this.route.snapshot.queryParams['xeppoCode'];
    this.client_code = this.route.snapshot.queryParams['clientCode'];

    if(this.client_id && this.isLoaded) {    
        // this.familyCompOneObj.getFamilyTreeExist();
    }

  }
  
  loadDocumentImage() {
    this.GraphComponentObject.openDocumentImage();
  }


  DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
  }
  
  saveImageUrl2() {
    const image = localStorage.getItem('imageURL');
    console.log(image);

    var iframe = "<iframe width='100%' height='100%' src='" +
    image + "'></iframe>"
                    var x = window.open();
                    x.document.open();
                    x.document.write(iframe);
                    x.document.close();

    window.location.href=iframe; 
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  setfinance(){

  
  }

  // firstNameChange(value, i){
  //   if(value == ""){
  //     this.nodeList.splice(i,1);;
  //   }
  //   else if(value.length == 1){
  //     //this.addTreeForm.controls.formNodeList.push();
  //   }
  // }

  // clickBudget(){
  //   this.getBudgetInfo();
  // }

  // clickInsurance(){
  //   this.getInsuranceInfo();
  // }

  // clickRetirement(){
  //   this.getRetirementInfo();
  // }

  // clickProvision(){
  //   this.getProvisionInfo();
  // }
  
  // clickWill(){
  //   this.getWillInfo();
  // }

  // clickFinances(){
  //   this.getFinancesInfo();
  // }

}
